import React, { useState } from "react";

import PopupWindow from "juice-base/components/popup-window/index.js";
import DateRangeCustom from "juice-base/components/date-range-custom/index.js";
import ButtonBig from "juice-base/components/button-big/index.js";

import styles from "./styles.module.css";
import "./styles.css";


const PopupDateRangeCustom = (props) => {
    const [range, setRange] = useState(() => ({
        startDate: props.dateRange.startDate,
        endDate: props.dateRange.endDate,
    }));

    const onDateRangeChange = (values) => {
        setRange({
            startDate: values.startDate,
            endDate: values.endDate,
        });
    };

    return (
        <PopupWindow
            title="Custom Selection"
            onClose={props.onClose}
        >
            <div className={[styles.range, "dateRangePicker"].join(" ")}>
                <DateRangeCustom
                    name="daterange-custom-for-students"
                    startDate={props.dateRange.startDate}
                    endDate={props.dateRange.endDate}
                    minDate={props.calendarDates.min}
                    maxDate={props.calendarDates.max}
                    onChange={onDateRangeChange}
                />
                <div className={styles.applyButton}>
                    <ButtonBig
                        disabled={!range.startDate || !range.endDate}
                        onClick={() => {
                            props.onApply(range);
                        }}
                    >
                        Apply
                    </ButtonBig>
                </div>
            </div>
        </PopupWindow>
    );
};

PopupDateRangeCustom.defaultProps = {
    dateRange: {},
    calendarDates: {},
    onApply: () => {},
    onDateRangeChange: () => {},
    onClose: () => {},
};

export default PopupDateRangeCustom;
