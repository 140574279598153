import React, { useEffect, useRef } from "react";

import timer from "juice-base/lib/timer.js";

import VideoDefault from "juice-base/components/video-default/index.js";
import VideoCustom from "juice-base/components/video-custom/index.js";


const Video = (props) => {
    const videoTimer = useRef(null);

    /* ---- */

    const getVideoPlayedTime = () => {
        return videoTimer?.current?.getDiffSeconds() || 0;
    };

    const onPlayStart = () => {
        videoTimer.current = timer.startTimer();
        props.onPlayStart(props.src, props.videoId);
    };

    const onPause = () => {
        const timePlayed = getVideoPlayedTime();

        props.onPause(props.src, props.videoId, timePlayed);
        videoTimer.current = null;
    };

    const onPlayEnd = () => {
        const timePlayed = getVideoPlayedTime();

        props.onPlayEnd(props.src, props.videoId, timePlayed);
        videoTimer.current = null;
    };

    /* ---- */

    useEffect(() => {
        return () => {
            onPause();
            onPlayEnd();
        };
    }, []);

    /* ---- */

    if (props.isDefault) {
        return (
            <VideoDefault
                videoId={props.videoId}
                src={props.src}
                type={props.type}
                poster={props.poster}
                videoHeight={props.videoHeight}
                canPlay={props.canPlay}
                withPosterCover={props.withPosterCover}
                captionSrc={props.captionSrc}
                onPlayStart={onPlayStart}
                onPlayEnd={onPlayEnd}
                onPause={onPause}
            />
        );
    }

    return (
        <VideoCustom
            videoId={props.videoId}
            src={props.src}
            type={props.type}
            poster={props.poster}
            videoHeight={props.videoHeight}
            canPlay={props.canPlay}
            withPosterCover={props.withPosterCover}
            captionSrc={props.captionSrc}
            onPlayStart={onPlayStart}
            onPlayEnd={onPlayEnd}
            onPause={onPause}
        />
    );
};

Video.defaultProps = {
    videoId: -1,
    src: "",
    type: "",
    poster: "",
    videoHeight: "auto",
    captionSrc: null,
    onPlayStart: () => { },
    onPlayEnd: () => { },
    onPause: () => { },
    canPlay: true,
    withPosterCover: true,
    isDefault: true,
};

export default Video;
