import React from "react";

import classNames from "juice-base/lib/class-names.js";

import styles from "./styles.module.css";


const Checkbox = (props) => {
    const inputClassName = classNames({
        [styles.input]: true,
        [styles.inputPurple]: props.theme === "purple",
        [styles.inputRose]: props.theme === "rose",
        [styles.inputGreenLight]: props.theme === "green-light",
        [styles.inputPumpkin]: props.theme === "pumpkin",
    });

    const labelClassName = classNames({
        [styles.label]: true,
        [styles.hasLabel]: props.label,
        [styles.withLabel2]: props.label2,
    });

    let label2 = null;

    if (props.label2) {
        label2 = (
            <div className={styles.label2}>
                {props.label2}
            </div>
        );
    }

    return (
        <div
            className={styles.field}
            data-comment={props.dataComment}
        >
            <input
                id={props.name}
                name={props.name}
                type="checkbox"
                className={inputClassName}
                checked={props.checked}
                disabled={props.disabled}
                onChange={props.onChange}
            />
            <label
                htmlFor={props.name}
                className={labelClassName}
            >
                <div>
                    {props.label}
                </div>
                {label2}
            </label>
        </div>
    );
};

Checkbox.defaultProps = {
    name: "",
    label: "",
    label2: "",

    theme: "",

    checked: false,
    disabled: false,
    dataComment: "",
    onChange: () => { },
};

export default Checkbox;
