import React from "react";

import Theme from "juice-base/project/theme.js";

import text from "juice-base/text/index.js";

import IconDisplaySettings from "juice-base/icons/display-settings/index.js";

import PopupWindow from "juice-base/components/popup-window/index.js";
import ThemeSelectors from "juice-base/components/theme-selectors/index.js";
import Checkbox from "juice-base/components/forms/checkbox/index.js";

import styles from "./styles.module.css";


const THEMES = Theme.getThemes();

const PopupThemeSettings = (props) => {
    const renderTitle = () => {
        return (
            <div className={styles.header}>
                <IconDisplaySettings
                    title="Settings"
                    isOrange
                />
                <div className={styles.headerTitle}>
                    Theme Settings
                </div>
            </div>
        );
    };

    const renderContent = () => {
        const { theme } = props;

        if (!theme) {
            return null;
        }

        return (
            <div className={styles.theme}>
                <div className={styles.themeAutoSwitcher}>
                    <Checkbox
                        name="isAutomatic"
                        label="Automatic"
                        label2={text.automaticThemeDescription}
                        checked={theme.isAutomatic}
                        onChange={(evt) => {
                            props.onAutoThemeChange(evt.target.checked);
                        }}
                    />
                </div>
                <div className={styles.themeSelectors}>
                    <ThemeSelectors
                        selected={theme.theme}
                        onChange={props.onThemeChange}
                        disableOutline={theme.isAutomatic}
                    />
                </div>
            </div>
        );
    };

    return (
        <PopupWindow
            title={renderTitle()}
            barClassName={styles.popupBar}
            onClose={props.onClose}
            withTitleBarShadow={false}
            isCentered
            isSmall
        >
            {renderContent()}
        </PopupWindow>
    );
};

PopupThemeSettings.defaultProps = {
    theme: {
        isAutomatic: true,
        theme: THEMES.LIGHT,
    },
    onAutoThemeChange: () => { },
    onThemeChange: () => { },
    onClose: () => { },
};

export default PopupThemeSettings;
