// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JnKtpBuF_Ju4IoabaSgg {\n    display: grid;\n    grid-gap: 3.5rem;\n\n    text-align: center;\n    justify-content: center;\n\n    max-width: 45rem;\n    margin: 2rem auto;\n}\n\n.N2lwbSBS_dcDI2BDUwoZ {\n    display: grid;\n    justify-content: center;\n}\n\n.lOH0NnV69sJYoRyd8XDL {\n    font-size: 1.5rem;\n    color: var(--color-5);\n}\n\n.KPUCJleZmt1JBVgMJ37p {\n    display: grid;\n    grid-gap: 2rem;\n}", "",{"version":3,"sources":["webpack://./base/business/message-referral-code/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,gBAAgB;;IAEhB,kBAAkB;IAClB,uBAAuB;;IAEvB,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,iBAAiB;IACjB,qBAAqB;AACzB;;AAEA;IACI,aAAa;IACb,cAAc;AAClB","sourcesContent":[".section {\n    display: grid;\n    grid-gap: 3.5rem;\n\n    text-align: center;\n    justify-content: center;\n\n    max-width: 45rem;\n    margin: 2rem auto;\n}\n\n.messageDanger {\n    display: grid;\n    justify-content: center;\n}\n\n.message {\n    font-size: 1.5rem;\n    color: var(--color-5);\n}\n\n.controls {\n    display: grid;\n    grid-gap: 2rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": "JnKtpBuF_Ju4IoabaSgg",
	"messageDanger": "N2lwbSBS_dcDI2BDUwoZ",
	"message": "lOH0NnV69sJYoRyd8XDL",
	"controls": "KPUCJleZmt1JBVgMJ37p"
};
export default ___CSS_LOADER_EXPORT___;
