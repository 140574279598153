import React from "react";

import ButtonFlat from "juice-base/components/button-flat/index.js";
import PopupConfirm, {
    PopupConfirmContent,
    PopupConfirmButtons,
    PopupConfirmTitle,
} from "juice-base/components/popup-confirm/index.js";


const PopupConfirmTeacherLimit = (props) => {
    const content = `
        We see you want to subscribe to a ${props.planName} plan.
        ${props.planName} plans allow ${props.maxClasses} class with up to ${props.maxStudents} students total.
        Your account is over these limits.
        Please make sure you have only ${props.maxClasses} class with ${props.maxStudents} students.
        Or, to keep your current roster, subscribe to a large class plan!
    `;

    return (
        <PopupConfirm
            closeOnLayoutClick
            hideScrollbar={props.hideScrollbar}
            onClose={props.onClose}
        >
            <PopupConfirmTitle>
                Please edit your classes.
            </PopupConfirmTitle>
            <PopupConfirmContent isGray>
                {content}
            </PopupConfirmContent>
            <PopupConfirmButtons vertical>
                <ButtonFlat onClick={props.onSubscribeToLargePlan}>
                    Subscribe to large class plan
                </ButtonFlat>
                <ButtonFlat onClick={props.onEditClasses}>
                    Edit classes
                </ButtonFlat>
            </PopupConfirmButtons>
        </PopupConfirm>
    );
};

PopupConfirmTeacherLimit.defaultProps = {
    planName: "",
    maxStudents: 0,
    maxClasses: 0,
    onSubscribeToLargePlan: () => {},
    onEditClasses: () => {},
    onClose: () => {},
};

export default PopupConfirmTeacherLimit;
