import { useState } from "react";


const useReferralCode = () => {
    const [state, setState] = useState({
        isLoaded: false,
        isActive: false,
        error: null,
    });

    const setCodeMissing = () => {
        setState({
            isLoaded: true,
            isActive: false,
            error: null,
        });
    };

    const setLoaded = (active, err) => {
        setState({
            isLoaded: true,
            isActive: active,
            error: err,
        });
    };

    return {
        state,
        setCodeMissing,
        setLoaded,
    };
};

export default useReferralCode;
