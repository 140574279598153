// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@keyframes m2XywSW0gjE_As9Li45v {\n    0% {\n        transform: rotate(0deg);\n    }\n\n    100% {\n        transform: rotate(360deg);\n    }\n}\n\n.FxXTrWTkKlL9NhqvSxae {\n    display: inline-block;\n\n    width: 2rem;\n    height: 2rem;\n\n    border: 0.5rem solid var(--loader-small-border-color);\n    border-radius: 50%;\n    border-top: 0.51rem solid var(--loader-small-border-top-color);\n\n    animation: m2XywSW0gjE_As9Li45v 1.5s linear infinite;\n}\n", "",{"version":3,"sources":["webpack://./base/components/loader-small/styles.module.css"],"names":[],"mappings":"AAAA;IACI;QACI,uBAAuB;IAC3B;;IAEA;QACI,yBAAyB;IAC7B;AACJ;;AAEA;IACI,qBAAqB;;IAErB,WAAW;IACX,YAAY;;IAEZ,qDAAqD;IACrD,kBAAkB;IAClB,8DAA8D;;IAE9D,oDAAoC;AACxC","sourcesContent":["@keyframes spin {\n    0% {\n        transform: rotate(0deg);\n    }\n\n    100% {\n        transform: rotate(360deg);\n    }\n}\n\n.loader {\n    display: inline-block;\n\n    width: 2rem;\n    height: 2rem;\n\n    border: 0.5rem solid var(--loader-small-border-color);\n    border-radius: 50%;\n    border-top: 0.51rem solid var(--loader-small-border-top-color);\n\n    animation: spin 1.5s linear infinite;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": "FxXTrWTkKlL9NhqvSxae",
	"spin": "m2XywSW0gjE_As9Li45v"
};
export default ___CSS_LOADER_EXPORT___;
