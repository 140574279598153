import { useState } from "react";


const getInitialState = () => ({
    code: "",
    error: "",
    className: "",
    isOpen: false,
    isLoading: false,
});

const useClassCodePopup = () => {
    const [state, setState] = useState(getInitialState());

    const open = (className) => {
        setState((prev) => ({
            ...prev,
            code: "",
            error: "",
            className,
            isOpen: true,
            isLoading: true,
        }));
    };

    const close = () => {
        setState(getInitialState());
    };

    const setCode = (code) => {
        setState((prev) => ({
            ...prev,
            code,
            error: "",
            isLoading: false,
        }));
    };

    const setError = (error) => {
        setState((prev) => ({
            ...prev,
            code: "",
            error,
            isLoading: false,
        }));
    };

    return {
        state,
        open,
        close,
        setCode,
        setError,
    };
};

export default useClassCodePopup;
