import React from "react";

import classNames from "juice-base/lib/class-names.js";

import IconProfileSettings from "juice-base/icons/profile-settings/index.js";
import IconSignOut from "juice-base/icons/sign-out/index.js";
import IconSupport from "juice-base/icons/support/index.js";
import IconDisplaySettings from "juice-base/icons/display-settings/index.js";

import ButtonBig from "juice-base/components/button-big/index.js";
import AvatarIcon from "juice-base/components/avatar-icon/index.js";
import ProfileRow from "juice-base/components/profile-row/index.js";

import dailyJuiceCommonStyles from "juice-base/business/daily-juice-common/styles.module.css";
import styles from "./styles.module.css";


const AvatarMenu = (props) => {
    const renderManageButton = () => {
        if (!props.isVisibleManageButton) {
            return null;
        }

        return (
            <ButtonBig
                outlined
                onClick={props.onManageAccount}
                icon={IconProfileSettings}
            >
                Manage Account
            </ButtonBig>
        );
    };

    const renderThemeSettingsButton = () => {
        return (
            <ButtonBig
                icon={IconDisplaySettings}
                onClick={props.onSettings}
                outlined
            >
                Theme Settings
            </ButtonBig>
        );
    };

    const renderEmail = () => {
        return (
            <ProfileRow
                value={props.data.email}
                onClickRightControl={props.onEditEmail}
                customRightControl={<div />}
                isEmail
            />
        );
    };

    const renderPassword = () => {
        if (props.data.isLmsUser) {
            return null;
        }

        return (
            <ProfileRow
                value="********"
                onClickRightControl={props.onEditPassword}
                isPassword
            />
        );
    };

    const renderFreeTrialRow = () => {
        if (!props.isVisibleFreeTrialRow) {
            return null;
        }

        const expireDate = props.data.daysLeftToExpire === 0
            ? "No expiration"
            : `${props.data.daysLeftToExpire} days left`;

        return (
            <ProfileRow
                value={expireDate}
                isExpiration
            />
        );
    };

    const avatarIcon = (
        <AvatarIcon
            name={props.data.name}
            avatarUrl={props.data.avatarUrl}
            disabled
        />
    );

    const menuClassName = classNames({
        [styles.menu]: true,
        [dailyJuiceCommonStyles.blockShadow]: true,
    });

    return (
        <div
            className={menuClassName}
            data-comment="avatar-menu"
        >
            <div className={styles.menuData}>
                <ProfileRow
                    customLeftControl={avatarIcon}
                    customName={props.data.fullname}
                    value={props.data.username}
                />
                {renderEmail()}
                {renderPassword()}
                {renderFreeTrialRow()}
            </div>
            <div className={styles.menuControls}>
                <ButtonBig
                    icon={IconSignOut}
                    onClick={props.onLogout}
                >
                    Log out
                </ButtonBig>

                {renderManageButton()}
                {renderThemeSettingsButton()}

                <ButtonBig
                    icon={IconSupport}
                    onClick={props.onContactSupport}
                    outlined
                >
                    Contact Support
                </ButtonBig>
            </div>
        </div>
    );
};

AvatarMenu.defaultProps = {
    data: {
        name: "A",
        username: "",
        avatarUrl: "",
        fullname: "",
        email: "",
        daysLeftToExpire: 0,
        isLmsUser: false,
    },
    isVisibleFreeTrialRow: false,
    isVisibleManageButton: true,
    onLogout: () => { },
    onEditPassword: () => { },
    onEditEmail: () => { },
    onManageAccount: () => { },
    onSettings: () => { },
    onContactSupport: () => { },
};

export default AvatarMenu;
