// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".q18g8A2cPc7mvBTCUnyp {\n    display: grid;\n    grid-gap: 2rem;\n\n    font-size: 1.8rem;\n\n    margin: 2rem;\n}\n\n.E3mimgATMWAXBz9dItVQ {\n    display: grid;\n    justify-content: center;\n    align-items: center;\n\n    width: 4.5rem;\n}\n", "",{"version":3,"sources":["webpack://./base/business/popup-tips/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,cAAc;;IAEd,iBAAiB;;IAEjB,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;;IAEnB,aAAa;AACjB","sourcesContent":[".content {\n    display: grid;\n    grid-gap: 2rem;\n\n    font-size: 1.8rem;\n\n    margin: 2rem;\n}\n\n.tipsIcon {\n    display: grid;\n    justify-content: center;\n    align-items: center;\n\n    width: 4.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "q18g8A2cPc7mvBTCUnyp",
	"tipsIcon": "E3mimgATMWAXBz9dItVQ"
};
export default ___CSS_LOADER_EXPORT___;
