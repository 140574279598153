import text from "juice-base/text/index.js";
import Urls from "juice-base/project/urls.js";


const loadAssignmentsScores = (services, params = {}) => {
    const { api, actions } = services;

    const forceUpdate = params.forceUpdate || false;

    return async (dispatch, getState) => {
        const state = getState();

        const classId = state.teacher.selectedClassId;

        if (!classId || classId === "-1") {
            return;
        }

        const { dateFrom, dateToOrLoadType } = state.navigation.params;

        const range = `${dateFrom}-${dateToOrLoadType}`;

        if (!dateFrom || !dateToOrLoadType) {
            return;
        }

        const isLoaded = state.teacher.classesScoresByAssignments?.[classId]?.[range]?.data;

        if (isLoaded && !forceUpdate) {
            return;
        }

        dispatch(actions.teacher.setTeacherClassScoresByAssignmentsLoading({
            range,
            classId,
        }));

        const res = await api.teacherClasses.getTeacherClassScoresByAssignments({
            session: state.user.session,
            classId,
            date: dateFrom,
            loadType: dateToOrLoadType,
        });

        let data = null;
        let error = "";
        let hasMoreBefore = false;
        let hasMoreAfter = false;

        if (res.ok) {
            data = res.data || null;
            hasMoreBefore = res.hasMoreBefore || false;
            hasMoreAfter = res.hasMoreAfter || false;
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.teacher.setTeacherClassScoresByAssignments({
            classId,
            range,
            data,
            error,
            hasMoreBefore,
            hasMoreAfter,
        }));
    };
};

const reloadAssignmentsScores = (services) => {
    const { api, actions } = services;

    return async (dispatch, getState) => {
        const state = getState();

        const { pathname } = state.navigation.location;

        // NOTE: hard fix
        if (!Urls.isClass(pathname)) {
            return;
        }

        const classId = state.teacher.selectedClassId;

        if (!classId || classId === "-1") {
            return;
        }

        const { dateFrom, dateToOrLoadType } = state.navigation.params;

        const range = `${dateFrom}-${dateToOrLoadType}`;

        if (!dateFrom || !dateToOrLoadType) {
            return;
        }

        const res = await api.teacherClasses.getTeacherClassScoresByAssignments({
            session: state.user.session,
            classId,
            date: dateFrom,
            loadType: dateToOrLoadType,
        });

        let data = null;
        let error = "";
        let hasMoreBefore = false;
        let hasMoreAfter = false;

        if (res.ok) {
            data = res.data || null;
            hasMoreBefore = res.hasMoreBefore || false;
            hasMoreAfter = res.hasMoreAfter || false;
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.teacher.updateTeacherClassScoresByAssignments({
            classId,
            range,
            data,
            error,
            hasMoreBefore,
            hasMoreAfter,
        }));
    };
};

const loadOrReloadAssignmentsScores = (services) => {
    return async (dispatch, getState) => {
        const state = getState();

        const classId = state.teacher.selectedClassId;
        const { dateFrom, dateToOrLoadType } = state.navigation.params;

        if (!classId || classId === "-1" || !dateFrom || !dateToOrLoadType) {
            return;
        }

        const range = `${dateFrom}-${dateToOrLoadType}`;

        const isLoaded = state.teacher.classesScoresByAssignments?.[classId]?.[range]?.data;

        if (isLoaded) {
            const reloadAction = reloadAssignmentsScores(services);
            reloadAction(dispatch, getState);
            return;
        }

        const loadAction = loadAssignmentsScores(services);
        loadAction(dispatch, getState);
    };
};

export default {
    loadAssignmentsScores,
    reloadAssignmentsScores,
    loadOrReloadAssignmentsScores,
};
