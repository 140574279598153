export default (apiURL, methods) => {
    return {
        getTeacherClassScoresByAssignments(params = {}) {
            // NOTE: action=get_teacher_class_last_assignments_by_date
            const url = `${apiURL}/teacher/classes/${params.classId}/scores/by-assignments`;

            return methods.get3(url, {
                session_id: params.session,
                date: params.date,
                load_type: params.loadType,
            });
        },

        getTeacherClassScoresByDailyJuices(params = {}) {
            // NOTE: action=get_teacher_class_students_daily_juices_scores
            const url = `${apiURL}/classes/${params.classId}/scores/by-daily-juices`;

            return methods.get3(url, {
                session_id: params.session,
                date_from: params.dateFrom,
                date_to: params.dateTo,
            });
        },
    };
};
