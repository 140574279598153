import React from "react";

import classNames from "juice-base/lib/class-names.js";

import styles from "./styles.module.css";


const ButtonRounded = (props) => {
    const buttonClassName = classNames({
        [styles.button]: true,
        [styles.buttonUppercase]: props.uppercase,
    });

    return (
        <div
            className={buttonClassName}
            onClick={props.onClick}
            onKeyPress={props.onClick}
            tabIndex="-1"
            role="button"
        >
            {props.children}
        </div>
    );
};

ButtonRounded.defaultProps = {
    children: null,

    uppercase: false,

    onClick: () => { },
};

export default ButtonRounded;
