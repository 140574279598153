import * as globalTypes from "juice-base/store-common/types.js";
import * as types from "./types.js";


const initialState = {
    tutorialsBySlug: {},
};

export default (state, action) => {
    switch (action.type) {
        case types.SET_TUTORIAL_BY_SLUG: {
            const { tutorialsBySlug } = state;
            const { slug, tutorial } = action.payload;

            return {
                ...state,
                tutorialsBySlug: {
                    ...tutorialsBySlug,
                    [slug]: tutorial,
                },
            };
        }

        /* --- */

        case globalTypes.CLEAR_AFTER_SIGNOUT: {
            return { ...initialState };
        }

        /* --- */

        default: {
            return state || { ...initialState };
        }
    }
};
