import * as types from "./types.js";


export const setCompletedAssignmentsByPageLoading = (payload) => ({
    type: types.SET_COMPLETED_ASSIGNMENTS_BY_PAGE_LOADING,
    payload,
});

export const setCompletedAssignmentsByPage = (payload) => ({
    type: types.SET_COMPLETED_ASSIGNMENTS_BY_PAGE,
    payload,
});

export const clearCompletedAssignments = (payload) => ({
    type: types.CLEAR_COMPLETED_ASSIGNMENTS_BY_PAGE,
    payload,
});

/* ---- */

export const setIncompleteAssignmentsByPageLoading = (payload) => ({
    type: types.SET_INCOMPLETE_ASSIGNMENTS_BY_PAGE_LOADING,
    payload,
});

export const setIncompleteAssignmentsByPage = (payload) => ({
    type: types.SET_INCOMPLETE_ASSIGNMENTS_BY_PAGE,
    payload,
});

export const clearIncompleteAssignments = (payload) => ({
    type: types.CLEAR_INCOMPLETE_ASSIGNMENTS,
    payload,
});

/* ---- */

export const setAssignmentsByPageLoading = (payload) => ({
    type: types.SET_ASSIGNMENTS_BY_PAGE_LOADING,
    payload,
});

export const setAssignmentsByPage = (payload) => ({
    type: types.SET_ASSIGNMENTS_BY_PAGE,
    payload,
});

export const clearAssignmentsByPage = (payload) => ({
    type: types.CLEAR_ASSIGNMENTS_BY_PAGE,
    payload,
});
