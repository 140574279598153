export default (apiURL, methods) => {
    return {
        vocabulary(params = {}) {
            const url = `${apiURL}/text2speech/vocabulary`;

            return methods.get3(url, {
                session_id: params.session,
                text: params.text,
            });
        },

        juice(params = {}) {
            const url = `${apiURL}/text2speech/juice`;

            const ps = {
                juice_id: params.juiceId,
                api_version: 2,
            };

            if (params.storyId) {
                ps.story_id = params.storyId;
            } else if (params.extraJuiceId) {
                ps.extra_juice_id = params.extraJuiceId;
            }

            return methods.get3(url, ps);
        },
    };
};
