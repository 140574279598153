import array from "juice-base/lib/array.js";


const setScoresUpdated = (oldScores, newScores) => {
    const result = [];

    const oldIds = [];

    for (let i = 0; i < oldScores.length; i += 1) {
        const score = oldScores[i];

        oldIds.push(score.id);

        let isUpdated = false;
        let { totalCorrect, totalAnswered } = score;

        const newScore = array.findOneById(newScores, score.id);

        if (newScore
            && (score.totalCorrect !== newScore.totalCorrect
                || score.totalAnswered !== newScore.totalAnswered)) {
            isUpdated = true;
            totalCorrect = newScore.totalCorrect;
            totalAnswered = newScore.totalAnswered;
        }

        result.push({
            ...score,
            totalCorrect,
            totalAnswered,
            isUpdated,
        });
    }

    for (let i = 0; i < newScores.length; i += 1) {
        const score = newScores[i];

        if (oldIds.indexOf(score.id) === -1) {
            result.push({
                ...score,
                isUpdated: true,
            });
        }
    }

    return result;
};

const unsetScoresUpdated = (oldScores, newScores) => {
    return oldScores.map((score) => {
        let isUpdated = false;

        const newScore = array.findOneById(newScores, score.id);

        if (newScore) {
            isUpdated = false;
        }

        return {
            ...score,
            isUpdated,
        };
    });
};

const updateStudentsScores = (oldStudents, newStudents) => {
    const result = [];

    const newIds = newStudents.map((student) => student.id);
    const oldIds = [];

    for (let i = 0; i < oldStudents.length; i += 1) {
        const student = oldStudents[i];

        if (newIds.indexOf(student.id) === -1) {
            continue;
        }

        oldIds.push(student.id);

        const scores = student.quizzesScores || [];

        const newStudent = array.findOneById(newStudents, student.id);
        const newStudentScores = newStudent?.quizzesScores || [];

        const newScores = setScoresUpdated(scores, newStudentScores);

        result.push({
            ...student,
            quizzesScores: newScores,
        });
    }

    for (let i = 0; i < newStudents.length; i += 1) {
        const student = newStudents[i];

        if (oldIds.indexOf(student.id) === -1) {
            result.push({
                ...student,
                isUpdated: true,
            });
        }
    }

    return result;
};

const clearStudentsScoresUpdatedStatus = (oldStudents, newStudents) => {
    return oldStudents.map((student) => {
        const scores = student.quizzesScores || [];

        const newStudent = array.findOneById(newStudents, student.id);
        const newStudentScores = newStudent?.quizzesScores || [];

        const newScores = unsetScoresUpdated(scores, newStudentScores);

        return {
            ...student,
            isUpdated: false,
            quizzesScores: newScores,
        };
    });
};

export default {
    updateStudentsScores,
    clearStudentsScoresUpdatedStatus,
};
