import React, { useState, useEffect, useLayoutEffect } from "react";
import { useSelector } from "react-redux";

import LoaderSmall from "juice-base/components/loader-small/index.js";
import ButtonBig from "juice-base/components/button-big/index.js";
import ButtonFlat from "juice-base/components/button-flat/index.js";
import PopupWindow from "juice-base/components/popup-window/index.js";
import PopupConfirm, {
    PopupConfirmContent,
    PopupConfirmButtons,
} from "juice-base/components/popup-confirm/index.js";

import PopupConfirmStudentDuplicate from "juice-base/business/popup-confirm-student-duplicate/index.js";

import api from "juice-app/api.js";

import styles from "./styles.module.css";


const storeSelector = (state) => ({
    session: state.user.session,
});

const getDuplicateState = () => ({
    isVisiblePopup: false,
    isFullnameDuplicate: false,
    isEmailDuplicate: false,
    studentId: null,
    student: null,
});

const TeacherPopupStudentsAdd = (props) => {
    const [duplicateState, setDuplicateState] = useState(() => getDuplicateState());

    const [importStatus, setImportStatus] = useState({
        isImporting: false,
        isFinished: false,
        importedCount: 0,
        statuses: [],
    });

    const [isVisibleNotificationPopup, setIsVisibleNotificationPopup] = useState(false);

    /* --------- */

    const store = useSelector(storeSelector);

    /* --------- */

    const onAddStudent = async (index, student, addNewOnDuplicate) => {
        const selectedGrade = student.gradeValue.split("-")[1];

        const res = await api.classes.addStudentV2({
            session: store.session,
            classId: props.classId,
            firstname: student.nameValue,
            lastname: student.lastNameValue,
            email: student.emailValue,
            grade: selectedGrade,
            addNewOnDuplicate,
        });

        const status = {
            id: index,
            ok: res.ok || false,
        };

        if (res.error) {
            const isFullnameDuplicate = res?.details?.isFullnameDuplicate || false;
            const isEmailDuplicate = res?.details?.isEmailDuplicate || false;

            if (isFullnameDuplicate || isEmailDuplicate) {
                setDuplicateState({
                    isVisiblePopup: true,
                    isFullnameDuplicate,
                    isEmailDuplicate,
                    studentId: index,
                    student,
                });
                return;
            }
        }

        const importedCount = importStatus.importedCount + 1;
        const isFinished = props.students.length === importedCount;

        setImportStatus((prev) => ({
            ...prev,
            isFinished,
            importedCount,
            statuses: [...prev.statuses].concat(status),
        }));
    };

    /* --------- */

    const onCloseDuplicatePopup = () => {
        setDuplicateState(getDuplicateState());
    };

    const onClose = () => {
        props.onClose();

        if (importStatus.isFinished) {
            props.onLoadClass();
        }
    };

    const onSkipDuplicate = () => {
        const importedCount = importStatus.importedCount + 1;
        const isFinished = props.students.length === importedCount;

        setImportStatus((prev) => ({
            ...prev,
            isFinished,
            importedCount,
            statuses: [...prev.statuses].concat({
                id: duplicateState.studentId,
                ok: true,
                isSkipped: true,
            }),
        }));

        onCloseDuplicatePopup();
    };

    const onStartImportProcess = () => {
        if (importStatus.isImporting) {
            return;
        }

        const firstStudent = props.students?.[0] || null;

        if (!firstStudent) {
            return;
        }

        setImportStatus((prev) => ({
            ...prev,
            isImporting: true,
        }));

        onAddStudent(0, firstStudent, false);
    };

    /* --------- */

    useLayoutEffect(() => {
        if (!importStatus.isImporting) {
            return;
        }

        const { importedCount } = importStatus;

        if (importedCount < props.students.length) {
            onAddStudent(importedCount, props.students[importedCount], false);
        }
    }, [importStatus.importedCount]);

    useEffect(() => {
        if (!importStatus.isFinished) {
            return;
        }

        setIsVisibleNotificationPopup(true);
    }, [importStatus.isFinished]);

    /* --------- */

    const renderNotificationPopup = () => {
        if (!isVisibleNotificationPopup) {
            return null;
        }

        let addedCount = 0;

        for (let i = 0; i < importStatus.statuses.length; i += 1) {
            const s = importStatus.statuses[i];

            if (s.ok && !s.isSkipped) {
                addedCount += 1;
            }
        }

        const contentMessage = addedCount > 0
            ? `Nice! You just added ${addedCount} students. Head back to the class page to see your roster.`
            : "No students added.";

        return (
            <PopupConfirm>
                <PopupConfirmContent>
                    {contentMessage}
                </PopupConfirmContent>
                <PopupConfirmButtons>
                    <ButtonFlat
                        onClick={() => {
                            setIsVisibleNotificationPopup(false);
                        }}
                    >
                        Close
                    </ButtonFlat>
                </PopupConfirmButtons>
            </PopupConfirm>
        );
    };

    const renderPopupDuplicate = () => {
        const {
            isEmailDuplicate,
            isFullnameDuplicate,
            studentId,
            student,
        } = duplicateState;

        if (!duplicateState.isVisiblePopup) {
            return null;
        }

        return (
            <PopupConfirmStudentDuplicate
                errorCode={null}
                isEmailDuplicate={isEmailDuplicate}
                isFullnameDuplicate={isFullnameDuplicate}
                user={student}
                onAddDuplicate={() => {
                    onAddStudent(studentId, student, true);
                    onCloseDuplicatePopup();
                }}
                onSkipDuplicate={onSkipDuplicate}
            />
        );
    };

    const renderContent = () => {
        const students = props.students.map((s, index) => {
            let status = null;

            if (importStatus.isImporting) {
                for (let i = 0; i < importStatus.statuses.length; i += 1) {
                    const st = importStatus.statuses[i];

                    if (st.id === index) {
                        status = st.ok
                            ? "Added!"
                            : "Failed!";

                        if (st.isSkipped) {
                            status = "Skipped!";
                        }
                        break;
                    }
                }

                if (!status) {
                    status = (
                        <LoaderSmall />
                    );
                }
            }

            return (
                <div className={styles.studentRow}>
                    <div>
                        {`${s.nameValue} ${s.lastNameValue}`}
                    </div>
                    <div>
                        {status}
                    </div>
                </div>
            );
        });

        let controls = null;

        if (importStatus.isFinished) {
            controls = (
                <ButtonBig
                    onClick={onClose}
                >
                    Close
                </ButtonBig>
            );
        } else {
            controls = (
                <ButtonBig
                    disabled={importStatus.isImporting}
                    onClick={onStartImportProcess}
                >
                    {props.students.length === 1
                        ? "Add student"
                        : "Add students"}
                </ButtonBig>
            );
        }

        return (
            <div className={styles.content}>
                <div className={styles.studentsList}>
                    {students}
                </div>
                {controls}
            </div>
        );
    };

    return (
        <>
            <PopupWindow
                title={props.students.length === 1 ? "Add Student" : "Add Students"}
                onClose={onClose}
                hideClose={importStatus.isImporting && !importStatus.isFinished}
                hideScrollbar={props.hideScrollbar}
                isCentered
                isSmall
            >
                {renderContent()}
            </PopupWindow>

            {renderPopupDuplicate()}
            {renderNotificationPopup()}
        </>
    );
};

TeacherPopupStudentsAdd.defaultProps = {
    classId: null,
    students: [],

    onLoadClass: () => { },
    onClose: () => { },

    hideScrollbar: false,
};

export default TeacherPopupStudentsAdd;
