import React, { useState } from "react";

import Assignments from "juice-base/project/assignments.js";
import Grades from "juice-base/project/grades.js";
import Standards from "juice-base/project/standards.js";

import classNames from "juice-base/lib/class-names.js";
import date from "juice-base/lib/date.js";

import text from "juice-base/text/index.js";

import IconDownload from "juice-base/icons/download/index.js";
import IconMenuFurl from "juice-base/icons/menu-furl/index.js";
import IconMenuUnfurl from "juice-base/icons/menu-unfurl/index.js";

import RequestLoader from "juice-base/components/request-loader/index.js";
import DLCustom from "juice-base/components/dl-custom/index.js";
import Message from "juice-base/components/message/index.js";
import ButtonCircle from "juice-base/components/button-circle/index.js";
import ButtonFlat from "juice-base/components/button-flat/index.js";
import ButtonFAB, {
    ButtonFabPosition,
} from "juice-base/components/button-fab/index.js";

import ColumnNameWithSort from "./column-name-with-sort.js";
import styles from "./styles.module.css";


const AssignmentsTable = (props) => {
    const [sortBy, setSortBy] = useState(null);

    const [expandedRows, setExpandedRows] = useState([]);

    /* ----- */

    const sortValues = Assignments.getSortValues();

    /* ----- */

    const getStandardValue = (data) => {
        const hasStateStandards = Standards.hasStateStandard(data.standards);

        const standardValue = Standards.getDefaultStandardValue(hasStateStandards);
        const standard = Standards.getStandardsByType(data.standards, standardValue);

        return standard?.[0]?.standard || "";
    };

    /* ----- */

    const onToggleRow = (index) => {
        setExpandedRows((prev) => {
            if (prev.indexOf(index) === -1) {
                return [...prev].concat(index);
            }

            return [...prev].filter((i) => i !== index);
        });
    };

    const onToggleAllRows = () => {
        if (expandedRows.length > 0) {
            setExpandedRows([]);
            return;
        }

        const indexes = [];

        for (let i = 0; i < props.data.length; i += 1) {
            indexes.push(i);
        }

        setExpandedRows(indexes);
    };

    const onOpenStandards = (standardId, standards) => {
        props.onOpenStandards(standardId, standards);
    };

    const onOpenQuiz = (quiz) => {
        props.onOpenQuiz(quiz);
    };

    const onOpenStory = (storyId) => {
        props.onOpenStory(storyId);
    };

    const onExport = () => {
        props.onExport();
    };

    const onSortChange = (value) => {
        setSortBy(value);
    };

    const onSortByDate = () => {
        props.onSortByDate();

        if (sortBy === sortValues.dateNewestToOldest) {
            onSortChange(sortValues.dateOldestToNewest);
            return;
        }

        onSortChange(sortValues.dateNewestToOldest);
    };

    const onSortByScore = () => {
        props.onSortByScore();

        if (sortBy === sortValues.scoreHighToLow) {
            onSortChange(sortValues.scoreLowToHigh);
            return;
        }

        onSortChange(sortValues.scoreHighToLow);
    };

    const onSortByGrade = () => {
        props.onSortByGrade();

        if (sortBy === sortValues.gradeHighToLow) {
            onSortChange(sortValues.gradeLowToHigh);
            return;
        }

        onSortChange(sortValues.gradeHighToLow);
    };

    const onSortByStandard = () => {
        props.onSortByStandard();

        if (sortBy === sortValues.standardHighToLow) {
            onSortChange(sortValues.standardLowToHigh);
            return;
        }

        onSortChange(sortValues.standardHighToLow);
    };

    /* ----- */

    const renderButtonsFAB = () => {
        if (props.isLoading) {
            return null;
        }

        const downloadIcon = (
            <IconDownload
                isSky
            />
        );

        let iconToggle = null;

        if (props.data.length === expandedRows.length) {
            iconToggle = (
                <IconMenuFurl
                    isWhite
                />
            );
        } else {
            iconToggle = (
                <IconMenuUnfurl
                    isWhite
                />
            );
        }

        return (
            <>
                <ButtonFabPosition
                    placeUpper
                    alignRightByVW={props.alignButtonFABByVW}
                >
                    <ButtonFAB
                        icon={downloadIcon}
                        onClick={onExport}
                        whiteTheme
                    />
                </ButtonFabPosition>
                <ButtonFabPosition
                    alignRightByVW={props.alignButtonFABByVW}
                >
                    <ButtonFAB
                        icon={iconToggle}
                        onClick={onToggleAllRows}
                    />
                </ButtonFabPosition>
            </>
        );
    };

    const renderExportButton = () => {
        const icon = (
            <IconDownload isSky />
        );

        return (
            <div className={styles.exportButton}>
                <ButtonCircle
                    icon={icon}
                    alt="Download"
                    onClick={onExport}
                />
            </div>
        );
    };

    const renderColumnNameWithSort = (params) => {
        const { name, onClick, isSorted } = params;

        return (
            <ColumnNameWithSort
                inSorted={isSorted}
                name={name}
                onClick={onClick}
            />
        );
    };

    const renderHeaderDesktop = () => {
        const columns = [
            {
                name: "Date",
                isSorted: sortBy === sortValues.dateNewestToOldest,
                onClick: onSortByDate,
            },
            {
                name: "Grade",
                isSorted: sortBy === sortValues.scoreHighToLow,
                onClick: onSortByScore,
            },
            { name: "Article" },
            { name: "Question" },
            {
                name: "Reading Level",
                isSorted: sortBy === sortValues.gradeHighToLow,
                onClick: onSortByGrade,
            },
            {
                name: "Standard",
                isSorted: sortBy === sortValues.standardHighToLow,
                onClick: onSortByStandard,
            },
            {
                customElement: renderExportButton(),
            },
        ];

        const columnsDesktop = columns.map((column) => {
            if (column.customElement) {
                return column.customElement;
            }

            if (column.onClick) {
                return renderColumnNameWithSort(column);
            }

            const columnClassName = classNames({
                [styles.columnName]: true,
                [column.className]: column.className,
            });

            return (
                <div className={columnClassName}>
                    {column.name}
                </div>
            );
        });

        return (
            <div className={styles.rowDesktopHeader}>
                {columnsDesktop}
            </div>
        );
    };

    const renderEllipsisRowColumn = (params) => {
        const rowClassName = classNames({
            [styles.rowColumn]: true,
            [styles.rowColumnPointer]: params.onClick,
            [params.className]: params.className,
        });

        return (
            <div
                className={rowClassName}
                onClick={params.onClick}
                onKeyPress={params.onClick}
                tabIndex="-1"
                role="button"
            >
                {params.text}
            </div>
        );
    };

    const renderGradeScore = (score) => {
        if (score === -1) {
            return "-";
        }

        return `${score}%`;
    };

    const renderRows = () => {
        const sortedData = Assignments.sort(props.data, sortBy);

        const rows = sortedData.map((row) => {
            const dateFormatted = date.formatMonthDayYear(date.newDateUTC(row.date));

            const grades = Grades.getGradeGroup(row.grades);

            const standardsId = row?.quiz?.standardId || null;
            const standards = row?.quiz?.standards || [];

            return (
                <div className={styles.row}>
                    <div className={styles.rowColumn}>
                        {dateFormatted}
                    </div>
                    <div className={styles.rowColumn}>
                        {renderGradeScore(row.score)}
                    </div>
                    {renderEllipsisRowColumn({
                        text: row.storyName,
                        onClick: () => onOpenStory(row.storyId),
                    })}
                    {renderEllipsisRowColumn({
                        text: row.storyQuestion,
                        onClick: () => onOpenQuiz(row.quiz),
                    })}
                    <div className={styles.rowColumn}>
                        {grades}
                    </div>
                    {renderEllipsisRowColumn({
                        text: getStandardValue(row),
                        onClick: () => onOpenStandards(standardsId, standards),
                    })}
                </div>
            );
        });

        return rows;
    };

    const renderLoadMoreButton = () => {
        if (props.isLoading) {
            return (
                <RequestLoader />
            );
        }

        if (!props.isVisibleLoadMoreButton) {
            return null;
        }

        return (
            <div className={styles.loadMoreButton}>
                <ButtonFlat
                    uppercase
                    onClick={props.onLoadMore}
                >
                    Load more
                </ButtonFlat>
            </div>
        );
    };

    /* ----- */

    const renderMobileTable = () => {
        const rows = props.data.map((d, index) => {
            const grades = Grades.getGradeGroup(d.grades);

            const standardsId = d?.quiz?.standardId || null;
            const standards = d?.quiz?.standards || [];

            const values = [
                { label: "Grade", value: renderGradeScore(d.score) },
                {
                    label: "Article",
                    value: renderEllipsisRowColumn({
                        className: styles.rowColumnMobile,
                        text: d.storyName,
                        onClick: () => onOpenStory(d.storyId),
                    }),
                },
                {
                    label: "Question",
                    value: renderEllipsisRowColumn({
                        className: styles.rowColumnMobile,
                        text: d.storyQuestion,
                        onClick: () => onOpenQuiz(d.quiz),
                    }),
                },
                { label: "Reading Level", value: grades },
                {
                    label: "Standard",
                    value: renderEllipsisRowColumn({
                        text: getStandardValue(d),
                        onClick: () => onOpenStandards(standardsId, standards),
                    }),
                },
            ];

            const dateFormatted = date.formatMonthDayYear(date.newDateUTC(d.date));

            return (
                <div className={styles.rowMobile}>
                    <DLCustom
                        title={dateFormatted}
                        values={values}
                        isExpanded={expandedRows.indexOf(index) !== -1}
                        onToggle={() => {
                            onToggleRow(index);
                        }}
                    />
                </div>
            );
        });

        return (
            <>
                {rows}
                {renderLoadMoreButton()}

                {renderButtonsFAB()}
            </>
        );
    };

    const renderDesktopTable = () => {
        return (
            <>
                {renderHeaderDesktop()}
                {renderRows()}
                {renderLoadMoreButton()}
            </>
        );
    };

    /* ----- */

    if (props.data.length === 0 && !props.isLoading) {
        return (
            <Message>
                {text.noAssignments}
            </Message>
        );
    }

    if (props.isMobile) {
        return renderMobileTable();
    }

    return renderDesktopTable();
};

AssignmentsTable.defaultProps = {
    data: [],

    onSortByStandard: () => { },
    onSortByGrade: () => { },
    onSortByScore: () => { },
    onSortByDate: () => { },

    onLoadMore: () => { },
    onOpenStory: () => { },
    onOpenStandards: () => { },
    onOpenQuiz: () => { },
    onExport: () => { },

    isLoading: false,
    isMobile: false,
    isVisibleLoadMoreButton: false,
    alignButtonFABByVW: false,
};

export default AssignmentsTable;
