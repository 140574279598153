// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kdhWDLM1YkLOeMorlj2i {\n    position: fixed;\n    top: var(--main-header-height);\n    left: 2rem;\n    right: 2rem;\n\n    z-index: 200;\n\n    display: grid;\n\n    background: var(--header-desktop-bg-color);\n    border: 0.1rem solid var(--border-default-color);\n}\n\n.S3nfVklr3L5SkVjxCjgU,\n.XpcGKJbSwwYqzJ9saZkS {\n    padding: 2.5rem 2rem;\n}\n\n.S3nfVklr3L5SkVjxCjgU {\n    display: grid;\n}\n\n.XpcGKJbSwwYqzJ9saZkS {\n    display: grid;\n    grid-gap: 1.5rem;\n\n    border-top: 0.1rem solid var(--grey-light);\n}\n\n/* --- */\n\n@media only screen and (min-width: 601px) {\n    .kdhWDLM1YkLOeMorlj2i {\n        top: var(--main-header-height);\n        left: initial;\n        right: 2rem;\n\n        width: 40rem;\n    }\n}\n", "",{"version":3,"sources":["webpack://./base/components/avatar-menu/styles.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,8BAA8B;IAC9B,UAAU;IACV,WAAW;;IAEX,YAAY;;IAEZ,aAAa;;IAEb,0CAA0C;IAC1C,gDAAgD;AACpD;;AAEA;;IAEI,oBAAoB;AACxB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,gBAAgB;;IAEhB,0CAA0C;AAC9C;;AAEA,QAAQ;;AAER;IACI;QACI,8BAA8B;QAC9B,aAAa;QACb,WAAW;;QAEX,YAAY;IAChB;AACJ","sourcesContent":[".menu {\n    position: fixed;\n    top: var(--main-header-height);\n    left: 2rem;\n    right: 2rem;\n\n    z-index: 200;\n\n    display: grid;\n\n    background: var(--header-desktop-bg-color);\n    border: 0.1rem solid var(--border-default-color);\n}\n\n.menuData,\n.menuControls {\n    padding: 2.5rem 2rem;\n}\n\n.menuData {\n    display: grid;\n}\n\n.menuControls {\n    display: grid;\n    grid-gap: 1.5rem;\n\n    border-top: 0.1rem solid var(--grey-light);\n}\n\n/* --- */\n\n@media only screen and (min-width: 601px) {\n    .menu {\n        top: var(--main-header-height);\n        left: initial;\n        right: 2rem;\n\n        width: 40rem;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menu": "kdhWDLM1YkLOeMorlj2i",
	"menuData": "S3nfVklr3L5SkVjxCjgU",
	"menuControls": "XpcGKJbSwwYqzJ9saZkS"
};
export default ___CSS_LOADER_EXPORT___;
