// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.zHlV1ma_2OJnT_BXCQJZ {\n    display: grid;\n    grid-gap: 2rem;\n\n    margin: 2rem;\n}\n\n/* ---- */\n\n.BiE7KSKHsc0mwKiWHdtx {\n    display: grid\n}\n\n.BiE7KSKHsc0mwKiWHdtx img {\n    display: block;\n\n    margin-left: auto;\n    margin-right: auto;\n    max-width: 100%;\n\n    border-radius: 1rem;\n}\n\n/* ---- */\n\n.H8GNkHIfYr5FcqbrdQq0 {\n    display: grid;\n    grid-gap: 1rem;\n}", "",{"version":3,"sources":["webpack://./base/business/teacher-quick-links/styles.module.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,cAAc;;IAEd,YAAY;AAChB;;AAEA,SAAS;;AAET;IACI;AACJ;;AAEA;IACI,cAAc;;IAEd,iBAAiB;IACjB,kBAAkB;IAClB,eAAe;;IAEf,mBAAmB;AACvB;;AAEA,SAAS;;AAET;IACI,aAAa;IACb,cAAc;AAClB","sourcesContent":["\n.content {\n    display: grid;\n    grid-gap: 2rem;\n\n    margin: 2rem;\n}\n\n/* ---- */\n\n.sponsorImage {\n    display: grid\n}\n\n.sponsorImage img {\n    display: block;\n\n    margin-left: auto;\n    margin-right: auto;\n    max-width: 100%;\n\n    border-radius: 1rem;\n}\n\n/* ---- */\n\n.quickLinks {\n    display: grid;\n    grid-gap: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "zHlV1ma_2OJnT_BXCQJZ",
	"sponsorImage": "BiE7KSKHsc0mwKiWHdtx",
	"quickLinks": "H8GNkHIfYr5FcqbrdQq0"
};
export default ___CSS_LOADER_EXPORT___;
