import React from "react";

import styles from "./styles.module.css";


const SubscribePlanCardButton = (props) => {
    const buttonStyle = {};

    if (props.color) {
        buttonStyle.color = props.color;
        buttonStyle.borderColor = props.color;
    }

    return (
        <div
            className={styles.pickButton}
            style={buttonStyle}
            onClick={props.onClick}
            onKeyPress={props.onClick}
            role="button"
            tabIndex="-1"
        >
            {props.children}
        </div>
    );
};

SubscribePlanCardButton.defaultProps = {
    color: "",
    children: null,
};

export default SubscribePlanCardButton;
