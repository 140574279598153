import React from "react";

import date from "juice-base/lib/date.js";

import text from "juice-base/text/index.js";

import IconScoresVertical from "juice-base/icons/scores-vertical/index.js";

import SectionStudentV2 from "juice-base/business/section-student-v2/index.js";
import ChartBar from "juice-base/components/chart-bar/index.js";
import ButtonRounded from "juice-base/components/button-rounded/index.js";

import styles from "./styles.module.css";


const DailyJuiceQuizResults = (props) => {
    const results = props.quizResults.map((result) => {
        const groupData = result.data.map((d) => {
            let showValue = false;

            if (d.isJuiceCompleted || d.isCompletedJuiceExist) {
                showValue = true;
            }

            return {
                date: date.newDate(d.date),
                value: d.value,
                showValue,
            };
        });

        return {
            name: result.name,
            dates: {
                from: date.newDate(result.dates.from),
                to: date.newDate(result.dates.to),
            },
            data: groupData,
        };
    });

    return (
        <SectionStudentV2
            title="Quiz results"
            titleBottomBorder={false}
            icon={<IconScoresVertical isOrange />}
        >
            <div className={styles.results}>
                <ChartBar
                    dataGroups={results}
                    noDataText={text.noQuizResults}
                    width={props.isMobile ? 270 : 350}
                />
            </div>
            <div className={styles.viewMoreButton}>
                <ButtonRounded
                    uppercase
                    onClick={props.onViewMore}
                >
                    View More
                </ButtonRounded>
            </div>
        </SectionStudentV2>
    );
};

DailyJuiceQuizResults.defaultProps = {
    quizResults: [],
    isMobile: false,
    onViewMore: () => { },
};

export default DailyJuiceQuizResults;
