import { useState } from "react";

import Students from "juice-base/project/students.js";
import DailyJuice from "juice-base/project/daily-juice.js";


const useTeacherClassTable = () => {
    const [state, setState] = useState(() => ({
        sortBy: Students.getDefaultSort(),
        view: DailyJuice.getDailyJuicesTypes()[0].value,
    }));

    const sortByName = () => {
        setState((prev) => ({
            ...prev,
            sortBy: Students.getSortValueBySort(prev.sortBy),
        }));
    };

    const setView = (view) => {
        setState((prev) => ({
            ...prev,
            view,
        }));
    };

    return {
        state,
        sortByName,
        setView,
    };
};


export default useTeacherClassTable;
