import React from "react";

import IconFeedback from "juice-base/icons/feedback/index.js";
import IconEmojiHappy from "juice-base/icons/emoji-happy/index.js";
import IconEmojiSad from "juice-base/icons/emoji-sad/index.js";

import PopupWindow from "juice-base/components/popup-window/index.js";

import styles from "./styles.module.css";


const PopupEmoji = (props) => {
    const renderLeftControl = () => {
        const icon = props.isCorrect
            ? <IconFeedback title="Quiz Feedback" isGreen />
            : <IconFeedback title="Quiz Feedback" isRed />;

        return (
            <div className={styles.feedbackIcon}>
                {icon}
            </div>
        );
    };

    const renderEmoji = () => {
        if (props.isCorrect) {
            return (
                <IconEmojiHappy
                    className={styles.emojiImage}
                    title="Emoji Happy"
                    isBlack
                />
            );
        }

        return (
            <IconEmojiSad
                className={styles.emojiImage}
                title="Emoji Sad"
                isBlack
            />
        );
    };

    const renderMessage = () => {
        let message = null;

        if (props.isCorrect) {
            message = (
                <div className={styles.emojiMessage}>
                    <div className={styles.emojiMessageTitle}>
                        Correct!
                    </div>
                    <div className={styles.emojiMessageText}>
                        <div>Have you been studying?</div>
                        <div>You&apos;re doing awesome!</div>
                    </div>
                </div>
            );
        } else {
            message = (
                <div className={styles.emojiMessage}>
                    <div className={styles.emojiMessageTitle}>
                        Incorrect
                    </div>
                    <div className={styles.emojiMessageText}>
                        <div>Keep at it!</div>
                        <div>You&apos;ll get the next one.</div>
                    </div>
                </div>
            );
        }

        return message;
    };

    return (
        <PopupWindow
            title="Quiz Feedback"
            onClose={props.onClose}
            leftControl={renderLeftControl()}
            isSmall
            isCentered
            titleToStart
            withTitleBarShadow={false}
            autoClose
        >
            <div className={styles.content}>
                {renderEmoji()}
                {renderMessage()}
            </div>
        </PopupWindow>
    );
};

PopupEmoji.defaultProps = {
    isCorrect: true,
    onClose: () => { },
};

export default PopupEmoji;
