// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".nCUOTjADtXqSLylkLHUr {\n    display: grid;\n    padding: 3rem 3rem 0 3rem;\n}\n\n.gcsLNwQ37K1DZnra7imm {\n    display: grid;\n    grid-auto-flow: column;\n    grid-gap: 2rem;\n}\n\n.G4Sff6DIktg2aJGWCPjy {\n    display: grid;\n\n    padding: 3rem;\n\n    border-top: 0.1rem solid var(--border-default-color);\n}\n\n.XF3bG0z8DuI_JRpzAbUH {\n    padding: 0 3rem 3rem;\n}\n", "",{"version":3,"sources":["webpack://./base/forms/teacher-add/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,cAAc;AAClB;;AAEA;IACI,aAAa;;IAEb,aAAa;;IAEb,oDAAoD;AACxD;;AAEA;IACI,oBAAoB;AACxB","sourcesContent":[".formContent {\n    display: grid;\n    padding: 3rem 3rem 0 3rem;\n}\n\n.formRow {\n    display: grid;\n    grid-auto-flow: column;\n    grid-gap: 2rem;\n}\n\n.submitButton {\n    display: grid;\n\n    padding: 3rem;\n\n    border-top: 0.1rem solid var(--border-default-color);\n}\n\n.alertBox {\n    padding: 0 3rem 3rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formContent": "nCUOTjADtXqSLylkLHUr",
	"formRow": "gcsLNwQ37K1DZnra7imm",
	"submitButton": "G4Sff6DIktg2aJGWCPjy",
	"alertBox": "XF3bG0z8DuI_JRpzAbUH"
};
export default ___CSS_LOADER_EXPORT___;
