// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bdFTVO1djsSAcHhA5tKa {\n    width: 1rem;\n    height: 1rem;\n    border-radius: 1rem;\n}\n\n.M9IYi9f8QGfPj8mtIAxt {\n    background-color: var(--grade-5-6-color);\n}\n\n.FkVFfUnyKbU595QeYVAg {\n    background-color: var(--grade-7-8-color);\n}\n\n.o0o61Rr975sWoyGJsJYE {\n    background-color: var(--grade-9-10-color);\n}\n\n.UO_iKwjX2fznrAzqok7Y {\n    background-color: var(--grade-11-12-color);\n}\n", "",{"version":3,"sources":["webpack://./base/business/grades-indicator/styles.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,wCAAwC;AAC5C;;AAEA;IACI,wCAAwC;AAC5C;;AAEA;IACI,yCAAyC;AAC7C;;AAEA;IACI,0CAA0C;AAC9C","sourcesContent":[".indicator {\n    width: 1rem;\n    height: 1rem;\n    border-radius: 1rem;\n}\n\n.indicatorG5G6 {\n    background-color: var(--grade-5-6-color);\n}\n\n.indicatorG7G8 {\n    background-color: var(--grade-7-8-color);\n}\n\n.indicatorG9G10 {\n    background-color: var(--grade-9-10-color);\n}\n\n.indicatorG11G12 {\n    background-color: var(--grade-11-12-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"indicator": "bdFTVO1djsSAcHhA5tKa",
	"indicatorG5G6": "M9IYi9f8QGfPj8mtIAxt",
	"indicatorG7G8": "FkVFfUnyKbU595QeYVAg",
	"indicatorG9G10": "o0o61Rr975sWoyGJsJYE",
	"indicatorG11G12": "UO_iKwjX2fznrAzqok7Y"
};
export default ___CSS_LOADER_EXPORT___;
