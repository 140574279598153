import React from "react";

import styles from "./styles.module.css";


const ButtonExtraJuice = (props) => {
    return (
        <div
            className={styles.button}
            onClick={props.onClick}
            onKeyPress={props.onClick}
            role="button"
            tabIndex="-1"
        >
            {props.children}
        </div>
    );
};

ButtonExtraJuice.defaultProps = {
    children: null,
    onClick: () => { },
};

export default ButtonExtraJuice;
