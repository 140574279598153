import React, { useState } from "react";

import classNames from "juice-base/lib/class-names.js";

import styles from "./styles.module.css";


const ButtonBig = (props) => {
    const [animation, setAnimation] = useState({
        x: -1,
        y: -1,
        size: 0,
    });

    const onClick = (evt) => {
        setAnimation((prev) => ({
            ...prev,
            x: -1,
            y: -1,
            size: 0,
        }));

        const { offsetHeight, offsetWidth } = evt.target;
        const size = Math.max(offsetHeight, offsetWidth);

        const rect = evt.target.getBoundingClientRect();
        const x = evt.clientX - rect.left;
        const y = evt.clientY - rect.top;

        setAnimation((prev) => ({
            ...prev,
            x,
            y,
            size,
        }));

        const durationInSec = 0.5;

        window.setTimeout(() => {
            setAnimation((prev) => ({
                ...prev,
                x: -1,
                y: -1,
                size: 0,
            }));
        }, durationInSec * 1000);

        if (props.onClick) {
            props.onClick(evt);
        }
    };

    const renderAnimation = () => {
        const aClassName = classNames({
            [styles.animation]: true,
            [styles.animationRose]: props.isRoseTheme,
            [styles.animationGreen]: props.isGreenTheme,
            [styles.animationOrange]: props.isOrangeTheme,
            [styles.animate]: animation.x !== -1 && animation.y !== -1,
        });

        const aStyles = {};

        if (animation.x !== -1 && animation.y !== -1) {
            aStyles.top = animation.y - Math.round(animation.size / 2);
            aStyles.left = animation.x - Math.round(animation.size / 2);
            aStyles.width = `${animation.size}px`;
            aStyles.height = `${animation.size}px`;
        }

        return (
            <div
                className={aClassName}
                style={aStyles}
            />
        );
    };

    const renderContent = () => {
        let icon = null;

        if (props.icon) {
            icon = (
                <props.icon
                    className={styles.buttonIcon}
                    isSky={props.outlined}
                    isWhite={!props.outlined}
                />
            );
        }

        const contentClassName = classNames({
            [styles.buttonWithIcon]: icon,
        });

        return (
            <div className={contentClassName}>
                {icon}
                {props.children}
            </div>
        );
    };

    const buttonClassName = classNames({
        [styles.button]: true,
        [styles.buttonRose]: props.isRoseTheme,
        [styles.buttonGreen]: props.isGreenTheme,
        [styles.buttonOrange]: props.isOrangeTheme,

        [styles.buttonOutlined]: props.outlined,
        [styles.buttonRoseOutlined]: props.outlined && props.isRoseTheme,
        [styles.buttonGreenOutlined]: props.outlined && props.isGreenTheme,
        [styles.buttonOrangeOutlined]: props.outlined && props.isOrangeTheme,

        [styles.buttonDefaultBorder]: props.isDefaultBorder,
        [styles.buttonRounded]: props.isRounded,
    });

    let buttonTabIndex = "0";

    if (props.isDisabledTabIndex) {
        buttonTabIndex = "-1";
    }

    return (
        <button
            type={props.type} // eslint-disable-line react/button-has-type
            disabled={props.disabled}
            className={buttonClassName}
            onClick={onClick}
            tabIndex={buttonTabIndex}
        >
            {renderAnimation()}
            {renderContent()}
        </button>
    );
};

ButtonBig.defaultProps = {
    type: "submit",

    icon: null,

    isRoseTheme: false,
    isGreenTheme: false,
    isOrangeTheme: false,

    isDisabledTabIndex: false,
    isDefaultBorder: false,
    isRounded: false,
    disabled: false,
    outlined: false,

    onClick: () => { },
};

export default ButtonBig;
