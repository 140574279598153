import React from "react";

import IconWrapper from "juice-base/icons/wrapper/index.js";


const IconCancel = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    width="45"
                    height="45"
                    viewBox="0 0 45 45"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={iconProps.className}
                >
                    {iconProps.title}
                    <path
                        fill="currentColor"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M22.5 3.75C12.1312 3.75 3.75 12.1312 3.75 22.5C3.75 32.8687 12.1312 41.25 22.5 41.25C32.8687 41.25 41.25 32.8687 41.25 22.5C41.25 12.1312 32.8687 3.75 22.5 3.75ZM22.5 37.5C14.2313 37.5 7.5 30.7687 7.5 22.5C7.5 14.2313 14.2313 7.5 22.5 7.5C30.7687 7.5 37.5 14.2313 37.5 22.5C37.5 30.7687 30.7687 37.5 22.5 37.5ZM22.5 19.8563L29.2313 13.125L31.875 15.7688L25.1437 22.5L31.875 29.2313L29.2313 31.875L22.5 25.1437L15.7688 31.875L13.125 29.2313L19.8563 22.5L13.125 15.7688L15.7688 13.125L22.5 19.8563Z"
                    />
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconCancel;
