import React from "react";

import IconWrapper from "juice-base/icons/wrapper/index.js";


const IconArrowInCircle = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={iconProps.className}
                    fillRule="evenodd"
                    clipRule="evenodd"
                    strokeLinejoin="round"
                    strokeMiterlimit="2"
                >
                    {iconProps.title}
                    <path
                        fill="currentColor"
                        d="M30,15C30,18.978 28.419,22.794 25.606,25.607C22.793,28.42 18.978,30 15,30C11.022,30 7.207,28.42 4.394,25.607C1.581,22.794 0,18.978 0,15C0,11.022 1.581,7.207 4.394,4.393C7.207,1.581 11.022,0 15,0C18.978,0 22.793,1.581 25.606,4.393C28.419,7.207 30,11.022 30,15ZM14.336,19.961C14.16,20.137 14.061,20.376 14.061,20.625C14.061,20.874 14.16,21.113 14.336,21.289C14.512,21.465 14.751,21.564 15,21.564C15.249,21.564 15.488,21.465 15.664,21.289L21.289,15.664C21.376,15.577 21.445,15.473 21.493,15.359C21.54,15.246 21.564,15.124 21.564,15C21.564,14.877 21.54,14.755 21.493,14.641C21.445,14.527 21.376,14.423 21.289,14.336L15.664,8.711C15.577,8.624 15.473,8.555 15.359,8.509C15.245,8.461 15.123,8.436 15,8.436C14.877,8.436 14.755,8.461 14.641,8.509C14.527,8.555 14.423,8.624 14.336,8.711C14.249,8.799 14.18,8.903 14.133,9.016C14.086,9.13 14.061,9.252 14.061,9.375C14.061,9.498 14.086,9.621 14.133,9.734C14.18,9.848 14.249,9.952 14.336,10.04L18.362,14.063L9.375,14.063C9.126,14.063 8.888,14.161 8.712,14.337C8.536,14.513 8.438,14.752 8.438,15C8.438,15.249 8.536,15.487 8.712,15.663C8.888,15.839 9.126,15.938 9.375,15.938L18.362,15.938L14.336,19.961Z"
                    />
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconArrowInCircle;
