import React from "react";

import IconWrapper from "juice-base/icons/wrapper/index.js";


const IconListLayout = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    width="17"
                    height="14"
                    viewBox="0 0 17 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={iconProps.className}
                >
                    <path
                        fill="currentColor"
                        d="M0 0V14H17V0H0ZM4 2V4H2V2H4ZM2 8V6H4V8H2ZM2 10H4V12H2V10ZM15 12H6V10H15V12ZM15 8H6V6H15V8ZM15 4H6V2H15V4Z"
                    />
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconListLayout;
