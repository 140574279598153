import * as globalTypes from "juice-base/store-common/types.js";
import * as types from "./types.js";


const initialState = {
    isVideosLoaded: false,
    isVideosLoading: false,
    hasMorePages: false,
    page: 0,
    videos: [],
};

export default (state, action) => {
    switch (action.type) {
        case types.SET_JUICES_VIDEOS: {
            return {
                ...state,
                isVideosLoaded: true,
                isVideosLoading: false,
                videos: action.payload.videos,
                page: action.payload.page,
                hasMorePages: action.payload.hasMorePages,
            };
        }

        case types.SET_JUICES_VIDEOS_LOADING: {
            return {
                ...state,
                isVideosLoading: true,
            };
        }

        /* --- */

        case globalTypes.CLEAR_AFTER_SIGNOUT: {
            return { ...initialState };
        }

        /* --- */

        default: {
            return state || { ...initialState };
        }
    }
};
