// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tCgWs6_RYvcsXKSpM9b_ {\n    box-sizing: border-box;\n\n    height: calc(100vh - var(--daily-juice-nav-height) - var(--mobile-header-bottom-height) - 0.5rem);\n    overflow-y: scroll;\n}\n\n.McDznGrNjd5s0sTuoJVJ {\n    height: calc(100vh - var(--daily-juice-nav-height) - var(--mobile-header-bottom-height) - 0.5rem - 7rem);\n}\n\n@media only screen and (min-width: 901px) {\n    .tCgWs6_RYvcsXKSpM9b_ {\n        height: calc(100vh - var(--main-header-height) - var(--main-header-height));\n    }\n}\n", "",{"version":3,"sources":["webpack://./base/business/swiper-daily-juice-stories-v2/styles.module.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;;IAEtB,iGAAiG;IACjG,kBAAkB;AACtB;;AAEA;IACI,wGAAwG;AAC5G;;AAEA;IACI;QACI,2EAA2E;IAC/E;AACJ","sourcesContent":[".page {\n    box-sizing: border-box;\n\n    height: calc(100vh - var(--daily-juice-nav-height) - var(--mobile-header-bottom-height) - 0.5rem);\n    overflow-y: scroll;\n}\n\n.pageForSafari {\n    height: calc(100vh - var(--daily-juice-nav-height) - var(--mobile-header-bottom-height) - 0.5rem - 7rem);\n}\n\n@media only screen and (min-width: 901px) {\n    .page {\n        height: calc(100vh - var(--main-header-height) - var(--main-header-height));\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": "tCgWs6_RYvcsXKSpM9b_",
	"pageForSafari": "McDznGrNjd5s0sTuoJVJ"
};
export default ___CSS_LOADER_EXPORT___;
