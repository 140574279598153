import React from "react";

import classNames from "juice-base/lib/class-names.js";

import IconChevron from "juice-base/icons/chevron/index.js";

import styles from "./styles.module.css";


const AccordionWithControls = (props) => {
    const onClick = () => {
        props.onClick({
            isExpanded: !props.isExpanded,
        });
    };

    const renderControl = (params = {}) => {
        const controlClassName = classNames({
            [styles.control]: true,
            [styles.controlWithBorder]: props.isControlsWithBorder,
            [styles.controlReversed]: params.isCaret && props.isExpanded,
        });

        return (
            <div
                className={controlClassName}
                onClick={params.onClick}
                onKeyPress={params.onClick}
                tabIndex="-1"
                role="button"
            >
                <params.icon />
            </div>
        );
    };

    const renderLabel2 = () => {
        if (props.label2) {
            return (
                <div className={styles.label2}>
                    {props.label2}
                </div>
            );
        }

        return null;
    };

    const renderCaret = () => {
        if (props.isControlsBottom) {
            return renderControl({
                isCaret: true,
                icon: IconChevron,
                onClick: () => { },
            });
        }

        return null;
    };

    const renderControls = () => {
        const controls = props.controls.map((control) => {
            if (control.isCustom) {
                return control.element;
            }

            return renderControl(control);
        });

        if (!props.isControlsBottom) {
            controls.push(renderControl({
                isCaret: true,
                icon: IconChevron,
                onClick,
            }));
        }

        const controlsClassName = classNames({
            [styles.controls]: true,
            [styles.controlsBottom]: props.isControlsBottom,
        });

        return (
            <div className={controlsClassName}>
                {controls}
            </div>
        );
    };

    const renderLabel = () => {
        const labelContainerClassName = classNames({
            [styles.labelContainer]: true,
            [styles.labelContainerHighlighted]: props.isHighlighted,
            [styles.labelContainerControlsToBottom]: props.isControlsBottom,
            [styles.labelContainerWithBorder]: props.labelWithBorder,
            [styles.labelContainerWithBorderRadius]: props.labelWithBorderRadius,
            [props.labelClassName]: props.labelClassName,
        });

        return (
            <div className={labelContainerClassName}>
                <div
                    className={styles.label}
                    onClick={onClick}
                    onKeyPress={onClick}
                    tabIndex="-1"
                    role="button"
                >
                    <div className={styles.labels}>
                        <div className={styles.labelText}>
                            {props.label}
                        </div>
                        {renderLabel2()}
                    </div>
                    {renderCaret()}
                </div>
                {renderControls()}
            </div>
        );
    };

    const renderContent = () => {
        const contentClassName = classNames({
            [styles.content]: true,
            [styles.contentExpanded]: props.isExpanded,
            [props.expandedContentClassName]: props.expandedContentClassName,
        });

        return (
            <div className={contentClassName}>
                {props.children}
            </div>
        );
    };

    const accordionClassName = classNames({
        [styles.accordion]: true,
        [styles.accordionHighlighted]: props.isHighlighted,
        [props.accordionClassName]: props.accordionClassName,
    });

    return (
        <div className={accordionClassName}>
            {renderLabel()}
            {renderContent()}
        </div>
    );
};

AccordionWithControls.defaultProps = {
    controls: [],
    label: "",
    label2: "",
    labelClassName: "",
    accordionClassName: "",
    expandedContentClassName: "",
    children: null,
    onClick: () => { },
    isExpanded: false,
    isControlsBottom: false,
    isControlsWithBorder: true,
    isHighlighted: false,
    labelWithBorder: true,
    labelWithBorderRadius: true,
};

export default AccordionWithControls;
