import React from "react";

import classNames from "juice-base/lib/class-names.js";

import styles from "./styles.module.css";


const MenuRow = (props) => {
    const renderIconAfter = () => {
        if (props.rightControl) {
            return props.rightControl;
        }

        return <div />;
    };

    const fieldClassName = classNames({
        [styles.field]: true,
        [props.className]: props.className,
    });

    const fieldNameClassName = classNames({
        [styles.textBold]: props.boldName,
        [styles.textGrey]: !props.boldName,
        [styles.textEllipsis]: true,
    });

    const fieldValueClassName = classNames({
        [styles.textBold]: !props.boldName,
        [styles.textGrey]: props.boldName,
        [styles.textNoneTextTransform]: true,
        [styles.textEllipsis]: props.withValueEllipsis,
    });

    return (
        <div className={fieldClassName}>
            {props.leftControl}
            <div className={styles.fieldInfo}>
                <div className={fieldNameClassName}>
                    {props.name}
                </div>
                <div className={fieldValueClassName}>
                    {props.value}
                </div>
            </div>
            {renderIconAfter()}
        </div>
    );
};

MenuRow.defaultProps = {
    name: "",
    value: "",

    className: "",

    boldName: false,
    withValueEllipsis: true,

    leftControl: null,
    rightControl: null,
};

export default MenuRow;
