import React, { useState } from "react";

import classNames from "juice-base/lib/class-names.js";

import IconEye from "juice-base/icons/eye/index.js";
import IconEyeClosed from "juice-base/icons/eye-closed/index.js";

import Error from "juice-base/components/forms/error/index.js";
import IconClickable from "juice-base/components/icon-clickable/index.js";
import MessagePassword from "juice-base/components/message-password/index.js";

import fieldCommonStyles from "juice-base/components/forms/field-common/styles.module.css";


const Password = (props) => {
    const [isFocused, setIsFocused] = useState(false);
    const [isTypePassword, setIsTypePassword] = useState(true);

    const onFocus = () => {
        setIsFocused(true);
    };

    const onBlur = (evt) => {
        setIsFocused(false);
        props.onBlur(evt);
    };

    const onToggleType = () => {
        setIsTypePassword((prev) => !prev);
    };

    /* --- */

    const renderEye = () => {
        let eyeIcon = (
            <IconEyeClosed
                className={fieldCommonStyles.controlIconImg}
                title="Show password"
                isBlack
            />
        );

        if (!isTypePassword) {
            eyeIcon = (
                <IconEye
                    className={fieldCommonStyles.controlIconImg}
                    title="Hide password"
                    isBlack
                />
            );
        }

        return (
            <IconClickable
                className={fieldCommonStyles.controlIcon}
                onClick={onToggleType}
            >
                {eyeIcon}
            </IconClickable>
        );
    };

    const renderError = () => {
        if (props.showMessage) {
            return (
                <MessagePassword
                    passwordMinLength={props.passwordMinLength}
                    isRed={props.error}
                />
            );
        }

        if (!props.error) {
            return null;
        }

        return (
            <Error>
                {props.error}
            </Error>
        );
    };

    /* --- */

    const containerClassName = classNames({
        [fieldCommonStyles.container]: true,
        [fieldCommonStyles.containerFocused]: isFocused || props.value,
        [fieldCommonStyles.containerError]: props.error,
    });

    const inputClassName = classNames({
        [fieldCommonStyles.input]: true,
        [fieldCommonStyles.inputFocused]: isFocused || props.value,
        [fieldCommonStyles.inputError]: props.error,
    });

    const labelClassName = classNames({
        [fieldCommonStyles.label]: true,
        [fieldCommonStyles.labelWithCursorText]: true,
    });

    return (
        <div className={fieldCommonStyles.field}>
            <div className={containerClassName}>
                <input
                    id={props.name}
                    name={props.name}
                    type={isTypePassword ? "password" : "text"}
                    autoComplete={props.autoComplete}
                    value={props.value}
                    maxLength={props.maxLength}
                    className={inputClassName}
                    onChange={props.onChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                />

                <label
                    htmlFor={props.name}
                    className={labelClassName}
                >
                    {props.label}
                </label>

                {renderEye()}
            </div>

            {renderError()}
        </div>
    );
};

Password.defaultProps = {
    autoComplete: "",
    name: "",
    label: "",
    value: "",
    error: "",
    maxLength: 255,
    passwordMinLength: 8,
    showMessage: false,
    onChange: () => { },
    onBlur: () => { },
};

export default Password;
