import React from "react";
import { Link } from "react-router-dom";

import useTitle from "juice-base/hooks/use-title/index.js";

import IconHome from "juice-base/icons/home/index.js";
import LayoutContent from "juice-base/components/layout-content/index.js";

import linkStyles from "juice-base/components/link/styles.module.css";
import styles from "./styles.module.css";


const NotFound = () => {
    useTitle(() => "Not Found", []);

    return (
        <LayoutContent>
            <div className={styles.notFound}>
                <div className={styles.message}>
                    <div>
                        The page you were looking for doesn&apos;t exist.
                    </div>

                    <IconHome
                        title="Home"
                        className={styles.homeIcon}
                        isBlack
                    />

                    <div>
                        <Link to="/" className={linkStyles.link}>
                            Go to Home Page
                        </Link>
                    </div>
                </div>
            </div>
        </LayoutContent>
    );
};

export default NotFound;
