import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import User from "juice-base/project/user.js";

import useTitle from "juice-base/hooks/use-title/index.js";

import { withAuth } from "juice-base/components/auth/index.js";

import StudentIndexRegular from "juice-app/containers/student-index-regular/index.js";
import StudentIndexTrial from "juice-app/containers/student-index-trial/index.js";


const Index = () => {
    const store = useSelector((state) => ({
        user: state.user.user,
    }));

    const navigate = useNavigate();

    useTitle(() => "Home", []);

    if (User.hasRoleTeacher(store.user)) {
        if (store?.user?.isSignUpCompleted) {
            navigate("/daily-juices");
        } else {
            navigate("/dashboard");
        }

        return null;
    }

    if (User.hasRoleStudent(store.user) || User.hasRoleGuardian(store.user)) {
        if (User.isTypeTrial(store.user)) {
            return (
                <StudentIndexTrial />
            );
        }

        return (
            <StudentIndexRegular />
        );
    }

    return null;
};

export default withAuth([
    "teacher",
    "student",
    "guardian",
])(Index);
