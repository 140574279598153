import React from "react";

import Vocabulary from "juice-base/project/vocabulary.js";

import PopupWordCard from "juice-base/components/popup-word-card/index.js";
import PopupText from "juice-base/components/popup-text/index.js";
import PopupLoading from "juice-base/components/popup-loading/index.js";


const PopupWord = (props) => {
    if (!props.wordPopup.isVisible) {
        return null;
    }

    const wordAlias = Vocabulary.getWord(
        props.wordsByName,
        props.wordPopup.wordAlias,
    );

    if (wordAlias && !wordAlias.isLoaded) {
        return (
            <PopupLoading
                onClose={props.onClose}
                hideScrollbar={props.hideScrollbar}
            />
        );
    }

    if (wordAlias && wordAlias.error) {
        return (
            <PopupText
                lines={[wordAlias.error]}
                onClose={props.onClose}
                hideScrollbar={props.hideScrollbar}
            />
        );
    }

    const word = Vocabulary.getWord(
        props.wordsByName,
        props.wordPopup.word,
    );

    if (!word) {
        return (
            <PopupText
                lines={["Unknown word"]}
                onClose={props.onClose}
                hideScrollbar={props.hideScrollbar}
            />
        );
    }

    return (
        <PopupWordCard
            word={word}
            audio={props.audio}
            onLoad={props.onAudioLoad}
            onPlay={props.onAudioPlay}
            onStop={props.onAudioStop}
            onStopAll={props.onAudioStopAll}
            onClose={props.onClose}
            hideScrollbar={props.hideScrollbar}
        />
    );
};

PopupWord.defaultProps = {
    wordsByName: {},
    wordPopup: {},
    hideScrollbar: true,
    audio: {},
    onAudioLoad: () => { },
    onAudioPlay: () => { },
    onAudioStop: () => { },
    onAudioStopAll: () => { },
    onClose: () => { },
};

export default PopupWord;
