import React, { useState, useEffect, useRef } from "react";
import moment from "moment";

import { DayPicker } from "react-dates";

import IconCalendar from "juice-base/icons/calendar/index.js";
import IconCaret from "juice-base/icons/caret/index.js";

import date from "juice-base/lib/date.js";
import classNames from "juice-base/lib/class-names.js";

import styles from "./styles.module.css";
import "./styles.css";


const SelectCalendar = (props) => {
    const [isVisibleCalendar, setIsVisibleCalendar] = useState(false);

    const [selectedDates, setSelectedDates] = useState([]);
    const [hoveringDates, setHoveringDates] = useState([]);

    const selectRef = useRef(null);

    /* ------- */

    const isDayDisabled = (d) => {
        if (date.isWeekend(d)) {
            return true;
        }

        if (props.maxDate && date.newDate(d) > date.newDate(props.maxDate)) {
            const isDatesSame = date.isDatesSame(
                date.newDate(d),
                date.newDate(props.maxDate),
            );

            if (isDatesSame) {
                return false;
            }

            return true;
        }

        return false;
    };

    /* ------- */

    const onResetHoveringDates = () => {
        setHoveringDates([]);
    };

    const onSelectClick = () => {
        onResetHoveringDates();
        setIsVisibleCalendar((prev) => !prev);
    };

    const onDateHover = (d) => {
        let weekdays = [];

        if (!isDayDisabled(d)) {
            weekdays = date.getWeekDaysByDate(d);
        }

        setHoveringDates(weekdays);
    };

    const onDayClick = (d) => {
        if (isDayDisabled(d)) {
            return;
        }

        setIsVisibleCalendar(false);

        const weekdays = date.getWeekDaysByDate(d);

        const dateFrom = weekdays.length > 0
            ? weekdays[0]
            : new Date();

        const dateTo = weekdays.length > 0
            ? weekdays[weekdays.length - 1]
            : new Date();

        props.onRangeChange({
            dateFrom,
            dateTo,
        });
    };

    /* ------- */

    useEffect(() => {
        const globalClose = (evt) => {
            if (selectRef?.current?.contains
                && selectRef.current.contains(evt.target)) {
                return;
            }

            onResetHoveringDates();
            setIsVisibleCalendar(false);
        };

        if (document) {
            document.addEventListener("click", globalClose, false);
        }

        return () => {
            if (document) {
                document.removeEventListener("click", globalClose, false);
            }
        };
    }, []);

    useEffect(() => {
        const { selectedRange } = props;

        const sDate = selectedRange.dateFrom || selectedRange.dateTo;

        if (!sDate) {
            return;
        }

        const weekdays = date.getWeekDaysByDate(sDate);

        setSelectedDates(weekdays);
    }, [props.selectedRange]);

    /* ------- */

    const renderDayContents = (d) => {
        const { selectedRange } = props;

        let isHoveringDate = false;
        let isDisabledDate = false;

        let isFirstSelectedDate = false;
        let isLastSelectedDate = false;

        const sDate = selectedRange.dateFrom || selectedRange.dateTo;

        if (sDate) {
            if (isDayDisabled(d)) {
                isDisabledDate = true;
            }

            for (let i = 0; i < hoveringDates.length; i += 1) {
                const isSame = date.isDatesSame(
                    date.newDate(d),
                    date.newDate(hoveringDates[i]),
                );

                if (isSame) {
                    isHoveringDate = true;
                }
            }

            for (let i = 0; i < selectedDates.length; i += 1) {
                const isSame = date.isDatesSame(
                    date.newDate(d),
                    date.newDate(selectedDates[i]),
                );

                if (isSame) {
                    if (i === selectedDates.length - 1) {
                        isLastSelectedDate = true;
                    }

                    if (i === 0) {
                        isFirstSelectedDate = true;
                    }

                    isHoveringDate = true;
                    break;
                }
            }
        }

        const dayClassName = classNames({
            [styles.day]: true,
            [styles.dayDisabled]: isDisabledDate,
            [styles.dayHovering]: isHoveringDate,
            [styles.dayHoveringFirst]: isFirstSelectedDate,
            [styles.dayHoveringLast]: isLastSelectedDate,
        });

        const dayDateClassName = classNames({
            [styles.dayDate]: true,
            [styles.dayDateHoveringFirstLast]: isFirstSelectedDate || isLastSelectedDate,
        });

        return (
            <div className={dayClassName}>
                <div className={dayDateClassName}>
                    {date.newDate(d).getDate()}
                </div>
            </div>
        );
    };

    const renderCalendar = () => {
        if (!isVisibleCalendar) {
            return null;
        }

        const { selectedRange } = props;

        const calendarClassName = classNames({
            [styles.calendar]: true,
            [props.calendarClassName]: props.calendarClassName,
            selectCalendar: true,
        });

        const sDate = selectedRange.dateFrom || selectedRange.dateTo;

        return (
            <div className={calendarClassName}>
                <DayPicker
                    id={`day-picker-select-${props.id}`}
                    date={moment(sDate)}
                    numberOfMonths={1}
                    firstDayOfWeek={1}
                    enableOutsideDays
                    initialVisibleMonth={() => moment(sDate)}
                    renderDayContents={renderDayContents}
                    onDayClick={onDayClick}
                    onDayMouseEnter={onDateHover}
                    onPrevMonthClick={onResetHoveringDates}
                    onNextMonthClick={onResetHoveringDates}
                />
            </div>
        );
    };

    const renderLabel = () => {
        const { selectedRange } = props;

        let label = "Select...";

        if (selectedRange.dateFrom && selectedRange.dateTo) {
            const date1 = date.tryFormatDate(selectedRange.dateFrom, date.formatShortMonthDate);
            const date2 = date.tryFormatDate(selectedRange.dateTo, date.formatShortMonthDate);

            label = `${date1} - ${date2}`;
        }

        return (
            <div className={styles.label}>
                {label}
            </div>
        );
    };

    /* ------- */

    const caretClassName = classNames({
        [styles.caret]: true,
        [styles.caretReversed]: isVisibleCalendar,
    });

    return (
        <div
            className={styles.selectContainer}
            ref={selectRef}
        >
            <div
                className={styles.select}
                tabIndex="-1"
                role="button"
                onClick={onSelectClick}
                onKeyPress={onSelectClick}
            >
                <IconCalendar
                    className={styles.calendarIcon}
                    isBlack
                />
                {renderLabel()}
                <IconCaret
                    className={caretClassName}
                />
            </div>
            {renderCalendar()}
        </div>
    );
};

SelectCalendar.defaultProps = {
    id: null,
    selectedRange: {
        dateFrom: null,
        dateTo: null,
    },
    maxDate: null,
    calendarClassName: "",
    onRangeChange: () => { },
};

export default SelectCalendar;
