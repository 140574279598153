// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".XTPJJWubnSCLibm_QqnF {\n    padding: 3rem;\n\n    display: flex;\n    justify-content: center;\n    align-items: center;\n\n    font-weight: 600;\n    text-transform: uppercase;\n\n    color: var(--text-dev-color);\n    background-color: var(--text-primary-default-bg-color);\n}\n", "",{"version":3,"sources":["webpack://./base/components/dev-placeholder/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;;IAEb,aAAa;IACb,uBAAuB;IACvB,mBAAmB;;IAEnB,gBAAgB;IAChB,yBAAyB;;IAEzB,4BAA4B;IAC5B,sDAAsD;AAC1D","sourcesContent":[".text {\n    padding: 3rem;\n\n    display: flex;\n    justify-content: center;\n    align-items: center;\n\n    font-weight: 600;\n    text-transform: uppercase;\n\n    color: var(--text-dev-color);\n    background-color: var(--text-primary-default-bg-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": "XTPJJWubnSCLibm_QqnF"
};
export default ___CSS_LOADER_EXPORT___;
