import React, { useEffect, useState } from "react";
import {
    Link,
    Navigate,
    useParams,
    useNavigate,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import User from "juice-base/project/user.js";

import text from "juice-base/text/index.js";
import urls from "juice-base/lib/urls.js";
import storage from "juice-base/lib/storage/index.js";

import userActions from "juice-base/actions/user.js";
import actions from "juice-base/store/actions.js";
import { setUserSession, setUser } from "juice-base/store/user/actions.js";

import useTitle from "juice-base/hooks/use-title/index.js";

import LayoutContent from "juice-base/components/layout-content/index.js";
import LogoRound from "juice-base/components/logo-round/index.js";
import RequestLoader from "juice-base/components/request-loader/index.js";
import Footer from "juice-base/components/footer/index.js";
import ButtonBig from "juice-base/components/button-big/index.js";
import Text from "juice-base/components/text/index.js";
import PopupConfirmError from "juice-base/components/popup-confirm-error/index.js";

import UserSignInForm from "juice-base/forms/user-sign-in/index.js";

import PopupUserResetPassword from "juice-base/business/popup-user-reset-password/index.js";

import settings from "juice-app/settings.js";
import api from "juice-app/api.js";
import events from "juice-app/events.js";

import styles from "./styles.module.css";


const storeSelector = (state) => ({
    prevLocation: state.navigation.prevLocation,
    lmsSignInUrl: state.info.features.lmsSignInUrl,
    userId: state.user.user.userId,
});

const SignIn = () => {
    const [popupErrorState, setPopupErrorState] = useState({
        isVisible: false,
        message: null,
    });

    const { secretKey } = useParams();
    const navigate = useNavigate();

    const store = useSelector(storeSelector);
    const dispatch = useDispatch();

    const hasLMS = settings?.features?.LMS && store.lmsSignInUrl;
    const isLMSLoading = settings?.features?.LMS && !store.lmsSignInUrl;

    const [keyState, setKeyState] = useState({
        isLoaded: false,
        error: "",
    });

    useEffect(() => {
        if (!secretKey) {
            return;
        }

        api.user.checkRegistrationCode({
            secret: secretKey,
        }).then((res) => {
            if (res.ok) {
                setKeyState((prev) => ({
                    ...prev,
                    isLoaded: true,
                }));
            } else {
                setKeyState((prev) => ({
                    ...prev,
                    isLoaded: true,
                    error: "Invalid confirmation key",
                }));
            }
        });
    }, []);

    useTitle(() => "Sign In", []);

    /* --- */

    const onSignIn = async (values, { setSubmitting, setErrors }) => {
        const res = await api.user.signIn(values);

        if (!res.ok) {
            const error = res.error || text.error;

            setErrors({
                username: error,
            });

            if (error.toLowerCase().indexOf("active") >= 0) {
                const message = User.hasRoleTeacher(res.user)
                    ? text.inactiveTeacher
                    : text.inactiveStudent;

                setPopupErrorState({
                    isVisible: true,
                    message,
                });
            }

            setSubmitting(false);
            return;
        }

        events.user.signIn({
            session: res.sessionId,
        });

        if (values.isRememberMe) {
            storage.local.saveSession(res.sessionId ?? "");
            storage.session.saveSession("");
        } else {
            storage.local.saveSession("");
            storage.session.saveSession(res.sessionId ?? "");
        }

        dispatch(setUserSession(res.sessionId || ""));
        dispatch(setUser(res.user));

        const userId = res?.user?.userId || "";

        dispatch(userActions.loadUserOptions({ actions, api }, {
            session: res.sessionId,
        }));

        if (userId) {
            dispatch(userActions.saveUserIdAndReport({ storage, api }, {
                userId,
            }));
        }

        const signInPaths = [
            "/user/login",
            "/user/sign-in",
            "/sign-in",
        ];

        const prevPath = store.prevLocation?.pathname || "/";

        if (signInPaths.indexOf(prevPath) !== -1) {
            navigate("/");
            return;
        }

        navigate(prevPath);
    };

    const onConfirmRegistration = (newPassword) => {
        api.user.confirmRegistration({
            secret: secretKey,
            password: newPassword,
        }).then((res) => {
            if (res.ok) {
                navigate("/");
            } else {
                setKeyState((prev) => ({
                    ...prev,
                    error: res.error || "Error!",
                }));
            }
        });
    };

    const onCloseConfirmRegistration = () => {
        navigate("/sign-in");
    };

    /* --- */

    const onCloseErrorPopup = () => {
        setPopupErrorState({
            isVisible: false,
            message: null,
        });
    };

    /* --- */

    const renderConfirmRegistration = () => {
        if (!secretKey) {
            return null;
        }

        return (
            <PopupUserResetPassword
                passwordMinLength={settings.password.minLength}
                isLoaded={keyState.isLoaded}
                error={keyState.error}
                onSubmit={onConfirmRegistration}
                onClose={onCloseConfirmRegistration}
            />
        );
    };

    const renderConfirmError = () => {
        if (!popupErrorState.isVisible) {
            return null;
        }

        const error = (
            <Text>
                {popupErrorState.message}
            </Text>
        );

        return (
            <PopupConfirmError
                error={error}
                onClose={onCloseErrorPopup}
            />
        );
    };

    const renderForm = () => {
        return (
            <UserSignInForm
                passwordMinLen={settings.password.minLength}
                onSubmit={onSignIn}
            />
        );
    };

    const renderSignInViaLMS = () => {
        if (!hasLMS) {
            return null;
        }

        return (
            <div className={styles.customSignIn}>
                <ButtonBig
                    type="button"
                    onClick={() => {
                        urls.redirect(store.lmsSignInUrl);
                    }}
                >
                    Sign In with LMS
                </ButtonBig>
            </div>
        );
    };

    const renderLink = (params = {}) => {
        return (
            <a
                className={styles.accountLinkSignUp}
                href={params.href}
                target="_blank"
                rel="noreferrer"
            >
                {params.label}
            </a>
        );
    };

    const renderAccountLinks = () => {
        const signUpLink = [
            settings.landingSite.domain,
            settings.landingSite.routeSignUp,
        ].join("");

        const supportLink = [
            settings.landingSite.domain,
            settings.landingSite.routeSupport,
        ].join("");

        return (
            <div className={styles.accountLinks}>
                <Link
                    to="/user/sign-up"
                    className={styles.accountLinkCode}
                >
                    Have a class code? Enter it here.
                </Link>
                {renderLink({
                    href: signUpLink,
                    label: "Need an account? Sign Up here.",
                })}
                {renderLink({
                    href: supportLink,
                    label: "Need help? Contact us here.",
                })}
            </div>
        );
    };

    const renderContent = () => {
        if (isLMSLoading) {
            return (
                <RequestLoader />
            );
        }

        return (
            <div className={styles.content}>
                <div className={styles.contentHeader}>
                    <LogoRound />

                    <div className={styles.formName}>
                        Sign In
                    </div>
                </div>

                {renderForm()}
                {renderSignInViaLMS()}
                {renderAccountLinks()}

                <div className={styles.footer}>
                    <Footer
                        copyright={settings.copyright}
                    />
                </div>
            </div>
        );
    };

    if (store.userId) {
        return (
            <Navigate to="/" />
        );
    }

    return (
        <>
            {renderConfirmRegistration()}
            {renderConfirmError()}

            <LayoutContent>
                {renderContent()}
            </LayoutContent>
        </>
    );
};

export default SignIn;
