import Theme from "juice-base/project/theme.js";

import * as types from "./types.js";


const initialState = {
    version: "",

    theme: {
        isAutomatic: true,
        theme: Theme.getDefaultTheme(),
    },

    libs: {
        videojs: { isLoaded: false, error: "" },
        gapi: { isLoaded: false, error: "" },
        stripe: { isLoaded: false, error: "" },
        stripeInit: { isLoaded: false, error: "" },
    },
};

export default (state, action) => {
    switch (action.type) {
        case types.SET_VERSION: {
            return {
                ...state,
                version: action.payload.version,
            };
        }

        /* --- */

        case types.SET_AUTOMATIC_THEME: {
            return {
                ...state,
                theme: {
                    ...state.theme,
                    isAutomatic: action.payload.isAutomatic,
                },
            };
        }

        case types.SET_THEME: {
            return {
                ...state,
                theme: {
                    ...state.theme,
                    theme: action.payload.theme,
                },
            };
        }

        /* --- */

        case types.SET_LIB_VIDEOJS_LOADED: {
            return {
                ...state,
                libs: {
                    ...state.libs,
                    videojs: {
                        ...state.libs.gapi,
                        isLoaded: true,
                    },
                },
            };
        }

        case types.SET_LIB_GAPI_LOADED: {
            return {
                ...state,
                libs: {
                    ...state.libs,
                    gapi: {
                        ...state.libs.gapi,
                        isLoaded: true,
                    },
                },
            };
        }

        case types.SET_LIB_STRIPE_LOADED: {
            return {
                ...state,
                libs: {
                    ...state.libs,
                    stripe: {
                        ...state.libs.stripe,
                        isLoaded: true,
                    },
                },
            };
        }

        case types.SET_LIB_STRIPE_ERROR: {
            const { error } = action.payload;

            return {
                ...state,
                libs: {
                    ...state.libs,
                    stripe: {
                        ...state.libs.stripe,
                        error,
                    },
                },
            };
        }

        case types.SET_LIB_STRIPE_INIT_LOADED: {
            return {
                ...state,
                libs: {
                    ...state.libs,
                    stripeInit: {
                        ...state.libs.stripe,
                        isLoaded: true,
                    },
                },
            };
        }

        default: {
            return state || { ...initialState };
        }
    }
};
