import * as globalTypes from "juice-base/store-common/types.js";
import * as types from "./types.js";


const initialState = {
    isExtraJuicesLoaded: false,
    isExtraJuicesLoading: false,
    hasMorePages: false,
    page: 0,
    extraJuices: [],

    extraJuicesById: {},
};

export default (state, action) => {
    switch (action.type) {
        case types.SET_EXTRA_JUICES: {
            return {
                ...state,
                isExtraJuicesLoaded: true,
                isExtraJuicesLoading: false,
                extraJuices: action.payload.extraJuices,
                page: action.payload.page,
                hasMorePages: action.payload.hasMorePages,
            };
        }

        case types.SET_EXTRA_JUICES_LOADING: {
            return {
                ...state,
                isExtraJuicesLoading: true,
            };
        }

        case types.SET_EXTRA_JUICE: {
            const { extraJuicesById } = state;
            const { extraJuice } = action.payload;

            return {
                ...state,
                extraJuicesById: {
                    ...extraJuicesById,
                    [extraJuice.id]: extraJuice,
                },
            };
        }

        /* --- */

        case globalTypes.CLEAR_AFTER_SIGNOUT: {
            return { ...initialState };
        }

        /* --- */

        default: {
            return state || { ...initialState };
        }
    }
};
