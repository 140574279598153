import React from "react";

import classNames from "juice-base/lib/class-names.js";

import styles from "./styles.module.css";


const WizardMultiStep = (props) => {
    if (props.steps.length === 0) {
        return null;
    }

    const onGoToStep = (stepIndex) => {
        return () => {
            if (props.disablePrevSteps) {
                return;
            }

            if (stepIndex < props.step) {
                props.onStepChange(stepIndex);
            }
        };
    };

    const renderStep = (index, step) => {
        const stepClassName = classNames({
            [styles.step]: true,
            [styles.stepSelected]: index <= props.step,
        });

        const onGo = onGoToStep(index);

        return (
            <div
                key={step.name}
                className={stepClassName}
                onClick={onGo}
                onKeyPress={onGo}
                role="button"
                tabIndex="-1"
            >
                <div className={styles.stepIndex}>
                    <div className={styles.stepCircle}>
                        {index + 1}
                    </div>
                </div>
                <div className={styles.stepName}>
                    {step.name}
                </div>
            </div>
        );
    };

    const renderSteps = () => {
        const steps = [];

        props.steps.forEach((step, i) => {
            steps.push(renderStep(i, step));
        });

        return steps;
    };

    const renderContent = () => {
        return props.steps.map((step) => {
            const contentStepClassName = classNames({
                [styles.contentStep]: true,
                [props.contentStepClassName]: props.contentStepClassName,
            });

            return (
                <div className={contentStepClassName}>
                    {step.content}
                </div>
            );
        });
    };

    const stepsClassName = classNames({
        [styles.steps]: true,
        [styles.stepsDisabled]: props.disablePrevSteps,
    });

    const sliderStyles = {
        transform: `translateX(-${100 * props.step}%)`,
    };

    return (
        <div className={styles.wizard}>
            <div className={stepsClassName}>
                {renderSteps()}
            </div>
            <div className={styles.content}>
                <div
                    className={styles.contentSlider}
                    style={sliderStyles}
                >
                    {renderContent()}
                </div>
            </div>
        </div>
    );
};

WizardMultiStep.defaultProps = {
    disablePrevSteps: false,
    contentStepClassName: "",
    step: 0,
    steps: [],
    onStepChange: () => { },
};

export default WizardMultiStep;
