import React, { useEffect } from "react";

import classNames from "juice-base/lib/class-names.js";

import IconClose from "juice-base/icons/close/index.js";

import IconClickable from "juice-base/components/icon-clickable/index.js";
import Text from "juice-base/components/text/index.js";

import styles from "./styles.module.css";


const PopupMessageWithImage = (props) => {
    const onContentClick = (evt) => {
        if (evt.target.tagName === "A") {
            if (evt.target.href) {
                evt.stopPropagation();
                evt.preventDefault();

                if (evt.target.target === "_blank") {
                    props.onOpenNewTab(evt.target.href);
                    return;
                }

                props.onNavigate(evt.target.pathname);
            }
            return;
        }

        props.onImageClick();
    };

    useEffect(() => {
        props.onMount();
    }, []);

    /* --- */

    const renderContent = () => {
        if (!props.content) {
            return null;
        }

        return (
            <Text className={styles.content}>
                {props.content}
            </Text>
        );
    };

    const messageClassName = classNames({
        [styles.popupMessage]: true,
        [styles.popupMessageHidden]: props.isHidden,
    });

    const contentBoxStyles = {};

    if (props.maxWidth) {
        contentBoxStyles.maxWidth = `${props.maxWidth}px`;
    }

    const imageContainerStyles = {};

    if (props.image) {
        imageContainerStyles.backgroundImage = `url('${props.image}')`;
    }

    return (
        <div className={messageClassName}>
            <div
                className={styles.contentBox}
                style={contentBoxStyles}
            >
                <div
                    className={styles.imageContainer}
                    style={imageContainerStyles}
                    onClick={onContentClick}
                    onKeyPress={onContentClick}
                    tabIndex="-1"
                    role="button"
                >
                    {renderContent()}
                </div>
                <div className={styles.control}>
                    <IconClickable
                        onClick={props.onClose}
                        className={styles.icon}
                    >
                        <IconClose
                            isWhite
                        />
                    </IconClickable>
                </div>
            </div>
        </div>
    );
};

PopupMessageWithImage.defaultProps = {
    title: "",
    content: "",
    image: "",
    maxWidth: 0,
    onMount: () => { },
    onOpenNewTab: () => { },
    onImageClick: () => { },
    onNavigate: () => { },
    onClose: () => { },
    isHidden: false,
};

export default PopupMessageWithImage;
