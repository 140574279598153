// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GhENsXZBOUJKwF_FEG0s {\n    padding: 0.3rem 4rem;\n    margin: 0;\n\n    display: inline-flex;\n    flex-direction: row;\n    align-items: center;\n\n    border: 0.2rem solid var(--button-extra-juice-border-color);\n    border-radius: 1rem;\n\n    background-color: var(--button-extra-juice-bg-color);\n    box-shadow: var(--box-shadow-1);\n\n    color: var(--button-extra-juice-color);\n    text-transform: uppercase;\n    text-align: center;\n    font-weight: 600;\n    font-size: 1.4rem;\n    word-break: break-word;\n\n    cursor: pointer;\n    outline: none;\n}\n", "",{"version":3,"sources":["webpack://./base/components/button-extra-juice/styles.module.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,SAAS;;IAET,oBAAoB;IACpB,mBAAmB;IACnB,mBAAmB;;IAEnB,2DAA2D;IAC3D,mBAAmB;;IAEnB,oDAAoD;IACpD,+BAA+B;;IAE/B,sCAAsC;IACtC,yBAAyB;IACzB,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,sBAAsB;;IAEtB,eAAe;IACf,aAAa;AACjB","sourcesContent":[".button {\n    padding: 0.3rem 4rem;\n    margin: 0;\n\n    display: inline-flex;\n    flex-direction: row;\n    align-items: center;\n\n    border: 0.2rem solid var(--button-extra-juice-border-color);\n    border-radius: 1rem;\n\n    background-color: var(--button-extra-juice-bg-color);\n    box-shadow: var(--box-shadow-1);\n\n    color: var(--button-extra-juice-color);\n    text-transform: uppercase;\n    text-align: center;\n    font-weight: 600;\n    font-size: 1.4rem;\n    word-break: break-word;\n\n    cursor: pointer;\n    outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "GhENsXZBOUJKwF_FEG0s"
};
export default ___CSS_LOADER_EXPORT___;
