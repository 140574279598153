import React, { useState } from "react";

import text from "juice-base/text/index.js";

import IconCompass from "juice-base/icons/compass/index.js";
import IconOrange from "juice-base/icons/orange/index.js";

import RequestLoader from "juice-base/components/request-loader/index.js";
import IconClickable from "juice-base/components/icon-clickable/index.js";
import Tabs from "juice-base/components/tabs/index.js";
import ButtonRounded from "juice-base/components/button-rounded/index.js";
import Message from "juice-base/components/message/index.js";

import SectionStudentV2 from "juice-base/business/section-student-v2/index.js";

import styles from "./styles.module.css";


const tabs = [
    { value: "extraJuice", label: "Extra Juice" },
    { value: "videos", label: "Videos" },
];

const DailyJuiceExplore = (props) => {
    const [selectedTab, setSelectedTab] = useState(() => tabs[0].value);

    const renderViewMoreButton = () => {
        return (
            <div className={styles.viewMoreButton}>
                <ButtonRounded
                    uppercase
                    onClick={() => {
                        props.onViewMore(selectedTab);
                    }}
                >
                    View More
                </ButtonRounded>
            </div>
        );
    };

    const renderExtraJuices = () => {
        if (!props.isExtraJuicesLoaded) {
            return (
                <RequestLoader />
            );
        }

        const extraJuices = [];

        for (let i = 0; i < props.extraJuices.length; i += 1) {
            if (i > 3) {
                break;
            }

            const ej = props.extraJuices[i];

            extraJuices.push(
                <IconClickable
                    key={ej.id}
                    className={styles.row}
                    onClick={() => {
                        props.onExtraJuiceClick(ej.id);
                    }}
                >
                    <IconOrange
                        className={styles.rowIcon}
                        title="Extra Juice"
                    />
                    <div className={styles.rowTitle}>
                        {ej.title}
                    </div>
                </IconClickable>,
            );
        }

        if (extraJuices.length === 0) {
            return (
                <Message>
                    {text.noExtraJuices}
                </Message>
            );
        }

        return (
            <div>
                {extraJuices}
                {renderViewMoreButton()}
            </div>
        );
    };

    const renderVideos = () => {
        if (!props.isVideosLoaded) {
            return (
                <RequestLoader />
            );
        }

        const videos = [];

        for (let i = 0; i < props.videos.length; i += 1) {
            if (i > 3) {
                break;
            }

            const video = props.videos[i];

            let videoImage = null;

            if (video?.featuredImage?.url) {
                videoImage = (
                    <img
                        className={styles.rowIcon}
                        src={video.featuredImage.url}
                        alt="Preview"
                    />
                );
            }

            videos.push(
                <div
                    className={styles.row}
                    onClick={() => {
                        props.onVideoClick(video.videoID);
                    }}
                    onKeyPress={() => {
                        props.onVideoClick(video.videoID);
                    }}
                    role="button"
                    tabIndex="-1"
                >
                    {videoImage}
                    <div className={styles.rowTitle}>
                        {video.newsTitle}
                    </div>
                </div>,
            );
        }

        if (videos.length === 0) {
            return (
                <Message>
                    {text.noVideos}
                </Message>
            );
        }

        return (
            <div>
                {videos}
                {renderViewMoreButton()}
            </div>
        );
    };

    const renderContent = () => {
        const content = selectedTab === "extraJuice"
            ? renderExtraJuices()
            : renderVideos();

        return (
            <div>
                <Tabs
                    isBlueTheme
                    onlyTabs
                    tabs={tabs}
                    selectedTab={selectedTab}
                    onChange={setSelectedTab}
                />
                {content}
            </div>
        );
    };

    return (
        <SectionStudentV2
            title="Explore"
            icon={<IconCompass isOrange />}
            titleBottomBorder={false}
        >
            {renderContent()}
        </SectionStudentV2>
    );
};

DailyJuiceExplore.defaultProps = {
    extraJuices: [],
    videos: [],

    isExtraJuicesLoaded: false,
    isVideosLoaded: false,

    onVideoClick: () => { },
    onExtraJuiceClick: () => { },

    onViewMore: () => { },
};

export default DailyJuiceExplore;
