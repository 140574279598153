import React from "react";

import { Formik } from "formik";

import { hasEmptyValue } from "juice-base/lib/object.js";
import v from "juice-base/lib/form-validators.js";
import classNames from "juice-base/lib/class-names.js";

import ButtonBig from "juice-base/components/button-big/index.js";
import MessageDanger from "juice-base/components/message-danger/index.js";

import Input from "juice-base/components/forms/input/index.js";
import Select from "juice-base/components/forms/select/index.js";

import styles from "./styles.module.css";


const ClassDetailsForm = (props) => {
    const isDisabled = (formProps) => {
        const hasErrors = Object.keys(formProps.errors).length > 0;

        return hasEmptyValue(formProps.values)
            || hasErrors
            || formProps.isSubmitting;
    };

    const validateForm = (values) => {
        const errorsClassName = v.validate(values.className, [
            v.required("Please enter class name"),
        ]);

        const errors = {};

        if (errorsClassName) {
            errors.className = errorsClassName;
        }

        return errors;
    };

    const renderFormError = (error) => {
        if (error) {
            return (
                <MessageDanger>
                    {error}
                </MessageDanger>
            );
        }

        return null;
    };

    const renderForm = (formProps) => {
        const {
            values,
            errors,
            handleSubmit,
            setFieldValue,
            handleChange,
            handleBlur,
            isSubmitting,
        } = formProps;

        const formClassName = classNames({
            [styles.form]: true,
            [styles.formWithError]: errors.formError,
        });

        let deleteButton = null;

        if (props.showDelete) {
            deleteButton = (
                <ButtonBig
                    type="button"
                    isRoseTheme
                    onClick={props.onDeleteClass}
                >
                    Delete Class
                </ButtonBig>
            );
        }

        return (
            <form onSubmit={handleSubmit} className={formClassName}>
                <div>
                    <Input
                        name="className"
                        label="Class Name *"
                        showMaxLengthMessage
                        maxLength={25}
                        value={values.className}
                        error={v.getError(formProps, "className")}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isDisabled={!props.allowEditClassName}
                    />

                    <Select
                        name="grades"
                        label="Default Reading Level *"
                        selected={values.grades}
                        options={props.grades}
                        isOptionsFour
                        onSelect={(val) => {
                            setFieldValue("grades", val);
                        }}
                    />
                </div>

                {renderFormError(errors.formError)}

                <div className={styles.submitButton}>
                    <ButtonBig
                        disabled={isDisabled(formProps)}
                        type="submit"
                    >
                        {isSubmitting ? "Saving..." : "Save"}
                    </ButtonBig>
                    {deleteButton}
                </div>
            </form>
        );
    };

    return (
        <Formik
            initialValues={props.initialValues}
            validate={validateForm}
            onSubmit={props.onSubmit}
        >
            {renderForm}
        </Formik>
    );
};

ClassDetailsForm.defaultProps = {
    initialValues: {},
    grades: [],

    allowEditClassName: true,
    showDelete: false,

    onDeleteClass: () => { },
    onSubmit: () => { },
};

export default ClassDetailsForm;
