import React, {
    useContext,
    useEffect,
    useState,
    useRef,
} from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import text from "juice-base/text/index.js";

import User from "juice-base/project/user.js";
import DailyJuice from "juice-base/project/daily-juice.js";
import Polls from "juice-base/project/polls.js";
import Assignments from "juice-base/project/assignments.js";

import device from "juice-base/lib/device.js";
import scroll from "juice-base/lib/scroll.js";
import timer from "juice-base/lib/timer.js";

import actions from "juice-base/store/actions.js";
import vocabularyActions from "juice-base/actions/vocabulary.js";
import assignmentsActions from "juice-base/actions/assignments.js";

import AudioManagerContext from "juice-base/context/audio-manager/index.js";

import RequestLoader from "juice-base/components/request-loader/index.js";

import PopupImage from "juice-base/components/popup-image/index.js";
import PopupExtraJuice from "juice-base/components/popup-extra-juice/index.js";
import PopupPlayerAudio from "juice-base/components/popup-player-audio/index.js";
import ButtonBig from "juice-base/components/button-big/index.js";
import ButtonText from "juice-base/components/button-text/index.js";

import SwiperDailyJuiceStoriesV2 from "juice-base/business/swiper-daily-juice-stories-v2/index.js";
import SwiperDailyJuiceStoryPage from "juice-base/business/swiper-daily-juice-story-page/index.js";
import DailyJuiceNav from "juice-base/business/daily-juice-nav/index.js";
import DailyJuiceStory from "juice-base/business/daily-juice-story/index.js";
import DailyJuiceStoryPoll from "juice-base/business/daily-juice-story-poll/index.js";
import QuizQuestion from "juice-base/business/quiz-question/index.js";
import Message from "juice-base/components/message/index.js";
import PopupWord from "juice-base/business/popup-word/index.js";
import PopupTips from "juice-base/business/popup-tips/index.js";
import PopupEmojiFeedback from "juice-base/business/popup-emoji-feedback/index.js";
import PopupEmoji from "juice-base/business/popup-emoji/index.js";
import PopupDailyJuiceStatesLegend from "juice-base/business/popup-daily-juice-states-legend/index.js";

import UserPopupPolls from "juice-app/containers/user-popup-polls/index.js";

import settings from "juice-app/settings.js";
import api from "juice-app/api.js";
import events from "juice-app/events.js";

import styles from "./styles.module.css";


const storeSelector = (state) => ({
    session: state.user.session,
    user: state.user.user,
    wordPopup: state.vocabulary.popup,
    wordsByName: state.vocabulary.wordsByName,
    dimensions: state.device.dimensions,
    playerState: state.player.playerState,

    assignmentsByGroupCode: state.assignments.assignmentsByGroupCode,
});

const AssignmentStory = () => {
    const audioManager = useContext(AudioManagerContext);

    const [imagePopup, setImagePopup] = useState(null);
    const [isVisibleQuizEmojiPopup, setIsVisibleQuizEmojiPopup] = useState(false);
    const [isVisibleLegendPopup, setIsVisibleLegendPopup] = useState(false);
    const [isVisibleUserPollsPopup, setVisibleUserPollsPopup] = useState(false);
    const [tipsPopupState, setTipsPopupState] = useState({
        isOpen: false,
        feedback: [],
    });

    const [audioPlayerPopup, setAudioPlayerPopup] = useState({
        storyId: -1,
    });

    const [extraJuicePopup, setExtraJuicePopup] = useState({
        storyId: -1,
        ejId: -1,
        isVisiblePlayer: false,
    });

    const storyTimer = useRef(null);
    const extraJuiceTimer = useRef(null);

    const params = useParams();
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const store = useSelector(storeSelector);

    const isUserTeacher = User.hasRoleTeacher(store.user);

    /* --- */

    const onGoBack = () => {
        if (settings.features.ASSIGNMENTS) {
            navigate("/assignments");
            return;
        }

        // TODO: remove later with assignments feature
        navigate(`/assignments/${params.groupCode}`);
    };

    const onGoToStory = (storyId) => {
        navigate(`/assignments/${params.groupCode}/${storyId}`);
    };

    /* ------ */

    const getAssignmentId = () => {
        return store.assignmentsByGroupCode?.[params.groupCode]?.data?.id || null;
    };

    const getStoryIndex = (stories) => {
        const id = parseInt(params.storyId, 10);

        for (let i = 0; i < stories.length; i += 1) {
            if (stories[i].ID === id) {
                return i;
            }
        }

        return 0;
    };

    const getStoriesAndStory = () => {
        const stories = store.assignmentsByGroupCode?.[params.groupCode]?.data?.stories || [];
        const story = Assignments.getStoryById(stories, parseInt(params.storyId, 10));

        return {
            stories,
            story,
        };
    };

    const getExtraJuice = (ejId) => {
        const { stories } = getStoriesAndStory();

        return DailyJuice.getExtraJuiceById(stories, ejId);
    };

    const onGoNextStory = (stories) => {
        const nextStory = DailyJuice.getNextStory(stories, parseInt(params.storyId, 10));

        scroll.scrollToTop();

        if (nextStory) {
            onGoToStory(nextStory.ID);
        }
    };

    const onGoPrevStory = (stories) => {
        const prevStory = DailyJuice.getPrevStory(stories, parseInt(params.storyId, 10));

        scroll.scrollToTop();

        if (prevStory) {
            onGoToStory(prevStory.ID);
            return;
        }

        onGoBack();
    };

    const onSlideToStory = (stories, storyIndex) => {
        const story = stories[storyIndex] || null;

        if (story) {
            onGoToStory(story.ID);
        }
    };

    const isFirstStoryOpened = (stories) => {
        if (stories.length > 0 && stories[0]?.ID === parseInt(params.storyId, 10)) {
            return true;
        }

        return false;
    };

    const isLastStoryOpened = (stories) => {
        if (stories.length > 0
            && stories[stories.length - 1]?.ID === parseInt(params.storyId, 10)) {
            return true;
        }

        return false;
    };

    const onOpenUserPollsPopup = () => {
        setVisibleUserPollsPopup(true);
    };

    const onCloseUserPollsPopup = () => {
        setVisibleUserPollsPopup(false);
    };

    /* --- */

    const onOpenImagePopup = (storyId, image) => {
        events.assignment.openInfographic({
            session: store.session,
            assignmentId: getAssignmentId(),
            storyId,
            imageId: image.id,
        });

        setImagePopup(image);
    };

    const onCloseImagePopup = () => {
        setImagePopup(null);
    };

    const onOpenWordPopup = (word) => {
        dispatch(vocabularyActions.openPopup({
            api,
            actions,
            events: {
                onLoadWord(wordId) {
                    events.assignment.openVocab({
                        session: store.session,
                        assignmentId: getAssignmentId(),
                        storyId: params.storyId,
                        wordId,
                    });
                },
            },
        }, {
            session: store.session,
            word,
        }));
    };

    const onCloseWordPopup = () => {
        dispatch(vocabularyActions.closePopup({ actions }));
    };

    const onOpenExtraJuicePopup = (sId, ejId) => {
        if (sId && ejId) {
            extraJuiceTimer.current = timer.startTimer();

            setExtraJuicePopup((prev) => ({
                ...prev,
                storyId: sId,
                ejId,
            }));

            events.assignment.openExtraJuice({
                session: store.session,
                assignmentId: getAssignmentId(),
                storyId: sId,
                extraJuiceId: ejId,
            });
        }
    };

    const onCloseExtraJuicePopup = () => {
        setExtraJuicePopup((prev) => ({
            ...prev,
            storyId: -1,
            ejId: -1,
        }));
    };

    const onCloseQuizEmojiPopup = () => {
        setIsVisibleQuizEmojiPopup(false);
    };

    /* --- */

    const onPlayJuiceStory = () => {
        events.assignment.onListenStory({
            session: store.session,
            assignmentId: getAssignmentId(),
            storyId: params.storyId,
        });

        setAudioPlayerPopup((prev) => ({
            ...prev,
            storyId: params.storyId,
        }));

        audioManager.loadStory(params.storyId);
    };

    const onCloseAudioPlayerPopup = () => {
        setAudioPlayerPopup((prev) => ({
            ...prev,
            storyId: -1,
        }));
    };

    /* --- */

    const onShowExtraJuiceStoryPlayer = (extraJuiceId) => {
        setExtraJuicePopup((prev) => ({
            ...prev,
            isVisiblePlayer: true,
        }));

        audioManager.loadExtraJuice(extraJuiceId);
    };

    const onHideExtraJuiceStoryPlayer = () => {
        setExtraJuicePopup((prev) => ({
            ...prev,
            isVisiblePlayer: false,
        }));
    };

    /* --- */

    const onFormError = (error, formMethods) => {
        formMethods.setErrors({
            answer: error || text.error,
        });
        formMethods.setSubmitting(false);
    };

    const onQuestionSubmit = async (values, formMethods) => {
        const { story } = getStoriesAndStory();
        const assignmentId = getAssignmentId();

        const { ID, assignmentContentId } = story;

        if (!assignmentId || !assignmentContentId) {
            onFormError(text.error, formMethods);
            return;
        }

        events.assignment.submitQuizAnswer({
            session: store.session,
            assignmentId,
            storyId: ID,
            answerId: values.answerId,
        });

        const res = await api.assignments.setQuizAnswer({
            session: store.session,
            assignmentId,
            assignmentContentId,
            ...values,
        });

        if (!res.ok) {
            onFormError(res.error, formMethods);
            return;
        }

        const { quiz } = res;
        const quizQuestions = quiz.questions || [];

        for (let i = 0; i < quizQuestions.length; i += 1) {
            const q = quizQuestions[i];

            if (q.id === values.questionId) {
                dispatch(actions.assignments.setAssignmentStoryQuestion({
                    groupCode: params.groupCode,
                    quizId: values.quizId,
                    question: q,
                }));

                setIsVisibleQuizEmojiPopup(true);
                return;
            }
        }
    };

    const onOpenTipsPopup = (feedback) => {
        setTipsPopupState({
            isOpen: true,
            feedback,
        });
    };

    /* --- */

    const onAssignmentsLoaded = (res) => {
        if (!res.ok) {
            return;
        }

        const stories = res?.data?.stories || [];
        const storiesIds = stories.map((s) => s.ID);

        if (storiesIds.length === 0) {
            return;
        }

        const currStoryId = parseInt(params.storyId, 10);

        if (storiesIds.indexOf(currStoryId) === -1) {
            onGoToStory(storiesIds[0]);
            return;
        }

        events.assignment.openStory({
            session: store.session,
            assignmentId: res?.data?.id || null,
            storyId: currStoryId,
        });
    };

    const onLoadAssignmentStories = async () => {
        dispatch(assignmentsActions.getAssignmentStories(
            { api, actions },
            {
                session: store.session,
                groupCode: params.groupCode,
                onFinish: onAssignmentsLoaded,
            },
        ));
    };

    /* --- */

    useEffect(() => {
        onLoadAssignmentStories();
    }, []);

    useEffect(() => {
        // TODO:
        // const { story } = getStoriesAndStory();

        // if (story && !story.isUserOpenedStory) {
        //     dispatch(actions.assignments.setStoryViewed({
        //         storyId: story.ID,
        //     }));
        // }

        storyTimer.current = timer.startTimer();
    }, [params.storyId]);

    /* --- */

    const renderPlayerAudioPopup = (story) => {
        let title = "";
        let categoryName = "";
        let img = null;
        let trackGroupName = "";
        let trackId = "";
        let audioData = {};

        if (!story) {
            return null;
        }

        if (audioPlayerPopup.storyId !== -1) {
            title = story.title;
            categoryName = story.categoryName;

            if (story.featuredImage && story.featuredImage.url) {
                img = story.featuredImage.url;
            }

            trackGroupName = "juiceStories";
            trackId = story.ID;
        }

        if (!trackGroupName || !trackId) {
            return null;
        }

        audioData = store.playerState?.[trackGroupName]?.[trackId] || {};

        return (
            <PopupPlayerAudio
                key={`player-audio-story-${story.ID}`}
                image={img}
                title={title}
                category={categoryName}
                audio={audioData}
                onPlay={() => {
                    audioManager.play(trackGroupName, trackId);
                }}
                onPause={() => {
                    audioManager.pause(trackGroupName, trackId);
                }}
                onRewind={() => {
                    audioManager.rewind(trackGroupName, trackId);
                }}
                onForward={() => {
                    audioManager.forward(trackGroupName, trackId);
                }}
                onChangeRate={(rate) => {
                    audioManager.changeRate(trackGroupName, trackId, rate);
                }}
                onClose={() => {
                    audioManager.stop(trackGroupName, trackId);
                    onCloseAudioPlayerPopup();
                }}
            />
        );
    };

    const renderImagePopup = () => {
        return (
            <PopupImage
                image={imagePopup}
                onClose={onCloseImagePopup}
            />
        );
    };

    const renderWordPopup = () => {
        const trackGroupName = "words";
        const audioData = store.playerState?.[trackGroupName] || {};

        return (
            <PopupWord
                wordsByName={store.wordsByName}
                wordPopup={store.wordPopup}
                audio={audioData}
                onAudioLoad={(txt) => {
                    audioManager.loadWord(txt);
                }}
                onAudioPlay={(txt) => {
                    audioManager.play(trackGroupName, txt);
                }}
                onAudioStop={(txt) => {
                    audioManager.stop(trackGroupName, txt);
                }}
                onAudioStopAll={(words) => {
                    audioManager.stopAllTracks(trackGroupName, words);
                }}
                onClose={onCloseWordPopup}
            />
        );
    };

    const renderExtraJuicePopup = () => {
        if (extraJuicePopup.storyId === -1
            || extraJuicePopup.ejId === -1) {
            return null;
        }

        const eJuice = getExtraJuice(extraJuicePopup.ejId);

        if (!eJuice) {
            return null;
        }

        const trackGroupName = "extraJuices";
        const trackId = eJuice.id;
        let audioData = null;

        if (extraJuicePopup.isVisiblePlayer) {
            audioData = store.playerState?.[trackGroupName]?.[trackId] || null;
        }

        return (
            <PopupExtraJuice
                key={`popup-extra-juice-${extraJuicePopup.storyId}`}
                storyId={extraJuicePopup.storyId}
                extraJuice={eJuice}
                audio={audioData}
                onWordClick={(word) => {
                    onOpenWordPopup(word, extraJuicePopup.storyId, "extra-juice");
                }}
                onAudioPlay={() => {
                    onShowExtraJuiceStoryPlayer(eJuice.id);
                }}
                onPlay={() => {
                    audioManager.play(trackGroupName, trackId);
                }}
                onPause={() => {
                    audioManager.pause(trackGroupName, trackId);
                }}
                onRewind={() => {
                    audioManager.rewind(trackGroupName, trackId);
                }}
                onForward={() => {
                    audioManager.forward(trackGroupName, trackId);
                }}
                onChangeRate={(rate) => {
                    audioManager.changeRate(trackGroupName, trackId, rate);
                }}
                onPlayerClose={() => {
                    audioManager.stop(trackGroupName, trackId);
                    onHideExtraJuiceStoryPlayer();
                }}
                onClose={onCloseExtraJuicePopup}
            />
        );
    };

    const renderEmojiPopup = (story) => {
        const q = DailyJuice.getStoryQuiz(story);

        if (!q) {
            return null;
        }

        const isCorrect = DailyJuice.isValidQuiz(q);

        let exFeedback = q.explicitFeedback || [];

        if (q?.explicitFeedbackCustom?.length > 0) {
            exFeedback = q.explicitFeedbackCustom;
        }

        const feedback = DailyJuice.fixExFeedback(exFeedback, isCorrect);

        if (feedback.length === 0) {
            return (
                <PopupEmoji
                    isCorrect={isCorrect}
                    onClose={onCloseQuizEmojiPopup}
                />
            );
        }

        return (
            <PopupEmojiFeedback
                feedback={feedback}
                isCorrect={isCorrect}
                onClose={onCloseQuizEmojiPopup}
            />
        );
    };

    const renderLegendPopup = () => {
        return (
            <PopupDailyJuiceStatesLegend
                onClose={() => {
                    setIsVisibleLegendPopup(false);
                }}
            />
        );
    };

    const renderTipsPopup = () => {
        return (
            <PopupTips
                messages={tipsPopupState.feedback}
                onClose={() => {
                    setTipsPopupState({
                        isOpen: false,
                        feedback: [],
                    });
                }}
            />
        );
    };

    const renderUserPollsPopup = () => {
        return (
            <UserPopupPolls
                onClose={onCloseUserPollsPopup}
            />
        );
    };

    const renderPopups = (story) => {
        const ps = [];

        if (imagePopup) {
            ps.push(renderImagePopup());
        }

        const ejPopup = renderExtraJuicePopup();

        if (ejPopup) {
            ps.push(ejPopup);
        }

        if (store.wordPopup.isVisible) {
            ps.push(renderWordPopup());
        }

        if (audioPlayerPopup.storyId !== -1
            || audioPlayerPopup.extraJuiceId !== -1) {
            ps.push(renderPlayerAudioPopup(story));
        }

        if (isVisibleQuizEmojiPopup) {
            ps.push(renderEmojiPopup(story));
        }

        if (isVisibleLegendPopup) {
            ps.push(renderLegendPopup());
        }

        if (isVisibleUserPollsPopup) {
            ps.push(renderUserPollsPopup());
        }

        if (tipsPopupState.isOpen) {
            ps.push(renderTipsPopup());
        }

        return ps;
    };

    /* --- */

    const renderStoryNav = (stories, story) => {
        const dateTitle = settings.features.ASSIGNMENTS
            ? "Assignments"
            : "Assignment";

        return (
            <div className={styles.nav}>
                <DailyJuiceNav
                    dateTitle={dateTitle}
                    title={story.title || ""}
                    onGoToPrevStory={() => {
                        onGoPrevStory(stories);
                    }}
                    onGoToNextStory={() => {
                        onGoNextStory(stories);
                    }}
                    isFirstStoryOpened={isFirstStoryOpened(stories)}
                    isLastStoryOpened={isLastStoryOpened(stories)}
                    onBack={() => {
                        scroll.scrollToTop();
                        onGoBack();
                    }}
                />
            </div>
        );
    };

    const renderNextStoryLink = (sId) => {
        const { stories } = getStoriesAndStory();

        const nextStory = DailyJuice.getNextStory(stories, sId);

        if (!nextStory) {
            return null;
        }

        return (
            <div className={styles.nextStoryBlock}>
                <ButtonBig
                    onClick={() => {
                        onGoToStory(nextStory.ID);
                    }}
                >
                    Next
                </ButtonBig>
            </div>
        );
    };

    const renderQuiz = (story) => {
        const q = DailyJuice.getStoryQuiz(story);

        if (!q) {
            return [null, renderNextStoryLink(story.ID)];
        }

        let nextStoryLink = null;

        if (DailyJuice.isAnsweredQuiz(q)) {
            nextStoryLink = renderNextStoryLink(story.ID);
        }

        const feedback = q?.explicitFeedback || [];

        const quiz = (
            <QuizQuestion
                question={q}
                allowAnswering={!isUserTeacher}
                showAnswers={isUserTeacher}
                showFeedback={feedback.length > 0}
                onTipsClick={(() => {
                    onOpenTipsPopup(feedback);
                })}
                onSubmit={onQuestionSubmit}
            />
        );

        return [quiz, nextStoryLink];
    };

    const renderStory = (story, storyIndex) => {
        let videoCaptionSrc = null;

        if (story.featuredVideo?.id) {
            videoCaptionSrc = api.videos.getVideoCaptionURL({
                id: story.featuredVideo.id,
                session: store.session,
            });
        }

        const storyId = story.ID;

        const poll = Polls.getStoryPoll(story);

        let djStory = null;

        if (poll) {
            const pollBottomControl = (
                <ButtonText
                    uppercase
                    onClick={onOpenUserPollsPopup}
                >
                    See all polls
                </ButtonText>
            );

            djStory = (
                <DailyJuiceStoryPoll
                    storyIndex={storyIndex}
                    story={story}
                    poll={{
                        ...poll,
                        bottomControl: pollBottomControl,
                    }}
                    // TODO: with assignments
                    // onSubmitPoll={(values) => {
                    //     onSubmitPollAnswer({
                    //         ...values,
                    //         storyId,
                    //     });
                    // }}
                />
            );
        } else {
            djStory = (
                <DailyJuiceStory
                    story={story}
                    storyIndex={storyIndex}
                    dimensions={store.dimensions}
                    isDefaultVideo={!device.isChrome}
                    videoCaptionSrc={videoCaptionSrc}
                    onImageClick={(img) => {
                        onOpenImagePopup(storyId, img);
                    }}
                    onWordClick={(word) => {
                        onOpenWordPopup(word, storyId, "story");
                    }}
                    onAudioPlay={onPlayJuiceStory}
                    onExtraJuiceWordClick={onOpenExtraJuicePopup}
                />
            );
        }

        const [quiz, link] = renderQuiz(story);

        return (
            <SwiperDailyJuiceStoryPage
                story={djStory}
                quiz={quiz}
                link={link}
            />
        );
    };

    const renderPages = (stories) => {
        const pages = stories.map((story, i) => {
            return renderStory(story, i);
        });

        return pages;
    };

    /* --- */

    const { stories, story } = getStoriesAndStory();

    const error = store.assignmentsByGroupCode?.[params.groupCode]?.error || "";

    if (error) {
        return (
            <Message>
                {error}
            </Message>
        );
    }

    if (!story) {
        return (
            <RequestLoader />
        );
    }

    return (
        <>
            {renderPopups(story)}

            {renderStoryNav(stories, story)}

            <div className={styles.stories}>
                <SwiperDailyJuiceStoriesV2
                    index={getStoryIndex(stories)}
                    onSlide={(storyIndex) => {
                        onSlideToStory(stories, storyIndex);
                    }}
                    isSafari={device.isSafari}
                    isIOS15={device.isIOS15}
                    isPWA={device.isPWA}
                >
                    {renderPages(stories)}
                </SwiperDailyJuiceStoriesV2>
            </div>
        </>
    );
};

export default AssignmentStory;
