import staticFiles from "juice-base/static-files.js";
import device from "juice-base/lib/device.js";

import api from "juice-app/api.js";
import actions from "juice-base/store/actions.js";
import appActions from "juice-base/actions/app.js";
import storage from "juice-base/lib/storage/index.js";
import lazyLoader from "juice-base/lib/lazy-loader.js";

import settings from "juice-app/settings.js";


const loadVideojs = (callback) => {
    const styleParams = {
        url: staticFiles.videoCss,
        delay: 5,
    };

    lazyLoader.loadStylesheet(styleParams, () => {
        const params = {
            url: staticFiles.videoJs,
            delay: 0,
        };

        lazyLoader.loadScriptWithDelay(params, callback);
    });
};

const loadGooglePlatform = (callback) => {
    const params = {
        url: "//apis.google.com/js/platform.js",
        delay: 7,
    };

    lazyLoader.loadScriptWithDelay(params, callback);
};

/* --- */

const initGlobalErrorHandler = () => {
    if (!window) {
        return;
    }

    window.onerror = (message, url, line, column, error) => {
        const userSession = storage.session.loadSession()
            || storage.local.loadSession();

        if (!userSession) {
            return false;
        }

        const err = {
            message,
            url,
            line,
            column,
            error: error ? error.toString() : "",
        };

        api.error.sendError({
            session: userSession,
            error: err,
        });

        return false;
    };
};

const initSiteInfo = async (store) => {
    const res = await api.site.getInfo();

    if (!res.ok) {
        return;
    }

    if (res.info.sponsors) {
        store.dispatch(actions.info.setSponsors(res.info.sponsors));
    }

    if (res?.info) {
        store.dispatch(actions.info.setFeatures({
            lmsSignInUrl: res.info?.lmsSignInUrl || "",
        }));
    }
};

const initTheme = (store) => {
    store.dispatch(appActions.initTheme({
        actions,
        storage,
    }));
};

const setTitle = () => {
    const els = document.getElementsByTagName("title");

    if (els.length > 0) {
        els[0].textContent = settings.appName;
    }
};

const hideAppLoader = () => {
    const body = document.querySelector("body");

    if (body) {
        body.classList.remove("bodyLoading");
    }

    const els = document.getElementsByClassName("appLoader");

    if (els.length > 0) {
        els[0].remove();
    }
};

export const preInit = (store) => {
    loadVideojs(() => {
        store.dispatch(actions.app.setLibVideojsLoaded());
    });

    loadGooglePlatform(() => {
        store.dispatch(actions.app.setLibGAPILoaded());
    });

    setTitle();

    initGlobalErrorHandler();
    initTheme(store);
    initSiteInfo(store);

    // NOTE: check webpack for global VERSION variable
    store.dispatch(actions.app.setVersion(VERSION)); // eslint-disable-line no-undef
    store.dispatch(actions.device.setIsPWA(device.isPWA));

    store.dispatch(actions.user.setUserLoading(true));
};

export const postInit = () => {
    hideAppLoader();
};
