import React from "react";

import IconWrapper from "juice-base/icons/wrapper/index.js";


const IconSettingFilter = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    width="14"
                    height="16"
                    viewBox="0 0 14 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={iconProps.className}
                >
                    <path
                        stroke="currentColor"
                        strokeWidth="2"
                        d="M7 0V12M2 0V2M2 16V4M4 5H2H0M9 15H5M14 13H12M10 13H12M12 0V10M12 16V13M12 12V13"
                    />
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconSettingFilter;
