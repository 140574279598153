// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Zl4gwL8KIdlLMA6mrycD {\n    display: grid;\n    justify-content: center;\n}\n\n.Ienoh5LgNI1KTmRMzNNw {\n    width: 12rem;\n    height: auto;\n    margin: 2rem auto 0;\n}\n\n.BUmYEUlJnHtojYHHWn6P {\n    display: grid;\n    align-items: center;\n    justify-content: center;\n\n    width: 4rem;\n}\n\n.BUmYEUlJnHtojYHHWn6P > svg {\n    width: 2rem;\n    height: 2rem;\n}\n", "",{"version":3,"sources":["webpack://./base/business/popup-emoji-feedback/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;;IAEvB,WAAW;AACf;;AAEA;IACI,WAAW;IACX,YAAY;AAChB","sourcesContent":[".content {\n    display: grid;\n    justify-content: center;\n}\n\n.emojiImage {\n    width: 12rem;\n    height: auto;\n    margin: 2rem auto 0;\n}\n\n.feedbackIcon {\n    display: grid;\n    align-items: center;\n    justify-content: center;\n\n    width: 4rem;\n}\n\n.feedbackIcon > svg {\n    width: 2rem;\n    height: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "Zl4gwL8KIdlLMA6mrycD",
	"emojiImage": "Ienoh5LgNI1KTmRMzNNw",
	"feedbackIcon": "BUmYEUlJnHtojYHHWn6P"
};
export default ___CSS_LOADER_EXPORT___;
