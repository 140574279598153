const welcomeToJuice = "Welcome to the Juice!";

/* --- */

const error = "Something went wrong.";

const errorTryAgain = "Something went wrong. Please try again.";

const emailSentSuccess = "Email successfully sent!";

const emailUpdatedSuccess = "Email successfully updated!";

const passwordUpdated = "Password successfully updated!";

const passwordChanged = "Password successfully changed!";

const studentResetPassword = `
The student's password for The Juice was successfully reset.
Since they do not have an email address on record, make sure to tell them their new password.
`;

const categoryFilter = "Category Filters";
const selectCategories = "Select one or more categories to filter search content";

const noStudentAnswers = "The student has not yet answered any questions in this issue of the Daily Juice.";
const noStudentDailyJuices = "The student has no Daily Juices.";

const noJuicesInRange = "No Daily Juices published in the selected date range";
const noAssignmentsInRange = "No Assignments found in the selected date range";

const noQuizResults = `
Looks like we don't have enough data to show you quiz results yet.
Check back again soon!
`;

const noData = "No data found";
const noExtraJuices = "No Extra Juices found.";
const noStandards = "No Standards found.";
const noVideos = "No videos found.";
const noClosedPolls = "Closed polls not found. Please check again later.";
const noAssignments = "No assignments have been completed by the student.";
const noDataBySchools = "No data by schools found.";
const noClassesWithStudents = "No classes with students found";
const noAssignmentsFound = "No assignments found.";
const noTeachersFound = "No Teachers found.";

/* --- */

const assignmentCompleted = "All assignments completed!";
const assignmentCreated = "This assignment was successfully created!";
const assignmentCopied = "The link to assignment has been copied to your clipboard.";

/* --- */

const alreadySigned = `
Hmm. You are already signed in to The Juice so we cannot create a new account for you.
`;

/* --- */

const trialRegistration1 = `
Re-engage students with daily real-world content that
motivates them to achieve success in reading, vocabulary,
and 21st century skills.
`;

const trialRegistration2 = `
Educators, let's get you set up! What's your role?
`;

const trialRegistration3 = `
Students, you can not login to the product here.
Please check with your instructor for your student credentials and login URL.
`;

const trialRegistration4 = `
We are pleased to offer you a free trial of The Juice.
Your free trial will allow you to  experience all of the daily student materials
plus the full array of teacher content and reports.
`;

const trialRegistration5 = `
Students receive standards-aligned engaging stories every day at their reading level along with vocabulary enrichment,
read alouds to scaffold support, infographics to develop quantitative skills, and questions to encourage critical thinking.
Teachers receive real-time feedback on student performance against the key learning standards addressed in questions associated with each current events story presented.
Sign up now to see for yourself.
The process takes about 30 seconds to complete.
`;

const trialCreateAccount1 = `
In order for you to see the performance of your classes on key standards,
and to assign content for intervention, you'll need to create an account.
`;

const trialCreateAccount2 = `
In order for you to see how one of your teachers might use The Juice, we will provision your trial account as a single teacher.
`;

const trialCreateAccount3 = `
In order for you to see how you might use The Juice for your homeschooling, we will provision your trial account as a single teacher.
`;

const trialCreateAccount4 = `
Keep in mind that The Juice is designed to provide schools and districts individual accounts for their specific teachers, each one administered at the level you see fit.
Your trial account will provide insight into the performance tracking capabilities of The Juice and see how it can be used with multiple classes and students
We only ask for bare minimum information from you during this setup process.
`;

const trialCreateAccount5 = `
Keep in mind that The Juice is designed to provide you an individual "teacher" account under which you can add your children and/or students.
Your trial account will provide insight into the performance tracking capabilities. We only ask for bare minimum information from you during this setup process.
`;

const purchaseSubscription1 = `
Thank you for purchasing a subscription.
To complete your subscription, we need to gather information about you and your initial class, then collect payment information.
When you complete the process, you will be ready to start with your new Juice account.
`;

const purchaseSubscription2 = `
If you don't complete the process or get interrupted - don't worry, when you come back we will complete the signup process!
`;

/* --- */

const userHasNoPaymentPlans = `
For plan details, please contact your system administrator or customer support
`;

/* --- */

const restorePasswordStudent = `
We let your teacher know that you need a new password.
They're probably great and will help you out as soon as they're able to do so.
Until you're back in The Juice, check out some of the fresh stories on our news aggregator.
`;

const restorePasswordViaEmail = `
Huzzah! A fresh reset link is on its way! Check your email.
`;

const restorePasswordLMSUser = `
Your account is managed by your school's LMS system.
Please contact your LMS administrator for password reset help.
`;

/* --- */

const teacherAdministratorSchoolDetails = `
On this panel, you will provide your country, state, school and district information.
For the School District field, please indicate the school district you work for, <strong>or overwrite the field with N/A.</strong>
If you are not part of a school, <strong>overwrite the School Name field with N/A.</strong>
`;

const teacherOrganizationSchoolDetails = `
On this panel, you will provide your country, state, school and district information.
For the School District field, please overwrite the field with <strong>your company or organization name.</strong>
For the School field, overwrite the field with your <strong>job title or N/A.</strong>
`;

const teacherAdministratorClassSetup1 = `
Your free trial of The Juice provides you with immediate access to evaluate all of the tools in the teacher portal.
To complete the Department field, <strong>use one of the drop-down menu options, type in your own custom department name, or type in N/A.</strong>
You will create your own class name. For reading level, pick an option from the drop-down menu.
You can adjust this later on in the teacher portal.
`;

const teacherAdministratorClassSetup2 = `
To evaluate the student material, you will need to go into the class you have just created and then add yourself as a student.
You will need to use a different email to set yourself up as a student as our system only allows an email to be assigned to a single account.
`;

const teacherOrganizationClassSetup1 = `
Your free trial of The Juice provides you with immediate access to evaluate all of the tools in the teacher portal.
To complete the Department field, <strong>use one of the drop-down menu options, type in your own custom department name, or type in N/A.</strong>
You will create your own class name. For reading level, pick an option from the drop-down menu. You can adjust this later on in the teacher portal.
`;

const teacherOrganizationClassSetup2 = `
To evaluate the student material, you will need to go into the class you have just created and then add yourself as a student.
You will need to use a different email to set yourself up as a student as our system only allows an email to be assigned to a single account.
`;

/* --- */

const teacherSignUpForm1 = `
We have already pre-populated this form with most of the schools and districts across the USA.
If your location is not on the list, or you are from another country,
you can fill in your own school and/or district in the associated text box.
`;

const teacherSignUpForm2 = `
Let's finish setting up your account. Where do you teach?
`;

const teacherSignUpForm3 = `
We have already partially pre-populated this form for you and just need to know your general location.
`;

const teacherSignUpForm4 = `
Almost done.
We ask for this information to help set up your first class and establish the default reading level so we know what reading level to provide your students.
`;

const teacherSignUpForm5 = `
Almost done. You just need to set up your first class.
`;

const teacherSignUpForm6 = `
We ask for this information to help set up your homeschool class and
establish the default reading level so we know what reading level to provide your students.
`;

const teacherSignUpForm7 = `
That's it. You should receive a brief welcome letter shortly, to confirm your account and provide additional details to make your trial productive.
Be on the lookout for a few other emails that provide details about key features of The Juice.
`;

const teacherSignUpForm8 = `
To make sure you and your students receive our daily emails, add @thejuice.news and fresh@thejuice.news to your safe sender list.
`;

const teacherSignUpForm9 = `
And, each morning you will receive a short email that summarizes the stories in that day's Juice with a link to go experience all the features of The Juice.
`;

/* --- */

const teacherByEmailNotFound = "The teacher with this email address was not found in the system. Please enter teacher first and last name.";

/* --- */

const inactiveStudent = `
Hmmm. Your account appears to have been removed from the class.
Please contact your teacher or customer support at <a href="mailto:customersupport@thejuice.news">customersupport@thejuice.news</a>
`;

const inactiveTeacher = `
Hmmm. Your account appears to be inactive.
Please contact customer support at <a href="mailto:customersupport@thejuice.news">customersupport@thejuice.news</a>
`;

/* --- */

const automaticThemeDescription = `
Automatically switch between light and dark modes based on your system settings.
`;

/* --- */

const classTableDescription = "Correct answers in completed Daily Juices";

/* --- */

const blockProgressError = `
We can't load the graphics, the data is not correct.
Please try again later or contact support.
`;

const chartRadarError = `
We can't load the graphics, the data is not correct.
Please try again later or contact support.
`;

/* --- */

const usersInvalidEmail = `
Emails to one or more users are not working properly.
Please check user emails.
`;

const userInvalidEmail = `
Emails to this user are not working - please update email address.
`;

/* --- */

const confirmLMSClassRemovalTitle = "This operation will remove classes from your juice account.";

const confirmLMSClassRemoval = `
Are you sure you want to proceed?
Press Cancel or OK to proceed.
`;


/* --- */

const issueNotAvailable = "Issue not available";
const cantLoadJuice = "Oops. We can't load Daily Juice";

/* --- */

export default {
    welcomeToJuice,

    error,
    errorTryAgain,

    emailSentSuccess,
    emailUpdatedSuccess,
    passwordUpdated,
    passwordChanged,
    studentResetPassword,
    selectCategories,
    categoryFilter,

    noData,
    noStudentAnswers,
    noStudentDailyJuices,
    noJuicesInRange,
    noAssignmentsInRange,
    noQuizResults,
    noExtraJuices,
    noVideos,
    noClosedPolls,
    noStandards,
    noAssignments,
    noDataBySchools,
    noClassesWithStudents,
    noAssignmentsFound,
    noTeachersFound,

    assignmentCompleted,
    assignmentCreated,
    assignmentCopied,

    alreadySigned,

    trialRegistration1,
    trialRegistration2,
    trialRegistration3,
    trialRegistration4,
    trialRegistration5,

    trialCreateAccount1,
    trialCreateAccount2,
    trialCreateAccount3,
    trialCreateAccount4,
    trialCreateAccount5,

    purchaseSubscription1,
    purchaseSubscription2,

    userHasNoPaymentPlans,

    restorePasswordStudent,
    restorePasswordViaEmail,
    restorePasswordLMSUser,

    teacherAdministratorSchoolDetails,
    teacherOrganizationSchoolDetails,

    teacherAdministratorClassSetup1,
    teacherAdministratorClassSetup2,
    teacherOrganizationClassSetup1,
    teacherOrganizationClassSetup2,

    teacherSignUpForm1,
    teacherSignUpForm2,
    teacherSignUpForm3,
    teacherSignUpForm4,
    teacherSignUpForm5,
    teacherSignUpForm6,
    teacherSignUpForm7,
    teacherSignUpForm8,
    teacherSignUpForm9,

    teacherByEmailNotFound,

    inactiveStudent,
    inactiveTeacher,

    automaticThemeDescription,
    classTableDescription,

    blockProgressError,
    chartRadarError,

    usersInvalidEmail,
    userInvalidEmail,

    confirmLMSClassRemovalTitle,
    confirmLMSClassRemoval,

    issueNotAvailable,
    cantLoadJuice,
};
