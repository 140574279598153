import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import Students from "juice-base/project/students.js";
import Grades from "juice-base/project/grades.js";
import Classes from "juice-base/project/classes.js";

import array from "juice-base/lib/array.js";
import date from "juice-base/lib/date.js";
import urls from "juice-base/lib/urls.js";
import storage from "juice-base/lib/storage/index.js";

import siteActions from "juice-base/actions/site.js";
import teacherActions from "juice-base/actions/teacher.js";
import actions from "juice-base/store/actions.js";
import text from "juice-base/text/index.js";

import useSnackbar from "juice-base/hooks/use-snackbar/index.js";
import useAddClassPopup from "juice-base/hooks/use-add-class-popup/index.js";
import useEditClassPopup from "juice-base/hooks/use-edit-class-popup/index.js";

import RequestLoader from "juice-base/components/request-loader/index.js";
import Snackbar from "juice-base/components/snackbar/index.js";
import PopupConfirmError from "juice-base/components/popup-confirm-error/index.js";

import PopupClassAdd from "juice-base/business/popup-class-add/index.js";
import PopupClassDetails from "juice-base/business/popup-class-details/index.js";
import TeacherClassTable from "juice-base/business/teacher-class-table/index.js";

import UserFooter from "juice-app/containers/user-footer/index.js";
import TeacherPopupLMS from "juice-app/containers/teacher-popup-lms/index.js";
import TeacherPopupStudentsRemove from "juice-app/containers/teacher-popup-students-remove/index.js";
import PopupFullScreenAddStudentV2 from "juice-app/containers/popup-full-screen-add-student-v2/index.js";

import settings from "juice-app/settings.js";
import api from "juice-app/api.js";
import events from "juice-app/events.js";

import styles from "./styles.module.css";


const storeSelector = (state) => ({
    dimensions: state.device.dimensions,
    urlParams: state.navigation.params,

    session: state.user.session,
    user: state.user.user,

    studentsNotifications: state.notifications.studentsNotifications,
    teacher: state.teacher,
    juices: state.juices,
    info: state.info,
});

const ClassV2 = () => {
    const [isVisibleAddStudentsPopup, setIsVisibleAddStudentsPopup] = useState(false);
    const [isLMSPopupVisible, setIsLMSProviderVisible] = useState(false);

    const [sortTableBy, setSortTableBy] = useState(() => Students.StudentsSortByValues.nameATOZ);

    const [removeStudentsState, setRemoveStudentsState] = useState({
        isVisiblePopup: false,
        students: [],
    });

    /* ------- */

    const dispatch = useDispatch();
    const store = useSelector(storeSelector);

    const navigate = useNavigate();

    const addClassPopup = useAddClassPopup();
    const editClassPopup = useEditClassPopup();
    const snackbar = useSnackbar();

    const isLMS = settings.features.LMS && store.user.isLmsUser;

    const isMobile1025 = store.dimensions.width < 1025;
    const isMobile900 = store.dimensions.width < 900;
    const isMobile500 = store.dimensions.width < 500;

    /* ------- */

    const selectedRange = useMemo(() => {
        let dateFrom = null;
        let dateTo = null;

        if (store.urlParams.dateFrom && store.urlParams.dateTo) {
            dateFrom = date.tryFormatDateUTC(store.urlParams.dateFrom, date.getDateFromDate);
            dateTo = date.tryFormatDateUTC(store.urlParams.dateTo, date.getDateFromDate);
        }

        return {
            dateFrom,
            dateTo,
        };
    }, [
        store.urlParams.dateFrom,
        store.urlParams.dateTo,
    ]);

    /* ------- */

    const getClassStudentsScores = (classId, dates) => {
        const range = `${dates.dateFrom}-${dates.dateTo}`;

        return store.teacher.classesStudentsScoresByRange?.[classId]?.[range] || {};
    };

    const getCurrentWeekRange = () => {
        const currWeekdays = date.getWeekDaysByDate(new Date());

        const dateFrom = currWeekdays[0];
        const dateTo = currWeekdays[currWeekdays.length - 1];

        return {
            dateFrom: date.tryFormatDateUTC(dateFrom, date.getDateFromDate),
            dateTo: date.tryFormatDateUTC(dateTo, date.getDateFromDate),
        };
    };

    /* ------- */

    const onLoadSiteDate = async () => {
        dispatch(siteActions.loadSiteDate({
            api,
            actions,
        }));
    };

    const onLoadTeacherClasses = async (params = {}) => {
        dispatch(teacherActions.loadAllClasses(
            { api, actions },
            {
                session: store.session,
                onFinish: () => {
                    if (params.onFinish) {
                        params.onFinish();
                    }
                },
            },
        ));
    };

    const reloadTeacherClasses = async () => {
        const res = await api.classes.getTeacherClasses({
            session: store.session,
        });

        if (!res.ok) {
            return;
        }

        const classes = res.classes || [];

        if (classes.length === 0) {
            return;
        }

        const classId = array.last(classes).id;

        storage.local.saveTeacherSelectedClass(classId);

        dispatch(actions.teacher.setTeacherSelectedClass({
            selectedClassId: classId,
        }));

        dispatch(actions.teacher.setMaxClasses({
            maxClasses: res.maxClasses || null,
        }));

        dispatch(actions.teacher.setClasses({
            classes,
        }));
    };

    const onLoadClassStudents = async (params = {}) => {
        const { classId, dates, forceUpdate } = params;

        const scores = getClassStudentsScores(classId, dates);

        if (scores?.data && !forceUpdate) {
            return;
        }

        dispatch(teacherActions.loadClassScores(
            { api, actions },
            {
                session: store.session,
                classId,
                dateFrom: dates.dateFrom,
                dateTo: dates.dateTo,
            },
        ));
    };

    /* --- */

    const onAddClass = async (values, { setSubmitting, setErrors }) => {
        const res = await api.classes.addClass({
            session: store.session,
            className: values.className,
            grade: values.grades?.value,
        });

        if (!res.ok) {
            setErrors({
                formError: res.error || text.error,
            });

            setSubmitting(false);
            return;
        }

        addClassPopup.close();

        reloadTeacherClasses();

        setSubmitting(false);
    };

    const onOpenAddClassPopup = () => {
        addClassPopup.open();
    };

    const onCloseAddClassPopup = () => {
        addClassPopup.close();
    };

    /* --- */

    const onDeleteClass = async (classId, className) => {
        const res = await api.classes.deleteClass({
            session: store.session,
            classId,
        });

        if (res.ok) {
            snackbar.add({
                autoCloseInSeconds: 3,
                message: `"${className}" successfully deleted.`,
            });

            onLoadTeacherClasses({
                onFinish: () => {
                    editClassPopup.setIsDeleting(false);
                },
            });
        } else {
            editClassPopup.setDeletingError(res.error || "Error!");
        }
    };

    const onChangeClassDetails = async (values, classId, formParams) => {
        const res = await api.classes.updateClass({
            session: store.session,
            classId,
            className: values.className,
            grade: values.grades?.value,
        });

        if (res.ok) {
            editClassPopup.close();

            onLoadTeacherClasses();
            onLoadClassStudents({
                forceUpdate: true,
                classId,
                dates: selectedRange,
            });
        } else {
            formParams.setErrors({
                formError: res.error || text.error,
            });
        }

        formParams.setSubmitting(false);
    };

    const onExportSelectedWeek = () => {
        events.teacher.teacherExportClassWeekScores({
            session: store.session,
            classId: store.teacher.selectedClassId,
        });

        const dateFrom = date.tryFormatDateUTC(selectedRange.dateFrom, date.getDateFromDate);
        const dateTo = date.tryFormatDateUTC(selectedRange.dateTo, date.getDateFromDate);

        const exportUrl = api.export.getClassScoresByRange({
            session: store.session,
            classId: store.teacher.selectedClassId,
            sortBy: Students.findSortKeyByValue(sortTableBy),
            dateFrom,
            dateTo,
        });

        urls.universalDownload(exportUrl);
    };

    const onExportAllScores = () => {
        events.teacher.teacherExportClassScores({
            session: store.session,
            classId: store.teacher.selectedClassId,
        });

        const exportUrl = api.export.getClassScoresByRange({
            session: store.session,
            classId: store.teacher.selectedClassId,
            sortBy: Students.findSortKeyByValue(sortTableBy),
            dateFrom: "",
            dateTo: "",
        });

        urls.universalDownload(exportUrl);
    };

    /* ------- */

    const onLoadClassScoresWithClear = (classId) => {
        dispatch(actions.teacher.clearStudentsScoresByRange());

        onLoadClassStudents({
            forceUpdate: true,
            classId,
            dates: selectedRange,
        });
    };

    const onOpenStudentInfo = (studentId) => {
        events.classes.openStudentInfo({
            session: store.session,
            classId: store.teacher.selectedClassId,
        });

        navigate(`/class/${store.urlParams.dateFrom}/${store.urlParams.dateTo}/${studentId}/student-overview`);
    };

    const onRangeChange = (dates) => {
        navigate(`/class/${dates.dateFrom}/${dates.dateTo}`);
    };

    const onSortStudentsByName = () => {
        const { StudentsSortByValues } = Students;

        let sortByValue = "";

        if (sortTableBy === StudentsSortByValues.nameATOZ) {
            sortByValue = StudentsSortByValues.nameZTOA;
        } else {
            sortByValue = StudentsSortByValues.nameATOZ;
        }

        setSortTableBy(sortByValue);
    };

    const onClosePopupStudentsRemove = () => {
        setRemoveStudentsState({
            isVisiblePopup: false,
            students: [],
        });
    };

    const onOpenRemoveStudentsPopup = (students) => {
        if (students.length === 0) {
            return;
        }

        setRemoveStudentsState({
            isVisiblePopup: true,
            students,
        });
    };

    const onClassChange = (classId) => {
        events.classes.changeClass({
            session: store.session,
            classId,
        });

        dispatch(actions.teacher.setTeacherSelectedClass({
            selectedClassId: classId,
        }));

        storage.local.saveTeacherSelectedClass(classId);
    };

    const onEditClass = (classId) => {
        editClassPopup.open(classId);
    };

    const onCloseEditClassPopup = () => {
        editClassPopup.close();
    };

    const onOpenAddStudentPopup = () => {
        setIsVisibleAddStudentsPopup(true);
    };

    const onDeleteClassClick = (classId, className) => {
        editClassPopup.setIsDeleting(true);

        snackbar.add({
            isVisibleUndoButton: true,
            id: classId,
            message: `"${className}" will be deleted from your account.`,
            onCloseWithUndo: () => {
                editClassPopup.setIsDeleting(false);
            },
            onClose: () => {
                onDeleteClass(classId, className);
            },
        });
    };

    const onCloseSnackbar = (params = {}) => {
        snackbar.close(params.index);

        if (params.withUndo) {
            params.onCloseWithUndo();
        } else {
            params.onClose();
        }
    };

    /* ------- */

    useEffect(() => {
        onLoadTeacherClasses();
        onLoadSiteDate();

        return () => {
            dispatch(actions.teacher.clearStudentsScoresByRange());
        };
    }, []);

    useEffect(() => {
        const { isClassesLoaded, selectedClassId } = store.teacher;

        if (!isClassesLoaded || selectedClassId === -1) {
            return;
        }

        dispatch(teacherActions.loadClassNotifications({ api, actions }, {
            session: store.session,
            classId: selectedClassId,
        }));

        if (selectedRange.dateFrom && selectedRange.dateTo) {
            onLoadClassStudents({
                classId: selectedClassId,
                dates: selectedRange,
            });
        } else {
            const currWeekRange = getCurrentWeekRange();

            onRangeChange(currWeekRange);
        }
    }, [
        selectedRange,
        store.teacher.selectedClassId,
        store.teacher.isClassesLoaded,
    ]);

    useEffect(() => {
        const { classes, isClassesLoaded, selectedClassId } = store.teacher;

        if (!isClassesLoaded) {
            return;
        }

        let classId = storage.local.loadTeacherSelectedClass();

        if (!classId) {
            classId = selectedClassId === -1
                ? classes?.[0]?.id || -1
                : selectedClassId;
        }

        dispatch(actions.teacher.setTeacherSelectedClass({
            selectedClassId: classId,
        }));
    }, [store.teacher.isClassesLoaded]);

    /* ------- */

    const renderAddClassPopup = () => {
        if (!addClassPopup.state.isOpen) {
            return null;
        }

        return (
            <PopupClassAdd
                grades={Grades.getGradesOptions()}
                onSubmit={onAddClass}
                onClose={onCloseAddClassPopup}
            />
        );
    };

    const renderLMSPopup = () => {
        if (!isLMSPopupVisible) {
            return null;
        }

        return (
            <TeacherPopupLMS
                onSuccess={() => {
                    onLoadTeacherClasses();
                    setIsLMSProviderVisible(false);
                }}
                onClose={() => {
                    setIsLMSProviderVisible(false);
                }}
            />
        );
    };

    const renderAddStudentsPopup = () => {
        if (!isVisibleAddStudentsPopup) {
            return null;
        }

        const { classes, selectedClassId } = store.teacher;

        const classLimit = Classes.getClassLimit(classes, selectedClassId);
        const classStudentsCount = Classes.getStudentsCount(classes, selectedClassId);
        const classGrade = Classes.getClassGradeById(classes, selectedClassId);

        const defaultGrade = classGrade
            ? Grades.getValidGrade(classGrade)
            : Grades.getMaxGradeValue();

        return (
            <PopupFullScreenAddStudentV2
                classId={selectedClassId}
                classLimit={classLimit}
                defaultSelectedMenu={1}
                defaultGrade={defaultGrade}
                classStudentsCount={classStudentsCount}
                onLoadClass={() => {
                    onLoadTeacherClasses();
                    onLoadClassScoresWithClear(selectedClassId);
                }}
                onClose={() => {
                    setIsVisibleAddStudentsPopup(false);
                }}
            />
        );
    };

    const renderStudentRemovingPopup = () => {
        if (!removeStudentsState.isVisiblePopup) {
            return null;
        }

        const data = getClassStudentsScores(store.teacher.selectedClassId, selectedRange);
        const students = data?.data?.students || [];

        const selectedStudents = [];

        for (let i = 0; i < students.length; i += 1) {
            const s = students[i];

            if (removeStudentsState.students.indexOf(s.id) !== -1) {
                selectedStudents.push(s);
            }
        }

        return (
            <TeacherPopupStudentsRemove
                students={selectedStudents}
                onClose={onClosePopupStudentsRemove}
                onCloseWithFinishedStatus={() => {
                    onLoadTeacherClasses();
                    onLoadClassScoresWithClear(store.teacher.selectedClassId);
                    onClosePopupStudentsRemove();
                }}
            />
        );
    };

    const renderEditClassPopup = () => {
        if (!editClassPopup.state.isOpen) {
            return null;
        }

        const className = Classes.getClassNameById(
            store.teacher.classes,
            editClassPopup.state.classId,
        );

        const classGrade = Classes.getClassGradeById(
            store.teacher.classes,
            editClassPopup.state.classId,
        );

        const classGradeGroup = Grades.getValidGrade(classGrade);

        const initialValues = {
            className,
            grades: {
                value: classGradeGroup,
                isCreated: false,
            },
        };

        const canDeleteClass = store.teacher.classes.length > 1 && !isLMS;

        return (
            <PopupClassDetails
                initialValues={initialValues}
                grades={Grades.getGradesOptions()}
                allowEditClassName={!isLMS}
                allowDelete={canDeleteClass}
                onDelete={(() => {
                    onDeleteClassClick(editClassPopup.state.classId, initialValues.className);
                })}
                onSubmit={(values, formParams) => {
                    onChangeClassDetails(values, editClassPopup.state.classId, formParams);
                }}
                onClose={onCloseEditClassPopup}
            />
        );
    };

    const renderConfirmErrorDeleteClass = () => {
        if (!editClassPopup.state.deletingError) {
            return null;
        }

        return (
            <PopupConfirmError
                error={editClassPopup.state.deletingError}
                onClose={() => {
                    editClassPopup.setDeletingError(null);
                }}
            />
        );
    };

    const renderSnackbars = () => {
        return snackbar.state.map((bar, index) => {
            return (
                <Snackbar
                    isVisibleUndoButton={bar.isVisibleUndoButton}
                    autoCloseInSeconds={bar.autoCloseInSeconds}
                    message={bar.message}
                    onClose={(params) => {
                        onCloseSnackbar({
                            ...params,
                            index,
                            onCloseWithUndo: () => {
                                if (bar.onCloseWithUndo) {
                                    bar.onCloseWithUndo();
                                }
                            },
                            onClose: () => {
                                if (bar.onClose) {
                                    bar.onClose();
                                }
                            },
                        });
                    }}
                />
            );
        });
    };

    const renderContent = () => {
        const {
            isClassesLoaded,
            selectedClassId,
        } = store.teacher;

        if (!isClassesLoaded) {
            return (
                <RequestLoader />
            );
        }

        const data = getClassStudentsScores(selectedClassId, selectedRange);
        const students = data?.data?.students || [];

        const range = {
            dateFrom: date.newDateUTC(selectedRange.dateFrom),
            dateTo: date.newDateUTC(selectedRange.dateTo),
        };

        const sortedStudents = Students.sortBy(sortTableBy, students, false);

        const classesOptions = store.teacher.classes.map((cl) => ({
            value: cl.id,
            label: cl.title,
            isBounceNotificationsExists: cl.isBounceNotificationsExists,
        }));

        const notifications = store.studentsNotifications[selectedClassId] || [];

        return (
            <TeacherClassTable
                isLoading={data?.isLoading || false}
                isMobileTable={isMobile900}
                isMenuFullWidth={isMobile500}
                isMenuOpensRight={isMobile1025}
                isLMS={isLMS}
                selectedClassId={selectedClassId}
                classes={classesOptions}
                maxClasses={store.teacher.maxClasses}
                error={data?.error || ""}
                sortBy={sortTableBy}
                siteDate={store.info.siteDate}
                selectedRange={range}
                students={sortedStudents}
                studentsNotifications={notifications}
                dates={data?.data?.dates || []}
                onAddNewClass={onOpenAddClassPopup}
                onSyncLMSClasses={() => {
                    setIsLMSProviderVisible(true);
                }}
                onEditClass={onEditClass}
                onClassChange={onClassChange}
                onOpenAddStudentPopup={onOpenAddStudentPopup}
                onRemoveStudents={onOpenRemoveStudentsPopup}
                onOpenStudentInfo={onOpenStudentInfo}
                onSortStudentsByName={onSortStudentsByName}
                onExportSelectedWeek={onExportSelectedWeek}
                onExportAllScores={onExportAllScores}
                onRangeChange={(dates) => {
                    const newRange = {
                        dateFrom: date.tryFormatDate(dates.dateFrom, date.getDateFromDate),
                        dateTo: date.tryFormatDate(dates.dateTo, date.getDateFromDate),
                    };

                    onRangeChange(newRange);
                }}
            />
        );
    };

    return (
        <>
            {renderAddClassPopup()}
            {renderLMSPopup()}
            {renderAddStudentsPopup()}
            {renderStudentRemovingPopup()}
            {renderEditClassPopup()}
            {renderConfirmErrorDeleteClass()}
            {renderSnackbars()}

            <div className={styles.index}>
                {renderContent()}
            </div>

            <UserFooter />
        </>
    );
};

export default ClassV2;
