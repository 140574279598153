// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VRmznCXeHzguNdINolDm {}\n\n.k5KCxWw17lNOowDONRDb {\n    margin: 3rem 0 1rem 0;\n}\n\n.QhDKy5aOqNMQtrcNFABJ {\n    margin-top: 2.5rem;\n    display: grid;\n}\n", "",{"version":3,"sources":["webpack://./base/forms/user-sign-up-password/styles.module.css"],"names":[],"mappings":"AAAA,uBAAO;;AAEP;IACI,qBAAqB;AACzB;;AAEA;IACI,kBAAkB;IAClB,aAAa;AACjB","sourcesContent":[".form {}\n\n.message {\n    margin: 3rem 0 1rem 0;\n}\n\n.submitButtonBlock {\n    margin-top: 2.5rem;\n    display: grid;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "VRmznCXeHzguNdINolDm",
	"message": "k5KCxWw17lNOowDONRDb",
	"submitButtonBlock": "QhDKy5aOqNMQtrcNFABJ"
};
export default ___CSS_LOADER_EXPORT___;
