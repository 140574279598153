import React from "react";

import useTitle from "juice-base/hooks/use-title/index.js";

import { withAuth } from "juice-base/components/auth/index.js";

import StudentDailyJuiceStory from "juice-app/containers/student-daily-juice-story/index.js";


const DailyJuiceStory = () => {
    useTitle(() => "Daily Juice story", []);

    return (
        <StudentDailyJuiceStory />
    );
};

export default withAuth([
    "student",
    "teacher",
    "guardian",
])(DailyJuiceStory);
