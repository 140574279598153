
export const getSignupOptions = () => {
    return [
        { value: "teacher", name: "Teacher" },
        { value: "homeschooler", name: "Homeschooler" },
        { value: "administrator", name: "Administrator" },
        { value: "organization-or-other", name: "Organization / Other" },
    ];
};

export default {
    getSignupOptions,
};
