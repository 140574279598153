import React from "react";

import IconWrapper from "juice-base/icons/wrapper/index.js";


const IconGradesG5G6 = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={iconProps.className}
                >
                    {iconProps.title}
                    <rect
                        width="40"
                        height="40"
                        rx="20"
                        fill="#D946EF"
                    />
                    <path
                        d="M8 14.8H13.2V17.4H8V20H13.2C14.63 20 15.8 18.83 15.8 17.4V14.8C15.8 13.37 14.63 12.2 13.2 12.2H10.6V9.6H15.8V7H8V14.8Z"
                        fill="#ffffff"
                    />
                    <path
                        d="M24 23.6V31.4C24 32.83 25.17 34 26.6 34H29.2C30.63 34 31.8 32.83 31.8 31.4V28.8C31.8 27.37 30.63 26.2 29.2 26.2H26.6V23.6H31.8V21H26.6C25.17 21 24 22.17 24 23.6ZM29.2 28.8V31.4H26.6V28.8H29.2Z"
                        fill="#ffffff"
                    />
                    <mask
                        id="gradesG5G6IconMask"
                        style={{ maskType: "alpha" }}
                        width="40"
                        height="40"
                    >
                        <circle
                            cx="20"
                            cy="20"
                            r="20"
                            fill="#D946EF"
                        />
                    </mask>
                    <g mask="url(#gradesG5G6IconMask)">
                        <path
                            d="M30.0862 1.46683L31.9624 2.28911L9.87622 37.3742L7.99999 36.552L30.0862 1.46683Z"
                            fill="#ffffff"
                        />
                    </g>
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconGradesG5G6;
