export const T2S_SET_JUICE_STORY_LOADING = "T2S_SET_JUICE_STORY_LOADING";
export const T2S_SET_JUICE_STORY_ERROR = "T2S_SET_JUICE_STORY_ERROR";
export const T2S_SET_JUICE_STORY = "T2S_SET_JUICE_STORY";
export const T2S_CLEAR_JUICE_STORY = "T2S_CLEAR_JUICE_STORY";

export const T2S_SET_EXTRA_JUICE_LOADING = "T2S_SET_EXTRA_JUICE_LOADING";
export const T2S_SET_EXTRA_JUICE_ERROR = "T2S_SET_EXTRA_JUICE_ERROR";
export const T2S_SET_EXTRA_JUICE = "T2S_SET_EXTRA_JUICE";
export const T2S_CLEAR_EXTRA_JUICE = "T2S_CLEAR_EXTRA_JUICE";

export const T2S_SET_WORD_LOADING = "T2S_SET_WORD_LOADING";
export const T2S_SET_WORD_ERROR = "T2S_SET_WORD_ERROR";
export const T2S_SET_WORD = "T2S_SET_WORD";
export const T2S_CLEAR_WORD = "T2S_CLEAR_WORD";
