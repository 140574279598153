import React from "react";

import { Formik } from "formik";

import ButtonBig from "juice-base/components/button-big/index.js";
import Input from "juice-base/components/forms/input/index.js";
import v from "juice-base/lib/form-validators.js";

import styles from "./styles.module.css";


const UserEmailUnsubscribe = (props) => {
    const isDisabled = (formProps) => {
        return formProps.errors.email
            || formProps.isSubmitting;
    };

    const validateForm = (values) => {
        const errorsEmail = v.validate(values.email, [
            v.required("Please enter email."),
            v.email("Sorry, that email address isn't valid. Please enter a valid one and try again."),
        ]);

        const errors = {};

        if (errorsEmail) {
            errors.email = errorsEmail;
        }

        return errors;
    };

    const renderForm = (formProps) => {
        const {
            values,
            handleChange,
            handleBlur,
            handleSubmit,
        } = formProps;

        return (
            <form onSubmit={handleSubmit}>
                <Input
                    name="email"
                    label="Email Address"
                    value={values.email}
                    error={v.getError(formProps, "email")}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />

                <div className={styles.buttons}>
                    <ButtonBig
                        type="submit"
                        disabled={isDisabled(formProps)}
                    >
                        Unsubscribe
                    </ButtonBig>
                </div>
            </form>
        );
    };

    return (
        <Formik
            initialValues={props.initialValues}
            validate={validateForm}
            onSubmit={props.onSubmit}
        >
            {renderForm}
        </Formik>
    );
};

UserEmailUnsubscribe.defaultProps = {
    initialValues: {},
    onSubmit: () => {},
};

export default UserEmailUnsubscribe;
