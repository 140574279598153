import React from "react";

import Grades from "juice-base/project/grades.js";

import IconGradesG5G6 from "juice-base/icons/grades-5-6/index.js";
import IconGradesG7G8 from "juice-base/icons/grades-7-8/index.js";
import IconGradesG9G10 from "juice-base/icons/grades-9-10/index.js";
import IconGradesG11G12 from "juice-base/icons/grades-11-12/index.js";
import IconBook from "juice-base/icons/book/index.js";

import styles from "./styles.module.css";


const GradesIcons = (props) => {
    const iconG5G6 = (
        <IconGradesG5G6 className={styles.icon} />
    );

    const iconG7G8 = (
        <IconGradesG7G8 className={styles.icon} />
    );

    const iconG9G10 = (
        <IconGradesG9G10 className={styles.icon} />
    );

    const iconG11G12 = (
        <IconGradesG11G12 className={styles.icon} />
    );

    const getAllGradesIcons = () => {
        return [
            iconG5G6,
            iconG7G8,
            iconG9G10,
            iconG11G12,
        ];
    };

    const getVisibleGradesIcons = () => {
        const gradeIcons = [];

        const grades = Grades.filterGradesDuplicate(props.visibleGrades);

        for (let i = 0; i < grades.length; i += 1) {
            const grade = grades[i];

            if (Grades.isGradesG5G6(grade)) {
                gradeIcons.push(iconG5G6);
            }

            if (Grades.isGradesG7G8(grade)) {
                gradeIcons.push(iconG7G8);
            }

            if (Grades.isGradesG9G10(grade)) {
                gradeIcons.push(iconG9G10);
            }

            if (Grades.isGradesG11G12(grade)) {
                gradeIcons.push(iconG11G12);
            }
        }

        return gradeIcons;
    };

    const renderGrades = () => {
        let gradeIcons = [];

        if (props.visibleGrades.length === 0) {
            gradeIcons = getAllGradesIcons();
        } else {
            gradeIcons = getVisibleGradesIcons();
        }

        return (
            <div className={styles.icons}>
                {gradeIcons}
            </div>
        );
    };

    return (
        <div className={styles.container}>
            <IconBook
                isBlack
                title="Reading Level"
            />
            {renderGrades()}
        </div>
    );
};

GradesIcons.defaultProps = {
    visibleGrades: [],
};

export default GradesIcons;
