import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";

import Geo from "juice-base/project/geo.js";
import Subscription from "juice-base/project/subscription.js";
import Classes from "juice-base/project/classes.js";

import text from "juice-base/text/index.js";
import scroll from "juice-base/lib/scroll.js";
import urls from "juice-base/lib/urls.js";
import actions from "juice-base/store/actions.js";
import actionsGeo from "juice-base/actions/geo.js";
import actionsTeacher from "juice-base/actions/teacher.js";
import actionsUser from "juice-base/actions/user.js";

import useTitle from "juice-base/hooks/use-title/index.js";
import useCoupon from "juice-base/hooks/use-coupon/index.js";
import usePaymentDetails from "juice-base/hooks/use-payment-details/index.js";
import usePaymentSubscription from "juice-base/hooks/use-payment-subscription/index.js";

import IconEmojiHappy from "juice-base/icons/emoji-happy/index.js";
import IconEmojiSad from "juice-base/icons/emoji-sad/index.js";

import { withAuth } from "juice-base/components/auth/index.js";
import LogoRound from "juice-base/components/logo-round/index.js";
import Breadcrumbs from "juice-base/components/breadcrumbs/index.js";
import RequestLoader from "juice-base/components/request-loader/index.js";
import ButtonBig from "juice-base/components/button-big/index.js";

import UserCheckoutForm from "juice-base/forms/user-checkout/index.js";
import SubscribeCheckout from "juice-base/business/subscribe-checkout/index.js";

import Stripe from "juice-app/containers/stripe/index.js";

import settings from "juice-app/settings.js";
import api from "juice-app/api.js";

import styles from "./styles.module.css";


const getPageState = () => ({
    isSubmitted: false,
    isStatusLoaded: false,
    isSuccess: false,

    message: "",
});

const storeSelector = (state) => ({
    theme: state.app.theme.theme,
    session: state.user.session,
    dimensions: state.device.dimensions,
    subscription: state.subscription,
    teacher: state.teacher,
    subdivisionsByAlpha2: state.geo.subdivisionsByAlpha2,
});

// NOTE: payments, form with 2 stages
const SubscribeByPlan = () => {
    const [currentStep, setCurrentStep] = useState(1);

    const [pageState, setPageState] = useState(() => getPageState());

    const store = useSelector(storeSelector);

    const elements = useElements();
    const stripe = useStripe();

    const coupon = useCoupon();
    const paymentDetails = usePaymentDetails();
    const paymentSubscription = usePaymentSubscription();

    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    /* --- */

    const onContactSupport = () => {
        const supportLink = [
            settings.landingSite.domain,
            settings.landingSite.routeSupport,
        ].join("");

        urls.openUrl(supportLink);
    };

    const onBackToTeacherPortal = () => {
        navigate("/");
    };

    const getSubdivisions = () => {
        const subdivisions = store.subdivisionsByAlpha2[Geo.DEFAULT_ALPHA_2]?.subdivisions || [];

        return subdivisions.map((div) => ({
            value: div.code,
            name: div.name,
            name2: div.code,
        })).concat({
            value: "Other",
            name: "Other",
        });
    };

    /* --- */

    const loadSubdivisionsByAlpha2 = (alpha2) => {
        dispatch(actionsGeo.loadSubdivisionsByAlpha2({ api, actions }, {
            session: store.session,
            alpha2,
        }));
    };

    const loadPlans = async () => {
        const res = await api.subscription.getPlans({
            session: store.session,
        });

        if (res.ok) {
            dispatch(actions.subscription.setPlans({
                plans: res.plans,
                freeTrialPlanFeatures: res.freeTrialFeatures,
            }));
        }
    };

    const loadTeacherClasses = () => {
        dispatch(actionsTeacher.loadAllClasses({
            actions,
            api,
        }, {
            session: store.session,
        }));
    };

    const loadPaymentPriceDetails = async (intentId) => {
        const res = await api.subscription.getPaymentDetails({
            session: store.session,
            intentId,
        });

        let price = null;
        let error = null;

        if (res.ok) {
            price = res.data.amount;
        } else {
            error = res.error || text.error;
        }

        paymentSubscription.setData({
            isPriceLoaded: true,
            price,
            error,
        });
    };

    /* --- */

    const onTryAgain = () => {
        setCurrentStep(1);

        paymentDetails.reset();
        paymentSubscription.reset();

        setPageState(getPageState());
    };

    const onGoToStep = (newStepIndex) => {
        scroll.scrollToTop();

        setCurrentStep(newStepIndex);
    };

    /* --- */

    const onLoadSession = () => {
        dispatch(actionsUser.loadSession({
            actions,
            api,
        }, {
            session: store.session,
        }));
    };

    const onPaymentTrackActivity = (intentId, stripeRes) => {
        api.subscription.paymentLogActivity({
            session: store.session,
            intentId,
            body: stripeRes,
        });
    };

    const onApplyCoupon = async (values) => {
        if (!values.couponCode) {
            return;
        }

        coupon.setSubmitted(values.couponCode);

        const planId = Subscription.getPlanIdBySlug(store.subscription.plans, params.planSlug);

        const res = await api.subscription.subscriptionApplyCoupon({
            session: store.session,
            coupon: values.couponCode,
            plan: planId,
        });

        if (!res.ok) {
            coupon.setLoaded({
                error: res.error || text.error,
            });
            return;
        }

        let discountMessage = "";

        if (res.data.discountUSD > 0) {
            discountMessage = `$${res.data.discountUSD} discount added`;
        } else {
            discountMessage = `${res.data.discountPercent}% discount added`;
        }

        coupon.setLoaded({
            discountMessage,
            error: "",
        });
    };

    const createPaymentIntent = async (values) => {
        paymentDetails.setError("");

        const planId = Subscription.getPlanIdBySlug(store.subscription.plans, params.planSlug);

        const res = await api.subscription.createPaymentIntentByPlan({
            session: store.session,
            plan: planId,
            coupon: coupon.state.couponCode,
            isAutoRenew: values.isAutoRenew,
        });

        if (!res.ok) {
            paymentDetails.setData({
                clientSecret: null,
                intentId: null,
                paymentMethodId: null,
                error: res.error || text.error,
            });
            return null;
        }

        const {
            clientSecret,
            intentExternalId: intentId,
            subscriptionEncId,
            is100PercentDiscount,
        } = res.data;

        paymentDetails.setData({
            clientSecret,
            intentId,
            error: "",
        });

        return {
            intentId,
            clientSecret,
            is100PercentDiscount,
            subscriptionEncId,
            dates: {
                start: res.data.subscriptionStartDateFormatted,
                end: res.data.subscriptionEndDateFormatted,
            },
        };
    };

    const createPaymentMethod = async (intentId, subscriptionDates, billingValues) => {
        const billingDetails = {
            name: billingValues.name,
            address: {
                line1: billingValues.line1,
                line2: billingValues.line2,
                city: billingValues.city,
                state: billingValues.state.value,
                postal_code: billingValues.zip,
            },
        };

        const cardElement = elements.getElement(CardElement);

        const res = await stripe.createPaymentMethod({
            type: "card",
            card: cardElement,
            billing_details: billingDetails,
        });

        onPaymentTrackActivity(intentId, res);

        if (!res?.paymentMethod) {
            paymentDetails.setError(res.error?.message || text.error);
            return "";
        }

        paymentSubscription.setData({
            isCardLoaded: true,
            card: {
                name: res.paymentMethod.billing_details.name,
                expYear: res.paymentMethod.card.exp_year,
                expMonth: res.paymentMethod.card.exp_month,
                last4: res.paymentMethod.card.last4,
            },
            subscriptionDates,
            isAutoRenew: billingValues.isAutoRenew,
        });

        paymentDetails.setData({
            paymentMethodId: res.paymentMethod.id,
        });

        return res.paymentMethod.id;
    };

    const onPaymentSubmit = async (values, formActions) => {
        const paymentIntent = await createPaymentIntent(values);

        if (!paymentIntent) {
            formActions.setSubmitting(false);
            return;
        }

        const paymentMethod = await createPaymentMethod(
            paymentIntent.intentId,
            paymentIntent.dates,
            values,
            formActions,
        );

        if (!paymentMethod) {
            formActions.setSubmitting(false);
            return;
        }

        if (paymentIntent.is100PercentDiscount) {
            await api.subscription.paymentAttachPaymentMethod({
                session: store.session,
                paymentMethodId: paymentMethod,
            });

            const completeRes = await api.subscription.paymentCompleteWithDiscount100({
                session: store.session,
                subscriptionId: paymentIntent.subscriptionEncId,
            });

            if (!completeRes.ok) {
                paymentDetails.setError(completeRes.error || text.error);
                return;
            }

            setPageState((prev) => ({
                ...prev,
                isSubmitted: true,
                isStatusLoaded: true,
                isSuccess: true,
                message: "",
            }));

            onLoadSession();

            onGoToStep(2);
            return;
        }

        await loadPaymentPriceDetails(paymentIntent.intentId);

        onGoToStep(2);

        window.setTimeout(() => {
            formActions.setSubmitting(false);
        }, 1000);
    };

    const onPaymentCompleted = async (intentId) => {
        const res = await api.subscription.paymentCompleted({
            session: store.session,
            intentId,
        });

        let isSuccess = false;
        let message = "";

        if (res.ok) {
            isSuccess = true;
            message = res.data.thankYouMessage || "";

            onLoadSession();
        }

        setPageState((prev) => ({
            ...prev,
            isStatusLoaded: true,
            isSuccess,
            message,
        }));
    };

    const onSubscribe = async () => {
        setPageState((prev) => ({
            ...prev,
            isSubmitted: true,
        }));

        const res = await stripe.confirmCardPayment(paymentDetails.state.clientSecret, {
            payment_method: paymentDetails.state.paymentMethodId,
            setup_future_usage: "off_session",
        });

        onPaymentTrackActivity(paymentDetails.state.intentId, res);

        if (res?.paymentIntent?.status !== "succeeded") {
            setPageState((prev) => ({
                ...prev,
                isStatusLoaded: true,
                isSuccess: false,
            }));
            return;
        }

        onPaymentCompleted(paymentDetails.state.intentId);
    };

    /* --- */

    useTitle(() => "Subscribe", []);

    useEffect(() => {
        scroll.scrollToTop();

        loadTeacherClasses();

        loadPlans();
        loadSubdivisionsByAlpha2(Geo.DEFAULT_ALPHA_2);
    }, []);

    useEffect(() => {
        if (store.teacher.isClassesLoaded) {
            const planLimits = Subscription.getPlanLimitsBySlug(
                store.subscription.plans,
                params.planSlug,
            );

            if (Classes.isTeacherLimitReached(store.teacher.classes, planLimits)) {
                navigate("/subscribe");
            }
        }
    }, [store.teacher.isClassesLoaded]);

    /* --- */

    const renderPaymentPageLogo = (description = "") => {
        return (
            <div className={styles.paymentLogo}>
                <LogoRound />
                <div className={styles.paymentLogoCheckout}>
                    Checkout
                </div>
                <div>
                    {description}
                </div>
            </div>
        );
    };

    const renderPaymentDetails = () => {
        if (!store.subscription.isPlansLoaded
            || !store.teacher.isClassesLoaded) {
            return (
                <RequestLoader />
            );
        }

        const plan = Subscription.getPlanBySlug(store.subscription.plans, params.planSlug);

        if (!plan) {
            return null;
        }

        const isPlanAnnually = Subscription.isPlanAnnually(plan);

        const couponState = {
            ...coupon.state,
            onApply: (values) => {
                onApplyCoupon(values);
            },
            onChange: () => {
                coupon.reset();
            },
        };

        return (
            <div className={styles.paymentPage}>
                {renderPaymentPageLogo(`The Juice ${plan.name || ""} Plan`)}

                <UserCheckoutForm
                    theme={store.theme}
                    couponState={couponState}
                    states={getSubdivisions()}
                    error={paymentDetails.state.error}
                    onSubmit={onPaymentSubmit}
                    withAutoRenew={isPlanAnnually}
                    withCoupon
                />
            </div>
        );
    };

    const renderConfirmSubscription = () => {
        const isLoaded = paymentSubscription.state.isCardLoaded
            && paymentSubscription.state.isPriceLoaded;

        const planName = Subscription.getPlanNameBySlug(store.subscription.plans, params.planSlug);

        return (
            <div className={styles.paymentPage}>
                {renderPaymentPageLogo()}

                <SubscribeCheckout
                    isLoaded={isLoaded}
                    isSubmitted={pageState.isSubmitted}
                    planName={planName}
                    price={paymentSubscription.state.price}
                    card={paymentSubscription.state.card}
                    dates={paymentSubscription.state.subscriptionDates}
                    isAutoRenew={paymentSubscription.state.isAutoRenew}
                    error={paymentSubscription.state.error}
                    onSubscribe={onSubscribe}
                    onChangePaymentDetails={() => {
                        onGoToStep(1);
                    }}
                />
            </div>
        );
    };

    const renderPaidSuccessStatus = (message) => {
        return (
            <div className={styles.paidStatusPage}>
                <IconEmojiHappy
                    className={styles.paidStatusPageEmoji}
                    title="Emoji Happy"
                    isBlack
                />

                <div className={styles.paidStatusTitle}>
                    Thank you for subscribing to The Juice!
                </div>

                <div className={styles.paidStatusDescription}>
                    {message || ""}
                </div>

                <div className={styles.paidStatusControls}>
                    <ButtonBig onClick={onBackToTeacherPortal}>
                        Back to the teacher portal
                    </ButtonBig>
                </div>
            </div>
        );
    };

    const renderPaidErrorStatus = (message) => {
        return (
            <div className={styles.paidStatusPage}>
                <IconEmojiSad
                    className={styles.paidStatusPageEmoji}
                    title="Emoji Sad"
                    isBlack
                />

                <div className={styles.paidStatusTitle}>
                    We are sorry but the payment failed!
                    Please try again or contact support.
                </div>

                <div className={styles.paidStatusDescription}>
                    {message || ""}
                </div>

                <div className={styles.paidStatusControls}>
                    <ButtonBig onClick={onTryAgain}>
                        Try again
                    </ButtonBig>
                    <ButtonBig onClick={onContactSupport}>
                        Contact support
                    </ButtonBig>
                </div>
            </div>
        );
    };

    const renderBreadcrumbs = () => {
        const breadcrumbs = [
            {
                name: "Select plan",
                onClick: () => {
                    navigate("/subscribe");
                },
                content: null,
            },
            {
                name: "Enter Payment Details",
                content: renderPaymentDetails(),
            },
            {
                name: "Confirm Subscription",
                content: renderConfirmSubscription(),
            },
        ];

        return (
            <Breadcrumbs
                className={styles.breadcrumbs}
                isColumns={store.dimensions.width < 600}
                step={currentStep}
                steps={breadcrumbs}
                onStepChange={onGoToStep}
            />
        );
    };

    /* --- */

    let pageContent = null;

    if (pageState.isSubmitted && pageState.isStatusLoaded) {
        if (pageState.isSuccess) {
            pageContent = renderPaidSuccessStatus(pageState.message);
        } else {
            pageContent = renderPaidErrorStatus(pageState.message);
        }
    } else {
        pageContent = renderBreadcrumbs();
    }

    return (
        <div className={styles.page}>
            {pageContent}
        </div>
    );
};

const SubscribeByPlanView = () => {
    return (
        <Stripe>
            <SubscribeByPlan />
        </Stripe>
    );
};

export default withAuth(["teacher"])(SubscribeByPlanView);
