// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".gZFEgUkZseY_sYhFI9ou {\n    padding: 2rem;\n}\n\n.jLXfpxd5sAN0dFpcTw7v {\n    display: grid;\n    grid-row-gap: 1rem;\n}\n\n.iXjfmWOnbQJodv44RWFh {\n    width: 100%;\n    max-height: 5.5rem;\n}\n\n.Qixig2Ql9h6VLxzQ5xp6 {\n    display: block;\n    cursor: pointer;\n    outline: none;\n}\n", "",{"version":3,"sources":["webpack://./base/components/section-sponsor/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,eAAe;IACf,aAAa;AACjB","sourcesContent":[".content {\n    padding: 2rem;\n}\n\n.sponsor {\n    display: grid;\n    grid-row-gap: 1rem;\n}\n\n.sponsorImg {\n    width: 100%;\n    max-height: 5.5rem;\n}\n\n.sponsorLink {\n    display: block;\n    cursor: pointer;\n    outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "gZFEgUkZseY_sYhFI9ou",
	"sponsor": "jLXfpxd5sAN0dFpcTw7v",
	"sponsorImg": "iXjfmWOnbQJodv44RWFh",
	"sponsorLink": "Qixig2Ql9h6VLxzQ5xp6"
};
export default ___CSS_LOADER_EXPORT___;
