import React from "react";
import { VisibilityContext } from "react-horizontal-scrolling-menu";

import classNames from "juice-base/lib/class-names.js";
import symbols from "juice-base/lib/symbols.js";

import styles from "./chip.module.css";


const Chip = (props) => {
    const visibility = React.useContext(VisibilityContext);

    const onClick = (evt) => {
        props.onClick(evt, visibility);
    };

    const chipClassName = classNames({
        [styles.chip]: true,
        [styles.chipSelected]: props.selected && !props.disabled,
        [styles.chipDisabled]: props.disabled,
        [styles.chipRose]: props.isRoseTheme,
        [styles.chipRoseSelected]: props.isRoseTheme && props.selected,
        [styles.cursorPointer]: props.withCursorPointer,
    });

    return (
        <div
            className={chipClassName}
            onKeyPress={onClick}
            onClick={onClick}
            role="button"
            tabIndex="-1"
        >
            {props.label || symbols.nbsp}
        </div>
    );
};

Chip.defaultProps = {
    label: "",
    selected: false,
    isRoseTheme: false,
    disabled: false,
    withCursorPointer: true,
    onClick: () => {},
};

export default Chip;
