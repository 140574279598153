// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mjJbuFrH8kv_7nhkpqJP {\n    display: grid;\n}\n\n/* --- */\n\n@media only screen and (min-width: 521px) {\n    .mjJbuFrH8kv_7nhkpqJP {\n        grid-template-columns: 50rem;\n        justify-content: center;\n    }\n}\n", "",{"version":3,"sources":["webpack://./app/views/user/password-change/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA,QAAQ;;AAER;IACI;QACI,4BAA4B;QAC5B,uBAAuB;IAC3B;AACJ","sourcesContent":[".content {\n    display: grid;\n}\n\n/* --- */\n\n@media only screen and (min-width: 521px) {\n    .content {\n        grid-template-columns: 50rem;\n        justify-content: center;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "mjJbuFrH8kv_7nhkpqJP"
};
export default ___CSS_LOADER_EXPORT___;
