import React from "react";

import Close from "juice-base/components/close/index.js";
import PopupCommon from "juice-base/components/popup-common/index.js";
import RequestLoader from "juice-base/components/request-loader/index.js";

import styles from "./styles.module.css";


const PopupLoading = (props) => {
    let close = null;

    if (props.onClose) {
        close = (
            <Close
                onClose={props.onClose}
                className={styles.close}
            />
        );
    }

    const emptyFunction = () => { };
    const onClose = props.onClose || emptyFunction;

    return (
        <PopupCommon
            popupClasses={styles.overlappedPopup}
            showClose={false}
            onClose={onClose}
            hideBodyScrollOnOpen={props.hideScrollbar}
            closeOnLayoutClick={false}
        >
            <div className={styles.popupLoading}>
                {close}
                <RequestLoader />
            </div>
        </PopupCommon>
    );
};

PopupLoading.defaultProps = {
    hideScrollbar: true,
    onClose: null,
};

export default PopupLoading;
