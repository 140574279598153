// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".V0QOghpsxTrNhbzycWKb {\n    padding: 1.5rem 2rem;\n\n    color: var(--button-quick-link-color);\n    font-weight: 600;\n    font-size: 1.4rem;\n\n    border-radius: 1rem;\n    border: var(--button-quick-link-border);\n\n    background-color: var(--button-quick-link-bg-color);\n\n    cursor: pointer;\n    outline: none;\n}\n", "",{"version":3,"sources":["webpack://./base/components/button-quick-link/styles.module.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;;IAEpB,qCAAqC;IACrC,gBAAgB;IAChB,iBAAiB;;IAEjB,mBAAmB;IACnB,uCAAuC;;IAEvC,mDAAmD;;IAEnD,eAAe;IACf,aAAa;AACjB","sourcesContent":[".button {\n    padding: 1.5rem 2rem;\n\n    color: var(--button-quick-link-color);\n    font-weight: 600;\n    font-size: 1.4rem;\n\n    border-radius: 1rem;\n    border: var(--button-quick-link-border);\n\n    background-color: var(--button-quick-link-bg-color);\n\n    cursor: pointer;\n    outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "V0QOghpsxTrNhbzycWKb"
};
export default ___CSS_LOADER_EXPORT___;
