import React, { useState } from "react";

import IconPlayerHeadphone from "juice-base/icons/player-headphone/index.js";

import IconClickable from "juice-base/components/icon-clickable/index.js";
import RequestLoader from "juice-base/components/request-loader/index.js";
import PlayerAudio from "juice-base/components/player-audio/index.js";
import PopupWindow from "juice-base/components/popup-window/index.js";

import DailyJuiceStory from "juice-base/business/daily-juice-story/index.js";

import styles from "./styles.module.css";


const PopupStory = (props) => {
    const [isPlayerVisible, setIsPlayerVisible] = useState(false);
    const [isPlayerExpanded, setIsPlayerExpanded] = useState(false);

    const hasVideo = !!props?.story?.featuredVideo?.url;

    /* --- */

    const renderTitle = () => {
        if (!props.story) {
            return "Story";
        }

        return props.story.title;
    };

    const renderContent = () => {
        if (!props.story) {
            return (
                <RequestLoader />
            );
        }

        return (
            <div className={styles.content}>
                <DailyJuiceStory
                    storyIndex={null}
                    story={props.story}
                    dimensions={props.dimensions}
                    isDefaultVideo={props.isDefaultVideo}
                    videoCaptionSrc={props.videoCaptionSrc}
                    enableListen={false}
                    onImageClick={props.onImageClick}
                    onWordClick={props.onWordClick}
                    onExtraJuiceWordClick={props.onOpenExtraJuice}
                />
            </div>
        );
    };

    const renderPopupControl = () => {
        if (!props.story || hasVideo) {
            return [];
        }

        const audioPlayer = (
            <IconClickable
                onClick={() => {
                    if (!isPlayerVisible) {
                        props.storyCallbacks.onLoadingStory(props.storyId);
                        setIsPlayerVisible(true);
                    }
                }}
            >
                <IconPlayerHeadphone
                    title="Play story"
                    isOrange
                />
            </IconClickable>
        );

        return [audioPlayer];
    };

    const renderPlayer = () => {
        if (!isPlayerVisible) {
            return null;
        }

        const trackId = props.story.id;
        const audio = props.audioState?.[trackId] || {};

        return (
            <PlayerAudio
                image={props.story?.featuredImage?.url || ""}
                title={props.story?.title || "Title"}
                category={props.story?.categoryName || "Story"}
                audio={audio}
                error={audio.error || ""}
                isExpanded={isPlayerExpanded}
                onFirstPlay={() => {
                    props.storyCallbacks.onFirstPlay(trackId);
                }}
                onPlay={() => {
                    props.storyCallbacks.onPlay(trackId);
                }}
                onPause={() => {
                    props.storyCallbacks.onPause(trackId);
                }}
                onRewind={() => {
                    props.storyCallbacks.onRewind(trackId);
                }}
                onForward={() => {
                    props.storyCallbacks.onForward(trackId);
                }}
                onChangeRate={(rate) => {
                    props.storyCallbacks.onChangeRate(trackId, rate);
                }}
                onExpand={() => {
                    setIsPlayerExpanded((prev) => !prev);
                }}
                onClose={() => {
                    setIsPlayerVisible(false);
                    props.storyCallbacks.onStop(trackId);
                }}
            />
        );
    };

    return (
        <PopupWindow
            title={renderTitle()}
            rightControl={renderPopupControl()}
            player={renderPlayer()}
            playerExpanded={isPlayerExpanded}
            onClose={props.onClose}
            hideScrollbar={props.hideScrollbar}
        >
            {renderContent()}
        </PopupWindow>
    );
};

PopupStory.defaultProps = {
    dimensions: {},
    story: null,
    videoCaptionSrc: "",
    isDefaultVideo: true,
    hideScrollbar: true,

    audioState: {},

    storyCallbacks: {
        onLoadingStory: () => { },
        onFirstPlay: () => { },
        onPlay: () => { },
        onPause: () => { },
        onRewind: () => { },
        onForward: () => { },
        onChangeRate: () => { },
        onStop: () => { },
    },

    onImageClick: () => { },
    onWordClick: () => { },
    onOpenExtraJuice: () => { },
    onClose: () => { },
};

export default PopupStory;
