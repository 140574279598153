import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";

import date from "juice-base/lib/date.js";
import classNames from "juice-base/lib/class-names.js";
import text from "juice-base/text/index.js";

import ChartTooltip from "juice-base/components/chart-tooltip/index.js";

import styles from "./styles.module.css";


const ChartBar = (props) => {
    const chartRef = useRef();

    const [groupIndex, setGroupIndex] = useState(0);

    const rectStyles = {
        className: "bar",
        fill: "var(--chart-bar-color-1)",
    };

    const getDataset = (index = groupIndex) => {
        const dataGroup = props.dataGroups[index];

        if (dataGroup && dataGroup.data) {
            const data = [];

            if (dataGroup.data.length > 0) {
                dataGroup.data.forEach((d) => {
                    let label = "";

                    if ([0, 1, 2].indexOf(index) !== -1) {
                        label = date.getMonthDayFromDate(date.newDateUTC(d.date));
                    } else if (index === 3) {
                        label = date.tryFormatDateUTC(d.date, date.getShortMonth);
                    }

                    data.push({
                        ...d,
                        value: d.value,
                        label,
                    });
                });
            }

            return data;
        }

        return [];
    };

    const getBarValueClassName = (bar, index) => {
        const dataGroup = props.dataGroups[index];

        return classNames({
            [styles.chartBarValue]: true,
            [styles.chartBarValueSmall]: dataGroup?.data?.length > 5,
            [styles.chartBarValueEmpty]: bar.value === 0,
        });
    };

    /* ----- */

    const renderZeroTooltip = (width) => {
        return ChartTooltip({
            width,
        });
    };

    const renderBarValuePercent = (value) => {
        return `
            <div class="${styles.chartBarValuePercent}">
                ${value.toFixed(0)}%
            </div>
        `;
    };

    const renderBarValue = (bar, barWidth, index) => {
        if (index === 2) {
            return null;
        }

        if (bar.showValue) {
            if (bar.value === 0) {
                return renderZeroTooltip(barWidth);
            }

            if (bar.value) {
                return renderBarValuePercent(bar.value);
            }
        }

        return null;
    };

    const initOrUpdateChart = (elem, dataset, gIndex = groupIndex) => {
        if (!elem) {
            return;
        }

        const svg = d3.select(elem);

        const width = props.width - 2 * props.chartMargin;
        const height = props.height - 2 * props.chartMargin;

        const drawVerticalLines = gIndex === 2;

        svg.selectAll("*").remove();

        const chart = svg.append("g")
            .attr("transform", `translate(${props.chartMargin}, ${props.chartMargin})`);

        const xScale = d3.scaleBand()
            .range([0, width])
            .domain(dataset.map((d) => d.label))
            .padding(0.3);

        const yScale = d3.scaleLinear()
            .range([height, 0])
            .domain([0, 100]);

        const makeXLines = () => d3.axisBottom()
            .scale(xScale)
            .tickValues(xScale.domain().filter((d, idx) => {
                if (drawVerticalLines) {
                    return idx % 5 === 0;
                }

                return true;
            }));

        const makeYLines = () => d3.axisLeft()
            .scale(yScale);

        let chartBottomAxisClassName = null;

        if (gIndex === 1 || gIndex === 2) {
            chartBottomAxisClassName = styles.chartSmallBottomText;
        }

        chart.append("g")
            .attr("transform", `translate(0, ${height})`)
            .attr("class", chartBottomAxisClassName)
            .call(
                d3.axisBottom(xScale)
                    .tickFormat((domain, idx) => {
                        if (drawVerticalLines) {
                            return idx % 5 === 0 ? domain : "";
                        }

                        return domain;
                    }),
            );

        chart.append("g")
            .call(d3.axisLeft(yScale)
                .ticks(props.axisLeftTicks));

        if (drawVerticalLines) {
            chart.append("g")
                .attr("class", "grid")
                .attr("transform", `translate(0, ${height})`)
                .call(makeXLines()
                    .tickSize(-height, 0, 0)
                    .tickFormat(""));
        }

        chart.append("g")
            .attr("class", "grid")
            .call(makeYLines()
                .tickSize(-width, 0, 0)
                .tickFormat("")
                .ticks(props.axisLeftTicks));

        const barGroups = chart.selectAll()
            .data(dataset)
            .enter()
            .append("g");

        barGroups
            .append("rect")
            .attr("class", rectStyles.className)
            .style("fill", rectStyles.fill)
            .attr("x", (bar) => xScale(bar.label))
            .attr("y", (bar) => yScale(bar.value))
            .attr("height", (bar) => {
                const rectHeight = height - yScale(bar.value);

                if (Number.isNaN(rectHeight)) {
                    return 0;
                }

                if (rectHeight === 0) {
                    return 2;
                }

                return rectHeight;
            })
            .attr("width", xScale.bandwidth());

        barGroups
            .append("foreignObject")
            .attr("class", (bar) => {
                return getBarValueClassName(bar, gIndex);
            })
            .attr("x", (bar) => xScale(bar.label))
            .attr("y", (bar) => yScale(bar.value))
            .attr("width", xScale.bandwidth())
            .append("xhtml:div")
            .html((bar) => {
                return renderBarValue(bar, xScale.bandwidth(), gIndex);
            });
    };

    useEffect(() => {
        const dataset = getDataset();

        initOrUpdateChart(chartRef.current, dataset);
    }, [props.dataGroups]);

    const onDateRangeChange = (index) => {
        setGroupIndex(index);

        const dataset = getDataset(index);

        if (chartRef.current) {
            initOrUpdateChart(chartRef.current, dataset, index);
        }
    };

    const renderDateRangeSelector = () => {
        const dateRanges = props.dataGroups.map((group, i) => {
            const rClasses = [styles.range];

            if (i === groupIndex) {
                rClasses.push(styles.selectedRange);
            }

            return (
                <div
                    className={rClasses.join(" ")}
                    onClick={() => {
                        onDateRangeChange(i);
                    }}
                    onKeyPress={() => {
                        onDateRangeChange(i);
                    }}
                    tabIndex="-1"
                    role="button"
                >
                    {group.name}
                </div>
            );
        });

        return (
            <div className={styles.dateRanges}>
                {dateRanges}
            </div>
        );
    };

    const renderAverageScore = () => {
        const dataGroup = props.dataGroups[groupIndex];

        let score = 0;

        if (dataGroup?.data && dataGroup.data.length !== 0) {
            const maxAvailablePoints = dataGroup.data.filter((val) => {
                if (val.showValue) {
                    return true;
                }

                return false;
            }).length * 100;

            let totalPoints = 0;
            dataGroup.data.forEach((val) => {
                if (val.showValue || groupIndex === 3) {
                    totalPoints += val.value;
                }
            });

            score = (totalPoints * 100) / maxAvailablePoints;
        }

        if (Number.isNaN(score)) {
            score = "0.00";
        }

        score = Number(score).toFixed(2);

        return (
            <div className={styles.averageScore}>
                <div>Average score</div>
                <div>{`${score}%`}</div>
            </div>
        );
    };

    const renderDateRange = () => {
        if (!props.dataGroups[groupIndex]
            || !props.dataGroups[groupIndex].dates) {
            return null;
        }

        const { dates } = props.dataGroups[groupIndex];

        const from = date.newDateUTC(dates.from);
        const to = date.newDateUTC(dates.to);

        let title = "";

        if (from.getFullYear() !== to.getFullYear()) {
            title = `${date.getShortMonth(from)} ${from.getFullYear()} - ${date.getShortMonth(to)} ${to.getFullYear()}`;
        }

        if (from.getMonth() === to.getMonth()) {
            title = `${date.getShortMonth(from)} ${from.getDate()}-${to.getDate()}, ${from.getFullYear()}`;
        }

        if (to.getMonth() - from.getMonth() === 1) {
            title = `${date.getShortMonth(from)}  ${from.getDate()} - ${date.getShortMonth(to)}  ${to.getDate()}, ${from.getFullYear()}`;
        }

        if (to.getMonth() - from.getMonth() > 1) {
            title = `${date.getShortMonth(from)} - ${date.getShortMonth(to)}, ${from.getFullYear()}`;
        }

        return (
            <div className={styles.selectedRangeDate}>
                {title}
            </div>
        );
    };

    const dataset = getDataset();

    const minDatasetLength = 1;

    const svgClassName = classNames({
        [styles.chart]: true,
        [styles.chartHidden]: dataset.length < minDatasetLength,
    });

    let chartMessage = null;

    if (dataset.length < minDatasetLength) {
        chartMessage = (
            <div className={styles.chartMissingData}>
                {text.noQuizResults}
            </div>
        );
    }

    return (
        <div className={styles.chartBar}>
            {renderDateRangeSelector()}

            <div>
                {renderAverageScore()}
                {renderDateRange()}
            </div>

            <div
                className={svgClassName}
                style={{
                    width: props.width,
                    height: props.height,
                }}
            >
                {chartMessage}
                <svg ref={chartRef} />
            </div>
        </div>
    );
};

ChartBar.defaultProps = {
    dataGroups: [],
    height: 250,
    width: 350,
    chartMargin: 40,
    axisLeftTicks: 5,
};

export default ChartBar;
