import React, { useEffect, useState } from "react";

import classNames from "juice-base/lib/class-names.js";

import Search from "juice-base/project/search.js";
import Grades from "juice-base/project/grades.js";
import Standards from "juice-base/project/standards.js";

import useDebouncedCallback from "juice-base/hooks/use-debounced-callback/index.js";

import IconCardsLayout from "juice-base/icons/cards-layout/index.js";
import IconListLayout from "juice-base/icons/list-layout/index.js";
import IconGraduationCapEmpty from "juice-base/icons/graduation-cap-empty/index.js";
import IconSettingFilter from "juice-base/icons/setting-filter/index.js";
import IconStandardsEmpty from "juice-base/icons/standards-empty/index.js";
import IconCategoriesList from "juice-base/icons/categories-list/index.js";

import SelectCustom from "juice-base/components/select-custom/index.js";
import ButtonTogglerIcon from "juice-base/components/button-toggler-icon/index.js";

import SearchForm from "juice-base/forms/search/index.js";

import SelectWithPopup from "juice-base/business/select-with-popup/index.js";
import PopupFilterCategories from "juice-base/business/popup-filter-categories/index.js";
import PopupFilterStandards from "juice-base/business/popup-filter-standards/index.js";

import styles from "./styles.module.css";


const SearchViewTypes = Search.getViewTypes();


const SearchControl = (props) => {
    const allContentTypes = Search.getContentTypes();
    const allSortByValues = Search.getSortOrders();

    /* --- */

    const [searchText, setSearchText] = useState({
        isTyping: false,
        value: props.defaultValue,
    });

    const [selectedSortBy, setSelectedSortBy] = useState(allSortByValues[0].value);

    const [selectedGrade, setSelectedGrade] = useState(() => {
        let gs = Grades.convertGradesToString(props.defaultGrades);

        if (!gs) {
            gs = Grades.getMaxGradeValue();
        }

        return gs;
    });

    const [selectedType, setSelectedType] = useState(allContentTypes[0].value);

    const [categoriesState, setCategoriesState] = useState({
        isVisiblePopup: false,
        selectedCategories: [],
    });

    const [standardsState, setStandardsState] = useState(() => {
        const defaultType = Standards.getDefaultStandardValue(props.withStateStandard);

        return {
            isVisiblePopup: false,
            selectedStandards: [],
            defaultType,
        };
    });

    const [viewType, setViewType] = useState(props.defaultViewType);

    /* --- */

    const categories = (props.categories[selectedType] || []).map((cat) => ({
        value: cat.id,
        label: cat.name,
    }));

    /* --- */

    const getStandardsByGrades = (grades) => {
        const standards = props.standards.map((s) => ({
            ...s,
            standards: s.standards.filter((ss) => {
                for (let i = 0; i < grades.length; i += 1) {
                    if (ss.grades.indexOf(grades[i]) !== -1) {
                        return true;
                    }
                }

                return false;
            }),
        })).filter((s) => s.standards.length > 0);

        return standards;
    };

    const getSameStandardsBySubStandard = (grade) => {
        const standardsByGrades = getStandardsByGrades(grade);

        const standards = [];

        for (let i = 0; i < standardsByGrades.length; i += 1) {
            const s = Standards.getStandardsByType(
                standardsByGrades[i].standards,
                standardsState.defaultType,
            );

            for (let j = 0; j < s.length; j += 1) {
                standards.push(s[j]);
            }
        }

        const ret = [];

        for (let i = 0; i < standards.length; i += 1) {
            const s = standards[i];

            for (let k = 0; k < standardsState.selectedStandards.length; k += 1) {
                const ss = standardsState.selectedStandards[k];

                if (Standards.isSameStandardBySubStandard(s.subStandard, ss.subStandard)) {
                    ret.push({
                        id: s.id,
                        subStandard: s.subStandard,
                    });
                }
            }
        }

        return ret;
    };

    /* --- */

    const onSearch = (params) => {
        props.onSearch({
            search: searchText.value.trim(),
            orderBy: selectedSortBy,
            grades: selectedGrade,
            type: selectedType,
            categories: categoriesState.selectedCategories,
            standards: standardsState.selectedStandards.map((s) => s.id),
            standardType: standardsState.defaultType,
            page: 0,
            ...params,
        });
    };

    const onResetSelectedCategories = () => {
        setCategoriesState({
            isVisiblePopup: false,
            selectedCategories: [],
        });
    };

    const onSearchWithDebounce = useDebouncedCallback((value) => {
        onSearch({
            search: value.trim(),
            page: 0,
        });

        setSearchText((prev) => ({
            ...prev,
            isTyping: false,
        }));
    }, 500);

    const onSearchValueChange = (value) => {
        let isTyping = false;

        if (value) {
            isTyping = true;
        }

        setSearchText((prev) => ({
            ...prev,
            value,
            isTyping,
        }));

        onSearchWithDebounce(value);
    };

    const onChangeOrderBy = (value) => {
        setSelectedSortBy(value);

        props.onChangeOrderBy(value);

        onSearch({
            orderBy: value,
        });
    };

    const onChangeGrade = (value) => {
        setSelectedGrade(value);

        const standards = getSameStandardsBySubStandard(value.split("-"));

        setStandardsState((prev) => ({
            ...prev,
            selectedStandards: standards,
        }));

        props.onChangeGrade(value);

        onSearch({
            grades: value,
            standards: standards.map((s) => s.id),
        });
    };

    const onChangeType = (newType) => {
        let sCategories = [...categoriesState.selectedCategories];
        const cats = props.categories[newType] || [];

        if (cats.length === 0) {
            sCategories = [];
            onResetSelectedCategories();
        }

        setSelectedType(newType);

        setStandardsState((prev) => ({
            ...prev,
            selectedStandards: [],
        }));

        onSearch({
            type: newType,
            categories: sCategories,
            standards: [],
        });
    };

    /* --- */

    const onOpenCategoriesPopup = () => {
        props.onOpenCategories();

        setCategoriesState((prev) => ({
            ...prev,
            isVisiblePopup: true,
        }));
    };

    const onApplyCategories = (cats) => {
        onSearch({
            categories: cats,
        });

        props.onChangeCategories(cats);

        setCategoriesState((prev) => ({
            ...prev,
            isVisiblePopup: false,
            selectedCategories: cats,
        }));
    };

    const onCloseCategoriesPopup = () => {
        setCategoriesState((prev) => ({
            ...prev,
            isVisiblePopup: false,
        }));
    };

    /* --- */

    const onOpenStandardFilterPopup = () => {
        setStandardsState((prev) => ({
            ...prev,
            isVisiblePopup: true,
        }));
    };

    const onCloseStandardFilterPopup = () => {
        setStandardsState((prev) => ({
            ...prev,
            isVisiblePopup: false,
        }));
    };

    const onApplyStandardFilterPopup = (standards, type) => {
        setStandardsState((prev) => ({
            ...prev,
            isVisiblePopup: false,
            selectedStandards: standards,
            defaultType: type,
        }));

        onSearch({
            standards: standards.map((s) => s.id),
            standardType: type,
        });
    };

    /* --- */

    const onChangeViewType = (view) => {
        setViewType(view);
        props.onViewTypeChange(view);
    };

    /* --- */

    useEffect(() => {
        onSearch({});
    }, []);

    /* --- */

    const renderCategoriesPopup = () => {
        if (!categoriesState.isVisiblePopup) {
            return null;
        }

        return (
            <PopupFilterCategories
                categories={categories}
                selectedCategories={categoriesState.selectedCategories}
                onApply={onApplyCategories}
                onClose={onCloseCategoriesPopup}
            />
        );
    };


    const renderStandardsPopup = () => {
        if (!standardsState.isVisiblePopup) {
            return null;
        }

        const standards = getStandardsByGrades(selectedGrade.split("-"));

        return (
            <PopupFilterStandards
                withStateStandard={props.withStateStandard}
                defaultType={standardsState.defaultType}
                defaultSelectedStandards={standardsState.selectedStandards}
                standards={standards}
                onApply={onApplyStandardFilterPopup}
                onClose={onCloseStandardFilterPopup}
            />
        );
    };

    /* --- */

    const renderTextSearch = () => {
        const initValues = {
            search: searchText.value,
        };

        return (
            <SearchForm
                initialValues={initValues}
                onChange={onSearchValueChange}
            />
        );
    };

    /* --- */

    const renderSortBySelector = () => {
        return (
            <div className={styles.filterSelect}>
                <SelectCustom
                    icon={<IconSettingFilter isBlack />}
                    options={allSortByValues}
                    selected={selectedSortBy}
                    onSelect={onChangeOrderBy}
                />
            </div>
        );
    };

    const renderGradeSelector = () => {
        if (props.disabledGrades) {
            return null;
        }

        const grades = Grades.getGradesOptionsWithGT(props.defaultGrades);

        return (
            <div className={styles.filterSelect}>
                <SelectCustom
                    icon={<IconGraduationCapEmpty isBlack />}
                    options={grades}
                    selected={selectedGrade}
                    onSelect={onChangeGrade}
                />
            </div>
        );
    };

    const renderSortAndGradeSelectors = () => {
        if (props.disabledGrades) {
            const classes = classNames({
                [styles.filters]: true,
                [styles.filtersSingle]: true,
            });

            return (
                <div className={classes}>
                    {renderSortBySelector()}
                </div>
            );
        }

        return (
            <div className={styles.filters}>
                {renderSortBySelector()}
                {renderGradeSelector()}
            </div>
        );
    };

    const renderContentTypesTabs = () => {
        const typeTabs = allContentTypes.map((type) => {
            const tabClassName = classNames({
                [styles.typeTab]: true,
                [styles.typeTabSelected]: type.value === selectedType,
            });

            return (
                <div
                    key={type.value}
                    className={tabClassName}
                    role="button"
                    tabIndex="-1"
                    onClick={() => {
                        onChangeType(type.value);
                    }}
                    onKeyPress={() => {
                        onChangeType(type.value);
                    }}
                >
                    {type.label}
                </div>
            );
        });

        return (
            <div className={styles.typeTabs}>
                {typeTabs}
            </div>
        );
    };

    const renderContentTypeSelector = () => {
        const className = classNames({
            [styles.filterSelect]: true,
            [styles.contentTypeSelector]: true,
        });

        return (
            <div className={className}>
                <SelectCustom
                    icon={<IconSettingFilter isBlack />}
                    selected={selectedType}
                    options={allContentTypes}
                    onSelect={onChangeType}
                />
            </div>
        );
    };

    const renderCategories = () => {
        const isDisabled = searchText.isTyping || categories.length === 0;

        const icon = (
            <IconCategoriesList
                isBlack
            />
        );

        return (
            <SelectWithPopup
                icon={icon}
                count={categoriesState.selectedCategories.length}
                name="Categories"
                onOpenPopup={onOpenCategoriesPopup}
                disabled={isDisabled}
            />
        );
    };

    const renderStandardsSelect = () => {
        if (!props.withStandardsFilter) {
            return null;
        }

        const isDisabled = searchText.isTyping || props.standards.length === 0;

        const icon = (
            <IconStandardsEmpty
                isBlack
            />
        );

        return (
            <SelectWithPopup
                name="Standards"
                icon={icon}
                count={standardsState.selectedStandards.length}
                onOpenPopup={onOpenStandardFilterPopup}
                disabled={isDisabled}
                roseTheme
            />
        );
    };

    const renderCardLayoutToggler = () => {
        const buttons = [
            {
                value: SearchViewTypes.cards,
                icon: <IconCardsLayout isOrange />,
            },
            {
                value: SearchViewTypes.list,
                icon: <IconListLayout isOrange />,
            },
        ];

        return (
            <ButtonTogglerIcon
                buttons={buttons}
                selected={viewType}
                onSelect={onChangeViewType}
            />
        );
    };

    const filtersClassName = classNames({
        [styles.filtersAnsSwitcher]: props.withStandardsFilter,
    });

    return (
        <>
            {renderCategoriesPopup()}
            {renderStandardsPopup()}

            <div className={styles.searchControl}>
                {renderTextSearch()}

                {renderSortAndGradeSelectors()}

                <div className={styles.categoriesFilters}>
                    {renderContentTypesTabs()}
                    {renderContentTypeSelector()}

                    <div className={filtersClassName}>
                        {renderStandardsSelect()}
                        <div className={styles.categoriesAndSwitcher}>
                            {renderCategories()}
                            {renderCardLayoutToggler()}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

SearchControl.defaultProps = {
    defaultValue: "",

    defaultGrades: [],
    disabledGrades: false,

    withStandardsFilter: false,
    withStateStandard: false,
    standards: [],

    categories: [],
    defaultViewType: SearchViewTypes.list,
    onViewTypeChange: () => { },

    onChangeOrderBy: () => { },
    onChangeCategories: () => { },
    onChangeGrade: () => { },
    onOpenCategories: () => { },
    onSearch: () => { },
};

export default SearchControl;
