import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import DailyJuiceStory from "juice-base/project/daily-juice-story.js";

import date from "juice-base/lib/date.js";

import "./styles.css";
import styles from "./styles.module.css";


const DailyJuiceCarousel = (props) => {
    const carouselRef = useRef(null);

    const [selectedIndicator, setSelectedIndicator] = useState(0);

    const playInterval = 4000;

    const renderCarouselItems = () => {
        return props.items.map((item) => {
            const storyImage = DailyJuiceStory.getMaxSizeImage(item?.featuredImage);

            const slideStyle = {};

            if (storyImage) {
                slideStyle.backgroundImage = `url(${storyImage.src})`;
            }

            return (
                <div
                    key={item.id}
                    className={styles.slide}
                    style={slideStyle}
                >
                    <div className={styles.gradientLayout}>
                        <div className={styles.date}>
                            {date.tryFormatDate(item.date, date.formatDayDate)}
                        </div>
                        <div className={styles.title}>
                            {item.title}
                        </div>
                        <Link
                            to={item.juiceLink}
                            className={styles.juiceButton}
                        >
                            Open daily juice
                        </Link>
                    </div>
                </div>
            );
        });
    };

    const renderIndicator = (clickFn, isSelected, index) => {
        const indicatorClasses = [styles.carouselIndicator];

        if (index < selectedIndicator) {
            indicatorClasses.push(styles.pastIndicator);
        }

        let progress = null;

        if (isSelected) {
            const progressStyle = {
                animationDuration: `${playInterval}ms`,
            };

            progress = (
                <div
                    style={progressStyle}
                    className={styles.indicatorProgress}
                />
            );

            indicatorClasses.push(styles.selectedIndicator);
        }

        return (
            <div
                onClick={clickFn}
                onKeyPress={clickFn}
                role="button"
                tabIndex="-1"
                className={indicatorClasses.join(" ")}
            >
                {progress}
            </div>
        );
    };

    return (
        <div
            className={styles.carouselWrapper}
            data-comment="section-carousel"
        >
            <Carousel
                ref={carouselRef}
                showStatus={false}
                showArrows={false}
                showThumbs={false}
                stopOnHover={false}
                useKeyboardArrows={false}
                onChange={(index) => {
                    setSelectedIndicator(index);

                    if (carouselRef.current) {
                        carouselRef.current.resetAutoPlay();
                    }
                }}
                swipeable
                autoPlay
                interval={playInterval}
                infiniteLoop
                emulateTouch
                renderIndicator={renderIndicator}
            >
                {renderCarouselItems()}
            </Carousel>
        </div>
    );
};

DailyJuiceCarousel.defaultProps = {
    items: [],
};

export default DailyJuiceCarousel;
