import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import Classes from "juice-base/project/classes.js";

import urls from "juice-base/lib/urls.js";
import actions from "juice-base/store/actions.js";
import subscriptionActions from "juice-base/actions/subscription.js";
import teacherActions from "juice-base/actions/teacher.js";

import useTitle from "juice-base/hooks/use-title/index.js";
import useTeacherLimit from "juice-base/hooks/use-teacher-limit/index.js";

import { withAuth } from "juice-base/components/auth/index.js";
import LogoRound from "juice-base/components/logo-round/index.js";
import Tabs from "juice-base/components/tabs/index.js";
import RequestLoader from "juice-base/components/request-loader/index.js";

import SubscribePlanCard from "juice-base/business/subscribe-plan-card/index.js";
import PopupConfirmTeacherLimit from "juice-base/business/popup-confirm-teacher-limit/index.js";

import api from "juice-app/api.js";

import styles from "./styles.module.css";


const storeSelector = (state) => ({
    session: state.user.session,
    dimensions: state.device.dimensions,
    juices: state.juices,
    teacher: state.teacher,
    subscription: state.subscription,
});

const Subscribe = () => {
    const [tabSelectedPlan, setTabSelectedPlan] = useState(null);

    const teacherLimit = useTeacherLimit();
    const store = useSelector(storeSelector);
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const isMobile = store.dimensions.width < 1000;

    /* --- */

    const loadPlans = () => {
        dispatch(subscriptionActions.loadPlans({ api, actions }));
    };

    const loadTeacherClasses = () => {
        dispatch(teacherActions.loadAllClasses({ api, actions }, {
            session: store.session,
        }));
    };

    /* --- */

    const onTabChange = (value) => {
        setTabSelectedPlan(value);
    };

    const onOpenPlan = (slug, requestQuoteUrl) => {
        if (requestQuoteUrl) {
            urls.openUrl(requestQuoteUrl);
        } else {
            navigate(`/subscribe/${slug}`);
        }
    };

    const onCloseTeacherLimitPopup = () => {
        teacherLimit.close();
    };

    const onEditClasses = () => {
        navigate("/class");
    };

    const onSubscribeToLargePlan = () => {
        // NOTE: magic word
        onOpenPlan("large-class", false);
    };

    const onPickPlan = (plan) => {
        if (plan.requestQuoteUrl) {
            onOpenPlan(plan.slug, plan.requestQuoteUrl);
            return;
        }

        if (Classes.isTeacherLimitReached(store.teacher.classes, plan)) {
            teacherLimit.open({
                planName: plan.name,
                maxClasses: plan.maxClasses,
                maxStudents: plan.maxStudents,
            });
        } else {
            onOpenPlan(plan.slug, plan.requestQuoteUrl);
        }
    };

    /* --- */

    useEffect(() => {
        loadTeacherClasses();
        loadPlans();
    }, []);

    useEffect(() => {
        if (store.subscription.isPlansLoaded && store.subscription.plans.length > 0) {
            setTabSelectedPlan(store.subscription.plans[0].slug);
        }
    }, [store.subscription]);

    useTitle(() => "Subscribe", []);

    /* --- */

    const renderCard = (plan = {}) => {
        const planPrice = plan.isRequestAQuotePlan
            ? "Inquire"
            : plan.price;

        return (
            <SubscribePlanCard
                planName={plan.name}
                buttonName={plan.buttonText}
                color={plan.color}
                price={planPrice}
                billingCycle={plan.billingCycle}
                hasPrice={!plan.isRequestAQuotePlan}
                features={plan.features}
                onPickPlan={() => {
                    onPickPlan(plan, plan.slug, plan.requestQuoteUrl);
                }}
            />
        );
    };

    const renderCards = () => {
        const cards = store.subscription.plans.map((plan) => renderCard(plan));

        return (
            <div className={styles.cards}>
                {cards}
            </div>
        );
    };

    const renderContent = () => {
        if (!store.subscription.isPlansLoaded
            || !store.teacher.isClassesLoaded) {
            return (
                <RequestLoader />
            );
        }

        if (!isMobile) {
            return renderCards();
        }

        const plans = store.subscription.plans.map((plan) => ({
            label: plan.name,
            value: plan.slug,
        }));

        const tabs = (
            <Tabs
                tabs={plans}
                selectedTab={tabSelectedPlan}
                onChange={onTabChange}
            />
        );

        const content = store.subscription.plans.map((plan) => {
            if (tabSelectedPlan === plan.slug) {
                return renderCard(plan);
            }

            return null;
        });

        return (
            <div className={styles.content}>
                {tabs}
                {content}
            </div>
        );
    };

    const renderClassLimitPopup = () => {
        if (!teacherLimit.state.isOpen) {
            return null;
        }

        return (
            <PopupConfirmTeacherLimit
                planName={teacherLimit.state.planName}
                maxStudents={teacherLimit.state.maxStudents}
                maxClasses={teacherLimit.state.maxClasses}
                onSubscribeToLargePlan={onSubscribeToLargePlan}
                onEditClasses={onEditClasses}
                onClose={onCloseTeacherLimitPopup}
            />
        );
    };

    return (
        <>
            {renderClassLimitPopup()}

            <div className={styles.page}>
                <div className={styles.logo}>
                    <LogoRound />
                    <div>Let&apos;s pick a plan!</div>
                </div>
                {renderContent()}
            </div>
        </>
    );
};

export default withAuth(["teacher"])(Subscribe);
