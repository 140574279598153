import React, { useEffect, useState } from "react";

import IconCopy from "juice-base/icons/copy/index.js";
import IconShare from "juice-base/icons/share/index.js";

import MenuWithButton, {
    MenuOption,
} from "juice-base/components/menu-with-button/index.js";

import useButtonShare from "./use-button-share.js";


const ButtonShare = (props) => {
    const [isVisibleMenu, setIsVisibleMenu] = useState(props.isVisibleMenu);

    /* --- */

    useEffect(() => {
        setIsVisibleMenu(props.isVisibleMenu);
    }, [props.isVisibleMenu]);

    /* --- */

    const onClick = (evt) => {
        if (props.isAuto) {
            setIsVisibleMenu((prev) => !prev);
        }

        props.onClick(evt);
    };

    const onCloseMenu = () => {
        if (props.isAuto) {
            setIsVisibleMenu(false);
        }

        props.onCloseMenu();
    };

    const onShareClassroom = () => {
        props.onShareClassroom();

        if (props.closeMenuOnClassroomClick) {
            onCloseMenu();
        }
    };

    const onCopyLink = () => {
        props.onCopyLink();

        if (props.closeMenuOnCopyLinkClick) {
            onCloseMenu();
        }
    };

    /* ----*/

    return (
        <MenuWithButton
            dataComment={props.dataComment}
            isVisibleMenu={isVisibleMenu}
            isMenuOpensDown={props.isMenuOpensDown}
            icon={<IconShare isOrange />}
            onClick={onClick}
            onCloseMenu={onCloseMenu}
        >
            <MenuOption
                googleClassroom={props.googleClassroom}
                onClick={onShareClassroom}
                isLoading={props.isGoogleClassroomLoading}
            />
            <MenuOption
                name="Copy Link"
                icon={<IconCopy title="Copy" isSky />}
                onClick={onCopyLink}
                isLoading={props.isCopyLinkLoading}
            />
        </MenuWithButton>
    );
};

ButtonShare.defaultProps = {
    dataComment: "",
    googleClassroom: null,

    onShareClassroom: () => { },
    onCopyLink: () => { },
    onClick: () => { },
    onCloseMenu: () => { },

    isCopyLinkLoading: false,
    isGoogleClassroomLoading: false,
    isMenuOpensDown: true,
    closeMenuOnClassroomClick: true,
    closeMenuOnCopyLinkClick: true,
    isAuto: true,
    isVisibleMenu: false,
};


export {
    useButtonShare,
};

export default ButtonShare;
