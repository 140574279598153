import React from "react";

import classNames from "juice-base/lib/class-names.js";

import IconCheck from "juice-base/icons/check/index.js";

import styles from "./styles.module.css";


const OptionWithLabels = (props) => {
    if (!props.option) {
        return null;
    }

    const onSelect = (evt) => {
        props.onSelect(evt, props.option);
    };

    const renderSelectedIcon = () => {
        if (!props.isSelected) {
            return null;
        }

        return (
            <IconCheck
                isRed
            />
        );
    };

    const optClassName = classNames({
        [styles.option]: true,
        [styles.optionWithOptionIcon]: props.option.optionIcon,
        [styles.optionWithIcon]: props.withIcon && !props.option.optionIcon,
        [styles.optionAlreadySelected]: props.isSelected,
    });

    const label2 = props.option.label2 || "";

    const optionWithLabelsClassName = classNames({
        [styles.optionWithLabel]: true,
        [styles.optionWithLabels]: label2,
    });

    return (
        <div
            className={optClassName}
            onClick={onSelect}
            onKeyPress={onSelect}
            tabIndex="-1"
            role="button"
        >
            {props.option.optionIcon || null}
            <div className={optionWithLabelsClassName}>
                <div>
                    {props.option.label || ""}
                </div>
                <div className={styles.optionLabel2}>
                    {label2}
                </div>
            </div>
            {renderSelectedIcon()}
        </div>
    );
};

OptionWithLabels.defaultProps = {
    option: null,
    isSelected: false,
    withIcon: false,
    onSelect: () => { },
};

export default OptionWithLabels;
