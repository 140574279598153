import React from "react";

import IconWrapper from "juice-base/icons/wrapper/index.js";


const IconLink = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    className={iconProps.className}
                    width="15"
                    height="8"
                    viewBox="0 0 15 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M11.25 0.25H8.25V1.75H11.25C12.4875 1.75 13.5 2.7625 13.5 4C13.5 5.2375 12.4875 6.25 11.25 6.25H8.25V7.75H11.25C13.32 7.75 15 6.07 15 4C15 1.93 13.32 0.25 11.25 0.25ZM6.75 6.25H3.75C2.5125 6.25 1.5 5.2375 1.5 4C1.5 2.7625 2.5125 1.75 3.75 1.75H6.75V0.25H3.75C1.68 0.25 0 1.93 0 4C0 6.07 1.68 7.75 3.75 7.75H6.75V6.25ZM4.5 3.25H10.5V4.75H4.5V3.25Z"
                        fill="currentColor"
                    />
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconLink;
