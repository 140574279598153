import React from "react";

import text from "juice-base/text/index.js";

import IconScoresHorizontal from "juice-base/icons/scores-horizontal/index.js";

import RequestLoader from "juice-base/components/request-loader/index.js";
import ButtonRounded from "juice-base/components/button-rounded/index.js";
import Message from "juice-base/components/message/index.js";
import PollSection from "juice-base/components/poll-section/index.js";

import SectionStudentV2 from "juice-base/business/section-student-v2/index.js";

import styles from "./styles.module.css";


const DailyJuicePolls = (props) => {
    const renderPoll = () => {
        const { poll, isLoading } = props;

        if (isLoading) {
            return (
                <RequestLoader />
            );
        }

        if (!poll) {
            return (
                <Message>
                    {text.noClosedPolls}
                </Message>
            );
        }

        return (
            <div className={styles.section}>
                <PollSection
                    isDisabled
                    isVisibleMessage={false}
                    isOutdated={poll?.isOutdated || false}
                    selectedAnswer={poll?.userAnswerId || null}
                    question={poll?.question || ""}
                    answers={poll?.answers || []}
                />
            </div>
        );
    };

    const renderContent = () => {
        let viewMoreButton = null;

        if (props.poll) {
            viewMoreButton = (
                <div className={styles.viewMoreButton}>
                    <ButtonRounded
                        uppercase
                        onClick={props.onViewMore}
                    >
                        View More
                    </ButtonRounded>
                </div>
            );
        }

        return (
            <div className={styles.sectionContainer}>
                {renderPoll()}
                {viewMoreButton}
            </div>
        );
    };

    return (
        <SectionStudentV2
            title="Latest Poll"
            icon={<IconScoresHorizontal isOrange />}
        >
            {renderContent()}
        </SectionStudentV2>
    );
};

DailyJuicePolls.defaultProps = {
    poll: null,
    isLoading: false,
    onViewMore: () => { },
};

export default DailyJuicePolls;
