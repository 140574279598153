import React from "react";
import { useSelector } from "react-redux";

import User from "juice-base/project/user.js";

import useTitle from "juice-base/hooks/use-title/index.js";

import { withAuth } from "juice-base/components/auth/index.js";

import TeacherDailyJuicesStudentEdition from "juice-app/containers/teacher-daily-juices-student-edition/index.js";
import GuardianDailyJuices from "juice-app/containers/guardian-daily-juices/index.js";
import StudentDailyJuicesRegular from "juice-app/containers/student-daily-juices-regular/index.js";
import StudentDailyJuicesTrial from "juice-app/containers/student-daily-juices-trial/index.js";


const storeSelector = (state) => ({
    isUserLoading: state.user.isUserLoading,
    user: state.user.user,
});

const DailyJuices = () => {
    const store = useSelector(storeSelector);

    useTitle(() => "Daily Juices", []);

    if (store.isUserLoading) {
        return null;
    }

    if (User.hasRoleTeacher(store.user)) {
        return (
            <TeacherDailyJuicesStudentEdition />
        );
    }

    if (User.hasRoleStudent(store.user)) {
        if (User.isTypeRegular(store.user)
            || User.isTypeHome(store.user)
            || User.isTypeFree(store.user)) {
            return (
                <StudentDailyJuicesRegular />
            );
        }

        if (User.isTypeTrial(store.user)) {
            return (
                <StudentDailyJuicesTrial />
            );
        }
    }

    if (User.hasRoleGuardian(store.user)) {
        return (
            <GuardianDailyJuices />
        );
    }

    return null;
};

export default withAuth([
    "teacher",
    "student",
    "guardian",
])(DailyJuices);
