import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import Grades from "juice-base/project/grades.js";
import LMS from "juice-base/project/lms.js";

import urls from "juice-base/lib/urls.js";
import repeater from "juice-base/lib/repeater.js";
import actions from "juice-base/store/actions.js";
import lmsActions from "juice-base/actions/lms.js";

import RequestLoader from "juice-base/components/request-loader/index.js";
import ButtonBig from "juice-base/components/button-big/index.js";
import PopupWindow from "juice-base/components/popup-window/index.js";

import LMSClassesForm from "juice-base/forms/lms-classes/index.js";

import PopupConfirmLMSClassRemoval, {
    usePopupConfirmLMSClassRemoval,
} from "juice-base/business/popup-confirm-lms-class-removal/index.js";

import api from "juice-app/api.js";

import styles from "./styles.module.css";


const storeSelector = (state) => ({
    session: state.user.session,
    user: state.user.user,
    isClassesLoaded: state.teacher.isClassesLoaded,
    classes: state.teacher.classes,
    lms: state.lms,
    lmsSignInUrl: state.info.features.lmsSignInUrl,
});

const TeacherPopupLMS = (props) => {
    const dispatch = useDispatch();
    const store = useSelector(storeSelector);
    const popupConfirmLMSClassRemoval = usePopupConfirmLMSClassRemoval();

    const jobRepeater = useRef(null);

    /* --- */

    const onOpenLMSSyncWarning = (classes, setSubmitting) => {
        popupConfirmLMSClassRemoval.open({
            classes,
            onCancel: () => {
                setSubmitting(false);
            },
        });
    };

    const onCloseLMSSyncWarning = () => {
        popupConfirmLMSClassRemoval.close();
    };

    /* --- */

    const loadProviders = () => {
        dispatch(lmsActions.loadProviders({ api, actions }, {
            session: store.session,
        }));
    };

    const onJob = (jobId) => {
        if (jobRepeater.current) {
            return;
        }

        dispatch(lmsActions.startSync({ actions }));

        jobRepeater.current = repeater.getFunctionRepeater(() => {
            const params = {
                session: store.session,
                jobId,
            };

            dispatch(lmsActions.checkSyncJob({ api, actions }, params, {
                onStop() {
                    dispatch(lmsActions.stopSync({ actions }));
                    jobRepeater.current.stop();
                },
                onSuccess() {
                    dispatch(lmsActions.stopSync({ actions }));
                    props.onSuccess();
                },
            }));
        });

        jobRepeater.current.start();
    };

    const syncLMSClasses = (classes) => {
        dispatch(lmsActions.syncClasses({ api, actions }, {
            session: store.session,
            classes,
        }, {
            onSuccess: onJob,
        }));
    };

    const onSyncLMSClasses = async (initialValues, classes, setSubmitting) => {
        const initSelectedClasses = Object.keys(initialValues);
        const classesIds = (classes || []).map((cl) => cl.classId);

        let hasDeletedClasses = false;

        for (let i = 0; i < initSelectedClasses.length; i += 1) {
            const cId = initSelectedClasses[i];

            if (classesIds.indexOf(cId) === -1) {
                const schoolClass = LMS.getProviderClassById(store.lms.providers, cId);

                if (schoolClass) {
                    classes.push({
                        ...schoolClass,
                        deleted: true,
                    });

                    hasDeletedClasses = true;
                }
            }
        }

        if (hasDeletedClasses) {
            onOpenLMSSyncWarning(classes, setSubmitting);
            return;
        }

        syncLMSClasses(classes);
    };

    useEffect(() => {
        loadProviders();

        return () => {
            if (jobRepeater?.current?.stop) {
                jobRepeater.current.stop();
            }

            dispatch(actions.lms.clearSyncingClasses());
        };
    }, []);

    /* --- */

    if (!store.user.isLmsUser) {
        return null;
    }

    const renderContent = () => {
        if (store.lms.syncingError) {
            return (
                <div>
                    <div className={styles.lmsError}>
                        {store.lms.syncingError}
                    </div>
                    <div className={styles.lmsControl}>
                        <ButtonBig
                            type="button"
                            onClick={() => {
                                urls.reload();
                            }}
                        >
                            Try again
                        </ButtonBig>
                    </div>
                </div>
            );
        }

        if (store.lms.isSyncingClasses) {
            return (
                <div>
                    <RequestLoader />
                    <div className={styles.lmsError}>
                        Syncing classes ...
                    </div>
                </div>
            );
        }

        if (store.lms.providersError) {
            return (
                <div className={styles.lmsMessage}>
                    <div className={styles.lmsError}>
                        {store.lms.providersError}
                    </div>
                    <div className={styles.lmsControl}>
                        <ButtonBig
                            type="button"
                            onClick={() => {
                                urls.redirect(store.lmsSignInUrl);
                            }}
                        >
                            Sign In with LMS
                        </ButtonBig>
                    </div>
                </div>
            );
        }

        if (!store.lms.isProvidersLoaded || !store.isClassesLoaded) {
            return (
                <RequestLoader />
            );
        }

        const initialValues = {};

        store.classes
            .filter((cl) => cl.isLmsClass)
            .forEach((cs) => {
                const lmsId = cs.lmsId || "";

                if (lmsId) {
                    initialValues[lmsId] = {
                        isChecked: true,
                        grade: cs.grade
                            ? Grades.getValidGrade(cs.grade)
                            : Grades.getMaxGradeValue(),
                    };
                }
            });

        return (
            <LMSClassesForm
                initialValues={initialValues}
                providers={store.lms.providers}
                onSubmit={({ classes, setSubmitting }) => {
                    onSyncLMSClasses(initialValues, classes, setSubmitting);
                }}
            />
        );
    };

    const renderConfirmPopup = () => {
        if (!popupConfirmLMSClassRemoval.state.isVisible) {
            return null;
        }

        return (
            <PopupConfirmLMSClassRemoval
                onProceed={() => {
                    syncLMSClasses(popupConfirmLMSClassRemoval.state.classes);
                    onCloseLMSSyncWarning();
                }}
                onClose={() => {
                    popupConfirmLMSClassRemoval.cancel();
                    onCloseLMSSyncWarning();
                }}
            />
        );
    };

    return (
        <>
            {renderConfirmPopup()}
            <PopupWindow
                title="LMS Class Management"
                onClose={props.onClose}
            >
                <div className={styles.content}>
                    {renderContent()}
                </div>
            </PopupWindow>
        </>
    );
};

TeacherPopupLMS.defaultProps = {
    onSuccess: () => { },
    onClose: () => { },
};

export default TeacherPopupLMS;
