import React, { useRef, useEffect } from "react";
import * as d3 from "d3";

import classNames from "juice-base/lib/class-names.js";

import text from "juice-base/text/index.js";

import Message from "juice-base/components/message/index.js";

import styles from "./styles.module.css";


const ChartRadar = (props) => {
    const chartRef = useRef(null);

    const sizes = {
        width: 380,
        height: 380,
    };

    if (props.isMobile) {
        sizes.width = 270;
        sizes.height = 270;
    }

    const size = Math.min(sizes.width, sizes.height);
    const offset = Math.PI;

    const polygon = (Math.PI * 2) / props.numberOfSides;
    const r = 0.8 * size;
    const r0 = r / 2;

    const center = {
        x: size / 2,
        y: size / 2,
    };

    const colors = [
        {
            fill: "var(--chart-radar-color-1)",
            stroke: "var(--chart-radar-color-stroke-1)",
        },
        {
            fill: "var(--chart-radar-color-2)",
            stroke: "var(--chart-radar-color-stroke-2)",
        },
        {
            fill: "var(--chart-radar-color-3)",
            stroke: "var(--chart-radar-color-stroke-3)",
        },
    ];

    /* ---- */

    const getShapeColorByIndex = (index) => {
        if (index < 0) {
            return colors[0];
        }

        if (colors[index]) {
            return colors[index];
        }

        return getShapeColorByIndex(index - colors.length);
    };

    const genTicks = (levels) => {
        const ticks = [];

        for (let i = 0; i <= levels; i += 1) {
            const tickParams = {
                tick: i,
                tooltip: null,
                isVisible: true,
            };

            if (props?.ticks?.[i]?.tick) {
                tickParams.tick = props.ticks[i].tick;
                tickParams.tooltip = props.ticks[i]?.tooltip || "";
                tickParams.isVisible = props.ticks[i]?.isVisible;
            }

            ticks.push(tickParams);
        }

        return ticks;
    };

    /* ---- */

    const generatePoint = ({ length, angle }) => ({
        x: center.x + (length * Math.sin(offset - angle)),
        y: center.y + (length * Math.cos(offset - angle)),
    });

    const drawPath = (points, parent, fill, stroke, isShape) => {
        const lineGenerator = d3.line()
            .x((d) => d.x)
            .y((d) => d.y);

        parent.append("path")
            .attr("d", lineGenerator(points))
            .attr("fill", () => {
                if (fill) {
                    return fill;
                }

                return null;
            })
            .attr("stroke", () => {
                if (stroke) {
                    return stroke;
                }

                return null;
            })
            .attr("stroke-width", () => {
                if (isShape) {
                    return 2;
                }

                return 1;
            });
    };

    const generateAndDrawLevels = (g, levelsCount, sideCount) => {
        for (let level = 1; level <= levelsCount; level += 1) {
            const hyp = (level / levelsCount) * r0;
            const points = [];

            for (let vertex = 0; vertex < sideCount; vertex += 1) {
                const theta = vertex * polygon;

                points.push(generatePoint({ length: hyp, angle: theta }));
            }

            const group = g.append("g")
                .attr("class", styles.levels);

            drawPath([...points, points[0]], group);
        }
    };

    const generateAndDrawLines = (g, sideCount) => {
        const group = g.append("g").attr("class", styles.gridLines);

        for (let vertex = 1; vertex <= sideCount; vertex += 1) {
            const theta = vertex * polygon;
            const point = generatePoint({
                length: r0,
                angle: theta,
            });

            drawPath([center, point], group);
        }
    };

    const drawCircles = (g, points, fill) => {
        g.append("g")
            .selectAll("circle")
            .data(points)
            .enter()
            .append("circle")
            .attr("cx", (d) => d.x)
            .attr("cy", (d) => d.y)
            .attr("r", 4)
            .attr("fill", () => {
                if (fill) {
                    return fill;
                }

                return null;
            })
            .attr("stroke", "var(--white)");
    };

    const drawCircleTooltip = (group, data) => {
        group.append("foreignObject")
            .attr("class", styles.circleTooltip)
            .attr("x", data.x - 8)
            .attr("y", data.y - 8)
            .html(() => {
                const tooltipClassName = classNames({
                    [styles.tooltipTick]: true,
                    [styles.tooltipCircle]: true,
                });

                return (`
                    <div class="${tooltipClassName}">
                        <div class="${styles.tooltipTickArrowUp}"></div>
                        ${data.tooltip}
                    </div>
                `);
            });
    };

    const drawCirclesTooltips = (g, shapePoints) => {
        const group = g.append("g").attr("class", styles.shapesTooltips);

        shapePoints.forEach((p) => {
            if (p.tooltip) {
                drawCircleTooltip(group, p);
            }
        });
    };

    const drawLabel = (label, points, group, tooltipParams) => {
        const angleDeg = (Math.atan2(points.y - center.y, points.x - center.x) * 180) / Math.PI;

        group.append("foreignObject")
            .attr("class", styles.label)
            .attr("x", points.x - 8)
            .attr("y", points.y - 8)
            .html(() => {
                const tooltipClassName = classNames({
                    [styles.labelTooltip]: true,
                    [styles.labelTooltipRight]: tooltipParams.isTooltipRight,
                    [styles.labelTooltipBottom]: tooltipParams.isTooltipBottom,
                    [styles.labelTooltipCenter]: tooltipParams.isTooltipCenter,
                });

                return (`
                    <div>
                        <div
                            class="${styles.label}"
                            style="transform: rotate(${angleDeg}deg)"
                        >
                            ${label.label}
                        </div>
                        <div class="${tooltipClassName}">
                            ${label.tooltip}
                        </div>
                    </div>
                `);
            });
    };

    const drawTick = (tick, point, group) => {
        group.append("foreignObject")
            .attr("class", styles.axisContainer)
            .attr("x", point.x - 8)
            .attr("y", point.y - 8)
            .html(() => {
                return `
                    <div class="${styles.axisText}">
                        ${tick.tick}
                    </div>
                `;
            });
    };

    const drawTickTooltip = (tick, point, group) => {
        group.append("foreignObject")
            .attr("class", styles.axisContainer)
            .attr("x", point.x - 8)
            .attr("y", point.y - 8)
            .html(() => {
                return `
                    <div class="${styles.tooltipTick}">
                        <div class="${styles.tooltipTickArrowUp}"></div>
                        ${tick.tooltip}
                    </div>
                `;
            });
    };

    const drawShape = (g, shapes, n) => {
        for (let i = 0; i < shapes.length; i += 1) {
            const scale = d3.scaleLinear()
                .domain([0, 100])
                .range([0, r0])
                .nice();

            const p = shapes[i].map((shape, index) => {
                const len = scale(shape.value);
                const theta = index * ((2 * Math.PI) / n);

                return {
                    ...generatePoint({ length: len, angle: theta }),
                    value: shape.value,
                    tooltip: shape.tooltip || null,
                };
            });

            if (p.length > 0) {
                const group = g.append("g")
                    .attr("class", "shape");

                const pathColor = getShapeColorByIndex(i);

                const fill = pathColor?.fill || null;
                const stroke = pathColor?.stroke || null;

                drawPath([...p, p[0]], group, fill, stroke, true);

                drawCircles(g, p, stroke);
                drawCirclesTooltips(g, p);
            }
        }
    };

    const drawTicks = (g, ticks, levelsCount) => {
        const groupT = g.append("g").attr("class", styles.ticks);

        ticks.forEach((tick, i) => {
            if (tick.isVisible) {
                const radius = (i / levelsCount) * r0;
                const point = generatePoint({ length: radius, angle: 0 });

                drawTick(tick, point, groupT);
            }
        });
    };

    const drawTicksTooltips = (g, ticks, levelsCount) => {
        const groupT = g.append("g").attr("class", styles.ticks);

        ticks.forEach((tick, i) => {
            if (tick.tooltip) {
                const radius = (i / levelsCount) * r0;
                const point = generatePoint({ length: radius, angle: 0 });

                drawTickTooltip(tick, point, groupT);
            }
        });
    };

    const drawLabels = (g, labels, sideCount) => {
        const groupL = g.append("g").attr("class", "labels");

        for (let vertex = 0; vertex < sideCount; vertex += 1) {
            const angle = vertex * polygon;
            const label = labels[vertex];

            const point = generatePoint({
                length: 0.9 * (size / 2),
                angle,
            });

            const tooltipParams = {
                isTooltipRight: vertex < ((sideCount - 1) / 2),
                isTooltipBottom: vertex > sideCount * 0.20 && vertex < sideCount * 0.65,
                isTooltipCenter: vertex === 0,
            };

            drawLabel(label, point, groupL, tooltipParams);
        }
    };

    /* ---- */

    const initOrUpdateChart = (chart) => {
        if (!chart) {
            return;
        }

        const labels = props?.data?.labels || [];
        const numberOfSides = props.numberOfSides || 0;
        const shapes = props?.data?.shapes || [];

        const svg = d3.select(chart);

        svg.selectAll("*").remove();

        const g = svg.append("g");
        const ticks = genTicks(props.numberOfLevel);

        generateAndDrawLevels(g, props.numberOfLevel, numberOfSides);
        generateAndDrawLines(g, numberOfSides);

        drawTicks(g, ticks, props.numberOfLevel);
        drawShape(g, shapes, numberOfSides);

        drawTicksTooltips(g, ticks, props.numberOfLevel);

        drawLabels(g, labels, numberOfSides);
    };

    /* ---- */

    useEffect(() => {
        initOrUpdateChart(chartRef.current);
    }, [props.data]);

    /* ---- */

    if (props.data?.labels?.length < 3
        || props?.numberOfSides < 3
        || props?.numberOfLevel === 0) {
        return (
            <Message>
                {text.chartRadarError}
            </Message>
        );
    }

    return (
        <svg
            ref={chartRef}
            className={styles.chartRadar}
            style={{
                width: sizes.width,
                height: sizes.height,
            }}
        />
    );
};

ChartRadar.defaultProps = {
    data: [],
    ticks: [],
    numberOfSides: 5,
    numberOfLevel: 9,
    isMobile: false,
};

export default ChartRadar;
