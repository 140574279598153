// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dmESZzCrPgAP6b01mDKl {\n    background-color: var(--background-color)\n}\n\n/* --- */\n\n.sIJh274U3kKjpPk32fNO {}\n\n/* --- */\n\n.k8o3ETA0NWGzgfUEdgZd {\n    padding: 2rem 2rem 1rem 2rem;\n}\n\n.tuNQLrTuW2v3jB4IUj6i {\n    color: var(--text-primary-color);\n    padding: 0 2rem 2rem 2rem;\n}\n\n.iGXIGYQVIE8Kgrl8brtb {\n    display: grid;\n    grid-auto-flow: column;\n    grid-template-columns: max-content max-content;\n    grid-gap: 1rem;\n\n    align-items: center;\n\n    padding: 0 2rem 2rem 2rem;\n}\n\n.rUJRaU33OXB4Snsm03AQ {\n    padding: 0 2rem 2rem 2rem;\n}\n\n@media only screen and (min-width: 620px) {\n    .k8o3ETA0NWGzgfUEdgZd,\n    .tuNQLrTuW2v3jB4IUj6i,\n    .iGXIGYQVIE8Kgrl8brtb,\n    .rUJRaU33OXB4Snsm03AQ {\n        padding-left: 0;\n        padding-right: 0;\n    }\n}\n", "",{"version":3,"sources":["webpack://./base/business/daily-juice-story/styles.module.css"],"names":[],"mappings":"AAAA;IACI;AACJ;;AAEA,QAAQ;;AAER,uBAAyB;;AAEzB,QAAQ;;AAER;IACI,4BAA4B;AAChC;;AAEA;IACI,gCAAgC;IAChC,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,8CAA8C;IAC9C,cAAc;;IAEd,mBAAmB;;IAEnB,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI;;;;QAII,eAAe;QACf,gBAAgB;IACpB;AACJ","sourcesContent":[".juiceStory {\n    background-color: var(--background-color)\n}\n\n/* --- */\n\n.featuredVideoContainer {}\n\n/* --- */\n\n.category {\n    padding: 2rem 2rem 1rem 2rem;\n}\n\n.headline {\n    color: var(--text-primary-color);\n    padding: 0 2rem 2rem 2rem;\n}\n\n.playerAudioToggleButton {\n    display: grid;\n    grid-auto-flow: column;\n    grid-template-columns: max-content max-content;\n    grid-gap: 1rem;\n\n    align-items: center;\n\n    padding: 0 2rem 2rem 2rem;\n}\n\n.content {\n    padding: 0 2rem 2rem 2rem;\n}\n\n@media only screen and (min-width: 620px) {\n    .category,\n    .headline,\n    .playerAudioToggleButton,\n    .content {\n        padding-left: 0;\n        padding-right: 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"juiceStory": "dmESZzCrPgAP6b01mDKl",
	"featuredVideoContainer": "sIJh274U3kKjpPk32fNO",
	"category": "k8o3ETA0NWGzgfUEdgZd",
	"headline": "tuNQLrTuW2v3jB4IUj6i",
	"playerAudioToggleButton": "iGXIGYQVIE8Kgrl8brtb",
	"content": "rUJRaU33OXB4Snsm03AQ"
};
export default ___CSS_LOADER_EXPORT___;
