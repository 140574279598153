// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.pSXp2vVyEGpzKhSvJDP1 {\n    display: grid;\n    grid-auto-flow: column;\n    grid-gap: 1rem;\n\n    align-items: center;\n    justify-content: start;\n\n    padding: 0 2rem;\n    font-weight: 600;\n\n    user-select: none;\n\n    cursor: pointer;\n    outline: none;\n}\n\n.YckOdlg3y67fV4nf3d58 {\n    width: 1.8rem;\n    height: 1.8rem;\n\n    transform: rotate(90deg);\n}\n\n.hPDCz15waYTRAXWwh8bQ {\n    transform: rotate(-90deg);\n}\n", "",{"version":3,"sources":["webpack://./base/business/table-sortable-column-name/styles.module.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,sBAAsB;IACtB,cAAc;;IAEd,mBAAmB;IACnB,sBAAsB;;IAEtB,eAAe;IACf,gBAAgB;;IAEhB,iBAAiB;;IAEjB,eAAe;IACf,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,cAAc;;IAEd,wBAAwB;AAC5B;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":["\n.columnName {\n    display: grid;\n    grid-auto-flow: column;\n    grid-gap: 1rem;\n\n    align-items: center;\n    justify-content: start;\n\n    padding: 0 2rem;\n    font-weight: 600;\n\n    user-select: none;\n\n    cursor: pointer;\n    outline: none;\n}\n\n.sortIcon {\n    width: 1.8rem;\n    height: 1.8rem;\n\n    transform: rotate(90deg);\n}\n\n.sortIconDown {\n    transform: rotate(-90deg);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"columnName": "pSXp2vVyEGpzKhSvJDP1",
	"sortIcon": "YckOdlg3y67fV4nf3d58",
	"sortIconDown": "hPDCz15waYTRAXWwh8bQ"
};
export default ___CSS_LOADER_EXPORT___;
