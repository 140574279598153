// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sbmICUFhvVFFd0ZMxSb9 {\n    height: 46rem;\n\n    padding: 2rem;\n\n    display: grid;\n    grid-template-rows: auto min-content min-content;\n}\n\n.IQSEcqXaSRECtXv84e0y {\n    grid-template-rows: max-content auto min-content min-content;\n}\n\n.BeaeQyx6r7X0Le_QZBs_ {\n    display: grid;\n\n    grid-gap: 2rem;\n}\n", "",{"version":3,"sources":["webpack://./base/forms/class-details/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;;IAEb,aAAa;;IAEb,aAAa;IACb,gDAAgD;AACpD;;AAEA;IACI,4DAA4D;AAChE;;AAEA;IACI,aAAa;;IAEb,cAAc;AAClB","sourcesContent":[".form {\n    height: 46rem;\n\n    padding: 2rem;\n\n    display: grid;\n    grid-template-rows: auto min-content min-content;\n}\n\n.formWithError {\n    grid-template-rows: max-content auto min-content min-content;\n}\n\n.submitButton {\n    display: grid;\n\n    grid-gap: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "sbmICUFhvVFFd0ZMxSb9",
	"formWithError": "IQSEcqXaSRECtXv84e0y",
	"submitButton": "BeaeQyx6r7X0Le_QZBs_"
};
export default ___CSS_LOADER_EXPORT___;
