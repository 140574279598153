import React from "react";

import IconWrapper from "juice-base/icons/wrapper/index.js";


const IconMedal = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    width="14"
                    height="19"
                    viewBox="0 0 14 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={iconProps.className}
                >
                    {iconProps.title}
                    <path
                        fill="currentColor"
                        d="M5.06658 10.4084L6.99992 8.94175L8.92492 10.4084L8.19158 8.03341L10.1249 6.50008H7.75825L6.99992 4.15841L6.24158 6.50008H3.87492L5.79992 8.03341L5.06658 10.4084ZM13.6666 7.33341C13.6666 3.65008 10.6832 0.666748 6.99992 0.666748C3.31658 0.666748 0.333252 3.65008 0.333252 7.33341C0.333252 9.02508 0.966585 10.5584 1.99992 11.7334V18.1667L6.99992 16.5001L11.9999 18.1667V11.7334C13.0332 10.5584 13.6666 9.02508 13.6666 7.33341ZM6.99992 2.33341C9.75825 2.33341 11.9999 4.57508 11.9999 7.33341C11.9999 10.0917 9.75825 12.3334 6.99992 12.3334C4.24158 12.3334 1.99992 10.0917 1.99992 7.33341C1.99992 4.57508 4.24158 2.33341 6.99992 2.33341Z"
                    />
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconMedal;
