import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";

import actions from "juice-base/store/actions.js";
import vocabularyActions from "juice-base/actions/vocabulary.js";

import AudioManagerContext from "juice-base/context/audio-manager/index.js";

import useTitle from "juice-base/hooks/use-title/index.js";

import { withAuth } from "juice-base/components/auth/index.js";
import LayoutContent from "juice-base/components/layout-content/index.js";
import RequestLoader from "juice-base/components/request-loader/index.js";
import Section from "juice-base/components/section/index.js";
import Searcher from "juice-base/components/searcher/index.js";

import PopupWord from "juice-base/business/popup-word/index.js";

import api from "juice-app/api.js";

import styles from "./styles.module.css";


const storeSelector = (state) => ({
    session: state.user.session,
    isWordsLoaded: state.vocabulary.isWordsLoaded,
    wordPopup: state.vocabulary.popup,
    wordsByName: state.vocabulary.wordsByName,
    words: state.vocabulary.words,
    playerState: state.player.playerState,
});

const VocabularyView = () => {
    const audioManager = useContext(AudioManagerContext);

    const [page, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(true);

    const dispatch = useDispatch();
    const store = useSelector(storeSelector);

    const setPageAndHasMore = (isHasMore) => {
        setHasMore(isHasMore);
        setPage((prev) => prev + 1);
    };

    const getWords = async () => {
        const res = await api.vocabulary.getWords({ page });

        if (res.ok) {
            dispatch(actions.vocabulary.setVocabularyWords({
                words: store.words.concat(res.words),
            }));

            setPageAndHasMore(res.hasMore);
        }
    };

    /* --- */

    useEffect(() => {
        getWords();

        return () => {
            dispatch(vocabularyActions.closePopup({ actions }));
        };
    }, []);

    useTitle(() => "Vocabulary", []);

    /* --- */

    const onSearchWord = (value) => {
        return api.vocabulary.searchWords({
            session: store.session,
            value,
        }).then((res) => {
            if (!res.ok) {
                return [];
            }

            return (res.words || []).map((w) => ({
                value: w.id,
                label: w.word,
            }));
        });
    };

    const onWordClick = async (word) => {
        dispatch(vocabularyActions.openPopup({
            api,
            actions,
        }, {
            session: store.session,
            word,
        }));
    };

    const onCloseWordPopup = () => {
        dispatch(vocabularyActions.closePopup({ actions }));
    };

    const onSelectWord = (value) => {
        if (value) {
            onWordClick(value.label);
        }
    };

    const renderSearcher = () => {
        return (
            <Searcher
                onLoad={onSearchWord}
                onChange={onSelectWord}
            />
        );
    };

    const renderWords = () => {
        const ws = store.words.map((w) => {
            return (
                <div key={w.id} className={styles.word}>
                    <div
                        className={styles.wordLink}
                        onClick={() => {
                            onWordClick(w.word);
                        }}
                        onKeyPress={() => {
                            onWordClick(w.word);
                        }}
                        tabIndex="-1"
                        role="button"
                    >
                        {w.word}
                    </div>
                </div>
            );
        });

        if (ws.length === 0) {
            const noWords = (
                <div className={styles.noWords}>
                    No words
                </div>
            );

            ws.push(noWords);
        }

        const loader = (
            <p>Loading...</p>
        );

        return (
            <div className={styles.words}>
                <InfiniteScroll
                    dataLength={store.words.length}
                    next={getWords}
                    hasMore={hasMore}
                    loader={loader}
                >
                    {ws}
                </InfiniteScroll>
            </div>
        );
    };

    const renderWordPopup = () => {
        if (!store.wordPopup.isVisible) {
            return null;
        }

        const trackGroupName = "words";
        const audioData = store.playerState?.[trackGroupName] || {};

        return (
            <PopupWord
                wordsByName={store.wordsByName}
                wordPopup={store.wordPopup}
                audio={audioData}
                onAudioLoad={(txt) => {
                    audioManager.loadWord(txt);
                }}
                onAudioPlay={(txt) => {
                    audioManager.play(trackGroupName, txt);
                }}
                onAudioStop={(txt) => {
                    audioManager.stop(trackGroupName, txt);
                }}
                onAudioStopAll={(words) => {
                    audioManager.stopAllTracks(trackGroupName, words);
                }}
                onClose={onCloseWordPopup}
            />
        );
    };

    if (!store.isWordsLoaded) {
        return (
            <RequestLoader />
        );
    }

    return (
        <>
            {renderWordPopup()}

            <LayoutContent>
                <div className={styles.vocabulary}>
                    <Section name="Vocab">
                        {renderSearcher()}
                        {renderWords()}
                    </Section>
                </div>
            </LayoutContent>
        </>
    );
};

export default withAuth(["student", "teacher"])(VocabularyView);
