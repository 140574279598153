// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".y4299W53shNMEF1OlwUH {\n    padding: 2rem;\n}\n\n\n/* --- */\n\n.tCpk8cWFEqWwgKVBr4LV {\n    position: fixed;\n    top: 0;\n    left: 0;\n    bottom: 0;\n\n    width: 100%;\n}\n\n\n@media only screen and (min-width: 800px) {\n    .tCpk8cWFEqWwgKVBr4LV {\n        left: 44rem;\n        top: 27rem;\n        bottom: auto;\n\n        width: 34rem;\n    }\n}\n", "",{"version":3,"sources":["webpack://./base/business/popup-student-info/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;;AAGA,QAAQ;;AAER;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,SAAS;;IAET,WAAW;AACf;;;AAGA;IACI;QACI,WAAW;QACX,UAAU;QACV,YAAY;;QAEZ,YAAY;IAChB;AACJ","sourcesContent":[".loader {\n    padding: 2rem;\n}\n\n\n/* --- */\n\n.datepicker {\n    position: fixed;\n    top: 0;\n    left: 0;\n    bottom: 0;\n\n    width: 100%;\n}\n\n\n@media only screen and (min-width: 800px) {\n    .datepicker {\n        left: 44rem;\n        top: 27rem;\n        bottom: auto;\n\n        width: 34rem;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": "y4299W53shNMEF1OlwUH",
	"datepicker": "tCpk8cWFEqWwgKVBr4LV"
};
export default ___CSS_LOADER_EXPORT___;
