import React from "react";

import PopupWindow from "juice-base/components/popup-window/index.js";
import ClassAddForm from "juice-base/forms/class-add/index.js";


const PopupClassAdd = (props) => {
    const renderContent = () => {
        return (
            <ClassAddForm
                grades={props.grades}
                onSubmit={props.onSubmit}
            />
        );
    };

    return (
        <PopupWindow
            title="New Class"
            onClose={props.onClose}
        >
            {renderContent()}
        </PopupWindow>
    );
};

PopupClassAdd.defaultProps = {
    grades: [],

    onSubmit: () => {},
    onClose: () => {},
};

export default PopupClassAdd;
