import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import User from "juice-base/project/user.js";

import actions from "juice-base/store/actions.js";
import subscriptionActions from "juice-base/actions/subscription.js";

import usePopup from "juice-base/hooks/use-popup/index.js";
import useSnackbar from "juice-base/hooks/use-snackbar/index.js";

import IconArrowBold from "juice-base/icons/arrow-bold/index.js";

import PopupWindow from "juice-base/components/popup-window/index.js";
import Tabs from "juice-base/components/tabs/index.js";
import Snackbar from "juice-base/components/snackbar/index.js";
import PopupConfirmMessage from "juice-base/components/popup-confirm-message/index.js";
import RequestLoader from "juice-base/components/request-loader/index.js";
import ButtonCircle from "juice-base/components/button-circle/index.js";

import PopupFullnameChange from "juice-base/business/popup-fullname-change/index.js";
import PopupProfileAvatar from "juice-base/business/popup-profile-avatar/index.js";
import PopupSubscriptionCancel from "juice-base/business/popup-subscription-cancel/index.js";

import UserPlans from "juice-app/containers/user-plans/index.js";
import Stripe from "juice-app/containers/stripe/index.js";

import settings from "juice-app/settings.js";
import api from "juice-app/api.js";

import PersonalInfo from "./personal-info.js";
import Payment from "./payment.js";
import PlanDetails from "./plan-details.js";
import styles from "./styles.module.css";


const storeSelector = (state) => ({
    session: state.user.session,
    user: state.user.user,
    paymentDetails: state.user.paymentDetails,
    isPaymentDetailsLoaded: state.user.isPaymentDetailsLoaded,
    subscription: state.subscription,
    geo: state.geo,
});

const getPlanDetailsTabState = () => ({
    isVisibleQuoteStatus: false,
    isVisiblePaymentDetails: false,
    isVisiblePaymentChange: false,
    isPaymentSubmitted: false,
});

const getPaymentTabState = () => ({
    isVisiblePaymentChange: false,
});

const UserPopupAccount = (props) => {
    const [selectedTab, setSelectedTab] = useState("personal-info");

    const uploadAvatarPopup = usePopup();
    const deleteAvatarPopup = usePopup();
    const fullnamePopup = usePopup();

    const [isLoadingAutoRenewSubscription, setIsLoadingAutoRenewSubscription] = useState(false);
    const [isLoadingEmailNotificationStatus, setIsLoadingEmailNotificationStatus] = useState(false);

    const [isVisiblePlans, setIsVisiblePlans] = useState(false);
    const [planDetailsTabState, setPlanDetailsTabState] = useState(() => getPlanDetailsTabState());
    const [paymentTabState, setPaymentTabState] = useState(() => getPaymentTabState());

    const [isVisibleCancelSubscriptionPopup, setIsVisibleCancelSubscriptionPopup] = useState(false);

    /* --- */

    const snackbar = useSnackbar();
    const dispatch = useDispatch();
    const store = useSelector(storeSelector);

    const isTeacher = User.hasRoleTeacher(store.user);
    const isUserHasCardDetails = User.hasCardDetails(store.paymentDetails);
    const isUserHasPlan = User.hasPlan(store.user);

    /* --- */

    const loadPlans = async () => {
        dispatch(subscriptionActions.loadActivePlans({ api, actions }));
        dispatch(subscriptionActions.loadPlans({ api, actions }));
    };

    const onLoadUserPaymentDetails = async () => {
        dispatch(actions.user.setUserPaymentDetailsLoaded({
            isLoaded: false,
        }));

        const res = await api.subscription.getUserPaymentDetails({
            session: store.session,
        });

        if (res.ok) {
            dispatch(actions.user.setUserPaymentDetails({
                card: res.data,
            }));
        }
    };

    /* --- */

    const onTabChange = (value) => {
        if (value === "plan-details") {
            onLoadUserPaymentDetails();
            loadPlans();
        }

        if (value === "payment") {
            onLoadUserPaymentDetails();
        }

        setSelectedTab(value);
    };

    /* --- */

    const onUploadProfileImage = async (evt) => {
        uploadAvatarPopup.setIsSaving();

        if (!evt) {
            const res = await api.user.userDeleteAvatar({
                session: store.session,
            });

            if (res.ok) {
                dispatch(actions.user.setUserAvatar({
                    avatarUrl: "",
                }));
            }

            uploadAvatarPopup.close();
            return;
        }

        const file = evt.target.files[0];

        const res = await api.user.userSetAvatar({
            session: store.session,
            file,
        });

        if (!res.ok) {
            uploadAvatarPopup.setError("Something went wrong. Please try again.");
            return;
        }

        dispatch(actions.user.setUserAvatar({
            avatarUrl: res.avatarUrl,
        }));

        uploadAvatarPopup.close();
    };

    /* --- */

    const onDeleteAvatar = async () => {
        deleteAvatarPopup.open();

        const res = await api.user.userDeleteAvatar({
            session: store.session,
        });

        if (res.ok) {
            dispatch(actions.user.setUserAvatar({
                avatarUrl: "",
            }));
        }

        deleteAvatarPopup.setIsLoaded();
        deleteAvatarPopup.setMessage("Your avatar was successfully deleted.");
    };

    /* --- */

    const onEditFullname = () => {
        fullnamePopup.open();
    };

    const onCloseEditFullnamePopup = () => {
        fullnamePopup.close();
    };

    const onSaveFullname = async (params) => {
        fullnamePopup.setIsSaving();

        const res = await api.user.updateFullname({
            session: store.session,
            fullname: params.value,
        });

        let message = "";

        if (res.ok) {
            message = "Name successfully changed!";
            fullnamePopup.close();
            props.onLoadUser();
        } else {
            message = res.error || "Failed to change.";
            fullnamePopup.setError(message);
        }

        snackbar.add({
            message,
        });
    };

    /* --- */

    const onChangeEmailNotification = async (isEnabled) => {
        setIsLoadingEmailNotificationStatus(true);

        const res = await api.user.updateUserSettings({
            session: store.session,
            key: "isEmailNotificationEnabled",
            value: isEnabled,
        });

        let message = "";

        if (res.ok) {
            dispatch(actions.user.setUserEmailNotification({
                isEnabled,
            }));

            props.onLoadUser();

            if (isEnabled) {
                message = "Email notifications successfully enabled!";
            } else {
                message = "Email notifications successfully disabled!";
            }
        } else {
            message = res.error;
        }

        setIsLoadingEmailNotificationStatus(false);

        snackbar.add({
            message,
        });
    };

    /* --- */

    const onOpenCancelSubscriptionPopup = () => {
        setIsVisibleCancelSubscriptionPopup(true);
    };

    const onCloseCancelSubscriptionPopup = () => {
        setIsVisibleCancelSubscriptionPopup(false);
    };

    /* --- */

    const onUpgradePlan = () => {
        setIsVisiblePlans(true);
    };

    const onClosePlans = () => {
        onTabChange("personal-info");

        setIsVisiblePlans(false);
        setPlanDetailsTabState(getPlanDetailsTabState());
    };

    /* --- */

    const getCardDetails = () => {
        const card = {
            name: store.paymentDetails?.defaultPaymentMethod?.card?.name || "",
            last4: store.paymentDetails?.defaultPaymentMethod?.card?.last4digits || "",
            expMonth: store.paymentDetails?.defaultPaymentMethod?.card?.expMonth || "",
            expYear: store.paymentDetails?.defaultPaymentMethod?.card?.expYear || "",
        };

        return card;
    };

    /* --- */

    const onChangeAutoRenewPlan = async (isEnabled) => {
        setIsLoadingAutoRenewSubscription(true);

        const res = await api.subscription.subscriptionChangeAutoRenew({
            session: store.session,
            subscriptionEncId: store.user.subscription.subscriptionEncId,
            value: isEnabled,
        });

        let message = "";

        if (res.ok) {
            dispatch(actions.user.setUserAutoRenewSubscription({
                isEnabled,
            }));

            props.onLoadUser();

            if (isEnabled) {
                message = "Auto-Renew Plan successfully enabled!";
            } else {
                message = "Auto-Renew Plan successfully disabled!";
            }
        } else {
            message = res.error;
        }

        setIsLoadingAutoRenewSubscription(false);

        snackbar.add({
            message,
        });
    };

    /* --- */

    const onPlansSetIsPaymentVisible = (isVisible) => {
        setPlanDetailsTabState((prev) => ({
            ...prev,
            isVisiblePaymentDetails: isVisible,
        }));
    };

    const onPlansSetIsQuoteStatusVisible = (isVisible) => {
        setPlanDetailsTabState((prev) => ({
            ...prev,
            isVisibleQuoteStatus: isVisible,
        }));
    };

    const onPlansSetIsPaymentChangeVisible = (isVisible) => {
        setPlanDetailsTabState((prev) => ({
            ...prev,
            isVisiblePaymentChange: isVisible,
        }));
    };

    const onPlansSetIsPaymentSubmitted = (isSubmitted) => {
        setPlanDetailsTabState((prev) => ({
            ...prev,
            isPaymentSubmitted: isSubmitted,
        }));
    };

    const onPaymentSetIsPaymentChangeVisible = (isVisible) => {
        setPaymentTabState((prev) => ({
            ...prev,
            isVisiblePaymentChange: isVisible,
        }));
    };

    /* --- */

    const isDisabledPopupClose = () => {
        if (planDetailsTabState.isPaymentSubmitted) {
            return true;
        }

        return false;
    };

    const renderPopupTitle = () => {
        if (planDetailsTabState.isVisiblePaymentChange) {
            if (isUserHasCardDetails) {
                return "Payment Details";
            }

            return "Add a Payment Method";
        }

        if (planDetailsTabState.isPaymentSubmitted) {
            return "Payment Confirmation";
        }

        if (planDetailsTabState.isVisiblePaymentDetails) {
            return "Confirm and Pay";
        }

        if (isVisiblePlans) {
            return "Select a Plan";
        }

        /* ----- */

        if (paymentTabState.isVisiblePaymentChange) {
            if (isUserHasCardDetails) {
                return "Payment Details";
            }

            return "Add a Payment Method";
        }

        /* ----- */

        return "Your Account";
    };

    const onBackByArrow = () => {
        if (paymentTabState.isVisiblePaymentChange) {
            onPaymentSetIsPaymentChangeVisible(false);
            onLoadUserPaymentDetails();
        }

        /* ----- */

        if (planDetailsTabState.isVisiblePaymentChange) {
            onPlansSetIsPaymentChangeVisible(false);
            return;
        }

        if (planDetailsTabState.isVisiblePaymentDetails) {
            onPlansSetIsPaymentVisible(false);
            return;
        }

        /* ----- */

        setPlanDetailsTabState(getPlanDetailsTabState());
        setPaymentTabState(getPaymentTabState());

        setIsVisiblePlans(false);
    };

    /* --- */

    const renderSnackbars = () => {
        return snackbar.state.map((bar) => {
            return (
                <Snackbar
                    message={bar.message}
                />
            );
        });
    };

    /* --- */

    const renderUploadAvatarPopup = () => {
        if (!uploadAvatarPopup.state.isOpen) {
            return null;
        }

        const avatarUrl = store.user.avatarUrl || "";

        return (
            <PopupProfileAvatar
                avatarUrl={avatarUrl}
                error={uploadAvatarPopup.state.error}
                isSaving={uploadAvatarPopup.state.isSaving}
                onUploadProfileImage={onUploadProfileImage}
                onClose={uploadAvatarPopup.close}
            />
        );
    };

    const renderDeleteAvatarPopup = () => {
        if (!deleteAvatarPopup.state.isOpen) {
            return null;
        }

        return (
            <PopupConfirmMessage
                isLoading={deleteAvatarPopup.state.isLoading}
                message={deleteAvatarPopup.state.message}
                onClose={deleteAvatarPopup.close}
            />
        );
    };

    const renderFullnameEditPopup = () => {
        if (!fullnamePopup.state.isOpen) {
            return null;
        }

        return (
            <PopupFullnameChange
                defaultValue={store.user.fullName}
                isSaving={fullnamePopup.state.isSaving}
                error={fullnamePopup.state.error}
                onSave={onSaveFullname}
                onClose={onCloseEditFullnamePopup}
            />
        );
    };

    const renderCancelSubscriptionPopup = () => {
        if (!isVisibleCancelSubscriptionPopup) {
            return null;
        }

        return (
            <PopupSubscriptionCancel
                onClose={onCloseCancelSubscriptionPopup}
            />
        );
    };

    /* --- */

    const renderPlans = () => {
        if (!isVisiblePlans) {
            return null;
        }

        return (
            <Stripe>
                <UserPlans
                    plans={store.subscription.plans}
                    userPlanId={store.user.subscription.planId}
                    card={getCardDetails()}
                    onLoadUser={props.onLoadUser}
                    hasCardDetails={isUserHasCardDetails}
                    isVisiblePaymentDetails={planDetailsTabState.isVisiblePaymentDetails}
                    isVisibleQuoteStatus={planDetailsTabState.isVisibleQuoteStatus}
                    isVisiblePaymentChange={planDetailsTabState.isVisiblePaymentChange}
                    onSetIsVisiblePaymentDetails={onPlansSetIsPaymentVisible}
                    onSetIsVisibleQuoteStatus={onPlansSetIsQuoteStatusVisible}
                    onSetIsVisiblePaymentChange={onPlansSetIsPaymentChangeVisible}
                    onSetIsPaymentSubmitted={onPlansSetIsPaymentSubmitted}
                    onClose={onClosePlans}
                    onFullClose={props.onClose}
                />
            </Stripe>
        );
    };

    /* --- */

    const renderTabs = () => {
        if (!isTeacher || store.user.isLmsUser) {
            return null;
        }

        if (paymentTabState.isVisiblePaymentChange) {
            return <div />;
        }

        const tabs = [
            {
                label: "Personal Info",
                value: "personal-info",
            },
            {
                label: "Plan Details",
                value: "plan-details",
            },
        ];

        if (settings.features.PAYMENT_SUBSCRIPTION) {
            tabs.push({
                label: "Payment",
                value: "payment",
            });
        }

        return (
            <Tabs
                onlyTabs
                isBlueTheme
                tabs={tabs}
                selectedTab={selectedTab}
                onChange={onTabChange}
            />
        );
    };

    /* --- */

    const renderPersonalInfo = () => {
        return (
            <PersonalInfo
                disabledDeleteAvatar={store.user.avatarUrl === ""}
                avatarIconName={props.avatarIconName}
                avatarUrl={store.user.avatarUrl}
                fullName={store.user.fullName}
                userName={store.user.userName}
                email={store?.user?.email || "email is missing"}
                district={store.user.district}
                school={store.user.school}
                department={store.user.department}
                isLMS={store.user.isLmsUser}
                onOpenUploadAvatar={uploadAvatarPopup.open}
                emailNotification={{
                    enabled: store.user?.settings?.isEmailNotificationEnabled || false,
                    isLoading: isLoadingEmailNotificationStatus,
                    onChange: (isEnabled) => {
                        onChangeEmailNotification(isEnabled);
                    },
                }}
                onContactSupport={props.onContactSupport}
                onEditEmail={props.onEditEmail}
                onEditPassword={props.onEditPassword}
                onEditFullname={onEditFullname}
                onDeleteAvatar={onDeleteAvatar}
            />
        );
    };

    const renderPlanDetails = () => {
        const user = {
            planId: store.user.subscription.planId,
            daysLeftToExpire: store.user?.daysLeftToExpire || 0,
            expiration: store.user?.expiration || "",
            hasPlan: isUserHasPlan,
            isFree: User.isTypeFree(store.user),
        };

        const subscription = {
            isLoadingAutoRenewSubscription,
            isAutoRenewEnabled: store.user.subscription.isAutoRenewEnabled,
            expireDate: store.user.subscription.expiredDateFormatted,
            onUpgrade: onUpgradePlan,
            onCancel: onOpenCancelSubscriptionPopup,
            onChangeAutoRenew: onChangeAutoRenewPlan,
        };

        const plans = {
            isPlansLoaded: store.subscription.isPlansLoaded,
            plans: store.subscription.plans,
            freeTrialPlanFeatures: store.subscription.freeTrialPlanFeatures,
            isActivePlansLoaded: store.subscription.isActivePlansLoaded,
            activePlans: store.subscription.activePlans,
        };

        return (
            <PlanDetails
                user={user}
                plans={plans}
                subscription={subscription}
                onContactSupport={props.onContactSupport}
            />
        );
    };

    const renderPayment = () => {
        if (!store.isPaymentDetailsLoaded) {
            return (
                <RequestLoader />
            );
        }

        return (
            <Payment
                hasSubscription={isUserHasPlan}
                hasCardDetails={isUserHasCardDetails}
                card={getCardDetails()}
                expireDate={store.user.subscription.expiredDateFormatted}
                onContactSupport={props.onContactSupport}
                isVisiblePaymentChange={paymentTabState.isVisiblePaymentChange}
                onSetIsVisiblePaymentChange={onPaymentSetIsPaymentChangeVisible}
                onLoadUserPaymentDetails={onLoadUserPaymentDetails}
                onCancelSubscription={onOpenCancelSubscriptionPopup}
            />
        );
    };

    const renderTabContent = () => {
        if (selectedTab === "personal-info") {
            return renderPersonalInfo();
        }

        if (selectedTab === "plan-details") {
            return renderPlanDetails();
        }

        if (selectedTab === "payment") {
            return renderPayment();
        }

        return null;
    };

    const renderContent = () => {
        if (isVisiblePlans) {
            return (
                <div className={styles.popup}>
                    {renderPlans()}
                </div>
            );
        }

        return (
            <div className={styles.popup}>
                {renderTabs()}
                {renderTabContent()}
            </div>
        );
    };

    const renderPopupLeftControl = () => {
        if (planDetailsTabState.isPaymentSubmitted) {
            return null;
        }

        if (isVisiblePlans || paymentTabState.isVisiblePaymentChange) {
            return (
                <ButtonCircle
                    icon={<IconArrowBold />}
                    onClick={onBackByArrow}
                    isImageBackwards
                />
            );
        }

        return null;
    };

    return (
        <>
            <PopupWindow
                title={renderPopupTitle()}
                leftControl={renderPopupLeftControl()}
                hideClose={isDisabledPopupClose()}
                hideContentScroll
                isDisplayFlex
                onClose={props.onClose}
            >
                {renderContent()}
            </PopupWindow>

            {renderUploadAvatarPopup()}
            {renderDeleteAvatarPopup()}
            {renderFullnameEditPopup()}

            {renderCancelSubscriptionPopup()}

            {renderSnackbars()}
        </>
    );
};

UserPopupAccount.defaultProps = {
    avatarIconName: "",

    emailNotification: {},

    onLoadUser: () => { },

    onContactSupport: () => { },

    onEditEmail: () => { },
    onEditPassword: () => { },

    onClose: () => { },
};

export default UserPopupAccount;
