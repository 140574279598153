import React, { useEffect, useState } from "react";

import date from "juice-base/lib/date.js";
import array from "juice-base/lib/array.js";

import Grades from "juice-base/project/grades.js";
import DailyJuice from "juice-base/project/daily-juice.js";
import Standards from "juice-base/project/standards.js";
import Assignments from "juice-base/project/assignments.js";

import RequestLoader from "juice-base/components/request-loader/index.js";
import TabsScrolling from "juice-base/components/tabs-scrolling/index.js";
import DatepickerCustom from "juice-base/components/datepicker-custom/index.js";
import MenuWithContent, { MenuContent } from "juice-base/components/menu-with-content/index.js";

// TODO: rename v2 to v1
import PopupFullScreen from "juice-base/components/popup-full-screen-v2/index.js";

import TableDailyJuices from "juice-base/business/table-daily-juices/index.js";
import StudentAccountInfo from "juice-base/business/student-account-info/index.js";
import StudentOverview from "juice-base/business/student-overview/index.js";
import StudentQuizPerformance from "juice-base/business/student-quiz-performance/index.js";
import AssignmentsTable from "juice-base/business/assignments-table/index.js";
import TeacherNotes from "juice-base/business/teacher-notes/index.js";

import styles from "./styles.module.css";


const MENU = [
    { index: 0, value: "student-overview", name: "Student Overview" },
    { index: 1, value: "daily-juice", name: "Daily Juice" },
    { index: 2, value: "quiz-performance", name: "Quiz Performance" },
    { index: 3, value: "assignments", name: "Assignments" },
    { index: 4, value: "account-information", name: "Account Information" },
];

const MENU_ACCOUNT_INFO = MENU[4].value;

const getMenuNameByIndex = (index) => {
    const menuItem = array.findByFieldName(MENU, "index", index);

    if (!menuItem?.value) {
        return MENU[0].value;
    }

    return menuItem.value;
};

const getDefaultSelectedMenu = (nameOrIndex) => {
    if (!Number.isNaN(parseInt(nameOrIndex, 10))) {
        return parseInt(nameOrIndex, 10);
    }

    const menuItem = array.findByFieldName(MENU, "value", nameOrIndex);

    if (!menuItem?.index) {
        return 0;
    }

    return menuItem.index;
};

const getDatepickerState = () => ({
    isVisible: false,
    isRangeTabByDefault: false,

    date: null,

    dateFrom: null,
    dateTo: null,
});

const PopupStudentInfo = (props) => {
    const [selectedStudentId, setSelectedStudentId] = useState(() => {
        return props.student?.id || null;
    });

    const [isMenuVisible, setIsMenuVisible] = useState(true);
    const [selectedMenuIndex, setSelectedMenuIndex] = useState(() => {
        return getDefaultSelectedMenu(props.defaultSelectedMenu);
    });

    const [notesState, setNotesState] = useState({
        isContentChanged: false,
    });

    const [datepickerState, setDatepickerState] = useState(() => getDatepickerState());

    const [lastSelectedStandardId, setLastSelectedStandardId] = useState(null);

    /* --- */

    // TODO: enable later and fix
    // eslint-disable-next-line no-unused-vars
    const isNotesPageAndNotesChanged = () => {
        if (selectedMenuIndex === 4 && notesState.isContentChanged) {
            return true;
        }

        return false;
    };

    /* --- */

    const onOpenDatepicker = () => {
        setDatepickerState((prev) => ({
            ...prev,
            isVisible: true,
        }));
    };

    const onCloseDatepickerWithClear = () => {
        setDatepickerState(getDatepickerState());
    };

    const onCloseDatepicker = () => {
        if (!datepickerState.isVisible) {
            return;
        }

        setDatepickerState((prev) => ({
            ...prev,
            isVisible: false,
        }));
    };

    const onSelectStudent = (id) => {
        const newId = Number(id);

        props.onSelectStudent(newId);
        setSelectedStudentId(newId);
    };

    const onMenuClick = (pageIndex) => {
        setSelectedMenuIndex(pageIndex);

        const menuValue = getMenuNameByIndex(pageIndex);

        props.onMenuChange(menuValue);

        setIsMenuVisible(false);
    };

    const onDatepickerSaveDate = (params) => {
        const { selectedDate, standardId } = params;

        const d = date.getDateFromDate(selectedDate);

        props.quizPerformance.onDateRangeChange({
            dateFrom: d,
            dateTo: d,
            range: `${d}-${d}`,
            standardId,
        });

        setDatepickerState((prev) => ({
            ...prev,
            isVisible: false,
            isRangeTabByDefault: false,
            date: selectedDate,
            dateFrom: null,
            dateTo: null,
        }));
    };

    const onDatepickerSaveRange = (params) => {
        const { startDate, endDate, standardId } = params;

        const d1 = date.getDateFromDate(startDate);
        const d2 = date.getDateFromDate(endDate);

        props.quizPerformance.onDateRangeChange({
            dateFrom: d1,
            dateTo: d2,
            range: `${d1}-${d2}`,
            standardId,
        });

        setDatepickerState((prev) => ({
            ...prev,
            isVisible: false,
            isRangeTabByDefault: true,
            date: null,
            dateFrom: startDate,
            dateTo: endDate,
        }));
    };

    const onDateRangeChange = (params) => {
        if (params.standardId) {
            setLastSelectedStandardId(params.standardId);
        }

        if (params.openDatepicker) {
            onOpenDatepicker();
            return;
        }

        if (params.range === "custom") {
            if (datepickerState.isRangeTabByDefault) {
                onDatepickerSaveRange({
                    startDate: datepickerState.dateFrom,
                    endDate: datepickerState.dateTo,
                    standardId: params.standardId || "",
                });
            } else {
                onDatepickerSaveDate({
                    selectedDate: datepickerState.date,
                    standardId: params.standardId || "",
                });
            }
            return;
        }

        onCloseDatepickerWithClear();
        props.quizPerformance.onDateRangeChange(params);
    };

    const onExportStudentPerformance = (params) => {
        const reqParams = {
            ...params,
            studentId: selectedStudentId,
        };

        if (params.range === "custom") {
            if (!datepickerState.date && !datepickerState.dateFrom && !datepickerState.dateTo) {
                return;
            }

            if (datepickerState.date) {
                reqParams.dateFrom = date.getDateFromDate(datepickerState.date);
                reqParams.dateTo = date.getDateFromDate(datepickerState.date);
            } else {
                reqParams.dateFrom = date.getDateFromDate(datepickerState.dateFrom);
                reqParams.dateTo = date.getDateFromDate(datepickerState.dateTo);
            }
        }

        props.onExportStudentPerformance(reqParams);
    };

    /* --- */

    useEffect(() => {
        onCloseDatepicker();
    }, [props.isMobile]);

    /* --- */

    const renderStudentSelector = () => {
        if (props.studentsList.length === 0) {
            return null;
        }

        const tabs = props.studentsList.map((student) => ({
            value: student.id,
            label: student.fullName,
        }));

        return (
            <TabsScrolling
                selected={selectedStudentId}
                tabs={tabs}
                hideArrows={props.hideArrows}
                onChange={onSelectStudent}
            />
        );
    };

    const renderStudentOverview = () => {
        if (!props.student.isLoaded) {
            return (
                <div className={styles.loader}>
                    <RequestLoader />
                </div>
            );
        }

        const overview = props.student?.data?.overview || {};
        const gradeField = Grades.getGradeGroup(overview.studentGrade);

        return (
            <StudentOverview
                grade={gradeField}
                quizAverage={overview.cumulativeQuizAverage}
                onEditGrade={props.student.onEditGrade}
            />
        );
    };

    const renderStudentAccountInfo = () => {
        if (!props.student.isLoaded) {
            return (
                <div className={styles.loader}>
                    <RequestLoader />
                </div>
            );
        }

        return (
            <StudentAccountInfo
                account={props.student.data.account}
                emailNotification={props.emailNotification}
                isLMS={props.isLMS}
                onDeleteAccount={props.student.onDeleteAccount}
                onEditName={props.student.onEditName}
                onEditEmail={props.student.onEditEmail}
                onEditPassword={props.student.onEditPassword}
            />
        );
    };

    const renderDailyJuices = () => {
        const data = (props?.dailyJuice?.data?.dailyJuices || []).map((dj) => ({
            date: date.tryFormatDateUTC(dj.juiceDate, date.formatDayDate),
            quizStatus: dj.statusId,
            quizScore: `${dj.quizScore}%`,
            extraJuices: (dj?.extraJuices || []).map((ej) => ej.statusId),
            videos: (dj.videos || []).map((video) => video.statusId),
        }));

        return (
            <TableDailyJuices
                data={data}
                errorBefore={props?.dailyJuice?.data?.errorBefore}
                errorAfter={props?.dailyJuice?.data?.errorAfter}
                onLoadMoreBefore={props?.dailyJuice?.onLoadMoreBefore}
                onLoadMoreAfter={props?.dailyJuice?.onLoadMoreAfter}
                onExport={props?.dailyJuice?.onExport}
                isFirstLoaded={props?.dailyJuice?.data?.isFirstLoaded}
                isLastLoaded={props?.dailyJuice?.data?.isLastLoaded}
                isLoadingBefore={props.dailyJuice?.data?.isLoadingBefore}
                isLoadingAfter={props?.dailyJuice?.data?.isLoadingAfter}
                isMobile={props.isCards}
            />
        );
    };

    const renderAssignments = () => {
        const data = props.assignments.data.assignments.map((assignment) => {
            const assignmentStatus = assignment?.status;

            const firstStory = assignment?.stories?.[0] || {};
            const quiz = DailyJuice.getStoryQuiz(firstStory);

            const stateStandard = Standards.getStandardById(
                props.assignments.data.stateStandards,
                quiz?.standardId,
            );

            const standards = Array.isArray(quiz?.standards)
                ? [...quiz.standards]
                : [];

            if (stateStandard) {
                standards.push(stateStandard);
            }

            let score = -1;

            if (!Assignments.isPendingAssignmentStatus(assignmentStatus)) {
                score = assignment?.quizScore || 0;
            }

            return {
                storyId: firstStory.ID,
                quiz,
                date: assignment.date,
                score,
                storyName: firstStory.title,
                storyQuestion: quiz?.question,
                grades: assignment.grades,
                standards,
            };
        });

        return (
            <AssignmentsTable
                data={data}
                onSortByStandard={props.assignments.onSortByStandard}
                onSortByGrade={props.assignments.onSortByGrade}
                onSortByScore={props.assignments.onSortByScore}
                onSortByDate={props.assignments.onSortByDate}
                onLoadMore={props.assignments.onLoadMore}
                onExport={props.assignments.onExport}
                onOpenStory={props.assignments.onOpenStory}
                onOpenQuiz={props.assignments.onOpenQuiz}
                onOpenStandards={props.assignments.onOpenStandards}
                alignButtonFABByVW={props.isCards}
                isVisibleLoadMoreButton={props.assignments.data.hasMore || false}
                isLoading={props.assignments.data.isLoading}
                isMobile={props.assignments.isMobile}
            />
        );
    };

    // TODO: enable TextEditor themes
    // eslint-disable-next-line no-unused-vars
    const renderNotes = () => {
        return (
            <TeacherNotes
                onChange={({ isChanged }) => {
                    setNotesState({
                        isContentChanged: isChanged,
                    });
                }}
                onSave={() => {
                    // TODO:
                }}
            />
        );
    };

    const renderQuizPerformance = () => {
        if (!props.dailyJuiceDates.isLoaded) {
            return (
                <div className={styles.loader}>
                    <RequestLoader />
                </div>
            );
        }

        return (
            <StudentQuizPerformance
                defaultDate={props.quizPerformance.defaultDate}
                customRange={{
                    dateFrom: datepickerState.dateFrom,
                    dateTo: datepickerState.dateTo,
                }}
                customDate={datepickerState.date}
                dailyJuiceDates={props.dailyJuiceDates}
                performance={props.quizPerformance.data}
                onDateChange={props.quizPerformance.onDateChange}
                onDateRangeChange={onDateRangeChange}
                onStandardTypeClick={props.quizPerformance.onStandardTypeClick}
                onOpenStory={props.quizPerformance.onOpenStory}
                onClear={props.quizPerformance.onClear}
                onExportStudentPerformance={onExportStudentPerformance}
                isMobile={props.isMobile}
                showStateStandard={props.showStateStandard}
                isDatepickerVisible={datepickerState.isVisible}
            />
        );
    };

    const renderMenuContent = (value) => {
        if (value === "student-overview") {
            return renderStudentOverview();
        }

        if (value === "daily-juice") {
            return renderDailyJuices();
        }

        if (value === "quiz-performance") {
            return renderQuizPerformance();
        }

        if (value === "assignments") {
            return renderAssignments();
        }

        if (value === "account-information") {
            return renderStudentAccountInfo();
        }

        return null;
    };

    const renderContent = () => {
        const menu = MENU.map((menuOpt) => {
            let tooltip = "";

            if (menuOpt.value === MENU_ACCOUNT_INFO
                && props.emailNotification) {
                tooltip = props.emailNotification;
            }

            return (
                <MenuContent
                    name={menuOpt.name}
                    tooltip={tooltip}
                >
                    {renderMenuContent(menuOpt.value)}
                </MenuContent>
            );
        });

        return (
            <MenuWithContent
                isMobile={props.isMobile}
                isMobileMenuVisible={isMenuVisible}
                defaultSelected={getDefaultSelectedMenu(props.defaultSelectedMenu)}
                // TODO: showConfirmOnMenuClick={isNotesPageAndNotesChanged()}
                onMenuClick={onMenuClick}
            >
                {menu}
            </MenuWithContent>
        );
    };

    const renderDatepicker = () => {
        if (!datepickerState.isVisible) {
            return null;
        }

        const minDate = props.siteDate ? date.addDays(props.siteDate, -180) : null;
        const maxDate = props.siteDate ? date.newDate(props.siteDate) : null;

        const defaultRange = {
            start: datepickerState.dateFrom,
            end: datepickerState.dateTo,
        };

        return (
            <DatepickerCustom
                isWeekendsDisabled
                isRangeTabByDefault={datepickerState.isRangeTabByDefault}
                defaultRange={defaultRange}
                defaultDate={datepickerState.date}
                minDate={minDate}
                maxDate={maxDate}
                datepickerClassName={styles.datepicker}
                onSaveDate={(selectedDate) => {
                    onDatepickerSaveDate({
                        selectedDate,
                        standardId: lastSelectedStandardId,
                    });
                }}
                onSaveRange={({ startDate, endDate }) => {
                    onDatepickerSaveRange({
                        startDate,
                        endDate,
                        standardId: lastSelectedStandardId,
                    });
                }}
                onClose={onCloseDatepicker}
            />
        );
    };

    return (
        <>
            {renderDatepicker()}

            <PopupFullScreen
                title="Student View"
                subheader={renderStudentSelector()}
                isMobile={props.isMobile}
                isArrowBackVisible={!isMenuVisible}
                // TODO: showConfirmOnClose={isNotesPageAndNotesChanged()}
                onArrowBack={setIsMenuVisible}
                onClose={props.onClose}
            >
                {renderContent()}
            </PopupFullScreen>
        </>
    );
};

PopupStudentInfo.defaultProps = {
    studentsList: [],

    siteDate: null,

    student: {
        isLoaded: false,
        id: null,
        data: {},

        onEditName: () => { },
        onEditEmail: () => { },
        onEditGrade: () => { },
        onEditPassword: () => { },

        onDeleteAccount: () => { },
    },

    emailNotification: "",

    dailyJuices: {},
    dailyJuiceDates: {},

    isCards: false,
    isMobile: false,
    isLMS: false,

    hideArrows: false,

    showStateStandard: false,

    defaultSelectedMenu: 0,

    dailyJuice: {},

    quizPerformance: {
        data: {},
        onDateChange: () => { },
        onDateRangeChange: () => { },
        onOpenStory: () => { },
        onClear: () => { },
    },

    assignments: {},

    onMenuChange: () => { },

    onExportStudentPerformance: () => { },

    onSelectStudent: () => { },
    onClose: () => { },
};

export default PopupStudentInfo;
