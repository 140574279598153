import React from "react";

import classNames from "juice-base/lib/class-names.js";

import styles from "./styles.module.css";

// TODO: rename, it is not a slider
const SliderRange = (props) => {
    const handleClassName = classNames({
        [styles.handle]: true,
        [styles.handleWithBorder]: props.withBorder,
    });

    const handle = (
        <div className={handleClassName} />
    );

    let minHandle = handle;
    let maxHandle = handle;

    if (props.noMinHandle) {
        minHandle = null;
    }

    if (props.noHandlers) {
        minHandle = null;
        maxHandle = null;
    }

    const rangeSectionClassName = classNames({
        [styles.rangeSection]: true,
        [styles.rangeSectionNoMinHandle]: props.noMinHandle,
        [styles.rangeSectionNoHandlers]: props.noHandlers,
    });

    const rangeSectionStyle = {
        width: `${props.max - props.min}%`,
        marginLeft: `${props.min}%`,
    };

    const rangeClassNames = classNames({
        [styles.range]: true,
        [styles.rangeNoHandlers]: props.noHandlers && !props.withBorder,
        [styles.rangeNoHandlersWithBorder]: props.noHandlers && props.withBorder,
    });

    const rangeLineClassName = classNames({
        [styles.rangeLine]: true,
        [styles.rangeLineWithBorder]: props.withBorder,
    });

    const range = (
        <div className={rangeClassNames}>
            <div
                className={rangeSectionClassName}
                style={rangeSectionStyle}
            >
                {minHandle}
                <div className={rangeLineClassName} />
                {maxHandle}
            </div>
        </div>
    );

    const lineClassName = classNames({
        [styles.line]: true,
        [styles.lineWithBorder]: props.withBorder,
        [styles.lineWithBorderAndZero]: props.withBorder && props.min === 0,
        [styles.lineWithBorderAndMax]: props.withBorder && (props.max - props.min) === 100,
    });

    return (
        <div className={lineClassName}>
            {range}
        </div>
    );
};

SliderRange.defaultProps = {
    min: 0,
    max: 100,

    noMinHandle: false,
    noHandlers: false,

    withBorder: true,
};

export default SliderRange;
