import React from "react";

import urls from "juice-base/lib/urls.js";

import RequestLoader from "juice-base/components/request-loader/index.js";
import ButtonFlat from "juice-base/components/button-flat/index.js";
import PopupConfirm, {
    PopupConfirmContent,
    PopupConfirmButtons,
} from "juice-base/components/popup-confirm/index.js";
import Text from "juice-base/components/text/index.js";

import styles from "./styles.module.css";


const PopupConfirmGenerateClassCode = (props) => {
    const onSubmit = () => {
        let copyValue = "";

        if (props.isLink) {
            copyValue = `${props.signUpUrl}/${props.code}`;
        } else {
            copyValue = props.code;
        }

        props.onSubmit(copyValue);
    };

    const renderContent = () => {
        if (props.isLoading) {
            return (
                <RequestLoader />
            );
        }

        if (props.error) {
            return props.error;
        }

        const signUpUrl = urls.stripProtocol(props.signUpUrl);

        let text1 = "";
        let text2 = null;
        let textDescription = null;

        if (props.isLink) {
            text1 = `Your Class Link for your class <br /><b>${props.className}</b> is:`;
            text2 = (
                <div className={styles.classCodeLink}>
                    {`${signUpUrl}/${props.code}`}
                </div>
            );
            textDescription = (
                <div className={styles.classCodeMessage}>
                    Share a class link with your students
                    so that they can join your class on The Juice.
                </div>
            );
        } else {
            text1 = `
                Your Class Code is: <br /><b>${props.code}<b/>
            `;
            text2 = (
                <Text className={styles.classCodeMessage}>
                    {`This is to join your class <br />${props.className}`}
                </Text>
            );
            textDescription = (
                <Text className={styles.classCodeMessage}>
                    {`
                        Tell your students to visit
                        <a href=${props.signUpUrl} target="_blank" rel="noreferrer">${signUpUrl}</a>
                        and enter the class code to join to your class!
                    `}
                </Text>
            );
        }

        return (
            <div className={styles.classCodeContent}>
                <Text className={styles.classCodeText}>
                    {text1}
                </Text>
                {text2}
                {textDescription}
            </div>
        );
    };

    const copyButtonText = props.isLink
        ? "Copy Class Link to Clipboard"
        : "Copy Class Code to Clipboard";

    return (
        <PopupConfirm
            onClose={props.onClose}
            hideScrollbar={props.hideScrollbar}
            closeOnLayoutClick
        >
            <PopupConfirmContent>
                {renderContent()}
            </PopupConfirmContent>

            <PopupConfirmButtons>
                <ButtonFlat
                    disabled={props.isDisabled}
                    onClick={onSubmit}
                >
                    {copyButtonText}
                </ButtonFlat>
            </PopupConfirmButtons>
        </PopupConfirm>
    );
};

PopupConfirmGenerateClassCode.defaultProps = {
    code: "",
    signUpUrl: "",
    className: "",
    error: "",
    onSubmit: () => { },
    onClose: () => { },
    hideScrollbar: false,
    isDisabled: false,
    isLoading: false,
    isLink: false,
};

export default PopupConfirmGenerateClassCode;
