// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._7I30L1zd3UD0fpQnU0KF {\n    display: grid;\n    justify-content: center;\n}\n\n\n.IXXgQcg9u8WQxeEzKrye {\n    padding: 1.5rem 2rem;\n\n    border-top: solid 0.1rem var(--grey-light);\n}\n", "",{"version":3,"sources":["webpack://./base/business/daily-juice-quiz-results/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;AAC3B;;;AAGA;IACI,oBAAoB;;IAEpB,0CAA0C;AAC9C","sourcesContent":[".results {\n    display: grid;\n    justify-content: center;\n}\n\n\n.viewMoreButton {\n    padding: 1.5rem 2rem;\n\n    border-top: solid 0.1rem var(--grey-light);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"results": "_7I30L1zd3UD0fpQnU0KF",
	"viewMoreButton": "IXXgQcg9u8WQxeEzKrye"
};
export default ___CSS_LOADER_EXPORT___;
