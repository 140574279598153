import React from "react";

import ButtonFlat from "juice-base/components/button-flat/index.js";

import styles from "./styles.module.css";


const TeacherShareClassCode = (props) => {
    const menu = [
        {
            label: "Direct Link (specific to this class only)",
            onClick: props.onDirectLinkClick,
        },
        // TODO: remove or enable
        // {
        //     label: "Alternatively, a student can sign up with a code directly from the signup screen.",
        //     onClick: props.onGenerateClassCode,
        // },
    ];

    const blocks = menu.map((block) => {
        return (
            <div className={styles.shareClassCodePageBlock}>
                <div className={styles.shareClassCodePageBlockLabel}>
                    {block.label}
                </div>
                <ButtonFlat
                    hugePadding
                    onClick={block.onClick}
                >
                    Generate
                </ButtonFlat>
            </div>
        );
    });

    return (
        <div className={styles.shareClassCodePage}>
            <div className={styles.shareClassCodePageTitle}>
                Share the code or link with your students.
            </div>
            <div className={styles.shareClassCodePageBlocks}>
                {blocks}
            </div>
        </div>
    );
};

TeacherShareClassCode.defaultProps = {
    onDirectLinkClick: () => {},
    onGenerateClassCode: () => {},
};

export default TeacherShareClassCode;
