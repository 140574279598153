import * as types from "./types.js";


export const setPlans = (payload) => ({
    type: types.SET_PLANS,
    payload,
});

export const setActivePlans = (payload) => ({
    type: types.SET_ACTIVE_PLANS,
    payload,
});

export default {};
