import React from "react";

import classNames from "juice-base/lib/class-names.js";

import styles from "./styles.module.css";


const Close = (props) => {
    const closeClassName = classNames({
        [styles.close]: true,
        [props.className]: props.className,
        [styles.closeWhite]: props.isWhite,
    });

    /* eslint-disable */
    const close = (
        <div
            className={closeClassName}
            onClick={props.onClose}
            onKeyPress={props.onClose}
            role="button"
            tabIndex="-1"
        />
    );
    /* eslint-enable */

    return close;
};

Close.defaultProps = {
    className: "",
    isWhite: false,
    onClose: () => {},
};

export default Close;
