import React, { useState } from "react";

import classNames from "juice-base/lib/class-names.js";
import Error from "juice-base/components/forms/error/index.js";

import fieldCommonStyles from "juice-base/components/forms/field-common/styles.module.css";
import styles from "./styles.module.css";


const Textarea = (props) => {
    const [isFocused, setIsFocused] = useState(false);

    const onFocus = () => {
        setIsFocused(true);
    };

    const onBlur = (evt) => {
        setIsFocused(false);
        props.onBlur(evt);
    };

    const renderMaxLengthMessage = () => {
        if (props.showMaxLengthMessage) {
            const msgClassName = classNames({
                [styles.maxLengthMessage]: true,
                [styles.maxLengthMessageMax]: props.value.length === props.maxLength,
            });

            return (
                <div className={msgClassName}>
                    <div>{`Character Limit: ${props.maxLength}`}</div>
                    <div>{`${props.value.length}/${props.maxLength}`}</div>
                </div>
            );
        }

        return null;
    };

    let inputError = null;

    if (props.error) {
        inputError = (
            <Error>
                {props.error}
            </Error>
        );
    }

    const containerClassName = classNames({
        [fieldCommonStyles.container]: true,
        [fieldCommonStyles.containerFocused]: isFocused,
        [fieldCommonStyles.containerError]: props.error,
    });

    const textareaClassName = classNames({
        [fieldCommonStyles.input]: true,
        [styles.textarea]: true,
        [fieldCommonStyles.inputFocused]: isFocused || props.value,
        [fieldCommonStyles.inputError]: props.error,
    });

    let inputTabIndex = "0";

    if (props.isDisabledTabIndex) {
        inputTabIndex = "-1";
    }

    return (
        <div className={fieldCommonStyles.field}>
            <div className={containerClassName}>
                <textarea
                    name={props.name}
                    value={props.value}
                    placeholder={props.placeholder}
                    maxLength={props.maxLength}
                    className={textareaClassName}
                    tabIndex={inputTabIndex}
                    onKeyPress={props.onKeyPress}
                    onChange={props.onChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                />
            </div>

            {renderMaxLengthMessage()}

            {inputError}
        </div>
    );
};

Textarea.defaultProps = {
    name: "",
    placeholder: "",
    value: "",
    error: "",
    maxLength: 255,
    showMaxLengthMessage: false,
    isDisabledTabIndex: false,
    onKeyPress: () => {},
    onChange: () => {},
    onBlur: () => {},
};

export default Textarea;
