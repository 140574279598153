import vocabulary from "juice-base/api-common/vocabulary.js";
import t2s from "juice-base/api-common/t2s.js";
import videos from "juice-base/api-common/videos.js";
import events from "juice-base/api-common/events.js";
import pages from "juice-base/api-common/pages.js";
import search from "juice-base/api-common/search.js";
import juiceStories from "juice-base/api-common/juice-stories.js";

import site from "./site.js";

import user from "./user.js";
import signup from "./signup.js";

import jobs from "./jobs.js";
import dailyJuices from "./daily-juices.js";
import extraJuices from "./extra-juices.js";
import quizzes from "./quizzes.js";
import polls from "./polls.js";

import classes from "./classes.js";
import students from "./students.js";
import teachers from "./teachers.js";
import teacherClasses from "./teacher-classes.js";

import tutorials from "./tutorials.js";
import templates from "./templates.js";
import popups from "./popups.js";

import geo from "./geo.js";

import subscription from "./subscription.js";

import standards from "./standards.js";

import exp from "./export.js";

import lms from "./lms.js";

import assignments from "./assignments.js";

import error from "./error.js";


const createAPI = (url, methods) => {
    return {
        site: site(url, methods),

        user: user(url, methods),
        signup: signup(url, methods),

        jobs: jobs(url, methods),
        dailyJuices: dailyJuices(url, methods),
        extraJuices: extraJuices(url, methods),
        juiceStories: juiceStories(url, methods),
        quizzes: quizzes(url, methods),
        polls: polls(url, methods),
        vocabulary: vocabulary(url, methods),
        videos: videos(url, methods),

        search: search(url, methods),

        classes: classes(url, methods),
        students: students(url, methods),
        teachers: teachers(url, methods),
        teacherClasses: teacherClasses(url, methods),

        templates: templates(url, methods),
        tutorials: tutorials(url, methods),
        pages: pages(url, methods),
        popups: popups(url, methods),

        t2s: t2s(url, methods),

        geo: geo(url, methods),

        subscription: subscription(url, methods),

        standards: standards(url, methods),

        export: exp(url, methods),
        lms: lms(url, methods),

        assignments: assignments(url, methods),

        events: events(url, methods),

        error: error(url, methods),
    };
};

export default createAPI;
