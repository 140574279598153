// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AwGGII7cJSkzWFNpfCjU {\n    padding: 1rem 0 2rem 0;\n\n    font-size: 2rem;\n    font-weight: 600;\n}\n\n.RNNpH_371dagp4iF4yQa {\n    display: grid;\n    grid-row-gap: 2rem;\n}\n\n.oT9v9jxleaanxxqKse8T {\n    display: grid;\n    justify-content: center;\n}\n", "",{"version":3,"sources":["webpack://./base/business/popup-user-reset-password/styles.module.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;;IAEtB,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,uBAAuB;AAC3B","sourcesContent":[".formHeader {\n    padding: 1rem 0 2rem 0;\n\n    font-size: 2rem;\n    font-weight: 600;\n}\n\n.errorBlock {\n    display: grid;\n    grid-row-gap: 2rem;\n}\n\n.errorLinks {\n    display: grid;\n    justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formHeader": "AwGGII7cJSkzWFNpfCjU",
	"errorBlock": "RNNpH_371dagp4iF4yQa",
	"errorLinks": "oT9v9jxleaanxxqKse8T"
};
export default ___CSS_LOADER_EXPORT___;
