import React, { useState } from "react";

import Standards from "juice-base/project/standards.js";

import text from "juice-base/text/index.js";

import IconFilter from "juice-base/icons/filter/index.js";

import PopupWindow from "juice-base/components/popup-window/index.js";
import SelectCustom from "juice-base/components/select-custom/index.js";
import Message from "juice-base/components/message/index.js";
import ButtonBig from "juice-base/components/button-big/index.js";

import Checkbox from "juice-base/components/forms/checkbox/index.js";

import styles from "./styles.module.css";


const PopupFilterStandards = (props) => {
    const standardsTypes = props.withStateStandard
        ? Standards.getAllStandardsTypes()
        : Standards.getStandardsTypes();

    /* ----- */

    const [selectedStandardType, setSelectedStandardType] = useState(() => {
        if (props.defaultType) {
            return props.defaultType;
        }

        return standardsTypes[0].value;
    });

    const [selectedStandards, setSelectedStandards] = useState(() => {
        return props.defaultSelectedStandards;
    });

    /* ----- */

    const onStandardTypeChange = (value) => {
        setSelectedStandardType(value);
        setSelectedStandards([]);
    };

    const onSelectStandard = (standard) => {
        setSelectedStandards((prev) => {
            const ids = prev.map((s) => s.id);

            if (ids.indexOf(standard.id) === -1) {
                return prev.concat(standard);
            }

            return prev.filter((s) => s.id !== standard.id);
        });
    };

    const onApply = () => {
        props.onClose();
        props.onApply(selectedStandards, selectedStandardType);
    };

    /* ----- */

    const renderSelect = () => {
        return (
            <div className={styles.typeSelect}>
                <SelectCustom
                    icon={<IconFilter isBlack />}
                    selected={selectedStandardType}
                    options={standardsTypes}
                    onSelect={onStandardTypeChange}
                />
            </div>
        );
    };

    const renderStandards = () => {
        const standards = props.standards.map((s) => {
            const standardsByType = Standards.getStandardsByType(s.standards, selectedStandardType);

            let rows = standardsByType.map((ss) => {
                return (
                    <div className={styles.standard}>
                        <Checkbox
                            name={`standard-${ss.id}`}
                            checked={selectedStandards.map((sss) => sss.id).indexOf(ss.id) !== -1}
                            theme="rose"
                            label={ss.standard}
                            onChange={() => {
                                onSelectStandard({
                                    id: ss.id,
                                    subStandard: ss.subStandard,
                                });
                            }}
                        />
                    </div>
                );
            });

            if (rows.length === 0) {
                rows = (
                    <Message>
                        {text.noStandards}
                    </Message>
                );
            }

            return (
                <div className={styles.standardGroup}>
                    <div className={styles.standardGroupName}>
                        {s.anchorStandardGroup}
                    </div>
                    {rows}
                </div>
            );
        });

        if (standards.length === 0) {
            return (
                <Message>
                    {text.noStandards}
                </Message>
            );
        }

        return standards;
    };

    const renderControls = () => {
        return (
            <div className={styles.controls}>
                <ButtonBig
                    isRoseTheme
                    onClick={onApply}
                >
                    Apply
                </ButtonBig>
            </div>
        );
    };

    const renderContent = () => {
        return (
            <div className={styles.content}>
                {renderSelect()}
                {renderStandards()}
                {renderControls()}
            </div>
        );
    };

    /* ----- */

    return (
        <PopupWindow
            title="Standards"
            barClassName={styles.popupTitle}
            onClose={props.onClose}
            titleToStart
            isCentered
            withTitleBarShadow={false}
        >
            {renderContent()}
        </PopupWindow>
    );
};

PopupFilterStandards.defaultProps = {
    withStateStandard: false,

    defaultType: null,
    defaultSelectedStandards: [],
    standards: [],

    onApply: () => { },
    onClose: () => { },
};

export default PopupFilterStandards;
