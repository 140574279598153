import React from "react";

import className from "juice-base/lib/class-names.js";

import LoaderSmall from "juice-base/components/loader-small/index.js";

import styles from "./styles.module.css";


const ButtonDefault = (props) => {
    const classes = className({
        [styles.button]: true,
        [styles.buttonDisabled]: props.disabled,
        [styles.buttonPrimary]: props.isPrimary,
        [styles.buttonDelete]: props.isDelete,
        [styles.buttonUppercase]: props.uppercase,
    });

    return (
        <button
            className={classes}
            onClick={props.onClick}
            onKeyPress={props.onClick}
            type="button"
            tabIndex="-1"
            disabled={props.disabled}
            data-comment={props.dataComment}
        >
            {props.children}
            {props.withLoader ? <LoaderSmall /> : null}
        </button>
    );
};

ButtonDefault.defaultProps = {
    dataComment: "button-default",

    children: null,

    onClick: () => { },

    isPrimary: false,
    isDelete: false,
    disabled: false,
    uppercase: false,
    withLoader: false,
};

export default ButtonDefault;
