const DEFAULT_ALPHA_2 = "US";

const getDefaultAlpha2 = () => {
    return DEFAULT_ALPHA_2;
};

const getDefaultCountry = (countries) => {
    const countriesNames = (countries || []).map((c) => c.value);

    if (countriesNames.indexOf(DEFAULT_ALPHA_2) === -1) {
        return "";
    }

    return DEFAULT_ALPHA_2;
};


export default {
    DEFAULT_ALPHA_2,
    getDefaultCountry,
    getDefaultAlpha2,
};
