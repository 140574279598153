import React from "react";

import styles from "./styles.module.css";


const SwiperDailyJuiceStoryPage = (props) => {
    const storyElem = React.cloneElement(props.story, {
        isVisible: props.isVisible,
    });

    let quizElem = null;
    let nextStoryLink = null;

    if (props.quiz) {
        const quiz = React.cloneElement(props.quiz, {
            isVisible: props.isVisible,
        });

        quizElem = (
            <div className={styles.quiz}>
                {quiz}
                {props.link}
            </div>
        );
    } else {
        nextStoryLink = props.link || null;
    }

    return (
        <div className={styles.page}>
            <div className={styles.story}>
                {storyElem}
                {nextStoryLink}
            </div>
            {quizElem}
        </div>
    );
};

SwiperDailyJuiceStoryPage.defaultProps = {
    story: null,
    quiz: null,
    link: null,
};

export default SwiperDailyJuiceStoryPage;
