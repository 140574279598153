// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pUb4N9N2ECImFki9CmfF {\n    width: 25rem;\n\n    padding: 1rem;\n\n    background-color: var(--color-6);\n    color: var(--color-7);\n\n    border: 0.1rem solid var(--border-default-color);\n    border-radius: var(--border-default-radius);\n}\n\n@media only screen and (min-width: 600px) {\n    .pUb4N9N2ECImFki9CmfF {\n        width: 46rem;\n    }\n}\n", "",{"version":3,"sources":["webpack://./base/components/tooltip/styles.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;;IAEZ,aAAa;;IAEb,gCAAgC;IAChC,qBAAqB;;IAErB,gDAAgD;IAChD,2CAA2C;AAC/C;;AAEA;IACI;QACI,YAAY;IAChB;AACJ","sourcesContent":[".tooltip {\n    width: 25rem;\n\n    padding: 1rem;\n\n    background-color: var(--color-6);\n    color: var(--color-7);\n\n    border: 0.1rem solid var(--border-default-color);\n    border-radius: var(--border-default-radius);\n}\n\n@media only screen and (min-width: 600px) {\n    .tooltip {\n        width: 46rem;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip": "pUb4N9N2ECImFki9CmfF"
};
export default ___CSS_LOADER_EXPORT___;
