import text from "juice-base/text/index.js";


const loadDailyJuicesByPage = (services, params) => {
    const { api, actions } = services;

    return async (dispatch) => {
        dispatch(actions.juices.setJuicesLoading());

        const res = await api.dailyJuices.getJuicesByPage({
            session: params.session,
            grades: params.grades || "",
            page: params.page,
        });

        let juices = [];
        let error = null;

        if (res.ok) {
            juices = res.juices;
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.juices.setJuices({
            hasMorePages: res?.hasMore || false,
            juices,
            error,
        }));
    };
};

const loadJuicesDatesByGrade = (services, params, callbacks) => {
    const { api, actions } = services;

    return async (dispatch, getState) => {
        const state = getState();

        dispatch(actions.juices.clearJuicesDates());

        const res = await api.dailyJuices.getJuicesDatesByGrade({
            session: state.user.session,
            grade: params.grade,
        });

        const dates = (res?.juices || []).map((juice) => ({
            date: juice.date,
            juiceId: juice.id,
        }));

        dispatch(actions.juices.setJuicesDates({
            dates,
        }));

        if (callbacks.onLoad) {
            callbacks.onLoad(dates, res);
        }
    };
};

const loadJuicesDates = (services) => {
    const { api, actions } = services;

    return async (dispatch, getState) => {
        const state = getState();

        dispatch(actions.juices.clearJuicesDates());

        const res = await api.dailyJuices.getJuicesDates({
            session: state.user.session,
        });

        if (!res.ok) {
            return;
        }

        const dates = (res.juices || []).map((juice) => ({
            date: juice.date,
            juiceId: juice.id,
        }));

        dispatch(actions.juices.setJuicesDates({
            dates,
        }));
    };
};

export default {
    loadDailyJuicesByPage,
    loadJuicesDatesByGrade,
    loadJuicesDates,
};
