import React from "react";

import classNames from "juice-base/lib/class-names.js";

import DailyJuice from "juice-base/project/daily-juice.js";

import JuiceStatusV2 from "juice-base/business/juice-status-v2/index.js";

import styles from "./styles.module.css";


const TeacherClassTableLegend = (props) => {
    const { view, isVisible } = props;

    if (!view || !isVisible) {
        return null;
    }

    const isDailyJuiceView = DailyJuice.isDailyJuiceView(view);

    const statuses = isDailyJuiceView
        ? DailyJuice.getDailyJuiceStatuses()
        : DailyJuice.getAssignmentsStatuses();

    const legend = statuses.map((s) => {
        return (
            <JuiceStatusV2
                status={s.name}
                withLabel
            />
        );
    });

    const legendClassName = classNames({
        [styles.legend]: true,
        [styles.legendRows]: isDailyJuiceView,
    });

    return (
        <div className={legendClassName}>
            {legend}
        </div>
    );
};

TeacherClassTableLegend.defaultProps = {
    view: "",
    isVisible: true,
};

export default TeacherClassTableLegend;
