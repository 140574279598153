import React from "react";
import { Formik } from "formik";

import { hasEmptyValue, filterByKey } from "juice-base/lib/object.js";
import v from "juice-base/lib/form-validators.js";

import ButtonBig from "juice-base/components/button-big/index.js";
import MessageDanger from "juice-base/components/message-danger/index.js";
import FormRow from "juice-base/components/forms/form-row/index.js";

import Input from "juice-base/components/forms/input/index.js";
import Password from "juice-base/components/forms/password/index.js";
import Checkbox from "juice-base/components/forms/checkbox/index.js";

import styles from "./styles.module.css";


const UserSignUpTrialCreateAccount = (props) => {
    const isDisabled = (formProps) => {
        const hasErrors = Object.keys(formProps.errors).length > 0;

        const filteredValues = filterByKey(formProps.values, "isRememberMe");

        return hasEmptyValue(filteredValues)
            || hasErrors
            || formProps.isSubmitting;
    };

    const validateForm = (values) => {
        const errorsFirstName = v.validate(values.firstname, [
            v.required("First name is required."),
        ]);

        const errorsLastName = v.validate(values.lastname, [
            v.required("Last name is required."),
        ]);

        const errorsEmail = v.validate(values.email, [
            v.required("Please enter email address."),
            v.email("Please enter a valid email address."),
        ]);

        const errorsPassword = v.validate(values.password, [
            v.required("Please enter password."),
            v.min(`Password must be at least ${props.passwordMinLength} characters long.`, props.passwordMinLength),
            v.hasUpperCase("Please use at least one upper case letter"),
            v.hasLowerCase("Please use at least one lower case letter"),
            v.hasSymbol("Please use at least one symbol letter"),
        ]);

        const errors = {};

        if (errorsFirstName) {
            errors.firstname = errorsFirstName;
        }

        if (errorsLastName) {
            errors.lastname = errorsLastName;
        }

        if (errorsEmail) {
            errors.email = errorsEmail;
        }

        if (errorsPassword) {
            errors.password = errorsPassword;
        }

        return errors;
    };

    const renderFormError = (error) => {
        if (error) {
            return (
                <div className={styles.formError}>
                    <MessageDanger>
                        {error}
                    </MessageDanger>
                </div>
            );
        }

        return null;
    };

    const renderForm = (formProps) => {
        const {
            values,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
        } = formProps;

        return (
            <form onSubmit={handleSubmit} className={styles.form}>
                <FormRow>
                    <Input
                        name="firstname"
                        label="First Name *"
                        placeholder="First Name"
                        autoComplete="firstname"
                        value={values.firstname}
                        error={v.getError(formProps, "firstname")}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />

                    <Input
                        name="lastname"
                        label="Last Name *"
                        placeholder="Last Name"
                        autoComplete="lastname"
                        value={values.lastname}
                        error={v.getError(formProps, "lastname")}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </FormRow>

                <Input
                    name="email"
                    label="Email Address *"
                    placeholder="Email Address"
                    autoComplete="email"
                    value={values.email}
                    error={v.getError(formProps, "email")}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />

                <Password
                    name="password"
                    label="Password *"
                    autoComplete="current-password"
                    passwordMinLength={props.passwordMinLength}
                    value={values.password}
                    error={v.getError(formProps, "password")}
                    showMessage
                    onChange={handleChange}
                    onBlur={handleBlur}
                />

                <div className={styles.formLinksSection}>
                    <Checkbox
                        name="isRememberMe"
                        label="Remember me"
                        checked={values.isRememberMe}
                        onChange={handleChange}
                    />
                </div>

                {renderFormError(errors.formError)}

                <div className={styles.submitButtonBlock}>
                    <ButtonBig
                        type="submit"
                        disabled={isDisabled(formProps)}
                    >
                        Create
                    </ButtonBig>
                </div>
            </form>
        );
    };

    return (
        <Formik
            initialValues={props.initialValues}
            validate={validateForm}
            onSubmit={props.onSubmit}
        >
            {renderForm}
        </Formik>
    );
};

UserSignUpTrialCreateAccount.defaultProps = {
    initialValues: {
        firstname: "",
        lastname: "",
        email: "",
        password: "",
        isRememberMe: true,
    },
    passwordMinLength: 8,
    onSubmit: () => { },
};

export default UserSignUpTrialCreateAccount;
