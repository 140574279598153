import { useState } from "react";

import Teachers from "juice-base/project/teachers.js";


const useClassTeacherTable = () => {
    const [state, setState] = useState(() => ({
        isVisible: false,
        sortBy: Teachers.getSortValues()[0].value,
    }));

    const toggleVisible = () => {
        setState((prev) => ({
            ...prev,
            isVisible: !prev.isVisible,
        }));
    };

    const sortByName = () => {
        setState((prev) => ({
            ...prev,
            sortBy: Teachers.getSortValueBySort(prev.sortBy),
        }));
    };

    return {
        state,
        toggleVisible,
        sortByName,
    };
};

export default useClassTeacherTable;
