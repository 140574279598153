import React from "react";

import IconWrapper from "juice-base/icons/wrapper/index.js";


const IconPlayInDottedCircle = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={iconProps.className}
                >
                    {iconProps.title}
                    <path
                        fill="currentColor"
                        d="M13.05 9.79005L10 7.50005V16.5L13.05 14.21L16 12L13.05 9.79005ZM13.05 9.79005L10 7.50005V16.5L13.05 14.21L16 12L13.05 9.79005ZM13.05 9.79005L10 7.50005V16.5L13.05 14.21L16 12L13.05 9.79005ZM11 4.07005V2.05005C8.99005 2.25005 7.16005 3.05005 5.68005 4.26005L7.10005 5.69005C8.21005 4.83005 9.54005 4.25005 11 4.07005ZM5.69005 7.10005L4.26005 5.68005C3.05005 7.16005 2.25005 8.99005 2.05005 11H4.07005C4.25005 9.54005 4.83005 8.21005 5.69005 7.10005ZM4.07005 13H2.05005C2.25005 15.01 3.05005 16.84 4.26005 18.32L5.69005 16.89C4.83005 15.79 4.25005 14.46 4.07005 13ZM5.68005 19.74C7.16005 20.9501 9.00005 21.75 11 21.9501V19.93C9.54005 19.75 8.21005 19.17 7.10005 18.31L5.68005 19.74ZM22 12C22 17.16 18.08 21.42 13.05 21.9501V19.93C16.97 19.41 20 16.05 20 12C20 7.95005 16.97 4.59005 13.05 4.07005V2.05005C18.08 2.58005 22 6.84005 22 12Z"
                    />
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconPlayInDottedCircle;
