import React from "react";

import classNames from "juice-base/lib/class-names.js";

import text from "juice-base/text/index.js";

import useAccordion from "juice-base/hooks/use-accordion/index.js";

import IconDownload from "juice-base/icons/download/index.js";
import IconMenuFurl from "juice-base/icons/menu-furl/index.js";
import IconMenuUnfurl from "juice-base/icons/menu-unfurl/index.js";

import Message from "juice-base/components/message/index.js";
import RequestLoader from "juice-base/components/request-loader/index.js";
import DLCustom from "juice-base/components/dl-custom/index.js";
import ButtonFlat from "juice-base/components/button-flat/index.js";
import ButtonFAB, {
    ButtonFabPosition,
} from "juice-base/components/button-fab/index.js";

import JuiceStatus from "juice-base/business/juice-status/index.js";

import styles from "./styles.module.css";


const TableDailyJuices = (props) => {
    const juicesAccordion = useAccordion();

    /* -------- */

    const onToggleJuice = (index) => {
        juicesAccordion.onSetExpanded(index);
    };

    const onToggleAllJuices = () => {
        if (props.data.length === juicesAccordion.expanded.length) {
            juicesAccordion.closeAll();
            return;
        }

        const indexes = [];

        for (let i = 0; i < props.data.length; i += 1) {
            indexes.push(i);
        }

        juicesAccordion.expandAll(indexes);
    };

    /* -------- */

    const renderQuizScore = (quizScore) => {
        if (quizScore !== "-1%") {
            return quizScore;
        }

        return "-";
    };

    const renderStatuses = (ss) => {
        const statuses = (ss || []).map((statusId) => (
            <JuiceStatus
                statusId={statusId}
            />
        ));

        return statuses;
    };

    const renderLists = (index, row) => {
        const quizStatus = (
            <JuiceStatus
                statusId={row.quizStatus}
            />
        );

        const values = [
            { label: "Quiz Status", value: quizStatus },
            { label: "Quiz Score", value: renderQuizScore(row.quizScore) },
            { label: "Extra Juice", value: renderStatuses(row.extraJuices) },
            { label: "Video", value: renderStatuses(row.videos) },
        ];

        return (
            <div
                key={index}
                className={styles.rowMobile}
            >
                <DLCustom
                    title={row.date}
                    values={values}
                    isExpanded={juicesAccordion.isExpanded(index)}
                    onToggle={() => {
                        onToggleJuice(index);
                    }}
                />
            </div>
        );
    };

    const renderTableRows = () => {
        if (props.error) {
            return (
                <Message>
                    {props.error}
                </Message>
            );
        }

        if (props.data.length === 0 && (props.isLoadingBefore || props.isLoadingAfter)) {
            return (
                <RequestLoader />
            );
        }

        if (props.data.length === 0) {
            return (
                <Message>
                    {text.noStudentDailyJuices}
                </Message>
            );
        }

        return props.data.map((rowData) => {
            return (
                <div className={styles.row}>
                    <div className={styles.rowData}>
                        {rowData.date}
                    </div>
                    <div className={styles.rowData}>
                        <JuiceStatus
                            statusId={rowData.quizStatus}
                        />
                    </div>
                    <div className={styles.rowData}>
                        {renderQuizScore(rowData.quizScore)}
                    </div>
                    <div className={styles.rowData}>
                        {renderStatuses(rowData.extraJuices)}
                    </div>
                    <div className={styles.rowData}>
                        {renderStatuses(rowData.videos)}
                    </div>
                </div>
            );
        });
    };

    const renderHeader = () => {
        const headerClassName = classNames({
            [styles.header]: true,
            [styles.row]: true,
        });

        return (
            <div className={headerClassName}>
                <div>Date</div>
                <div>Quiz Status</div>
                <div>Quiz Score</div>
                <div>Extra Juice</div>
                <div>Video</div>
            </div>
        );
    };

    const renderLoadMoreButton = (params = {}) => {
        const {
            error,
            onClick,
            hasMore,
            isLoading,
            isDisabled,
        } = params;

        if (props.data.length === 0) {
            return null;
        }

        if (error) {
            return (
                <Message>
                    {error}
                </Message>
            );
        }

        if (!hasMore) {
            return null;
        }

        if (isLoading) {
            return (
                <RequestLoader />
            );
        }

        return (
            <div className={styles.loadMoreButton}>
                <ButtonFlat
                    onClick={onClick}
                    disabled={isDisabled}
                    uppercase
                >
                    Load more
                </ButtonFlat>
            </div>
        );
    };

    const renderLoadMoreAfterButton = () => {
        return renderLoadMoreButton({
            error: props.errorAfter,
            onClick: props.onLoadMoreAfter,
            hasMore: !props.isLastLoaded,
            isLoading: props.isLoadingAfter,
            isDisabled: props.isLoadingBefore || props.isLoadingAfter,
        });
    };

    const renderLoadMoreBeforeButton = () => {
        return renderLoadMoreButton({
            error: props.errorBefore,
            onClick: props.onLoadMoreBefore,
            hasMore: !props.isFirstLoaded,
            isLoading: props.isLoadingBefore,
            isDisabled: props.isLoadingBefore || props.isLoadingAfter,
        });
    };

    /* -------- */

    const renderTableMobile = () => {
        const lists = props.data.map((row, i) => renderLists(i, row));

        let iconToggle = null;

        if (props.data.length === juicesAccordion.expanded.length) {
            iconToggle = (
                <IconMenuFurl
                    isWhite
                />
            );
        } else {
            iconToggle = (
                <IconMenuUnfurl
                    isWhite
                />
            );
        }

        return (
            <>
                {lists}
                <ButtonFabPosition
                    placeUpper
                    alignRightByVW
                >
                    <ButtonFAB
                        icon={<IconDownload isSky />}
                        onClick={props.onExport}
                        whiteTheme
                    />
                </ButtonFabPosition>
                <ButtonFabPosition alignRightByVW>
                    <ButtonFAB
                        icon={iconToggle}
                        onClick={onToggleAllJuices}
                    />
                </ButtonFabPosition>
            </>
        );
    };

    if (props.isMobile) {
        return renderTableMobile();
    }

    return (
        <>
            {renderHeader()}
            {renderLoadMoreAfterButton()}
            {renderTableRows()}
            {renderLoadMoreBeforeButton()}
            <ButtonFabPosition>
                <ButtonFAB
                    icon={<IconDownload isWhite />}
                    text="Download"
                    onClick={props.onExport}
                />
            </ButtonFabPosition>
        </>
    );
};

TableDailyJuices.defaultProps = {
    data: [],

    errorBefore: "",
    errorAfter: "",

    onLoadMoreBefore: () => { },
    onLoadMoreAfter: () => { },
    onExport: () => { },

    isLastLoaded: false,
    isFirstLoaded: false,
    isLoadingBefore: false,
    isLoadingAfter: false,
    isMobile: false,
};

export default TableDailyJuices;
