import React, { useState } from "react";

import ButtonFlat from "juice-base/components/button-flat/index.js";
import PopupConfirm, {
    PopupConfirmContent,
    PopupConfirmButtons,
} from "juice-base/components/popup-confirm/index.js";
import MessageDanger from "juice-base/components/message-danger/index.js";
import RequestLoader from "juice-base/components/request-loader/index.js";

import UserFullnameChangeForm from "juice-base/forms/user-fullname-change/index.js";


const PopupFullnameChange = (props) => {
    const [fullnameState, setFullnameState] = useState({
        value: props.defaultValue,
        error: null,
    });

    const onChangeFullname = (params) => {
        setFullnameState(params);
    };

    const isDisabled = () => {
        if (props.isSaving || props.error) {
            return true;
        }

        if (!fullnameState.value || fullnameState.error) {
            return true;
        }

        return false;
    };

    const renderContent = () => {
        if (props.isSaving) {
            return (
                <RequestLoader />
            );
        }

        if (props.error) {
            return (
                <MessageDanger>
                    {props.error}
                </MessageDanger>
            );
        }

        return (
            <UserFullnameChangeForm
                initialValues={{
                    fullname: props.defaultValue,
                }}
                onChange={onChangeFullname}
            />
        );
    };

    return (
        <PopupConfirm hideScrollbar={false}>
            <PopupConfirmContent>
                {renderContent()}
            </PopupConfirmContent>
            <PopupConfirmButtons>
                <ButtonFlat
                    onClick={() => {
                        props.onSave(fullnameState);
                    }}
                    disabled={isDisabled()}
                >
                    Save
                </ButtonFlat>
                <ButtonFlat
                    onClick={props.onClose}
                    disabled={props.isSaving}
                >
                    Cancel
                </ButtonFlat>
            </PopupConfirmButtons>
        </PopupConfirm>
    );
};

PopupFullnameChange.defaultProps = {
    defaultValue: "",
    isSaving: false,
    error: null,
    onSave: () => {},
    onClose: () => {},
};

export default PopupFullnameChange;
