import React from "react";

import classNames from "juice-base/lib/class-names.js";

import IconQuestion from "juice-base/icons/question/index.js";
import IconArrowRight from "juice-base/icons/arrow-right/index.js";

import IconClickable from "juice-base/components/icon-clickable/index.js";

import styles from "./styles.module.css";


const SectionTeacherHeaderWithPages = (props) => {
    const isLeftDisabled = props.currentPage < 1;
    const isRightDisabled = props.currentPage >= props.totalPages - 1;

    const onLeftClick = () => {
        if (isLeftDisabled) {
            return;
        }

        props.onLeftClick();
    };

    const onRightClick = () => {
        if (isRightDisabled) {
            return;
        }

        props.onRightClick();
    };

    const renderLeftControl = () => {
        const arrowClassName = classNames({
            [styles.headerWithPagesArrow]: true,
            [styles.headerWithPagesArrowDisabled]: isLeftDisabled,
            [styles.headerWithPagesArrowLeft]: true,
        });

        return (
            <IconClickable
                onClick={onLeftClick}
                className={arrowClassName}
            >
                <IconArrowRight
                    className={styles.headerWithPagesLeftArrowIcon}
                    isBlack={!isLeftDisabled}
                />
            </IconClickable>
        );
    };

    const renderRightControl = () => {
        const arrowClassName = classNames({
            [styles.headerWithPagesArrow]: true,
            [styles.headerWithPagesArrowDisabled]: isRightDisabled,
            [styles.headerWithPagesArrowRight]: true,
        });

        return (
            <IconClickable
                onClick={onRightClick}
                className={arrowClassName}
            >
                <IconArrowRight
                    isBlack={!isRightDisabled}
                />
            </IconClickable>
        );
    };

    const renderLabel = () => {
        let info = null;

        if (props.onInfoClick) {
            info = (
                <IconClickable onClick={props.onInfoClick}>
                    <IconQuestion
                        isBlack
                    />
                </IconClickable>
            );
        }

        return (
            <div className={styles.headerWithPagesLabel}>
                {props.label}
                {info}
            </div>
        );
    };

    return (
        <div className={styles.headerWithPages}>
            {renderLeftControl()}
            {renderLabel()}
            {renderRightControl()}
        </div>
    );
};

SectionTeacherHeaderWithPages.defaultProps = {
    label: "",
    totalPages: 0,
    currentPage: 0,
    onLeftClick: () => { },
    onRightClick: () => { },
    onInfoClick: null,
};

export default SectionTeacherHeaderWithPages;
