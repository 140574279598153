const loadPlans = (services) => {
    const { api, actions } = services;

    return async (dispatch) => {
        const res = await api.subscription.getPlans();

        if (res.ok) {
            dispatch(actions.subscription.setPlans({
                plans: res.plans,
                freeTrialPlanFeatures: res.freeTrialFeatures,
            }));
        }
    };
};

const loadActivePlans = (services) => {
    const { api, actions } = services;

    return async (dispatch) => {
        const res = await api.subscription.getActivePlans();

        if (res.ok) {
            dispatch(actions.subscription.setActivePlans({
                plans: res.plans,
            }));
        }
    };
};


export default {
    loadActivePlans,
    loadPlans,
};
