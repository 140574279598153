import React from "react";

import date from "juice-base/lib/date.js";

import Collapse from "juice-base/components/collapse-section/index.js";
import Message from "juice-base/components/message/index.js";
import ButtonFlat from "juice-base/components/button-flat/index.js";
import Button from "juice-base/components/button/index.js";
import RequestLoader from "juice-base/components/request-loader/index.js";

import styles from "./styles.module.css";


const DailyJuicePastIssues = (props) => {
    const renderReviewButton = (juice) => {
        if (!juice.completed || !juice.quizId) {
            return null;
        }

        return (
            <div className={styles.linkButton}>
                <Button
                    onClick={() => {
                        props.onReviewClick(juice.juiceId, juice.quizId);
                    }}
                >
                    Review Quiz
                </Button>
            </div>
        );
    };

    const renderOpenJuiceButton = (juiceId) => {
        return (
            <div className={styles.linkButton}>
                <Button
                    onClick={() => {
                        props.onGoToDailyJuice(juiceId);
                    }}
                >
                    Open Daily Juice
                </Button>
            </div>
        );
    };

    const renderPastIssues = () => {
        const {
            data,
            showLoadMore,
            isLoading,
            error,
        } = props;

        const issues = data.map((issue) => {
            let completedOn = "-";

            if (issue.completed && issue.completedOn && issue.completedOn !== "-") {
                completedOn = date.tryFormatDate(issue.completedOn, date.formatDayDate);
            }

            let withBlueIndicator = true;

            if (issue.completed || issue.withoutQuizzes) {
                withBlueIndicator = false;
            }

            return (
                <Collapse
                    title={issue.title}
                    collapseClassName={styles.collapse}
                    collapseButtonClassName={styles.collapseButton}
                    extendedContentClassName={styles.extendedContent}
                    withDot={withBlueIndicator}
                >
                    <div className={styles.expandedContent}>
                        <div className={styles.quizScore}>
                            <div className={styles.text}>
                                quiz score
                            </div>
                            <div className={styles.boldText}>
                                {issue.quizScore}
                            </div>
                        </div>
                        <div className={styles.completedDate}>
                            <div className={styles.text}>
                                completed on
                            </div>
                            <div className={styles.boldText}>
                                {completedOn}
                            </div>
                        </div>
                        <div className={styles.controls}>
                            {renderOpenJuiceButton(issue.juiceId)}
                            {renderReviewButton(issue)}
                        </div>
                    </div>
                </Collapse>
            );
        });

        let loader = null;
        let loadMoreButton = null;
        let errorMsg = null;

        if (isLoading) {
            loader = (
                <RequestLoader />
            );
        }

        if (showLoadMore && !isLoading) {
            loadMoreButton = (
                <ButtonFlat
                    onClick={props.onLoadMore}
                    uppercase={false}
                >
                    Load More
                </ButtonFlat>
            );
        }

        if (error) {
            errorMsg = (
                <Message>
                    {error}
                </Message>
            );
        }

        return (
            <>
                <div>{issues}</div>
                {errorMsg}
                {loadMoreButton}
                {loader}
            </>
        );
    };

    return (
        <div
            className={styles.content}
            data-comment="section-daily-juices-past-issues"
        >
            <div className={styles.header}>
                Past Issues
            </div>
            {renderPastIssues()}
        </div>
    );
};

DailyJuicePastIssues.defaultProps = {
    data: [],
    error: "",

    onGoToDailyJuice: () => { },
    onLoadMore: () => { },
    onReviewClick: () => { },

    showLoadMore: false,
    isLoading: false,
};

export default DailyJuicePastIssues;
