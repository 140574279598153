import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { formatDayDate, tryFormatDate } from "juice-base/lib/date.js";

import dailyJuiceActions from "juice-base/actions/daily-juice.js";
import actions from "juice-base/store/actions.js";

import LayoutContent from "juice-base/components/layout-content/index.js";
import SectionStudentV2 from "juice-base/business/section-student-v2/index.js";

import PopupQuizReview from "juice-base/business/popup-quiz-review/index.js";
import DailyJuiceTodoList from "juice-base/business/daily-juice-todo-list/index.js";
import DailyJuicePastIssues from "juice-base/business/daily-juice-past-issues/index.js";

import Tutorial from "juice-app/containers/tutorial/index.js";
import UserFooter from "juice-app/containers/user-footer/index.js";

import api from "juice-app/api.js";

import styles from "./styles.module.css";


const StudentDailyJuicesRegular = () => {
    const [quizReviewPopup, setQuizReviewPopup] = useState({
        isOpen: false,
        isLoaded: false,
        quizzes: [],
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const store = useSelector((state) => ({
        session: state.user.session,
        juices: state.juices,
        studentTodo: state.studentTodo,
    }));

    const loadJuices = (page) => {
        dispatch(dailyJuiceActions.loadDailyJuicesByPage({ api, actions }, {
            session: store.session,
            page,
        }));
    };

    const loadIncompletedJuices = async (page) => {
        dispatch(actions.studentTodo.setIncompletedJuicesLoading());

        const juicesRes = await api.dailyJuices.getIncompletedJuices({
            session: store.session,
            page,
        });

        let incompletedJuices = [];
        let hasMorePages = false;

        if (juicesRes.ok) {
            incompletedJuices = juicesRes.juices;
            hasMorePages = juicesRes.hasMore;
        }

        dispatch(actions.studentTodo.setIncompletedJuices({
            incompletedJuices,
            hasMorePages,
            page,
        }));
    };

    useEffect(() => {
        if (!store.studentTodo.isIncompletedJuicesLoaded) {
            loadIncompletedJuices(0);
        }

        if (!store.juices.isJuicesLoaded) {
            loadJuices(0);
        }

        return () => {
            dispatch(actions.juices.clearJuices());
            dispatch(actions.studentTodo.clearIncompletedJuices());
        };
    }, []);

    /* ---- */

    const onGoToDailyJuice = (juiceId) => {
        navigate(`/daily-juices/${juiceId}`);
    };

    const onCloseQuizReview = () => {
        setQuizReviewPopup({
            isOpen: false,
            isLoaded: false,
            quizzes: [],
        });
    };

    const loadQuizzesByJuiceId = (juiceId, quizId) => {
        setQuizReviewPopup({
            isOpen: true,
            isLoaded: false,
            quizzes: [],
        });

        api.quizzes.getQuizzesByJuiceId({
            session: store.session,
            juiceId,
            quizId,
        }).then((res) => {
            if (res.ok) {
                setQuizReviewPopup({
                    isOpen: true,
                    isLoaded: true,
                    quizzes: res.data,
                });
            }
        });
    };

    /* ----- */

    const renderTodoList = () => {
        const { studentTodo } = store;

        const data = studentTodo.incompletedJuices.map((juice) => ({
            date: tryFormatDate(juice.juiceDate, formatDayDate),
            title: "Quiz Incomplete",
            imgUrl: null,
            juiceUrl: `/daily-juices/${juice.id}`,
        }));

        let isLoading = false;

        if (studentTodo.isIncompletedJuicesLoading || !studentTodo.isIncompletedJuicesLoaded) {
            isLoading = true;
        }

        return (
            <DailyJuiceTodoList
                data={data}
                onLoadMore={() => {
                    loadIncompletedJuices(studentTodo.page + 1);
                }}
                showLoadMore={studentTodo.hasMorePages}
                isLoading={isLoading}
            />
        );
    };

    const renderPastIssues = () => {
        const data = store.juices.juices.map((juice) => {
            let quizScore = "0%";
            let completedOn = "-";
            let completed = false;
            let quizId = null;
            let withoutQuizzes = true;

            if (juice?.quizResult) {
                quizScore = `${juice.quizResult.score || "0"}%`;
                completedOn = juice.quizResult.dateOfLastAnswer;
                completed = juice.quizResult.isQuizCompleted;
                quizId = juice.quizResult.quizId;
                withoutQuizzes = !juice.quizResult.juiceWithQuiz;
            }

            return {
                title: juice.title,
                juiceId: juice.id,
                quizId,
                quizScore,
                completed,
                completedOn,
                withoutQuizzes,
            };
        });

        return (
            <DailyJuicePastIssues
                data={data}
                error={store.juices.juicesError}
                onReviewClick={loadQuizzesByJuiceId}
                onGoToDailyJuice={onGoToDailyJuice}
                onLoadMore={() => {
                    loadJuices(store.juices.juicesPage);
                }}
                showLoadMore={store.juices.juicesHasMorePages}
                isLoading={store.juices.isJuicesLoading}
            />
        );
    };

    const renderQuizReviewPopup = () => {
        if (!quizReviewPopup.isOpen) {
            return null;
        }

        const questions = quizReviewPopup.quizzes.map((quiz) => ({
            question: quiz.question,
            answers: quiz.answers,
        }));

        return (
            <PopupQuizReview
                isLoaded={quizReviewPopup.isLoaded}
                questions={questions}
                onClose={onCloseQuizReview}
            />
        );
    };

    return (
        <>
            <Tutorial name="student-daily-juices" />
            {renderQuizReviewPopup()}

            <LayoutContent>
                <div className={styles.page}>
                    <SectionStudentV2
                        title="Daily Juice"
                        dataComment="section-daily-juice-todo"
                        headerClassName={styles.header}
                        titleBottomBorder={false}
                    >
                        <div className={styles.sectionContent}>
                            {renderTodoList()}
                            {renderPastIssues()}
                        </div>
                    </SectionStudentV2>
                </div>
                <UserFooter />
            </LayoutContent>
        </>
    );
};

export default StudentDailyJuicesRegular;
