import React, { useState, useEffect } from "react";

import Students from "juice-base/project/students.js";
import Grades from "juice-base/project/grades.js";

import classNames from "juice-base/lib/class-names.js";
import date from "juice-base/lib/date.js";

import text from "juice-base/text/index.js";

import useAccordion from "juice-base/hooks/use-accordion/index.js";

import IconArrowBold from "juice-base/icons/arrow-bold/index.js";
import IconDownload from "juice-base/icons/download/index.js";
import IconGraduationCap from "juice-base/icons/graduation-cap/index.js";
import IconListLayout from "juice-base/icons/list-layout/index.js";
import IconPlus from "juice-base/icons/plus/index.js";
import IconTrashWithLinesFull from "juice-base/icons/trash-with-lines-full/index.js";
import IconUserAdd from "juice-base/icons/user-add/index.js";
import IconUserInRing from "juice-base/icons/user-in-ring/index.js";

import IconClickable from "juice-base/components/icon-clickable/index.js";
import InfoTooltip from "juice-base/components/info-tooltip/index.js";
import RequestLoader from "juice-base/components/request-loader/index.js";
import MenuSticky from "juice-base/components/menu-sticky/index.js";
import AccordionWithControls from "juice-base/components/accordion-with-controls/index.js";
import SelectCalendar from "juice-base/components/select-calendar/index.js";
import Message from "juice-base/components/message/index.js";
import ButtonCircle from "juice-base/components/button-circle/index.js";
import ButtonCircleArrow from "juice-base/components/button-circle-arrow/index.js";
import Checkbox from "juice-base/components/forms/checkbox/index.js";
import MenuWithButton, {
    MenuOption,
} from "juice-base/components/menu-with-button/index.js";

import RowWithOffset from "./row-with-offset.js";
import RowHeader from "./row-header.js";
import styles from "./styles.module.css";


const TeacherClassTable = (props) => {
    const [isVisibleExportMenu, setIsVisibleExportMenu] = useState(false);

    const [selectedStudents, setSelectedStudents] = useState([]);

    const [tableHeaderState, setTableHeaderState] = useState({
        isArrowsHidden: true,
        headerOffset: 0,
    });

    /* ------- */

    const studentsAccordion = useAccordion();

    /* ------- */

    const onRowClick = (params) => {
        studentsAccordion.onSetExpanded(params.studentId);
    };

    const onExpandOrCollapseRows = () => {
        if (studentsAccordion.expanded.length >= props.students.length) {
            studentsAccordion.closeAll();
            return;
        }

        for (let i = 0; i < props.students.length; i += 1) {
            const studentId = props.students[i].id;

            if (!studentsAccordion.isExpanded(studentId)) {
                studentsAccordion.onSetExpanded(studentId);
            }
        }
    };

    const onSelectStudent = (studentId) => {
        let newStudents = [...selectedStudents];

        if (newStudents.indexOf(studentId) === -1) {
            newStudents = [...newStudents].concat(studentId);
        } else {
            newStudents = [...newStudents].filter((id) => id !== studentId);
        }

        setSelectedStudents(newStudents);
    };

    const onSelectAllStudents = () => {
        if (props.students.length === selectedStudents.length) {
            setSelectedStudents([]);
            return;
        }

        const studentsIds = [];

        for (let i = 0; i < props.students.length; i += 1) {
            const sId = props.students[i].id;
            studentsIds.push(sId);
        }

        setSelectedStudents(studentsIds);
    };

    const onNavigateWeek = (params = {}) => {
        const { selectedRange } = props;

        const currentDate = selectedRange.dateFrom || selectedRange.dateTo;

        if (currentDate) {
            let weekdays = [];

            if (params.isNextWeek) {
                weekdays = date.getNextWeekDaysByDate(currentDate);
            } else if (params.isPrevWeek) {
                weekdays = date.getPrevWeekDaysByDate(currentDate);
            }

            if (weekdays.length > 0) {
                props.onRangeChange({
                    dateFrom: weekdays[0] || null,
                    dateTo: weekdays[weekdays.length - 1] || null,
                });
            }
        }
    };

    const onToggleReportMenu = () => {
        setIsVisibleExportMenu((prev) => !prev);
    };

    const onCloseReportMenu = () => {
        setIsVisibleExportMenu(false);
    };

    const onExportSelectedWeek = () => {
        props.onExportSelectedWeek();
        onCloseReportMenu();
    };

    const onExportAllScores = () => {
        props.onExportAllScores();
        onCloseReportMenu();
    };

    /* ------- */

    useEffect(() => {
        if (props.isLoading) {
            setSelectedStudents([]);
            setTableHeaderState((prev) => ({
                ...prev,
                headerOffset: 0,
            }));
        }
    }, [props.isLoading]);

    /* ------- */

    const renderClassMenu = () => {
        let isVisibleAddOption = false;

        if (props.maxClasses === -1 || props.classes.length < props.maxClasses) {
            isVisibleAddOption = true;
        }

        const controlOptions = [];

        if (!props.isLMS && isVisibleAddOption) {
            controlOptions.push({
                name: "New Class",
                icon: <IconPlus title="New Class" isSky />,
                onClick() {
                    props.onAddNewClass();
                },
            });
        }

        if (props.isLMS) {
            controlOptions.push({
                name: "LMS sync classes",
                icon: <IconGraduationCap title="LMS sync classes" isSky />,
                onClick() {
                    props.onSyncLMSClasses();
                },
            });
        }

        const options = props.classes.map((cl) => ({
            value: cl.value,
            label: cl.label,
            info: cl.isBounceNotificationsExists,
        }));

        return (
            <MenuSticky
                selected={props.selectedClassId}
                options={options}
                controlOptions={controlOptions}
                onEdit={props.onEditClass}
                onSelect={props.onClassChange}
            />
        );
    };

    const renderHeaderButtons = () => {
        const buttons = [];

        if (props.isMobileTable) {
            buttons.push({
                icon: <IconListLayout isSky />,
                onClick: onExpandOrCollapseRows,
            });
        }

        buttons.push({
            withMenu: true,
            isVisibleMenu: isVisibleExportMenu,
            icon: <IconDownload isSky />,
            options: [
                {
                    name: "Download selected week's data",
                    onClick: onExportSelectedWeek,
                },
                {
                    name: "Download all data",
                    onClick: onExportAllScores,
                },
            ],
            onClick: onToggleReportMenu,
            onCloseMenu: onCloseReportMenu,
        });

        if (!props.isLMS) {
            buttons.push({
                icon: <IconUserAdd isSky />,
                onClick: props.onOpenAddStudentPopup,
            });

            buttons.push({
                icon: <IconTrashWithLinesFull isSky />,
                disabled: selectedStudents.length === 0,
                onClick: () => {
                    props.onRemoveStudents(selectedStudents);
                },
            });
        }

        const btns = buttons.map((btn, index) => {
            const buttonClassName = classNames({
                [styles.headerButton]: true,
                [styles.headerButtonNoBorder]: index === 0,
            });

            if (btn.withMenu) {
                return (
                    <div className={buttonClassName}>
                        <MenuWithButton
                            icon={btn.icon}
                            isVisibleMenu={btn.isVisibleMenu}
                            isMenuOpensRight={props.isMenuOpensRight}
                            isFullWidth={props.isMenuFullWidth}
                            onClick={btn.onClick}
                            onCloseMenu={btn.onCloseMenu}
                        >
                            {btn.options.map((opt) => ((
                                <MenuOption
                                    name={opt.name}
                                    onClick={opt.onClick}
                                    blueTheme
                                />
                            )))}
                        </MenuWithButton>
                    </div>
                );
            }

            return (
                <div className={buttonClassName}>
                    <ButtonCircle
                        icon={btn.icon}
                        disabled={btn.disabled || false}
                        tooltip={btn?.tooltip || null}
                        onClick={btn.onClick}
                    />
                </div>
            );
        });

        return (
            <div className={styles.headerButtons}>
                {btns}
            </div>
        );
    };

    const renderCalendar = () => {
        const maxDate = props.siteDate
            ? date.getFriday(props.siteDate)
            : null;

        return (
            <div className={styles.headerCalendar}>
                <SelectCalendar
                    maxDate={maxDate}
                    selectedRange={props.selectedRange}
                    calendarClassName={styles.headerCalendarCalendar}
                    onRangeChange={props.onRangeChange}
                />
            </div>
        );
    };

    const renderHeader = () => {
        return (
            <div className={styles.tableHeader}>
                <div className={styles.tableHeaderClassMenu}>
                    {renderClassMenu()}
                </div>
                <div className={styles.tableHeaderDescription}>
                    {text.classTableDescription}
                </div>
                <div className={styles.tableHeaderControls}>
                    {renderHeaderButtons()}
                    {renderCalendar()}
                </div>
            </div>
        );
    };

    /* ------- */

    const renderRowContent = (dates, studentData) => {
        const rows = dates.map((data) => {
            let studentCompletedQuizzesCount = "-";

            let isUpdated = false;

            for (let i = 0; i < studentData.length; i += 1) {
                const isSame = date.isDatesSame(
                    date.newDate(data.date),
                    date.newDate(studentData[i].date),
                );

                if (isSame) {
                    if (studentData[i].isUpdated) {
                        isUpdated = true;
                    }

                    studentCompletedQuizzesCount = studentData[i].correctQuizzes;
                    break;
                }
            }

            const rowClassName = classNames({
                [styles.row]: true,
                [styles.rowUpdated]: isUpdated,
            });

            return (
                <div className={rowClassName}>
                    <div className={styles.rowData}>
                        <div>
                            {date.tryFormatDateUTC(data.date, date.formatFullDayShortMonthDate)}
                        </div>
                        <div>{`out of ${data.totalQuizzes}`}</div>
                    </div>
                    <div>
                        {studentCompletedQuizzesCount}
                    </div>
                </div>
            );
        });

        if (rows.length === 0) {
            return (
                <Message>
                    No Data Found
                </Message>
            );
        }

        return (
            <div className={styles.accordionContent}>
                {rows}
            </div>
        );
    };

    const renderRowControls = (student) => {
        const studentId = student.id;

        let studentSelector = null;

        if (!props.isLMS) {
            studentSelector = (
                <Checkbox
                    key={`table-mobile-student-${studentId}`}
                    name={`table-mobile-student-${studentId}`}
                    checked={selectedStudents.indexOf(studentId) !== -1}
                    onChange={() => {
                        onSelectStudent(studentId);
                    }}
                />
            );
        }

        return (
            <div className={styles.rowControls}>
                {studentSelector}
                <IconClickable
                    className={styles.profileIcon}
                    onClick={() => {
                        props.onOpenStudentInfo(studentId);
                    }}
                >
                    <IconUserInRing
                        title="Open profile"
                        isBlack
                    />
                </IconClickable>
            </div>
        );
    };

    const renderDesktopMessage = (message) => {
        return (
            <div className={styles.tableMessage}>
                <Message>
                    {message}
                </Message>
            </div>
        );
    };

    const renderMobileTable = () => {
        if (props.isLoading) {
            return (
                <RequestLoader />
            );
        }

        if (props.error) {
            return renderDesktopMessage(props.error);
        }

        if (props.dates.length === 0) {
            return renderDesktopMessage(text.noJuicesInRange);
        }

        return props.students.map((student) => {
            const label2 = Grades.getGradeGroup(student.grades);

            const controls = [{
                isCustom: true,
                element: renderRowControls(student),
            }];

            return (
                <AccordionWithControls
                    isExpanded={studentsAccordion.isExpanded(student.id)}
                    isControlsWithBorder={false}
                    labelClassName={styles.accordion}
                    label={student.fullName}
                    label2={label2}
                    controls={controls}
                    onClick={() => {
                        onRowClick({
                            studentId: student.id,
                        });
                    }}
                >
                    {renderRowContent(props.dates, student.quizzesScores)}
                </AccordionWithControls>
            );
        });
    };

    const renderStudentRowHeader = () => {
        const { students } = props;

        const arrowClassName = classNames({
            [styles.sortIcon]: true,
            [styles.sortIconDown]: props.sortBy === Students.StudentsSortByValues.nameZTOA,
        });

        let allStudentsSelector = null;

        if (!props.isLMS) {
            allStudentsSelector = (
                <Checkbox
                    name="table-desktop-all-student-check"
                    checked={students.length > 0 && students.length === selectedStudents.length}
                    onChange={onSelectAllStudents}
                />
            );
        }

        return (
            <div className={styles.tableDesktopStudentRowHeader}>
                {allStudentsSelector}
                <div
                    className={styles.tableDesktopStudentRowHeaderTitle}
                    onClick={props.onSortStudentsByName}
                    onKeyPress={props.onSortStudentsByName}
                    tabIndex="-1"
                    role="button"
                >
                    <div>Name</div>
                    <IconArrowBold
                        className={arrowClassName}
                        title="Sort"
                        isBlack
                    />
                </div>
            </div>
        );
    };

    const renderStudentRow = (student) => {
        const studentId = student.id;

        const grades = Grades.getGradeGroup(student?.grades || []);

        let studentSelector = null;

        if (!props.isLMS) {
            studentSelector = (
                <Checkbox
                    key={`table-desktop-student-${studentId}`}
                    name={`table-desktop-student-${studentId}`}
                    checked={selectedStudents.indexOf(studentId) !== -1}
                    onChange={() => {
                        onSelectStudent(studentId);
                    }}
                />
            );
        }

        const hasNotification = props.studentsNotifications.indexOf(studentId) !== -1;

        let tooltip = <div />;

        if (hasNotification) {
            tooltip = (
                <InfoTooltip
                    tooltip={text.userInvalidEmail}
                    position="rightCenter"
                />
            );
        }

        return (
            <div className={styles.tableDesktopStudentRow}>
                {studentSelector}
                <div
                    className={styles.studentRowName}
                    onClick={() => {
                        props.onOpenStudentInfo(studentId);
                    }}
                    onKeyPress={() => {
                        props.onOpenStudentInfo(studentId);
                    }}
                    tabIndex="-1"
                    role="button"
                >
                    {student.fullName}
                </div>
                {tooltip}
                <div className={styles.studentRowGrades}>
                    {grades}
                </div>
            </div>
        );
    };

    const renderStudentRowResultsHeader = () => {
        let isNextWeekDisabled = false;

        if (props.isLoading) {
            isNextWeekDisabled = true;
        } else if (props.siteDate) {
            const currWeekFriday = date.getFriday(props.siteDate);

            const isSame = date.isDatesSame(props.selectedRange.dateTo, currWeekFriday);
            const isGreater = date.newDate(props.selectedRange.dateTo) > currWeekFriday;

            if (isSame || isGreater) {
                isNextWeekDisabled = true;
            }
        }

        return (
            <div className={styles.studentRowResultsHeader}>
                <div className={styles.navButtonDesktop}>
                    <ButtonCircleArrow
                        onClick={() => {
                            onNavigateWeek({
                                isPrevWeek: true,
                            });
                        }}
                        disabled={props.isLoading}
                        left
                    />
                </div>
                <div className={styles.rowHeader}>
                    <RowHeader
                        dates={props.dates}
                        hideArrows={tableHeaderState.isArrowsHidden}
                        onFirstLastItemsVisibleChange={(params) => {
                            const isArrowsHidden = params.isFirstItemVisible
                                && params.isLastItemVisible;

                            setTableHeaderState((prev) => ({
                                ...prev,
                                isArrowsHidden,
                            }));
                        }}
                        onPositionChange={(pos) => {
                            setTableHeaderState((prev) => ({
                                ...prev,
                                headerOffset: pos,
                            }));
                        }}
                    />
                </div>
                <div className={styles.navButtonDesktop}>
                    <ButtonCircleArrow
                        onClick={() => {
                            onNavigateWeek({
                                isNextWeek: true,
                            });
                        }}
                        disabled={isNextWeekDisabled}
                        right
                    />
                </div>
            </div>
        );
    };

    const renderStudentRowResults = (student) => {
        let isUpdated = false;

        const scores = props.dates.map((d) => {
            let score = "-";

            for (let i = 0; i < student.quizzesScores.length; i += 1) {
                const s = student.quizzesScores[i];

                if (s.isUpdated) {
                    isUpdated = true;
                }

                if (s.date === d.date) {
                    score = s.correctQuizzes;
                    break;
                }
            }

            return (
                <div className={styles.studentRowResult}>
                    {score}
                </div>
            );
        });

        return (
            <RowWithOffset
                offset={tableHeaderState.headerOffset}
                isUpdated={isUpdated}
                withPadding={!tableHeaderState.isArrowsHidden}
            >
                {scores}
            </RowWithOffset>
        );
    };

    const renderDesktopTable = () => {
        const names = [];
        const results = [];

        for (let i = 0; i < props.students.length; i += 1) {
            const student = props.students[i];

            names.push(renderStudentRow(student));
            results.push(renderStudentRowResults(student));
        }

        let resultsContent = null;

        if (props.isLoading) {
            resultsContent = (
                <div className={styles.tableLoader}>
                    <RequestLoader />
                </div>
            );
        } else if (props.error) {
            resultsContent = renderDesktopMessage(props.error);
        } else if (props.dates.length === 0) {
            resultsContent = renderDesktopMessage(text.noJuicesInRange);
        } else {
            resultsContent = results;
        }

        return (
            <div className={styles.tableDesktop}>
                <div className={styles.tableDesktopStudents}>
                    {renderStudentRowHeader()}
                    {names}
                </div>
                <div className={styles.tableDesktopContent}>
                    {renderStudentRowResultsHeader()}
                    {resultsContent}
                </div>
            </div>
        );
    };

    const renderContent = () => {
        let content = null;

        if (props.isMobileTable) {
            content = renderMobileTable();
        } else {
            content = renderDesktopTable();
        }

        return (
            <div className={styles.tableContent}>
                {content}
            </div>
        );
    };

    /* ------- */

    return (
        <div className={styles.table}>
            {renderHeader()}
            {renderContent()}
        </div>
    );
};

TeacherClassTable.defaultProps = {
    isLoading: false,
    isMobileTable: false,
    isMenuFullWidth: false,
    isMenuOpensRight: false,
    isLMS: false,

    selectedClassId: null,
    maxClasses: null,
    siteDate: null,
    selectedRange: {
        dateFrom: null,
        dateTo: null,
    },

    error: "",
    sortBy: "",

    students: [],
    studentsNotifications: [],
    classes: [],
    dates: [],

    onAddNewClass: () => { },
    onSyncLMSClasses: () => { },
    onEditClass: () => { },
    onClassChange: () => { },
    onRangeChange: () => { },
    onOpenAddStudentPopup: () => { },
    onOpenStudentInfo: () => { },
    onRemoveStudents: () => { },
    onSortStudentsByName: () => { },
    onExportSelectedWeek: () => { },
    onExportAllScores: () => { },
};

export default TeacherClassTable;
