import React from "react";
import { Link } from "react-router-dom";

import IconArrowInCircle from "juice-base/icons/arrow-in-circle/index.js";

import styles from "./styles.module.css";


const LinkBlockWithImage = (props) => {
    const renderTitle = () => {
        return (
            <div className={styles.blockTitle}>
                <div className={styles.date}>
                    {props.date}
                </div>
                <div className={styles.title}>
                    {props.title}
                </div>
            </div>
        );
    };

    return (
        <div className={styles.blockContainer}>
            <Link
                to={props.url}
                className={styles.block}
            >
                <div className={styles.dot} />

                {renderTitle()}

                <IconArrowInCircle
                    isBlack
                    className={styles.arrowIcon}
                />
            </Link>
        </div>
    );
};

LinkBlockWithImage.defaultProps = {
    title: "",
    date: "",
    url: null,
};

export default LinkBlockWithImage;
