import React, { useState } from "react";
import { Link } from "react-router-dom";

import classNames from "juice-base/lib/class-names.js";

import { getIconByName } from "juice-base/icons/index.js";

import User from "juice-base/project/user.js";
import IconWithName from "juice-base/components/icon-with-name/index.js";
import IconHome from "juice-base/icons/home/index.js";
import IconSignOut from "juice-base/icons/sign-out/index.js";
import PopupUserLogout from "juice-base/components/popup-user-logout/index.js";

import styles from "./styles.module.css";


const ShortNav = (props) => {
    const [isVisibleConfirmLogoutPopup, setVisibleConfirmLogoutPopup] = useState(false);

    const renderConfirmLogoutPopup = () => {
        if (!isVisibleConfirmLogoutPopup) {
            return null;
        }

        return (
            <PopupUserLogout
                onLogout={() => {
                    props.onSignOut();
                    setVisibleConfirmLogoutPopup(false);
                }}
                onClose={() => {
                    setVisibleConfirmLogoutPopup(false);
                }}
            />
        );
    };

    const renderHomeSection = () => {
        if (!props.isVisibleHomeLink) {
            return null;
        }

        const isSelected = props.pathname === "/";

        const homeSectionClassName = classNames({
            [styles.section]: true,
            [styles.sectionSelected]: isSelected,
        });

        return (
            <Link
                to="/"
                className={homeSectionClassName}
                data-comment="mobile-header-link-home"
                key="home"
            >
                <IconWithName name="Home">
                    <IconHome isOrange={isSelected} />
                </IconWithName>
            </Link>
        );
    };

    const renderSectionIcon = (sec, isSelected) => {
        const IconComponent = getIconByName(sec.icon);

        if (!IconComponent) {
            return null;
        }

        return (
            <IconWithName name={sec.name}>
                <IconComponent
                    isOrange={isSelected}
                />
            </IconWithName>
        );
    };

    const renderSections = () => {
        return User.getNavSections(props.user, props.sectionsByRoles).map((sec) => {
            const isSelected = props.pathname.indexOf(sec.to) === 0;

            const sectionClassName = classNames({
                [styles.section]: true,
                [styles.sectionSelected]: isSelected,
            });

            return (
                <Link
                    key={sec.name}
                    to={sec.to}
                    className={sectionClassName}
                    data-comment={`mobile-header-${sec.dataComment}`}
                >
                    {renderSectionIcon(sec, isSelected)}
                </Link>
            );
        });
    };

    const renderLogout = () => {
        let logoutLink = null;

        if (User.isTypeTrial(props.user)) {
            const logoutSectionClassName = classNames({
                [styles.section]: true,
                [styles.sectionSelected]: props.pathname === "/",
            });

            logoutLink = (
                <div key="logout" className={logoutSectionClassName}>
                    <IconWithName
                        name="Sign Out"
                        onClick={() => {
                            setVisibleConfirmLogoutPopup(true);
                        }}
                    >
                        <IconSignOut />
                    </IconWithName>
                </div>
            );
        }

        return logoutLink;
    };

    if (!props.user.userId) {
        return (
            <div className={styles.shortNav}>
                {renderHomeSection()}
            </div>
        );
    }

    return (
        <>
            {renderConfirmLogoutPopup()}
            <div className={styles.shortNav}>
                {renderHomeSection()}
                {renderSections()}
                {renderLogout()}
            </div>
        </>
    );
};

ShortNav.defaultProps = {
    pathname: "/",
    user: {},
    sectionsByRoles: {},
    isVisibleHomeLink: true,
    onSignOut: () => { },
};

export default ShortNav;
