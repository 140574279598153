import React from "react";

import IconLamp from "juice-base/icons/lamp/index.js";

import PopupWindow from "juice-base/components/popup-window/index.js";

import styles from "./styles.module.css";


const PopupTips = (props) => {
    const renderLeftControl = () => {
        return (
            <div className={styles.tipsIcon}>
                <IconLamp
                    title="Tips"
                    isRed
                />
            </div>
        );
    };

    const renderContent = () => {
        const messages = props.messages.map((message) => (
            <div>
                {message}
            </div>
        ));

        return (
            <div className={styles.content}>
                {messages}
            </div>
        );
    };

    return (
        <PopupWindow
            title="Tips and Tricks"
            isSmall
            isCentered
            titleToStart
            withTitleBarShadow={false}
            leftControl={renderLeftControl()}
            onClose={props.onClose}
        >
            {renderContent()}
        </PopupWindow>
    );
};

PopupTips.defaultProps = {
    messages: [],

    onClose: () => { },
};

export default PopupTips;
