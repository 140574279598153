
export const SET_CLOSED_POLLS = "SET_CLOSED_POLLS";
export const SET_CLOSED_POLLS_LOADING = "SET_CLOSED_POLLS_LOADING";
export const CLEAR_CLOSED_POLLS = "CLEAR_CLOSED_POLLS";

export const SET_POLL_RESULT_BY_CLASS_ID_LOADING = "SET_POLL_RESULT_BY_CLASS_ID_LOADING";
export const SET_POLL_RESULT_BY_CLASS_ID = "SET_POLL_RESULT_BY_CLASS_ID";
export const CLEAR_POLLS_RESULTS = "CLEAR_POLLS_RESULTS";

export default {};
