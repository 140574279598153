import * as globalTypes from "juice-base/store-common/types.js";
import * as types from "./types.js";


const initialState = {
    isPlansLoaded: false,
    plans: [],
    freeTrialPlanFeatures: [],

    isActivePlansLoaded: false,
    activePlans: false,
};

export default (state, action) => {
    switch (action.type) {
        case types.SET_PLANS: {
            return {
                ...state,
                isPlansLoaded: true,
                plans: action.payload.plans,
                freeTrialPlanFeatures: action.payload.freeTrialPlanFeatures,
            };
        }

        case types.SET_ACTIVE_PLANS: {
            return {
                ...state,
                isActivePlansLoaded: true,
                activePlans: action.payload.plans,
            };
        }

        /* --- */

        case globalTypes.CLEAR_AFTER_SIGNOUT: {
            return { ...initialState };
        }

        /* --- */

        default: {
            return state || { ...initialState };
        }
    }
};
