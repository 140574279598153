import React, { useRef, useState, useEffect } from "react";

import symbols from "juice-base/lib/symbols.js";
import array from "juice-base/lib/array.js";
import classNames from "juice-base/lib/class-names.js";

import IconCaret from "juice-base/icons/caret/index.js";
import IconCheck from "juice-base/icons/check/index.js";
import IconClose from "juice-base/icons/close/index.js";

import Error from "juice-base/components/forms/error/index.js";

import scrollbarStyles from "juice-base/components/scrollbar/styles.module.css";
import fieldCommonStyles from "juice-base/components/forms/field-common/styles.module.css";
import styles from "./styles.module.css";


const filterOptionsByValue = (options, value) => {
    const filterValue = value.toLowerCase();

    let filteredOptions = [...options];

    filteredOptions = filteredOptions
        .filter((val) => {
            const foundName = val.name.toLowerCase().indexOf(filterValue) !== -1;

            if (val.name2) {
                const foundName2 = val.name2.indexOf(value) !== -1;
                return foundName || foundName2;
            }

            return foundName;
        });

    filteredOptions.sort((a, b) => {
        const ai = a.name.toLowerCase().indexOf(filterValue);
        const bi = b.name.toLowerCase().indexOf(filterValue);

        if (ai === 0 && bi === 0) {
            return a.name.localeCompare(b.name);
        }

        if (ai === 0) {
            return -1;
        }

        return a.name.localeCompare(b.name);
    });

    return filteredOptions;
};


const Select = (props) => {
    const selectCustom = useRef(null);
    const inputRef = useRef(null);
    const [isFocused, setIsFocused] = useState(false);

    /* --- */

    const inputFocus = () => {
        if (props.isDisabled) {
            return;
        }

        if (props.isCreatable || props.isSearchable) {
            if (inputRef?.current) {
                inputRef?.current.focus();
            }
        }
    };

    const inputBlur = () => {
        if (props.isCreatable || props.isSearchable) {
            if (inputRef?.current) {
                inputRef?.current.blur();
            }
        }
    };

    const onBlur = () => {
        inputBlur();
        props.onBlur();
    };

    const onToggle = () => {
        if (props.isDisabled) {
            return;
        }

        if (isFocused) {
            setIsFocused(false);
            onBlur();
        } else {
            setIsFocused(true);
            inputFocus();
        }
    };

    const onClear = (evt) => {
        evt.stopPropagation();

        props.onSelect(null);
    };

    const onSelect = (evt, opt) => {
        evt.stopPropagation();

        setIsFocused(false);

        props.onSelect({
            value: opt.value,
            isCreated: false,
        });
    };

    const onInputValueCreate = () => {
        setIsFocused(false);
    };

    const onInputValueKeyPress = (evt) => {
        if (evt.code === "Enter") {
            if (props.isSearchable && !props.isCreatable) {
                if (props.selected?.isCreated) {
                    const opts = filterOptionsByValue(props.options, props.selected.value);

                    if (opts[0]) {
                        props.onSelect({
                            value: opts[0].value,
                            isCreated: false,
                        });
                    } else {
                        props.onSelect({
                            value: "",
                            isCreated: false,
                        });
                    }
                }

                setIsFocused(false);
                onBlur();
            } else if (props.isCreatable) {
                setIsFocused(false);
                onBlur();
            } else {
                onClear(evt);
            }
        }
    };

    const onInputValueChange = (evt) => {
        let val = "";

        if (props.selected?.isCreated) {
            val = evt.target.value || "";
        } else {
            const selectedOpt = array.findOneByValue(props.options, props.selected?.value);
            const prefix = selectedOpt ? selectedOpt.name || "" : "";
            const value = evt.target.value || "";
            val = value.slice(prefix.length);
        }

        props.onSelect({
            value: val,
            isCreated: true,
        });
    };

    /* --- */

    useEffect(() => {
        const globalClose = (evt) => {
            if (selectCustom?.current?.contains
                && selectCustom.current.contains(evt.target)) {
                return;
            }

            setIsFocused(false);

            if (props.isSearchable
                && !props.isCreatable
                && props.selected?.isCreated) {
                props.onSelect({
                    value: "",
                    isCreated: false,
                });
            }
        };

        if (document) {
            document.addEventListener("click", globalClose, false);
        }

        return () => {
            if (document) {
                document.removeEventListener("click", globalClose, false);
            }
        };
    }, [props.selected]);

    /* --- */

    const renderControlIcon = () => {
        if (props.isDisabled) {
            return null;
        }

        if (props?.selected?.value && props.isEmptyAllowed) {
            return (
                <div
                    className={fieldCommonStyles.controlIcon}
                    onClick={onClear}
                    onKeyPress={onClear}
                    role="button"
                    tabIndex="-1"
                >
                    <IconClose
                        className={styles.optionIconClose}
                        title="Clear value"
                    />
                </div>
            );
        }

        const iconClassName = classNames({
            [fieldCommonStyles.controlIcon]: true,
            [fieldCommonStyles.controlIconDisabled]: props.isDisabled,
        });

        const imgClassName = classNames({
            [fieldCommonStyles.controlIconImg]: true,
            [fieldCommonStyles.controlIconImgUp]: isFocused,
            [styles.optionIcon]: true,
        });

        return (
            <div className={iconClassName}>
                <IconCaret
                    className={imgClassName}
                    title="Toggle selector"
                />
            </div>
        );
    };

    const renderValue = () => {
        let value = "";

        const selectedOpt = array.findOneByValue(props.options, props.selected?.value);

        if (selectedOpt) {
            value = selectedOpt
                ? selectedOpt.name
                : "";
        } else {
            value = props.selected?.value || "";
        }

        const isSimpleSelect = !props.isDisabled && !props.isCreatable && !props.isSearchable;

        const inputClassName = classNames({
            [fieldCommonStyles.inputWithPointer]: isSimpleSelect,
            [fieldCommonStyles.inputDisabled]: props.isDisabled,
            [styles.input]: true,
        });

        if (!props.isCreatable && !props.isSearchable) {
            return (
                <div className={inputClassName}>
                    {value || symbols.nbsp}
                </div>
            );
        }

        let inputTabIndex = "0";

        if (props.isDisabledTabIndex) {
            inputTabIndex = "-1";
        }

        return (
            <input
                ref={inputRef}
                id={props.name}
                name={props.name}
                type="text"
                value={value}
                className={inputClassName}
                maxLength="255"
                tabIndex={inputTabIndex}
                onChange={onInputValueChange}
                onKeyPress={onInputValueKeyPress}
                onBlur={onBlur}
                disabled={props.isDisabled}
                autoComplete="off"
            />
        );
    };

    const renderCreateOption = () => {
        const val = props.selected?.value || "";
        const valueName = `Create "${val}"`;

        return (
            <div
                className={styles.option}
                onClick={onInputValueCreate}
                onKeyPress={onInputValueCreate}
                tabIndex="-1"
                role="button"
            >
                {valueName}
            </div>
        );
    };

    const renderNotFoundOption = () => {
        return (
            <div
                className={styles.option}
                onClick={onToggle}
                onKeyPress={onToggle}
                tabIndex="-1"
                role="button"
            >
                Not found
            </div>
        );
    };

    const renderAvailableOptions = (opts) => {
        return opts.map((opt) => {
            const isSelected = props.selected?.value === opt.value;

            const optClassName = classNames({
                [styles.option]: true,
                [styles.optionAlreadySelected]: isSelected,
            });

            let icon = null;

            if (isSelected) {
                icon = (
                    <IconCheck
                        title="Selected"
                        isRed
                    />
                );
            }

            return (
                <div
                    className={optClassName}
                    onClick={(evt) => { onSelect(evt, opt); }}
                    onKeyPress={(evt) => { onSelect(evt, opt); }}
                    tabIndex="-1"
                    role="button"
                >
                    {opt.name}
                    {icon}
                </div>
            );
        });
    };

    const renderOptions = () => {
        if (!isFocused) {
            return null;
        }

        let options = [];

        if (props.isSearchable && props.selected?.value) {
            let filteredOptions = props.options;

            if (props.selected?.isCreated) {
                filteredOptions = filterOptionsByValue(props.options, props.selected.value);
            }

            if (filteredOptions.length === 0) {
                if (props.isCreatable) {
                    options.push(renderCreateOption());
                } else {
                    options.push(renderNotFoundOption());
                }
            } else {
                options = renderAvailableOptions(filteredOptions);
            }
        } else if (props.isCreatable && props.selected?.value && props.selected.isCreated) {
            options.push(renderCreateOption());
        } else {
            options = renderAvailableOptions(props.options);
        }

        if (options.length === 0) {
            return null;
        }

        const optionsClassName = classNames({
            [scrollbarStyles.scrollbar]: true,
            [styles.options]: true,
            [styles.optionsFour]: props.isOptionsFour,
        });

        return (
            <div className={optionsClassName}>
                {options}
            </div>
        );
    };

    const renderError = () => {
        if (!props.error) {
            return null;
        }

        return (
            <Error>
                {props.error}
            </Error>
        );
    };

    const containerClassName = classNames({
        [fieldCommonStyles.container]: true,
        [fieldCommonStyles.containerError]: props.error,
        [fieldCommonStyles.containerDisabled]: props.isDisabled,
    });

    const inputClassName = classNames({
        [fieldCommonStyles.input]: true,
        [fieldCommonStyles.inputError]: props.error,
        [fieldCommonStyles.inputFocused]: isFocused || (props.selected?.value && !props.isDisabled),
        [fieldCommonStyles.inputDisabled]: props.isDisabled,
        [styles.value]: true,
    });

    const labelClassName = classNames({
        [fieldCommonStyles.label]: true,
        [fieldCommonStyles.labelWithCursorText]: !props.isDisabled && props.isCreatable,
        [fieldCommonStyles.labelWithCursorPointer]: !props.isDisabled && !props.isCreatable,
        [fieldCommonStyles.labelFocused]: isFocused || props.selected?.value,
        [fieldCommonStyles.labelDisabled]: props.isDisabled,
    });

    /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
    const label = (
        <label
            htmlFor={props.name}
            className={labelClassName}
            onClick={(evt) => { evt.preventDefault(); }}
            onKeyPress={(evt) => { evt.preventDefault(); }}
        >
            {props.label}
        </label>
    );
    /* eslint-enable */

    return (
        <div
            ref={selectCustom}
            className={fieldCommonStyles.field}
        >
            <div className={containerClassName}>
                <div
                    className={inputClassName}
                    onClick={onToggle}
                    onKeyPress={() => { }}
                    tabIndex="-1"
                    role="button"
                >
                    {label}

                    {renderValue()}

                    {renderControlIcon()}
                </div>

                {renderOptions()}
            </div>

            {renderError()}
        </div>
    );
};

Select.defaultProps = {
    name: "",
    label: "",
    error: "",
    selected: null,
    options: [],
    isOptionsFour: false,
    isEmptyAllowed: true,
    isDisabled: false,
    isCreatable: false,
    isSearchable: false,
    isDisabledTabIndex: false,
    onSelect: () => { },
    onBlur: () => { },
};

export default Select;
