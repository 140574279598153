import React from "react";

import IconPlayerHeadphone from "juice-base/icons/player-headphone/index.js";

import JuiceContent from "juice-base/components/juice-content/index.js";
import ButtonWithIcon from "juice-base/components/button-with-icon/index.js";

import dailyJuiceCommonStyles from "juice-base/business/daily-juice-common/styles.module.css";
import styles from "./styles.module.css";


const ExtraJuice = (props) => {
    if (!props.extraJuice) {
        return null;
    }

    const onListenStory = () => {
        props.onAudioPlay();
    };

    const renderListenButton = () => {
        return (
            <div className={styles.playerAudioToggleButton}>
                <ButtonWithIcon
                    icon={<IconPlayerHeadphone title="Headphone" isWhite />}
                    onClick={onListenStory}
                >
                    Listen
                </ButtonWithIcon>
            </div>
        );
    };

    const categoryClassName = [
        dailyJuiceCommonStyles.header,
        styles.category,
    ].join(" ");

    const headlineClassName = [
        dailyJuiceCommonStyles.headline,
        styles.headline,
    ].join(" ");

    return (
        <div className={styles.extraJuice}>
            <div className={categoryClassName}>
                Extra Juice
            </div>

            <div className={headlineClassName}>
                {props.extraJuice.title}
            </div>

            {renderListenButton()}

            <JuiceContent
                className={styles.content}
                content={props.extraJuice.content}
                onWordClick={(word) => {
                    props.onWordClick(word);
                }}
                onExtraJuiceWordClick={() => { }}
                extraJuices={[]}
            />
        </div>
    );
};

ExtraJuice.defaultProps = {
    extraJuice: null,
    onWordClick: () => { },
    onAudioPlay: () => { },
};

export default ExtraJuice;
