import React from "react";

import Money from "juice-base/components/money/index.js";

import SubscribePlanCardButton from "./button.js";
import styles from "./styles.module.css";


const SubscribePlanCard = (props) => {
    const renderBar = () => {
        const barStyle = {};

        if (props.color) {
            barStyle.background = props.color;
        }

        return (
            <div
                className={styles.bar}
                style={barStyle}
            >
                {props.planName}
            </div>
        );
    };

    const renderPrice = () => {
        let priceSection = null;

        if (props.price) {
            let annuallySection = null;

            if (props.hasPrice) {
                annuallySection = (
                    <div className={styles.priceSectionText}>
                        {props.billingCycle}
                    </div>
                );
            }

            priceSection = (
                <div className={styles.priceSection}>
                    <Money
                        className={styles.priceSectionPrice}
                        value={props.price}
                    />
                    {annuallySection}
                </div>
            );
        }

        return priceSection;
    };

    const renderPlanFeaturesList = () => {
        return (
            <div className={styles.features}>
                {(props.features || []).map((f) => (
                    <div>
                        {f}
                    </div>
                ))}
            </div>
        );
    };

    const renderContent = () => {
        return (
            <div className={styles.content}>
                {renderPrice()}
                {renderPlanFeaturesList()}
            </div>
        );
    };

    const renderButton = () => {
        return (
            <SubscribePlanCardButton
                color={props.color}
                onClick={props.onPickPlan}
            >
                {props.buttonName || "Get Started"}
            </SubscribePlanCardButton>
        );
    };

    /* ------------ */

    return (
        <div className={styles.card}>
            {renderBar()}
            <div className={styles.cardContent}>
                {renderContent()}
                {renderButton()}
            </div>
        </div>
    );
};

SubscribePlanCard.defaultProps = {
    planName: "",
    buttonName: "",

    color: "",

    price: null,
    billingCycle: "",

    hasPrice: false,

    features: [],

    onPickPlan: () => { },
};

export default SubscribePlanCard;
