// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HLJDN56ePvVEcTqwHcXp {\n    width: 100%;\n    max-width: 60rem;\n\n    margin: 1rem auto 0 auto;\n}\n\n.k2iLZne_RcjUm_Jd_ez1 {\n    position: fixed;\n}\n\n.MZh2AxW963CefqDyofIV {\n    margin: 1rem 0 0 0;\n}\n\n.BGZ3kLqF7YM54ubuq8HJ {\n    font-weight: 500;\n    padding: 1rem;\n    border-bottom: 0.05rem solid var(--grey-lightest);\n}\n\n.OuZ1E68WVl3zY53DQi5y {\n    color: var(--color-1);\n    cursor: pointer;\n    outline: none;\n}\n\n.OuZ1E68WVl3zY53DQi5y:hover {\n    color: var(--rose-600);\n}\n\n.wG_VouGmvjAcky1djqIa {\n    font-weight: 600;\n}\n\n@media only screen and (max-width: 800px) {\n    .HLJDN56ePvVEcTqwHcXp {\n        grid-auto-flow: row;\n        grid-template-columns: none;\n        justify-content: initial;\n    }\n}\n", "",{"version":3,"sources":["webpack://./app/views/vocabulary/styles.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,gBAAgB;;IAEhB,wBAAwB;AAC5B;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,iDAAiD;AACrD;;AAEA;IACI,qBAAqB;IACrB,eAAe;IACf,aAAa;AACjB;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI;QACI,mBAAmB;QACnB,2BAA2B;QAC3B,wBAAwB;IAC5B;AACJ","sourcesContent":[".vocabulary {\n    width: 100%;\n    max-width: 60rem;\n\n    margin: 1rem auto 0 auto;\n}\n\n.fixedPopup {\n    position: fixed;\n}\n\n.words {\n    margin: 1rem 0 0 0;\n}\n\n.word {\n    font-weight: 500;\n    padding: 1rem;\n    border-bottom: 0.05rem solid var(--grey-lightest);\n}\n\n.wordLink {\n    color: var(--color-1);\n    cursor: pointer;\n    outline: none;\n}\n\n.wordLink:hover {\n    color: var(--rose-600);\n}\n\n.noWords {\n    font-weight: 600;\n}\n\n@media only screen and (max-width: 800px) {\n    .vocabulary {\n        grid-auto-flow: row;\n        grid-template-columns: none;\n        justify-content: initial;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"vocabulary": "HLJDN56ePvVEcTqwHcXp",
	"fixedPopup": "k2iLZne_RcjUm_Jd_ez1",
	"words": "MZh2AxW963CefqDyofIV",
	"word": "BGZ3kLqF7YM54ubuq8HJ",
	"wordLink": "OuZ1E68WVl3zY53DQi5y",
	"noWords": "wG_VouGmvjAcky1djqIa"
};
export default ___CSS_LOADER_EXPORT___;
