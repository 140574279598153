import React from "react";

import IconWrapper from "juice-base/icons/wrapper/index.js";


const IconSupport = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    width="16"
                    height="14"
                    viewBox="0 0 16 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={iconProps.className}
                >
                    <path
                        fill="currentColor"
                        d="M14.75 7.16598C14.75 3.04848 11.555 0.250977 8 0.250977C4.4825 0.250977 1.25 2.98848 1.25 7.21098C0.8 7.46598 0.5 7.94598 0.5 8.50098V10.001C0.5 10.826 1.175 11.501 2 11.501H2.75V6.92598C2.75 4.02348 5.0975 1.67598 8 1.67598C10.9025 1.67598 13.25 4.02348 13.25 6.92598V12.251H7.25V13.751H13.25C14.075 13.751 14.75 13.076 14.75 12.251V11.336C15.1925 11.1035 15.5 10.646 15.5 10.106V8.38098C15.5 7.85598 15.1925 7.39848 14.75 7.16598Z"
                    />
                    <path
                        fill="currentColor"
                        d="M5.75 8.50098C6.16421 8.50098 6.5 8.16519 6.5 7.75098C6.5 7.33676 6.16421 7.00098 5.75 7.00098C5.33579 7.00098 5 7.33676 5 7.75098C5 8.16519 5.33579 8.50098 5.75 8.50098Z"
                    />
                    <path
                        fill="currentColor"
                        d="M10.25 8.50098C10.6642 8.50098 11 8.16519 11 7.75098C11 7.33676 10.6642 7.00098 10.25 7.00098C9.83579 7.00098 9.5 7.33676 9.5 7.75098C9.5 8.16519 9.83579 8.50098 10.25 8.50098Z"
                    />
                    <path
                        fill="currentColor"
                        d="M12.5001 6.27348C12.1401 4.13598 10.2801 2.50098 8.0376 2.50098C5.7651 2.50098 3.3201 4.38348 3.5151 7.33848C5.3676 6.58098 6.7626 4.93098 7.1601 2.92098C8.1426 4.89348 10.1601 6.25098 12.5001 6.27348Z"
                    />
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconSupport;
