import React from "react";

import classNames from "juice-base/lib/class-names.js";

import styles from "./styles.module.css";


const ButtonCircle = (props) => {
    const onClick = (evt) => {
        if (props.disabled) {
            return;
        }

        props.onClick(evt);
    };

    const renderTooltip = () => {
        if (!props.tooltip || props.isMobile) {
            return null;
        }

        const tooltipClassName = classNames({
            [styles.tooltip]: true,
            [styles.tooltipToLeft]: props.tooltip?.align === "to-left",
            [styles.tooltipToRight]: props.tooltip?.align === "to-right",
        });

        return (
            <div className={tooltipClassName}>
                {props.tooltip.message || ""}
            </div>
        );
    };

    const buttonClassName = classNames({
        [styles.button]: true,
        [styles.buttonDisabled]: props.disabled,
        [styles.imageBackwards]: props.isImageBackwards,
    });

    return (
        <div
            className={buttonClassName}
            onClick={onClick}
            onKeyPress={onClick}
            role="button"
            tabIndex="-1"
        >
            {props.icon}
            {renderTooltip()}
        </div>
    );
};

ButtonCircle.defaultProps = {
    icon: null,
    tooltip: null,
    isMobile: false,
    disabled: false,
    isImageBackwards: false,
    onClick: () => { },
};

export default ButtonCircle;
