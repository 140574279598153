import React from "react";

import classNames from "juice-base/lib/class-names.js";

import styles from "./styles.module.css";


const PlayerButton = (props) => {
    const controlClassName = classNames({
        [styles.controlBtn]: true,
        [styles.controlBtnMedium]: props.medium,
    });

    const onClick = (evt) => {
        evt.stopPropagation();
        props.onClick();
    };

    const renderIcon = () => {
        if (!props.icon) {
            return null;
        }

        const iconClassName = classNames({
            [styles.controlBtnImg]: true,
            [styles.controlBtnImgRight]: props.rotateRight,
        });

        return React.cloneElement(props.icon, {
            className: iconClassName,
        });
    };

    return (
        <div
            className={controlClassName}
            onClick={onClick}
            onKeyPress={onClick}
            tabIndex="-1"
            role="button"
            data-comment={props.dataComment}
        >
            {renderIcon()}
        </div>
    );
};

PlayerButton.defaultProps = {
    dataComment: "",
    icon: null,
    medium: false,
    rotateRight: false,
    onClick: () => { },
};

export default PlayerButton;
