import React from "react";

import IconWrapper from "juice-base/icons/wrapper/index.js";


const IconClipboard = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    width="16"
                    height="18"
                    viewBox="0 0 16 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={iconProps.className}
                >
                    {iconProps.title}
                    <path
                        d="M13.8333 2.33335H10.35C10 1.36669 9.08333 0.666687 8 0.666687C6.91667 0.666687 6 1.36669 5.65 2.33335H2.16667C1.25 2.33335 0.5 3.08335 0.5 4.00002V15.6667C0.5 16.5834 1.25 17.3334 2.16667 17.3334H13.8333C14.75 17.3334 15.5 16.5834 15.5 15.6667V4.00002C15.5 3.08335 14.75 2.33335 13.8333 2.33335ZM8 2.33335C8.45833 2.33335 8.83333 2.70835 8.83333 3.16669C8.83333 3.62502 8.45833 4.00002 8 4.00002C7.54167 4.00002 7.16667 3.62502 7.16667 3.16669C7.16667 2.70835 7.54167 2.33335 8 2.33335ZM9.66667 14H3.83333V12.3334H9.66667V14ZM12.1667 10.6667H3.83333V9.00002H12.1667V10.6667ZM12.1667 7.33335H3.83333V5.66669H12.1667V7.33335Z"
                        fill="currentColor"
                    />
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconClipboard;
