import React, { useState } from "react";

import classNames from "juice-base/lib/class-names.js";
import Error from "juice-base/components/forms/error/index.js";

import fieldCommonStyles from "juice-base/components/forms/field-common/styles.module.css";
import styles from "./styles.module.css";


const Input = (props) => {
    const [isFocused, setIsFocused] = useState(false);

    const onFocus = () => {
        setIsFocused(true);
    };

    const onBlur = (evt) => {
        setIsFocused(false);
        props.onBlur(evt);
    };

    const renderMaxLengthMessage = () => {
        if (props.showMaxLengthMessage) {
            const msgClassName = classNames({
                [styles.maxLengthMessage]: true,
                [styles.maxLengthMessageMax]: props.value.length === props.maxLength,
            });

            return (
                <div className={msgClassName}>
                    <div>{`Character Limit: ${props.maxLength}`}</div>
                    <div>{`${props.value.length}/${props.maxLength}`}</div>
                </div>
            );
        }

        return null;
    };

    let inputError = null;

    if (props.error) {
        inputError = (
            <Error>
                {props.error}
            </Error>
        );
    }

    const fieldClassName = classNames({
        [fieldCommonStyles.field]: true,
        [styles.fieldAutoHeight]: props.isDisabledMinHeight,
    });

    const containerClassName = classNames({
        [fieldCommonStyles.container]: true,
        [fieldCommonStyles.containerFocused]: isFocused,
        [fieldCommonStyles.containerError]: props.error,
        [fieldCommonStyles.containerDisabled]: props.isDisabled,
    });

    const inputClassName = classNames({
        [fieldCommonStyles.input]: true,
        [fieldCommonStyles.inputFocused]: isFocused || (props.value && !props.isDisabled),
        [fieldCommonStyles.inputError]: props.error,
        [fieldCommonStyles.inputDisabled]: props.isDisabled,
    });

    const labelClassName = classNames({
        [fieldCommonStyles.labelDisabled]: props.isDisabled,
        [fieldCommonStyles.label]: true,
        [fieldCommonStyles.labelWithCursorText]: true,
    });

    const inputTabIndex = props.isDisabledTabIndex ? "-1" : "0";

    return (
        <div className={fieldClassName}>
            <div className={containerClassName}>
                <input
                    id={props.id || props.name}
                    name={props.name}
                    type={props.type}
                    autoComplete={props.autoComplete}
                    value={props.value}
                    maxLength={props.maxLength}
                    className={inputClassName}
                    tabIndex={inputTabIndex}
                    disabled={props.isDisabled}
                    onKeyPress={props.onKeyPress}
                    onChange={props.onChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                />

                <label
                    htmlFor={props.id || props.name}
                    className={labelClassName}
                >
                    {props.label}
                </label>
            </div>

            {renderMaxLengthMessage()}

            {inputError}
        </div>
    );
};

Input.defaultProps = {
    id: 0,
    type: "text",
    autoComplete: "",
    name: "",
    label: "",
    value: "",
    error: "",
    maxLength: 255,
    showMaxLengthMessage: false,
    isDisabledTabIndex: false,
    isDisabledMinHeight: false,
    isDisabled: false,
    onKeyPress: () => { },
    onChange: () => { },
    onBlur: () => { },
};

export default Input;
