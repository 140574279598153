import React from "react";

import IconWrapper from "juice-base/icons/wrapper/index.js";


const IconCoupon = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    className={iconProps.className}
                    width="20"
                    height="19"
                    viewBox="0 0 20 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill="currentColor"
                        d="M18 4.00098H15.82C15.93 3.69098 16 3.35098 16 3.00098C16 1.34098 14.66 0.000976562 13 0.000976562C11.95 0.000976562 11.04 0.540976 10.5 1.35098L10 2.02098L9.5 1.34098C8.96 0.540976 8.05 0.000976562 7 0.000976562C5.34 0.000976562 4 1.34098 4 3.00098C4 3.35098 4.07 3.69098 4.18 4.00098H2C0.89 4.00098 0.00999999 4.89098 0.00999999 6.00098L0 17.001C0 18.111 0.89 19.001 2 19.001H18C19.11 19.001 20 18.111 20 17.001V6.00098C20 4.89098 19.11 4.00098 18 4.00098ZM13 2.00098C13.55 2.00098 14 2.45098 14 3.00098C14 3.55098 13.55 4.00098 13 4.00098C12.45 4.00098 12 3.55098 12 3.00098C12 2.45098 12.45 2.00098 13 2.00098ZM7 2.00098C7.55 2.00098 8 2.45098 8 3.00098C8 3.55098 7.55 4.00098 7 4.00098C6.45 4.00098 6 3.55098 6 3.00098C6 2.45098 6.45 2.00098 7 2.00098ZM18 17.001H2V15.001H18V17.001ZM18 12.001H2V6.00098H7.08L5 8.83098L6.62 10.001L9 6.76098L10 5.40098L11 6.76098L13.38 10.001L15 8.83098L12.92 6.00098H18V12.001Z"
                    />
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconCoupon;
