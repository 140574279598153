import React from "react";

import DailyJuiceStory from "juice-base/project/daily-juice-story.js";

import date from "juice-base/lib/date.js";
import text from "juice-base/text/index.js";

import useAccordion from "juice-base/hooks/use-accordion/index.js";

import IconPlus from "juice-base/icons/plus/index.js";
import IconMinus from "juice-base/icons/minus/index.js";

import ButtonBig from "juice-base/components/button-big/index.js";
import Accordion from "juice-base/components/accordion/index.js";
import Message from "juice-base/components/message/index.js";
import RequestLoader from "juice-base/components/request-loader/index.js";

import SectionStudentV2 from "juice-base/business/section-student-v2/index.js";
import AssignmentCard from "juice-base/business/assignment-card/index.js";

import styles from "./styles.module.css";


const AssignmentsCompleted = (props) => {
    const assignmentsAccordion = useAccordion();

    const renderStories = (assignment) => {
        const cards = (assignment?.stories || []).map((story) => {
            const storyId = story?.ID;
            const img = DailyJuiceStory.getMinSizeImage(story.featuredImage);

            return (
                <AssignmentCard
                    title={story.title}
                    date={story.date}
                    imageSrc={img?.src || ""}
                    completedDate={assignment.completedDate}
                    status={assignment.status}
                    onClick={() => {
                        props.onAssignmentClick(assignment, storyId);
                    }}
                />
            );
        });

        return (
            <div className={styles.assignmentStories}>
                {cards}
            </div>
        );
    };

    const renderAssignments = () => {
        if (props.data.length === 0 && !props.isLoading) {
            return (
                <Message>
                    {text.noAssignmentsFound}
                </Message>
            );
        }

        const assignmentsByDate = props.data.map((d, index) => {
            const isExpanded = assignmentsAccordion.isExpanded(index);

            const label = (
                <div className={styles.accordionLabelDate}>
                    {date.tryFormatDateUTC(d.date, date.formatDayDate)}
                </div>
            );

            const expandIcon = isExpanded
                ? <IconMinus />
                : <IconPlus />;

            const assignments = (d.assignments || []).map((assignment) => {
                return renderStories(assignment);
            });

            return (
                <div className={styles.accordion}>
                    <Accordion
                        className={styles.accordionLabel}
                        iconClassName={styles.accordionIcon}
                        label={label}
                        icon={expandIcon}
                        onClick={() => {
                            assignmentsAccordion.onSetExpanded(index);
                        }}
                        expanded={isExpanded}
                        withContentPadding={false}
                    >
                        <div className={styles.assignments}>
                            {assignments}
                        </div>
                    </Accordion>
                </div>
            );
        });

        return (
            <div className={styles.assignmentsByDate}>
                {assignmentsByDate}
            </div>
        );
    };

    const renderLoadButton = () => {
        if (props.error) {
            return (
                <Message>
                    {props.error}
                </Message>
            );
        }

        if (props.isLoading) {
            return (
                <div className={styles.loader}>
                    <RequestLoader />
                </div>
            );
        }

        if (!props.hasMore || props.data.length === 0) {
            return null;
        }

        return (
            <div className={styles.loadMoreButton}>
                <ButtonBig
                    onClick={props.onLoadMore}
                    outlined
                    isRounded
                    isDefaultBorder
                >
                    Load More
                </ButtonBig>
            </div>
        );
    };

    return (
        <SectionStudentV2
            title="Completed Assignments"
            isDefaultBorder
        >
            {renderAssignments()}
            {renderLoadButton()}
        </SectionStudentV2>
    );
};

AssignmentsCompleted.defaultProps = {
    data: [],
    error: "",
    onAssignmentClick: () => { },
    onLoadMore: () => { },
    hasMore: false,
    isLoading: false,
};

export default AssignmentsCompleted;
