import text from "juice-base/text/index.js";


const loadDailyJuicesResults = (services, params) => {
    const { actions, api } = services;
    const {
        session,
        studentId,
        date,
        onLoad,
        isBefore,
    } = params;

    return async (dispatch) => {
        dispatch(actions.teacherStudents.setStudentDailyJuicesLoadingById({
            studentId,
            isBefore,
        }));

        const res = await api.students.getStudentDailyJuicesResults({
            session,
            studentId,
            date,
            loadType: isBefore ? "before" : "after",
        });

        let juices = [];

        let errorBefore = "";
        let errorAfter = "";

        let hasMoreBefore = false;
        let hasMoreAfter = false;

        if (res.ok) {
            hasMoreBefore = res.hasMoreBefore || false;
            hasMoreAfter = res.hasMoreAfter || false;
            juices = res.juices;
        } else {
            const error = res.error || text.error;

            if (isBefore) {
                errorBefore = error;
            } else {
                errorAfter = error;
            }
        }

        dispatch(actions.teacherStudents.setStudentDailyJuicesById({
            studentId,
            juices,
            errorBefore,
            errorAfter,
            hasMoreBefore,
            hasMoreAfter,
            isBefore,
        }));

        if (onLoad) {
            onLoad(res);
        }
    };
};

export default {
    loadDailyJuicesResults,
};
