import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Google from "juice-base/project/google.js";
import Standards from "juice-base/project/standards.js";

import date from "juice-base/lib/date.js";
import array from "juice-base/lib/array.js";
import urls from "juice-base/lib/urls.js";
import classNames from "juice-base/lib/class-names.js";
import copyToClipboard from "juice-base/lib/clipboard.js";

import text from "juice-base/text/index.js";

import staticFiles from "juice-base/static-files.js";

import teacherActions from "juice-base/actions/teacher.js";
import actions from "juice-base/store/actions.js";

import useSnackbar from "juice-base/hooks/use-snackbar/index.js";
import useAccordion from "juice-base/hooks/use-accordion/index.js";

import IconCopyWithArrow from "juice-base/icons/copy-with-arrow/index.js";
import IconClipboardChecked from "juice-base/icons/clipboard-checked/index.js";
import IconLink from "juice-base/icons/link/index.js";

import PopupWindow from "juice-base/components/popup-window/index.js";
import Image from "juice-base/components/image/index.js";
import Accordion from "juice-base/components/accordion/index.js";
import CheckboxWithStates, {
    CheckboxWithStatesWrapper,
} from "juice-base/components/checkbox-with-states/index.js";
import ButtonBig from "juice-base/components/button-big/index.js";
import Checkbox from "juice-base/components/forms/checkbox/index.js";
import Message from "juice-base/components/message/index.js";
import Snackbar from "juice-base/components/snackbar/index.js";
import RequestLoader from "juice-base/components/request-loader/index.js";

import settings from "juice-app/settings.js";
import events from "juice-app/events.js";
import api from "juice-app/api.js";

import usePopupAssignHook from "./use-popup-assign.js";
import styles from "./styles.module.css";


const storeSelector = (state) => ({
    session: state.user.session,
    teacher: state.teacher,
    classesStudents: state.teacher.classesStudents,
});

const PopupAssign = (props) => {
    const [selectedStudents, setSelectedStudents] = useState([]);

    const [popupState, setPopupState] = useState({
        isConfirmed: false,
        isLoading: false,
        error: "",
        pathToAssignment: "",
    });

    /* ---- */

    const dispatch = useDispatch();
    const store = useSelector(storeSelector);
    const classesAccordion = useAccordion();
    const snackbar = useSnackbar();

    /* ---- */

    const getCheckboxWithStatesByClassIdData = (classId) => {
        const classes = store.classesStudents?.classes || [];

        const sClass = array.findByFieldName(classes, "classId", classId);

        const students = sClass?.students || [];
        let selectedStudentsCount = 0;

        for (let i = 0; i < students.length; i += 1) {
            const studentId = students?.[i]?.id || null;

            if (selectedStudents.indexOf(studentId) !== -1) {
                selectedStudentsCount += 1;
            }
        }

        const isChecked = students.length === selectedStudentsCount;

        return {
            isChecked: isChecked && selectedStudentsCount > 0,
            isHalfChecked: !isChecked && selectedStudentsCount > 0,
        };
    };

    /* ---- */

    const onSelectStudent = (studentId) => {
        setSelectedStudents((prev) => {
            if (prev.indexOf(studentId) === -1) {
                return [...prev].concat(studentId);
            }

            return [...prev].filter((id) => id !== studentId);
        });
    };

    const onToggleAllStudentsClass = (classId, students) => {
        const { isChecked } = getCheckboxWithStatesByClassIdData(classId);

        const studentIds = students.map((s) => s.id);

        if (isChecked) {
            const newStudents = [];

            for (let i = 0; i < selectedStudents.length; i += 1) {
                if (studentIds.indexOf(selectedStudents[i]) === -1) {
                    newStudents.push(selectedStudents[i]);
                }
            }

            setSelectedStudents(newStudents);
            return;
        }

        const newStudents = [
            ...selectedStudents,
            ...studentIds,
        ];

        const filteredStudents = [];

        for (let i = 0; i < newStudents.length; i += 1) {
            const s = newStudents[i];

            if (filteredStudents.indexOf(s) === -1) {
                filteredStudents.push(s);
            }
        }

        setSelectedStudents(filteredStudents);
    };

    const onConfirm = async () => {
        setPopupState((prev) => ({
            ...prev,
            isLoading: true,
            isConfirmed: true,
        }));

        const { story } = props;

        const storyId = story?.storyId || null;

        const reqParams = {
            session: store.session,
            storyId,
            quizId: story.quizId,
            questionId: story.questionId,
            studentsIds: selectedStudents.join(","),
        };

        const res = await api.assignments.createAssignment(reqParams);

        let path = "";
        let error = "";

        if (res.ok) {
            events.teacher.createAssignmentsOnSearch(reqParams);
            path = `/assignments/${res.groupCode}/${storyId}`;
        } else {
            error = res.error || text.error;
        }

        setPopupState((prev) => ({
            ...prev,
            error,
            pathToAssignment: path,
            isLoading: false,
        }));
    };

    const onCopyLink = () => {
        const siteUrl = urls.getSiteUrl();

        copyToClipboard(`${siteUrl}${popupState.pathToAssignment}`);

        snackbar.add({
            message: text.assignmentCopied,
        });
    };

    const onGoogleClassRoomClick = () => {
        const googleLink = Google.getGoogleClassroomShareLink(
            settings.google.classroomShareUrl,
            popupState.pathToAssignment,
            props?.story?.title || "",
        );

        urls.openUrl(googleLink);
    };

    const loadTeacherStudents = () => {
        dispatch(teacherActions.loadClassesStudents(
            { api, actions },
            { session: store.session },
        ));
    };

    /* ---- */

    useEffect(() => {
        loadTeacherStudents();
    }, []);

    /* ---- */

    const renderAssignTitle = () => {
        return (
            <div className={styles.title}>
                <IconCopyWithArrow
                    isOrange
                />
                <div>
                    Assign
                </div>
            </div>
        );
    };

    const renderStory = () => {
        const { story } = props;

        if (!story) {
            return null;
        }

        let storyDate = "";

        if (story.date) {
            storyDate = date.tryFormatDate(story.date, date.formatMonthDayYearDate);
        }

        let featuredImage = null;

        if (story.featuredImageSrc) {
            featuredImage = (
                <Image
                    className={styles.storyImage}
                    src={story.featuredImageSrc}
                    title={story.title}
                />
            );
        }

        const standards = story?.standards || [];
        const standardType = story.defaultStandardType || Standards.getCommonCoreValue();

        const standard = Standards.getStandardByType(standards, standardType);

        const storyClassName = classNames({
            [styles.story]: true,
            [styles.storyWithImage]: featuredImage,
        });

        return (
            <div className={storyClassName}>
                {featuredImage}

                <div className={styles.storyContent}>
                    <div className={styles.storyCategory}>
                        {story.category}
                    </div>
                    <div className={styles.storyTitle}>
                        {story.title}
                    </div>
                    <div className={styles.storyContentData}>
                        <div>
                            {storyDate}
                        </div>
                        <div>|</div>
                        <div>
                            {standard?.standard || ""}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderAssignedStudents = () => {
        const classes = (store.classesStudents?.classes || []).map((cl, index) => {
            const classStudents = cl?.students || [];

            const studentsList = [];

            for (let i = 0; i < classStudents.length; i += 1) {
                const s = classStudents[i];

                if (selectedStudents.indexOf(s.id) !== -1) {
                    studentsList.push(s.fullName);
                }
            }

            const blockClassName = classNames({
                [styles.assignBlockClass]: true,
                [styles.assignBlockClassWithBorderTop]: index > 0,
            });

            let students = "";

            if (studentsList.length > 0 && studentsList.length === classStudents.length) {
                students = "All Students";
            } else if (studentsList.length === 0) {
                students = "No students selected";
            } else {
                students = studentsList.join(", ");
            }

            return (
                <div className={blockClassName}>
                    <div>
                        {cl.className}
                    </div>
                    <div>
                        {students}
                    </div>
                </div>
            );
        });

        return (
            <div className={styles.assignBlock}>
                <div className={styles.assignBlockTitle}>
                    Assigned Students
                </div>
                {classes}
            </div>
        );
    };

    const renderAssignStatus = () => {
        if (popupState.isLoading) {
            return (
                <RequestLoader />
            );
        }

        if (popupState.error) {
            return (
                <Message>
                    {popupState.error}
                </Message>
            );
        }

        const status = (
            <div className={styles.assignStatus}>
                <IconClipboardChecked
                    className={styles.assignStatusIcon}
                    isGreen
                />
                <div>
                    {text.assignmentCreated}
                </div>
            </div>
        );

        const storyDetails = (
            <div className={styles.assignBlock}>
                <div className={styles.assignBlockTitle}>
                    Story Details
                </div>
                {renderStory()}
            </div>
        );

        return (
            <div className={styles.assign}>
                {status}
                <div className={styles.assignContent}>
                    {storyDetails}
                    {renderAssignedStudents()}
                </div>
            </div>
        );
    };

    const renderClassesList = () => {
        if (store.classesStudents?.isLoading) {
            return (
                <RequestLoader />
            );
        }

        const teacherClasses = store.classesStudents?.classes || [];

        const classes = teacherClasses.map((cl) => {
            const sClass = array.findByFieldName(teacherClasses, "classId", cl.classId);
            const classStudents = sClass?.students || [];

            if (classStudents.length === 0) {
                return null;
            }

            const students = classStudents.map((st, i) => {
                return (
                    <div className={styles.row}>
                        <Checkbox
                            name={`${st.fullName}-${i}`}
                            label={st.fullName}
                            onChange={() => {
                                onSelectStudent(st.id);
                            }}
                            checked={selectedStudents.indexOf(st.id) !== -1}
                        />
                    </div>
                );
            });

            const checkboxWithStateData = getCheckboxWithStatesByClassIdData(cl.classId);

            const classLabel = (
                <CheckboxWithStatesWrapper
                    label={cl.className}
                    label2={cl.classGrades.join(", ")}
                    onClick={(evt) => {
                        evt.stopPropagation();
                        onToggleAllStudentsClass(cl.classId, classStudents);
                    }}
                >
                    <CheckboxWithStates
                        name={`class-${cl.classId}`}
                        label={cl.className}
                        isChecked={checkboxWithStateData.isChecked}
                        isHalfChecked={checkboxWithStateData.isHalfChecked}
                    />
                </CheckboxWithStatesWrapper>
            );

            return (
                <div className={styles.class}>
                    <Accordion
                        label={classLabel}
                        className={styles.accordion}
                        onClick={() => {
                            classesAccordion.onSetExpanded(cl.classId);
                        }}
                        expanded={classesAccordion.isExpanded(cl.classId)}
                        withContentPadding={false}
                    >
                        <div className={styles.rows}>
                            {students}
                        </div>
                    </Accordion>
                </div>
            );
        });

        if (classes.length === 0) {
            return (
                <Message>
                    {text.noClassesWithStudents}
                </Message>
            );
        }

        return (
            <div className={styles.classes}>
                {classes}
            </div>
        );
    };

    const renderPopupContent = () => {
        if (popupState.isConfirmed) {
            return renderAssignStatus();
        }

        return (
            <>
                <div className={styles.storyWithBorderBottom}>
                    {renderStory()}
                </div>
                {renderClassesList()}
            </>
        );
    };

    const renderGoogleClassRoomButton = () => {
        return (
            <ButtonBig
                onClick={onGoogleClassRoomClick}
                isGreenTheme
                isDefaultBorder
                isRounded
                outlined
            >
                <div className={styles.googleClassroomButton}>
                    <img
                        src={staticFiles.googleClassroom32}
                        alt="Google Classroom"
                    />
                    <div>
                        Google Classroom
                    </div>
                </div>
            </ButtonBig>
        );
    };

    const renderCopyLinkButton = () => {
        return (
            <ButtonBig
                icon={IconLink}
                onClick={onCopyLink}
                isDefaultBorder
                isRounded
                outlined
            >
                Copy Link
            </ButtonBig>
        );
    };

    const renderCloseButton = () => {
        return (
            <ButtonBig
                onClick={props.onClose}
                isRounded
            >
                Close
            </ButtonBig>
        );
    };

    const renderControls = () => {
        const { error, isConfirmed, isLoading } = popupState;

        const controls = [];

        if (isConfirmed && !isLoading) {
            if (!error) {
                controls.push(
                    <div className={styles.linksButtons}>
                        {renderGoogleClassRoomButton()}
                        {renderCopyLinkButton()}
                    </div>,
                );
            }

            controls.push(renderCloseButton());
        } else {
            controls.push(
                <ButtonBig
                    onClick={onConfirm}
                    disabled={selectedStudents.length === 0 || isLoading}
                    isRounded
                >
                    Confirm
                </ButtonBig>,
            );
        }

        return (
            <div className={styles.controls}>
                {controls}
            </div>
        );
    };

    const renderContent = () => {
        return (
            <>
                {renderPopupContent()}
                {renderControls()}
            </>
        );
    };

    const renderSnackbar = () => {
        return snackbar.state.map((bar) => {
            return (
                <Snackbar
                    message={bar.message}
                />
            );
        });
    };

    return (
        <>
            {renderSnackbar()}
            <PopupWindow
                title={renderAssignTitle()}
                barClassName={styles.popupBar}
                onClose={props.onClose}
                isCentered
                withTitleBarShadow={false}
            >
                {renderContent()}
            </PopupWindow>
        </>
    );
};

PopupAssign.defaultProps = {
    story: null,
    onClose: () => { },
};

export const usePopupAssign = usePopupAssignHook;
export default PopupAssign;
