import document from "juice-base/lib/document.js";
import { isDarkTheme } from "juice-base/lib/device.js";
import Theme from "juice-base/project/theme.js";


const initTheme = (services) => {
    const { actions, storage } = services;

    return (dispatch) => {
        let isAutomatic = storage.local.loadAutomaticTheme();

        if (isAutomatic === undefined) {
            isAutomatic = true;
        }

        const isDark = isDarkTheme();
        const themes = Theme.getThemes();

        let theme = "";

        if (isAutomatic) {
            theme = isDark ? themes.DARK : themes.LIGHT;
        } else {
            theme = storage.local.loadTheme() || Theme.getDefaultTheme();
        }

        dispatch(actions.app.setAutomaticTheme({
            isAutomatic,
        }));

        dispatch(actions.app.setTheme({
            theme,
        }));

        if (!isAutomatic) {
            document.setTheme(theme);
            return;
        }

        if (isDark) {
            document.setTheme(themes.DARK);
            return;
        }

        document.setTheme(themes.LIGHT);
    };
};

const setAutomaticTheme = (services, params) => {
    const { actions, storage } = services;

    return (dispatch) => {
        dispatch(actions.app.setAutomaticTheme({
            isAutomatic: params.isAutomatic,
        }));

        storage.local.saveAutomaticTheme(params.isAutomatic);

        if (!params.isAutomatic) {
            return;
        }

        const themes = Theme.getThemes();
        const isDark = isDarkTheme();

        const theme = isDark
            ? themes.DARK
            : themes.LIGHT;

        dispatch(actions.app.setTheme({
            theme,
        }));

        document.setTheme(theme);
        storage.local.saveTheme(theme);
    };
};

const setTheme = (services, params) => {
    const { actions, storage } = services;

    return (dispatch) => {
        dispatch(actions.app.setAutomaticTheme({
            isAutomatic: false,
        }));

        dispatch(actions.app.setTheme({
            theme: params.theme,
        }));

        storage.local.saveAutomaticTheme(false);
        storage.local.saveTheme(params.theme);

        document.setTheme(params.theme);
    };
};

export default {
    initTheme,
    setAutomaticTheme,
    setTheme,
};
