import React from "react";

import classNames from "juice-base/lib/class-names.js";

import IconArrowBold from "juice-base/icons/arrow-bold/index.js";

import styles from "./styles.module.css";


const TableSortableColumnName = (props) => {
    const arrowClassName = classNames({
        [styles.sortIcon]: true,
        [styles.sortIconDown]: props.isDown,
    });

    return (
        <div
            className={styles.columnName}
            onClick={props.onClick}
            onKeyPress={props.onClick}
            tabIndex="-1"
            role="button"
        >
            <div>
                {props.name}
            </div>
            <IconArrowBold
                className={arrowClassName}
                title="Sort"
                isBlack
            />
        </div>
    );
};

TableSortableColumnName.defaultProps = {
    name: "",
    isDown: false,
    onClick: () => { },
};

export default TableSortableColumnName;
