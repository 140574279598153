import React from "react";

import IconWrapper from "juice-base/icons/wrapper/index.js";


const IconEmojiSad = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    width="230"
                    height="183"
                    viewBox="0 0 230 183"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={iconProps.className}
                >
                    {iconProps.title}
                    <path
                        fill="#2EBBFF"
                        d="M206.891 19.3096H0.9375V164.59H206.891V19.3096Z"
                    />
                    <path
                        fill="#000000"
                        d="M126.013 63.1064H149.257V87.0531H126.013V63.1064ZM67.2109 63.1064H90.6036V87.0531H67.2109V63.1064Z"
                    />
                    <path
                        fill="#000000"
                        d="M53.927 133.874C64.0654 125.432 75.6205 118.854 88.0556 114.446C100.365 110.015 113.349 107.749 126.432 107.748C138.929 107.742 151.333 109.895 163.097 114.113C175.28 118.501 186.507 125.188 196.166 133.812V149.325C185.469 142.862 173.965 137.84 161.952 134.391C150.292 131.094 138.229 129.436 126.112 129.466C113.778 129.465 101.5 131.121 89.6069 134.391C77.1237 137.845 65.1271 142.863 53.9023 149.325L53.927 133.874Z"
                    />
                    <path
                        fill="#000000"
                        d="M0.814453 182.456V0.313477H49.6804V19.4092H25.4752V163.791H49.6804V182.456H0.814453Z"
                    />
                    <path
                        fill="#000000"
                        d="M181.008 182.456V163.791H205.25V19.4092H181.008V0.313477H229.923V182.456H181.008Z"
                    />
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconEmojiSad;
