// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rlOXT1Z8C8Kcb1wNTxjT {\n    margin: 1rem 0 0 0;\n}\n", "",{"version":3,"sources":["webpack://./app/views/vocabulary-word/styles.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB","sourcesContent":[".word {\n    margin: 1rem 0 0 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"word": "rlOXT1Z8C8Kcb1wNTxjT"
};
export default ___CSS_LOADER_EXPORT___;
