import React from "react";

import IconWrapper from "juice-base/icons/wrapper/index.js";


const IconClassPerformance = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={iconProps.className}
                >
                    <path
                        fill="currentColor"
                        d="M9 0V20C3.93 19.5 0 15.21 0 10C0 4.79 3.93 0.5 9 0ZM11.03 0V8.99H20C19.53 4.25 15.76 0.47 11.03 0ZM11.03 11.01V20C15.77 19.53 19.53 15.75 20 11.01H11.03Z"
                    />
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconClassPerformance;
