import React from "react";

import classNames from "juice-base/lib/class-names.js";

import dailyJuiceCommonStyles from "juice-base/business/daily-juice-common/styles.module.css";
import styles from "./styles.module.css";


const Card = (props) => {
    const cardClassName = classNames({
        [styles.card]: true,
        [dailyJuiceCommonStyles.blockShadow]: true,
    });

    const features = props.features.map((f) => {
        return (
            <li>{f}</li>
        );
    });

    return (
        <div className={cardClassName}>
            <div className={styles.cardPrice}>
                <div>{`$${props.price}`}</div>
                <div>{props.billingCycle}</div>
            </div>
            <ul>
                {features}
            </ul>
        </div>
    );
};

Card.defaultProps = {
    price: "",
    billingCycle: "",
    features: [],
};

export default Card;
