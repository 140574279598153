import React from "react";

import classNames from "juice-base/lib/class-names.js";

import styles from "./styles.module.css";


const ButtonTogglerIcon = (props) => {
    const renderButtons = () => {
        return props.buttons.map((btn) => {
            const btnClassName = classNames({
                [styles.toggler]: true,
                [styles.togglerSelected]: props.selected === btn.value,
            });

            return (
                <div
                    key={btn.value}
                    className={btnClassName}
                    onClick={() => {
                        props.onSelect(btn.value);
                    }}
                    onKeyPress={() => {
                        props.onSelect(btn.value);
                    }}
                    role="button"
                    tabIndex="-1"
                >
                    {btn.icon}
                </div>
            );
        });
    };

    return (
        <div className={styles.togglers}>
            {renderButtons()}
        </div>
    );
};

ButtonTogglerIcon.defaultProps = {
    buttons: [],
    selected: "",
    onSelect: () => { },
};

export default ButtonTogglerIcon;
