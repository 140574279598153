import React, { useEffect, useState } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useSelector, useDispatch } from "react-redux";

import staticFiles from "juice-base/static-files.js";

import Geo from "juice-base/project/geo.js";

import urls from "juice-base/lib/urls.js";

import actions from "juice-base/store/actions.js";
import actionsGeo from "juice-base/actions/geo.js";

import IconEmojiSad from "juice-base/icons/emoji-sad/index.js";

import ButtonBig from "juice-base/components/button-big/index.js";

import UserCheckoutForm from "juice-base/forms/user-checkout/index.js";

import settings from "juice-app/settings.js";
import api from "juice-app/api.js";

import styles from "./styles.module.css";


const storeSelector = (state) => ({
    theme: state.app.theme.theme,
    session: state.user.session,
    subdivisionsByAlpha2: state.geo.subdivisionsByAlpha2,
});

const getPaymentState = () => ({
    isLoading: false,
    isLoaded: false,
    error: "",
});

const UserPaymentDetailsChange = (props) => {
    const [paymentState, setPaymentState] = useState(() => getPaymentState());

    const store = useSelector(storeSelector);
    const elements = useElements();
    const stripe = useStripe();
    const dispatch = useDispatch();

    /* ----- */

    const loadSubdivisionsByAlpha2 = (alpha2) => {
        dispatch(actionsGeo.loadSubdivisionsByAlpha2({ api, actions }, {
            session: store.session,
            alpha2,
        }));
    };

    /* ----- */

    useEffect(() => {
        loadSubdivisionsByAlpha2(Geo.DEFAULT_ALPHA_2);
    }, []);

    /* ----- */

    const onTryAgain = () => {
        setPaymentState(getPaymentState());
    };

    const onContactSupport = () => {
        const supportLink = [
            settings.landingSite.domain,
            settings.landingSite.routeSupport,
        ].join("");

        urls.openUrl(supportLink);
    };

    const onSubmit = async (values) => {
        setPaymentState((prev) => ({
            ...prev,
            isLoading: true,
            isLoaded: false,
            error: "",
        }));

        const cardElement = elements.getElement(CardElement);

        const billingDetails = {
            name: values.name,
            address: {
                line1: values.line1,
                line2: values.line2,
                city: values.city,
                state: values.state.value,
                postal_code: values.zip,
            },
        };

        const createPaymentMethodRes = await stripe.createPaymentMethod({
            type: "card",
            card: cardElement,
            billing_details: billingDetails,
        });

        let error = "";

        if (createPaymentMethodRes.error) {
            error = createPaymentMethodRes.error.message || "Error!";
        } else {
            const attachPaymentMethodRes = await api.subscription.paymentAttachPaymentMethod({
                session: store.session,
                paymentMethodId: createPaymentMethodRes.paymentMethod.id,
            });

            if (attachPaymentMethodRes.ok) {
                props.onLoadUserPaymentDetails();
            } else {
                error = attachPaymentMethodRes.error || "Error";
            }
        }

        setPaymentState((prev) => ({
            ...prev,
            isLoading: false,
            isLoaded: true,
            error,
        }));
    };

    /* ---- */

    const getSubdivisions = () => {
        const subdivisions = store.subdivisionsByAlpha2[Geo.DEFAULT_ALPHA_2]?.subdivisions || [];

        return subdivisions.map((div) => ({
            value: div.code,
            name: div.name,
            name2: div.code,
        })).concat({
            value: "Other",
            name: "Other",
        });
    };

    const getSubmitLabel = () => {
        if (props.isAddPaymentDetails) {
            return "Add payment details";
        }

        return "Save payment details";
    };

    /* ---- */

    const renderStatus = () => {
        let image = null;
        let title = "";
        let description = "";
        let controls = null;

        if (!paymentState.error) {
            image = (
                <img
                    src={staticFiles.iconCardSuccessBlue}
                    alt="Success"
                />
            );

            title = "Your payment details were updated";

            description = "Check your email for more details.";

            controls = (
                <ButtonBig
                    onClick={props.onBackToManagement}
                >
                    Back to account management
                </ButtonBig>
            );
        } else {
            title = "We are sorry, but the card details were not changed. Please contact support or try again.";

            image = (
                <IconEmojiSad
                    className={styles.statusEmoji}
                    title="Failed"
                    isBlack
                />
            );

            controls = [
                <ButtonBig onClick={onTryAgain}>
                    Try again
                </ButtonBig>,
                <ButtonBig onClick={onContactSupport}>
                    Contact support
                </ButtonBig>,
            ];
        }

        return (
            <div className={styles.statusContainer}>
                <div className={styles.status}>
                    {image}
                    <div className={styles.textBig}>
                        {title}
                    </div>
                    <div className={styles.textGrey}>
                        {description}
                    </div>
                </div>
                {controls}
            </div>
        );
    };

    const renderTopMessage = () => {
        if (paymentState.isLoaded) {
            return null;
        }

        if (props.isAddPaymentDetails) {
            return (
                <div className={styles.addPaymentSection}>
                    <div>It looks like you haven&apos;t added payment details yet.</div>
                    <div>Add a card to subscribe to The Juice.</div>
                </div>
            );
        }

        return (
            <div className={styles.addPaymentSection}>
                <div>Enter your new card information here.</div>
                <div>This will become your default payment method.</div>
            </div>
        );
    };

    const initialValues = {
        name: "",
        line1: "",
        line2: "",
        city: "",
        state: "",
        zip: "",
    };

    if (paymentState.isLoaded) {
        return renderStatus();
    }

    return (
        <div className={styles.content}>
            {renderTopMessage()}
            <div className={styles.form}>
                <UserCheckoutForm
                    theme={store.theme}
                    withAutoRenew={false}
                    showContactSupportButton={props.showContactSupportButton}
                    submitButtonLabel={getSubmitLabel()}
                    initialValues={initialValues}
                    states={getSubdivisions()}
                    onContactSupport={props.onContactSupport}
                    onSubmit={onSubmit}
                />
            </div>
        </div>
    );
};

UserPaymentDetailsChange.defaultProps = {
    isAddPaymentDetails: false,
    showContactSupportButton: true,
    onLoadUserPaymentDetails: () => { },
    onContactSupport: () => { },
    onBackToManagement: () => { },
    onClose: () => { },
};

export default UserPaymentDetailsChange;
