import React from "react";

import classNames from "juice-base/lib/class-names.js";
import symbols from "juice-base/lib/symbols.js";

import styles from "./styles.module.css";


const Chip = (props) => {
    const chipClassName = classNames({
        [styles.chip]: true,
        [styles.chipSelected]: props.selected && !props.disabled,
        [styles.chipDisabled]: props.disabled,

        [styles.chipRose]: props.isRoseTheme,
        [styles.chipRoseSelected]: props.isRoseTheme && props.selected,

        [styles.chipPumpkin]: props.isPumpkinTheme,
        [styles.chipPumpkinSelected]: props.isPumpkinTheme && props.selected,

        [styles.chipSmall]: props.isSmall,

        [styles.cursorPointer]: props.withCursorPointer,
    });

    return (
        <div
            className={chipClassName}
            onKeyPress={props.onClick}
            onClick={props.onClick}
            role="button"
            tabIndex="-1"
        >
            {props.label || symbols.nbsp}
        </div>
    );
};

Chip.defaultProps = {
    label: "",
    selected: false,
    disabled: false,
    isRoseTheme: false,
    isPumpkinTheme: false,
    isSmall: false,
    withCursorPointer: true,
    onClick: () => { },
};

export default Chip;
