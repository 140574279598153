import React from "react";

import IconWrapper from "juice-base/icons/wrapper/index.js";


const IconGraph = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    className={iconProps.className}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill="currentColor"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M1 5C1 3.89543 1.89543 3 3 3H21C22.1046 3 23 3.89543 23 5V19C23 20.1046 22.1046 21 21 21H3C1.89543 21 1 20.1046 1 19V5ZM21 5H3V19H21V5ZM7 8V17H5V8H7ZM19 8V17H17V8H19ZM11 10V17H9V10H11ZM15 14V17H13V14H15Z"
                    />
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconGraph;
