import React from "react";

import IconWrapper from "juice-base/icons/wrapper/index.js";


const IconCheck = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 49 49"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={iconProps.className}
                >
                    <path
                        fill="currentColor"
                        d="M17.9559 32.4115L9.44213 23.8978L6.54297 26.7765L17.9559 38.1894L42.4559 13.6894L39.5771 10.8107L17.9559 32.4115Z"
                    />
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconCheck;
