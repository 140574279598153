import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import text from "juice-base/text/index.js";

import classNames from "juice-base/lib/class-names.js";

import Theme from "juice-base/project/theme.js";
import actions from "juice-base/store/actions.js";

import RequestLoader from "juice-base/components/request-loader/index.js";
import PopupWindow from "juice-base/components/popup-window/index.js";
import ButtonBig from "juice-base/components/button-big/index.js";
import Tabs from "juice-base/components/tabs/index.js";
import TabsScrolling from "juice-base/components/tabs-scrolling/index.js";

import TextEditor, { TEXT_EDITOR_THEMES } from "juice-base/components/forms/text-editor/index.js";

import api from "juice-app/api.js";

import useAnnouncementPopupHook from "./use-announcement-popup.js";
import styles from "./styles.module.css";


const storeSelector = (state) => ({
    theme: state.app.theme.theme,
    session: state.user.session,
    teacher: state.teacher,
});

const TeacherPopupAnnouncement = (props) => {
    const [selectedClass, setSelectedClass] = useState(() => {
        if (props.defaultClass) {
            return props.defaultClass;
        }

        return null;
    });

    /* ----- */

    const dispatch = useDispatch();
    const store = useSelector(storeSelector);

    /* ----- */

    const isAllContentsSame = (announcement) => {
        const firstContent = announcement?.contents?.[0] || "";

        for (let i = 0; i < announcement?.contents.length; i += 1) {
            const aContent = announcement?.contents[i] || "";

            if (aContent !== firstContent) {
                return false;
            }
        }

        return true;
    };

    /* -------- */

    const getTabs = () => {
        const tabs = (store.teacher.classes || []).map((cl) => ({
            value: cl.id,
            label: cl.title,
        }));

        if (tabs.length >= 2) {
            tabs.push({
                value: "all",
                label: "all",
            });
        }

        return tabs;
    };

    const getAnnouncementByClassId = (classId) => {
        const data = store.teacher?.classesAnnouncementsById?.[classId] || {};

        return {
            isLoading: data.isLoading || false,
            isSaving: data.isSaving || false,
            data: data.data || null,
            error: data.error || "",
        };
    };

    /* -------- */

    const onLoadClassAnnouncement = async (classId) => {
        dispatch(actions.teacher.setTeacherAnnouncementsByIdLoading({
            classId,
        }));

        if (classId === "all") {
            const resAll = await api.classes.getAllClassesAnnouncementsByTeacher({
                session: store.session,
            });

            let allContents = [];
            let error = "";

            if (resAll.ok) {
                allContents = (resAll.data || []).map((cl) => cl.announcementContent);
            } else {
                error = resAll.error || text.error;
            }

            dispatch(actions.teacher.setTeacherAnnouncementsById({
                classId,
                data: {
                    contents: allContents,
                },
                error,
            }));
            return;
        }

        const res = await api.classes.getClassAnnouncement({
            classId,
        });

        let data = null;
        let error = "";

        if (res.ok) {
            data = res.data;
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.teacher.setTeacherAnnouncementsById({
            classId,
            data,
            error,
        }));
    };

    /* -------- */

    const onSaveText = async (params) => {
        dispatch(actions.teacher.setTeacherAnnouncementByIdSaving({
            classId: selectedClass,
            isSaving: true,
            error: "",
        }));

        const content = params?.data?.announcementContent || "";
        const title = params?.data?.announcementTitle || "";

        const updateApi = selectedClass === "all"
            ? api.classes.updateAllTeacherClassesAnnouncements
            : api.classes.updateClassAnnouncement;

        const res = await updateApi({
            session: store.session,
            classId: selectedClass,
            content,
            title,
        });

        let error = "";

        if (res.ok) {
            onLoadClassAnnouncement(selectedClass);
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.teacher.setTeacherAnnouncementByIdSaving({
            classId: selectedClass,
            isSaving: false,
            error,
        }));
    };

    const onClassChange = (classId) => {
        onLoadClassAnnouncement(classId);

        setSelectedClass(classId);
    };

    const onAnnouncementChange = (contentText, size) => {
        dispatch(actions.teacher.setTeacherAnnouncementContentById({
            classId: selectedClass,
            text: contentText,
            size,
        }));
    };

    /* -------- */

    useEffect(() => {
        if (props.defaultClass) {
            onClassChange(props.defaultClass);
            return;
        }

        const tabs = getTabs();
        const classId = tabs?.[0]?.value || null;

        onClassChange(classId);
    }, []);

    /* -------- */

    const renderError = (error) => {
        if (error) {
            return (
                <div className={styles.errorMessage}>
                    {error}
                </div>
            );
        }

        return <div />;
    };

    const renderTextEditor = (announcement) => {
        let rewriteMessage = null;
        let defaultValue = announcement?.announcementContent || "";

        if (selectedClass === "all") {
            if (isAllContentsSame(announcement)) {
                defaultValue = announcement?.contents?.[0] || "";
            } else {
                rewriteMessage = (
                    <div className={styles.rewriteMessage}>
                        Some announcements have already been written.
                        Saving this tab will overwrite all announcements.
                    </div>
                );
            }
        }

        const editorTheme = store.theme === Theme.getThemes().DARK
            ? TEXT_EDITOR_THEMES.DARK
            : TEXT_EDITOR_THEMES.LIGHT;

        return (
            <div>
                <TextEditor
                    label=""
                    name="announcementText"
                    defaultValue={defaultValue}
                    theme={editorTheme}
                    onChange={onAnnouncementChange}
                />
                {rewriteMessage}
            </div>
        );
    };

    const renderMaxTextMessage = (isLimitReached, textSize) => {
        const textLimitClassName = classNames({
            [styles.limitMessage]: isLimitReached,
        });

        return (
            <div className={textLimitClassName}>
                {`${textSize}/${props.maxTextCharacters} characters`}
            </div>
        );
    };

    const renderControl = (announcement) => {
        const textSize = announcement?.data?.announcementContentSize || 0;

        const isTextLimitReached = textSize > props.maxTextCharacters;
        const isSaving = announcement?.isSaving || false;

        const error = announcement?.error || "";

        return (
            <div className={styles.controls}>
                {renderMaxTextMessage(isTextLimitReached, textSize)}
                {renderError(error)}
                <ButtonBig
                    onClick={() => {
                        onSaveText(announcement);
                    }}
                    disabled={isTextLimitReached || isSaving}
                >
                    {isSaving ? "Saving..." : "Save"}
                </ButtonBig>
            </div>
        );
    };

    const renderContent = () => {
        const announcement = getAnnouncementByClassId(selectedClass);

        if (announcement.isLoading || !announcement.data) {
            return (
                <RequestLoader />
            );
        }

        return (
            <div className={styles.content}>
                {renderTextEditor(announcement.data)}
                {renderControl(announcement)}
            </div>
        );
    };

    const renderTabs = () => {
        const tabs = getTabs();

        if (props.isMobile || tabs.length > 4) {
            return (
                <div className={styles.tabsScrolling}>
                    <TabsScrolling
                        selected={selectedClass}
                        tabs={tabs}
                        onChange={onClassChange}
                    />
                </div>
            );
        }

        return (
            <Tabs
                onlyTabs
                tabs={tabs}
                selectedTab={selectedClass}
                onChange={onClassChange}
            />
        );
    };

    const renderPopupContent = () => {
        return (
            <div className={styles.announcementContent}>
                <div className={styles.aboutAnnouncementList}>
                    <div>
                        About the announcement:
                    </div>
                    <ul>
                        <li>
                            An announcement will display every day until
                            you edit or delete it.
                        </li>
                        <li>
                            Your announcement will be displayed on the second screen
                            of the Daily Juice.
                        </li>
                        <li>
                            Each class can have their own separate announcement,
                            or you can send one announcement to all your classes at the same time.
                        </li>
                    </ul>
                </div>
                {renderTabs()}
                {renderContent()}
            </div>
        );
    };

    /* -------- */

    // TODO: showConfirmOnClose
    return (
        <PopupWindow
            onClose={props.onClose}
            title="Class Announcement"
        >
            {renderPopupContent()}
        </PopupWindow>
    );
};

TeacherPopupAnnouncement.defaultProps = {
    defaultClass: null,
    maxTextCharacters: 300,
    onClose: () => { },
};

export const useAnnouncementPopup = useAnnouncementPopupHook;
export default TeacherPopupAnnouncement;
