import React from "react";
import { useSelector } from "react-redux";

import useTitle from "juice-base/hooks/use-title/index.js";

import { withAuth } from "juice-base/components/auth/index.js";
import AccountSettings from "juice-base/components/account-settings/index.js";
import LayoutContent from "juice-base/components/layout-content/index.js";

import settings from "juice-app/settings.js";

import styles from "./styles.module.css";


const Profile = () => {
    const store = useSelector((state) => ({
        user: state.user.user,
    }));

    useTitle(() => "Profile", []);

    const accSettings = [
        {
            title: "Username",
            value: store.user.userName,
        },
        {
            title: "Email",
            value: store.user.email,
        },
    ];

    const isLMS = settings.features.LMS && store.user.isLmsUser;

    return (
        <LayoutContent>
            <div className={styles.profile}>
                <AccountSettings
                    settings={accSettings}
                    isLMS={isLMS}
                />
            </div>
        </LayoutContent>
    );
};

export default withAuth([
    "teacher",
    "student",
    "guardian",
])(Profile);
