import { useState } from "react";


const initialState = {
    isOpen: false,
    isExtraJuiceView: false,
    id: null,
    isAutoPlay: false,
    playerState: {
        isOpen: false,
        trackGroupName: "",
        trackId: "",
    },
};

const useJuiceStorySearch = () => {
    const [state, setState] = useState({
        ...initialState,
    });

    const open = (id) => {
        setState((prev) => ({
            ...prev,
            isOpen: true,
            id,
        }));
    };

    const openWithAutoPlay = (id) => {
        setState((prev) => ({
            ...prev,
            isOpen: true,
            id,
            isAutoPlay: true,
        }));
    };

    const openPlayer = (trackGroupName, trackId) => {
        setState((prev) => ({
            ...prev,
            playerState: {
                ...prev.playerState,
                isOpen: true,
                trackGroupName,
                trackId,
            },
        }));
    };

    const closePlayer = () => {
        setState((prev) => ({
            ...prev,
            playerState: {
                ...prev.playerState,
                isOpen: false,
                trackGroupName: "",
                trackId: "",
            },
        }));
    };

    const openExtraJuice = () => {
        setState((prev) => ({
            ...prev,
            isExtraJuiceView: true,
            playerState: {
                ...initialState.playerState,
            },
        }));
    };

    const closeExtraJuice = () => {
        setState((prev) => ({
            ...prev,
            isExtraJuiceView: false,
            playerState: {
                ...initialState.playerState,
            },
        }));
    };

    const close = () => {
        setState({
            ...initialState,
        });
    };

    return {
        state,
        open,
        openWithAutoPlay,
        openPlayer,
        closePlayer,
        openExtraJuice,
        closeExtraJuice,
        close,
    };
};

export default useJuiceStorySearch;
