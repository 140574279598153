import React, { useEffect } from "react";
import { createRoot } from "react-dom/client"; // eslint-disable-line import/extensions

import configureStore from "juice-base/store/index.js";
import actions from "juice-base/store/actions.js";
import actionsPopups from "juice-base/actions/popups.js";

import interval from "juice-base/workers/interval.js";

import EventsMonitor from "./monitors/events.js";
import configureRoutes from "./routes/index.js";
import { preInit, postInit } from "./init.js";
import api from "./api.js";


const createEventsMonitor = (store) => {
    const sseEventsURL = SSE_EVENTS_URL; // eslint-disable-line no-undef

    if (!sseEventsURL) {
        console.log("[SSE]: No events URL"); // eslint-disable-line no-console
        return null;
    }

    const eventsMonitor = new EventsMonitor(sseEventsURL, store);

    return eventsMonitor;
};

const AppWithCallbackAfterRender = (props) => {
    useEffect(() => {
        postInit();
    }, []);

    return props.app;
};

const main = () => {
    const store = configureStore();

    const eventsMonitor = createEventsMonitor(store);
    globalThis.eventsMonitor = eventsMonitor;

    const routes = configureRoutes({
        store,
        actions,
        api,
    });

    preInit(store);

    interval.start(() => {
        store.dispatch(actionsPopups.checkPopupsStates({
            actions,
        }));
    });

    const rootElement = document.getElementById("app");
    const root = createRoot(rootElement);

    root.render(<AppWithCallbackAfterRender app={routes} />);
};

main();
