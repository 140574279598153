import User from "juice-base/project/user.js";
import text from "juice-base/text/index.js";


const saveUserId = (services, params) => {
    const { storage } = services;

    return () => {
        const userIds = storage.local.loadUserIds();

        if (userIds.indexOf(params.userId) !== -1) {
            return;
        }

        userIds.push(params.userId);
        storage.local.saveUserIds(userIds);
    };
};

const saveUserIdAndReport = (services, params) => {
    const { storage, api } = services;

    return (dispatch, getState) => {
        const userIds = storage.local.loadUserIds();

        if (userIds.indexOf(params.userId) !== -1) {
            return;
        }

        userIds.push(params.userId);
        storage.local.saveUserIds(userIds);

        const state = getState();
        const session = state.user.session || "";

        api.user.newLocation({ session });
    };
};

const signUpByReferralCode = (services, params) => {
    const {
        actions,
        api,
        storage,
        onError,
        onReady,
    } = services;

    return async (dispatch) => {
        const res = await api.signup.signUpByReferralCode({
            ...params.values,
            code: params.referralCode,
            role: params.role,
        });

        if (!res.ok) {
            onError(res.error || text.error);
            return;
        }

        if (params.values.isRememberMe) {
            storage.local.saveSession(res.sessionId ?? "");
            storage.session.saveSession("");
        } else {
            storage.local.saveSession("");
            storage.session.saveSession(res.sessionId ?? "");
        }

        dispatch(actions.user.setUserSession(res.sessionId || ""));
        dispatch(actions.user.setUser(res.user));

        const userId = res?.user?.userId || "";

        dispatch(saveUserId({ storage }, {
            userId,
        }));

        onReady();
    };
};

const loadSession = (services, params) => {
    const { api, actions } = services;

    return async (dispatch) => {
        const res = await api.user.checkSession({
            session: params.session,
        });

        if (res.ok) {
            dispatch(actions.user.setUser(res.user));
        }
    };
};

const loadUserOptions = (services, params) => {
    return async (dispatch) => {
        const res = await services.api.user.getOptions({
            session: params.session,
        });

        const options = res.ok ? res.data || [] : [];

        dispatch(services.actions.user.setUserOptions({
            options: User.optionsArrToObj(options),
        }));
    };
};

const loadUser = (services) => {
    const { actions, storage, api } = services;

    return async (dispatch) => {
        let userSession = storage.session.loadSession();

        if (!userSession) {
            userSession = storage.local.loadSession();
        }

        if (!userSession) {
            dispatch(actions.user.setUserLoading(false));
            return;
        }

        dispatch(actions.user.setUserSession(userSession));

        const userRes = await api.user.checkSession({
            session: userSession,
        });

        if (!userRes.ok) {
            dispatch(actions.user.setUserLoading(false));

            storage.local.clearSession();
            services.onGoToSignIn();
            return;
        }

        dispatch(actions.user.setUser(userRes.user));

        dispatch(loadUserOptions({
            actions,
            api,
        }, {
            session: userSession,
        }));

        if (services.onSuccess) {
            services.onSuccess({
                session: userSession,
                isTeacher: User.hasRoleTeacher(userRes.user),
            });
        }
    };
};

const signOut = (services, params) => {
    const {
        events,
        api,
        storage,
        actions,
    } = services;

    return (dispatch) => {
        events.user.signOut({
            session: params.session,
            location: params.location,
        });

        api.user.signOut({
            session: params.session,
        });

        storage.local.clearSession();
        storage.local.clearTeacherSelectedClass();

        dispatch(actions.clearAfterSignOut());

        services.goToSignIn();
    };
};

const openTeacherWelcomePopup = (services) => {
    const { actions } = services;

    return (dispatch) => {
        dispatch(actions.user.setTeacherWelcomePopupOpened());
    };
};

const closeTeacherWelcomePopup = (services) => {
    const { actions } = services;

    return (dispatch) => {
        dispatch(actions.user.setTeacherWelcomePopupClosed());
    };
};

export default {
    signUpByReferralCode,
    saveUserId,
    saveUserIdAndReport,
    loadSession,
    loadUserOptions,
    loadUser,
    signOut,

    openTeacherWelcomePopup,
    closeTeacherWelcomePopup,
};
