import React from "react";

import classNames from "juice-base/lib/class-names.js";

import JuiceContent from "juice-base/components/juice-content/index.js";
import PollSection from "juice-base/components/poll-section/index.js";

import dailyJuiceCommonStyles from "juice-base/business/daily-juice-common/styles.module.css";
import styles from "./styles.module.css";


const DailyJuiceStoryPoll = (props) => {
    const { story } = props;

    if (!story) {
        return null;
    }

    const categoryClassName = classNames({
        [dailyJuiceCommonStyles.header]: true,
        [styles.category]: true,
    });

    let category = story.categoryName;

    if (props.storyIndex !== null) {
        category = `${props.storyIndex + 1} | ${story.categoryName}`;
    }

    const renderPoll = () => {
        const { poll } = props;

        if (!poll) {
            return null;
        }

        return (
            <PollSection
                isVisibleResults={poll?.isVisibleResults || false}
                isLoadingResults={poll.isLoading}
                isDisabled={poll.isDisabled}
                isOutdated={poll.data?.isOutdated}
                questionControl={poll?.questionControl || null}
                bottomControl={poll?.bottomControl || null}
                question={poll.data.question}
                answers={poll.data.answers}
                votes={poll.data?.totalVotes || 0}
                selectedAnswer={poll.data.userAnswerId}
                error={poll.error}
                onSelect={(answerId) => {
                    props.onSubmitPoll({
                        pollId: poll.data.id,
                        answerId,
                    });
                }}
            />
        );
    };

    return (
        <div className={styles.juiceStory}>
            <div className={categoryClassName}>
                {category}
            </div>
            {renderPoll()}
            <JuiceContent
                className={styles.content}
                extraJuices={story.extraJuices || []}
                content={story.content}
                onWordClick={props.onWordClick}
                onExtraJuiceWordClick={(openedBy) => {
                    const sId = story.ID || story.id;
                    const ejs = story.extraJuices || [];
                    const ejId = ejs.length > 0 ? ejs[0].id : null;

                    props.onExtraJuiceWordClick(sId, ejId, openedBy);
                }}
            />
        </div>
    );
};

DailyJuiceStoryPoll.defaultProps = {
    storyIndex: 0,
    story: null,
    poll: null,
    onSubmitPoll: () => { },
    onExtraJuiceWordClick: () => { },
    onWordClick: () => { },
};

export default DailyJuiceStoryPoll;
