import React from "react";

import classNames from "juice-base/lib/class-names.js";

import styles from "./styles.module.css";


const RowWithOffset = (props) => {
    const resultsClassName = classNames({
        [styles.studentRowResults]: true,
        [styles.studentRowResultsWithPadding]: props.withPadding,
    });

    const rowStyle = {
        transform: `translateX(-${props.offset}px)`,
    };

    const containerClassName = classNames({
        [styles.studentRowResultsContainer]: true,
        [styles.studentRowResultsContainerUpdated]: props.isUpdated,
        [styles.studentRowResultsContainerWithBorderTop]: props.withBorderTop,
    });

    return (
        <div className={containerClassName}>
            <div className={styles.studentRowResultsLayout}>
                <div
                    className={resultsClassName}
                    style={rowStyle}
                >
                    {props.children}
                </div>
            </div>
        </div>
    );
};

RowWithOffset.defaultProps = {
    offset: 0,
    withBorderTop: true,
    withPadding: true,
    isUpdated: false,
    children: null,
};

export default RowWithOffset;
