import React from "react";

import Standards from "juice-base/project/standards.js";

import styles from "./styles.module.css";


const StandardsShape = (props) => {
    const { standardGroup, isStringFormat } = props;

    if (Standards.isKeyIdeasAndDetailsGroup(standardGroup)) {
        if (isStringFormat) {
            return `
                <div class="${styles.shapeTriangle}"></div>
            `;
        }

        return (
            <div className={styles.shapeTriangle} />
        );
    }

    if (Standards.isCraftAndStructureGroup(standardGroup)) {
        if (isStringFormat) {
            return `
                <div class="${styles.shapeSquare}"></div>
            `;
        }

        return (
            <div className={styles.shapeSquare} />
        );
    }

    if (Standards.isIntegrationOfKnowledgeAndIdeasGroup(standardGroup)) {
        if (isStringFormat) {
            return `
                <div class="${styles.shapeCircle}"></div>
            `;
        }

        return (
            <div className={styles.shapeCircle} />
        );
    }

    if (props.isStringFormat) {
        return "";
    }

    return null;
};

StandardsShape.defaultProps = {
    standardGroup: "",
    // NOTE: need for chart-radar component
    isStringFormat: false,
};

export default StandardsShape;
