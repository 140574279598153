import React from "react";

import text from "juice-base/text/index.js";

import IconHome from "juice-base/icons/home/index.js";

import MessageWithEmoji from "juice-base/components/message-with-emoji/index.js";
import ButtonBig from "juice-base/components/button-big/index.js";

import styles from "./styles.module.css";


const MessageAlreadyAuthorized = (props) => {
    return (
        <div className={styles.content}>
            <MessageWithEmoji
                header={text.alreadySigned}
                type="sad"
            />

            <ButtonBig
                icon={IconHome}
                onClick={props.onClick}
            >
                Go Home
            </ButtonBig>
        </div>
    );
};

MessageAlreadyAuthorized.defaultProps = {
    onClick: () => { },
};

export default MessageAlreadyAuthorized;
