export default (apiURL, methods) => {
    return {
        getStudentInfo(params = {}) {
            // NOTE: action=get_student_info_by_id
            const url = `${apiURL}/students/${params.studentId}`;

            return methods.get(url, {
                session_id: params.session,
            });
        },

        /* --- */

        getStudentsByGuardian(params = {}) {
            // NOTE: action=get_students_by_guardian_id
            const url = `${apiURL}/students/by-guardian`;

            return methods.get(url, {
                session_id: params.session,
            });
        },

        getStudentsStatsByGuardian(params = {}) {
            // NOTE: action=get_students_stats_by_guardian_id
            const url = `${apiURL}/students/stats/by-guardian`;

            return methods.get(url, {
                session_id: params.session,
                date_from: params.dateFrom,
                date_to: params.dateTo,
            });
        },

        /* --- */

        getStudentClassInfo(params = {}) {
            // NOTE: action=class_get_published_content
            const url = `${apiURL}/student/class`;

            return methods.get(url, {
                session_id: params.session,
                student_id: params.studentId,
            });
        },

        getStudentQuizChart(params = {}) {
            // NOTE: action=get_student_chart
            const url = `${apiURL}/student/chart`;

            return methods.get(url, {
                session_id: params.session,
                student_id: params.studentId,
            });
        },

        getStudentQuizPerformance(params = {}) {
            // NOTE: action=get_student_quiz_performance_by_id
            const url = `${apiURL}/students/${params.studentId}/quiz-performance`;

            return methods.get3(url, {
                session_id: params.session,
                standard_id: params.standardId,
                filter_by: params.filterBy,
                range: params.range,
                date_from: params.dateFrom,
                date_to: params.dateTo,
            });
        },

        getStudentAssignments(params = {}) {
            // NOTE: new action=get_teacher_student_assignments_by_page.
            //       Previous action=get_student_assignments_by_id.
            const url = `${apiURL}/students/${params.studentId}/assignments`;

            return methods.get3(url, {
                session_id: params.session,
                student_id: params.studentId,
                page: params.page,
            });
        },

        getStudentDailyJuicesResults(params) {
            // NOTE: action=get_student_daily_juices_results
            const url = `${apiURL}/student/daily-juices/results`;

            return methods.get3(url, {
                session_id: params.session,
                student_id: params.studentId,
                date: params.date,
                load_type: params.loadType,
            });
        },

        // TODO: remove from rest api
        getStudentDailyJuicesResultsByPage(params = {}) {
            // TOTE: action=get_student_daily_juices_stats_by_page
            const url = `${apiURL}/student/daily-juices/stats`;

            return methods.get(url, {
                session_id: params.session,
                student_id: params.studentId,
                page: params.page,
            });
        },

        setPassword(params = {}) {
            // NOTE: action=set_student_password
            const url = `${apiURL}/students/${params.studentId}/set-password`;

            return methods.post(url, {
                session_id: params.session,
                student_id: params.studentId,
                password: params.password,
            });
        },

        updateStudentDataV2(params = {}) {
            // NOTE: action=update_student_data_v2
            const url = `${apiURL}/students/${params.studentId}`;

            return methods.post(url, {
                session_id: params.session,
                user_id: params.studentId,
                fullname: params.fullname,
                email: params.email,
                grade: params.grade,
            });
        },

        getImportStudentsTemplate(params = {}) {
            const url = `${apiURL}/students/template`;

            return methods.get3(url, {
                session_id: params.session,
            });
        },

        getStudentIncompleteAssignments(params = {}) {
            // NOTE: action=get_student_incomplete_assignments_by_page
            const url = `${apiURL}/student/assignments/incomplete`;

            return methods.get3(url, {
                session_id: params.session,
                page: params.page,
            });
        },

        getStudentCompletedAssignments(params = {}) {
            // NOTE: action=get_student_completed_assignments_by_page
            const url = `${apiURL}/student/assignments/completed`;

            return methods.get3(url, {
                session_id: params.session,
                page: params.page,
            });
        },

        getStudentAssignmentsByPage(params = {}) {
            // NOTE: action=get_student_assignments_by_page
            const url = `${apiURL}/student/assignments`;

            return methods.get3(url, {
                session_id: params.session,
                page: params.page,
            });
        },
    };
};
