import React, { useEffect, useState } from "react";
import { Controller, Keyboard } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import classNames from "juice-base/lib/class-names.js";
import juiceScrollbarStyles from "juice-base/components/juice-scrollbar/styles.module.css";

import "swiper/css";
import "swiper/css/controller";
import "swiper/css/keyboard";

import styles from "./styles.module.css";


const SwiperDailyJuiceStories = (props) => {
    const [controlledSwiper, setControlledSwiper] = useState(null);

    useEffect(() => {
        if (controlledSwiper) {
            controlledSwiper.slideTo(props.index);
        }
    }, [props.index, controlledSwiper]);

    const renderSlides = () => {
        const slides = props.children || [];

        const pageClassName = classNames({
            [juiceScrollbarStyles.scrollbar]: true,
            [styles.page]: true,
            [styles.pageForSafari]: props.isSafari && !props.isPWA && !props.isIOS15,
        });

        return slides.map((slide) => {
            const renderSlide = ({ isActive }) => {
                const slideElem = React.cloneElement(slide, {
                    isVisible: isActive,
                });

                return (
                    <div className={pageClassName}>
                        {slideElem}
                    </div>
                );
            };

            return (
                <SwiperSlide>
                    {renderSlide}
                </SwiperSlide>
            );
        });
    };

    const modules = [
        Controller,
        Keyboard,
    ];

    const controller = {
        control: controlledSwiper,
    };

    const keyboard = {
        enabled: true,
    };

    return (
        <Swiper
            modules={modules}
            controller={controller}
            keyboard={keyboard}
            initialSlide={props.index}
            spaceBetween={50}
            slidesPerView={1}
            onSlideChange={(sw) => {
                props.onSlide(sw.activeIndex, sw.previousIndex);
            }}
            onSwiper={setControlledSwiper}
            simulateTouch={false}
            shortSwipes={false}
            longSwipesRatio={0.05}
        >
            {renderSlides()}
        </Swiper>
    );
};

SwiperDailyJuiceStories.defaultProps = {
    index: 0,
    children: [],
    isSafari: false,
    isIOS15: false,
    isPWA: false,
    onSlide: () => { },
};

export default SwiperDailyJuiceStories;
