// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".gh8Gnu0FZnj_L6tU6bBD {\n    display: grid;\n    grid-gap: 2rem;\n\n    justify-content: center;\n    text-align: center;\n\n    font-weight: 600;\n}\n\n.gh8Gnu0FZnj_L6tU6bBD a {\n    color: var(--sky);\n\n    text-decoration: none;\n\n    cursor: pointer;\n    outline: none;\n}", "",{"version":3,"sources":["webpack://./base/business/teacher-import-students/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,cAAc;;IAEd,uBAAuB;IACvB,kBAAkB;;IAElB,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;;IAEjB,qBAAqB;;IAErB,eAAe;IACf,aAAa;AACjB","sourcesContent":[".importTemplate {\n    display: grid;\n    grid-gap: 2rem;\n\n    justify-content: center;\n    text-align: center;\n\n    font-weight: 600;\n}\n\n.importTemplate a {\n    color: var(--sky);\n\n    text-decoration: none;\n\n    cursor: pointer;\n    outline: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"importTemplate": "gh8Gnu0FZnj_L6tU6bBD"
};
export default ___CSS_LOADER_EXPORT___;
