
const loadClosedPolls = (services, params) => {
    const { api, actions } = services;

    return async (dispatch) => {
        dispatch(actions.polls.setClosedPollsLoading());

        const res = await api.polls.getAllClosedPolls({
            session: params.session,
            page: params.page,
        });

        let closedPolls = [];
        let hasMorePages = false;

        if (res.ok) {
            closedPolls = res.polls;
            hasMorePages = res.hasMore;
        }

        dispatch(actions.polls.setClosedPolls({
            closedPolls,
            hasMorePages,
        }));
    };
};

export default {
    loadClosedPolls,
};
