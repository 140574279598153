import React from "react";
import { Link } from "react-router-dom";

import staticFiles from "juice-base/static-files.js";

import classNames from "juice-base/lib/class-names.js";

import { getIconByName } from "juice-base/icons/index.js";

import User from "juice-base/project/user.js";

import IconClickable from "juice-base/components/icon-clickable/index.js";
import IconClose from "juice-base/icons/close/index.js";
import IconSignIn from "juice-base/icons/sign-in/index.js";
import IconSignOut from "juice-base/icons/sign-out/index.js";
import IconQuestion from "juice-base/icons/question/index.js";
import IconUser from "juice-base/icons/user/index.js";
import Status from "juice-base/components/status/index.js";

import styles from "./styles.module.css";


const AsideNav = (props) => {
    const onSignOut = () => {
        props.onClose();
        props.onSignOut();
    };

    const renderStatus = () => {
        return (
            <Status
                isGreen={props.isSocketMonitorOnline}
            />
        );
    };

    const renderLink = (to, linkName, iconName) => {
        let isSelected = false;

        if (to === "/") {
            isSelected = props.pathname === to;
        } else {
            isSelected = props.pathname.indexOf(to) === 0;
        }

        let icon = null;
        const IconComponent = getIconByName(iconName);

        if (IconComponent) {
            icon = (
                <IconComponent
                    className={styles.sectionIcon}
                    isOrange={isSelected}
                />
            );
        }

        const sectionClassName = classNames({
            [styles.sectionWithIcon]: true,
            [styles.sectionWithIconAndBorder]: true,
            [styles.sectionWithIconSelected]: isSelected,
        });

        return (
            <Link
                key={linkName}
                to={to}
                className={sectionClassName}
                onClick={props.onClose}
            >
                {icon}
                <div className={styles.sectionName}>
                    {linkName}
                </div>
            </Link>
        );
    };

    const renderSupportLink = () => {
        return (
            <a
                className={styles.sectionWithIcon}
                href={props.supportLink}
                target="_blank"
                rel="noopener"
            >
                <IconQuestion
                    className={styles.sectionIcon}
                />
                <div className={styles.sectionName}>
                    Support
                </div>
            </a>
        );
    };

    const renderSections = () => {
        if (!props.user.userId) {
            return null;
        }

        const homeLink = props.isVisibleHomeLink
            ? renderLink("/", "Home", "home")
            : null;

        const sections = [];

        User.getNavSections(props.user, props.sectionsByRoles).forEach((sec) => {
            sections.push(renderLink(sec.to, sec.name, sec.icon));
        });

        return (
            <>
                {homeLink}
                {sections}
            </>
        );
    };

    const renderUser = () => {
        if (!props.user.userId) {
            return (
                <>
                    <Link
                        to="/sign-in"
                        className={styles.sectionWithIcon}
                        onClick={props.onClose}
                    >
                        <IconSignIn
                            className={styles.sectionIcon}
                        />
                        Sign In
                    </Link>
                    {renderSupportLink()}
                </>
            );
        }

        const signOutClassName = [
            styles.sectionWithIcon,
            styles.signout,
        ].join(" ");

        return (
            <>
                <Link
                    to="/user/profile"
                    className={styles.sectionWithIcon}
                    onClick={props.onClose}
                >
                    <IconUser
                        className={styles.sectionIcon}
                    />
                    <div className={styles.sectionName}>
                        {props.user.fullName}
                    </div>
                </Link>
                {renderSupportLink()}
                <div
                    className={signOutClassName}
                    onClick={onSignOut}
                    onKeyPress={onSignOut}
                    tabIndex="-1"
                    role="button"
                >
                    <IconSignOut
                        className={styles.sectionIcon}
                    />
                    Sign Out
                </div>
            </>
        );
    };

    /* eslint-disable */
    const layout = (
        <div
            className={styles.asideLayout}
            onClick={props.onClose}
            onKeyPress={props.onClose}
            tabIndex="-1"
            role="button"
        />
    );
    /* eslint-enable */

    return (
        <div className={styles.asideNav}>
            {layout}
            <aside className={styles.aside}>
                <div className={styles.header}>
                    <IconClickable
                        onClick={props.onClose}
                        className={styles.close}
                    >
                        <IconClose isBlack />
                    </IconClickable>

                    <div className={styles.headerName}>
                        <img
                            src={staticFiles.juiceHomeLogo}
                            alt={props.name}
                            title={props.name}
                            className={styles.logoImg}
                        />
                    </div>
                </div>

                <div className={styles.sections}>
                    <div>
                        {renderSections()}
                    </div>
                    <div className={styles.sectionGroupWithBottomBorder}>
                        {renderUser()}
                    </div>
                </div>

                <div className={styles.sysinfo}>
                    {renderStatus()}
                    {`Build: ${props.version}`}
                </div>
            </aside>
        </div>
    );
};

AsideNav.defaultProps = {
    name: "",
    version: "",
    user: {},
    pathname: "",
    sectionsByRoles: {},
    supportLink: "",
    isVisibleHomeLink: true,
    isSocketMonitorOnline: false,
    onSignOut: () => { },
    onClose: () => { },
};

export default AsideNav;
