import React from "react";

import classNames from "juice-base/lib/class-names.js";

import styles from "./styles.module.css";


const BlockProgressLayout = (props) => {
    const layoutClassName = classNames({
        [styles.layout]: true,
        [props.className]: props.className,
    });

    return (
        <div className={layoutClassName}>
            {props.children}
        </div>
    );
};

BlockProgressLayout.defaultProps = {
    className: "",
    children: null,
};

export default BlockProgressLayout;

