import React from "react";

import RequestLoader from "juice-base/components/request-loader/index.js";
import ButtonFlat from "juice-base/components/button-flat/index.js";
import PopupConfirm, {
    PopupConfirmTitle,
    PopupConfirmContent,
    PopupConfirmButtons,
} from "juice-base/components/popup-confirm/index.js";
import Text from "juice-base/components/text/index.js";

import usePopupConfirmDeleteTeacherHook from "./use-popup-confirm-delete-teacher.js";


const PopupConfirmDeleteTeacher = (props) => {
    const renderContent = () => {
        if (props.isSuccessfullyDeleted) {
            if (props.isRemoveYourself) {
                return `You successfully removed yourself from the class "${props.className}".`;
            }

            return `The teacher ${props.fullName} successfully removed from the class "${props.className}".`;
        }

        if (props.isLoading) {
            return (
                <RequestLoader />
            );
        }

        if (props.error) {
            return props.error;
        }


        if (props.isRemoveYourself) {
            const notificationText = props.primaryTeacherFullName && props.primaryTeacherEmail
                ? `If so, the primary teacher, ${props.primaryTeacherFullName} with the email ${props.primaryTeacherEmail} will be notified.`
                : "If so, the primary teacher of this class will be notified.";

            return (
                <Text>
                    {`
                        You are about to remove yourself from the class "${props.className}". Are you sure?</br>
                        ${notificationText}
                    `}
                </Text>
            );
        }

        return `
            You are about to remove ${props.fullName} from the class "${props.className}".
            Are you sure?
        `;
    };

    const renderControls = () => {
        if (props.isSuccessfullyDeleted) {
            return (
                <ButtonFlat
                    onClick={props.onClose}
                    disabled={props.isLoading}
                    uppercase={false}
                >
                    Close
                </ButtonFlat>
            );
        }

        const controls = [
            <ButtonFlat
                onClick={props.onClose}
                disabled={props.isLoading}
                uppercase={false}
            >
                Cancel
            </ButtonFlat>,
            <ButtonFlat
                onClick={props.onDelete}
                disabled={props.isLoading}
                uppercase={false}
                danger
            >
                {props.error ? "Try again" : "Remove Access"}
            </ButtonFlat>,
        ];

        return controls;
    };

    return (
        <PopupConfirm>
            <PopupConfirmTitle>
                Remove access
            </PopupConfirmTitle>

            <PopupConfirmContent>
                {renderContent()}
            </PopupConfirmContent>

            <PopupConfirmButtons>
                {renderControls()}
            </PopupConfirmButtons>
        </PopupConfirm>
    );
};

PopupConfirmDeleteTeacher.defaultProps = {
    fullName: "",
    className: "",
    error: "",
    primaryTeacherFullName: "",
    primaryTeacherEmail: "",
    onDelete: () => {},
    onClose: () => {},
    isSuccessfullyDeleted: false,
    isRemoveYourself: false,
    isLoading: false,
};

export const usePopupConfirmDeleteTeacher = usePopupConfirmDeleteTeacherHook;
export default PopupConfirmDeleteTeacher;
