import React from "react";

import IconWrapper from "juice-base/icons/wrapper/index.js";


const IconUsers = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    className={iconProps.className}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill="currentColor"
                        d="M11.6667 8.25C11.6667 10.0637 10.2303 11.5 8.5 11.5V13.5C11.372 13.5 13.6667 11.1307 13.6667 8.25H11.6667ZM8.5 11.5C6.76967 11.5 5.33333 10.0637 5.33333 8.25H3.33333C3.33333 11.1307 5.62795 13.5 8.5 13.5V11.5ZM5.33333 8.25C5.33333 6.43631 6.76967 5 8.5 5V3C5.62795 3 3.33333 5.36927 3.33333 8.25H5.33333ZM8.5 5C10.2303 5 11.6667 6.43631 11.6667 8.25H13.6667C13.6667 5.36927 11.372 3 8.5 3V5ZM1.96032 21.2789C2.85426 18.2009 5.48191 16.05 8.5 16.05V14.05C4.4794 14.05 1.14884 16.902 0.0396806 20.7211L1.96032 21.2789ZM19.7778 11.75C19.7778 12.707 18.9913 13.5 18 13.5V15.5C20.0769 15.5 21.7778 13.8305 21.7778 11.75H19.7778ZM18 13.5C17.0087 13.5 16.2222 12.707 16.2222 11.75H14.2222C14.2222 13.8305 15.9231 15.5 18 15.5V13.5ZM16.2222 11.75C16.2222 10.793 17.0087 10 18 10V8C15.9231 8 14.2222 9.66948 14.2222 11.75H16.2222ZM18 10C18.9913 10 19.7778 10.793 19.7778 11.75H21.7778C21.7778 9.66948 20.0769 8 18 8V10ZM18 18.15C19.8649 18.15 21.4893 19.4395 22.042 21.2867L23.958 20.7133C23.1753 18.0976 20.8276 16.15 18 16.15V18.15ZM15.3676 19.1226C16.0951 18.5099 17.0092 18.15 18 18.15V16.15C16.511 16.15 15.1464 16.6942 14.0793 17.5928L15.3676 19.1226ZM8.5 16.05C10.6773 16.05 12.6402 17.162 13.9097 18.939L15.5371 17.7764C13.9318 15.5294 11.3885 14.05 8.5 14.05V16.05ZM13.9097 18.939C14.4037 19.6304 14.7903 20.4203 15.0397 21.2789L16.9603 20.7211C16.6482 19.6465 16.1627 18.652 15.5371 17.7764L13.9097 18.939Z"
                    />
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconUsers;
