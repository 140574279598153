// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UFQnOrlTX51sjMBPKrYm {\n    display: grid;\n    grid-gap: 2rem;\n\n    margin: 2rem;\n\n    color: var(--text-primary-color);\n}\n\n.UFQnOrlTX51sjMBPKrYm ul {\n    padding: 0 2rem;\n}\n\n/* ------ */\n\n._zHiQKfr31tRbFZtVLZa {\n    display: grid;\n    grid-auto-flow: column;\n    grid-gap: 0.5rem;\n\n    justify-content: start;\n    align-items: center;\n\n    color: var(--teacher-class-announcement-note-color);\n    font-size: 1.2rem;\n}\n\n\n._zHiQKfr31tRbFZtVLZa img {\n    width: 2rem;\n}\n\n/* ------ */\n\n.dqhOFDYZzq8OlAj9jmS0 {\n    display: grid;\n\n    cursor: pointer;\n    outline: none;\n}\n\n.dqhOFDYZzq8OlAj9jmS0 > svg {\n    width: 2rem;\n}\n", "",{"version":3,"sources":["webpack://./base/business/teacher-class-announcement/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,cAAc;;IAEd,YAAY;;IAEZ,gCAAgC;AACpC;;AAEA;IACI,eAAe;AACnB;;AAEA,WAAW;;AAEX;IACI,aAAa;IACb,sBAAsB;IACtB,gBAAgB;;IAEhB,sBAAsB;IACtB,mBAAmB;;IAEnB,mDAAmD;IACnD,iBAAiB;AACrB;;;AAGA;IACI,WAAW;AACf;;AAEA,WAAW;;AAEX;IACI,aAAa;;IAEb,eAAe;IACf,aAAa;AACjB;;AAEA;IACI,WAAW;AACf","sourcesContent":[".content {\n    display: grid;\n    grid-gap: 2rem;\n\n    margin: 2rem;\n\n    color: var(--text-primary-color);\n}\n\n.content ul {\n    padding: 0 2rem;\n}\n\n/* ------ */\n\n.note {\n    display: grid;\n    grid-auto-flow: column;\n    grid-gap: 0.5rem;\n\n    justify-content: start;\n    align-items: center;\n\n    color: var(--teacher-class-announcement-note-color);\n    font-size: 1.2rem;\n}\n\n\n.note img {\n    width: 2rem;\n}\n\n/* ------ */\n\n.editButton {\n    display: grid;\n\n    cursor: pointer;\n    outline: none;\n}\n\n.editButton > svg {\n    width: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "UFQnOrlTX51sjMBPKrYm",
	"note": "_zHiQKfr31tRbFZtVLZa",
	"editButton": "dqhOFDYZzq8OlAj9jmS0"
};
export default ___CSS_LOADER_EXPORT___;
