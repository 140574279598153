// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bfbunrUNh4XeEw2340Rk {\n    display: grid;\n    grid-gap: 5rem;\n}\n\n.qKLODAriDCNrPKiuKj41 {\n    display: grid;\n    grid-gap: 1rem;\n}\n\n/* ----- */\n\n._dbiCa8fpsN6LLgBSe6q {\n    display: grid;\n    grid-auto-flow: column;\n    grid-gap: 2rem;\n\n    justify-content: space-between;\n}\n\n._dbiCa8fpsN6LLgBSe6q > div:last-child {\n    text-align: end;\n}\n\n/* ----- */\n\n.fXsCI46dOD5LVvLGirGX {\n    font-size: 1.5rem;\n    color: var(--grey);\n}\n\n.aYsN8lOVjXw_uukjMcgo {\n    font-size: 2rem;\n    font-weight: 600;\n}\n\n.DpaHzXDjddsu5gRr6fKi {\n    font-size: 2rem;\n}\n\n/* ----- */\n\n.DFDfE05HPdtw7fgYIuDq {\n    display: grid;\n    grid-gap: 2rem;\n}", "",{"version":3,"sources":["webpack://./base/business/subscribe-checkout/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,cAAc;AAClB;;AAEA,UAAU;;AAEV;IACI,aAAa;IACb,sBAAsB;IACtB,cAAc;;IAEd,8BAA8B;AAClC;;AAEA;IACI,eAAe;AACnB;;AAEA,UAAU;;AAEV;IACI,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,eAAe;AACnB;;AAEA,UAAU;;AAEV;IACI,aAAa;IACb,cAAc;AAClB","sourcesContent":[".page {\n    display: grid;\n    grid-gap: 5rem;\n}\n\n.checkoutSection {\n    display: grid;\n    grid-gap: 1rem;\n}\n\n/* ----- */\n\n.sectionRow {\n    display: grid;\n    grid-auto-flow: column;\n    grid-gap: 2rem;\n\n    justify-content: space-between;\n}\n\n.sectionRow > div:last-child {\n    text-align: end;\n}\n\n/* ----- */\n\n.smallGreyText {\n    font-size: 1.5rem;\n    color: var(--grey);\n}\n\n.bigBoldText {\n    font-size: 2rem;\n    font-weight: 600;\n}\n\n.bigText {\n    font-size: 2rem;\n}\n\n/* ----- */\n\n.controls {\n    display: grid;\n    grid-gap: 2rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": "bfbunrUNh4XeEw2340Rk",
	"checkoutSection": "qKLODAriDCNrPKiuKj41",
	"sectionRow": "_dbiCa8fpsN6LLgBSe6q",
	"smallGreyText": "fXsCI46dOD5LVvLGirGX",
	"bigBoldText": "aYsN8lOVjXw_uukjMcgo",
	"bigText": "DpaHzXDjddsu5gRr6fKi",
	"controls": "DFDfE05HPdtw7fgYIuDq"
};
export default ___CSS_LOADER_EXPORT___;
