import React from "react";

import IconWrapper from "juice-base/icons/wrapper/index.js";


const IconPollAnswered = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={iconProps.className}
                >
                    {iconProps.title}
                    <path
                        fill="currentColor"
                        d="M14 10H3V12H14V10Z"
                    />
                    <path
                        fill="currentColor"
                        d="M14 6H3V8H14V6Z"
                    />
                    <path
                        fill="currentColor"
                        d="M10 14H3V16H10V14Z"
                    />
                    <path
                        fill="#10b981"
                        d="M20.59 11.9301L16.34 16.1701L14.22 14.0501L12.81 15.4601L16.34 19.0001L22 13.3401L20.59 11.9301Z"
                    />
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconPollAnswered;
