import React from "react";

import IconWrapper from "juice-base/icons/wrapper/index.js";


const IconArrowsSort = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    width="16"
                    height="20"
                    viewBox="0 0 16 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={iconProps.className}
                >
                    <path
                        fill="currentColor"
                        d="M4 0L0 4H3L3 19H5L5 4H8L4 0Z"
                    />
                    <path
                        fill="currentColor"
                        d="M12 20L16 16H13L13 1H11L11 16H8L12 20Z"
                    />
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconArrowsSort;
