import React from "react";

import IconWrapper from "juice-base/icons/wrapper/index.js";


const IconGradesG9G10 = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={iconProps.className}
                >
                    {iconProps.title}
                    <rect
                        width="40"
                        height="40"
                        rx="20"
                        fill="#22C55E"
                    />
                    <path
                        d="M12.9231 7H10.4615C9.10769 7 8 8.10769 8 9.46154V11.9231C8 13.2769 9.10769 14.3846 10.4615 14.3846H12.9231V16.8462H8V19.3077H12.9231C14.2769 19.3077 15.3846 18.2 15.3846 16.8462V9.46154C15.3846 8.10769 14.2769 7 12.9231 7ZM12.9231 11.9231H10.4615V9.46154H12.9231V11.9231Z"
                        fill="#ffffff"
                    />
                    <path
                        d="M30.8077 20H28.3462C26.9923 20 25.8846 21.1077 25.8846 22.4615V29.8462C25.8846 31.2 26.9923 32.3077 28.3462 32.3077H30.8077C32.1616 32.3077 33.2693 31.2 33.2693 29.8462V22.4615C33.2693 21.1077 32.1616 20 30.8077 20ZM30.8077 29.8462H28.3462V22.4615H30.8077V29.8462Z"
                        fill="#ffffff"
                    />
                    <rect
                        x="22"
                        y="20"
                        width="2.5"
                        height="12.3"
                        fill="#ffffff"
                    />
                    <mask
                        id="gradesG9G10IconMask"
                        style={{ maskType: "alpha" }}
                        width="40"
                        height="40"
                    >
                        <circle
                            cx="20"
                            cy="20"
                            r="20"
                            fill="#22C55E"
                        />
                    </mask>
                    <g mask="url(#gradesG9G10IconMask)">
                        <path
                            d="M29.0862 1.46683L30.9624 2.28911L8.87622 37.3742L6.99999 36.552L29.0862 1.46683Z"
                            fill="#ffffff"
                        />
                    </g>
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconGradesG9G10;
