import React from "react";

import IconWrapper from "juice-base/icons/wrapper/index.js";


const IconCardsLayout = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    width="19"
                    height="18"
                    viewBox="0 0 19 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={iconProps.className}
                >
                    <path
                        fill="currentColor"
                        d="M2.75 9.75101H8.75V2.25101H2.75V9.75101ZM2.75 15.751H8.75V11.251H2.75V15.751ZM10.25 15.751H16.25V8.25101H10.25V15.751ZM10.25 2.25101V6.75101H16.25V2.25101H10.25Z"
                    />
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconCardsLayout;
