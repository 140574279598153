import React, { useState, useEffect } from "react";

import Students from "juice-base/project/students.js";
import Grades from "juice-base/project/grades.js";
import DailyJuice from "juice-base/project/daily-juice.js";

import classNames from "juice-base/lib/class-names.js";
import date from "juice-base/lib/date.js";
import array from "juice-base/lib/array.js";

import text from "juice-base/text/index.js";

import useAccordion from "juice-base/hooks/use-accordion/index.js";

import IconDownload from "juice-base/icons/download/index.js";
import IconListLayout from "juice-base/icons/list-layout/index.js";
import IconTrashWithLinesFull from "juice-base/icons/trash-with-lines-full/index.js";
import IconUserAdd from "juice-base/icons/user-add/index.js";
import IconUserInRing from "juice-base/icons/user-in-ring/index.js";
import IconCalendar from "juice-base/icons/calendar/index.js";

import IconClickable from "juice-base/components/icon-clickable/index.js";
import InfoTooltip from "juice-base/components/info-tooltip/index.js";
import RequestLoader from "juice-base/components/request-loader/index.js";
import AccordionWithControls from "juice-base/components/accordion-with-controls/index.js";
import Message from "juice-base/components/message/index.js";
import ButtonCircle from "juice-base/components/button-circle/index.js";
import ButtonCircleArrow from "juice-base/components/button-circle-arrow/index.js";
import Checkbox from "juice-base/components/forms/checkbox/index.js";
import MenuWithButton, {
    MenuOption,
} from "juice-base/components/menu-with-button/index.js";
import ButtonToggler from "juice-base/components/button-toggler/index.js";
import SelectCalendar from "juice-base/components/select-calendar/index.js";
import DatePickerSingle from "juice-base/components/date-picker-single/index.js";

import TableSortableColumnName from "juice-base/business/table-sortable-column-name/index.js";
import JuiceStatusV2 from "juice-base/business/juice-status-v2/index.js";
import GradesIcons from "juice-base/business/grades-icons/index.js";
import GradesIndicator from "juice-base/business/grades-indicator/index.js";

import useTeacherClassTableHook from "./use-teacher-class-table.js";
import RowWithOffset from "./row-with-offset.js";
import RowHeader from "./row-header.js";
import styles from "./styles.module.css";


const TeacherClassTableV2 = (props) => {
    const [isVisibleExportMenu, setIsVisibleExportMenu] = useState(false);

    const [selectedStudents, setSelectedStudents] = useState([]);

    const [tableHeaderState, setTableHeaderState] = useState({
        isArrowsHidden: true,
        headerOffset: 0,
    });

    /* ------- */

    const studentsAccordion = useAccordion();

    /* ------- */

    const isDailyJuiceView = DailyJuice.isDailyJuiceView(props.view);

    /* ------- */

    const onRowClick = (params) => {
        studentsAccordion.onSetExpanded(params.studentId);
    };

    const onExpandOrCollapseRows = () => {
        if (studentsAccordion.expanded.length >= props.students.length) {
            studentsAccordion.closeAll();
            return;
        }

        for (let i = 0; i < props.students.length; i += 1) {
            const studentId = props.students[i].id;

            if (!studentsAccordion.isExpanded(studentId)) {
                studentsAccordion.onSetExpanded(studentId);
            }
        }
    };

    const onSelectStudent = (studentId) => {
        let newStudents = [...selectedStudents];

        if (newStudents.indexOf(studentId) === -1) {
            newStudents = [...newStudents].concat(studentId);
        } else {
            newStudents = [...newStudents].filter((id) => id !== studentId);
        }

        setSelectedStudents(newStudents);
    };

    const onSelectAllStudents = () => {
        if (props.students.length === selectedStudents.length) {
            setSelectedStudents([]);
            return;
        }

        const studentsIds = [];

        for (let i = 0; i < props.students.length; i += 1) {
            const sId = props.students[i].id;
            studentsIds.push(sId);
        }

        setSelectedStudents(studentsIds);
    };

    const navigateWeek = (isAfter) => {
        if (!isDailyJuiceView) {
            const d = isAfter
                ? array.last(props.dates)
                : array.head(props.dates);

            if (d?.date) {
                const daysIncrement = isAfter ? 1 : -1;
                const newDate = date.addDays(date.newDateUTC(d.date), daysIncrement);

                props.onDateChangeByArrow(newDate, isAfter);
                return;
            }

            props.onDateChangeByArrow(date.newDateUTC(props.selectedRange.dateFrom), isAfter);
            return;
        }

        const { selectedRange } = props;

        const currentDate = selectedRange.dateFrom || selectedRange.dateTo;

        if (currentDate) {
            const weekdays = isAfter
                ? date.getNextWeekDaysByDate(currentDate)
                : date.getPrevWeekDaysByDate(currentDate);

            if (weekdays.length > 0) {
                props.onRangeChange({
                    dateFrom: weekdays[0] || null,
                    dateTo: weekdays[weekdays.length - 1] || null,
                });
            }
        }
    };

    const onToggleReportMenu = () => {
        setIsVisibleExportMenu((prev) => !prev);
    };

    const onCloseReportMenu = () => {
        setIsVisibleExportMenu(false);
    };

    const onExportSelectedWeek = () => {
        props.onExportSelectedWeek();
        onCloseReportMenu();
    };

    const onExportAllScores = () => {
        props.onExportAllScores();
        onCloseReportMenu();
    };

    /* ------- */

    useEffect(() => {
        if (props.isLoading) {
            setSelectedStudents([]);
            setTableHeaderState((prev) => ({
                ...prev,
                headerOffset: 0,
            }));
        }
    }, [props.isLoading]);

    /* ------- */

    const renderHeaderButtons = () => {
        const buttons = [];

        if (props.isMobileTable) {
            buttons.push({
                icon: <IconListLayout isSky />,
                onClick: onExpandOrCollapseRows,
            });
        }

        buttons.push({
            withMenu: true,
            isVisibleMenu: isVisibleExportMenu,
            icon: <IconDownload isSky />,
            options: [
                {
                    name: "Download selected week's data",
                    onClick: onExportSelectedWeek,
                },
                {
                    name: "Download all data",
                    onClick: onExportAllScores,
                },
            ],
            onClick: onToggleReportMenu,
            onCloseMenu: onCloseReportMenu,
        });

        if (!props.isLMS) {
            buttons.push({
                icon: <IconUserAdd isSky />,
                onClick: props.onOpenAddStudentPopup,
            });

            buttons.push({
                icon: <IconTrashWithLinesFull isSky />,
                disabled: selectedStudents.length === 0,
                onClick: () => {
                    props.onRemoveStudents(selectedStudents);
                },
            });
        }

        const btns = buttons.map((btn, index) => {
            const buttonClassName = classNames({
                [styles.headerButton]: true,
                [styles.headerButtonNoBorder]: index === 0,
            });

            if (btn.withMenu) {
                return (
                    <div className={buttonClassName}>
                        <MenuWithButton
                            icon={btn.icon}
                            isVisibleMenu={btn.isVisibleMenu}
                            isMenuOpensRight={props.isMenuOpensRight}
                            isFullWidth={props.isMenuFullWidth}
                            onClick={btn.onClick}
                            onCloseMenu={btn.onCloseMenu}
                        >
                            {btn.options.map((opt) => ((
                                <MenuOption
                                    name={opt.name}
                                    onClick={opt.onClick}
                                    blueTheme
                                />
                            )))}
                        </MenuWithButton>
                    </div>
                );
            }

            return (
                <div className={buttonClassName}>
                    <ButtonCircle
                        icon={btn.icon}
                        disabled={btn.disabled || false}
                        tooltip={btn?.tooltip || null}
                        onClick={btn.onClick}
                    />
                </div>
            );
        });

        return (
            <div className={styles.headerButtons}>
                {btns}
            </div>
        );
    };

    const renderCalendar = () => {
        let calendar = null;

        if (props.isCalendarByDate) {
            calendar = (
                <DatePickerSingle
                    date={props.selectedRange.dateFrom}
                    maxDate={props.siteDate}
                    icon={<IconCalendar isBlack />}
                    onChange={(d) => {
                        props.onDateChange(date.newDateUTC(d));
                    }}
                />
            );
        } else {
            const maxDate = props.siteDate
                ? date.getFriday(props.siteDate)
                : null;

            calendar = (
                <SelectCalendar
                    maxDate={maxDate}
                    selectedRange={props.selectedRange}
                    calendarClassName={styles.headerCalendarCalendar}
                    onRangeChange={props.onRangeChange}
                />
            );
        }

        return (
            <div className={styles.headerCalendar}>
                {calendar}
            </div>
        );
    };

    const renderGrades = () => {
        const studentsGrades = props.students.map((s) => s?.grades?.[0] || "")
            .filter(array.filterCallbackNonEmptyString);

        return (
            <div className={styles.headerGrades}>
                <GradesIcons
                    visibleGrades={studentsGrades}
                />
            </div>
        );
    };

    const renderHeader = () => {
        return (
            <div className={styles.tableHeader}>
                <div className={styles.headerTitle}>
                    Students and Quiz Scores
                </div>
                <div className={styles.headerSwitcher}>
                    <ButtonToggler
                        buttons={DailyJuice.getDailyJuicesTypes()}
                        selected={props.view}
                        onSelect={props.onChangeView}
                        uppercase={false}
                        outlined
                        rounded
                        small
                    />
                </div>
                {renderCalendar()}
                {renderGrades()}
                {renderHeaderButtons()}
            </div>
        );
    };

    /* ------- */

    const renderStudentScores = (student) => {
        return props.dates.map((d) => {
            let isUpdated = false;

            const ss = [];

            for (let i = 0; i < student.quizzesScores.length; i += 1) {
                const s = student.quizzesScores[i];

                if (s.date === d.date) {
                    if (isDailyJuiceView) {
                        if (s.isUpdated) {
                            isUpdated = true;
                        }

                        const status = DailyJuice.getDailyJuiceStatusByScores(
                            d.totalQuizzes,
                            s.totalAnswered,
                        );

                        const resultStyle = {};

                        if (status?.color) {
                            resultStyle.color = status.color;
                        }

                        ss.push(
                            <div style={resultStyle}>
                                {s.totalAnswered}
                            </div>,
                        );
                        break;
                    }

                    ss.push(
                        <JuiceStatusV2
                            status={s?.status || ""}
                        />,
                    );
                }
            }

            if (ss.length === 0) {
                if (isDailyJuiceView) {
                    const unansweredStatus = DailyJuice.getDailyJuiceStatuses()[2];

                    ss.push(
                        <div
                            style={{
                                color: unansweredStatus.color,
                            }}
                        >
                            <JuiceStatusV2
                                status={unansweredStatus.name}
                            />
                        </div>,
                    );
                } else {
                    ss.push(
                        <div>
                            -
                        </div>,
                    );
                }
            }

            const rowClassName = classNames({
                [styles.studentRowResult]: true,
                [styles.studentRowResultUpdated]: isUpdated,
            });

            return (
                <div className={rowClassName}>
                    {ss}
                </div>
            );
        });
    };

    const renderRowContent = (dates, student) => {
        const rows = dates.map((d) => {
            const studentCompletedQuizzes = [];

            let isUpdated = false;

            for (let i = 0; i < student.quizzesScores.length; i += 1) {
                const score = student.quizzesScores[i];

                if (d.date === score.date) {
                    if (isDailyJuiceView) {
                        if (score.isUpdated) {
                            isUpdated = true;
                        }

                        const status = DailyJuice.getDailyJuiceStatusByScores(
                            d.totalQuizzes,
                            score.totalAnswered,
                        );

                        studentCompletedQuizzes.push(
                            <div
                                style={{
                                    color: `${status?.color}`,
                                }}
                            >
                                {score.totalAnswered}
                            </div>,
                        );
                        break;
                    } else {
                        const status = DailyJuice.getStatusByName(score.status);

                        studentCompletedQuizzes.push(
                            <JuiceStatusV2
                                status={status?.name}
                            />,
                        );
                    }
                }
            }

            if (studentCompletedQuizzes.length === 0) {
                if (isDailyJuiceView) {
                    const unansweredStatus = DailyJuice.getDailyJuiceStatuses()[2];

                    studentCompletedQuizzes.push(
                        <JuiceStatusV2
                            status={unansweredStatus.name}
                        />,
                    );
                } else {
                    studentCompletedQuizzes.push("-");
                }
            }

            let totalQuizzesData = null;

            if (isDailyJuiceView) {
                totalQuizzesData = (
                    <div className={styles.totalQuizzesText}>
                        {`out of ${d.totalQuizzes}`}
                    </div>
                );
            }

            const rowClassName = classNames({
                [styles.row]: true,
                [styles.rowUpdated]: isUpdated,
            });

            return (
                <div className={rowClassName}>
                    <div className={styles.rowData}>
                        <div>
                            {date.tryFormatDateUTC(d.date, date.formatFullDayShortMonthDate)}
                        </div>
                        {totalQuizzesData}
                    </div>
                    <div className={styles.studentRowResultMobile}>
                        {studentCompletedQuizzes}
                    </div>
                </div>
            );
        });

        if (rows.length === 0) {
            return (
                <Message>
                    {text.noData}
                </Message>
            );
        }

        return (
            <div className={styles.accordionContent}>
                {rows}
            </div>
        );
    };

    const renderRowControls = (student) => {
        const studentId = student.id;

        let studentSelector = null;

        if (!props.isLMS) {
            studentSelector = (
                <Checkbox
                    key={`table-mobile-student-${studentId}`}
                    name={`table-mobile-student-${studentId}`}
                    checked={selectedStudents.indexOf(studentId) !== -1}
                    onChange={() => {
                        onSelectStudent(studentId);
                    }}
                />
            );
        }

        return (
            <div className={styles.rowControls}>
                {studentSelector}
                <IconClickable
                    className={styles.profileIcon}
                    onClick={() => {
                        props.onOpenStudentInfo(studentId);
                    }}
                >
                    <IconUserInRing
                        title="Open profile"
                        isBlack
                    />
                </IconClickable>
            </div>
        );
    };

    const renderMessage = (message) => {
        const messageClassName = classNames({
            [styles.messageMobile]: props.isMobileTable,
        });

        return (
            <div className={messageClassName}>
                <Message>
                    {message}
                </Message>
            </div>
        );
    };

    const renderMessageByView = () => {
        if (isDailyJuiceView) {
            return renderMessage(text.noJuicesInRange);
        }

        return renderMessage(text.noAssignmentsInRange);
    };

    const renderMobileTable = (sortedStudents) => {
        if (sortedStudents.length === 0) {
            return renderMessageByView();
        }

        return sortedStudents.map((student, i) => {
            const isLast = i === (sortedStudents.length - 1);

            const label2 = Grades.getGradeGroup(student.grades);

            const controls = [{
                isCustom: true,
                element: renderRowControls(student),
            }];

            const accordionClassName = classNames({
                [styles.accordionLast]: isLast,
            });

            const accordionLabelClassName = classNames({
                [styles.accordionLabel]: true,
                [styles.accordionLabelLast]: isLast,
            });

            return (
                <AccordionWithControls
                    key={student.id}
                    accordionClassName={accordionClassName}
                    labelClassName={accordionLabelClassName}
                    expandedContentClassName={styles.accordionExpandedContent}
                    label={student.fullName}
                    label2={label2}
                    controls={controls}
                    onClick={() => {
                        onRowClick({
                            studentId: student.id,
                        });
                    }}
                    isExpanded={studentsAccordion.isExpanded(student.id)}
                    isControlsWithBorder={false}
                    isHighlighted={student.isUpdated}
                    labelWithBorder={false}
                    labelWithBorderRadius={false}
                >
                    {renderRowContent(props.dates, student)}
                </AccordionWithControls>
            );
        });
    };

    const renderStudentRowHeader = () => {
        const { students } = props;

        let allStudentsSelector = null;

        if (!props.isLMS) {
            allStudentsSelector = (
                <div className={styles.checkbox}>
                    <Checkbox
                        name="table-desktop-all-student-check"
                        checked={students.length > 0 && students.length === selectedStudents.length}
                        onChange={onSelectAllStudents}
                    />
                </div>
            );
        }

        const tableDesktopHeaderRowClassName = classNames({
            [styles.tableDesktopStudentRowHeader]: true,
            [styles.tableDesktopStudentRowHeaderWithSelector]: allStudentsSelector,
        });

        return (
            <div className={tableDesktopHeaderRowClassName}>
                {allStudentsSelector}
                <TableSortableColumnName
                    name="Name"
                    isDown={props.sortBy === Students.StudentsSortByValues.nameZTOA}
                    onClick={props.onSortStudentsByName}
                />
            </div>
        );
    };

    const renderStudentGrade = (student) => {
        const studentGrades = student?.grades || [];
        const filteredGrades = Grades.filterGradesDuplicate(studentGrades);

        const gradesIndicators = filteredGrades.map((grades) => {
            return (
                <GradesIndicator
                    grades={grades}
                />
            );
        });

        return (
            <div className={styles.studentGrades}>
                {gradesIndicators}
            </div>
        );
    };

    const renderStudentRow = (student, index) => {
        const studentId = student.id;

        let studentSelector = null;

        if (!props.isLMS) {
            studentSelector = (
                <div className={styles.checkbox}>
                    <Checkbox
                        key={`table-desktop-student-${studentId}`}
                        name={`table-desktop-student-${studentId}`}
                        checked={selectedStudents.indexOf(studentId) !== -1}
                        onChange={() => {
                            onSelectStudent(studentId);
                        }}
                    />
                </div>
            );
        }

        const hasNotification = props.studentsNotifications.indexOf(studentId) !== -1;

        let tooltip = null;

        if (hasNotification) {
            tooltip = (
                <div className={styles.tooltip}>
                    <InfoTooltip
                        tooltip={text.userInvalidEmail}
                        position="rightCenter"
                    />
                </div>
            );
        }

        const rowClassName = classNames({
            [styles.tableDesktopStudentRow]: true,
            [styles.tableDesktopStudentRowWithSelector]: studentSelector,
            [styles.tableDesktopStudentRowFirst]: index === 0,
            [styles.tableDesktopStudentRowWithTooltip]: tooltip,
            [styles.tableDesktopStudentRowWithTooltipWithSelector]: tooltip && studentSelector,
            [styles.tableDesktopStudentRowUpdated]: student.isUpdated,
        });

        return (
            <div className={rowClassName}>
                {studentSelector}
                <div className={styles.studentName}>
                    <div
                        className={styles.studentRowName}
                        onClick={() => {
                            props.onOpenStudentInfo(studentId);
                        }}
                        onKeyPress={() => {
                            props.onOpenStudentInfo(studentId);
                        }}
                        tabIndex="-1"
                        role="button"
                    >
                        {student.fullName}
                    </div>
                    {renderStudentGrade(student)}
                </div>
                {tooltip}
            </div>
        );
    };

    const renderStudentRowResultsHeader = () => {
        return (
            <div className={styles.studentRowResultsHeader}>
                <div className={styles.navButtonDesktop}>
                    <ButtonCircleArrow
                        onClick={() => {
                            navigateWeek(false);
                        }}
                        disabled={props.isLoading || props.isPrevWeekDisabled}
                        left
                    />
                </div>
                <div className={styles.rowHeader}>
                    <RowHeader
                        dates={props.dates}
                        onFirstLastItemsVisibleChange={(params) => {
                            const isArrowsHidden = params.isFirstItemVisible
                                && params.isLastItemVisible;

                            setTableHeaderState((prev) => ({
                                ...prev,
                                isArrowsHidden,
                            }));
                        }}
                        onPositionChange={(pos) => {
                            setTableHeaderState((prev) => ({
                                ...prev,
                                headerOffset: pos,
                            }));
                        }}
                        hideArrows={tableHeaderState.isArrowsHidden}
                        withTotalQuizzes={isDailyJuiceView}
                    />
                </div>
                <div className={styles.navButtonDesktop}>
                    <ButtonCircleArrow
                        onClick={() => {
                            navigateWeek(true);
                        }}
                        disabled={props.isLoading || props.isNextWeekDisabled}
                        right
                    />
                </div>
            </div>
        );
    };

    const renderStudentRowResults = (student, index) => {
        const scores = renderStudentScores(student);

        return (
            <RowWithOffset
                offset={tableHeaderState.headerOffset}
                withPadding={!tableHeaderState.isArrowsHidden}
                withBorderTop={index > 0}
                isUpdated={student.isUpdated}
            >
                {scores}
            </RowWithOffset>
        );
    };

    const renderDesktopTable = (sortedStudents) => {
        const names = [];
        const results = [];

        for (let i = 0; i < sortedStudents.length; i += 1) {
            const student = sortedStudents[i];

            names.push(renderStudentRow(student, i));
            results.push(renderStudentRowResults(student, i));
        }

        let resultsContent = null;

        if (props.dates.length === 0) {
            resultsContent = renderMessageByView();
        } else {
            resultsContent = results;
        }

        return (
            <div className={styles.tableDesktop}>
                <div className={styles.tableDesktopStudents}>
                    {renderStudentRowHeader()}
                    {names}
                </div>
                <div className={styles.tableDesktopContent}>
                    {renderStudentRowResultsHeader()}
                    {resultsContent}
                </div>
            </div>
        );
    };

    const renderContent = () => {
        if (props.isLoading) {
            return (
                <div className={styles.tableLoader}>
                    <RequestLoader />
                </div>
            );
        }

        if (props.error) {
            return renderMessage(props.error);
        }

        const sortedStudents = Students.sortStudents(props.sortBy, props.students);

        const content = props.isMobileTable
            ? renderMobileTable(sortedStudents)
            : renderDesktopTable(sortedStudents);

        return (
            <div className={styles.tableContent}>
                {content}
            </div>
        );
    };

    /* ------- */

    const tableClassName = classNames({
        [styles.table]: true,
        [styles.tableMobile]: props.isMobileTable,
        [styles.tableWithLoader]: props.isLoading,
    });

    return (
        <div className={tableClassName}>
            {renderHeader()}
            {renderContent()}
        </div>
    );
};

TeacherClassTableV2.defaultProps = {
    view: "",
    siteDate: null,
    selectedRange: {
        dateFrom: null,
        dateTo: null,
    },
    error: "",
    sortBy: "",
    students: [],
    studentsNotifications: [],
    dates: [],

    onAddNewClass: () => { },
    onSyncLMSClasses: () => { },
    onEditClass: () => { },
    onClassChange: () => { },
    onRangeChange: () => { },
    onDateChange: () => { },
    onDateChangeByArrow: () => { },
    onOpenAddStudentPopup: () => { },
    onOpenStudentInfo: () => { },
    onRemoveStudents: () => { },
    onSortStudentsByName: () => { },
    onExportSelectedWeek: () => { },
    onExportAllScores: () => { },
    onChangeView: () => { },

    isLoading: false,
    isMobileTable: false,
    isMenuFullWidth: false,
    isMenuOpensRight: false,
    isCalendarByDate: false,
    isNextWeekDisabled: false,
    isPrevWeekDisabled: false,
    isLMS: false,
};

export const useTeacherClassTable = useTeacherClassTableHook;
export default TeacherClassTableV2;
