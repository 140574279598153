import React from "react";

import RequestLoader from "juice-base/components/request-loader/index.js";
import ButtonQuickLink from "juice-base/components/button-quick-link/index.js";
import SectionTeacher, {
    SectionTeacherHeader,
    SectionTeacherExplanation,
} from "juice-base/components/section-teacher/index.js";

import styles from "./styles.module.css";


const TeacherQuickLinks = (props) => {
    const renderControls = () => {
        return [
            <SectionTeacherExplanation alignLeft>
                Use quick links to customize your class&apos;s edition of
                The Juice with a unique image.
            </SectionTeacherExplanation>,
        ];
    };

    const renderSponsorImage = () => {
        if (!props.sponsorImage) {
            return null;
        }

        return (
            <div className={styles.sponsorImage}>
                <img
                    src={props.sponsorImage}
                    alt="sponsor"
                />
            </div>
        );
    };

    const renderLinks = () => {
        const links = [
            {
                name: props.sponsorImage
                    ? "Update image"
                    : "Upload an image",
                callback: props.onUploadImageClick,
            },
        ];

        return links.map((link) => (
            <ButtonQuickLink onClick={link.callback}>
                {link.name}
            </ButtonQuickLink>
        ));
    };

    const renderContent = () => {
        if (props.isLoading) {
            return (
                <RequestLoader />
            );
        }

        return (
            <div className={styles.content}>
                {renderSponsorImage()}
                <div className={styles.quickLinks}>
                    {renderLinks()}
                </div>
            </div>
        );
    };

    return (
        <SectionTeacher>
            <SectionTeacherHeader
                label="Quick Link"
                rightControls={renderControls()}
            />
            {renderContent()}
        </SectionTeacher>
    );
};

TeacherQuickLinks.defaultProps = {
    isLoading: false,
    sponsorImage: null,
    onUploadImageClick: () => { },
};

export default TeacherQuickLinks;
