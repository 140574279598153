import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import Grades from "juice-base/project/grades.js";

import actions from "juice-base/store/actions.js";
import dailyJuiceActions from "juice-base/actions/daily-juice.js";

import text from "juice-base/text/index.js";

import MessageWithEmoji from "juice-base/components/message-with-emoji/index.js";
import RequestLoader from "juice-base/components/request-loader/index.js";
import Message from "juice-base/components/message/index.js";
import LayoutContent from "juice-base/components/layout-content/index.js";

import api from "juice-app/api.js";

import linkStyles from "juice-base/components/link/styles.module.css";
import styles from "./styles.module.css";


const TeacherDailyJuicesStudentEdition = () => {
    const [pageState, setPageState] = useState({
        error: "",
        isLoading: true,
    });

    /* ---- */

    const navigate = useNavigate();
    const dispatch = useDispatch();

    /* ---- */

    const onJuicesDatesLoaded = (dates, res) => {
        const firstJuiceId = dates?.[0]?.juiceId;

        if (firstJuiceId) {
            navigate(`/daily-juices/${firstJuiceId}`);
            return;
        }

        setPageState({
            error: res?.error || text.error,
            isLoading: false,
        });
    };

    const loadJuicesDates = () => {
        setPageState({
            error: "",
            isLoading: true,
        });

        const grade = Grades.getMaxGradeValue();

        dispatch(dailyJuiceActions.loadJuicesDatesByGrade(
            { api, actions },
            { grade },
            { onLoad: onJuicesDatesLoaded },
        ));
    };

    /* ---- */

    useEffect(() => {
        loadJuicesDates();
    }, []);

    /* ---- */

    if (pageState.isLoading) {
        return (
            <RequestLoader />
        );
    }

    let errorMessage = null;

    if (pageState.error) {
        errorMessage = (
            <Message>
                {pageState.error}
            </Message>
        );
    }

    return (
        <LayoutContent>
            <div className={styles.error}>
                <MessageWithEmoji
                    header={text.issueNotAvailable}
                    message={text.cantLoadJuice}
                />

                {errorMessage}

                <Link
                    to="/teacher-daily-juices"
                    className={linkStyles.link}
                >
                    Go to Teacher Daily Juice Edition
                </Link>
            </div>
        </LayoutContent>
    );
};

export default TeacherDailyJuicesStudentEdition;
