import React from "react";

import HorizontalScrollingMenu from "juice-base/components/horizontal-scrolling-menu/index.js";

import Tab from "./tab.js";
import styles from "./styles.module.css";


const TabsScrolling = (props) => {
    if (props.tabs.length === 0) {
        return null;
    }

    const onChange = (itemId) => {
        props.onChange(itemId);
    };

    const renderTabs = ({ handleItemClick }) => {
        return props.tabs.map((tab) => {
            // NOTE: itemId is required for react-horizontal-scrolling-menu
            //       to track items(string format)
            const itemId = `item-${tab.value}`;

            const isSelected = props.selected === tab.value;

            return (
                <Tab
                    key={itemId}
                    itemId={itemId}
                    value={itemId}
                    label={tab.label}
                    selected={isSelected}
                    onClick={handleItemClick({
                        value: tab.value,
                        itemId,
                    })}
                />
            );
        });
    };

    // NOTE: The "selected" prop must be the same as
    //       the Tab "key" prop(to scroll to selected tab onInit)
    return (
        <div className={styles.tabs}>
            <HorizontalScrollingMenu
                selected={`item-${props.selected}`}
                onSelect={onChange}
            >
                {renderTabs}
            </HorizontalScrollingMenu>
        </div>
    );
};

TabsScrolling.defaultProps = {
    tabs: [],
    selected: null,
    hideArrows: false,
    onChange: () => { },
};

export default TabsScrolling;
