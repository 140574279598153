import React from "react";

import IconWrapper from "juice-base/icons/wrapper/index.js";


const IconStandardsEmpty = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    width="19"
                    height="22"
                    viewBox="0 0 19 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={iconProps.className}
                >
                    <path
                        fill="currentColor"
                        d="M9.5 8C11.71 8 13.5 6.21 13.5 4C13.5 1.79 11.71 0 9.5 0C7.29 0 5.5 1.79 5.5 4C5.5 6.21 7.29 8 9.5 8ZM9.5 2C10.6 2 11.5 2.9 11.5 4C11.5 5.1 10.6 6 9.5 6C8.4 6 7.5 5.1 7.5 4C7.5 2.9 8.4 2 9.5 2ZM9.5 10.55C7.14 8.35 3.98 7 0.5 7V18C3.98 18 7.14 19.35 9.5 21.55C11.86 19.36 15.02 18 18.5 18V7C15.02 7 11.86 8.35 9.5 10.55ZM16.5 16.13C13.97 16.47 11.57 17.43 9.5 18.95C7.44 17.43 5.03 16.46 2.5 16.12V9.17C4.6 9.55 6.55 10.52 8.14 12L9.5 13.28L10.86 12.01C12.45 10.53 14.4 9.56 16.5 9.18V16.13Z"
                    />
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconStandardsEmpty;
