import React from "react";

import { Formik } from "formik";

import { hasValue } from "juice-base/lib/object.js";
import v from "juice-base/lib/form-validators.js";

import FormRow from "juice-base/components/forms/form-row/index.js";
import Input from "juice-base/components/forms/input/index.js";
import ButtonBig from "juice-base/components/button-big/index.js";
import MessageDanger from "juice-base/components/message-danger/index.js";

import styles from "./styles.module.css";


const UserSignUpPersonalInfoForm = (props) => {
    const isDisabled = (formProps) => {
        const hasErrors = Object.keys(formProps.errors).length > 0;

        return !hasValue(formProps.values)
            || hasErrors
            || formProps.isSubmitting;
    };

    const validateForm = (values) => {
        const errorsFirstName = v.validate(values.firstName, [
            v.required("Please enter first name"),
        ]);

        const errorsLastName = v.validate(values.lastName, [
            v.required("Please enter last name"),
        ]);

        let errorsEmail = "";

        if (props.isEmailRequired) {
            errorsEmail = v.validate(values.email, [
                v.required("Please enter email"),
                v.email("Please enter valid email"),
            ]);
        } else if (values.email) {
            errorsEmail = v.validate(values.email, [
                v.email("Please enter valid email"),
            ]);
        }

        const errors = {};

        if (errorsFirstName) {
            errors.firstName = errorsFirstName;
        }

        if (errorsLastName) {
            errors.lastName = errorsLastName;
        }

        if (errorsEmail) {
            errors.email = errorsEmail;
        }

        return errors;
    };

    const renderFormError = (error) => {
        if (error) {
            return (
                <MessageDanger>
                    {error}
                </MessageDanger>
            );
        }

        return null;
    };

    const renderForm = (formProps) => {
        const {
            values,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
        } = formProps;

        const emailLabel = props.isEmailRequired
            ? "Email address *"
            : "Email address";

        return (
            <form onSubmit={handleSubmit} className={styles.form}>
                <FormRow>
                    <Input
                        name="firstName"
                        label="First name *"
                        placeholder="First name *"
                        value={values.firstName}
                        error={v.getError(formProps, "firstName")}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />

                    <Input
                        name="lastName"
                        label="Last name *"
                        placeholder="Last name *"
                        value={values.lastName}
                        error={v.getError(formProps, "lastName")}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </FormRow>

                <Input
                    name="email"
                    label={emailLabel}
                    placeholder={emailLabel}
                    value={values.email}
                    error={v.getError(formProps, "email")}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />

                {renderFormError(errors.formError)}

                <div className={styles.submitButtonBlock}>
                    <ButtonBig
                        type="submit"
                        disabled={isDisabled(formProps)}
                    >
                        Next
                    </ButtonBig>
                </div>
            </form>
        );
    };

    return (
        <Formik
            initialValues={props.initialValues}
            validate={validateForm}
            onSubmit={props.onSubmit}
        >
            {renderForm}
        </Formik>
    );
};

UserSignUpPersonalInfoForm.defaultProps = {
    initialValues: {},
    isEmailRequired: false,
    onSubmit: () => {},
};

export default UserSignUpPersonalInfoForm;
