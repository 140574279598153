// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".c5CueQ44RkYTeIkNOTKh {\n    min-width: 25rem;\n}\n\n.WvlAMGvgjQ7B5UyAH9js {\n    min-height: 6.4rem;\n\n    display: grid;\n    align-items: center;\n\n    padding: 1rem 1.5rem;\n    margin: 0 0 1rem 0;\n\n    border: 0.1rem solid var(--border-default-color);\n    border-radius: 0.5rem;\n\n    background-color: var(--text-primary-bg-color);\n    color: var(--text-primary-color);\n}\n\n.fGUx6DN4I4nIIky9IRJQ {\n    margin: 0 0 0.5rem 0;\n}\n\n.MwD_1F_4ghl7Z91FUcVQ {\n    display: grid;\n    padding: 2rem 0;\n}\n", "",{"version":3,"sources":["webpack://./base/forms/quiz-question/styles.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;;IAElB,aAAa;IACb,mBAAmB;;IAEnB,oBAAoB;IACpB,kBAAkB;;IAElB,gDAAgD;IAChD,qBAAqB;;IAErB,8CAA8C;IAC9C,gCAAgC;AACpC;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,eAAe;AACnB","sourcesContent":[".form {\n    min-width: 25rem;\n}\n\n.field {\n    min-height: 6.4rem;\n\n    display: grid;\n    align-items: center;\n\n    padding: 1rem 1.5rem;\n    margin: 0 0 1rem 0;\n\n    border: 0.1rem solid var(--border-default-color);\n    border-radius: 0.5rem;\n\n    background-color: var(--text-primary-bg-color);\n    color: var(--text-primary-color);\n}\n\n.answerError {\n    margin: 0 0 0.5rem 0;\n}\n\n.buttons {\n    display: grid;\n    padding: 2rem 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "c5CueQ44RkYTeIkNOTKh",
	"field": "WvlAMGvgjQ7B5UyAH9js",
	"answerError": "fGUx6DN4I4nIIky9IRJQ",
	"buttons": "MwD_1F_4ghl7Z91FUcVQ"
};
export default ___CSS_LOADER_EXPORT___;
