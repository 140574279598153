import React from "react";

import { Formik } from "formik";

import Input from "juice-base/components/forms/input/index.js";
import ButtonBig from "juice-base/components/button-big/index.js";

import styles from "./styles.module.css";


const UserCouponCodeForm = (props) => {
    const renderForm = (formProps) => {
        const {
            values,
            handleChange,
            handleBlur,
            handleSubmit,
        } = formProps;

        return (
            <form onSubmit={handleSubmit} className={styles.form}>
                <Input
                    name="couponCode"
                    label="Coupon Code"
                    value={values.couponCode}
                    error={props.error}
                    isDisabledMinHeight
                    onChange={(evt) => {
                        handleChange(evt);
                        props.onChange(evt.target.value);
                    }}
                    onBlur={handleBlur}
                />
                <div className={styles.submitButton}>
                    <ButtonBig
                        type="button"
                        onClick={() => {
                            props.onApply(values);
                        }}
                    >
                        Apply
                    </ButtonBig>
                </div>
            </form>
        );
    };

    return (
        <Formik
            initialValues={props.initialValues}
            onSubmit={props.onSubmit}
        >
            {renderForm}
        </Formik>
    );
};

UserCouponCodeForm.defaultProps = {
    initialValues: {},
    error: "",
    onChange: () => {},
    onApply: () => {},
    onSubmit: () => {},
};

export default UserCouponCodeForm;
