import React, { useState } from "react";

import { CardElement } from "@stripe/react-stripe-js";

import classNames from "juice-base/lib/class-names.js";
import Error from "juice-base/components/forms/error/index.js";

import styles from "./styles.module.css";


const InputCard = (props) => {
    const [inputState, setInputState] = useState({
        complete: false,
        empty: true,
        hasError: false,
    });

    /* --- */

    const getElementStyles = () => {
        // TODO: https://stripe.com/docs/js/appendix/style
        const style = {
            base: {
                color: "#000000",
                backgroundColor: "#ffffff",
                fontSize: "16px",
                fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
                fontSmoothing: "antialiased",
                "::placeholder": {
                    color: "#0000008a",
                },
            },
            complete: {
                color: "#000000",
                iconColor: "#1975d2",
            },
            invalid: {
                color: "#ed123a",
                iconColor: "#ed123a",
            },
        };

        if (props.theme === "dark") {
            style.base.color = "#ffffff";
            style.base.iconColor = "#ffffff";
            style.base.backgroundColor = "#2f2f32";
            style.base["::placeholder"].color = "#ffffff";

            style.complete.color = "#ffffff";
            style.complete.iconColor = "#ffffff";

            style.invalid.color = "#f47171";
            style.invalid.iconColor = "#f47171";
        } else if (props.theme === "highContrast") {
            style.base.backgroundColor = "#ffffff";

            style.complete.color = "#000000";
            style.complete.iconColor = "#1975d2";

            style.invalid.color = "#ef4343";
            style.invalid.iconColor = "#ef4343";
        }

        return style;
    };

    /* --- */

    const onChange = (evt) => {
        setInputState({
            complete: evt.complete,
            empty: evt.empty,
            hasError: evt.error,
        });

        props.onChange(evt);
    };

    /* --- */

    const cardElementOptions = {
        style: getElementStyles(),
        hidePostalCode: true,
    };

    const fieldNotCompleted = !inputState.empty && !inputState.complete;

    const fieldClassName = classNames({
        [styles.field]: true,
        [styles.fieldFilled]: !inputState.empty,
        [styles.fieldError]: inputState.hasError,
        [styles.fieldNotCompleted]: fieldNotCompleted,
        // NOTE: Stripe doesn't support tabindex
        [styles.fieldHidden]: props.isDisabledTabIndex,
    });

    let inputError = null;

    if (props.error) {
        inputError = (
            <Error>
                {props.error}
            </Error>
        );
    } else if (fieldNotCompleted) {
        inputError = (
            <Error>
                Please complete field
            </Error>
        );
    }

    return (
        <div className={styles.inputField}>
            <div className={fieldClassName}>
                <CardElement
                    onChange={onChange}
                    options={cardElementOptions}
                />
            </div>
            {inputError}
        </div>
    );
};

InputCard.defaultProps = {
    isDisabledTabIndex: false,
    error: null,
    onChange: () => { },
};

export default InputCard;
