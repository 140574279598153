const isDashboard = (url) => {
    const urlStr = url || "";
    return urlStr.indexOf("/dashboard") === 0;
};

const isClass = (url) => {
    const urlStr = url || "";
    return urlStr.indexOf("/class") === 0;
};

export default {
    isDashboard,
    isClass,
};
