import React, { useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import actions from "juice-base/store/actions.js";

import AudioManagerContext from "juice-base/context/audio-manager/index.js";

import useTitle from "juice-base/hooks/use-title/index.js";

import { withAuth } from "juice-base/components/auth/index.js";
import LayoutContent from "juice-base/components/layout-content/index.js";
import RequestLoader from "juice-base/components/request-loader/index.js";
import WordCard from "juice-base/components/word-card/index.js";

import api from "juice-app/api.js";

import styles from "./styles.module.css";


const VocabularyWord = () => {
    const audioManager = useContext(AudioManagerContext);

    const { wordId } = useParams();
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const store = useSelector((state) => ({
        session: state.user.session,
        wordsById: state.vocabulary.wordsById,
        playerState: state.player.playerState,
    }));

    /* --- */

    const loadWord = async () => {
        const res = await api.vocabulary.getWord({
            session: store.session,
            wordId,
        });

        if (res.ok) {
            dispatch(actions.vocabulary.setVocabularyWord({
                word: res.word,
                definitions: res.definitions,
            }));
        } else {
            navigate("/vocabulary");
        }
    };

    useEffect(() => {
        loadWord();
    }, [wordId]);

    useTitle(() => "Vocabulary", []);

    /* --- */

    if (!store.wordsById[wordId]) {
        return (
            <RequestLoader />
        );
    }

    const trackGroupName = "words";
    const audioData = store.playerState?.[trackGroupName] || {};

    const { word, definitions } = store.wordsById[wordId];

    return (
        <LayoutContent>
            <div className={styles.word}>
                <WordCard
                    word={word}
                    definitions={definitions}
                    audio={audioData}
                    onLoad={(text) => {
                        audioManager.loadWord(text);
                    }}
                    onPlay={(text) => {
                        audioManager.play(trackGroupName, text);
                    }}
                    onStop={(text) => {
                        audioManager.stop(trackGroupName, text);
                    }}
                    onStopAll={(words) => {
                        audioManager.stopAllTracks(trackGroupName, words);
                    }}
                />
            </div>
        </LayoutContent>
    );
};

export default withAuth(["teacher", "student"])(VocabularyWord);
