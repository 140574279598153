import React, { useContext, useEffect, useState } from "react";
import {
    Link,
    useParams,
    useNavigate,
    useLocation,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import device from "juice-base/lib/device.js";
import scroll from "juice-base/lib/scroll.js";
import date from "juice-base/lib/date.js";

import actions from "juice-base/store/actions.js";
import dailyJuiceActions from "juice-base/actions/daily-juice.js";

import text from "juice-base/text/index.js";

import User from "juice-base/project/user.js";
import DailyJuice from "juice-base/project/daily-juice.js";
import Grades from "juice-base/project/grades.js";
import Polls from "juice-base/project/polls.js";

import AudioManagerContext from "juice-base/context/audio-manager/index.js";

import IconGraduationCapEmpty from "juice-base/icons/graduation-cap-empty/index.js";
import IconCalendar from "juice-base/icons/calendar/index.js";

import LayoutContent from "juice-base/components/layout-content/index.js";
import RequestLoader from "juice-base/components/request-loader/index.js";
import MessageWithEmoji from "juice-base/components/message-with-emoji/index.js";
import SelectCustom from "juice-base/components/select-custom/index.js";
import Tiles from "juice-base/components/tiles/index.js";
import DatePickerSingle from "juice-base/components/date-picker-single/index.js";
import PopupPlayerAudio from "juice-base/components/popup-player-audio/index.js";
import Message from "juice-base/components/message/index.js";

import PopupDailyJuiceStatesLegend from "juice-base/business/popup-daily-juice-states-legend/index.js";
import PopupDailyJuiceStats from "juice-base/business/popup-daily-juice-stats/index.js";
import DailyJuiceSponsor from "juice-base/business/daily-juice-sponsor/index.js";
import DailyJuiceActivity from "juice-base/business/daily-juice-activity/index.js";
import DailyJuiceClassAnnouncement from "juice-base/business/daily-juice-class-announcement/index.js";
import DailyJuiceStoryCard from "juice-base/business/daily-juice-story-card/index.js";
import DailyJuiceStoryPollCard from "juice-base/business/daily-juice-story-poll-card/index.js";

import settings from "juice-app/settings.js";
import events from "juice-app/events.js";
import api from "juice-app/api.js";

import Tutorial from "juice-app/containers/tutorial/index.js";
import UserPopupPolls from "juice-app/containers/user-popup-polls/index.js";

import linkStyles from "juice-base/components/link/styles.module.css";
import styles from "./styles.module.css";


const storeSelector = (state) => ({
    session: state.user.session,
    user: state.user.user,
    teacher: state.teacher,

    dimensions: state.device.dimensions,

    juicesById: state.juices.juicesById,
    juicesDates: state.juices.juicesDates,
    isJuicesDatesLoaded: state.juices.isJuicesDatesLoaded,

    sponsors: state.info.sponsors,
    playerState: state.player.playerState,
});

const StudentDailyJuiceV2 = () => {
    const audioManager = useContext(AudioManagerContext);

    /* --- */

    const [pageState, setPageState] = useState({
        error: "",
    });

    const [isVisibleStatsPopup, setIsVisibleStatsPopup] = useState(false);
    const [isVisibleIconsLegendPopup, setIsVisibleIconsLegendPopup] = useState(false);
    const [isVisibleUserPollsPopup, setVisibleUserPollsPopup] = useState(false);

    const [audioPlayer, setAudioPlayer] = useState({
        storyId: null,
    });

    const [selectedGrade, setSelectedGrade] = useState(() => {
        return Grades.getMaxGradeValue();
    });

    /* --- */

    const urlParams = useParams();
    const navigate = useNavigate();
    const loc = useLocation();

    const dispatch = useDispatch();
    const store = useSelector(storeSelector);

    /* --- */

    const { juiceId } = urlParams;

    const juiceGrades = (store.juicesById[juiceId] || {}).grades || [];

    const isPreview = loc.pathname.indexOf("preview") !== -1;
    const isMobile = store.dimensions.width <= 1024;

    const hasRoleTeacher = User.hasRoleTeacher(store.user);

    /* --- */

    const goToJuice = (newJuiceId) => {
        if (isPreview) {
            navigate(`/daily-juice-preview-${settings.previewCode}/${newJuiceId}`);
            return;
        }

        navigate(`/daily-juices/${newJuiceId}`);
    };

    const goToStory = (storyId) => {
        const storyUrl = isPreview
            ? `/daily-juice-preview-${settings.previewCode}/${juiceId}/${storyId}`
            : `/daily-juices/${juiceId}/${storyId}`;

        scroll.scrollToTop();
        navigate(storyUrl);
    };

    /* --- */

    const getJuiceDate = () => {
        return DailyJuice.getJuiceDateByJuiceId(store.juicesById, juiceId);
    };

    /* --- */

    const addEventReadStoryClick = (values) => {
        events.dailyJuice.storyOpen({
            disabled: isPreview,
            session: store.session,
            ...values,
        });
    };

    const addEventVideoPlay = (values) => {
        events.dailyJuice.videoPlay({
            disabled: isPreview,
            session: store.session,
            ...values,
        });
    };

    const addEventVideoPause = (values) => {
        events.dailyJuice.videoPause({
            disabled: isPreview,
            session: store.session,
            ...values,
        });
    };

    const addEventVideoEnded = (values) => {
        events.dailyJuice.videoEnded({
            disabled: isPreview,
            session: store.session,
            ...values,
        });
    };

    /* --- */

    const onOpenStatsPopup = () => {
        setIsVisibleStatsPopup(true);
    };

    const onCloseStatsPopup = () => {
        setIsVisibleStatsPopup(false);
    };

    /* --- */

    const onOpenAudioPlayer = (storyId) => {
        if (!audioPlayer.storyId) {
            setAudioPlayer((prev) => ({
                ...prev,
                storyId,
            }));

            audioManager.loadStory(storyId);
            return;
        }

        setAudioPlayer((prev) => ({
            ...prev,
            storyId: null,
        }));

        window.setTimeout(() => {
            setAudioPlayer((prev) => ({
                ...prev,
                storyId,
            }));

            audioManager.loadStory(storyId);
        }, 300);
    };

    const onCloseAudioPlayer = () => {
        setAudioPlayer((prev) => ({
            ...prev,
            storyId: null,
        }));
    };

    /* --- */

    const onOpenUserPollsPopup = () => {
        setVisibleUserPollsPopup(true);
    };

    const onCloseUserPollsPopup = () => {
        setVisibleUserPollsPopup(false);
    };

    /* --- */

    const loadTeacherDates = (grade, callbacks) => {
        dispatch(dailyJuiceActions.loadJuicesDatesByGrade(
            { api, actions },
            { grade },
            { onLoad: callbacks?.onLoad },
        ));
    };

    const loadJuice = async (params) => {
        const myGetJuice = isPreview
            ? api.dailyJuices.getUnpublishedJuice
            : api.dailyJuices.getJuice;

        const res = await myGetJuice({
            session: store.session,
            juiceId: params.juiceId,
        });

        if (!res.ok) {
            setPageState((prev) => ({
                ...prev,
                error: res.error || text.error,
            }));
            return;
        }

        events.dailyJuice.open({
            disabled: isPreview,
            session: store.session,
            juiceId: res.data.id,
            grades: res.data.grades,
        });

        const juice = res.data || {};

        if (params.onLoad) {
            params.onLoad(juice);
        }

        dispatch(actions.juices.setJuice({
            juice,
        }));
    };

    const loadJuicesDates = async (grade) => {
        if (hasRoleTeacher) {
            loadTeacherDates(grade);
            return;
        }

        dispatch(dailyJuiceActions.loadJuicesDates({ api, actions }));
    };

    /* --- */

    const onDateChange = (value) => {
        const nDate = date.newDate(value);

        const jId = DailyJuice.getJuiceIdByDate(nDate, store.juicesDates);

        if (!jId) {
            return;
        }

        loadJuice({ juiceId: jId });
        goToJuice(jId);
    };

    const onTeacherDatesLoaded = (dates) => {
        if (dates.length === 0) {
            return;
        }

        const selectedDate = getJuiceDate();

        let jId = DailyJuice.getJuiceIdByDate(selectedDate, dates);

        if (!jId) {
            jId = dates?.[0]?.juiceId;
        }

        loadJuice({ juiceId: jId });
        goToJuice(jId);
    };

    const onGradeChange = (value) => {
        setSelectedGrade(value);

        dispatch(actions.juices.clearJuice({ juiceId }));

        loadTeacherDates(value, {
            onLoad: onTeacherDatesLoaded,
        });
    };

    const onStoryReadMore = (storyId) => {
        addEventReadStoryClick({
            juiceId,
            storyId,
        });

        dispatch(actions.juices.setStoryViewed({
            juiceId,
            storyId,
        }));

        goToStory(storyId);
    };

    /* --- */

    const onSubmitPollAnswer = async (values = {}) => {
        const juiceData = {
            juiceId,
            storyId: values.storyId,
        };

        if (isPreview) {
            dispatch(actions.juices.setJuicePollAnswered({
                ...juiceData,
                answerId: values.answerId,
            }));
            return;
        }

        addEventReadStoryClick(juiceData);

        dispatch(actions.juices.setStoryViewed(juiceData));
        dispatch(actions.juices.setJuicePollLoading(juiceData));

        const res = await api.polls.setPollAnswer({
            session: store.session,
            juiceId,
            pollId: values.pollId,
            answerId: values.answerId,
        });

        if (!res.ok) {
            dispatch(actions.juices.setJuicePollError({
                ...juiceData,
                error: res.error || text.error,
            }));
            return;
        }

        events.student.selectPollAnswer({
            ...juiceData,
            session: store.session,
            answerId: values.answerId,
        });

        dispatch(actions.juices.setJuicePollLoaded({
            ...juiceData,
            poll: res.poll,
        }));

        loadJuice({ juiceId });
    };

    const loadStudentDailyJuice = async (params) => {
        const res = await api.dailyJuices.getStudentDailyJuiceByJuiceId({
            juiceId: params.juiceId,
            session: store.session,
        });

        if (!res.ok) {
            return;
        }

        // NOTE: check if student opened correct juice by grade
        const isCorrectJuiceId = res.juiceId === parseInt(params.juiceId, 10);

        const jId = isCorrectJuiceId
            ? params.juiceId
            : res.juiceId;

        loadJuice({
            juiceId: jId,
        });

        if (!isCorrectJuiceId) {
            goToJuice(jId);
        }
    };

    /* --- */

    const onInitJuiceLoaded = (juice) => {
        const firstGrade = juice?.grades?.[0];
        const grade = Grades.getGradesRangeByGrade(firstGrade);

        if (!store.isJuicesDatesLoaded) {
            loadJuicesDates(grade);
        }

        setSelectedGrade(grade);
    };

    /* --- */

    useEffect(() => {
        if (isPreview || hasRoleTeacher) {
            loadJuice({
                juiceId,
                onLoad: onInitJuiceLoaded,
            });
            return;
        }

        loadJuicesDates();
        loadStudentDailyJuice({ juiceId });

        return () => {
            events.dailyJuice.close({
                session: store.session,
                juiceId,
            });
        };
    }, []);

    useEffect(() => {
        dispatch(actions.juices.setLastVisitedJuice({
            lastVisitedJuice: juiceId,
        }));
    }, [juiceId]);

    /* --- */

    const renderTutorial = () => {
        return (
            <Tutorial name="student-daily-juice" />
        );
    };

    const renderStatsPopup = (stories) => {
        if (!isVisibleStatsPopup) {
            return null;
        }

        const quizzesStats = DailyJuice.getQuizzesStats(stories);

        return (
            <PopupDailyJuiceStats
                stats={quizzesStats}
                sponsors={store.sponsors}
                isWindows={device.isWindows}
                onSponsorClick={() => {
                    events.dailyJuice.lastPageSponsorClick({
                        disabled: isPreview,
                        session: store.session,
                        juiceId,
                        grades: juiceGrades,
                    });
                }}
                onScrollTo={() => {
                    if (quizzesStats.firstSkipped) {
                        goToStory(quizzesStats.firstSkipped);
                    }
                }}
                onClose={onCloseStatsPopup}
            />
        );
    };

    const renderLegendPopup = () => {
        return (
            <PopupDailyJuiceStatesLegend
                onClose={() => {
                    setIsVisibleIconsLegendPopup(false);
                }}
            />
        );
    };

    const renderPlayerAudioPopup = (stories) => {
        const story = DailyJuice.getStoryById(stories, audioPlayer.storyId);

        if (!story) {
            return null;
        }

        const storyId = story.ID;

        let img = null;

        if (story.featuredImage && story.featuredImage.url) {
            img = story.featuredImage.url;
        }

        const trackGroupName = "juiceStories";
        const trackId = storyId;
        const audioData = store.playerState?.[trackGroupName]?.[trackId];

        return (
            <PopupPlayerAudio
                key={`player-audio-story-${story.ID}`}
                image={img}
                title={story.title}
                category={story.categoryName}
                audio={audioData}
                onPlay={() => {
                    audioManager.play(trackGroupName, trackId);
                }}
                onPause={() => {
                    audioManager.pause(trackGroupName, trackId);
                }}
                onRewind={() => {
                    audioManager.rewind(trackGroupName, trackId);
                }}
                onForward={() => {
                    audioManager.forward(trackGroupName, trackId);
                }}
                onChangeRate={(rate) => {
                    audioManager.changeRate(trackGroupName, trackId, rate);
                }}
                onClose={() => {
                    audioManager.stop(trackGroupName, trackId);
                    onCloseAudioPlayer();
                }}
            />
        );
    };

    const renderUserPollsPopup = () => {
        return (
            <UserPopupPolls
                onClose={onCloseUserPollsPopup}
            />
        );
    };

    const renderPopups = (stories) => {
        const ps = [];

        if (isVisibleStatsPopup) {
            ps.push(renderStatsPopup(stories));
        }

        if (isVisibleIconsLegendPopup) {
            ps.push(renderLegendPopup());
        }

        if (audioPlayer.storyId) {
            ps.push(renderPlayerAudioPopup(stories));
        }

        if (isVisibleUserPollsPopup) {
            ps.push(renderUserPollsPopup());
        }

        return ps;
    };

    const renderGradeSelector = () => {
        if (!hasRoleTeacher) {
            return null;
        }

        return (
            <SelectCustom
                selected={selectedGrade}
                options={Grades.getGradesOptions()}
                icon={<IconGraduationCapEmpty isBlack />}
                onSelect={onGradeChange}
                withBorderRadius
            />
        );
    };

    const renderJuiceSelector = () => {
        const availableDates = (store.juicesDates || []).map((juice) => juice.date);

        const juiceDate = getJuiceDate();

        return (
            <DatePickerSingle
                dataComment="juice-selector"
                date={juiceDate}
                availableDates={availableDates}
                icon={<IconCalendar isBlack />}
                onChange={onDateChange}
                withBorder
            />
        );
    };

    const renderStory = (story, index) => {
        const storyId = story.ID;

        const poll = Polls.getStoryPoll(story);

        if (poll && hasRoleTeacher) {
            poll.isDisabled = true;
            poll.isVisibleResults = true;
        }

        if (poll) {
            return (
                <DailyJuiceStoryPollCard
                    key={storyId}
                    storyIndex={index}
                    story={story}
                    poll={poll}
                    onShowAllPolls={onOpenUserPollsPopup}
                    onReadMore={() => {
                        onStoryReadMore(storyId);
                    }}
                    onSubmitPoll={(values) => {
                        onSubmitPollAnswer({
                            ...values,
                            storyId,
                        });
                    }}
                />
            );
        }

        let videoCaptionUrl = "";

        if (story.featuredVideo?.url) {
            videoCaptionUrl = api.videos.getVideoCaptionURL({
                session: store.session,
                id: story.featuredVideo.id,
            });
        }

        return (
            <DailyJuiceStoryCard
                key={storyId}
                story={story}
                storyIndex={index}
                videoCaptionUrl={videoCaptionUrl}
                onAudioPlay={() => {
                    events.dailyJuice.storyListen({
                        session: store.session,
                        storyId,
                    });

                    onOpenAudioPlayer(storyId);
                }}
                onVideoPlayStart={(videoSrc, videoId) => {
                    dispatch(actions.juices.setStoryVideoViewed({
                        juiceId,
                        storyId,
                        videoId,
                    }));

                    addEventVideoPlay({
                        juiceId,
                        grades: juiceGrades,
                        storyId,
                        videoSrc,
                        videoId,
                    });
                }}
                onVideoPlayPause={(videoSrc, videoId) => {
                    addEventVideoPause({
                        juiceId,
                        grades: juiceGrades,
                        storyId,
                        videoSrc,
                        videoId,
                    });
                }}
                onVideoPlayEnd={(videoSrc, videoId) => {
                    addEventVideoEnded({
                        juiceId,
                        grades: juiceGrades,
                        storyId,
                        videoSrc,
                        videoId,
                    });
                }}
                onReadMore={() => {
                    onStoryReadMore(storyId);
                }}
                isDefaultVideo={!device.isChrome}
                withQuizStatus={!hasRoleTeacher}
            />
        );
    };

    const renderStories = (stories, classCustomContent) => {
        const items = [];

        if (classCustomContent && !hasRoleTeacher) {
            const storyAds = (
                <DailyJuiceSponsor
                    sponsor={classCustomContent}
                />
            );

            items.push(storyAds);

            const classAnnouncement = (
                <DailyJuiceClassAnnouncement
                    announcement={classCustomContent}
                />
            );

            items.push(classAnnouncement);
        }

        stories.forEach((s, index) => {
            items.push(renderStory(s, index));
        });

        const djActivity = (
            <DailyJuiceActivity
                key="activity"
                stories={stories}
                onGoToStory={goToStory}
                onShowReport={onOpenStatsPopup}
                onInfoClick={() => {
                    setIsVisibleIconsLegendPopup(true);
                }}
                withScoreReport={!hasRoleTeacher}
            />
        );

        items.push(djActivity);

        return items;
    };

    /* --- */

    if (pageState.error) {
        return (
            <div className={styles.errorMessage}>
                <MessageWithEmoji
                    header={text.issueNotAvailable}
                    message={text.cantLoadJuice}
                />

                <Message>
                    {pageState.error}
                </Message>

                <div className={styles.errorMessageLinks}>
                    <Link
                        to="/daily-juices"
                        className={linkStyles.link}
                    >
                        Go to Daily Juices
                    </Link>
                </div>
            </div>
        );
    }

    if (!store.juicesById[juiceId] || !store.isJuicesDatesLoaded) {
        return (
            <RequestLoader />
        );
    }

    const juice = store.juicesById[juiceId];
    const studentStories = DailyJuice.getStudentStories(juice.stories.juice);

    const stories = renderStories(
        studentStories,
        juice.classCustomContent,
    );

    return (
        <>
            {renderTutorial()}
            {renderPopups(studentStories)}

            <LayoutContent>
                <div className={styles.dailyJuice}>
                    <div className={styles.juiceSelectors}>
                        {renderJuiceSelector()}
                        {renderGradeSelector()}
                    </div>

                    <Tiles isMobile={isMobile}>
                        {stories}
                    </Tiles>
                </div>
            </LayoutContent>
        </>
    );
};

export default StudentDailyJuiceV2;
