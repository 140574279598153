// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".E6IMtXxIWsAadriTi826 {\n    width: 0;\n    height: 0;\n    border-left: 0.5rem solid transparent;\n    border-right: 0.5rem solid transparent;\n    border-bottom: 1rem solid var(--standards-shape-bg-color-1);\n}\n\n.AvJXUHvwRaJue41CJxOz {\n    width: 1rem;\n    height: 1rem;\n\n    background-color: var(--standards-shape-bg-color-2);\n}\n\n.lTW3tikNqvyvqpeBsCM7 {\n    width: 1rem;\n    height: 1rem;\n\n    border-radius: 50%;\n\n    background-color: var(--standards-shape-bg-color-3);\n}", "",{"version":3,"sources":["webpack://./base/business/standards-shape/styles.module.css"],"names":[],"mappings":"AAAA;IACI,QAAQ;IACR,SAAS;IACT,qCAAqC;IACrC,sCAAsC;IACtC,2DAA2D;AAC/D;;AAEA;IACI,WAAW;IACX,YAAY;;IAEZ,mDAAmD;AACvD;;AAEA;IACI,WAAW;IACX,YAAY;;IAEZ,kBAAkB;;IAElB,mDAAmD;AACvD","sourcesContent":[".shapeTriangle {\n    width: 0;\n    height: 0;\n    border-left: 0.5rem solid transparent;\n    border-right: 0.5rem solid transparent;\n    border-bottom: 1rem solid var(--standards-shape-bg-color-1);\n}\n\n.shapeSquare {\n    width: 1rem;\n    height: 1rem;\n\n    background-color: var(--standards-shape-bg-color-2);\n}\n\n.shapeCircle {\n    width: 1rem;\n    height: 1rem;\n\n    border-radius: 50%;\n\n    background-color: var(--standards-shape-bg-color-3);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"shapeTriangle": "E6IMtXxIWsAadriTi826",
	"shapeSquare": "AvJXUHvwRaJue41CJxOz",
	"shapeCircle": "lTW3tikNqvyvqpeBsCM7"
};
export default ___CSS_LOADER_EXPORT___;
