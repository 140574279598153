// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".oEJciayib8X4yqjuCZtw {\n    display: grid;\n    grid-gap: 3rem;\n    padding: 3rem;\n}\n\n.E1FnGoh_t_wDdZVTYBMj {\n    width: 12rem;\n    height: auto;\n    margin: 0 auto;\n}\n\n.reJoNtQlwI2P5nPBvMUV {\n    display: grid;\n    grid-gap: 1rem;\n}\n\n.NuN95ZaVdieVp3rPuD8L {\n    font-size: 3rem;\n    font-weight: 600;\n    text-align: center;\n}\n\n.tDBZMmMSJDgM8kmB5x8t {\n    text-align: center;\n}\n\n.mVIMCJS1qK0rpmqwAYsp {\n    display: grid;\n    align-items: center;\n    justify-content: center;\n\n    width: 4rem;\n}\n\n.mVIMCJS1qK0rpmqwAYsp > svg {\n    width: 2rem;\n    height: 2rem;\n}\n", "",{"version":3,"sources":["webpack://./base/business/popup-emoji/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,cAAc;IACd,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;;IAEvB,WAAW;AACf;;AAEA;IACI,WAAW;IACX,YAAY;AAChB","sourcesContent":[".content {\n    display: grid;\n    grid-gap: 3rem;\n    padding: 3rem;\n}\n\n.emojiImage {\n    width: 12rem;\n    height: auto;\n    margin: 0 auto;\n}\n\n.emojiMessage {\n    display: grid;\n    grid-gap: 1rem;\n}\n\n.emojiMessageTitle {\n    font-size: 3rem;\n    font-weight: 600;\n    text-align: center;\n}\n\n.emojiMessageText {\n    text-align: center;\n}\n\n.feedbackIcon {\n    display: grid;\n    align-items: center;\n    justify-content: center;\n\n    width: 4rem;\n}\n\n.feedbackIcon > svg {\n    width: 2rem;\n    height: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "oEJciayib8X4yqjuCZtw",
	"emojiImage": "E1FnGoh_t_wDdZVTYBMj",
	"emojiMessage": "reJoNtQlwI2P5nPBvMUV",
	"emojiMessageTitle": "NuN95ZaVdieVp3rPuD8L",
	"emojiMessageText": "tDBZMmMSJDgM8kmB5x8t",
	"feedbackIcon": "mVIMCJS1qK0rpmqwAYsp"
};
export default ___CSS_LOADER_EXPORT___;
