import React from "react";

import classNames from "juice-base//lib/class-names.js";

import styles from "./styles.module.css";


const CheckboxWithStatesWrapper = (props) => {
    const wrapperClassName = classNames({
        [styles.wrapper]: true,
        [styles.className]: props.className,
    });

    let label2 = null;

    if (props.label2) {
        label2 = (
            <div className={styles.wrapperLabel2}>
                {props.label2}
            </div>
        );
    }

    return (
        <div
            className={wrapperClassName}
            onClick={props.onClick}
            onKeyPress={props.onClick}
            tabIndex="-1"
            role="button"
        >
            {props.children}
            <div className={styles.wrapperLabels}>
                <div>
                    {props.label}
                </div>
                {label2}
            </div>
        </div>
    );
};

CheckboxWithStatesWrapper.defaultProps = {
    className: "",
    label: "",
    label2: "",
    children: null,
    onClick: () => { },
};

export default CheckboxWithStatesWrapper;
