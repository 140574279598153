import { debugEvent } from "juice-base/lib/debug.js";


export default (methods, ids) => {
    return {
        homeSelectStudent(params) {
            const event = {
                session: params.session,
                locationId: ids.location.home,
                eventId: ids.event.guardianHomeSelectStudent,
                objectId: params.studentId,
            };

            debugEvent("Guardian select student on home", event);
            methods.sendEvent(event);
        },

        studentEditStudent(params) {
            const event = {
                session: params.session,
                locationId: ids.location.student,
                eventId: ids.event.guardianStudentEditStudent,
                objectId: params.studentId,
            };

            debugEvent("Guardian edit student", event);
            methods.sendEvent(event);
        },

        selectStudent(params) {
            const event = {
                session: params.session,
                locationId: ids.location.student,
                eventId: ids.event.guardianStudentSelectStudent,
                objectId: params.studentId,
            };

            debugEvent("Guardian select student", event);
            methods.sendEvent(event);
        },
    };
};
