import React from "react";

import IconWrapper from "juice-base/icons/wrapper/index.js";


const IconPlayerPlay = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={iconProps.className}
                >
                    {iconProps.title}
                    <path
                        fill="currentColor"
                        d="M8 5v14l11-7z"
                    />
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconPlayerPlay;
