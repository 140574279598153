import React from "react";

import IconWrapper from "juice-base/icons/wrapper/index.js";


const IconLocation = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    className={iconProps.className}
                    width="16"
                    height="20"
                    viewBox="0 0 16 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill="currentColor"
                        d="M8 0.000976562C3.8 0.000976562 0 3.22098 0 8.20098C0 11.521 2.67 15.451 8 20.001C13.33 15.451 16 11.521 16 8.20098C16 3.22098 12.2 0.000976562 8 0.000976562ZM8 10.001C6.9 10.001 6 9.10098 6 8.00098C6 6.90098 6.9 6.00098 8 6.00098C9.1 6.00098 10 6.90098 10 8.00098C10 9.10098 9.1 10.001 8 10.001Z"
                    />
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconLocation;
