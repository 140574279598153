import React, { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";

import DailyJuice from "juice-base/project/daily-juice.js";

import device from "juice-base/lib/device.js";
import actions from "juice-base/store/actions.js";
import vocabularyActions from "juice-base/actions/vocabulary.js";

import AudioManagerContext from "juice-base/context/audio-manager/index.js";

import usePopup from "juice-base/hooks/use-popup/index.js";
import useExtraJuicePopup from "juice-base/hooks/use-extra-juice-popup/index.js";

import PopupImage from "juice-base/components/popup-image/index.js";

import PopupWord from "juice-base/business/popup-word/index.js";
import PopupStory from "juice-base/business/popup-story/index.js";
import PopupExtraJuice from "juice-base/components/popup-extra-juice/index.js";

import api from "juice-app/api.js";


const storeSelector = (state) => ({
    dimensions: state.device.dimensions,
    session: state.user.session,
    storiesById: state.juiceStories.storiesById,
    wordPopup: state.vocabulary.popup,
    wordsByName: state.vocabulary.wordsByName,
    playerState: state.player.playerState,
});

const PopupStoryContainer = (props) => {
    const audioManager = useContext(AudioManagerContext);

    const [imagePopup, setImagePopup] = useState(null);

    const storyPopup = usePopup();
    const extraJuicePopup = useExtraJuicePopup();

    const dispatch = useDispatch();
    const store = useSelector(storeSelector);

    const story = store.storiesById[props.storyId];

    /* --- */

    const getExtraJuice = (sId, ejId) => {
        return DailyJuice.getExtraJuiceById([story], ejId);
    };

    /* --- */

    const onClose = () => {
        storyPopup.close();
        props.onClose();
    };

    /* --- */

    const onOpenImagePopup = (image) => {
        setImagePopup(image);
    };

    const onCloseImagePopup = () => {
        setImagePopup(null);
    };

    /* --- */

    const onWordClick = (word) => {
        dispatch(vocabularyActions.openPopup({
            api,
            actions,
        }, {
            session: store.session,
            word,
        }));
    };

    const onCloseWordPopup = () => {
        dispatch(vocabularyActions.closePopup({ actions }));
    };

    /* --- */

    const onLoadingJuiceStory = async () => {
        audioManager.loadStory(props.storyId);
    };

    const onPlayJuiceStory = (trackId) => {
        audioManager.play("juiceStories", trackId);
    };

    const onPauseJuiceStory = (trackId) => {
        audioManager.pause("juiceStories", trackId);
    };

    const onRewindJuiceStory = (trackId) => {
        audioManager.rewind("juiceStories", trackId);
    };

    const onForwardJuiceStory = (trackId) => {
        audioManager.forward("juiceStories", trackId);
    };

    const onChangeRateJuiceStory = (trackId, rate) => {
        audioManager.changeRate("juiceStories", trackId, rate);
    };

    const onStopJuiceStory = (trackId) => {
        audioManager.stop("juiceStories", trackId);
    };

    /* --- */

    const onLoadingExtraJuiceStory = (extraJuiceId) => {
        audioManager.loadExtraJuice(extraJuiceId);
    };

    const onPlayExtraJuice = (trackId) => {
        audioManager.play("extraJuices", trackId);
    };

    const onPauseExtraJuice = (trackId) => {
        audioManager.pause("extraJuices", trackId);
    };

    const onRewindExtraJuice = (trackId) => {
        audioManager.rewind("extraJuices", trackId);
    };

    const onForwardExtraJuice = (trackId) => {
        audioManager.forward("extraJuices", trackId);
    };

    const onChangeRateExtraJuice = (trackId, rate) => {
        audioManager.changeRate("extraJuices", trackId, rate);
    };

    const onStopExtraJuice = (trackId) => {
        audioManager.stop("extraJuices", trackId);
    };

    /* --- */

    const onOpenExtraJuicePopup = (storyId, ejId) => {
        if (storyId && ejId) {
            extraJuicePopup.open(storyId, ejId);
        }
    };

    const onCloseExtraJuicePopup = () => {
        extraJuicePopup.close();
    };

    /* --- */

    const onShowExtraJuiceStoryPlayer = (extraJuiceId) => {
        extraJuicePopup.showPlayer();
        onLoadingExtraJuiceStory(extraJuiceId);
    };

    const onHideExtraJuiceStoryPlayer = () => {
        extraJuicePopup.hidePlayer();
    };

    /* --- */

    const renderImagePopup = () => {
        return (
            <PopupImage
                image={imagePopup}
                onClose={onCloseImagePopup}
            />
        );
    };

    const renderWordPopup = () => {
        if (!store.wordPopup.isVisible) {
            return null;
        }

        const trackGroupName = "words";
        const audioData = store.playerState?.[trackGroupName] || {};

        return (
            <PopupWord
                wordsByName={store.wordsByName}
                wordPopup={store.wordPopup}
                hideScrollbar={props.hideScrollbar}
                audio={audioData}
                onAudioLoad={(txt) => {
                    audioManager.loadWord(txt);
                }}
                onAudioPlay={(txt) => {
                    audioManager.play(trackGroupName, txt);
                }}
                onAudioStop={(txt) => {
                    audioManager.stop(trackGroupName, txt);
                }}
                onAudioStopAll={(words) => {
                    audioManager.stopAllTracks(trackGroupName, words);
                }}
                onClose={onCloseWordPopup}
            />
        );
    };

    const renderExtraJuicePopup = () => {
        if (!extraJuicePopup.isVisible()) {
            return null;
        }

        const eJuice = getExtraJuice(
            extraJuicePopup.state.storyId,
            extraJuicePopup.state.ejId,
        );

        if (!eJuice) {
            return null;
        }

        const trackId = eJuice.id;
        let audioData = null;

        if (extraJuicePopup.state.isVisiblePlayer) {
            audioData = store.playerState?.extraJuices?.[trackId] || null;
        }

        return (
            <PopupExtraJuice
                key={`popup-extra-juice-${extraJuicePopup.state.storyId}`}
                storyId={extraJuicePopup.state.storyId}
                extraJuice={eJuice}
                hideScrollbar={props.hideScrollbar}
                audio={audioData}
                onWordClick={onWordClick}
                onAudioPlay={() => {
                    onShowExtraJuiceStoryPlayer(eJuice.id);
                }}
                onPlay={() => {
                    onPlayExtraJuice(trackId);
                }}
                onPause={() => {
                    onPauseExtraJuice(trackId);
                }}
                onRewind={() => {
                    onRewindExtraJuice(trackId);
                }}
                onForward={() => {
                    onForwardExtraJuice(trackId);
                }}
                onChangeRate={(rate) => {
                    onChangeRateExtraJuice(trackId, rate);
                }}
                onPlayerClose={() => {
                    onStopExtraJuice(trackId);
                    onHideExtraJuiceStoryPlayer();
                }}
                onClose={onCloseExtraJuicePopup}
            />
        );
    };

    /* --- */

    const loadStory = async () => {
        if (!props.storyId) {
            return;
        }

        const res = await api.juiceStories.getStory({
            session: store.session,
            storyId: props.storyId,
        });

        if (res.ok) {
            dispatch(actions.juiceStories.setStory({
                story: res.story,
            }));
        }
    };

    useEffect(() => {
        return () => {
            dispatch(vocabularyActions.closePopup({ actions }));
        };
    }, []);

    useEffect(() => {
        loadStory();
    }, [props.storyId]);

    /* --- */

    if (!props.storyId) {
        return null;
    }

    const storyCallbacks = {
        onLoadingStory: onLoadingJuiceStory,
        onFirstPlay: () => { },
        onPlay: onPlayJuiceStory,
        onPause: onPauseJuiceStory,
        onRewind: onRewindJuiceStory,
        onForward: onForwardJuiceStory,
        onChangeRate: onChangeRateJuiceStory,
        onStop: onStopJuiceStory,
    };

    let videoCaptionSrc = "";

    if (story?.featuredVideo?.id) {
        videoCaptionSrc = api.videos.getVideoCaptionURL({
            id: story.featuredVideo.id,
            session: store.session,
        });
    }

    const audioData = store.playerState?.juiceStories || {};

    return (
        <>
            <PopupStory
                dimensions={store.dimensions}
                story={story}
                videoCaptionSrc={videoCaptionSrc}
                isDefaultVideo={!device.isChrome}
                hideScrollbar={props.hideScrollbar}
                audioState={audioData}
                storyCallbacks={storyCallbacks}
                onImageClick={onOpenImagePopup}
                onWordClick={onWordClick}
                onOpenExtraJuice={onOpenExtraJuicePopup}
                onClose={onClose}
            />

            {renderExtraJuicePopup()}
            {renderWordPopup()}
            {renderImagePopup()}
        </>
    );
};

PopupStoryContainer.defaultProps = {
    storyId: null,
    hideScrollbar: false,
    onClose: () => { },
};

export default PopupStoryContainer;
