import { debugEvent } from "juice-base/lib/debug.js";


export default (methods, ids) => {
    return {
        lastPageSponsorClick(params) {
            if (params.disabled) {
                return;
            }

            const event = {
                session: params.session,
                objectId: params.juiceId,
                locationId: ids.location.dailyJuice,
                eventId: ids.event.juiceSponsorLast,
            };

            methods.sendEvent(event);

            debugEvent("Daily Juice last page sponsor click", event);
        },

        open(params) {
            if (params.disabled) {
                return;
            }

            const event = {
                session: params.session,
                objectId: params.juiceId,
                locationId: ids.location.dailyJuice,
                eventId: ids.event.juiceOpen,
            };

            methods.sendEvent(event);

            debugEvent("Daily Juice open", event);
        },

        submitQuiz(params) {
            if (params.disabled) {
                return;
            }

            const event = {
                session: params.session,
                objectId: params.juiceId,
                subObjectId: params.quizId,
                subSubObjectId: params.questionId,

                locationId: ids.location.dailyJuice,
                eventId: ids.event.juiceStoryQuizQuiestionSubmit,
            };

            debugEvent("Daily Juice Sumbit Quiz", event);

            methods.sendEvent(event);
        },

        quizAnswerClick(params) {
            if (params.disabled) {
                return;
            }

            const event = {
                session: params.session,
                objectId: params.juiceId,
                subObjectId: params.quizId,
                subSubObjectId: params.answerId,

                locationId: ids.location.dailyJuice,
                eventId: ids.event.dailyJuiceOnQuizAnswerClick,
            };

            debugEvent("Daily Juice quiz answer click", event);

            methods.sendEvent(event);
        },

        onPauseWordToSpeech(params) {
            if (params.disabled) {
                return;
            }

            const event = {
                session: params.session,
                objectId: params.juiceId,
                subObjectId: params.wordId,

                locationId: ids.location.dailyJuice,
                eventId: ids.event.juiceStoryWordSpeechPause,
            };

            debugEvent("Daily Juice word to speech pause", event);

            methods.sendEvent(event);
        },

        onPlayWordToSpeech(params) {
            if (params.disabled) {
                return;
            }

            const event = {
                session: params.session,

                objectId: params.juiceId,
                subObjectId: params.storyId || 0,
                subSubObjectId: params.wordId,

                locationId: ids.location.dailyJuice,
                eventId: ids.event.juiceStoryWordSpeechPlay,
            };

            debugEvent("Daily Juice on play word to speech", event);

            methods.sendEvent(event);
        },

        onDefinitionPlay(params) {
            if (params.disabled) {
                return;
            }

            const event = {
                session: params.session,

                objectId: params.juiceId,
                subObjectId: params.storyId || 0,
                subSubObjectId: params.wordId,

                locationId: ids.location.dailyJuice,
                eventId: ids.event.juiceStoryDefinitionPlay,
            };

            debugEvent("Daily Juice on Definition Play", event);

            methods.sendEvent(event);
        },

        onVocabularyWordClick(params) {
            if (params.disabled) {
                return;
            }

            let eventId = ids.event.juiceStoryVocabOpen;
            let objectId = params.juiceId;
            let subObjectId = params.storyId;
            let subSubObjectId = params.wordId;

            if (params.openedFrom === "extra-juice") {
                eventId = ids.event.vocabOpenFromExtraJuice;
                objectId = params.storyId;
                subObjectId = params.wordId;
                subSubObjectId = null;
            }

            const event = {
                session: params.session,

                objectId,
                subObjectId,
                subSubObjectId,

                locationId: ids.location.dailyJuice,
                eventId,
            };

            debugEvent("Daily Juice on wocabulary click", event);

            methods.sendEvent(event);
        },

        storyOpen(params) {
            if (params.disabled) {
                return;
            }

            const event = {
                session: params.session,

                objectId: params.juiceId,
                subObjectId: params.storyId,

                locationId: ids.location.dailyJuice,
                eventId: ids.event.juiceStoryOpen,
            };

            debugEvent("Daily Juice on story open", event);

            methods.sendEvent(event);
        },

        featuredImageClick(params) {
            if (params.disabled) {
                return;
            }

            const event = {
                session: params.session,

                objectId: params.juiceId,
                subObjectId: params.storyId,
                subSubObjectId: params.imageId,

                locationId: ids.location.dailyJuice,
                eventId: ids.event.juiceStoryImageOpen,
            };

            debugEvent("Daily Juice featured click", event);

            methods.sendEvent(event);
        },

        videoEnded(params) {
            if (params.disabled) {
                return;
            }

            const event = {
                session: params.session,

                objectId: params.juiceId,
                subObjectId: params.storyId,
                subSubObjectId: params.videoId,

                locationId: ids.location.dailyJuice,
                eventId: ids.event.juiceStoryVideoEnd,
            };

            debugEvent("Daily Juice video ended", event);

            methods.sendEvent(event);
        },

        videoPause(params) {
            if (params.disabled) {
                return;
            }

            const event = {
                session: params.session,

                objectId: params.juiceId,
                subObjectId: params.storyId,
                subSubObjectId: params.videoId,

                locationId: ids.location.dailyJuice,
                eventId: ids.event.juiceStoryVideoPause,
            };

            debugEvent("Daily Juice video pause", event);

            methods.sendEvent(event);
        },

        videoPlay(params) {
            const event = {
                session: params.session,

                objectId: params.juiceId,
                subObjectId: params.storyId,
                subSubObjectId: params.videoId,

                locationId: ids.location.dailyJuice,
                eventId: ids.event.juiceStoryVideoPlay,
            };

            debugEvent("Daily Juice video play", event);

            methods.sendEvent(event);
        },

        close(params) {
            const event = {
                session: params.session,

                objectId: params.juiceId,

                locationId: ids.location.dailyJuice,
                eventId: ids.event.juiceClose,
            };

            debugEvent("Daily Juice close", event);

            methods.sendEvent(event);
        },

        storyListen(params) {
            const event = {
                session: params.session,

                objectId: params.juiceId,
                subObjectId: params.storyId,

                locationId: ids.location.dailyJuice,
                eventId: ids.event.dailyJuiceStoryListen,
            };

            debugEvent("Daily Juice story listen", event);

            methods.sendEvent(event);
        },

        extraJuiceOpen(params) {
            if (params.disabled) {
                return;
            }

            let locationId = ids.location.dailyJuice;
            let eventId = ids.event.extraJuiceOpenFromWordInStory;

            if (params.openedBy === "story-button") {
                eventId = ids.event.extraJuiceOpenFromButtonInStory;
            }

            if (params.openedBy === "explore") {
                eventId = ids.event.extraJuiceOpenFromExplore;
                locationId = ids.location.home;
            }

            const event = {
                session: params.session,
                locationId,
                eventId,
                objectId: params.juiceId,
                subObjectId: params.storyId,
                subSubObjectId: params.extraJuiceId,
            };

            debugEvent("Daily Juice story listen", event);

            methods.sendEvent(event);
        },

        storyReadTime(params) {
            if (params.disabled) {
                return;
            }

            const event = {
                session: params.session,
                locationId: ids.location.dailyJuice,
                eventId: ids.event.storyReadTime,
                objectId: params.juiceId,
                subObjectId: params.storyId,
                timeTook: params.timeTook,
            };

            debugEvent("Daily Juice story read time", event);

            methods.sendEvent(event);
        },

        extraJuiceReadTime(params) {
            if (params.disabled) {
                return;
            }

            const event = {
                session: params.session,
                locationId: ids.location.dailyJuice,
                eventId: ids.event.extraJuiceReadTime,
                objectId: params.extraJuiceId,
                timeTook: params.timeTook,
            };

            debugEvent("Extra Juice read time", event);

            methods.sendEvent(event);
        },

        videoPlayTime(params) {
            if (params.disabled) {
                return;
            }

            const event = {
                session: params.session,
                locationId: ids.location.dailyJuice,
                eventId: ids.event.videoPlayTime,
                objectId: params.videoId,
                timeTook: params.timeTook,
            };

            debugEvent("Video play time", event);

            methods.sendEvent(event);
        },

        teacherShareStory(params) {
            const event = {
                session: params.session,
                locationId: ids.location.dailyJuice,
                eventId: ids.event.teacherShareStory,
                objectId: params.storyId,
            };

            debugEvent("Teacher share story", event);

            methods.sendEvent(event);
        },

        teacherShareStoryWithGoogleClassroom(params) {
            const event = {
                session: params.session,
                locationId: ids.location.dailyJuice,
                eventId: ids.event.teacherShareStoryWithGoogleClassroom,
                objectId: params.storyId,
            };

            debugEvent("Teacher share story with google classroom", event);

            methods.sendEvent(event);
        },
    };
};
