import * as types from "./types.js";

export const setAssignmentByGroupCode = (payload) => ({
    type: types.SET_ASSIGNMENTS_BY_GROUP_CODE,
    payload,
});

export const setAssignmentStoryQuestion = (payload) => ({
    type: types.SET_ASSIGNMENT_STORY_QUESTION,
    payload,
});
