// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dqDpcuHP2TGgWJIwN9LT {\n    display: grid;\n    justify-content: center;\n}\n\n.aDA6IPqMvTPmn631FAxg {\n    margin: 2rem 0;\n    display: grid;\n}", "",{"version":3,"sources":["webpack://./app/views/user/sign-in-lms/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,cAAc;IACd,aAAa;AACjB","sourcesContent":[".message {\n    display: grid;\n    justify-content: center;\n}\n\n.customSignIn {\n    margin: 2rem 0;\n    display: grid;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"message": "dqDpcuHP2TGgWJIwN9LT",
	"customSignIn": "aDA6IPqMvTPmn631FAxg"
};
export default ___CSS_LOADER_EXPORT___;
