import React from "react";

import useTitle from "juice-base/hooks/use-title/index.js";

import { withAuth } from "juice-base/components/auth/index.js";

import TeacherDailyJuices from "juice-app/containers/teacher-daily-juices/index.js";


const DailyJuicesTeacher = () => {
    useTitle(() => "Teacher Daily Juices", []);

    return (
        <TeacherDailyJuices />
    );
};

export default withAuth([
    "teacher",
])(DailyJuicesTeacher);
