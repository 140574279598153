import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import text from "juice-base/text/index.js";

import teacherActions from "juice-base/actions/teacher.js";
import actions from "juice-base/store/actions.js";

import TeacherAddForm from "juice-base/forms/teacher-add/index.js";

import PopupWindow from "juice-base/components/popup-window/index.js";
import Message from "juice-base/components/message/index.js";
import RequestLoader from "juice-base/components/request-loader/index.js";

import PopupConfirmAddExistingTeacher from "juice-base/business/popup-confirm-add-existing-teacher/index.js";

import api from "juice-app/api.js";

import usePopupAddTeacherHook from "./use-popup-add-teacher.js";
import styles from "./styles.module.css";


const storeSelector = (state) => ({
    session: state.user.session,
    teacher: state.teacher,
    classesStudents: state.teacher.classesStudents,
    classesDetailsById: state.teacher.classesDetailsById,
});

const PopupAddTeacher = (props) => {
    const [popupState, setPopupState] = useState({
        message: "",
        isLoading: false,
        isRegistrationNeeded: false,
        isSuccess: false,
        isVisibleStatus: false,
        isFormSubmitted: false,
    });

    const [confirmPopup, setConfirmPopup] = useState({
        isVisible: false,
        data: {},
    });

    /* ---- */

    const dispatch = useDispatch();
    const store = useSelector(storeSelector);

    /* ---- */

    const getClassDetails = () => {
        return store.classesDetailsById?.[props.classId] || {};
    };

    const getSuccessMessage = (values) => {
        const { firstName, lastName, email } = values;
        const classDetails = getClassDetails();

        const teacherNameOrEmail = firstName && lastName
            ? `${firstName} ${lastName}`
            : email;

        return `
            ${teacherNameOrEmail} was added to ${classDetails?.data?.className}.
            A welcome email was sent with password set up instructions.
        `;
    };

    /* ---- */

    const onConfirmPopupClose = () => {
        setConfirmPopup({
            isVisible: false,
            data: {},
        });

        setPopupState((prev) => ({
            ...prev,
            isFormSubmitted: false,
        }));
    };

    const onConfirmPopupOpen = (data) => {
        setConfirmPopup({
            isVisible: true,
            data,
        });
    };

    /* ---- */

    const checkTeacherForAdditionToClass = async (values, formMethods) => {
        setPopupState((prev) => ({
            ...prev,
            isLoading: true,
            isFormSubmitted: true,
        }));

        const res = await api.classes.checkTeacherForAdditionToClass({
            session: store.session,
            classId: props.classId,
            email: values.email,
        });

        if (!res.ok) {
            setPopupState((prev) => ({
                ...prev,
                message: res.error || text.error,
                isVisibleStatus: true,
                isLoading: false,
            }));
            return;
        }

        formMethods.setSubmitting(false);

        if (res.isRegistrationNeeded) {
            setPopupState((prev) => ({
                ...prev,
                message: text.teacherByEmailNotFound,
                isRegistrationNeeded: true,
                isVisibleStatus: true,
                isLoading: false,
            }));
            return;
        }

        onConfirmPopupOpen(values);

        setPopupState((prev) => ({
            ...prev,
            isLoading: false,
        }));
    };

    /* ---- */

    const addTeacherToClass = async (values) => {
        setPopupState((prev) => ({
            ...prev,
            isLoading: true,
        }));

        const addRequest = popupState.isRegistrationNeeded
            ? api.classes.registerNewTeacherToClass
            : api.classes.addExistingTeacherToClass;

        const res = await addRequest({
            session: store.session,
            classId: props.classId,
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
        });

        const isSuccess = res.ok || false;

        const message = isSuccess
            ? getSuccessMessage(values)
            : res.error || text.error;

        setPopupState((prev) => ({
            ...prev,
            message,
            isSuccess,
            isVisibleStatus: true,
            isLoading: false,
        }));
    };

    /* ---- */

    const loadClassDetails = async () => {
        const { classId } = props;

        dispatch(teacherActions.loadClassDetails({ actions, api }, {
            session: store.session,
            classId,
        }));
    };

    /* ---- */

    const onSubmit = (values, formMethods) => {
        if (!popupState.isFormSubmitted) {
            checkTeacherForAdditionToClass(values, formMethods);
            return;
        }

        addTeacherToClass(values);
    };

    /* ---- */

    const onClose = () => {
        props.onClose(popupState.isSuccess);
    };

    /* ---- */

    useEffect(() => {
        loadClassDetails();
    }, []);

    /* ---- */

    const renderData = (label, txt) => {
        return (
            <div className={styles.dataRow}>
                <div className={styles.dataRowLabel}>
                    {label}
                </div>
                <div>
                    {txt}
                </div>
            </div>
        );
    };

    const renderClassDetails = (data) => {
        return (
            <div className={styles.classDetailsRows}>
                <div>
                    {renderData("school", data.schoolName)}
                </div>
                <div className={styles.classDetailsRow}>
                    <div className={styles.classDetailsRowBorderRight}>
                        {renderData("department", data.departmentName)}
                    </div>
                    <div>
                        {renderData("class", data.className)}
                    </div>
                </div>
            </div>
        );
    };

    const renderContent = () => {
        const classDetails = getClassDetails();

        if (classDetails.isLoading) {
            return (
                <RequestLoader />
            );
        }

        if (classDetails.error) {
            return (
                <Message>
                    {classDetails.error}
                </Message>
            );
        }

        if (!classDetails.data) {
            return (
                <Message>
                    {text.noData}
                </Message>
            );
        }

        const status = {
            message: popupState.message,
            isSuccess: popupState.isSuccess,
            isVisible: popupState.isVisibleStatus,
        };

        return (
            <div>
                {renderClassDetails(classDetails.data)}
                <TeacherAddForm
                    status={status}
                    onSubmit={onSubmit}
                    isEmailOnly={!popupState.isRegistrationNeeded}
                    isEmailDisabled={popupState.isRegistrationNeeded}
                    isLoading={popupState.isLoading}
                />
            </div>
        );
    };

    const renderConfirmPopup = () => {
        if (!confirmPopup.isVisible) {
            return null;
        }

        const data = confirmPopup?.data || {};

        return (
            <PopupConfirmAddExistingTeacher
                email={data.email || ""}
                onAdd={() => {
                    addTeacherToClass(data);
                    onConfirmPopupClose();
                }}
                onClose={onConfirmPopupClose}
            />
        );
    };

    return (
        <>
            {renderConfirmPopup()}

            <PopupWindow
                title="Add a teacher"
                popupClassName={styles.popup}
                barClassName={styles.popupBar}
                onClose={onClose}
                withTitleBarShadow={false}
                isCentered
                isSmall
            >
                {renderContent()}
            </PopupWindow>
        </>
    );
};

PopupAddTeacher.defaultProps = {
    classId: null,
    onClose: () => { },
};

export const usePopupAddTeacher = usePopupAddTeacherHook;
export default PopupAddTeacher;
