import * as globalTypes from "juice-base/store-common/types.js";
import * as types from "./types.js";


const initialState = {
    assignmentsByGroupCode: {},
};

export default (state, action) => {
    switch (action.type) {
        case types.SET_ASSIGNMENTS_BY_GROUP_CODE: {
            const { groupCode, data, error } = action.payload;

            return {
                ...state,
                assignmentsByGroupCode: {
                    ...state.assignmentsByGroupCode,
                    [groupCode]: {
                        data,
                        error,
                    },
                },
            };
        }

        case types.SET_ASSIGNMENT_STORY_QUESTION: {
            const { groupCode, quizId, question } = action.payload;
            const { assignmentsByGroupCode } = state;

            const assignment = assignmentsByGroupCode?.[groupCode] || null;

            if (!assignment?.data?.stories) {
                return state;
            }

            assignment.data.stories = assignment.data.stories.map((s) => {
                const quizzes = (s.quizzes || []).map((q) => {
                    if (q.quizId === quizId && q.id === question.id) {
                        return {
                            ...q,
                            ...question,
                        };
                    }

                    return q;
                });

                return {
                    ...s,
                    quizzes,
                };
            });

            return {
                ...state,
                assignmentsByGroupCode: {
                    ...assignmentsByGroupCode,
                    [groupCode]: assignment,
                },
            };
        }

        /* --- */

        case globalTypes.CLEAR_AFTER_SIGNOUT: {
            return { ...initialState };
        }

        /* --- */

        default: {
            return state || { ...initialState };
        }
    }
};
