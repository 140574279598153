// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".xAgAQ6yXhZV2PbB5BbVv {\n    max-width: 50rem;\n}\n\n.ys7AxodcDkDkvRV9kt8d {\n    padding: 1.5rem 4rem 1.5rem 2rem;\n\n    justify-content: start;\n\n    white-space: initial;\n    height: auto;\n}\n\n/* ----- */\n\n.mya2xLYfL6E3ZZ3AOtvV {\n    display: grid;\n    grid-gap: 0.5rem;\n\n    font-size: 1.6rem;\n}\n\n.mya2xLYfL6E3ZZ3AOtvV div:first-child {\n    font-weight: 600;\n}\n\n.mya2xLYfL6E3ZZ3AOtvV div:last-child {\n    color: var(--text-secondary-color);\n}\n\n/* ----- */\n\n.lufroWCXb687Tgv1hVG6 {\n    display: grid;\n    grid-gap: 2.5rem;\n\n    margin: 2rem;\n}\n\n.iubt8ies8jlWtTHqCc4S {\n    display: grid;\n    grid-gap: 2rem;\n}\n", "",{"version":3,"sources":["webpack://./base/business/popup-filter-categories/styles.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,gCAAgC;;IAEhC,sBAAsB;;IAEtB,oBAAoB;IACpB,YAAY;AAChB;;AAEA,UAAU;;AAEV;IACI,aAAa;IACb,gBAAgB;;IAEhB,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,kCAAkC;AACtC;;AAEA,UAAU;;AAEV;IACI,aAAa;IACb,gBAAgB;;IAEhB,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,cAAc;AAClB","sourcesContent":[".popup {\n    max-width: 50rem;\n}\n\n.popupBar {\n    padding: 1.5rem 4rem 1.5rem 2rem;\n\n    justify-content: start;\n\n    white-space: initial;\n    height: auto;\n}\n\n/* ----- */\n\n.header {\n    display: grid;\n    grid-gap: 0.5rem;\n\n    font-size: 1.6rem;\n}\n\n.header div:first-child {\n    font-weight: 600;\n}\n\n.header div:last-child {\n    color: var(--text-secondary-color);\n}\n\n/* ----- */\n\n.content {\n    display: grid;\n    grid-gap: 2.5rem;\n\n    margin: 2rem;\n}\n\n.controls {\n    display: grid;\n    grid-gap: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popup": "xAgAQ6yXhZV2PbB5BbVv",
	"popupBar": "ys7AxodcDkDkvRV9kt8d",
	"header": "mya2xLYfL6E3ZZ3AOtvV",
	"content": "lufroWCXb687Tgv1hVG6",
	"controls": "iubt8ies8jlWtTHqCc4S"
};
export default ___CSS_LOADER_EXPORT___;
