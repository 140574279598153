import React from "react";

import classNames from "juice-base/lib/class-names.js";

import IconArrowRight from "juice-base/icons/arrow-right/index.js";
import IconExclamationInCircle from "juice-base/icons/exclamation-in-circle/index.js";
import IconNotepadCheck from "juice-base/icons/notepad-check/index.js";
import IconNotepadExclamation from "juice-base/icons/notepad-exclamation/index.js";
import IconNotepadStop from "juice-base/icons/notepad-stop/index.js";
import IconNotepadX from "juice-base/icons/notepad-x/index.js";
import IconPlayInCircle from "juice-base/icons/play-in-circle/index.js";
import IconPlayInDottedCircle from "juice-base/icons/play-in-dotted-circle/index.js";
import IconPollAnswered from "juice-base/icons/poll-answered/index.js";
import IconPollUnanswered from "juice-base/icons/poll-unanswered/index.js";

import DailyJuice from "juice-base/project/daily-juice.js";
import Button from "juice-base/components/button/index.js";

import dailyJuiceCommonStyles from "juice-base/business/daily-juice-common/styles.module.css";
import styles from "./styles.module.css";


const DailyJuiceActivity = (props) => {
    const renderStoryIcon = (story, quiz) => {
        const { featuredVideo } = story;

        if (story.polls && story.polls.length > 0) {
            const poll = story.polls[0];
            const isAnswered = poll.userAnswerId !== null;

            if (!isAnswered) {
                return (
                    <IconPollUnanswered
                        title={DailyJuice.StoryStatesLegend.pollUnanswered}
                        isSky
                    />
                );
            }

            return (
                <IconPollAnswered
                    title={DailyJuice.StoryStatesLegend.pollAnswered}
                    isSky
                />
            );
        }

        if (featuredVideo?.featuredImage?.url) {
            if (featuredVideo.isUserViewed) {
                return (
                    <IconPlayInCircle
                        title={DailyJuice.StoryStatesLegend.playInCircle}
                        isGreen
                    />
                );
            }

            return (
                <IconPlayInDottedCircle
                    title={DailyJuice.StoryStatesLegend.playInDottedCircle}
                    isSky
                />
            );
        }

        if (!story.isUserOpenedStory) {
            return (
                <IconNotepadExclamation
                    title={DailyJuice.StoryStatesLegend.notepadExclamation}
                    isSky
                />
            );
        }

        if (!quiz) {
            return (
                <IconNotepadCheck
                    title={DailyJuice.StoryStates.quizEmpty}
                    isGreen
                />
            );
        }

        const isAnswered = DailyJuice.isAnsweredQuiz(quiz);

        if (isAnswered) {
            if (DailyJuice.isValidQuiz(quiz)) {
                return (
                    <IconNotepadCheck
                        title={DailyJuice.StoryStatesLegend.notepadCheck}
                        isGreen
                    />
                );
            }

            return (
                <IconNotepadX
                    title={DailyJuice.StoryStatesLegend.notepadX}
                    isGreen
                />
            );
        }

        return (
            <IconNotepadStop
                title={DailyJuice.StoryStatesLegend.notepadStop}
                isRed
            />
        );
    };

    const renderStory = (story) => {
        const { featuredImage, featuredVideo } = story;

        const imgStyles = {};

        if (featuredImage?.url) {
            imgStyles.backgroundImage = `url(${featuredImage.url})`;
        } else if (featuredImage?.vocabUrl) {
            imgStyles.backgroundImage = `url(${featuredImage.vocabUrl})`;
        } else if (featuredVideo?.featuredImage?.url) {
            imgStyles.backgroundImage = `url(${featuredVideo.featuredImage.url})`;
        }

        let quiz = null;

        if (story.quizzes && story.quizzes.length > 0) {
            quiz = story.quizzes[0] || null;
        }

        const goToTitle = "Go to story";

        return (
            <div
                key={story.ID}
                className={styles.story}
                onClick={() => {
                    props.onGoToStory(story.ID);
                }}
                onKeyPress={() => {
                    props.onGoToStory(story.ID);
                }}
                tabIndex="-1"
                role="button"
            >
                <div
                    className={styles.storyImage}
                    style={imgStyles}
                />
                <div className={styles.storyTitle}>
                    {story.title}
                </div>

                {renderStoryIcon(story, quiz)}

                <div className={styles.storyGoToLink}>
                    <IconArrowRight
                        title={goToTitle}
                        className={styles.storyGoToLinkIcon}
                        isBlack
                    />
                </div>
            </div>
        );
    };

    /* ------ */

    const renderScoreButton = () => {
        if (!props.withScoreReport) {
            return null;
        }

        return (
            <div className={styles.scoreButton}>
                <Button
                    theme="orange"
                    onClick={props.onShowReport}
                >
                    View score report
                </Button>
            </div>
        );
    };

    const renderInfoButton = () => {
        return (
            <div
                className={styles.infoButton}
                onClick={props.onInfoClick}
                onKeyPress={props.onInfoClick}
                role="button"
                tabIndex="-1"
            >
                <IconExclamationInCircle
                    title="Information"
                    isOrange
                />
            </div>
        );
    };

    const renderStories = () => {
        const stories = props.stories.map((story) => renderStory(story));

        return (
            <div className={styles.stories}>
                {stories}
            </div>
        );
    };

    /* ------ */

    const activityClassName = classNames({
        [dailyJuiceCommonStyles.block]: true,
        [styles.block]: true,
    });

    return (
        <div className={activityClassName}>
            <div className={styles.activityHeader}>
                <div className={dailyJuiceCommonStyles.header}>
                    Activity overview
                </div>
                {renderInfoButton()}
            </div>
            {renderStories()}
            {renderScoreButton()}
        </div>
    );
};

DailyJuiceActivity.defaultProps = {
    stories: [],
    onGoToStory: () => { },
    onShowReport: () => { },
    withScoreReport: true,
};

export default DailyJuiceActivity;
