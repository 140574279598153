import React from "react";

import IconStandardTypes from "juice-base/icons/standard-types/index.js";

import Text from "juice-base/components/text/index.js";
import ButtonFlat from "juice-base/components/button-flat/index.js";
import PopupConfirm, {
    PopupConfirmIcon,
    PopupConfirmContent,
    PopupConfirmTitle,
    PopupConfirmButtons,
} from "juice-base/components/popup-confirm/index.js";


const PopupConfirmStandardType = (props) => {
    if (!props.standardType) {
        return null;
    }

    return (
        <PopupConfirm>
            <PopupConfirmIcon
                icon={<IconStandardTypes title="Standard Type" isRed />}
            />

            <PopupConfirmTitle>
                <Text>
                    {props.standardType?.standard}
                </Text>
            </PopupConfirmTitle>

            <PopupConfirmContent>
                <Text>
                    {props.standardType?.details}
                </Text>
            </PopupConfirmContent>

            <PopupConfirmButtons>
                <ButtonFlat onClick={props.onClose}>
                    Ok
                </ButtonFlat>
            </PopupConfirmButtons>
        </PopupConfirm>
    );
};

PopupConfirmStandardType.defaultProps = {
    standardType: null,
    onClose: () => { },
};

export default PopupConfirmStandardType;
