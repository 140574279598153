import React from "react";

import IconEmojiHappy from "juice-base/icons/emoji-happy/index.js";
import IconEmojiSad from "juice-base/icons/emoji-sad/index.js";

import styles from "./styles.module.css";


const MessageTypes = {
    happy: "happy",
    sad: "sad",
};

const MessageWithEmoji = (props) => {
    let emoji = null;

    if (props.type === MessageTypes.happy) {
        emoji = (
            <IconEmojiHappy
                className={styles.emojiImg}
                title="Emoji Happy"
                isBlack
            />
        );
    } else if (props.type === MessageTypes.sad) {
        emoji = (
            <IconEmojiSad
                className={styles.emojiImg}
                title="Emoji Sad"
                isBlack
            />
        );
    }

    return (
        <div className={styles.messageWithEmoji}>
            {emoji}
            <div className={styles.header}>
                {props.header}
            </div>
            <div className={styles.message}>
                {props.message}
            </div>
        </div>
    );
};

MessageWithEmoji.defaultProps = {
    header: "",
    message: "",
    type: MessageTypes.happy,
};

export default MessageWithEmoji;
