import React from "react";

import classNames from "juice-base/lib/class-names.js";

import styles from "./styles.module.css";


const IconClickable = (props) => {
    const iconClassName = classNames({
        [styles.iconClickable]: true,
        [props.className]: props.className !== "",
    });

    return (
        <div
            onClick={props.onClick}
            onKeyPress={props.onClick}
            className={iconClassName}
            tabIndex="-1"
            role="button"
        >
            {props.children}
        </div>
    );
};

IconClickable.defaultProps = {
    className: "",
    children: null,
    onClick: () => { },
};

export default IconClickable;
