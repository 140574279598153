// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dxpY6KiQBvH0LpinP9w0 {\n    height: 4rem;\n\n    display: grid;\n    justify-content: center;\n    align-items: center;\n\n    border-radius: 5rem;\n    border: 0.2rem solid var(--button-rounded-color);\n\n    font-weight: 600;\n    color: var(--button-rounded-color);\n\n    overflow: hidden;\n\n    outline: none;\n    cursor: pointer;\n}\n\n.dxpY6KiQBvH0LpinP9w0:hover {\n    background-color: var(--button-rounded-bg-color-hover);\n}\n\n/* ---- */\n\n.vRcp16o3Sh5uWzcoXaxE {\n    text-transform: uppercase;\n}\n", "",{"version":3,"sources":["webpack://./base/components/button-rounded/styles.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;;IAEZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;;IAEnB,mBAAmB;IACnB,gDAAgD;;IAEhD,gBAAgB;IAChB,kCAAkC;;IAElC,gBAAgB;;IAEhB,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,sDAAsD;AAC1D;;AAEA,SAAS;;AAET;IACI,yBAAyB;AAC7B","sourcesContent":[".button {\n    height: 4rem;\n\n    display: grid;\n    justify-content: center;\n    align-items: center;\n\n    border-radius: 5rem;\n    border: 0.2rem solid var(--button-rounded-color);\n\n    font-weight: 600;\n    color: var(--button-rounded-color);\n\n    overflow: hidden;\n\n    outline: none;\n    cursor: pointer;\n}\n\n.button:hover {\n    background-color: var(--button-rounded-bg-color-hover);\n}\n\n/* ---- */\n\n.buttonUppercase {\n    text-transform: uppercase;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "dxpY6KiQBvH0LpinP9w0",
	"buttonUppercase": "vRcp16o3Sh5uWzcoXaxE"
};
export default ___CSS_LOADER_EXPORT___;
