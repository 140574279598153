// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".S9fjNTTlRQm4Wl9cR5BS {\n    font-weight: 600;\n    color: var(--button-text-color);\n\n    outline: none;\n    cursor: pointer;\n}\n\n.UPX0jIOOS9fAkXqg1VPq {\n    text-transform: uppercase;\n}\n", "",{"version":3,"sources":["webpack://./base/components/button-text/styles.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,+BAA+B;;IAE/B,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".button {\n    font-weight: 600;\n    color: var(--button-text-color);\n\n    outline: none;\n    cursor: pointer;\n}\n\n.buttonUppercase {\n    text-transform: uppercase;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "S9fjNTTlRQm4Wl9cR5BS",
	"buttonUppercase": "UPX0jIOOS9fAkXqg1VPq"
};
export default ___CSS_LOADER_EXPORT___;
