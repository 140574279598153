const IMAGE_SIZES = [
    "thumbnail",
    "medium",
    "medium_large",
    "large",
    "1536x1536",
    "2048x2048",
];

const getMinSizeImage = (image) => {
    if (!image) {
        return null;
    }

    const sizes = image?.sizes || {};

    for (let i = 0; i < IMAGE_SIZES.length; i += 1) {
        const size = IMAGE_SIZES[i];

        if (sizes[size]?.src) {
            return {
                ...sizes[size],
            };
        }
    }

    if (image?.url) {
        return {
            src: image.url,
            width: image.width,
            height: image.height,
        };
    }

    if (image?.vocabUrl) {
        return {
            src: image.vocabUrl,
            width: image.width || "",
            height: image.height || "",
        };
    }

    return null;
};

const getMaxSizeImage = (image) => {
    if (!image) {
        return null;
    }

    const allSizes = [...IMAGE_SIZES].reverse();

    const sizes = image?.sizes || {};

    for (let i = 0; i < allSizes.length; i += 1) {
        const size = allSizes[i];

        if (sizes[size]?.src) {
            return {
                ...sizes[size],
            };
        }
    }

    if (image?.url) {
        return {
            src: image.url,
            width: image.width,
            height: image.height,
        };
    }

    if (image?.vocabUrl) {
        return {
            src: image.vocabUrl,
            width: image.width || "",
            height: image.height || "",
        };
    }

    return null;
};

const getMinMaxSizeImage = (image) => {
    const min = getMinSizeImage(image);
    const max = getMaxSizeImage(image);

    return {
        minSrc: min?.src || null,
        maxSrc: max?.src || null,
    };
};

const getStoryImages = (story) => {
    if (!story) {
        return null;
    }

    let image = "";

    if (story?.type === "video") {
        image = story?.featuredVideo?.featuredImage;
    } else {
        image = story?.featuredImage;
    }

    const minMaxSrc = getMinMaxSizeImage(image);

    return {
        smallSrc: minMaxSrc.minSrc,
        largeSrc: minMaxSrc.maxSrc,
        infographic: image?.infographic || null,
    };
};

export default {
    getMinSizeImage,
    getMaxSizeImage,
    getMinMaxSizeImage,
    getStoryImages,
};
