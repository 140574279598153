import React, {
    useState,
    useEffect,
    useRef,
    useContext,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import DailyJuice from "juice-base/project/daily-juice.js";
import Grades from "juice-base/project/grades.js";
import Country from "juice-base/project/country.js";
import Polls from "juice-base/project/polls.js";
import Google from "juice-base/project/google.js";
import Standards from "juice-base/project/standards.js";
import DJStory from "juice-base/project/daily-juice-story.js";

import storage from "juice-base/lib/storage/index.js";
import text from "juice-base/text/index.js";
import device from "juice-base/lib/device.js";
import date from "juice-base/lib/date.js";
import urls from "juice-base/lib/urls.js";
import copyToClipboard from "juice-base/lib/clipboard.js";
import timer from "juice-base/lib/timer.js";

import actions from "juice-base/store/actions.js";
import vocabularyActions from "juice-base/actions/vocabulary.js";
import dailyJuiceActions from "juice-base/actions/daily-juice.js";
import teacherActions from "juice-base/actions/teacher.js";

import AudioManagerContext from "juice-base/context/audio-manager/index.js";

import useSnackbar from "juice-base/hooks/use-snackbar/index.js";

import IconGraduationCapEmpty from "juice-base/icons/graduation-cap-empty/index.js";
import IconGroup from "juice-base/icons/group/index.js";
import IconCalendar from "juice-base/icons/calendar/index.js";

import LayoutContent from "juice-base/components/layout-content/index.js";
import RequestLoader from "juice-base/components/request-loader/index.js";
import DatePickerSingle from "juice-base/components/date-picker-single/index.js";
import Snackbar from "juice-base/components/snackbar/index.js";
import SelectCustom from "juice-base/components/select-custom/index.js";
import ButtonText from "juice-base/components/button-text/index.js";
import ButtonShare, { useButtonShare } from "juice-base/components/button-share/index.js";
import Message from "juice-base/components/message/index.js";
import PopupExtraJuice from "juice-base/components/popup-extra-juice/index.js";
import PopupPlayerAudio from "juice-base/components/popup-player-audio/index.js";
import PopupImage from "juice-base/components/popup-image/index.js";

import PopupWord from "juice-base/business/popup-word/index.js";
import DailyJuiceStory from "juice-base/business/daily-juice-story/index.js";
import DailyJuiceStoryPoll from "juice-base/business/daily-juice-story-poll/index.js";
import QuizQuestion from "juice-base/business/quiz-question/index.js";

import Tutorial from "juice-app/containers/tutorial/index.js";
import UserFooter from "juice-app/containers/user-footer/index.js";
import UserPopupPolls from "juice-app/containers/user-popup-polls/index.js";
import PopupAssign, { usePopupAssign } from "juice-app/containers/popup-assign/index.js";

import api from "juice-app/api.js";
import events from "juice-app/events.js";
import settings from "juice-app/settings.js";

import styles from "./styles.module.css";


const storeSelector = (state) => ({
    session: state.user.session,
    teacher: state.teacher,
    user: state.user.user,

    juices: state.juices,
    juicesDates: state.juices.juicesDates,
    juicesById: state.juices.juicesById,

    polls: state.polls,

    dimensions: state.device.dimensions,

    wordPopup: state.vocabulary.popup,
    wordsByName: state.vocabulary.wordsByName,
    playerState: state.player.playerState,
});


const TeacherDailyJuices = () => {
    const audioManager = useContext(AudioManagerContext);

    const [pageState, setPageState] = useState({
        error: "",
    });

    const [isVisibleUserPollsPopup, setVisibleUserPollsPopup] = useState(false);

    const [selectedPollClassByPollId, setSelectedPollClassByPollId] = useState({});

    const [audioPlayerPopup, setAudioPlayerPopup] = useState({
        storyId: -1,
    });

    const [extraJuicePopup, setExtraJuicePopup] = useState({
        storyId: -1,
        ejId: -1,
        isVisiblePlayer: false,
    });

    const [imagePopup, setImagePopup] = useState(null);

    const juiceTimer = useRef(null);

    /* --------- */

    const dispatch = useDispatch();
    const store = useSelector(storeSelector);

    const navigate = useNavigate();
    const urlParams = useParams();

    const snackbar = useSnackbar();
    const buttonShare = useButtonShare();
    const popupAssign = usePopupAssign();

    /* --- */

    const goToJuice = (juiceId) => {
        if (!juiceId) {
            return;
        }

        navigate(`/teacher-daily-juices/${juiceId}`);
    };

    /* --- */

    const getJuiceDate = () => {
        return DailyJuice.getJuiceDateByJuiceId(store.juicesById, urlParams.juiceId);
    };

    const getSelectedGrade = () => {
        const firstGrade = store.juicesById?.[urlParams.juiceId]?.grades?.[0];

        const grade = Grades.getGradesRangeByGrade(firstGrade);

        if (!grade) {
            return Grades.getMaxGradeValue();
        }

        return grade;
    };

    const getJuiceId = () => {
        let juiceId = "";

        if (urlParams.juiceId) {
            juiceId = parseInt(urlParams.juiceId, 10);
        }

        return juiceId;
    };

    const getJuiceStories = () => {
        const juiceId = getJuiceId();

        if (juiceId) {
            const juice = store.juicesById[juiceId] || null;

            if (juice?.stories?.juice) {
                return juice.stories.juice;
            }
        }

        return [];
    };

    const getStoryById = (storyId) => {
        const stories = getJuiceStories();

        for (let i = 0; i < stories.length; i += 1) {
            if (stories[i].ID === storyId) {
                return stories[i];
            }
        }

        return null;
    };

    const getDailyJuice = () => {
        const juiceId = getJuiceId();

        return store.juicesById[juiceId] || {};
    };

    const getExtraJuice = (sId, ejId) => {
        const dailyJuice = getDailyJuice();

        const stories = (dailyJuice.stories || {}).juice || [];

        return DailyJuice.getExtraJuiceById(stories, ejId);
    };

    const getStoryQuiz = (story) => {
        const q = DailyJuice.getStoryQuiz(story);

        if (!q) {
            return null;
        }

        return q;
    };

    /* --- */

    const loadJuice = async (juiceId, callbacks = {}) => {
        setPageState((prev) => ({
            ...prev,
            error: "",
        }));

        const res = await api.dailyJuices.getJuice({
            session: store.session,
            juiceId,
        });

        if (!res.ok) {
            setPageState((prev) => ({
                ...prev,
                error: res.error || text.error,
            }));
            return;
        }

        const juice = res.data || null;

        dispatch(actions.juices.setJuice({
            juice,
        }));

        if (callbacks?.onLoad) {
            callbacks.onLoad(juice);
        }
    };

    const onDatesLoaded = (dates) => {
        const selectedDate = getJuiceDate();

        const jId = DailyJuice.getJuiceIdByDate(selectedDate, dates)
            || dates?.[0]?.juiceId;

        loadJuice(jId);
        goToJuice(jId);
    };

    /* --- */

    const loadTeacherClasses = async () => {
        dispatch(teacherActions.loadClasses({ api, storage, actions }, {
            session: store.session,
        }));
    };

    const loadJuicesDates = async (grade, callbacks) => {
        dispatch(dailyJuiceActions.loadJuicesDatesByGrade(
            { api, actions },
            { grade },
            { onLoad: callbacks?.onLoad },
        ));
    };

    const loadPollByClass = async (classId, pollId) => {
        if (!classId || classId === "all") {
            return;
        }

        const poll = store.polls.pollsResultsByClassId?.[pollId]?.[classId] || {};

        if (poll?.isLoading || poll.data) {
            return;
        }

        dispatch(actions.polls.setPollResultByClassIdLoading({
            pollId,
            classId,
        }));

        const selectedClass = classId === "all-my-classes"
            ? store.teacher.classes.map((cl) => cl.id).join(",")
            : classId;

        const res = await api.classes.getClassPollResults({
            session: store.session,
            pollId,
            classId: selectedClass,
        });

        let data = null;
        let error = "";

        if (res.ok) {
            data = res.poll || {};
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.polls.setPollResultByClassId({
            pollId,
            classId,
            data,
            error,
        }));
    };

    /* --- */

    const onPollSelectedClassChange = (classId, pollId) => {
        setSelectedPollClassByPollId((prev) => ({
            ...prev,
            [pollId]: classId,
        }));

        loadPollByClass(classId, pollId);
    };

    const onOpenImagePopup = (image) => {
        setImagePopup(image);
    };

    const onCloseImagePopup = () => {
        setImagePopup(null);
    };

    const onOpenWordPopup = (word) => {
        dispatch(vocabularyActions.openPopup({ api, actions }, {
            session: store.session,
            word,
        }));
    };

    const onCloseWordPopup = () => {
        dispatch(vocabularyActions.closePopup({ actions }));
    };

    /* --- */

    const onPlayJuiceStory = (storyId) => {
        setAudioPlayerPopup((prev) => ({
            ...prev,
            storyId,
        }));

        audioManager.loadStory(storyId);
    };

    const onCloseAudioPlayerPopup = () => {
        setAudioPlayerPopup((prev) => ({
            ...prev,
            storyId: -1,
        }));
    };

    /* --- */

    const onOpenExtraJuicePopup = (sId, ejId) => {
        if (sId && ejId) {
            setExtraJuicePopup((prev) => ({
                ...prev,
                storyId: sId,
                ejId,
            }));
        }
    };

    const onShowExtraJuiceStoryPlayer = (extraJuiceId) => {
        setExtraJuicePopup((prev) => ({
            ...prev,
            isVisiblePlayer: true,
        }));

        audioManager.loadExtraJuice(extraJuiceId);
    };

    const onHideExtraJuiceStoryPlayer = () => {
        setExtraJuicePopup((prev) => ({
            ...prev,
            isVisiblePlayer: false,
        }));
    };

    const onCloseExtraJuicePopup = () => {
        setExtraJuicePopup((prev) => ({
            ...prev,
            storyId: -1,
            ejId: -1,
        }));
    };

    const onDateChange = (value) => {
        const nDate = date.newDate(value);

        const jId = DailyJuice.getJuiceIdByDate(nDate, store.juicesDates);

        if (!jId) {
            return;
        }

        loadJuice(jId);
        goToJuice(jId);
    };

    const onGradeChange = (grade) => {
        dispatch(actions.juices.clearJuice({ juiceId: urlParams.juiceId }));

        loadJuicesDates(
            grade,
            { onLoad: onDatesLoaded },
        );
    };

    /* --- */

    const onOpenUserPollsPopup = () => {
        setVisibleUserPollsPopup(true);
    };

    const onCloseUserPollsPopup = () => {
        setVisibleUserPollsPopup(false);
    };

    /* --- */

    const onCopyToClipboard = (params = {}) => {
        if (params.toCopy) {
            copyToClipboard(params.toCopy);
        }

        snackbar.add({
            message: params.message,
        });
    };

    /* --- */

    const onStoryGoogleClassroomClick = (story) => {
        const googleLink = Google.getGoogleClassroomShareLink(
            settings.google.classroomShareUrl,
            `/story/${story.ID}`,
            story.title,
        );

        urls.openUrl(googleLink);
        buttonShare.toggleMenu();
    };

    const onStoryCopyLink = async (story) => {
        const siteUrl = urls.getSiteUrl();

        onCopyToClipboard({
            message: `The link to "${story.title}" has been copied to your clipboard.`,
            toCopy: `${siteUrl}/story/${story.ID}`,
        });
        buttonShare.toggleMenu();
    };

    /* --- */

    const onOpenAssignPopup = (story) => {
        let storyDate = null;

        if (story?.date) {
            storyDate = date.newDate(story.date);
        }

        const storyQuiz = DailyJuice.getStoryQuiz(story);
        const storyImage = DJStory.getMinSizeImage(story?.featuredImage);

        popupAssign.open({
            storyId: story?.ID || null,
            category: story?.categoryName || "",
            title: story?.title || "",
            date: storyDate,
            standards: storyQuiz?.standards || [],
            standardId: storyQuiz?.standardId || null,
            quizId: storyQuiz?.quizId || null,
            questionId: storyQuiz?.id || null,
            featuredImageSrc: storyImage?.src || "",
        });
    };

    /* --- */

    const onInitJuiceLoaded = (juice) => {
        const firstGrade = juice?.grades?.[0];
        const grade = Grades.getGradesRangeByGrade(firstGrade);

        loadJuicesDates(grade);
    };

    useEffect(() => {
        loadTeacherClasses();

        if (urlParams.juiceId) {
            loadJuice(urlParams.juiceId, {
                onLoad: onInitJuiceLoaded,
            });
            return;
        }

        const grade = getSelectedGrade();

        loadJuicesDates(grade, {
            onLoad: onDatesLoaded,
        });

        return () => {
            dispatch(vocabularyActions.closePopup({ actions }));
            dispatch(actions.polls.clearPollsResults());
        };
    }, []);

    useEffect(() => {
        const { juiceId } = urlParams;

        if (juiceId) {
            juiceTimer.current = timer.startTimer();

            events.teacher.dailyJuiceOpen({
                session: store.session,
                dailyJuiceId: juiceId,
            });
        }

        return () => {
            if (juiceId) {
                events.teacher.dailyJuiceClose({
                    session: store.session,
                    dailyJuiceId: juiceId,
                    timeTook: juiceTimer?.current?.getDiffSeconds() || 0,
                });
            }
        };
    }, [urlParams.juiceId]);

    /* --- */

    const renderTutorial = () => {
        return (
            <Tutorial
                name="teacher-daily-juices"
            />
        );
    };

    const renderShareButton = () => {
        const juiceId = getJuiceId();

        if (!juiceId) {
            return null;
        }

        const juiceTitle = store.juicesById?.[juiceId]?.title || "";

        const googleClassroom = {
            shareUrl: settings.google.classroomShareUrl,
            title: `Daily Juice: ${juiceTitle}`,
            path: `/daily-juices/${juiceId}`,
        };

        return (
            <div className={styles.shareButton}>
                <ButtonShare
                    dataComment="classroom-share-button-daily-juice"
                    googleClassroom={googleClassroom}
                    onCopyLink={() => {
                        const siteUrl = urls.getSiteUrl();

                        onCopyToClipboard({
                            toCopy: `${siteUrl}${googleClassroom.path}`,
                            message: `The link to "${googleClassroom.title}" has been copied to your clipboard.`,
                        });
                    }}
                />
            </div>
        );
    };

    const renderDatePicker = () => {
        const availableDates = store.juicesDates.map((juice) => {
            return juice.date;
        });

        const juiceDate = getJuiceDate();

        return (
            <div className={styles.selectorDates}>
                <DatePickerSingle
                    dataComment="juice-date-selector"
                    date={juiceDate}
                    icon={<IconCalendar isBlack />}
                    availableDates={availableDates}
                    onChange={onDateChange}
                    withBorder
                />
            </div>
        );
    };

    const renderGradeSelector = () => {
        const options = Grades.getGradesOptionsWithGT(store.user.grades);

        const grade = getSelectedGrade();

        return (
            <div className={styles.selectorGrades}>
                <SelectCustom
                    selected={grade}
                    options={options}
                    icon={<IconGraduationCapEmpty isBlack />}
                    dataComment="juice-grade-selector"
                    onSelect={onGradeChange}
                    withBorderRadius
                />
            </div>
        );
    };

    const renderSelectors = () => {
        return (
            <div className={styles.selectors}>
                {renderDatePicker()}
                {renderGradeSelector()}
                {renderShareButton()}
            </div>
        );
    };

    const renderQuiz = (story, storyIndex) => {
        const q = getStoryQuiz(story);

        if (!q) {
            return null;
        }

        const isCountryUS = Country.isCountryUS(store.user.country);

        const standards = DailyJuice.getQuizStandards(q);
        const withStateStandard = Standards.hasStateStandard(standards) && isCountryUS;

        const defaultStandardType = Standards.getDefaultStandardValue(withStateStandard);

        const dataComment = `juice-story-quiz-${storyIndex}`;

        return (
            <div className={styles.storyQuiz}>
                <QuizQuestion
                    question={q}
                    standards={standards}
                    defaultStandardType={defaultStandardType}
                    dataComment={dataComment}
                    isMobile={store.dimensions.width < 920}
                    allowAnswering={false}
                    showStateStandard={isCountryUS}
                    showAnswers
                    showStandards
                />
            </div>
        );
    };

    const renderPollQuestionControl = (pollId) => {
        const classesOptions = [{
            value: "all",
            label: "Everyone",
        }];

        if (store.teacher.classes.length > 1) {
            classesOptions.push({
                value: "all-my-classes",
                label: "All My Classes",
            });
        }

        for (let i = 0; i < store.teacher.classes.length; i += 1) {
            const cl = store.teacher.classes[i];

            classesOptions.push({
                value: cl.id,
                label: cl.title,
            });
        }

        return (
            <div className={styles.classSelector}>
                <SelectCustom
                    small
                    orangeTheme
                    selected={selectedPollClassByPollId[pollId] || "all"}
                    options={classesOptions}
                    icon={<IconGroup isBlack />}
                    onSelect={(classId) => {
                        onPollSelectedClassChange(classId, pollId);
                    }}
                />
            </div>
        );
    };

    const renderPollBottomControl = () => {
        return (
            <ButtonText
                uppercase
                onClick={onOpenUserPollsPopup}
            >
                See all polls
            </ButtonText>
        );
    };

    const renderStory = (juiceId, story, storyIndex) => {
        let videoCaptionSrc = null;

        if (story.featuredVideo?.id) {
            videoCaptionSrc = api.videos.getVideoCaptionURL({
                id: story.featuredVideo.id,
                session: store.session,
            });
        }

        const storyId = story.ID;
        const poll = Polls.getStoryPoll(story);

        let djStory = null;

        if (poll) {
            const { pollsResultsByClassId } = store.polls;

            const pollId = poll?.data?.id || null;
            let pollData = poll?.data || {};
            let isPollLoading = false;
            let pollError = poll?.error || "";

            const selectedClass = selectedPollClassByPollId[pollId] || null;

            if (selectedClass && selectedClass !== "all") {
                const pollByClass = pollsResultsByClassId?.[pollId]?.[selectedClass] || {};

                isPollLoading = pollByClass?.isLoading || false;

                if (pollByClass?.data) {
                    pollData = pollByClass.data;
                }

                if (pollByClass?.error) {
                    pollError = pollByClass.error;
                }
            }

            djStory = (
                <DailyJuiceStoryPoll
                    storyIndex={storyIndex}
                    story={story}
                    poll={{
                        ...poll,
                        data: pollData,
                        error: pollError,
                        isVisibleResults: true,
                        isDisabled: true,
                        isLoading: isPollLoading,
                        questionControl: renderPollQuestionControl(pollId),
                        bottomControl: renderPollBottomControl(),
                    }}
                />
            );
        } else {
            const googleClassroom = {
                shareUrl: settings.google.classroomShareUrl,
                title: story?.title || "",
                onClick: () => {
                    onStoryGoogleClassroomClick(story);
                },
            };

            const share = {
                googleClassroom,
                onClick: buttonShare.toggleMenu,
                onCloseMenu: () => {
                    buttonShare.setVisible(false);
                },
                onCopyLink: () => {
                    onStoryCopyLink(story);
                },
                closeMenuOnClassroomClick: false,
                closeMenuOnCopyLinkClick: false,
                isVisibleMenu: buttonShare.state.isVisibleMenu,
                isGoogleClassroomLoading: buttonShare.state.isGoogleClassroomLoading,
                isCopyLinkLoading: buttonShare.state.isCopyLinkLoading,
            };

            djStory = (
                <DailyJuiceStory
                    juiceId={juiceId}
                    story={story}
                    share={share}
                    storyIndex={storyIndex}
                    dimensions={store.dimensions}
                    videoCaptionSrc={videoCaptionSrc}
                    onCopyToClipboard={onCopyToClipboard}
                    onImageClick={onOpenImagePopup}
                    onWordClick={(word) => {
                        onOpenWordPopup(word, storyId, "story");
                    }}
                    onAudioPlay={() => {
                        onPlayJuiceStory(storyId);
                    }}
                    onExtraJuiceWordClick={onOpenExtraJuicePopup}
                    onAssignmentsClick={() => {
                        onOpenAssignPopup(story);
                    }}
                    enableListen={!story.isTeacherOnlyCategory}
                    isDefaultVideo={!device.isChrome}
                    showStoryIndex={!story.isTeacherOnlyCategory}
                    withShareButton
                    withAssignButton
                />
            );
        }

        return (
            <div className={styles.story}>
                <div className={styles.storyDjStory}>
                    {djStory}
                </div>
                {renderQuiz(story, storyIndex)}
            </div>
        );
    };

    const renderContent = () => {
        if (pageState.error) {
            return (
                <Message>
                    {pageState.error}
                </Message>
            );
        }

        const juiceId = getJuiceId();

        if (!juiceId) {
            return null;
        }

        const stories = getJuiceStories();

        const juiceStories = [];
        let storyIndex = 0;

        for (let i = 0; i < stories.length; i += 1) {
            const s = stories[i];

            juiceStories.push(renderStory(juiceId, s, storyIndex));

            if (!s.isTeacherOnlyCategory) {
                storyIndex += 1;
            }
        }

        return (
            <div className={styles.juiceStories}>
                {juiceStories}
            </div>
        );
    };

    const renderImagePopup = () => {
        return (
            <PopupImage
                image={imagePopup}
                onClose={onCloseImagePopup}
            />
        );
    };

    const renderPlayerAudioPopup = (story) => {
        let title = "";
        let categoryName = "";
        let img = null;
        let trackGroupName = "";
        let trackId = "";
        let audioData = {};

        if (!story) {
            return null;
        }

        if (audioPlayerPopup.storyId !== -1) {
            title = story.title;
            categoryName = story.categoryName;

            if (story.featuredImage && story.featuredImage.url) {
                img = story.featuredImage.url;
            }

            trackGroupName = "juiceStories";
            trackId = story.ID;
        }

        if (!trackGroupName || !trackId) {
            return null;
        }

        audioData = store.playerState?.[trackGroupName]?.[trackId] || {};

        return (
            <PopupPlayerAudio
                key="player-audio-story"
                image={img}
                title={title}
                category={categoryName}
                audio={audioData}
                onPlay={() => {
                    audioManager.play(trackGroupName, trackId);
                }}
                onPause={() => {
                    audioManager.pause(trackGroupName, trackId);
                }}
                onRewind={() => {
                    audioManager.rewind(trackGroupName, trackId);
                }}
                onForward={() => {
                    audioManager.forward(trackGroupName, trackId);
                }}
                onChangeRate={(rate) => {
                    audioManager.changeRate(trackGroupName, trackId, rate);
                }}
                onClose={() => {
                    audioManager.pauseAll();
                    audioManager.stop(trackGroupName, trackId);
                    onCloseAudioPlayerPopup();
                }}
            />
        );
    };

    const renderExtraJuicePopup = () => {
        const eJuice = getExtraJuice(extraJuicePopup.storyId, extraJuicePopup.ejId);

        if (!eJuice) {
            return null;
        }

        const trackGroupName = "extraJuices";
        const trackId = eJuice.id;
        let audioData = null;

        if (extraJuicePopup.isVisiblePlayer) {
            audioData = store.playerState?.[trackGroupName]?.[trackId] || null;
        }

        return (
            <PopupExtraJuice
                key={`popup-extra-juice-${extraJuicePopup.storyId}`}
                storyId={extraJuicePopup.storyId}
                extraJuice={eJuice}
                audio={audioData}
                onWordClick={(word) => {
                    onOpenWordPopup(word, extraJuicePopup.storyId, "extra-juice");
                }}
                onAudioPlay={() => {
                    onShowExtraJuiceStoryPlayer(eJuice.id);
                }}
                onPlay={() => {
                    audioManager.play(trackGroupName, trackId);
                }}
                onPause={() => {
                    audioManager.pause(trackGroupName, trackId);
                }}
                onRewind={() => {
                    audioManager.rewind(trackGroupName, trackId);
                }}
                onForward={() => {
                    audioManager.forward(trackGroupName, trackId);
                }}
                onChangeRate={(rate) => {
                    audioManager.changeRate(trackGroupName, trackId, rate);
                }}
                onPlayerClose={() => {
                    audioManager.stop(trackGroupName, trackId);
                    onHideExtraJuiceStoryPlayer();
                }}
                onClose={onCloseExtraJuicePopup}
            />
        );
    };

    const renderWordPopup = () => {
        const trackGroupName = "words";
        const audioData = store.playerState?.[trackGroupName] || {};

        return (
            <PopupWord
                wordsByName={store.wordsByName}
                wordPopup={store.wordPopup}
                audio={audioData}
                onAudioLoad={(txt) => {
                    audioManager.loadWord(txt);
                }}
                onAudioPlay={(txt) => {
                    audioManager.play(trackGroupName, txt);
                }}
                onAudioStop={(txt) => {
                    audioManager.stop(trackGroupName, txt);
                }}
                onAudioStopAll={(words) => {
                    audioManager.stopAllTracks(trackGroupName, words);
                }}
                onClose={onCloseWordPopup}
            />
        );
    };

    const renderUserPollsPopup = () => {
        return (
            <UserPopupPolls
                onClose={onCloseUserPollsPopup}
            />
        );
    };

    const renderAssignPopup = () => {
        return (
            <PopupAssign
                story={popupAssign.state.story}
                onClose={popupAssign.close}
            />
        );
    };

    const renderPopups = () => {
        const ps = [];

        if (imagePopup) {
            ps.push(renderImagePopup());
        }

        if (popupAssign.state.isVisible) {
            ps.push(renderAssignPopup());
        }

        if (audioPlayerPopup.storyId !== -1) {
            const story = getStoryById(audioPlayerPopup.storyId);

            ps.push(renderPlayerAudioPopup(story));
        }

        if (extraJuicePopup.storyId !== -1 || extraJuicePopup.ejId !== -1) {
            ps.push(renderExtraJuicePopup());
        }

        if (store.wordPopup.isVisible) {
            ps.push(renderWordPopup());
        }

        if (isVisibleUserPollsPopup) {
            ps.push(renderUserPollsPopup());
        }

        return ps;
    };

    const renderSnackbars = () => {
        return snackbar.state.map((bar) => {
            return (
                <Snackbar
                    message={bar.message}
                />
            );
        });
    };

    if (!store.juicesById[urlParams.juiceId] || !store.juices.isJuicesDatesLoaded) {
        return (
            <RequestLoader />
        );
    }

    return (
        <>
            {renderTutorial()}
            {renderPopups()}

            {renderSnackbars()}

            <LayoutContent
                dataComment="teacher-daily-juice"
                withSidePadding={false}
            >
                {renderSelectors()}
                {renderContent()}
            </LayoutContent>

            <UserFooter />
        </>
    );
};

export default TeacherDailyJuices;
