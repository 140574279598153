// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UO1gfA_7395h78oLMyuQ {\n    display: grid;\n}\n\n/* ----- */\n\n.BuT2RrMCBqTwlSc5O3vi {\n    font-weight: 600;\n    text-transform: uppercase;\n\n    color: var(--header-desktop-menu-selected-color);\n}\n\n/* ----- */\n\n.tm3PcMqZVl4g3l8hCvRb {\n    display: grid;\n    grid-gap: 2rem;\n\n    margin: 0 2rem 2rem 2rem;\n}\n\n/* ----- */\n\n@media only screen and (min-width: 700px) {\n    .UO1gfA_7395h78oLMyuQ {\n        justify-content: center;\n        grid-template-columns: 65rem;\n    }\n}\n", "",{"version":3,"sources":["webpack://./app/containers/student-daily-juices-regular/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA,UAAU;;AAEV;IACI,gBAAgB;IAChB,yBAAyB;;IAEzB,gDAAgD;AACpD;;AAEA,UAAU;;AAEV;IACI,aAAa;IACb,cAAc;;IAEd,wBAAwB;AAC5B;;AAEA,UAAU;;AAEV;IACI;QACI,uBAAuB;QACvB,4BAA4B;IAChC;AACJ","sourcesContent":[".page {\n    display: grid;\n}\n\n/* ----- */\n\n.header {\n    font-weight: 600;\n    text-transform: uppercase;\n\n    color: var(--header-desktop-menu-selected-color);\n}\n\n/* ----- */\n\n.sectionContent {\n    display: grid;\n    grid-gap: 2rem;\n\n    margin: 0 2rem 2rem 2rem;\n}\n\n/* ----- */\n\n@media only screen and (min-width: 700px) {\n    .page {\n        justify-content: center;\n        grid-template-columns: 65rem;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": "UO1gfA_7395h78oLMyuQ",
	"header": "BuT2RrMCBqTwlSc5O3vi",
	"sectionContent": "tm3PcMqZVl4g3l8hCvRb"
};
export default ___CSS_LOADER_EXPORT___;
