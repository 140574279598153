import React from "react";

import UserMainFooter from "juice-base/components/user-main-footer/index.js";

import settings from "juice-app/settings";


const UserFooter = () => {
    const privacyPolicyLink = [
        settings.landingSite.domain,
        settings.landingSite.routePrivacyPolicy,
    ].join("");

    const supportLink = [
        settings.landingSite.domain,
        settings.landingSite.routeSupport,
    ].join("");

    return (
        <UserMainFooter
            privacyPolicyLink={privacyPolicyLink}
            supportLink={supportLink}
            copyright={settings.copyright}
        />
    );
};

export default UserFooter;
