// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".I_fkMl1rzXHVZX4cd5aV {\n    display: grid;\n    grid-row-gap: 3rem;\n    justify-content: center;\n}\n\n.IRjqe0h7xHiCCM5f7vVq {}\n\n.v2ALM1CfeCnL0nj83v9s {\n    min-height: 6.4rem;\n\n    display: grid;\n    align-items: center;\n\n    margin: 0 0 1rem 0;\n    padding: 1rem 1.5rem;\n\n    border: 0.2rem solid var(--story-quiz-answer-border-color);\n    border-radius: 0.5rem;\n\n    background-color: var(--text-primary-bg-color);\n    color: var(--text-primary-color);\n}\n\n.nYDReTScT55QQU9z8StO {\n    width: 4rem;\n    height: 4rem;\n}\n\n.KS1tG9I9A5_NelEOcAXe {\n    grid-template-columns: 5rem auto;\n}\n\n.SVxsyMdcBFBOOsGzyPQI {\n    grid-template-columns: 5rem auto;\n}\n\n.f788H4vIRqNGtpTClZ86 {\n    grid-template-columns: 5rem auto;\n}\n", "",{"version":3,"sources":["webpack://./base/business/quiz-question-answers/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;IAClB,uBAAuB;AAC3B;;AAEA,uBAAU;;AAEV;IACI,kBAAkB;;IAElB,aAAa;IACb,mBAAmB;;IAEnB,kBAAkB;IAClB,oBAAoB;;IAEpB,0DAA0D;IAC1D,qBAAqB;;IAErB,8CAA8C;IAC9C,gCAAgC;AACpC;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI,gCAAgC;AACpC","sourcesContent":[".answerEmoji {\n    display: grid;\n    grid-row-gap: 3rem;\n    justify-content: center;\n}\n\n.answers {}\n\n.answer {\n    min-height: 6.4rem;\n\n    display: grid;\n    align-items: center;\n\n    margin: 0 0 1rem 0;\n    padding: 1rem 1.5rem;\n\n    border: 0.2rem solid var(--story-quiz-answer-border-color);\n    border-radius: 0.5rem;\n\n    background-color: var(--text-primary-bg-color);\n    color: var(--text-primary-color);\n}\n\n.answerIcon {\n    width: 4rem;\n    height: 4rem;\n}\n\n.answerCorrect {\n    grid-template-columns: 5rem auto;\n}\n\n.answerIncorrect {\n    grid-template-columns: 5rem auto;\n}\n\n.answerOther {\n    grid-template-columns: 5rem auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"answerEmoji": "I_fkMl1rzXHVZX4cd5aV",
	"answers": "IRjqe0h7xHiCCM5f7vVq",
	"answer": "v2ALM1CfeCnL0nj83v9s",
	"answerIcon": "nYDReTScT55QQU9z8StO",
	"answerCorrect": "KS1tG9I9A5_NelEOcAXe",
	"answerIncorrect": "SVxsyMdcBFBOOsGzyPQI",
	"answerOther": "f788H4vIRqNGtpTClZ86"
};
export default ___CSS_LOADER_EXPORT___;
