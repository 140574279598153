import React from "react";

import Grades from "juice-base/project/grades.js";

import classNames from "juice-base/lib/class-names.js";

import IconPlus from "juice-base/icons/plus/index.js";
import IconUsers from "juice-base/icons/users/index.js";
import IconCaret from "juice-base/icons/caret/index.js";
import IconGraduationCap from "juice-base/icons/graduation-cap/index.js";

import MenuSticky from "juice-base/components/menu-sticky/index.js";

import styles from "./styles.module.css";


const TeacherClassControls = (props) => {
    const renderClassMenu = () => {
        let isVisibleAddOption = false;

        if (props.maxClasses === -1 || props.classes.length < props.maxClasses) {
            isVisibleAddOption = true;
        }

        const controlOptions = [];

        if (!props.isLMS && isVisibleAddOption) {
            controlOptions.push({
                name: "New Class",
                icon: <IconPlus title="New Class" isSky />,
                onClick: props.onAddNewClass,
            });
        }

        if (props.isLMS) {
            controlOptions.push({
                name: "LMS sync classes",
                icon: <IconGraduationCap title="LMS sync classes" isSky />,
                onClick: props.onSyncLMSClasses,
            });
        }

        const options = (props.classes || []).map((cl) => {
            const classGrade = Grades.getGradeGroupByString(cl.grade);

            let label2 = "";

            if (classGrade) {
                label2 = `Grade ${classGrade}`;
            }

            return {
                value: cl.id,
                label: cl.name,
                label2,
                info: cl.isBounceNotificationsExists,
                // TODO: Enable after release
                // withRemoveButton: !cl.isPrimaryTeacher,
                withRemoveButton: false,
            };
        });

        return (
            <div className={styles.headerClassSelect}>
                <MenuSticky
                    selected={props.selectedClass}
                    options={options}
                    controlOptions={controlOptions}
                    onEdit={props.onEditClass}
                    onSelect={props.onClassChange}
                    onRemove={props.onRemoveFromClass}
                    withBorderRadius
                />
            </div>
        );
    };

    const renderTeachersButton = () => {
        if (!props.withTeachersButton) {
            return null;
        }

        const buttonClassTeachersClassName = classNames({
            [styles.headerClassTeachers]: true,
            [styles.headerClassTeachersSelected]: props.isTeacherButtonSelected,
        });

        return (
            <div
                className={buttonClassTeachersClassName}
                onClick={props.onToggleTeacherTable}
                onKeyPress={props.onToggleTeacherTable}
                tabIndex="-1"
                role="button"
            >
                <IconUsers isSky />
                <IconCaret
                    className={styles.iconCaret}
                    isSky
                />
            </div>
        );
    };

    /* ------ */

    return (
        <div className={styles.header}>
            {renderClassMenu()}
            {renderTeachersButton()}
        </div>
    );
};

TeacherClassControls.defaultProps = {
    classes: [],
    maxClasses: null,
    selectedClass: null,

    onClassChange: () => { },
    onToggleTeacherTable: () => { },
    onAddNewClass: () => { },
    onSyncLMSClasses: () => { },
    onEditClass: () => { },
    onRemoveFromClass: () => { },

    isLMS: false,
    isTeacherButtonSelected: false,
    withTeachersButton: true,
};

export default TeacherClassControls;
