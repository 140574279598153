import React from "react";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import classNames from "juice-base/lib/class-names.js";

import ButtonCircleArrow from "juice-base/components/button-circle-arrow/index.js";

import "./styles.css";
import styles from "./styles.module.css";


const CarouselQuizFeedback = (props) => {
    const renderIndicator = (clickFn, isSelected) => {
        const indicatorClassName = classNames({
            [styles.indicator]: true,
            [styles.indicatorSelected]: isSelected,
        });

        return (
            // eslint-disable-next-line jsx-a11y/control-has-associated-label
            <div
                onClick={clickFn}
                onKeyPress={clickFn}
                role="button"
                tabIndex="-1"
                className={indicatorClassName}
            />
        );
    };

    const renderArrowPrev = (clickFn, hasPrev) => {
        return (
            <div className={styles.arrowPrev}>
                <ButtonCircleArrow
                    disabled={!hasPrev}
                    onClick={clickFn}
                    left
                />
            </div>
        );
    };

    const renderArrowNext = (clickFn, hasNext) => {
        return (
            <div className={styles.arrowNext}>
                <ButtonCircleArrow
                    disabled={!hasNext}
                    onClick={clickFn}
                    right
                />
            </div>
        );
    };

    const items = props.messages.map((message) => (
        <div className={styles.slideContainer}>
            <div className={styles.slide}>
                <div className={styles.title}>
                    {message}
                </div>
            </div>
        </div>
    ));

    return (
        <div className="carousel-quiz-feedback">
            <Carousel
                showArrows
                swipeable
                emulateTouch
                showStatus={false}
                showThumbs={false}
                useKeyboardArrows={false}
                renderIndicator={renderIndicator}
                renderArrowPrev={renderArrowPrev}
                renderArrowNext={renderArrowNext}
            >
                {items}
            </Carousel>
        </div>
    );
};

CarouselQuizFeedback.defaultProps = {
    messages: [],
};

export default CarouselQuizFeedback;
