import React from "react";

import IconWrapper from "juice-base/icons/wrapper/index.js";


const IconGradesG7G8 = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={iconProps.className}
                >
                    {iconProps.title}
                    <rect
                        width="40"
                        height="40"
                        rx="20"
                        fill="#3B82F6"
                    />
                    <path
                        d="M9.20951 10.4012L14.4024 10.6734L8.66521 20.787L11.2617 20.9231L16.9988 10.8095L17.1349 8.21303L9.34558 7.80481L9.20951 10.4012Z"
                        fill="#ffffff"
                    />
                    <path
                        d="M30.8 25.55V23.6C30.8 22.17 29.63 21 28.2 21H25.6C24.17 21 23 22.17 23 23.6V25.55C23 26.655 23.845 27.5 24.95 27.5C23.845 27.5 23 28.345 23 29.45V31.4C23 32.83 24.17 34 25.6 34H28.2C29.63 34 30.8 32.83 30.8 31.4V29.45C30.8 28.345 29.955 27.5 28.85 27.5C29.955 27.5 30.8 26.655 30.8 25.55ZM28.2 31.4H25.6V28.8H28.2V31.4ZM28.2 26.2H25.6V23.6H28.2V26.2Z"
                        fill="#ffffff"
                    />
                    <mask
                        id="gradesG7G8IconMask"
                        style={{ maskType: "alpha" }}
                        width="40"
                        height="40"
                    >
                        <circle
                            cx="20"
                            cy="20"
                            r="20"
                            fill="#3B82F6"
                        />
                    </mask>
                    <g mask="url(#gradesG7G8IconMask)">
                        <path
                            d="M30.0862 1.46683L31.9624 2.28911L9.87622 37.3742L7.99999 36.552L30.0862 1.46683Z"
                            fill="#ffffff"
                        />
                    </g>
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconGradesG7G8;
