import { useState } from "react";

const getState = () => ({
    isOpen: false,

    classId: null,
});

const useEditClassPopup = () => {
    const [state, setState] = useState(() => getState());

    const open = (classId) => {
        setState((prev) => ({
            ...prev,
            isOpen: true,
            classId,
        }));
    };

    const close = () => {
        setState(getState());
    };

    return {
        state,
        open,
        close,
    };
};

export default useEditClassPopup;
