const getPlanBillingCycles = () => {
    return {
        MONTHLY: "monthly",
        ANNUALLY: "annually",
    };
};

/* --- */

const isPlanAnnually = (plan) => {
    const cycles = getPlanBillingCycles();

    if (plan) {
        return plan?.billingCycle === cycles.ANNUALLY;
    }

    return false;
};

const isPlanMonthly = (plan) => {
    const cycles = getPlanBillingCycles();

    if (plan) {
        return plan?.billingCycle === cycles.MONTHLY;
    }

    return false;
};

/* --- */

const getPlanByPlanId = (plans, id) => {
    for (let i = 0; i < plans.length; i += 1) {
        if (plans[i].id === id) {
            return plans[i];
        }
    }

    return {};
};

const getPlanBySlug = (plans, slug) => {
    for (let i = 0; i < plans.length; i += 1) {
        if (plans[i].slug === slug) {
            return plans[i];
        }
    }

    return null;
};

/* --- */

const getPlanIdBySlug = (plans, slug) => {
    for (let i = 0; i < plans.length; i += 1) {
        if (plans[i].slug === slug) {
            return plans[i].id;
        }
    }

    return null;
};

const getPlanNameBySlug = (plans, slug) => {
    for (let i = 0; i < plans.length; i += 1) {
        if (plans[i].slug === slug) {
            return plans[i].name;
        }
    }

    return "";
};

const getPlanNameById = (plans, id) => {
    for (let i = 0; i < plans.length; i += 1) {
        if (plans[i].id === id) {
            return plans[i].name;
        }
    }

    return "";
};

/* --- */

const getPlanPriceById = (plans, id) => {
    for (let i = 0; i < plans.length; i += 1) {
        if (plans[i].id === id) {
            return plans[i].price;
        }
    }

    return "";
};

/* ------ */

const getPlanLimitsBySlug = (plans, slug) => {
    const limits = {
        maxStudents: 0,
        maxClasses: 0,
    };

    for (let i = 0; i < plans.length; i += 1) {
        if (plans[i].slug === slug) {
            limits.maxClasses = plans[i].maxClasses;
            limits.maxStudents = plans[i].maxStudents;
            break;
        }
    }

    return limits;
};

/* ------ */

const getPlanFeaturesByPlanId = (plans, id) => {
    for (let i = 0; i < plans.length; i += 1) {
        if (plans[i].id === id) {
            return plans[i].features;
        }
    }

    return [];
};

/* --- */

export default {
    getPlanBillingCycles,

    isPlanAnnually,
    isPlanMonthly,

    getPlanByPlanId,
    getPlanBySlug,

    getPlanIdBySlug,

    getPlanNameBySlug,
    getPlanNameById,

    getPlanPriceById,

    getPlanLimitsBySlug,

    getPlanFeaturesByPlanId,
};
