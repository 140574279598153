import React, {
    useContext,
    useEffect,
    useState,
    useRef,
} from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import text from "juice-base/text/index.js";

import User from "juice-base/project/user.js";
import DailyJuice from "juice-base/project/daily-juice.js";
import Polls from "juice-base/project/polls.js";

import device from "juice-base/lib/device.js";
import scroll from "juice-base/lib/scroll.js";
import timer from "juice-base/lib/timer.js";

import actions from "juice-base/store/actions.js";
import vocabularyActions from "juice-base/actions/vocabulary.js";

import AudioManagerContext from "juice-base/context/audio-manager/index.js";

import RequestLoader from "juice-base/components/request-loader/index.js";

import PopupImage from "juice-base/components/popup-image/index.js";
import PopupExtraJuice from "juice-base/components/popup-extra-juice/index.js";
import PopupPlayerAudio from "juice-base/components/popup-player-audio/index.js";
import ButtonBig from "juice-base/components/button-big/index.js";
import ButtonText from "juice-base/components/button-text/index.js";

import PopupDailyJuiceStatesLegend from "juice-base/business/popup-daily-juice-states-legend/index.js";
import PopupDailyJuiceStats from "juice-base/business/popup-daily-juice-stats/index.js";

import SwiperDailyJuiceStoriesV2 from "juice-base/business/swiper-daily-juice-stories-v2/index.js";
import SwiperDailyJuiceStoryPage from "juice-base/business/swiper-daily-juice-story-page/index.js";
import DailyJuiceNav from "juice-base/business/daily-juice-nav/index.js";
import DailyJuiceStory from "juice-base/business/daily-juice-story/index.js";
import DailyJuiceStoryPoll from "juice-base/business/daily-juice-story-poll/index.js";
import DailyJuiceActivity from "juice-base/business/daily-juice-activity/index.js";
import QuizQuestion from "juice-base/business/quiz-question/index.js";
import PopupWord from "juice-base/business/popup-word/index.js";
import PopupTips from "juice-base/business/popup-tips/index.js";
import PopupEmojiFeedback from "juice-base/business/popup-emoji-feedback/index.js";
import PopupEmoji from "juice-base/business/popup-emoji/index.js";

import UserPopupPolls from "juice-app/containers/user-popup-polls/index.js";

import settings from "juice-app/settings.js";
import events from "juice-app/events.js";
import api from "juice-app/api.js";

import styles from "./styles.module.css";


const storeSelector = (state) => ({
    session: state.user.session,
    user: state.user.user,
    juicesById: state.juices.juicesById,
    wordPopup: state.vocabulary.popup,
    wordsByName: state.vocabulary.wordsByName,
    guardian: state.guardian,
    dimensions: state.device.dimensions,
    studentTodo: state.studentTodo,
    playerState: state.player.playerState,
});

const StudentDailyJuiceStory = () => {
    const activityStoryId = "activity";
    const activityStoryTitle = "Activity Overview";

    const audioManager = useContext(AudioManagerContext);

    const storyTimer = useRef(null);
    const extraJuiceTimer = useRef(null);
    const videoTimer = useRef(null);

    const params = useParams();
    const navigate = useNavigate();
    const loc = useLocation();

    const dispatch = useDispatch();
    const store = useSelector(storeSelector);

    const [imagePopup, setImagePopup] = useState(null);
    const [isVisibleQuizEmojiPopup, setIsVisibleQuizEmojiPopup] = useState(false);
    const [isVisibleLegendPopup, setIsVisibleLegendPopup] = useState(false);
    const [isVisibleStatsPopup, setIsVisibleStatsPopup] = useState(false);
    const [isVisibleUserPollsPopup, setVisibleUserPollsPopup] = useState(false);
    const [tipsPopupState, setTipsPopupState] = useState({
        isOpen: false,
        feedback: [],
    });

    const [audioPlayerPopup, setAudioPlayerPopup] = useState({
        storyId: -1,
    });

    const [extraJuicePopup, setExtraJuicePopup] = useState({
        storyId: -1,
        ejId: -1,
        isVisiblePlayer: false,
    });

    const { juiceId } = params;

    const juiceGrades = store.juicesById?.[juiceId]?.grades || [];

    const isPreview = loc.pathname.indexOf("preview") !== -1;

    const hasRoleGuardian = User.hasRoleGuardian(store.user);
    const hasRoleTeacher = User.hasRoleTeacher(store.user);

    /* --- */

    const addEventFeaturedImageClick = (values) => {
        events.dailyJuice.featuredImageClick({
            disabled: isPreview,
            session: store.session,
            ...values,
        });
    };

    const addEventVideoPlay = (values) => {
        events.dailyJuice.videoPlay({
            disabled: isPreview,
            session: store.session,
            ...values,
        });
    };

    const addEventVideoPause = (values) => {
        events.dailyJuice.videoPause({
            disabled: isPreview,
            session: store.session,
            ...values,
        });
    };

    const addEventVideoEnded = (values) => {
        events.dailyJuice.videoEnded({
            disabled: isPreview,
            session: store.session,
            ...values,
        });
    };

    const addEventVideoTimePlayed = (values) => {
        events.dailyJuice.videoPlayTime({
            disabled: isPreview,
            session: store.session,
            videoId: values.videoId,
            timeTook: videoTimer?.current?.getDiffSeconds() || 0,
        });
    };

    const addEventVocabularyWordClick = (values) => {
        events.dailyJuice.onVocabularyWordClick({
            disabled: isPreview,
            session: store.session,
            ...values,
        });
    };

    const addEventVocabularyWordToSpeech = (values) => {
        if (values.isDefinition) {
            events.dailyJuice.onDefinitionPlay({
                disabled: isPreview,
                session: store.session,
                juiceId: values.juiceId,
                wordId: values.wordId,
                ...values,
            });
            return;
        }

        events.dailyJuice.onPlayWordToSpeech({
            disabled: isPreview,
            session: store.session,
            ...values,
        });
    };

    const addEventPauseVocabularyWordToSpeech = (values) => {
        events.dailyJuice.onPauseWordToSpeech({
            disabled: isPreview,
            session: store.session,
            ...values,
        });
    };

    const addEventExtraJuice = (values) => {
        events.dailyJuice.extraJuiceOpen({
            disabled: isPreview,
            session: store.session,
            ...values,
        });
    };

    const addEventSubmitQuiz = (values) => {
        events.dailyJuice.submitQuiz({
            disabled: isPreview,
            session: store.session,
            ...values,
        });
    };

    const addEventClickOnQuizAnswer = (values) => {
        events.dailyJuice.quizAnswerClick({
            disabled: isPreview,
            session: store.session,
            juiceId: values?.juiceId || -1,
            quizId: values?.values?.quizId || -1,
            answerId: values?.values?.answerId || -1,
        });
    };

    const addEventStoryOpen = (values) => {
        events.dailyJuice.storyOpen({
            disabled: isPreview,
            session: store.session,
            ...values,
        });
    };

    const addEventStoryReadTime = (values) => {
        events.dailyJuice.storyReadTime({
            disabled: isPreview,
            session: store.session,
            juiceId: values.juiceId,
            storyId: values.storyId,
            timeTook: values.timeTook,
        });
    };

    /* --- */

    const onGoBack = () => {
        let backUrl = "";

        if (isPreview) {
            backUrl = `/daily-juice-preview-${settings.previewCode}/${juiceId}`;
        } else {
            backUrl = `/daily-juices/${juiceId}`;
        }

        navigate(backUrl);
    };

    const onGoToStory = (sId) => {
        let storyUrl = "";

        if (isPreview) {
            storyUrl = `/daily-juice-preview-${settings.previewCode}/${juiceId}/${sId}`;
        } else {
            storyUrl = `/daily-juices/${juiceId}/${sId}`;
        }

        navigate(storyUrl);
    };

    const loadJuiceCallback = (res) => {
        if (!res.ok) {
            onGoBack();
            return;
        }

        const stories = res?.data?.stories?.juice || [];
        const storiesIds = stories.map((s) => s.ID);
        const sId = parseInt(params.storyId, 10);

        if (storiesIds.indexOf(sId) === -1) {
            onGoBack();
            return;
        }

        events.dailyJuice.open({
            disabled: isPreview,
            session: store.session,
            juiceId: res.data.id,
            grades: res.data.grades,
        });


        dispatch(actions.juices.setJuice({
            juice: res.data,
        }));
    };

    const loadJuiceById = (studentId) => {
        const myGetJuice = isPreview
            ? api.dailyJuices.getUnpublishedJuice
            : api.dailyJuices.getJuice;

        const req = {
            session: store.session,
            juiceId,
            studentId,
        };

        myGetJuice(req).then(loadJuiceCallback);
    };

    const loadJuice = (studentId) => {
        loadJuiceById(studentId);
    };

    const loadGuardianStudents = () => {
        const req = {
            session: store.session,
            juiceId,
            studentId: store.guardian.selectedStudent,
        };

        api.students.getStudentsByGuardian(req).then((res) => {
            const students = res.students || [];

            let selectedStudentId = store.guardian.selectedStudent;
            if (!selectedStudentId) {
                selectedStudentId = students[0] ? students[0].id || null : null;
            }

            dispatch(actions.guardian.setStudents({
                students,
            }));

            dispatch(actions.guardian.setSelectedStudent({
                selectedStudent: selectedStudentId,
            }));

            loadJuice(selectedStudentId);
        });
    };

    /* ------ */

    const onSubmitPollAnswer = async (values = {}) => {
        if (isPreview) {
            dispatch(actions.juices.setJuicePollAnswered({
                juiceId,
                storyId: values.storyId,
                answerId: values.answerId,
            }));
            return;
        }

        dispatch(actions.juices.setJuicePollLoading({
            juiceId,
            storyId: values.storyId,
        }));

        const pollRes = await api.polls.setPollAnswer({
            session: store.session,
            juiceId,
            pollId: values.pollId,
            answerId: values.answerId,
        });

        if (pollRes.ok) {
            dispatch(actions.juices.setJuicePollLoaded({
                juiceId,
                storyId: values.storyId,
                poll: pollRes.poll,
            }));

            loadJuice();
        } else {
            dispatch(actions.juices.setJuicePollError({
                juiceId,
                storyId: values.storyId,
                error: pollRes.error || text.error,
            }));
        }

        events.student.selectPollAnswer({
            session: store.session,
            userId: store.user.userId,
            juiceId,
            storyId: values.storyId,
            pollId: values.pollId,
            answerId: values.answerId,
        });
    };

    /* ------ */

    const getJuiceStories = () => {
        const juice = store.juicesById[juiceId] || null;

        if (juice?.stories?.juice) {
            return DailyJuice.getStudentStories(juice.stories.juice);
        }

        return [];
    };

    const getJuiceStoryIndex = () => {
        const stories = getJuiceStories();

        if (params.storyId === activityStoryId) {
            return stories.length;
        }

        const sId = parseInt(params.storyId, 10);

        for (let i = 0; i < stories.length; i += 1) {
            if (stories[i].ID === sId) {
                return i;
            }
        }

        return 0;
    };

    const getJuiceAndStory = () => {
        const juice = store.juicesById[juiceId] || null;

        if (!juice) {
            return [null, null];
        }

        const stories = juice?.stories?.juice || [];
        const sId = parseInt(params.storyId, 10);

        const story = DailyJuice.getStoryById(stories, sId);

        return [juice, story];
    };

    const getExtraJuice = (sId, ejId) => {
        const dailyJuice = store.juicesById[juiceId] || {};
        const stories = (dailyJuice.stories || {}).juice || [];

        return DailyJuice.getExtraJuiceById(stories, ejId);
    };

    const onGoNextStory = () => {
        const stories = getJuiceStories();
        const nextStory = DailyJuice.getNextStory(stories, parseInt(params.storyId, 10));

        scroll.scrollToTop();

        if (nextStory) {
            onGoToStory(nextStory.ID);
            return;
        }

        onGoToStory(activityStoryId);
    };

    const onGoPrevStory = () => {
        const stories = getJuiceStories();

        scroll.scrollToTop();

        if (params.storyId === activityStoryId) {
            if (stories.length > 0) {
                const prevStory = stories[stories.length - 1];
                onGoToStory(prevStory.ID);
                return;
            }

            onGoBack();
            return;
        }

        const prevStory = DailyJuice.getPrevStory(stories, parseInt(params.storyId, 10));

        if (prevStory) {
            onGoToStory(prevStory.ID);
            return;
        }

        onGoBack();
    };

    const onSlideToStory = (storyIndex, prevStoryIndex) => {
        const stories = getJuiceStories();
        const story = stories[storyIndex] || null;
        const prevStory = stories[prevStoryIndex] || null;

        if (prevStory) {
            addEventStoryReadTime({
                juiceId,
                storyId: prevStory.ID,
                timeTook: storyTimer?.current?.getDiffSeconds() || 0,
            });
        }

        if (story) {
            addEventStoryOpen({
                juiceId,
                storyId: story.ID,
            });

            onGoToStory(story.ID);
            return;
        }

        onGoToStory(activityStoryId);
    };

    const isFirstStoryOpened = () => {
        const stories = getJuiceStories();

        if (stories.length > 0 && stories[0].ID === parseInt(params.storyId, 10)) {
            return true;
        }

        return false;
    };

    const isLastStoryOpened = () => {
        return params.storyId === activityStoryId;
    };

    const onOpenStatsPopup = () => {
        setIsVisibleStatsPopup(true);
    };

    const onCloseStatsPopup = () => {
        setIsVisibleStatsPopup(false);
    };

    const onOpenUserPollsPopup = () => {
        setVisibleUserPollsPopup(true);
    };

    const onCloseUserPollsPopup = () => {
        setVisibleUserPollsPopup(false);
    };

    /* --- */

    const onOpenImagePopup = (storyId, image) => {
        addEventFeaturedImageClick({
            juiceId,
            grades: juiceGrades,
            storyId,
            imageId: image.id,
        });

        setImagePopup(image);
    };

    const onCloseImagePopup = () => {
        setImagePopup(null);
    };

    const onOpenWordPopup = (word, storyId, openedFrom = "story") => {
        const onLoadWord = (wordId) => {
            addEventVocabularyWordClick({
                juiceId,
                grades: juiceGrades,
                storyId,
                wordId,
                word,
                openedFrom,
            });
        };

        dispatch(vocabularyActions.openPopup({
            api,
            actions,
            events: {
                onLoadWord,
            },
        }, {
            session: store.session,
            word,
        }));
    };

    const onCloseWordPopup = () => {
        dispatch(vocabularyActions.closePopup({ actions }));
    };

    const onOpenExtraJuicePopup = (sId, ejId, openedBy) => {
        if (sId && ejId) {
            extraJuiceTimer.current = timer.startTimer();
            setExtraJuicePopup((prev) => ({
                ...prev,
                storyId: sId,
                ejId,
            }));
        }

        addEventExtraJuice({
            juiceId,
            storyId: sId,
            extraJuiceId: ejId,
            openedBy,
        });
    };

    const onCloseExtraJuicePopup = () => {
        events.dailyJuice.extraJuiceReadTime({
            disabled: isPreview,
            session: store.session,
            extraJuiceId: extraJuicePopup.ejId,
            timeTook: extraJuiceTimer?.current?.getDiffSeconds() || 0,
        });

        setExtraJuicePopup((prev) => ({
            ...prev,
            storyId: -1,
            ejId: -1,
        }));
    };

    const onCloseQuizEmojiPopup = () => {
        setIsVisibleQuizEmojiPopup(false);
    };

    /* --- */

    const onPlayJuiceStory = () => {
        setAudioPlayerPopup((prev) => ({
            ...prev,
            storyId: params.storyId,
        }));

        audioManager.loadStory(params.storyId);
    };

    const onCloseAudioPlayerPopup = () => {
        setAudioPlayerPopup((prev) => ({
            ...prev,
            storyId: -1,
        }));
    };

    /* --- */

    const onShowExtraJuiceStoryPlayer = (extraJuiceId) => {
        setExtraJuicePopup((prev) => ({
            ...prev,
            isVisiblePlayer: true,
        }));

        audioManager.loadExtraJuice(extraJuiceId);
    };

    const onHideExtraJuiceStoryPlayer = () => {
        setExtraJuicePopup((prev) => ({
            ...prev,
            isVisiblePlayer: false,
        }));
    };

    /* --- */

    const onQuestionSubmit = async (values, formMethods) => {
        addEventSubmitQuiz({
            juiceId,
            grades: juiceGrades,
            quizId: values.quizId,
            questionId: values.questionId,
            isCorrect: values.isCorrect,
        });

        if (isPreview) {
            dispatch(actions.juices.setJuiceQuestionAnswer({
                juiceId,
                quizId: values.quizId,
                questionId: values.questionId,
                answerId: values.answerId,
            }));

            setIsVisibleQuizEmojiPopup(true);
            return;
        }

        const setQuizAnswerApi = hasRoleTeacher
            ? api.teachers.setTeacherQuizAnswer
            : api.quizzes.setQuizAnswer;

        const res = await setQuizAnswerApi({
            ...values,
            session: store.session,
            juiceId,
        });

        if (!res.ok) {
            formMethods.setErrors({
                answer: res.error || text.error,
            });
            formMethods.setSubmitting(false);
            return;
        }

        const { quiz } = res;
        const quizQuestions = quiz.questions || [];

        for (let i = 0; i < quizQuestions.length; i += 1) {
            const q = quizQuestions[i];

            if (q.id === values.questionId) {
                dispatch(actions.juices.setJuiceQuestion({
                    juiceId,
                    quizId: values.quizId,
                    question: q,
                }));

                setIsVisibleQuizEmojiPopup(true);
                return;
            }
        }
    };

    /* --- */

    const onOpenTipsPopup = (feedback) => {
        setTipsPopupState({
            isOpen: true,
            feedback,
        });
    };

    /* --- */

    useEffect(() => {
        const [juice, story] = getJuiceAndStory();

        if (story && !story.isUserOpenedStory) {
            dispatch(actions.juices.setStoryViewed({
                juiceId,
                storyId: story.ID,
            }));
        }

        if (store?.studentTodo?.incompletedJuices
            && store.studentTodo.incompletedJuices.length > 0
            && juice?.stories?.juice) {
            const allQuizzes = [];

            juice.stories.juice.forEach((j) => {
                if (j.quizzes && j.quizzes.length > 0) {
                    allQuizzes.push(j.quizzes);
                }
            });

            let userAnswersCount = 0;

            allQuizzes.forEach((quiz) => {
                if (quiz && quiz[0] && quiz[0].answers) {
                    for (let i = 0; i < quiz[0].answers.length; i += 1) {
                        if (quiz[0].answers[i].is_user_choice) {
                            userAnswersCount += 1;
                            break;
                        }
                    }
                }
            });

            if (allQuizzes.length === userAnswersCount) {
                let isJuiceInIncompletedTodos = false;

                for (let i = 0; i < store.studentTodo.incompletedJuices.length; i += 1) {
                    if (store.studentTodo.incompletedJuices[i].id === juice.id) {
                        isJuiceInIncompletedTodos = true;
                        break;
                    }
                }

                if (isJuiceInIncompletedTodos) {
                    if (store.studentTodo.incompletedJuices.length > 1) {
                        dispatch(actions.studentTodo.deleteIncompleteJuiceById({
                            juiceId: juice.id,
                        }));
                    } else {
                        dispatch(actions.studentTodo.clearIncompletedJuices());
                    }

                    dispatch(actions.juices.clearJuices());
                }
            }
        }
    }, [store.juicesById, params.storyId]);

    useEffect(() => {
        dispatch(actions.juices.setLastVisitedJuice({
            lastVisitedJuice: params.juiceId, // TODO:
        }));

        if (hasRoleGuardian) {
            loadGuardianStudents();
        } else {
            loadJuice(store.guardian.selectedStudent);
        }
    }, [params.juiceId]);

    useEffect(() => {
        storyTimer.current = timer.startTimer();
    }, [params.storyId]);

    /* --- */

    const renderPlayerAudioPopup = (story) => {
        let title = "";
        let categoryName = "";
        let img = null;
        let trackGroupName = "";
        let trackId = "";
        let audioData = {};

        if (!story) {
            return null;
        }

        if (audioPlayerPopup.storyId !== -1) {
            title = story.title;
            categoryName = story.categoryName;

            if (story.featuredImage && story.featuredImage.url) {
                img = story.featuredImage.url;
            }

            trackGroupName = "juiceStories";
            trackId = story.ID;
        }

        if (!trackGroupName || !trackId) {
            return null;
        }

        audioData = store.playerState?.[trackGroupName]?.[trackId] || {};

        return (
            <PopupPlayerAudio
                key={`player-audio-story-${story.ID}`}
                image={img}
                title={title}
                category={categoryName}
                audio={audioData}
                onPlay={() => {
                    audioManager.play(trackGroupName, trackId);
                }}
                onPause={() => {
                    audioManager.pause(trackGroupName, trackId);
                }}
                onRewind={() => {
                    audioManager.rewind(trackGroupName, trackId);
                }}
                onForward={() => {
                    audioManager.forward(trackGroupName, trackId);
                }}
                onChangeRate={(rate) => {
                    audioManager.changeRate(trackGroupName, trackId, rate);
                }}
                onClose={() => {
                    audioManager.stop(trackGroupName, trackId);
                    onCloseAudioPlayerPopup();
                }}
            />
        );
    };

    const renderImagePopup = () => {
        return (
            <PopupImage
                image={imagePopup}
                onClose={onCloseImagePopup}
            />
        );
    };

    const renderWordPopup = () => {
        const trackGroupName = "words";
        const audioData = store.playerState?.[trackGroupName] || {};

        return (
            <PopupWord
                wordsByName={store.wordsByName}
                wordPopup={store.wordPopup}
                audio={audioData}
                onAudioLoad={(txt, wordId, isDefinition) => {
                    addEventVocabularyWordToSpeech({
                        juiceId,
                        grades: juiceGrades,
                        wordId,
                        isDefinition,
                    });

                    audioManager.loadWord(txt);
                }}
                onAudioPlay={(txt, wordId, isDefinition) => {
                    addEventVocabularyWordToSpeech({
                        juiceId,
                        grades: juiceGrades,
                        text: txt,
                        wordId,
                        isDefinition,
                    });

                    audioManager.play(trackGroupName, txt);
                }}
                onAudioStop={(txt, wordId) => {
                    addEventPauseVocabularyWordToSpeech({
                        juiceId,
                        grades: juiceGrades,
                        text: txt,
                        wordId,
                    });

                    audioManager.stop(trackGroupName, txt);
                }}
                onAudioStopAll={(words) => {
                    audioManager.stopAllTracks(trackGroupName, words);
                }}
                onClose={onCloseWordPopup}
            />
        );
    };

    const renderExtraJuicePopup = () => {
        if (extraJuicePopup.storyId === -1
            || extraJuicePopup.ejId === -1) {
            return null;
        }

        const eJuice = getExtraJuice(extraJuicePopup.storyId, extraJuicePopup.ejId);

        if (!eJuice) {
            return null;
        }

        const trackGroupName = "extraJuices";
        const trackId = eJuice.id;
        let audioData = null;

        if (extraJuicePopup.isVisiblePlayer) {
            audioData = store.playerState?.[trackGroupName]?.[trackId] || null;
        }

        return (
            <PopupExtraJuice
                key={`popup-extra-juice-${extraJuicePopup.storyId}`}
                storyId={extraJuicePopup.storyId}
                extraJuice={eJuice}
                audio={audioData}
                onWordClick={(word) => {
                    onOpenWordPopup(word, extraJuicePopup.storyId, "extra-juice");
                }}
                onAudioPlay={() => {
                    onShowExtraJuiceStoryPlayer(eJuice.id);
                }}
                onPlay={() => {
                    audioManager.play(trackGroupName, trackId);
                }}
                onPause={() => {
                    audioManager.pause(trackGroupName, trackId);
                }}
                onRewind={() => {
                    audioManager.rewind(trackGroupName, trackId);
                }}
                onForward={() => {
                    audioManager.forward(trackGroupName, trackId);
                }}
                onChangeRate={(rate) => {
                    audioManager.changeRate(trackGroupName, trackId, rate);
                }}
                onPlayerClose={() => {
                    audioManager.stop(trackGroupName, trackId);
                    onHideExtraJuiceStoryPlayer();
                }}
                onClose={onCloseExtraJuicePopup}
            />
        );
    };

    const renderEmojiPopup = (story) => {
        const q = DailyJuice.getStoryQuiz(story);

        if (!q) {
            return null;
        }

        const isCorrect = DailyJuice.isValidQuiz(q);

        let exFeedback = q.explicitFeedback || [];

        if (q?.explicitFeedbackCustom?.length > 0) {
            exFeedback = q.explicitFeedbackCustom;
        }

        const feedback = DailyJuice.fixExFeedback(exFeedback, isCorrect);

        if (feedback.length === 0) {
            return (
                <PopupEmoji
                    isCorrect={isCorrect}
                    onClose={onCloseQuizEmojiPopup}
                />
            );
        }

        return (
            <PopupEmojiFeedback
                feedback={feedback}
                isCorrect={isCorrect}
                onClose={onCloseQuizEmojiPopup}
            />
        );
    };

    const renderLegendPopup = () => {
        return (
            <PopupDailyJuiceStatesLegend
                onClose={() => {
                    setIsVisibleLegendPopup(false);
                }}
            />
        );
    };

    const renderStatsPopup = () => {
        const stories = getJuiceStories();
        const quizzesStats = DailyJuice.getQuizzesStats(stories);

        return (
            <PopupDailyJuiceStats
                stats={quizzesStats}
                sponsors={store.sponsors}
                isWindows={device.isWindows}
                onSponsorClick={() => {
                    events.dailyJuice.lastPageSponsorClick({
                        disabled: isPreview,
                        session: store.session,
                        juiceId,
                        grades: store.juicesById[juiceId].grades,
                    });
                }}
                onScrollTo={() => {
                    if (quizzesStats.firstSkipped) {
                        onGoToStory(quizzesStats.firstSkipped);
                    }
                    onCloseStatsPopup();
                }}
                onClose={onCloseStatsPopup}
            />
        );
    };

    const renderTipsPopup = () => {
        return (
            <PopupTips
                messages={tipsPopupState.feedback}
                onClose={() => {
                    setTipsPopupState({
                        isOpen: false,
                        feedback: [],
                    });
                }}
            />
        );
    };

    const renderUserPollsPopup = () => {
        return (
            <UserPopupPolls
                onClose={onCloseUserPollsPopup}
            />
        );
    };

    const renderPopups = (story) => {
        const ps = [];

        if (imagePopup) {
            ps.push(renderImagePopup());
        }

        const ejPopup = renderExtraJuicePopup();

        if (ejPopup) {
            ps.push(ejPopup);
        }

        if (store.wordPopup.isVisible) {
            ps.push(renderWordPopup());
        }

        if (audioPlayerPopup.storyId !== -1
            || audioPlayerPopup.extraJuiceId !== -1) {
            ps.push(renderPlayerAudioPopup(story));
        }

        if (isVisibleQuizEmojiPopup) {
            ps.push(renderEmojiPopup(story));
        }

        if (isVisibleLegendPopup) {
            ps.push(renderLegendPopup());
        }

        if (isVisibleStatsPopup) {
            ps.push(renderStatsPopup());
        }

        if (isVisibleUserPollsPopup) {
            ps.push(renderUserPollsPopup());
        }

        if (tipsPopupState.isOpen) {
            ps.push(renderTipsPopup());
        }

        return ps;
    };

    /* --- */

    const renderStoryNav = (juice, story) => {
        let title = story ? story.title : "";

        if (params.storyId === activityStoryId) {
            title = activityStoryTitle;
        }

        const isFirstStory = isFirstStoryOpened();
        const isLastStory = isLastStoryOpened();

        return (
            <div className={styles.nav}>
                <DailyJuiceNav
                    date={juice.juiceDate}
                    title={title}
                    onGoToPrevStory={onGoPrevStory}
                    onGoToNextStory={onGoNextStory}
                    isFirstStoryOpened={isFirstStory}
                    isLastStoryOpened={isLastStory}
                    onBack={() => {
                        scroll.scrollToTop();

                        addEventStoryReadTime({
                            juiceId: params.juiceId,
                            storyId: params.storyId,
                            timeTook: storyTimer?.current?.getDiffSeconds() || 0,
                        });

                        onGoBack();
                    }}
                />
            </div>
        );
    };

    const renderNextStoryLink = (sId) => {
        const stories = getJuiceStories();
        const nextStory = DailyJuice.getNextStory(stories, sId);

        let nextStoryId = activityStoryId;

        if (nextStory) {
            nextStoryId = nextStory.ID;
        }

        return (
            <div className={styles.nextStoryBlock}>
                <ButtonBig
                    onClick={() => {
                        onGoToStory(nextStoryId);
                    }}
                >
                    Next
                </ButtonBig>
            </div>
        );
    };

    const renderQuiz = (story) => {
        const q = DailyJuice.getStoryQuiz(story);

        if (!q) {
            return [null, renderNextStoryLink(story.ID)];
        }

        let nextStoryLink = null;

        if (DailyJuice.isAnsweredQuiz(q)) {
            nextStoryLink = renderNextStoryLink(story.ID);
        }

        const feedback = q?.explicitFeedback || [];

        const quiz = (
            <QuizQuestion
                question={q}
                showFeedback={feedback.length > 0}
                allowAnswering={!hasRoleGuardian}
                onTipsClick={(() => {
                    onOpenTipsPopup(feedback);
                })}
                onSubmit={onQuestionSubmit}
                onSelectAnswer={(values) => {
                    addEventClickOnQuizAnswer({
                        juiceId,
                        values,
                    });
                }}
            />
        );

        return [quiz, nextStoryLink];
    };

    const renderStory = (juice, story, storyIndex) => {
        let videoCaptionSrc = null;

        if (story.featuredVideo?.id) {
            videoCaptionSrc = api.videos.getVideoCaptionURL({
                id: story.featuredVideo.id,
                session: store.session,
            });
        }

        const storyId = story.ID;

        const poll = Polls.getStoryPoll(story);

        let djStory = null;

        if (poll) {
            const bottomControl = (
                <ButtonText
                    uppercase
                    onClick={onOpenUserPollsPopup}
                >
                    See all polls
                </ButtonText>
            );

            const pollData = {
                ...poll,
                bottomControl,
            };

            if (hasRoleTeacher) {
                pollData.isDisabled = true;
                pollData.isVisibleResults = true;
            }

            djStory = (
                <DailyJuiceStoryPoll
                    storyIndex={storyIndex}
                    story={story}
                    poll={pollData}
                    onSubmitPoll={(values) => {
                        onSubmitPollAnswer({
                            ...values,
                            storyId,
                        });
                    }}
                />
            );
        } else {
            djStory = (
                <DailyJuiceStory
                    story={story}
                    storyIndex={storyIndex}
                    dimensions={store.dimensions}
                    isDefaultVideo={!device.isChrome}
                    videoCaptionSrc={videoCaptionSrc}
                    onImageClick={(img) => {
                        onOpenImagePopup(storyId, img);
                    }}
                    onWordClick={(word) => {
                        onOpenWordPopup(word, storyId, "story");
                    }}
                    onAudioPlay={onPlayJuiceStory}
                    onVideoPlayStart={(videoSrc, videoId) => {
                        videoTimer.current = timer.startTimer();

                        dispatch(actions.juices.setStoryVideoViewed({
                            juiceId,
                            storyId,
                            videoId,
                        }));

                        addEventVideoPlay({
                            juiceId,
                            grades: juiceGrades,
                            storyId,
                            videoId,
                            videoSrc,
                        });
                    }}
                    onVideoPlayPause={(videoSrc, videoId) => {
                        addEventVideoTimePlayed({
                            videoId,
                        });

                        addEventVideoPause({
                            juiceId,
                            grades: juiceGrades,
                            storyId,
                            videoId,
                            videoSrc,
                        });
                    }}
                    onVideoPlayEnd={(videoSrc, videoId) => {
                        addEventVideoTimePlayed({
                            videoId,
                        });

                        addEventVideoEnded({
                            juiceId,
                            grades: juiceGrades,
                            storyId,
                            videoId,
                            videoSrc,
                        });
                    }}
                    onExtraJuiceWordClick={onOpenExtraJuicePopup}
                />
            );
        }

        const [quiz, link] = renderQuiz(story);

        return (
            <SwiperDailyJuiceStoryPage
                key={storyId}
                story={djStory}
                quiz={quiz}
                link={link}
            />
        );
    };

    const renderPages = (juice) => {
        const pages = [];

        const stories = getJuiceStories();

        stories.forEach((story, index) => {
            pages.push(renderStory(juice, story, index));
        });

        const djActivity = (
            <div className={styles.activityOverview}>
                <DailyJuiceActivity
                    stories={stories}
                    onGoToStory={onGoToStory}
                    onShowReport={onOpenStatsPopup}
                    onInfoClick={() => {
                        setIsVisibleLegendPopup(true);
                    }}
                    withScoreReport={!hasRoleTeacher}
                />
            </div>
        );

        pages.push(djActivity);

        return pages;
    };

    /* --- */

    const [juice, story] = getJuiceAndStory();

    if (!juice || (hasRoleGuardian && !store.guardian.isStudentsLoaded)) {
        return (
            <RequestLoader />
        );
    }

    if (!story && params.storyId !== activityStoryId) {
        return null;
    }

    return (
        <>
            {renderPopups(story)}

            {renderStoryNav(juice, story)}

            <div className={styles.stories}>
                {/*
                <SwiperDailyJuiceStories
                    page={getJuiceStoryIndex()}
                    onLeft={onGoNextStory}
                    onRight={onGoPrevStory}
                    isSafari={device.isSafari}
                    isPWA={device.isPWA}
                >
                    {renderPages(juice)}
                </SwiperDailyJuiceStories>
                */}
                <SwiperDailyJuiceStoriesV2
                    index={getJuiceStoryIndex()}
                    onSlide={onSlideToStory}
                    isSafari={device.isSafari}
                    isIOS15={device.isIOS15}
                    isPWA={device.isPWA}
                >
                    {renderPages(juice)}
                </SwiperDailyJuiceStoriesV2>
            </div>
        </>
    );
};

export default StudentDailyJuiceStory;
