// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".yzGqt3I8rdU5E0foJ8Jo {\n    display: grid;\n    grid-gap: 1.5rem;\n\n    justify-content: center;\n}\n\n.uPN1m0aC61p7Co6M9btA {\n    font-size: 2rem;\n    word-break: break-all;\n}\n\n.pRiSgaXuuH2xr1rEvLWu {\n    color: var(--color-5);\n    word-break: break-all;\n}\n\n.pRiSgaXuuH2xr1rEvLWu > a {\n    color: var(--color-5);\n}\n\n.eysddl3Iyhh08Wz6nELo {\n    font-weight: 600;\n    font-size: 2rem;\n    word-break: break-all;\n}\n", "",{"version":3,"sources":["webpack://./base/business/popup-confirm-generate-class-code/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,gBAAgB;;IAEhB,uBAAuB;AAC3B;;AAEA;IACI,eAAe;IACf,qBAAqB;AACzB;;AAEA;IACI,qBAAqB;IACrB,qBAAqB;AACzB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,qBAAqB;AACzB","sourcesContent":[".classCodeContent {\n    display: grid;\n    grid-gap: 1.5rem;\n\n    justify-content: center;\n}\n\n.classCodeText {\n    font-size: 2rem;\n    word-break: break-all;\n}\n\n.classCodeMessage {\n    color: var(--color-5);\n    word-break: break-all;\n}\n\n.classCodeMessage > a {\n    color: var(--color-5);\n}\n\n.classCodeLink {\n    font-weight: 600;\n    font-size: 2rem;\n    word-break: break-all;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"classCodeContent": "yzGqt3I8rdU5E0foJ8Jo",
	"classCodeText": "uPN1m0aC61p7Co6M9btA",
	"classCodeMessage": "pRiSgaXuuH2xr1rEvLWu",
	"classCodeLink": "eysddl3Iyhh08Wz6nELo"
};
export default ___CSS_LOADER_EXPORT___;
