import React, { useState, useEffect } from "react";

import Teachers from "juice-base/project/teachers.js";

import classNames from "juice-base/lib/class-names.js";

import text from "juice-base/text/index.js";

import useAccordion from "juice-base/hooks/use-accordion/index.js";

import IconUserAdd from "juice-base/icons/user-add/index.js";
import IconTrashWithLinesFull from "juice-base/icons/trash-with-lines-full/index.js";

import ButtonCircle from "juice-base/components/button-circle/index.js";
import Checkbox from "juice-base/components/forms/checkbox/index.js";
import AccordionWithControls from "juice-base/components/accordion-with-controls/index.js";
import Message from "juice-base/components/message/index.js";
import RequestLoader from "juice-base/components/request-loader/index.js";

import TableSortableColumnName from "juice-base/business/table-sortable-column-name/index.js";

import useClassTeacherTableHook from "./use-class-teachers-table.js";
import styles from "./styles.module.css";


const ClassTeachersTable = (props) => {
    const [selectedTeachers, setSelectedTeachers] = useState([]);

    /* ----- */

    const teachersAccordion = useAccordion();

    /* ----- */

    const getNonPrimaryTeachers = () => {
        return (props.data || []).filter((d) => !d.isPrimaryTeacher);
    };

    /* ----- */

    const isAllTeachersSelected = () => {
        const nonPrimaryTeachers = getNonPrimaryTeachers();

        return nonPrimaryTeachers.length > 0
            && selectedTeachers.length === nonPrimaryTeachers.length;
    };

    const isAllTeachersCheckboxDisabled = () => {
        const nonPrimaryTeachers = getNonPrimaryTeachers();

        if (nonPrimaryTeachers.length === 0) {
            return true;
        }

        return false;
    };

    /* ----- */

    const onDelete = () => {
        props.onDelete(selectedTeachers);
    };

    const onSelectTeacher = (id) => {
        setSelectedTeachers((prev) => {
            if (prev.indexOf(id) === -1) {
                return [...prev].concat(id);
            }

            return [...prev].filter((teacherId) => teacherId !== id);
        });
    };

    const onToggleAllTeachers = () => {
        const nonPrimaryTeachers = getNonPrimaryTeachers();

        if (selectedTeachers.length === nonPrimaryTeachers.length) {
            setSelectedTeachers([]);
            return;
        }

        const ids = nonPrimaryTeachers.map((d) => d.id);

        setSelectedTeachers(ids);
    };

    /* ----- */

    useEffect(() => {
        setSelectedTeachers([]);
    }, [props.isLoading]);

    /* ----- */

    const renderHeader = () => {
        const controls = [
            {
                icon: <IconUserAdd isSky />,
                onClick: props.onAdd,
            },
            {
                icon: <IconTrashWithLinesFull isSky />,
                onClick: onDelete,
                disabled: selectedTeachers.length === 0,
            },
        ].map((c) => {
            return (
                <div className={styles.control}>
                    <ButtonCircle
                        icon={c.icon}
                        onClick={c.onClick}
                        disabled={c.disabled || false}
                    />
                </div>
            );
        });

        return (
            <div className={styles.header}>
                <div className={styles.title}>
                    Teachers
                </div>
                <div className={styles.controls}>
                    {controls}
                </div>
            </div>
        );
    };


    const renderDesktopTable = (sortedTeachers) => {
        const rowDataFirstClassName = classNames({
            [styles.tableRowData]: true,
            [styles.tableRowDataFirst]: true,
        });

        const rows = sortedTeachers.map((row) => {
            let checkbox = null;

            if (!row.isPrimaryTeacher) {
                checkbox = (
                    <Checkbox
                        name={`table-desktop-teacher-${row.id}`}
                        checked={selectedTeachers.indexOf(row.id) !== -1}
                        onChange={() => {
                            onSelectTeacher(row.id);
                        }}
                    />
                );
            }

            return (
                <div className={styles.tableRow}>
                    <div className={rowDataFirstClassName}>
                        {checkbox}
                    </div>
                    <div className={styles.tableRowData}>
                        {row.fullName}
                    </div>
                    <div className={styles.tableRowData}>
                        {row.email}
                    </div>
                    <div className={styles.tableRowData}>
                        {row.userName}
                    </div>
                </div>
            );
        });

        const headerRowClassName = classNames({
            [styles.tableRow]: true,
            [styles.tableRowFirst]: true,
        });

        const headerRow = (
            <div className={headerRowClassName}>
                <div className={rowDataFirstClassName}>
                    <Checkbox
                        name="table-desktop-all-teachers"
                        checked={isAllTeachersSelected()}
                        onChange={onToggleAllTeachers}
                        disabled={isAllTeachersCheckboxDisabled()}
                    />
                </div>
                <div className={styles.sortableColumn}>
                    <TableSortableColumnName
                        name="Name"
                        isDown={Teachers.isSortByNameZTOA(props.sortBy)}
                        onClick={props.onSortByTeacherNames}
                    />
                </div>
                <div className={styles.tableRowData}>
                    Email
                </div>
                <div className={styles.tableRowData}>
                    Username
                </div>
            </div>
        );

        return (
            <div className={styles.tableRows}>
                {headerRow}
                {rows}
            </div>
        );
    };

    const renderRowControls = (teacher) => {
        const teacherId = teacher.id;

        return (
            <Checkbox
                key={`class-teacher-mobile-table-${teacherId}`}
                name={`class-teacher-mobile-table-${teacherId}`}
                checked={selectedTeachers.indexOf(teacherId) !== -1}
                onChange={() => {
                    onSelectTeacher(teacherId);
                }}
            />
        );
    };

    const renderTeacherData = (teacher) => {
        const rows = [
            {
                label: "Email",
                value: teacher.email,
            },
            {
                label: "Username",
                value: teacher.username,
            },
        ].map((d) => {
            return (
                <div className={styles.mobileRow}>
                    <div>
                        {d.label}
                    </div>
                    <div className={styles.mobileRowValue}>
                        {d.value}
                    </div>
                </div>
            );
        });

        return rows;
    };

    const renderMobileTable = (sortedTeachers) => {
        return sortedTeachers.map((teacher, i) => {
            const accordionClassName = classNames({
                [styles.accordion]: true,
                [styles.accordionFirst]: i === 0,
            });

            const controls = [];

            if (!teacher.isPrimaryTeacher) {
                controls.push({
                    isCustom: true,
                    element: renderRowControls(teacher),
                });
            }

            return (
                <AccordionWithControls
                    labelClassName={accordionClassName}
                    expandedContentClassName={styles.accordionExpandedContent}
                    label={teacher.fullName}
                    controls={controls}
                    onClick={() => {
                        teachersAccordion.onSetExpanded(teacher.id);
                    }}
                    isExpanded={teachersAccordion.isExpanded(teacher.id)}
                    isControlsWithBorder={false}
                >
                    {renderTeacherData(teacher)}
                </AccordionWithControls>
            );
        });
    };

    const renderTable = () => {
        if (props.isLoading) {
            return (
                <RequestLoader />
            );
        }

        if (props.error) {
            return (
                <Message>
                    {props.error}
                </Message>
            );
        }

        if (props.data.length === 0) {
            return (
                <Message>
                    {text.noTeachersFound}
                </Message>
            );
        }

        const sortedTeachers = Teachers.sortTeachers(props.sortBy, props.data);

        if (props.isMobile) {
            return renderMobileTable(sortedTeachers);
        }

        return renderDesktopTable(sortedTeachers);
    };

    return (
        <div className={styles.table}>
            {renderHeader()}
            {renderTable()}
        </div>
    );
};

ClassTeachersTable.defaultProps = {
    data: [],
    sortBy: null,
    error: "",
    onAdd: () => { },
    onDelete: () => { },
    isMobile: false,
    isLoading: false,
};

export const useClassTeacherTable = useClassTeacherTableHook;
export default ClassTeachersTable;
