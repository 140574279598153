import React, { useState } from "react";
import { Link } from "react-router-dom";

import RequestLoader from "juice-base/components/request-loader/index.js";

import ButtonFlat from "juice-base/components/button-flat/index.js";
import PopupConfirm, {
    PopupConfirmContent,
    PopupConfirmButtons,
} from "juice-base/components/popup-confirm/index.js";

import UserPasswordResetForm from "juice-base/forms/user-password-reset/index.js";

import linkStyles from "juice-base/components/link/styles.module.css";
import styles from "./styles.module.css";


const PopupUserResetPassword = (props) => {
    const [isDisabled, setIsDisabled] = useState(true);
    const [newPassword, setNewPassword] = useState("");

    const onFormChange = (formProps) => {
        setIsDisabled(formProps.isDisabled);
        setNewPassword(formProps.values.password);
    };

    const onSubmit = () => {
        setIsDisabled(true);
        props.onSubmit(newPassword);
    };

    const renderContent = () => {
        if (props.error) {
            return (
                <div className={styles.errorBlock}>
                    <div>
                        {props.error}
                    </div>

                    <div className={styles.errorLinks}>
                        <Link
                            to="/forgot-password"
                            className={linkStyles.link}
                        >
                            Try to recover password
                        </Link>
                    </div>
                </div>
            );
        }

        return (
            <>
                <div className={styles.formHeader}>
                    Let&#39;s set a new password
                </div>

                <UserPasswordResetForm
                    passwordMinLength={props.passwordMinLength}
                    onChange={onFormChange}
                    onSubmit={onSubmit}
                />
            </>
        );
    };

    /* --- */

    if (!props.isLoaded) {
        return (
            <PopupConfirm>
                <PopupConfirmContent>
                    <RequestLoader />
                </PopupConfirmContent>

                <PopupConfirmButtons>
                    <ButtonFlat onClick={props.onClose}>
                        Close
                    </ButtonFlat>
                </PopupConfirmButtons>
            </PopupConfirm>
        );
    }

    let buttonSave = null;

    if (!props.error) {
        buttonSave = (
            <ButtonFlat
                onClick={onSubmit}
                disabled={isDisabled}
            >
                Save
            </ButtonFlat>
        );
    }

    return (
        <PopupConfirm>
            <PopupConfirmContent>
                {renderContent()}
            </PopupConfirmContent>

            <PopupConfirmButtons>
                {buttonSave}

                <ButtonFlat
                    onClick={props.onClose}
                    disabled={props.isLoading}
                >
                    {props.isSubmitted ? "Close" : "Cancel"}
                </ButtonFlat>
            </PopupConfirmButtons>
        </PopupConfirm>
    );
};

PopupUserResetPassword.defaultProps = {
    passwordMinLength: 8,
    isLoaded: false,
    error: "",
    onSubmit: () => { },
    onClose: () => { },
};

export default PopupUserResetPassword;
