import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import actions from "juice-base/store/actions.js";
import vocabularyActions from "juice-base/actions/vocabulary.js";

import AudioManagerContext from "juice-base/context/audio-manager/index.js";

import useTitle from "juice-base/hooks/use-title/index.js";

import { withAuth } from "juice-base/components/auth/index.js";
import LayoutContent from "juice-base/components/layout-content/index.js";
import RequestLoader from "juice-base/components/request-loader/index.js";
import ExtraJuice from "juice-base/business/extra-juice/index.js";

import PopupWord from "juice-base/business/popup-word/index.js";
import PopupPlayerAudio from "juice-base/components/popup-player-audio/index.js";

import api from "juice-app/api.js";

import styles from "./styles.module.css";


const storeSelector = (state) => ({
    session: state.user.session,
    extraJuicesById: state.extraJuices.extraJuicesById,
    wordPopup: state.vocabulary.popup,
    wordsByName: state.vocabulary.wordsByName,
    playerState: state.player.playerState,
});

const ShowExtraJuiceView = () => {
    const audioManager = useContext(AudioManagerContext);

    const params = useParams();
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const store = useSelector(storeSelector);

    const [audioPlayerPopup, setAudioPlayerPopup] = useState(-1);

    /* --- */

    const goBack = () => {
        navigate("/");
    };

    /* --- */

    const onWordClick = (word) => {
        dispatch(vocabularyActions.openPopup({
            api,
            actions,
        }, {
            session: store.session,
            word,
        }));
    };

    const onCloseWordPopup = () => {
        dispatch(vocabularyActions.closePopup({ actions }));
    };

    /* --- */

    const onPlayExtraJuice = (ejId) => {
        setAudioPlayerPopup(ejId);
        audioManager.loadExtraJuice(ejId);
    };

    const onCloseAudioPlayerPopup = () => {
        setAudioPlayerPopup(-1);
    };

    const loadExtraJuice = async () => {
        const res = await api.extraJuices.getExtraJuice({
            session: store.session,
            extraJuiceId: params.extraJuiceId,
        });

        if (res.ok) {
            dispatch(actions.extraJuices.setExtraJuice({
                extraJuice: res.extra_juice,
            }));
        } else {
            goBack();
        }
    };

    /* --- */

    useTitle(() => {
        let title = "Extra Juice";

        const ej = store.extraJuicesById[params.extraJuiceId];

        if (ej) {
            title += ` - ${ej.title}`;
        }

        return title;
    }, [store.extraJuicesById]);

    useEffect(() => {
        loadExtraJuice();
    }, [params.extraJuiceId]);

    useEffect(() => {
        return () => {
            dispatch(vocabularyActions.closePopup({ actions }));
        };
    }, []);

    /* --- */

    const renderWordPopup = () => {
        const trackGroupName = "words";
        const audioData = store.playerState?.[trackGroupName] || {};

        return (
            <PopupWord
                wordsByName={store.wordsByName}
                wordPopup={store.wordPopup}
                audio={audioData}
                onAudioLoad={(txt) => {
                    audioManager.loadWord(txt);
                }}
                onAudioPlay={(txt) => {
                    audioManager.play(trackGroupName, txt);
                }}
                onAudioStop={(txt) => {
                    audioManager.stop(trackGroupName, txt);
                }}
                onAudioStopAll={(words) => {
                    audioManager.stopAllTracks(trackGroupName, words);
                }}
                onClose={onCloseWordPopup}
            />
        );
    };

    const renderPlayerAudioPopup = (eJuice) => {
        let title = "";
        let categoryName = "";
        let img = null;
        let trackGroupName = "";
        let trackId = "";
        let audioData = {};

        if (audioPlayerPopup !== -1) {
            title = eJuice.title;
            categoryName = eJuice.categoryName;

            if (eJuice.image) {
                img = eJuice.image;
            }

            trackGroupName = "extraJuices";
            trackId = eJuice.id;
        }

        if (!trackGroupName || !trackId) {
            return null;
        }

        audioData = store.playerState?.[trackGroupName]?.[trackId] || {};

        return (
            <PopupPlayerAudio
                key={`player-audio-extra-juice-${eJuice.id}`}
                image={img}
                title={title}
                category={categoryName}
                audio={audioData}
                onPlay={() => {
                    audioManager.play(trackGroupName, trackId);
                }}
                onPause={() => {
                    audioManager.pause(trackGroupName, trackId);
                }}
                onRewind={() => {
                    audioManager.rewind(trackGroupName, trackId);
                }}
                onForward={() => {
                    audioManager.forward(trackGroupName, trackId);
                }}
                onChangeRate={(rate) => {
                    audioManager.changeRate(trackGroupName, trackId, rate);
                }}
                onClose={() => {
                    audioManager.stop(trackGroupName, trackId);
                    onCloseAudioPlayerPopup();
                }}
            />
        );
    };

    const renderPopups = (eJuice) => {
        const ps = [];

        if (store.wordPopup.isVisible) {
            ps.push(renderWordPopup());
        }

        if (audioPlayerPopup !== -1) {
            ps.push(renderPlayerAudioPopup(eJuice));
        }

        return ps;
    };

    if (!store.extraJuicesById[params.extraJuiceId]) {
        return (
            <RequestLoader />
        );
    }

    const extraJuice = store.extraJuicesById[params.extraJuiceId];

    return (
        <>
            {renderPopups(extraJuice)}

            <LayoutContent>
                <div className={styles.extraJuice}>
                    <ExtraJuice
                        extraJuice={extraJuice}
                        onWordClick={onWordClick}
                        onAudioPlay={() => {
                            onPlayExtraJuice(extraJuice.id);
                        }}
                    />
                </div>
            </LayoutContent>
        </>
    );
};

export default withAuth([
    "teacher",
    "student",
    "guardian",
])(ShowExtraJuiceView);
