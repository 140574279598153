import React from "react";

import classNames from "juice-base/lib/class-names.js";

import Wrapper from "./wrapper.js";
import styles from "./styles.module.css";


const CheckboxWithStates = (props) => {
    const isUnchecked = !props.isChecked && !props.isHalfChecked;

    const checkboxClassName = classNames({
        [styles.checkbox]: true,
        [styles.checkboxSelected]: props.isChecked || props.isHalfChecked,
        [styles.checkboxChecked]: props.isChecked,
        [styles.checkboxHalfChecked]: props.isHalfChecked,
        [styles.checkboxUnchecked]: isUnchecked,
    });

    /* eslint-disable */
    const checkbox = (
        <div
            className={checkboxClassName}
            onClick={props.onClick}
            onKeyPress={props.onClick}
            role="button"
            tabIndex="-1"
        />
    );
    /* eslint-enable */

    return checkbox;
};

CheckboxWithStates.defaultProps = {
    onClick: () => { },
    isChecked: false,
    isHalfChecked: false,
};

export const CheckboxWithStatesWrapper = Wrapper;
export default CheckboxWithStates;
