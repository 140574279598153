// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".iJwVSrqXmiRUH5acO2GE {\n    max-width: 130rem;\n    padding: 2rem;\n\n    text-align: center;\n}\n", "",{"version":3,"sources":["webpack://./app/containers/student-daily-juices-trial/styles.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,aAAa;;IAEb,kBAAkB;AACtB","sourcesContent":[".error {\n    max-width: 130rem;\n    padding: 2rem;\n\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": "iJwVSrqXmiRUH5acO2GE"
};
export default ___CSS_LOADER_EXPORT___;
