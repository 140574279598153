// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Mafewzg4ENw1aYt87XOY {}\n\n.DDVB7Iy1igcOoAQtVcIO {\n    margin-top: 2.5rem;\n\n    display: grid;\n}\n\n@media only screen and (min-width: 500px) { /* TODO: */\n    .lfZ5z6ZTHIqCEqhmYN6f {\n        grid-auto-flow: column;\n        justify-content: space-between;\n        align-items: center;\n    }\n}\n", "",{"version":3,"sources":["webpack://./base/forms/user-sign-up-trial-roles/styles.module.css"],"names":[],"mappings":"AAAA,uBAAO;;AAEP;IACI,kBAAkB;;IAElB,aAAa;AACjB;;AAEA,4CAA4C,UAAU;IAClD;QACI,sBAAsB;QACtB,8BAA8B;QAC9B,mBAAmB;IACvB;AACJ","sourcesContent":[".form {}\n\n.submitButtonBlock {\n    margin-top: 2.5rem;\n\n    display: grid;\n}\n\n@media only screen and (min-width: 500px) { /* TODO: */\n    .formLinksSection {\n        grid-auto-flow: column;\n        justify-content: space-between;\n        align-items: center;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "Mafewzg4ENw1aYt87XOY",
	"submitButtonBlock": "DDVB7Iy1igcOoAQtVcIO",
	"formLinksSection": "lfZ5z6ZTHIqCEqhmYN6f"
};
export default ___CSS_LOADER_EXPORT___;
