import React, { useState } from "react";

import PopupWindow from "juice-base/components/popup-window/index.js";

import ClassDetailsForm from "juice-base/forms/class-details/index.js";

import PopupConfirmDeleteClass from "juice-base/business/popup-confirm-delete-class/index.js";


const PopupClassDetails = (props) => {
    const [isVisibleConfirmDelete, setIsVisibleConfirmDelete] = useState(false);

    const onConfirmDeleteClass = () => {
        setIsVisibleConfirmDelete(true);
    };

    const onCloseConfirmDeleteClass = () => {
        setIsVisibleConfirmDelete(false);
    };

    const renderContent = () => {
        return (
            <ClassDetailsForm
                initialValues={props.initialValues}
                grades={props.grades}
                allowEditClassName={props.allowEditClassName}
                showDelete={props.allowDelete}
                onDeleteClass={onConfirmDeleteClass}
                onSubmit={props.onSubmit}
            />
        );
    };

    const renderConfirmDelete = () => {
        if (!isVisibleConfirmDelete) {
            return null;
        }

        return (
            <PopupConfirmDeleteClass
                className={props.initialValues.className}
                showDelete={props.allowDelete}
                onDelete={props.onDelete}
                onClose={onCloseConfirmDeleteClass}
            />
        );
    };

    return (
        <>
            {renderConfirmDelete()}

            <PopupWindow
                title="Class Details"
                onClose={props.onClose}
            >
                {renderContent()}
            </PopupWindow>
        </>
    );
};

PopupClassDetails.defaultProps = {
    initialValues: {},
    grades: [],

    allowEditClassName: true,
    allowDelete: false,

    onDelete: () => { },
    onSubmit: () => { },
    onClose: () => { },
};

export default PopupClassDetails;
