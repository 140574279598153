// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".psGJiEJGS7NWBjPhaGox {\n    display: grid;\n    grid-gap: 2rem;\n\n    max-width: 65rem;\n    width: 100%;\n\n    margin: auto;\n}\n", "",{"version":3,"sources":["webpack://./app/containers/assignments/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,cAAc;;IAEd,gBAAgB;IAChB,WAAW;;IAEX,YAAY;AAChB","sourcesContent":[".content {\n    display: grid;\n    grid-gap: 2rem;\n\n    max-width: 65rem;\n    width: 100%;\n\n    margin: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "psGJiEJGS7NWBjPhaGox"
};
export default ___CSS_LOADER_EXPORT___;
