import React from "react";

import Grades from "juice-base/project/grades.js";

import classNames from "juice-base/lib/class-names.js";

import styles from "./styles.module.css";


const GradesIndicator = ({ grades }) => {
    if (!grades) {
        return null;
    }

    const indicatorClassName = classNames({
        [styles.indicator]: true,
        [styles.indicatorG5G6]: Grades.isGradesG5G6(grades),
        [styles.indicatorG7G8]: Grades.isGradesG7G8(grades),
        [styles.indicatorG9G10]: Grades.isGradesG9G10(grades),
        [styles.indicatorG11G12]: Grades.isGradesG11G12(grades),
    });

    return (
        <div className={indicatorClassName} />
    );
};

GradesIndicator.defaultProps = {
    grades: "",
};

export default GradesIndicator;
