import React from "react";

import { Formik } from "formik";

import v from "juice-base/lib/form-validators.js";

import Input from "juice-base/components/forms/input/index.js";
import ButtonBig from "juice-base/components/button-big/index.js";
import AlertBox from "juice-base/components/alert-box/index.js";
import RequestLoader from "juice-base/components/request-loader/index.js";

import styles from "./styles.module.css";


const TeacherAddForm = (props) => {
    const getFirstNameError = (value) => {
        return v.validate(value, [
            v.required("Please enter a name"),
        ]);
    };

    const getLastNameError = (value) => {
        return v.validate(value, [
            v.required("Please enter a lastname"),
        ]);
    };

    const getEmailError = (value) => {
        return v.validate(value, [
            v.required("Please enter an email address"),
            v.email("Please enter a valid email"),
        ]);
    };

    /* ----- */

    const validateForm = (values) => {
        const firstNameError = getFirstNameError(values.firstName);
        const lastNameError = getLastNameError(values.lastName);
        const emailError = getEmailError(values.email);

        const errors = {};

        if (!props.isEmailOnly) {
            if (firstNameError) {
                errors.firstName = firstNameError;
            }

            if (lastNameError) {
                errors.lastName = lastNameError;
            }
        }

        if (emailError) {
            errors.email = emailError;
        }

        return errors;
    };

    /* ----- */

    const isAddedSuccessfully = () => {
        const { status } = props;

        if (status?.isVisible && status?.isSuccess) {
            return true;
        }

        return false;
    };

    const isDisabled = (errors, isSubmitting) => {
        if (Object.keys(errors).length > 0 || isSubmitting) {
            return true;
        }

        if (isAddedSuccessfully()) {
            return true;
        }

        return false;
    };

    /* ----- */

    const renderStatus = () => {
        const { status } = props;

        if (!status.isVisible) {
            return null;
        }

        const boxTheme = status.isSuccess
            ? "black-success"
            : "black-error";

        return (
            <div className={styles.alertBox}>
                <AlertBox
                    theme={boxTheme}
                >
                    {status.message}
                </AlertBox>
            </div>
        );
    };

    const renderSubmit = (errors, isSubmitting) => {
        let content = null;

        if (props.isLoading) {
            content = (
                <RequestLoader />
            );
        } else {
            content = (
                <ButtonBig
                    type="submit"
                    disabled={isDisabled(errors, isSubmitting)}
                    isRounded
                >
                    Add teacher
                </ButtonBig>
            );
        }

        return (
            <div className={styles.submitButton}>
                {content}
            </div>
        );
    };

    const renderForm = (formProps) => {
        const {
            values,
            errors,
            handleSubmit,
            handleChange,
            handleBlur,
            isSubmitting,
        } = formProps;

        const isAdded = isAddedSuccessfully();

        let details = null;

        if (!props.isEmailOnly) {
            details = (
                <div className={styles.formRow}>
                    <Input
                        name="firstName"
                        label="First Name *"
                        value={values.firstName}
                        error={errors.firstName}
                        onChange={handleChange}
                        isDisabled={isAdded}
                    />
                    <Input
                        name="lastName"
                        label="Last Name *"
                        value={values.lastName}
                        error={errors.lastName}
                        onChange={handleChange}
                        isDisabled={isAdded}
                    />
                </div>
            );
        }

        return (
            <form onSubmit={handleSubmit}>
                <div className={styles.formContent}>
                    {details}
                    <Input
                        name="email"
                        label="Email"
                        value={values.email}
                        error={errors.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isDisabled={isAdded || props.isEmailDisabled}
                    />
                </div>
                {renderStatus()}
                {renderSubmit(errors, isSubmitting)}
            </form>
        );
    };

    return (
        <Formik
            initialValues={props.initialValues}
            validate={validateForm}
            onSubmit={props.onSubmit}
        >
            {renderForm}
        </Formik>
    );
};

TeacherAddForm.defaultProps = {
    initialValues: {},
    status: {},

    onSubmit: () => {},

    isEmailDisabled: false,
    isEmailOnly: false,
    isLoading: false,
};

export default TeacherAddForm;
