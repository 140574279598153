import React from "react";

import Image from "juice-base/components/image/index.js";
import SectionStudent from "juice-base/business/section-student-v2/index.js";

import styles from "./styles.module.css";


const SectionSponsor = (props) => {
    const renderImage = () => {
        const {
            djHomeImage,
            djHomeUrl,
        } = props.sponsor;

        let img = null;

        if (djHomeImage) {
            const imageTitle = "Sponsor image";

            img = (
                <Image
                    className={styles.sponsorImg}
                    src={djHomeImage}
                    title={imageTitle}
                />
            );
        }

        if (djHomeUrl) {
            return (
                <a
                    href={djHomeUrl}
                    target="_blank"
                    rel="noopener"
                    className={styles.sponsorLink}
                    onClick={props.onSponsorClick}
                >
                    {img}
                </a>
            );
        }

        return img;
    };

    return (
        <SectionStudent
            dataComment="section-sponsor"
            titleBottomBorder={false}
        >
            <div className={styles.content}>
                {renderImage()}
            </div>
        </SectionStudent>
    );
};

SectionSponsor.defaultProps = {
    sponsor: null,
    onSponsorClick: () => { },
};

export default SectionSponsor;
