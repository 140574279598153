import React from "react";

import IconCheck from "juice-base/icons/check/index.js";
import IconClose from "juice-base/icons/close/index.js";
import IconText from "juice-base/icons/text/index.js";

import IconClickable from "juice-base/components/icon-clickable/index.js";

import styles from "./styles.module.css";


const TeacherQuizQuestion = (props) => {
    if (!props.quiz) {
        return null;
    }

    const renderStoryImage = () => {
        if (props.quiz?.story?.thumbnail) {
            return (
                <img
                    className={styles.headerImg}
                    src={props.quiz?.story?.thumbnail}
                    alt="Story Title"
                />
            );
        }

        return null;
    };

    const renderStoryIcon = () => {
        const storyId = props.quiz?.story?.id;

        if (!storyId) {
            return (
                <div className={styles.headerIcon} />
            );
        }

        return (
            <IconClickable
                className={styles.headerIcon}
                onClick={() => { props.onOpenStory(storyId); }}
            >
                <IconText
                    title="Quiz story"
                    isSky
                />
            </IconClickable>
        );
    };

    const renderCorrectIcon = () => {
        return (
            <IconCheck
                className={styles.answerIconImg}
                isGreen
            />
        );
    };

    const renderIncorrectIcon = () => {
        return (
            <IconClose
                className={styles.answerIconImg}
                isRed
            />
        );
    };

    const renderAnswers = () => {
        const answers = props.quiz?.answers || [];

        return answers.map((ans) => {
            let icon = null;

            if (ans.is_user_choice && ans.is_correct_answer) {
                icon = renderCorrectIcon();
            } else if (ans.is_user_choice && !ans.is_correct_answer) {
                icon = renderIncorrectIcon();
            } else if (!ans.is_user_choice && ans.is_correct_answer) {
                icon = renderCorrectIcon();
            }

            return (
                <div className={styles.answer}>
                    <div className={styles.answerIcon}>
                        {icon}
                    </div>
                    <div className={styles.answerText}>
                        {ans.answer}
                    </div>
                </div>
            );
        });
    };

    return (
        <div className={styles.quizQuestion}>
            <div className={styles.header}>
                <div className={styles.headerImage}>
                    {renderStoryImage()}
                </div>
                <div className={styles.headerTitle}>
                    Question
                </div>
                <div className={styles.headerQuestion}>
                    {props.quiz.question}
                </div>
                {renderStoryIcon()}
            </div>

            <div className={styles.answers}>
                {renderAnswers()}
            </div>
        </div>
    );
};

TeacherQuizQuestion.defaultProps = {
    quiz: null,
    onOpenStory: () => { },
};

export default TeacherQuizQuestion;
