import React, { useState, useRef, useMemo } from "react";
import { SingleDatePicker } from "react-dates";

import "react-dates/initialize.js";
import "react-dates/lib/css/_datepicker.css";
import { ICON_AFTER_POSITION } from "react-dates/src/constants.js";

import classNames from "juice-base/lib/class-names.js";
import date from "juice-base/lib/date.js";
import array from "juice-base/lib/array.js";
import dateMoment from "juice-base/lib/date-moment.js";

import useGlobalClose from "juice-base/hooks/use-global-close/index.js";

import IconCaret from "juice-base/icons/caret/index.js";

import "./styles.css";
import styles from "./styles.module.css";


const DatePickerSingle = (props) => {
    const picker = useRef(null);

    const [isFocused, setFocused] = useState(false);

    const availableDates = useMemo(() => {
        if (!props.availableDates || props.availableDates === "all") {
            return [];
        }

        return (props.availableDates || []).map((d) => {
            if (!d) {
                return "";
            }

            const aDate = date.getDateFromDate(d);

            if (aDate) {
                return aDate;
            }

            return "";
        }).filter(array.filterCallbackNonEmptyString);
    }, [props.availableDates]);


    /* ----- */

    const onClosePicker = () => {
        setFocused(false);
    };

    const onTogglePicker = () => {
        setFocused((prev) => !prev);
    };

    const onDateChange = (value) => {
        props.onChange(value);
        onClosePicker();
    };

    const onContainerClick = () => {
        if (props.disabled) {
            return;
        }

        onTogglePicker();
    };

    /* ----- */

    const isBlocked = (day) => {
        if (props.maxDate && date.newDate(day) > date.newDate(props.maxDate)) {
            const isDatesSame = date.isDatesSame(
                date.newDate(day),
                date.newDate(props.maxDate),
            );

            if (!isDatesSame) {
                return true;
            }
        }

        if (availableDates.length === 0) {
            return false;
        }

        const newDay = date.getDateFromDate(day);

        if (availableDates.indexOf(newDay) !== -1) {
            return false;
        }

        return true;
    };

    /* ----- */

    useGlobalClose((evt) => {
        if (picker?.current?.contains
            && picker.current.contains(evt.target)) {
            return;
        }

        setFocused(false);
    }, []);

    /* ----- */

    const renderDayContents = (d) => {
        const day = date.newDate(d).getDate();

        return (
            <div>{day}</div>
        );
    };

    const renderIcon = () => {
        const caretClassName = classNames({
            [styles.caret]: true,
            [styles.caretOpened]: isFocused,
        });

        return (
            <IconCaret
                className={caretClassName}
            />
        );
    };

    /* ----- */

    const datepickerClassName = classNames({
        [styles.datepickerContainer]: true,
        [styles.datepickerContainerWithBorder]: props.withBorder,
    });

    const dayPickerClassName = classNames({
        [styles.datePicker]: true,
        [styles.datePickerWithIcon]: props.icon,
        pickerInputLong: true,
    });

    return (
        <div
            className={datepickerClassName}
            ref={picker}
        >
            <div
                className={dayPickerClassName}
                data-comment={props.dataComment}
                onClick={onContainerClick}
                onKeyPress={onContainerClick}
                role="button"
                tabIndex="-1"
            >
                <div className={styles.icon}>
                    {props.icon}
                </div>
                <SingleDatePicker
                    date={dateMoment.dateFromString(props.date)}
                    displayFormat={props.displayFormat}
                    numberOfMonths={1}
                    inputIconPosition={ICON_AFTER_POSITION}
                    customInputIcon={renderIcon()}
                    renderDayContents={renderDayContents}
                    onDateChange={onDateChange}
                    onFocusChange={() => {}}
                    isOutsideRange={() => false}
                    isDayBlocked={isBlocked}
                    focused={isFocused}
                    disabled={props.disabled}
                    noBorder
                    readOnly
                    enableOutsideDays
                    showDefaultInputIcon
                />
            </div>
        </div>
    );
};

DatePickerSingle.defaultProps = {
    dataComment: "date-picker-single",
    availableDates: "all",
    displayFormat: "MMMM DD, YYYY",
    date: null,
    maxDate: null,
    icon: null,
    onChange: () => { },
    withBorder: false,
    disabled: false,
};

export default DatePickerSingle;
