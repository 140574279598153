import React, { useState, useEffect, useRef } from "react";

import classNames from "juice-base/lib/class-names.js";
import useElemDimensions from "juice-base/hooks/use-elem-dimensions/index.js";
import juiceScrollbarStyles from "juice-base/components/juice-scrollbar/styles.module.css";


const WithScroll = (props) => {
    // TODO: avoid conditions with hooks
    const scrollRef = props.scrollRef || useRef(null); // eslint-disable-line react-hooks/rules-of-hooks, max-len

    const [overflow, setOverflow] = useState("auto");

    const dimensions = useElemDimensions(scrollRef.current);

    useEffect(() => {
        if (scrollRef.current) {
            const { clientHeight, scrollHeight } = scrollRef.current;
            const isScrollable = clientHeight < scrollHeight;
            const newValue = isScrollable ? "scroll" : "auto";

            if (overflow !== newValue) {
                setOverflow(newValue);
            }
        }
    }, [
        scrollRef.current,
        dimensions.height,
        dimensions.width,
    ]);

    const style = {
        ...props.style,
    };

    if (!props.style?.overflow) {
        style.overflowY = overflow;
    }

    const divClassName = classNames({
        [juiceScrollbarStyles.scrollbar]: true,
        [props.className]: props.className,
    });

    return (
        <div
            ref={scrollRef}
            style={style}
            className={divClassName}
        >
            {props.children}
        </div>
    );
};

WithScroll.defaultProps = {
    scrollRef: null,
    className: "",
    style: {},
    children: null,
};

export default WithScroll;
