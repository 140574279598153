// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".xRNbaGb3Poap4XAcBXVq {\n    position: relative;\n    background-color: var(--tooltip-bg-color);\n    border-radius: 0.3rem;\n}\n\n.QyEyF8zsoqU1yMH8fNpZ {\n    position: absolute;\n    bottom: -0.5rem;\n    left: 50%;\n    transform: translateX(-50%);\n\n    width: 0;\n    height: 0;\n\n    border-left: 0.5rem solid transparent;\n    border-right: 0.5rem solid transparent;\n\n    border-top: 0.5rem solid var(--tooltip-bg-color);\n}\n\n.eHk4azNH01fKDfoSV17T {\n    display: grid;\n    justify-content: center;\n    align-items: center;\n\n    height: 100%;\n    padding: 0.5rem 0;\n\n    color: var(--tooltip-color);\n}\n", "",{"version":3,"sources":["webpack://./base/components/chart-tooltip/styles.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,yCAAyC;IACzC,qBAAqB;AACzB;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,SAAS;IACT,2BAA2B;;IAE3B,QAAQ;IACR,SAAS;;IAET,qCAAqC;IACrC,sCAAsC;;IAEtC,gDAAgD;AACpD;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;;IAEnB,YAAY;IACZ,iBAAiB;;IAEjB,2BAA2B;AAC/B","sourcesContent":[".tooltip {\n    position: relative;\n    background-color: var(--tooltip-bg-color);\n    border-radius: 0.3rem;\n}\n\n.tooltipArrow {\n    position: absolute;\n    bottom: -0.5rem;\n    left: 50%;\n    transform: translateX(-50%);\n\n    width: 0;\n    height: 0;\n\n    border-left: 0.5rem solid transparent;\n    border-right: 0.5rem solid transparent;\n\n    border-top: 0.5rem solid var(--tooltip-bg-color);\n}\n\n.tooltipValue {\n    display: grid;\n    justify-content: center;\n    align-items: center;\n\n    height: 100%;\n    padding: 0.5rem 0;\n\n    color: var(--tooltip-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip": "xRNbaGb3Poap4XAcBXVq",
	"tooltipArrow": "QyEyF8zsoqU1yMH8fNpZ",
	"tooltipValue": "eHk4azNH01fKDfoSV17T"
};
export default ___CSS_LOADER_EXPORT___;
