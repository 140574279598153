import React, { useState } from "react";

import IconArrowBold from "juice-base/icons/arrow-bold/index.js";
import IconNotebook from "juice-base/icons/notebook/index.js";
import IconPlayInCircle from "juice-base/icons/play-in-circle/index.js";
import IconPlayerHeadphone from "juice-base/icons/player-headphone/index.js";
import IconOrange from "juice-base/icons/orange/index.js";

import IconClickable from "juice-base/components/icon-clickable/index.js";
import PopupWindow from "juice-base/components/popup-window/index.js";
import RequestLoader from "juice-base/components/request-loader/index.js";
import Video from "juice-base/components/video/index.js";
import JuiceContent from "juice-base/components/juice-content/index.js";
import PlayerAudio from "juice-base/components/player-audio/index.js";
import ButtonCircle from "juice-base/components/button-circle/index.js";
import Tabs from "juice-base/components/tabs/index.js";
import ButtonFlat from "juice-base/components/button-flat/index.js";

import styles from "./styles.module.css";


const PopupDailyJuiceExplore = (props) => {
    const [isPlayerExpanded, setIsPlayerExpanded] = useState(false);

    const [selectedPage, setSelectedPage] = useState(() => {
        if (props.defaultTab) {
            return props.defaultTab;
        }

        return "extraJuice";
    });

    /* ------ */

    const renderPopupTitle = () => {
        if (props.selectedExtraJuice?.id) {
            return (
                <div className={styles.headerExtraJuice}>
                    <IconOrange
                        className={styles.headerExtraJuiceIcon}
                        title="Orange"
                    />

                    <div className={styles.headerExtraJuiceText}>
                        {props.selectedExtraJuice.title}
                    </div>
                </div>
            );
        }

        if (props.selectedVideo?.id) {
            return props.selectedVideo.title;
        }

        return "Explore";
    };

    const renderBackArrow = () => {
        if (props.selectedExtraJuice?.id || props.selectedVideo?.id) {
            return (
                <ButtonCircle
                    icon={<IconArrowBold />}
                    onClick={props.onBackToList}
                    isImageBackwards
                />
            );
        }

        return null;
    };

    const renderRow = (params) => {
        let image = (
            <div className={styles.rowFeatured} />
        );

        if (params.imageSrc) {
            image = (
                <img
                    className={styles.rowFeatured}
                    src={params.imageSrc}
                    alt="Featured"
                />
            );
        } else if (params.imageDefault) {
            image = params.imageDefault;
        }

        const icon = params.icon
            ? params.icon
            : <div />;

        return (
            <div
                className={styles.row}
                role="button"
                tabIndex="-1"
                onClick={params.onClick}
                onKeyPress={params.onClick}
            >
                {image}

                <div className={styles.rowTitleWithIcon}>
                    <div className={styles.rowTitle}>
                        {params.title}
                    </div>
                    {icon}
                </div>
            </div>
        );
    };

    const renderLoadMoreButton = (isLoading, showButton, onClick) => {
        if (isLoading) {
            return (
                <RequestLoader />
            );
        }

        if (!showButton) {
            return null;
        }

        return (
            <div className={styles.loadMoreButton}>
                <ButtonFlat
                    hugePadding
                    onClick={onClick}
                >
                    Load More
                </ButtonFlat>
            </div>
        );
    };

    const renderExtraJuicePage = () => {
        const extraJuices = props.extraJuices.map((ej) => {
            const iconDefault = (
                <IconOrange
                    className={styles.rowFeatured}
                    title="Featured"
                />
            );

            return renderRow({
                imageSrc: ej.image,
                imageDefault: iconDefault,
                title: ej.title,
                icon: <IconNotebook title="Read" isOrange />,
                onClick: () => {
                    props.onExtraJuiceClick(ej.id);
                },
            });
        });

        return (
            <div className={styles.explorePage}>
                {extraJuices}
                {renderLoadMoreButton(
                    props.isExtraJuicesLoading,
                    props.showLoadMoreExtraJuices,
                    props.onLoadMoreExtraJuices,
                )}
            </div>
        );
    };

    const renderVideoPage = () => {
        const videos = props.videos.map((video) => {
            let featured = null;

            if (video?.featuredImage?.sizes?.thumbnail?.src) {
                featured = video.featuredImage.sizes.thumbnail.src;
            }

            return (renderRow({
                imageSrc: featured,
                title: video.newsTitle,
                icon: <IconPlayInCircle title="Play" isOrange />,
                onClick: () => {
                    props.onVideoClick(video.videoID);
                },
            }));
        });

        return (
            <div className={styles.explorePage}>
                {videos}
                {renderLoadMoreButton(
                    props.isVideosLoading,
                    props.showLoadMoreVideos,
                    props.onLoadMoreVideos,
                )}
            </div>
        );
    };

    const renderPage = () => {
        if (selectedPage === "extraJuice") {
            return renderExtraJuicePage();
        }

        if (selectedPage === "videos") {
            return renderVideoPage();
        }


        return null;
    };

    const renderTabs = () => {
        const tabs = [
            { value: "extraJuice", label: "Extra Juice" },
            { value: "videos", label: "Videos" },
        ];

        return (
            <div className={styles.tabs}>
                <Tabs
                    tabs={tabs}
                    selectedTab={selectedPage}
                    onChange={setSelectedPage}
                />
            </div>
        );
    };

    const renderPlayerButton = () => {
        if (!props.selectedExtraJuice?.id) {
            return null;
        }

        return (
            <IconClickable
                onClick={() => {
                    if (!props.audio) {
                        props.onAudioPlay();
                    }
                }}
            >
                <IconPlayerHeadphone
                    title="Play"
                    isOrange
                />
            </IconClickable>
        );
    };

    const renderPlayer = () => {
        if (!props.audio || !props.selectedExtraJuice) {
            return null;
        }

        let playerImgSrc = null;

        if (props.selectedExtraJuice?.image) {
            playerImgSrc = props.selectedExtraJuice.image;
        }

        return (
            <PlayerAudio
                image={playerImgSrc}
                title={props.selectedExtraJuice.title}
                audio={props.audio}
                isExpanded={isPlayerExpanded}
                onFirstPlay={props.onFirstPlay}
                onPlay={props.onPlay}
                onPause={props.onPause}
                onRewind={props.onRewind}
                onForward={props.onForward}
                onChangeRate={props.onChangeRate}
                roundedPlayer
                onExpand={() => {
                    setIsPlayerExpanded((prev) => !prev);
                }}
                onClose={props.onPlayerClose}
            />
        );
    };

    const renderContent = () => {
        if (props.selectedExtraJuice?.id) {
            return (
                <div className={styles.popupContent}>
                    <JuiceContent
                        storyId={props.selectedExtraJuice.id}
                        content={props.selectedExtraJuice.content}
                        onWordClick={props.onWordClick}
                    />
                </div>
            );
        }

        if (props.selectedVideo?.id) {
            if (props.selectedVideo.isVideoLoading) {
                return (
                    <div className={styles.popupContent}>
                        <RequestLoader />
                    </div>
                );
            }

            return (
                <div className={styles.popupContent}>
                    <Video
                        videoId={props.selectedVideo.id}
                        captionSrc={props.selectedVideo.captionSrc}
                        src={props.selectedVideo.videoUrl}
                        poster={props.selectedVideo.poster}
                        type={props.selectedVideo.mimeType}
                        videoHeight={410}
                        withPosterCover={false}
                        isDefault={props.isDefaultVideo}
                    />
                </div>
            );
        }

        return (
            <div className={styles.content}>
                {renderTabs()}
                {renderPage()}
            </div>
        );
    };

    return (
        <PopupWindow
            title={renderPopupTitle()}
            leftControl={renderBackArrow()}
            rightControl={renderPlayerButton()}
            player={renderPlayer()}
            playerExpanded={isPlayerExpanded}
            withTitleBarShadow={false}
            onClose={props.onClose}
        >
            {renderContent()}
        </PopupWindow>
    );
};

PopupDailyJuiceExplore.defaultProps = {
    audio: null,

    extraJuices: [],
    videos: [],
    isDefaultVideo: true,

    selectedExtraJuice: {},
    selectedVideo: {},

    showLoadMoreExtraJuices: false,
    isExtraJuicesLoading: false,

    showLoadMoreVideos: false,
    isVideosLoading: false,

    defaultTab: "extraJuice",

    onBackToList: () => { },

    onVideoClick: () => { },
    onExtraJuiceClick: () => { },

    onLoadMoreVideos: () => { },
    onLoadMoreExtraJuices: () => { },

    onWordClick: () => { },

    onAudioPlay: () => { },
    onFirstPlay: () => { },
    onPlay: () => { },
    onPause: () => { },
    onRewind: () => { },
    onForward: () => { },
    onChangeRate: () => { },
    onPlayerClose: () => { },

    onClose: () => { },
};

export default PopupDailyJuiceExplore;
