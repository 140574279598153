// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".d0wAhQP_TKNQg2_oAjbz {\n    display: grid;\n}\n\n.RC_dtIFlNGdETrSdpGHs {\n    overflow: hidden;\n\n    padding: 2rem;\n\n    color: var(--input-search-color);\n    font-size: 2rem;\n    white-space: nowrap;\n    text-overflow: ellipsis;\n\n    background-color: transparent;\n\n    border: none;\n    outline: none;\n}\n\n.RC_dtIFlNGdETrSdpGHs::placeholder {\n    color: var(--input-search-color);\n    font-size: 1.5rem;\n}\n", "",{"version":3,"sources":["webpack://./base/components/forms/input-search/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,gBAAgB;;IAEhB,aAAa;;IAEb,gCAAgC;IAChC,eAAe;IACf,mBAAmB;IACnB,uBAAuB;;IAEvB,6BAA6B;;IAE7B,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,gCAAgC;IAChC,iBAAiB;AACrB","sourcesContent":[".fieldInput {\n    display: grid;\n}\n\n.input {\n    overflow: hidden;\n\n    padding: 2rem;\n\n    color: var(--input-search-color);\n    font-size: 2rem;\n    white-space: nowrap;\n    text-overflow: ellipsis;\n\n    background-color: transparent;\n\n    border: none;\n    outline: none;\n}\n\n.input::placeholder {\n    color: var(--input-search-color);\n    font-size: 1.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fieldInput": "d0wAhQP_TKNQg2_oAjbz",
	"input": "RC_dtIFlNGdETrSdpGHs"
};
export default ___CSS_LOADER_EXPORT___;
