import React, { useRef, useEffect } from "react";

import AudioManager from "juice-base/lib/audio-manager/index.js";
import AudioManagerContext from "juice-base/context/audio-manager/index.js";


const AudioProvider = (props) => {
    const { store, api, actions } = props;

    const am = useRef(null);

    /* --- */

    const getSession = () => {
        return store.getState()?.user?.session || "";
    };

    const getT2SState = () => {
        const state = store.getState();

        return {
            juiceStories: state.text2speech.juiceStories,
            extraJuices: state.text2speech.extraJuices,
            words: state.text2speech.words,
        };
    };

    const getT2SStory = (storyId) => {
        return store.getState()?.text2speech?.juiceStories[storyId];
    };

    const getT2SExtraJuice = (eJuiceId) => {
        return store.getState()?.text2speech?.extraJuices[eJuiceId];
    };

    /* --- */

    useEffect(() => {
        am.current = new AudioManager((audioState) => {
            store.dispatch(actions.player.setPlayerState({
                playerState: audioState,
            }));
        });
    }, []);

    const value = useRef({
        loadWord: async function loadWord(text) {
            am.current.pauseAll();

            props.store.dispatch(actions.t2s.setWordLoading({
                text,
            }));

            am.current.setFiles(getT2SState());

            const res = await api.t2s.vocabulary({
                session: getSession(),
                text,
            });

            if (!res.ok) {
                store.dispatch(actions.t2s.clearWord({
                    text,
                }));

                am.current.setFiles(getT2SState());
                return;
            }

            store.dispatch(actions.t2s.setWord({
                text,
                audioFiles: [res] || [],
            }));

            am.current.setPlayNext("words", text);
            am.current.setFiles(getT2SState());
        },

        loadStory: async function loadStory(storyId) {
            const story = getT2SStory(storyId);

            if (story) {
                am.current.play("juiceStories", storyId);
                return;
            }

            store.dispatch(actions.t2s.setJuiceStoryLoading({
                storyId,
            }));

            am.current.setFiles(getT2SState());

            const juiceId = null;

            const res = await api.t2s.juice({
                juiceId,
                storyId,
            });

            if (!res.ok) {
                store.dispatch(actions.t2s.clearJuiceStory({
                    storyId,
                }));

                am.current.setFiles(getT2SState());
                return;
            }

            store.dispatch(actions.t2s.setJuiceStory({
                juiceId,
                storyId,
                audioFiles: res.audioFiles,
            }));

            am.current.setPlayNext("juiceStories", storyId);
            am.current.setFiles(getT2SState());
        },

        loadExtraJuice: async function loadExtraJuice(extraJuiceId) {
            const eJuice = getT2SExtraJuice(extraJuiceId);

            if (eJuice) {
                am.current.play("extraJuices", extraJuiceId);
                return;
            }

            store.dispatch(actions.t2s.setExtraJuiceLoading({
                extraJuiceId,
            }));

            am.current.setFiles(getT2SState());

            const res = await api.t2s.juice({
                juiceId: null,
                extraJuiceId,
            });

            if (!res.ok) {
                store.dispatch(actions.t2s.clearExtraJuice({
                    extraJuiceId,
                }));

                am.current.setFiles(getT2SState());
                return;
            }

            store.dispatch(actions.t2s.setExtraJuice({
                juiceId: null,
                extraJuiceId,
                audioFiles: res.audioFiles,
            }));

            am.current.setPlayNext("extraJuices", extraJuiceId);
            am.current.setFiles(getT2SState());
        },

        load: function load(trackGroupName, trackId) {
            if (trackGroupName === "words") {
                this.loadWord(trackId); // eslint-disable-line react/no-this-in-sfc
            } else if (trackGroupName === "juiceStories") {
                this.loadStory(trackId); // eslint-disable-line react/no-this-in-sfc
            } else if (trackGroupName === "extraJuices") {
                this.loadExtraJuice(trackId); // eslint-disable-line react/no-this-in-sfc
            }
        },

        play: function play(trackGroupName, trackId) {
            if (am.current) {
                if (!am.current.hasFile(trackGroupName, trackId)) {
                    this.load(trackGroupName, trackId); // eslint-disable-line react/no-this-in-sfc
                    return;
                }

                am.current.play(trackGroupName, trackId);
            }
        },

        pause: function pause(trackGroupName, trackId) {
            if (am.current) {
                am.current.pause(trackGroupName, trackId);
            }
        },

        pauseAll: function pauseAll() {
            if (am.current) {
                am.current.pauseAll();
            }
        },

        stop: function stop(trackGroupName, trackId) {
            if (am.current) {
                am.current.stop(trackGroupName, trackId);
            }
        },

        stopAll: function stopAll() {
            if (am.current) {
                am.current.stopAll();
            }
        },

        stopAllTracks: function stopAllTracks(trackGroupName, trackId) {
            if (am.current) {
                am.current.stopAllTracks(trackGroupName, trackId);
            }
        },

        rewind: function rewind(trackGroupName, trackId) {
            if (am.current) {
                am.current.rewind(trackGroupName, trackId);
            }
        },

        forward: function forward(trackGroupName, trackId) {
            if (am.current) {
                am.current.forward(trackGroupName, trackId);
            }
        },

        changeRate: function changeRate(trackGroupName, trackId, rate) {
            if (am.current) {
                am.current.setPlaybackRate(trackGroupName, trackId, rate);
            }
        },
    });

    return (
        <AudioManagerContext.Provider value={value.current}>
            {props.children}
        </AudioManagerContext.Provider>
    );
};

AudioProvider.defaultProps = {
    store: {},
    actions: {},
    api: {},
    children: null,
};

export default AudioProvider;
