import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import Students from "juice-base/project/students.js";
import Grades from "juice-base/project/grades.js";
import Classes from "juice-base/project/classes.js";
import DailyJuice from "juice-base/project/daily-juice.js";

import array from "juice-base/lib/array.js";
import date from "juice-base/lib/date.js";
import urls from "juice-base/lib/urls.js";
import storage from "juice-base/lib/storage/index.js";

import actionsSite from "juice-base/actions/site.js";
import actionsTeacher from "juice-base/actions/teacher.js";
import actionsTeacherClassDailyJuices from "juice-base/actions/teacher-class-daily-juices.js";
import actionsTeacherClassAssignments from "juice-base/actions/teacher-class-assignments.js";
import actions from "juice-base/store/actions.js";
import text from "juice-base/text/index.js";

import useSnackbar from "juice-base/hooks/use-snackbar/index.js";
import useAddClassPopup from "juice-base/hooks/use-add-class-popup/index.js";
import useEditClassPopup from "juice-base/hooks/use-edit-class-popup/index.js";

import RequestLoader from "juice-base/components/request-loader/index.js";
import Snackbar from "juice-base/components/snackbar/index.js";
import PopupConfirmError from "juice-base/components/popup-confirm-error/index.js";
import LayoutContent from "juice-base/components/layout-content/index.js";

import PopupClassAdd from "juice-base/business/popup-class-add/index.js";
import PopupClassDetails from "juice-base/business/popup-class-details/index.js";
import TeacherClassTableLegend from "juice-base/business/teacher-class-table-legend/index.js";
import TeacherClassTableV2, {
    useTeacherClassTable,
} from "juice-base/business/teacher-class-table-v2/index.js";
import ClassTeachersTable, { useClassTeacherTable } from "juice-base/business/class-teachers-table/index.js";
import TeacherClassControls from "juice-base/business/teacher-class-controls/index.js";

import UserFooter from "juice-app/containers/user-footer/index.js";
import TeacherPopupLMS from "juice-app/containers/teacher-popup-lms/index.js";
import TeacherPopupStudentsRemove from "juice-app/containers/teacher-popup-students-remove/index.js";
import PopupFullScreenAddStudentV2 from "juice-app/containers/popup-full-screen-add-student-v2/index.js";
import PopupAddTeacher, { usePopupAddTeacher } from "juice-app/containers/popup-add-teacher/index.js";
import PopupConfirmDeleteTeacher, {
    usePopupConfirmDeleteTeacher,
} from "juice-base/business/popup-confirm-delete-teacher/index.js";

import settings from "juice-app/settings.js";
import api from "juice-app/api.js";
import events from "juice-app/events.js";

import styles from "./styles.module.css";


const storeSelector = (state) => ({
    session: state.user.session,
    user: state.user.user,

    dimensions: state.device.dimensions,
    urlParams: state.navigation.params,

    studentsNotifications: state.notifications.studentsNotifications,
    teacher: state.teacher,
    juices: state.juices,

    info: state.info,
    siteDate: state.info.siteDate,
});

const getDefaultDates = (siteDate) => {
    const currWeekdays = date.getWeekDaysByDate(siteDate);

    const dateFrom = date.getDateFromDate(currWeekdays[0]);
    const dateTo = date.getDateFromDate(currWeekdays[currWeekdays.length - 1]);

    return {
        dateFrom,
        dateTo,
        assignmentDate: date.getDateFromDate(siteDate),
    };
};

const LOAD_TYPES = {
    before: "before",
    after: "after",
};

const ClassV3 = () => {
    const [isVisibleAddStudentsPopup, setIsVisibleAddStudentsPopup] = useState(false);
    const [isLMSPopupVisible, setIsLMSProviderVisible] = useState(false);

    const [removeStudentsState, setRemoveStudentsState] = useState({
        isVisiblePopup: false,
        students: [],
    });

    const [usedValues, setUsedValues] = useState({
        dateFrom: null,
        dateTo: null,
        assignmentDate: null,
        loadType: LOAD_TYPES.before,
    });

    /* --- */

    const dispatch = useDispatch();
    const store = useSelector(storeSelector);

    const navigate = useNavigate();

    /* --- */

    const addClassPopup = useAddClassPopup();
    const editClassPopup = useEditClassPopup();
    const snackbar = useSnackbar();
    const classTeachersTable = useClassTeacherTable();
    const teacherClassTable = useTeacherClassTable();
    const popupAddTeacher = usePopupAddTeacher();
    const popupConfirmDeleteTeacher = usePopupConfirmDeleteTeacher();

    /* --- */

    const isLMS = settings.features.LMS && store.user.isLmsUser;

    const isMobile1420 = store.dimensions.width < 1420;
    const isMobile900 = store.dimensions.width < 900;
    const isMobile500 = store.dimensions.width < 500;

    const hasDateRange = useMemo(() => {
        const { view, dateFrom, dateToOrLoadType } = store.urlParams;

        if (!view) {
            return false;
        }

        if (DailyJuice.isDailyJuiceView(view)) {
            return dateFrom && dateToOrLoadType;
        }

        if (DailyJuice.isAssignmentsView(view) && dateFrom) {
            return true;
        }

        return false;
    }, [
        store.urlParams.view,
        store.urlParams.dateFrom,
        store.urlParams.dateToOrLoadType,
    ]);

    /* --- */

    const getClassStudentsScores = (classId, dates) => {
        const range = `${dates.dateFrom}-${dates.dateToOrLoadType}`;

        if (DailyJuice.isDailyJuiceView(store.urlParams.view)) {
            return store.teacher.classesScoresByDailyJuices?.[classId]?.[range] || {};
        }

        return store.teacher.classesScoresByAssignments?.[classId]?.[range] || {};
    };

    /* --- */

    const navigateRange = (values) => {
        const { dateFrom, dateTo, loadType } = values;

        if (DailyJuice.isDailyJuiceView(store.urlParams.view)) {
            navigate(`/class/${dateFrom}/${dateTo}/${store.urlParams.view}`);
            return;
        }

        navigate(`/class/${dateFrom}/${loadType}/${store.urlParams.view}`);
    };

    const navigateView = (view) => {
        const {
            dateFrom,
            dateTo,
            assignmentDate,
            loadType,
        } = usedValues;

        const defaultDates = getDefaultDates(store.siteDate);

        if (DailyJuice.isDailyJuiceView(view)) {
            if (!dateFrom || !dateTo) {
                navigate(`/class/${defaultDates.dateFrom}/${defaultDates.dateTo}/${view}`);
                return;
            }

            navigate(`/class/${dateFrom}/${dateTo}/${view}`);
            return;
        }

        if (!assignmentDate) {
            navigate(`/class/${defaultDates.assignmentDate}/${loadType}/${view}`);
            return;
        }

        navigate(`/class/${assignmentDate}/${loadType}/${view}`);
    };

    const navigateToStudentInfo = (studentId) => {
        events.classes.openStudentInfo({
            session: store.session,
            classId: store.teacher.selectedClassId,
        });

        const {
            dateFrom,
            dateToOrLoadType,
            view,
        } = store.urlParams;

        const overviewPath = `${studentId}/student-overview`;

        navigate(`/class/${dateFrom}/${dateToOrLoadType}/${view}/${overviewPath}`);
    };

    /* --- */

    const loadClassTeachers = (classId) => {
        dispatch(actionsTeacher.loadClassTeachers({ api, actions }, {
            session: store.session,
            classId,
        }));
    };

    const onLoadTeacherClasses = (params = {}) => {
        dispatch(actionsTeacher.loadAllClasses(
            { api, actions },
            {
                session: store.session,
                onFinish: (classes) => {
                    if (params.onFinish) {
                        params.onFinish(classes);
                    }
                },
            },
        ));
    };

    /* --- */

    const setTeacherSelectedClass = (classId) => {
        dispatch(actions.teacher.setTeacherSelectedClass({
            selectedClassId: classId,
        }));

        storage.local.saveTeacherSelectedClass(classId);
    };

    const onClassChange = (classId) => {
        if (!classId) {
            return;
        }

        dispatch(actionsTeacher.loadClassNotifications({ api, actions }, {
            session: store.session,
            classId,
        }));

        loadClassTeachers(classId);

        events.classes.changeClass({
            session: store.session,
            classId,
        });

        setTeacherSelectedClass(classId);
    };

    /* --- */

    const reloadTeacherClasses = async () => {
        dispatch(actionsTeacher.loadClasses(
            { api, storage, actions },
            {
                session: store.session,
                onFinish: (classes) => {
                    const lastClassId = array.last(classes).id;

                    if (lastClassId) {
                        onClassChange(lastClassId);
                    }
                },
            },
        ));
    };

    const loadScoresByView = (params) => {
        const { classId, forceUpdate } = params;

        if (DailyJuice.isDailyJuiceView(params.view)) {
            dispatch(actionsTeacher.loadClassDailyJuicesScores(
                { api, actions },
                { classId, forceUpdate },
            ));
            return;
        }

        dispatch(actionsTeacher.loadClassAssignmentsScores(
            { api, actions },
            { classId, forceUpdate },
        ));
    };

    const loadOrReloadScoresByView = (params) => {
        if (DailyJuice.isDailyJuiceView(params.view)) {
            setUsedValues((prev) => ({
                ...prev,
                dateFrom: store.urlParams.dateFrom,
                dateTo: store.urlParams.dateToOrLoadType,
            }));

            dispatch(actionsTeacherClassDailyJuices.loadOrReloadDailyJuicesScores({
                api,
                actions,
            }));
            return;
        }

        setUsedValues((prev) => ({
            ...prev,
            assignmentDate: store.urlParams.dateFrom,
            loadType: store.urlParams.dateToOrLoadType,
        }));

        dispatch(actionsTeacherClassAssignments.loadOrReloadAssignmentsScores({
            api,
            actions,
        }));
    };

    /* --- */

    const onAddClass = async (values, { setSubmitting, setErrors }) => {
        const res = await api.classes.addClass({
            session: store.session,
            className: values.className,
            grade: values.grades?.value,
        });

        if (!res.ok) {
            setErrors({
                formError: res.error || text.error,
            });

            setSubmitting(false);
            return;
        }

        addClassPopup.close();

        reloadTeacherClasses();

        setSubmitting(false);
    };

    const onOpenAddClassPopup = () => {
        addClassPopup.open();
    };

    const onCloseAddClassPopup = () => {
        addClassPopup.close();
    };

    /* --- */

    const onDeleteClass = async (classId, className) => {
        const res = await api.classes.deleteClass({
            session: store.session,
            classId,
        });

        if (res.ok) {
            snackbar.add({
                autoCloseInSeconds: 3,
                message: `"${className}" successfully deleted.`,
            });

            onLoadTeacherClasses({
                onFinish: (classes) => {
                    const firstClassId = classes?.[0]?.id;

                    if (firstClassId) {
                        onClassChange(firstClassId);
                    }

                    editClassPopup.setIsDeleting(false);
                },
            });
            return;
        }

        editClassPopup.setDeletingError(res.error || "Error!");
    };

    /* --- */

    const onDeleteTeacher = async (id) => {
        popupConfirmDeleteTeacher.setLoadingById(id);

        const res = await api.classes.deleteTeacherFromClass({
            session: store.session,
            teacherId: id,
            classId: store.teacher.selectedClassId,
        });

        if (!res.ok) {
            const error = res.error || text.error;

            popupConfirmDeleteTeacher.setErrorById(id, error);
            return;
        }

        popupConfirmDeleteTeacher.setSuccessDeletedById(id);
    };

    /* --- */

    const onChangeClassDetails = async (values, classId, formParams) => {
        const res = await api.classes.updateClass({
            session: store.session,
            classId,
            className: values.className,
            grade: values.grades?.value,
        });

        if (res.ok) {
            editClassPopup.close();

            onLoadTeacherClasses();

            loadScoresByView({
                ...store.urlParams,
                classId,
            });
        } else {
            formParams.setErrors({
                formError: res.error || text.error,
            });
        }

        formParams.setSubmitting(false);
    };

    /* --- */

    const exportAssignments = (dateFrom, dateTo) => {
        const exportUrl = api.export.getClassAssignmentsScoresByRange({
            session: store.session,
            classId: store.teacher.selectedClassId,
            sortBy: Students.findSortKeyByValue(teacherClassTable.state.sortBy),
            dateFrom,
            dateTo,
        });

        urls.universalDownload(exportUrl);
    };

    const exportSelectedWeekAssignments = () => {
        const data = getClassStudentsScores(store.teacher.selectedClassId, store.urlParams);
        const dates = data?.data?.dates || [];

        let dateFrom = array.head(dates);
        let dateTo = array.last(dates);

        if (dateFrom?.date) {
            dateFrom = dateFrom.date;
        }

        if (dateTo?.date) {
            dateTo = dateTo.date;
        }

        exportAssignments(dateFrom, dateTo);
    };

    const exportDailyJuices = (dateFrom, dateTo) => {
        const exportUrl = api.export.getClassScoresByRange({
            session: store.session,
            classId: store.teacher.selectedClassId,
            sortBy: Students.findSortKeyByValue(teacherClassTable.state.sortBy),
            dateFrom,
            dateTo,
        });

        urls.universalDownload(exportUrl);
    };

    const exportSelectedWeekDailyJuices = () => {
        const dateFrom = date.getDateFromDate(date.newDateUTC(store.urlParams.dateFrom));
        const dateTo = date.getDateFromDate(date.newDateUTC(store.urlParams.dateToOrLoadType));

        exportDailyJuices(dateFrom, dateTo);
    };

    const onExportSelectedWeek = () => {
        events.teacher.teacherExportClassWeekScores({
            session: store.session,
            classId: store.teacher.selectedClassId,
        });

        if (DailyJuice.isDailyJuiceView(store.urlParams.view)) {
            exportSelectedWeekDailyJuices();
            return;
        }

        exportSelectedWeekAssignments();
    };

    const onExportAllScores = () => {
        events.teacher.teacherExportClassScores({
            session: store.session,
            classId: store.teacher.selectedClassId,
        });

        if (DailyJuice.isDailyJuiceView(store.urlParams.view)) {
            exportDailyJuices("", "");
            return;
        }

        exportAssignments("", "");
    };

    /* ------- */

    const onLoadClassScoresWithClear = (classId) => {
        dispatch(actions.teacher.clearTeacherClassScoresByDailyJuices());
        dispatch(actions.teacher.clearTeacherClassScoresByAssignments());

        loadScoresByView({
            ...store.urlParams,
            forceUpdate: true,
            classId,
        });
    };

    const onClosePopupStudentsRemove = () => {
        setRemoveStudentsState({
            isVisiblePopup: false,
            students: [],
        });
    };

    const onOpenRemoveStudentsPopup = (students) => {
        if (students.length === 0) {
            return;
        }

        setRemoveStudentsState({
            isVisiblePopup: true,
            students,
        });
    };

    const onEditClass = (classId) => {
        editClassPopup.open(classId);
    };

    const onCloseEditClassPopup = () => {
        editClassPopup.close();
    };

    const onOpenAddStudentPopup = () => {
        setIsVisibleAddStudentsPopup(true);
    };

    const onDeleteClassClick = (classId, className) => {
        editClassPopup.setIsDeleting(true);

        snackbar.add({
            isVisibleUndoButton: true,
            id: classId,
            message: `"${className}" will be deleted from your account.`,
            onCloseWithUndo: () => {
                editClassPopup.setIsDeleting(false);
            },
            onClose: () => {
                onDeleteClass(classId, className);
            },
        });
    };

    const onCloseSnackbar = (params = {}) => {
        snackbar.close(params.index);

        if (params.withUndo) {
            params.onCloseWithUndo();
            return;
        }

        params.onClose();
    };

    /* --- */

    const onClosePopupConfirmDeleteTeacher = (teacherId) => {
        popupConfirmDeleteTeacher.closeId(
            { teacherId },
            {
                onLastClosed: () => {
                    const { statusById, classId } = popupConfirmDeleteTeacher.state;

                    const statusKeys = Object.keys(statusById);

                    let hasSuccessDeletes = false;

                    for (let i = 0; i < statusKeys.length; i += 1) {
                        if (statusById[statusKeys[i]].isSuccessfullyDeleted) {
                            hasSuccessDeletes = true;
                            break;
                        }
                    }

                    popupConfirmDeleteTeacher.clear();

                    if (!hasSuccessDeletes) {
                        return;
                    }

                    if (store.user.userId === teacherId) {
                        onLoadTeacherClasses({
                            onFinish: (classes) => {
                                const firstClassId = classes?.[0]?.id;

                                if (firstClassId) {
                                    onClassChange(firstClassId);
                                }
                            },
                        });
                        return;
                    }

                    loadClassTeachers(classId);
                },
            },
        );
    };

    /* --- */

    const onPopupLMSSuccess = () => {
        onLoadTeacherClasses({
            onFinish: (classes) => {
                const cl = Classes.getClassById(classes, store.teacher.selectedClassId)
                    || classes?.[0];
                let classId = cl?.id || null;

                if (!classId) {
                    classId = classes?.[0]?.id || null;
                }

                if (!classId) {
                    dispatch(actions.teacher.clearTeacherClassScoresByDailyJuices());
                    dispatch(actions.teacher.clearTeacherClassScoresByAssignments());
                    setTeacherSelectedClass(-1);
                    return;
                }

                onClassChange(classId);
                onLoadClassScoresWithClear(classId);
            },
        });

        setIsLMSProviderVisible(false);
    };

    /* ------- */

    const navigateDefault = () => {
        const { dateFrom, dateTo } = getDefaultDates(store.siteDate);

        const view = DailyJuice.getDailyJuicesTypes()[0].value;

        navigate(`/class/${dateFrom}/${dateTo}/${view}`);
    };

    const validateDailyJuiceViewParams = () => {
        const isValidDateFrom = store.urlParams.dateFrom
            && date.isDate(store.urlParams.dateFrom);

        const isValidDateTo = store.urlParams.dateToOrLoadType
            && date.isDate(store.urlParams.dateToOrLoadType);

        if (isValidDateFrom && isValidDateTo) {
            return;
        }

        const defaultDates = getDefaultDates(store.siteDate);

        let dateFrom = null;
        let dateTo = null;

        if (isValidDateFrom) {
            dateFrom = store.urlParams.dateFrom;
        } else {
            dateFrom = defaultDates.dateFrom;
        }

        if (isValidDateTo) {
            dateTo = store.urlParams.dateToOrLoadType;
        } else {
            dateTo = defaultDates.dateTo;
        }

        if (store.urlParams.dateFrom !== dateFrom || store.urlParams.dateToOrLoadType !== dateTo) {
            navigate(`/class/${dateFrom}/${dateTo}/${store.urlParams.view}`);
        }
    };

    const validateAssignmentsViewParams = () => {
        const isValidDate = store.urlParams.dateFrom
            && date.isDate(store.urlParams.dateFrom);

        if (isValidDate) {
            return;
        }

        const { dateFrom } = getDefaultDates(store.siteDate);

        navigate(`/class/${dateFrom}/${store.urlParams.dateToOrLoadType}/${store.urlParams.view}`);
    };

    /* ------- */

    useEffect(() => {
        dispatch(actionsSite.loadSiteDate({
            api,
            actions,
        }));

        onLoadTeacherClasses({
            onFinish: (classes) => {
                let classId = storage.local.loadTeacherSelectedClass();

                if (!classId) {
                    classId = store.teacher.selectedClassId;
                }

                const cl = Classes.getClassById(classes, classId) || classes?.[0];

                classId = cl?.id || null;

                onClassChange(classId);
            },
        });

        return () => {
            dispatch(actions.teacher.clearStudentsScoresByRange());
            dispatch(actions.teacher.clearTeacherClassScoresByDailyJuices());
            dispatch(actions.teacher.clearTeacherClassScoresByAssignments());
        };
    }, []);

    useEffect(() => {
        if (!store.siteDate) {
            return;
        }

        if (!DailyJuice.isValidDailyJuiceType(store.urlParams.view)) {
            navigateDefault();
            return;
        }

        if (DailyJuice.isDailyJuiceView(store.urlParams.view)) {
            validateDailyJuiceViewParams();
            return;
        }

        validateAssignmentsViewParams();
    }, [
        store.siteDate,
        store.urlParams.view,
        store.urlParams.dateFrom,
        store.urlParams.dateToOrLoadType,
    ]);

    useEffect(() => {
        if (!store.siteDate) {
            return;
        }

        if (!hasDateRange) {
            return;
        }

        if (!Classes.hasSelectedClass(store.teacher.selectedClassId)) {
            return;
        }

        if (!DailyJuice.isValidDailyJuiceType(store.urlParams.view)) {
            return;
        }

        loadOrReloadScoresByView({
            view: store.urlParams.view,
        });
    }, [
        store.siteDate,
        store.urlParams.view,
        store.urlParams.dateFrom,
        store.urlParams.dateToOrLoadType,
        store.teacher.selectedClassId,
    ]);

    /* ------- */

    const renderAddClassPopup = () => {
        if (!addClassPopup.state.isOpen) {
            return null;
        }

        return (
            <PopupClassAdd
                grades={Grades.getGradesOptions()}
                onSubmit={onAddClass}
                onClose={onCloseAddClassPopup}
            />
        );
    };

    const renderLMSPopup = () => {
        if (!isLMSPopupVisible) {
            return null;
        }

        return (
            <TeacherPopupLMS
                onSuccess={onPopupLMSSuccess}
                onClose={() => {
                    setIsLMSProviderVisible(false);
                }}
            />
        );
    };

    const renderAddTeacherPopup = () => {
        if (!popupAddTeacher.state.isVisible) {
            return null;
        }

        return (
            <PopupAddTeacher
                classId={popupAddTeacher.state.classId}
                onClose={(isSuccess) => {
                    if (isSuccess) {
                        loadClassTeachers(store.teacher.selectedClassId);
                    }

                    popupAddTeacher.close();
                }}
            />
        );
    };

    const renderAddStudentsPopup = () => {
        if (!isVisibleAddStudentsPopup) {
            return null;
        }

        const { classes, selectedClassId } = store.teacher;

        const classLimit = Classes.getClassLimit(classes, selectedClassId);
        const classStudentsCount = Classes.getStudentsCount(classes, selectedClassId);
        const classGrade = Classes.getClassGradeById(classes, selectedClassId);

        const defaultGrade = classGrade
            ? Grades.getValidGrade(classGrade)
            : Grades.getMaxGradeValue();

        return (
            <PopupFullScreenAddStudentV2
                classId={selectedClassId}
                classLimit={classLimit}
                defaultSelectedMenu={1}
                defaultGrade={defaultGrade}
                classStudentsCount={classStudentsCount}
                onLoadClass={() => {
                    onLoadTeacherClasses();
                    onLoadClassScoresWithClear(selectedClassId);
                }}
                onClose={() => {
                    setIsVisibleAddStudentsPopup(false);
                }}
            />
        );
    };

    const renderStudentRemovingPopup = () => {
        if (!removeStudentsState.isVisiblePopup) {
            return null;
        }

        const data = getClassStudentsScores(store.teacher.selectedClassId, store.urlParams);
        const students = data?.data?.students || [];

        const selectedStudents = [];

        for (let i = 0; i < students.length; i += 1) {
            const s = students[i];

            if (removeStudentsState.students.indexOf(s.id) !== -1) {
                selectedStudents.push(s);
            }
        }

        return (
            <TeacherPopupStudentsRemove
                students={selectedStudents}
                onClose={onClosePopupStudentsRemove}
                onCloseWithFinishedStatus={() => {
                    onLoadTeacherClasses();
                    onLoadClassScoresWithClear(store.teacher.selectedClassId);
                    onClosePopupStudentsRemove();
                }}
            />
        );
    };

    const renderEditClassPopup = () => {
        if (!editClassPopup.state.isOpen) {
            return null;
        }

        const className = Classes.getClassNameById(
            store.teacher.classes,
            editClassPopup.state.classId,
        );

        const classGrade = Classes.getClassGradeById(
            store.teacher.classes,
            editClassPopup.state.classId,
        );

        const classGradeGroup = Grades.getValidGrade(classGrade);

        const initialValues = {
            className,
            grades: {
                value: classGradeGroup,
                isCreated: false,
            },
        };

        const canDeleteClass = store.teacher.classes.length > 1 && !isLMS;

        return (
            <PopupClassDetails
                initialValues={initialValues}
                grades={Grades.getGradesOptions()}
                allowEditClassName={!isLMS}
                allowDelete={canDeleteClass}
                onDelete={(() => {
                    onDeleteClassClick(editClassPopup.state.classId, initialValues.className);
                })}
                onSubmit={(values, formParams) => {
                    onChangeClassDetails(values, editClassPopup.state.classId, formParams);
                }}
                onClose={onCloseEditClassPopup}
            />
        );
    };

    const renderConfirmErrorDeleteClass = () => {
        if (!editClassPopup.state.deletingError) {
            return null;
        }

        return (
            <PopupConfirmError
                error={editClassPopup.state.deletingError}
                onClose={() => {
                    editClassPopup.setDeletingError(null);
                }}
            />
        );
    };

    const renderSnackbars = () => {
        return snackbar.state.map((bar, index) => {
            return (
                <Snackbar
                    isVisibleUndoButton={bar.isVisibleUndoButton}
                    autoCloseInSeconds={bar.autoCloseInSeconds}
                    message={bar.message}
                    onClose={(params) => {
                        onCloseSnackbar({
                            ...params,
                            index,
                            onCloseWithUndo: () => {
                                if (bar.onCloseWithUndo) {
                                    bar.onCloseWithUndo();
                                }
                            },
                            onClose: () => {
                                if (bar.onClose) {
                                    bar.onClose();
                                }
                            },
                        });
                    }}
                />
            );
        });
    };

    const renderClassTable = () => {
        const {
            isClassesLoaded,
            selectedClassId,
        } = store.teacher;

        if (!isClassesLoaded) {
            return (
                <RequestLoader />
            );
        }

        const data = getClassStudentsScores(selectedClassId, store.urlParams);

        const range = {
            dateFrom: null,
            dateTo: null,
        };

        if (DailyJuice.isDailyJuiceView(store.urlParams.view)) {
            range.dateFrom = date.newDateUTC(usedValues.dateFrom);
            range.dateTo = date.newDateUTC(usedValues.dateTo);
        } else {
            range.dateFrom = date.newDateUTC(usedValues.assignmentDate);
        }

        const notifications = store.studentsNotifications[selectedClassId] || [];

        const isLoading = data?.isLoading || false;

        let isPrevWeekDisabled = false;
        let isNextWeekDisabled = false;

        if (DailyJuice.isDailyJuiceView(store.urlParams.view) && store.siteDate) {
            const currWeekFriday = date.getFriday(store.siteDate);

            const isSame = date.isDatesSame(range.dateTo, currWeekFriday);
            const isGreater = date.newDate(range.dateTo) > currWeekFriday;

            if (isSame || isGreater) {
                isNextWeekDisabled = true;
            }
        } else if (DailyJuice.isAssignmentsView(store.urlParams.view)) {
            if (!data.hasMoreBefore) {
                isPrevWeekDisabled = true;
            }

            if (!data.hasMoreAfter) {
                isNextWeekDisabled = true;
            }
        }

        return (
            <div>
                <TeacherClassTableV2
                    view={store.urlParams.view}
                    error={data?.error || ""}
                    sortBy={teacherClassTable.state.sortBy}
                    siteDate={store.siteDate}
                    selectedRange={range}
                    students={data?.data?.students || []}
                    studentsNotifications={notifications}
                    dates={data?.data?.dates || []}
                    onAddNewClass={onOpenAddClassPopup}
                    onSyncLMSClasses={() => {
                        setIsLMSProviderVisible(true);
                    }}
                    onEditClass={onEditClass}
                    onClassChange={onClassChange}
                    onOpenAddStudentPopup={onOpenAddStudentPopup}
                    onRemoveStudents={onOpenRemoveStudentsPopup}
                    onOpenStudentInfo={navigateToStudentInfo}
                    onSortStudentsByName={teacherClassTable.sortByName}
                    onExportSelectedWeek={onExportSelectedWeek}
                    onExportAllScores={onExportAllScores}
                    onRangeChange={(values) => {
                        navigateRange({
                            dateFrom: date.getDateFromDate(values.dateFrom),
                            dateTo: date.getDateFromDate(values.dateTo),
                        });
                    }}
                    onDateChange={(d) => {
                        navigateRange({
                            dateFrom: date.getDateFromDate(d),
                            loadType: store.urlParams.dateToOrLoadType,
                        });
                    }}
                    onDateChangeByArrow={(val, isAfter) => {
                        navigateRange({
                            dateFrom: date.getDateFromDate(date.newDate(val)),
                            loadType: isAfter ? LOAD_TYPES.after : LOAD_TYPES.before,
                        });
                    }}
                    onChangeView={navigateView}
                    isLMS={isLMS}
                    isLoading={isLoading}
                    isMobileTable={isMobile900}
                    isMenuFullWidth={isMobile500}
                    isMenuOpensRight={isMobile1420}
                    isPrevWeekDisabled={isPrevWeekDisabled}
                    isNextWeekDisabled={isNextWeekDisabled}
                    isCalendarByDate={DailyJuice.isAssignmentsView(store.urlParams.view)}
                />
                <TeacherClassTableLegend
                    view={store.urlParams.view}
                    isVisible={!isLoading}
                />
            </div>
        );
    };

    const renderHeader = () => {
        const {
            selectedClassId,
            classes,
            classesTeachersById,
            maxClasses,
            isClassesLoaded,
        } = store.teacher;

        if (!isClassesLoaded) {
            return null;
        }

        const classTeachers = classesTeachersById?.[selectedClassId]?.data || [];
        const teacher = Classes.getClassTeacherById(classTeachers, store.user.userId);

        let withTeachersButton = true;

        if (isLMS || !teacher?.isPrimaryTeacher) {
            withTeachersButton = false;
        }

        return (
            <TeacherClassControls
                classes={classes || []}
                maxClasses={maxClasses}
                selectedClass={selectedClassId}
                onClassChange={onClassChange}
                onToggleTeacherTable={classTeachersTable.toggleVisible}
                onAddNewClass={onOpenAddClassPopup}
                onSyncLMSClasses={() => {
                    setIsLMSProviderVisible(true);
                }}
                onEditClass={onEditClass}
                onRemoveFromClass={(classId) => {
                    popupConfirmDeleteTeacher.open([store.user.userId], classId);
                }}
                isLMS={isLMS}
                isTeacherButtonSelected={classTeachersTable.state.isVisible}
                withTeachersButton={withTeachersButton}
            />
        );
    };

    const renderTableTeacher = () => {
        if (!classTeachersTable.state.isVisible) {
            return null;
        }

        const data = store.teacher.classesTeachersById?.[store.teacher.selectedClassId] || {};

        return (
            <ClassTeachersTable
                data={data?.data}
                sortBy={classTeachersTable.state.sortBy}
                error={data?.error}
                onSortByTeacherNames={classTeachersTable.sortByName}
                onAdd={() => {
                    popupAddTeacher.open(store.teacher.selectedClassId);
                }}
                onDelete={(ids) => {
                    popupConfirmDeleteTeacher.open(ids, store.teacher.selectedClassId);
                }}
                isLoading={data?.isLoading}
                isMobile={isMobile900}
            />
        );
    };

    const renderPopupConfirmDeleteTeacher = () => {
        const { classId, visibleIds, statusById } = popupConfirmDeleteTeacher.state;

        if (visibleIds.length === 0) {
            return null;
        }

        const { classesTeachersById, classes } = store.teacher;
        const classTeachers = classesTeachersById?.[classId]?.data || [];

        const classPrimaryTeacher = Classes.getClassPrimaryTeacher(classTeachers);

        const popups = visibleIds.map((teacherId) => {
            const popupState = statusById?.[teacherId] || {};

            const teacher = Classes.getClassTeacherById(classTeachers, teacherId);
            const className = Classes.getClassNameById(classes, classId);

            return (
                <PopupConfirmDeleteTeacher
                    fullName={teacher?.fullName}
                    className={className}
                    error={popupState?.error}
                    primaryTeacherFullName={classPrimaryTeacher?.fullName}
                    primaryTeacherEmail={classPrimaryTeacher?.email}
                    onClose={() => {
                        onClosePopupConfirmDeleteTeacher(teacherId);
                    }}
                    onDelete={() => {
                        onDeleteTeacher(teacherId);
                    }}
                    isSuccessfullyDeleted={popupState.isSuccessfullyDeleted}
                    isRemoveYourself={teacherId === store.user.userId}
                    isLoading={popupState?.isLoading}
                />
            );
        });

        return popups;
    };

    const renderContent = () => {
        if (!hasDateRange || !store.siteDate) {
            return (
                <RequestLoader />
            );
        }

        return (
            <div className={styles.index}>
                {renderHeader()}
                {renderTableTeacher()}
                {renderClassTable()}
            </div>
        );
    };

    return (
        <>
            {renderAddClassPopup()}
            {renderLMSPopup()}
            {renderAddStudentsPopup()}
            {renderAddTeacherPopup()}
            {renderStudentRemovingPopup()}
            {renderEditClassPopup()}
            {renderConfirmErrorDeleteClass()}
            {renderPopupConfirmDeleteTeacher()}
            {renderSnackbars()}

            <LayoutContent>
                {renderContent()}
            </LayoutContent>

            <UserFooter />
        </>
    );
};

export default ClassV3;
