// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".KHaCVYHdnyCtvck9naLj {\n    border-radius: var(--border-default-radius);\n    border: var(--border-contrast);\n\n    overflow: hidden;\n}\n\n.h39PxvF8Bfz7e7qHDtr7 {\n    display: grid;\n    grid-auto-flow: column;\n    grid-template-columns: 4rem auto;\n    align-items: center;\n\n    background-color: var(--searcher-bg-color);\n    background-color: var(--background-secondary-color);\n\n    border-bottom: 0.2rem solid var(--grey-light);\n    border-bottom: var(--searcher-border-bottom);\n}\n\n.plDhmw9MmnFl0o5xvdsw {\n    display: grid;\n    justify-content: end;\n    align-items: center;\n}\n\n.ZfdV7pkznYDYIEclIghY {\n    width: 2.3rem;\n    height: 2.3rem;\n\n    cursor: pointer;\n    outline: none;\n}\n", "",{"version":3,"sources":["webpack://./base/forms/search/styles.module.css"],"names":[],"mappings":"AAAA;IACI,2CAA2C;IAC3C,8BAA8B;;IAE9B,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,gCAAgC;IAChC,mBAAmB;;IAEnB,0CAA0C;IAC1C,mDAAmD;;IAEnD,6CAA6C;IAC7C,4CAA4C;AAChD;;AAEA;IACI,aAAa;IACb,oBAAoB;IACpB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,cAAc;;IAEd,eAAe;IACf,aAAa;AACjB","sourcesContent":[".form {\n    border-radius: var(--border-default-radius);\n    border: var(--border-contrast);\n\n    overflow: hidden;\n}\n\n.textSearcher {\n    display: grid;\n    grid-auto-flow: column;\n    grid-template-columns: 4rem auto;\n    align-items: center;\n\n    background-color: var(--searcher-bg-color);\n    background-color: var(--background-secondary-color);\n\n    border-bottom: 0.2rem solid var(--grey-light);\n    border-bottom: var(--searcher-border-bottom);\n}\n\n.textSearchButton {\n    display: grid;\n    justify-content: end;\n    align-items: center;\n}\n\n.textSearchButtonIcon {\n    width: 2.3rem;\n    height: 2.3rem;\n\n    cursor: pointer;\n    outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "KHaCVYHdnyCtvck9naLj",
	"textSearcher": "h39PxvF8Bfz7e7qHDtr7",
	"textSearchButton": "plDhmw9MmnFl0o5xvdsw",
	"textSearchButtonIcon": "ZfdV7pkznYDYIEclIghY"
};
export default ___CSS_LOADER_EXPORT___;
