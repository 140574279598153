import React from "react";

import IconWrapper from "juice-base/icons/wrapper/index.js";


const IconPlayerHeadphone = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    width="19"
                    height="18"
                    viewBox="0 0 19 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={iconProps.className}
                >
                    {iconProps.title}
                    <path
                        fill="currentColor"
                        d="M0.732422 14.6016H1.52344C1.66406 14.6016 1.74316 14.5137 1.74316 14.3818V9.28418C1.74316 5.13574 4.89844 2.44629 9.39844 2.44629C13.8984 2.44629 17.0537 5.13574 17.0537 9.28418V14.3818C17.0537 14.5137 17.1416 14.6016 17.2822 14.6016H18.0645C18.46 14.6016 18.7412 14.3555 18.7412 13.9863V8.87988C18.7412 4.0459 14.9092 0.785156 9.71484 0.785156H9.09082C3.89648 0.785156 0.0556641 4.0459 0.0556641 8.87988V13.9863C0.0556641 14.3555 0.345703 14.6016 0.732422 14.6016ZM3.59766 17.9941H4.64355C5.69824 17.9941 6.32227 17.4141 6.32227 16.4121V12.3955C6.32227 11.3936 5.69824 10.8135 4.64355 10.8135H3.59766C3.01758 10.8135 2.68359 11.1387 2.68359 11.71V17.0977C2.68359 17.6689 3.01758 17.9941 3.59766 17.9941ZM14.1621 17.9941H15.208C15.7969 17.9941 16.1396 17.6689 16.1396 17.0977V11.71C16.1396 11.1299 15.7969 10.8135 15.208 10.8135H14.1621C13.1074 10.8135 12.4834 11.3936 12.4834 12.3955V16.4121C12.4834 17.4141 13.1074 17.9941 14.1621 17.9941Z"
                    />
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconPlayerHeadphone;
