// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".hM3zfCHoY_0ydelayTh3 {\n    display: grid;\n    justify-self: center;\n\n    max-width: 50rem;\n    width: 100%;\n}\n\n.I4Oo6QbRhH_YU1M2glba {\n    display: grid;\n    grid-auto-flow: column;\n    grid-gap: 1rem;\n\n    justify-content: space-between;\n    align-items: center;\n\n    padding: 2rem 1rem;\n\n    border-bottom: 0.1rem solid var(--grey-light);\n}\n\n.we7bhMN9DbakXvp3sxDu {\n    font-weight: 600;\n}\n\n.np_hlmnUlQC4CMUSwNAi {\n    display: grid;\n}\n\n.dXfvas6cZdCVwgDxt7nX {\n    padding: 2rem 0;\n\n    font-weight: 600;\n    font-size: 2.1rem;\n\n    border-bottom: 0.1rem solid var(--grey-light);\n}", "",{"version":3,"sources":["webpack://./base/business/teacher-share-class-code/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,oBAAoB;;IAEpB,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,cAAc;;IAEd,8BAA8B;IAC9B,mBAAmB;;IAEnB,kBAAkB;;IAElB,6CAA6C;AACjD;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,eAAe;;IAEf,gBAAgB;IAChB,iBAAiB;;IAEjB,6CAA6C;AACjD","sourcesContent":[".shareClassCodePage {\n    display: grid;\n    justify-self: center;\n\n    max-width: 50rem;\n    width: 100%;\n}\n\n.shareClassCodePageBlock {\n    display: grid;\n    grid-auto-flow: column;\n    grid-gap: 1rem;\n\n    justify-content: space-between;\n    align-items: center;\n\n    padding: 2rem 1rem;\n\n    border-bottom: 0.1rem solid var(--grey-light);\n}\n\n.shareClassCodePageBlockLabel {\n    font-weight: 600;\n}\n\n.shareClassCodePageBlocks {\n    display: grid;\n}\n\n.shareClassCodePageTitle {\n    padding: 2rem 0;\n\n    font-weight: 600;\n    font-size: 2.1rem;\n\n    border-bottom: 0.1rem solid var(--grey-light);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"shareClassCodePage": "hM3zfCHoY_0ydelayTh3",
	"shareClassCodePageBlock": "I4Oo6QbRhH_YU1M2glba",
	"shareClassCodePageBlockLabel": "we7bhMN9DbakXvp3sxDu",
	"shareClassCodePageBlocks": "np_hlmnUlQC4CMUSwNAi",
	"shareClassCodePageTitle": "dXfvas6cZdCVwgDxt7nX"
};
export default ___CSS_LOADER_EXPORT___;
