export const SET_VERSION = "SET_VERSION";

export const SET_AUTOMATIC_THEME = "SET_AUTOMATIC_THEME";
export const SET_THEME = "SET_THEME";

export const SET_LIB_VIDEOJS_LOADED = "SET_LIB_VIDEOJS_LOADED";
export const SET_LIB_GAPI_LOADED = "SET_LIB_GAPI_LOADED";
export const SET_LIB_STRIPE_LOADED = "SET_LIB_STRIPE_LOADED";
export const SET_LIB_STRIPE_ERROR = "SET_LIB_STRIPE_ERROR";
export const SET_LIB_STRIPE_INIT_LOADED = "SET_LIB_STRIPE_INIT_LOADED";

export default {};
