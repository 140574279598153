import React from "react";

import classNames from "juice-base/lib/class-names.js";
import text from "juice-base/text/index.js";

import Message from "juice-base/components/message/index.js";

import SIZES from "./sizes.js";
import Legend from "./legend.js";
import Layout from "./layout.js";
import styles from "./styles.module.css";


const BlockProgress = (props) => {
    const renderTicks = () => {
        const ticks = [];

        for (let i = 0; i < props.ticks; i += 1) {
            const percent = (100 / (props.ticks - 1)) * i;

            const tickStyles = {
                width: `${SIZES.tickWidthRem}rem`,
            };

            if (i === 0) {
                tickStyles.left = `${percent}%`;
            } else {
                tickStyles.left = `calc(${percent}% - ${SIZES.tickWidthRem}rem * (${percent} / 100))`;
            }

            ticks.push(
                <div
                    className={styles.tick}
                    style={tickStyles}
                >
                    <div className={styles.tickSeparator} />
                    <div className={styles.tickPercent}>
                        {`${percent.toFixed(0)}%`}
                    </div>
                </div>,
            );
        }

        return (
            <div className={styles.ticks}>
                {ticks}
            </div>
        );
    };

    const getBarContainerStylesByIndex = (data, barIndex) => {
        const barContainerStyles = {};

        let totalMarginLeft = 0;

        for (let i = 0; i < barIndex; i += 1) {
            totalMarginLeft += data.percents[i];
        }

        barContainerStyles.marginLeft = `${totalMarginLeft}%`;
        barContainerStyles.marginTop = `${SIZES.barMarginTopRem}rem`;

        return barContainerStyles;
    };

    const getAverageBarContainerStyles = (data) => {
        const barContainerStyles = {};

        barContainerStyles.marginLeft = `${data.min}%`;
        barContainerStyles.marginTop = `calc(${SIZES.barMarginTopRem}rem + 1rem)`;

        return barContainerStyles;
    };

    const getBarStylesByIndex = (data, index) => {
        let width = data.percents[index];

        if (width === 0) {
            width = 1;
        }

        return {
            height: `${SIZES.barHeightRem}rem`,
            width: `${width}%`,
        };
    };

    const getBarAverageStyles = (data) => {
        return {
            height: `${SIZES.barHeightRem}rem`,
            width: `${data.max - data.min}%`,
        };
    };

    const getBarPercentStylesByIndex = (data, index) => {
        const marginRight = `${SIZES.barPercentMarginRightRem}rem`;

        const barStyle = getBarStylesByIndex(data, index);

        return {
            left: `calc(${barStyle.width} + ${marginRight})`,
        };
    };

    const renderBlock = (d, bars, error) => {
        if (error) {
            return (
                <div className={styles.error}>
                    <Message>
                        {error}
                    </Message>
                </div>
            );
        }

        return (
            <div
                className={styles.barsBlock}
                style={{
                    margin: `0 ${SIZES.tickWidthRem / 2}rem`,
                }}
            >
                <div className={styles.barsBlockTitle}>
                    {d.title || ""}
                </div>
                {bars}
            </div>
        );
    };

    const renderBars = () => {
        const barsBlocks = [];

        for (let i = 0; i < props.data.length; i += 1) {
            const d = props.data[i];
            const bars = [];

            const totalPercentsBars = (d.percents || []).length;

            for (let j = 0; j < totalPercentsBars; j += 1) {
                const barClassNames = classNames({
                    [styles.bar]: true,
                    [styles.barFirst]: j % totalPercentsBars === 0,
                    [styles.bar1]: j % totalPercentsBars === 0,
                    [styles.bar2]: j % totalPercentsBars === 1,
                    [styles.bar3]: j % totalPercentsBars === 2,
                });

                bars.push(
                    <div
                        className={styles.barContainer}
                        style={getBarContainerStylesByIndex(d, j)}
                    >
                        <div
                            className={barClassNames}
                            style={getBarStylesByIndex(d, j)}
                        />
                        <div
                            className={styles.barPercent}
                            style={getBarPercentStylesByIndex(d, j)}
                        >
                            {`${d.percents[j]}%`}
                        </div>
                    </div>,
                );
            }

            barsBlocks.push(renderBlock(d, bars, ""));
        }

        return barsBlocks;
    };

    const renderAverageBars = () => {
        const barsBlocks = [];

        for (let i = 0; i < props.data.length; i += 1) {
            const d = props.data[i];

            if (d.max >= d.min) {
                let min = null;
                let max = null;

                const isMinMaxSame = d.min === d.max;

                if (!isMinMaxSame) {
                    min = (
                        <div
                            className={styles.barPercent}
                            style={{
                                right: `calc(100% + ${SIZES.barPercentMarginRightRem}rem)`,
                            }}
                        >
                            {`${d.min}%`}
                        </div>
                    );
                    max = (
                        <div
                            className={styles.barPercent}
                            style={{
                                left: `calc(${d.max - d.min}% + ${SIZES.barPercentMarginRightRem}rem)`,
                            }}
                        >
                            {`${d.max}%`}
                        </div>
                    );
                }

                const pointClassName = classNames({
                    [styles.barPercentAveragePoint]: true,
                    [styles.barPercentAveragePointFilled]: isMinMaxSame,
                });

                const barClassNames = classNames({
                    [styles.bar]: true,
                    [styles.bar1]: true,
                    [styles.barHidden]: isMinMaxSame,
                });

                const bar = (
                    <div
                        className={styles.barContainer}
                        style={getAverageBarContainerStyles(d)}
                    >
                        <div
                            className={barClassNames}
                            style={getBarAverageStyles(d)}
                        />
                        {min}
                        {max}
                        <div
                            className={styles.barPercentAverage}
                            style={{
                                width: `${SIZES.barAverageWidthRem}rem`,
                                left: `calc(${d.average - d.min}% - ${SIZES.barAverageWidthRem / 2}rem)`,
                                bottom: 0,
                            }}
                        >
                            <div>
                                {`${d.average}%`}
                            </div>
                            <div
                                className={styles.barPercentAveragePointContainer}
                                style={{
                                    height: `${SIZES.barHeightRem}rem`,
                                    width: `${SIZES.barHeightRem}rem`,
                                }}
                            >
                                <div
                                    className={pointClassName}
                                    style={{
                                        height: `${SIZES.barHeightRem / 2}rem`,
                                        width: `${SIZES.barHeightRem / 2}rem`,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                );

                barsBlocks.push(renderBlock(d, bar, ""));
            } else {
                barsBlocks.push(renderBlock(d, null, text.blockProgressError));
            }
        }

        return barsBlocks;
    };

    const renderBlocks = () => {
        const blocks = props.isAverageScore
            ? renderAverageBars()
            : renderBars();

        return (
            <div className={styles.barsBlocks}>
                {blocks}
            </div>
        );
    };

    return (
        <div
            className={styles.container}
            style={{
                marginRight: `calc(${SIZES.barPercentMarginRightRem}rem + 2rem)`,
            }}
        >
            {renderTicks()}
            {renderBlocks()}
        </div>
    );
};

BlockProgress.defaultProps = {
    ticks: 5,
    data: [],
    isAverageScore: false,
};

export const BlockProgressLegend = Legend;
export const BlockProgressLayout = Layout;
export default BlockProgress;
