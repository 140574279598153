import React from "react";

import IconArrowRight from "juice-base/icons/arrow-right/index.js";
import IconCaret from "juice-base/icons/caret/index.js";

import classNames from "juice-base/lib/class-names.js";

import styles from "./styles.module.css";


const ButtonCircleArrow = (props) => {
    const onClick = () => {
        if (props.disabled) {
            return;
        }

        props.onClick();
    };

    /* ---- */
    const renderIcon = () => {
        if (props.caretBold) {
            const iconBoldClassName = classNames({
                [styles.iconBold]: true,
                [styles.iconBoldRotateLeft]: props.left,
                [styles.iconBoldRotateRight]: props.right,
            });

            return (
                <IconCaret
                    className={iconBoldClassName}
                    isBlack
                />
            );
        }

        const iconClassName = classNames({
            [styles.icon]: true,
            [styles.iconRotateLeft]: props.left,
            [styles.iconRotateRight]: props.right,
        });

        return (
            <IconArrowRight
                className={iconClassName}
                isBlack
            />
        );
    };

    /* ---- */

    const buttonClassName = classNames({
        [styles.button]: true,
        [styles.buttonDisabled]: props.disabled,
    });

    return (
        <div
            className={buttonClassName}
            onClick={onClick}
            onKeyPress={onClick}
            tabIndex="-1"
            role="button"
        >
            {renderIcon()}
        </div>
    );
};

ButtonCircleArrow.defaultProps = {
    left: false,
    right: true,
    disabled: false,
    caretBold: false,

    onClick: () => { },
};

export default ButtonCircleArrow;
