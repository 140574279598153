import debug from "juice-base/lib/debug.js";


const getTimestamp = () => {
    const cacheTime = 24 * 60 * 60 * 1000;
    const timestamp = Math.ceil(new Date() / cacheTime) * cacheTime;
    return timestamp;
};

const addTimestampToUrl = (url) => {
    const timestamp = getTimestamp();

    if (url.indexOf("?") === -1) {
        return `${url}?t=${timestamp}`;
    }

    return url;
};

const loadScript = (params, callback) => {
    if (!params.url) {
        return;
    }

    const url = addTimestampToUrl(params.url);

    const scriptElem = document.createElement("script");
    scriptElem.async = 1;
    scriptElem.src = url;
    scriptElem.onload = () => {
        debug.load("javascript", params);
        callback();
    };

    const headElem = document.querySelector("head");
    headElem.append(scriptElem);
};

const loadScriptWithDelay = (params, callback) => {
    if (window.setTimeout) {
        const seconds = params.delay || 7;

        window.setTimeout(() => {
            loadScript(params, callback);
        }, seconds * 1000);
    } else {
        loadScript(params, callback);
    }
};

/* --- */

const loadStylesheet = (params, callback) => {
    if (!params.url) {
        return;
    }

    const url = addTimestampToUrl(params.url);

    const linkElem = document.createElement("link");
    linkElem.setAttribute("rel", "stylesheet");
    linkElem.href = url;

    linkElem.onload = () => {
        debug.load("css", params);
        callback();
    };

    const headElem = document.querySelector("head");
    headElem.append(linkElem);
};

const loadStylesheetWithDelay = (params, callback) => {
    if (window.setTimeout) {
        const seconds = params.delay || 7;

        window.setTimeout(() => {
            loadStylesheet(params, callback);
        }, seconds * 1000);
    } else {
        loadStylesheet(params, callback);
    }
};

export default {
    loadScript,
    loadScriptWithDelay,
    loadStylesheet,
    loadStylesheetWithDelay,
};
