import React from "react";

import ButtonBig from "juice-base/components/button-big/index.js";
import RequestLoader from "juice-base/components/request-loader/index.js";
import Error from "juice-base/components/forms/error/index.js";

import styles from "./styles.module.css";


const SubscribeCheckout = (props) => {
    const renderSmallGreyText = (text) => {
        return (
            <div className={styles.smallGreyText}>
                {text}
            </div>
        );
    };

    const renderBigBoldText = (text) => {
        return (
            <div className={styles.bigBoldText}>
                {text}
            </div>
        );
    };

    const renderBigText = (text) => {
        return (
            <div className={styles.bigText}>
                {text}
            </div>
        );
    };

    const renderError = () => {
        if (!props.error) {
            return null;
        }

        return (
            <Error>
                {props.error}
            </Error>
        );
    };

    /* ------------ */

    const renderOrderSummary = () => {
        let autoRenewSection = null;

        if (props.isAutoRenew) {
            autoRenewSection = (
                <div className={styles.sectionRow}>
                    <div>
                        {renderSmallGreyText(`Recurring billing starting ${props.dates.start}.`)}
                        {renderSmallGreyText(`Cancel anytime before ${props.dates.end}.`)}
                    </div>
                    {renderSmallGreyText(`$${props.price}`)}
                </div>
            );
        }

        return (
            <div className={styles.checkoutSection}>
                {renderBigBoldText("Order Summary")}
                <div>{`The Juice ${props.planName} Plan`}</div>
                {autoRenewSection}
            </div>
        );
    };

    const renderOrderTotal = () => {
        return (
            <div className={styles.checkoutSection}>
                <div className={styles.sectionRow}>
                    {renderBigBoldText("Total")}
                    {renderBigText(`$${props.price}`)}
                </div>
                <div className={styles.sectionRow}>
                    <div>Subtotal</div>
                    {renderSmallGreyText(`$${props.price}`)}
                </div>
                <div className={styles.sectionRow}>
                    <div>Tax</div>
                    {renderSmallGreyText("$0.00")}
                </div>
            </div>
        );
    };

    const renderPaymentDetails = () => {
        return (
            <div className={styles.checkoutSection}>
                {renderBigBoldText("Payment Details")}
                <div className={styles.sectionRow}>
                    <div>Card Holder</div>
                    {renderSmallGreyText(`${props.card.name}`)}
                </div>
                <div className={styles.sectionRow}>
                    <div>Card Number</div>
                    {renderSmallGreyText(`xxxx xxxx xxxx ${props.card.last4}`)}
                </div>
                <div className={styles.sectionRow}>
                    <div>Expiration</div>
                    {renderSmallGreyText(`${props.card.expMonth || ""}/${props.card.expYear || ""}`)}
                </div>
            </div>
        );
    };

    const renderButtons = () => {
        let controls = null;

        if (props.isSubmitted) {
            controls = (
                <RequestLoader />
            );
        } else {
            controls = (
                <>
                    <ButtonBig
                        uppercase
                        onClick={props.onSubscribe}
                    >
                        Subscribe
                    </ButtonBig>
                    <ButtonBig
                        uppercase
                        outlined
                        onClick={props.onChangePaymentDetails}
                    >
                        Change payment details
                    </ButtonBig>
                </>
            );
        }

        return (
            <div className={styles.controls}>
                {controls}
            </div>
        );
    };

    const renderContent = () => {
        let content = null;

        if (!props.isLoaded) {
            content = (
                <RequestLoader />
            );
        } else if (props.error) {
            content = renderError();
        } else {
            content = (
                <>
                    {renderOrderSummary()}
                    {renderOrderTotal()}
                    {renderPaymentDetails()}
                    {renderButtons()}
                </>
            );
        }

        return (
            <div className={styles.page}>
                {content}
            </div>
        );
    };

    /* ------------ */

    return (
        <div className={styles.page}>
            {renderContent()}
        </div>
    );
};

SubscribeCheckout.defaultProps = {
    isLoaded: false,
    isSubmitted: false,

    planName: "",
    price: null,
    card: {},
    dates: {},
    isAutoRenew: false,
    error: null,

    onSubscribe: () => {},
    onChangePaymentDetails: () => {},
};

export default SubscribeCheckout;
