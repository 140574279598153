import React from "react";


const AudioManager = React.createContext({
    loadWord: () => {},
    loadStory: () => {},
    loadExtraJuice: () => {},
    play: () => {},
    pause: () => {},
    pauseAll: () => {},
    stop: () => {},
    stopAll: () => {},
    stopAllTracks: () => {},
    rewind: () => {},
    forward: () => {},
    changeRate: () => {},
});

export default AudioManager;
