import React from "react";

import styles from "./styles.module.css";


// TODO: better name
const ButtonQuickLink = (props) => {
    return (
        <div
            className={styles.button}
            onClick={props.onClick}
            onKeyPress={props.onClick}
            role="button"
            tabIndex="-1"
            data-comment={props.dataComment || ""}
        >
            {props.children}
        </div>
    );
};

ButtonQuickLink.defaultProps = {
    children: null,
    dataComment: "",
    onClick: () => { },
};

export default ButtonQuickLink;
