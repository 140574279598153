import React, { useState, useEffect } from "react";
import { useNavigate, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import urls from "juice-base/lib/urls.js";
import text from "juice-base/text/index.js";
import storage from "juice-base/lib/storage/index.js";
import actions from "juice-base/store/actions.js";
import userActions from "juice-base/actions/user.js";

import LayoutContent from "juice-base/components/layout-content/index.js";
import RequestLoader from "juice-base/components/request-loader/index.js";
import MessageDanger from "juice-base/components/message-danger/index.js";
import ButtonBig from "juice-base/components/button-big/index.js";
import Text from "juice-base/components/text/index.js";

import settings from "juice-app/settings.js";
import api from "juice-app/api.js";

import styles from "./styles.module.css";


const storeSelector = (state) => ({
    lmsSignInUrl: state.info.features.lmsSignInUrl,
});

const SignInLMSView = () => {
    const code = urls.getSearchParamByName("code");

    const [codeState, setCodeState] = useState({
        isLoaded: false,
        error: "",
    });

    const navigate = useNavigate();

    const store = useSelector(storeSelector);
    const dispatch = useDispatch();

    const checkCode = async () => {
        if (!code) {
            return;
        }

        const res = await api.lms.signIn({ code });

        if (!res.ok) {
            setCodeState((prev) => ({
                ...prev,
                isLoaded: true,
                error: res.error || text.error,
            }));
            return;
        }

        setCodeState((prev) => ({
            ...prev,
            isLoaded: true,
        }));

        storage.local.saveSession(res.sessionId ?? "");
        storage.session.saveSession("");

        dispatch(actions.user.setUserSession(res.sessionId || ""));
        dispatch(actions.user.setUser(res.user));

        const userId = res?.user?.userId || "";

        if (userId) {
            dispatch(userActions.saveUserIdAndReport({ storage, api }, {
                userId,
            }));
        }

        navigate("/");
    };

    useEffect(() => {
        checkCode();
    }, [code]);

    const renderSignInViaLMS = () => {
        if (!settings?.features?.LMS || !store.lmsSignInUrl) {
            return null;
        }

        return (
            <div className={styles.customSignIn}>
                <ButtonBig
                    type="button"
                    onClick={() => {
                        urls.redirect(store.lmsSignInUrl);
                    }}
                >
                    Sign In with LMS
                </ButtonBig>
            </div>
        );
    };

    const renderContent = () => {
        if (!codeState.isLoaded) {
            return (
                <RequestLoader />
            );
        }

        if (codeState.error) {
            return (
                <div className={styles.message}>
                    <MessageDanger>
                        <Text>
                            {codeState.error}
                        </Text>
                    </MessageDanger>

                    {renderSignInViaLMS()}
                </div>
            );
        }

        return (
            <Navigate to="/" replace />
        );
    };

    if (!code) {
        return (
            <Navigate to="/sign-in" replace />
        );
    }

    return (
        <LayoutContent>
            {renderContent()}
        </LayoutContent>
    );
};

export default SignInLMSView;
