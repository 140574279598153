import React from "react";

import IconWrapper from "juice-base/icons/wrapper/index.js";


const IconContrast = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    className={iconProps.className}
                    viewBox="0 0 24 24"
                    height="24"
                    width="24"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    {iconProps.title}
                    <path
                        d="M12,22c5.52,0,10-4.48,10-10S17.52,2,12,2S2,6.48,2,12S6.48,22,12,22z M13,4.07c3.94,0.49,7,3.85,7,7.93s-3.05,7.44-7,7.93 V4.07z"
                    />
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconContrast;
