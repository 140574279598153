import React from "react";

import classNames from "juice-base/lib/class-names.js";

import Image from "juice-base/components/image/index.js";
import PollSection from "juice-base/components/poll-section/index.js";
import JuiceContentFirstLines from "juice-base/components/juice-content-first-lines/index.js";
import ButtonText from "juice-base/components/button-text/index.js";

import dailyJuiceCommonStyles from "juice-base/business/daily-juice-common/styles.module.css";
import styles from "./styles.module.css";


const DailyJuiceStoryPollCard = (props) => {
    const { story } = props;

    if (!story) {
        return null;
    }

    const renderFeaturedContent = () => {
        if (story?.featuredImage?.url) {
            const image = { ...story.featuredImage };

            const imageClassName = classNames({
                [styles.featuredImage]: true,
                [styles.featuredInfographicImage]: image.infographic,
            });

            return (
                <div
                    className={styles.featuredImgContainer}
                    onClick={props.onReadMore}
                    onKeyPress={props.onReadMore}
                    role="button"
                    tabIndex="-1"
                >
                    <Image
                        src={image.url}
                        title={image.title}
                        className={imageClassName}
                    />
                </div>
            );
        }

        return null;
    };

    const renderJuiceContentFirstLines = () => {
        if (!story.content) {
            return null;
        }

        return (
            <JuiceContentFirstLines
                content={story.content}
                className={styles.content}
                onClick={props.onReadMore}
            />
        );
    };

    const renderPoll = () => {
        const { poll } = props;

        if (!poll) {
            return null;
        }

        const pollBottomControl = (
            <ButtonText
                uppercase
                onClick={props.onShowAllPolls}
            >
                See all polls
            </ButtonText>
        );

        return (
            <PollSection
                question={poll.data.question}
                answers={poll.data.answers}
                votes={poll.data?.totalVotes || 0}
                selectedAnswer={poll.data.userAnswerId}
                bottomControl={pollBottomControl}
                error={poll.error}
                onSelect={(answerId) => {
                    props.onSubmitPoll({
                        pollId: poll.data.id,
                        answerId,
                    });
                }}
                isLoadingResults={poll.isLoading}
                isDisabled={poll.isDisabled}
                isOutdated={poll.data?.isOutdated}
                isVisibleResults={poll.isVisibleResults}
            />
        );
    };

    const cardClassName = classNames({
        [dailyJuiceCommonStyles.block]: true,
        [styles.card]: true,
    });

    const categoryClassName = classNames({
        [dailyJuiceCommonStyles.header]: true,
        [styles.category]: true,
    });

    return (
        <div
            className={cardClassName}
            data-comment="daily-juice-story-card"
        >
            {renderFeaturedContent()}
            <div className={styles.cardContent}>
                <div className={categoryClassName}>
                    {`${props.storyIndex + 1} | ${story.categoryName}`}
                </div>
                {renderPoll()}
                {renderJuiceContentFirstLines()}
            </div>
        </div>
    );
};

DailyJuiceStoryPollCard.defaultProps = {
    storyIndex: 0,
    story: null,
    poll: null,
    onSubmitPoll: () => { },
    onReadMore: () => { },
    onShowAllPolls: () => { },
};

export default DailyJuiceStoryPollCard;
