import React from "react";

import classNames from "juice-base/lib/class-names.js";
import date from "juice-base/lib/date.js";

import text from "juice-base/text/index.js";

import DailyJuiceStory from "juice-base/project/daily-juice-story.js";

import AssignmentCard from "juice-base/business/assignment-card/index.js";
import SectionStudentV2 from "juice-base/business/section-student-v2/index.js";
import ButtonBig from "juice-base/components/button-big/index.js";
import Message from "juice-base/components/message/index.js";
import RequestLoader from "juice-base/components/request-loader/index.js";

import styles from "./styles.module.css";


const AssignmentsTodoList = (props) => {
    const renderAssignments = () => {
        if (props.data.length === 0 && !props.isLoading) {
            return (
                <Message>
                    {text.assignmentCompleted}
                </Message>
            );
        }

        const assignmentsByDate = (props.data || []).map((d, i) => {
            const assignmentsCards = d.assignments.map((assignment) => {
                const firstStory = assignment?.stories?.[0] || {};
                const firstStoryId = firstStory?.ID;
                const img = DailyJuiceStory.getMinSizeImage(firstStory.featuredImage);

                return (
                    <AssignmentCard
                        title={firstStory.title}
                        date={firstStory.date}
                        imageSrc={img?.src || ""}
                        status={assignment.status}
                        onClick={() => {
                            props.onAssignmentClick(assignment, firstStoryId);
                        }}
                    />
                );
            });

            const dateClassName = classNames({
                [styles.assignmentDate]: true,
                [styles.assignmentDateFirst]: i === 0,
            });

            return (
                <>
                    <div className={dateClassName}>
                        {`Assigned ${date.tryFormatDateUTC(d.date, date.formatMonthDayYearDate)}`}
                    </div>
                    <div className={styles.assignments}>
                        {assignmentsCards}
                    </div>
                </>
            );
        });

        return (
            <div className={styles.assignmentsByDate}>
                {assignmentsByDate}
            </div>
        );
    };

    const renderLoadButton = () => {
        if (props.error) {
            return (
                <Message>
                    {props.error}
                </Message>
            );
        }

        if (props.isLoading) {
            return (
                <div className={styles.loader}>
                    <RequestLoader />
                </div>
            );
        }

        if (!props.hasMore || props.data.length === 0) {
            return null;
        }

        return (
            <div className={styles.loadMoreButton}>
                <ButtonBig
                    onClick={props.onLoadMore}
                    outlined
                    isRounded
                    isDefaultBorder
                >
                    Load More
                </ButtonBig>
            </div>
        );
    };

    return (
        <SectionStudentV2
            title="To-Do list"
            isDefaultBorder
        >
            {renderAssignments()}
            {renderLoadButton()}
        </SectionStudentV2>
    );
};

AssignmentsTodoList.defaultProps = {
    data: [],
    error: "",
    onAssignmentClick: () => { },
    onLoadMore: () => { },
    hasMore: false,
    isLoading: false,
};

export default AssignmentsTodoList;
