import sleep from "juice-base/lib/sleep.js";


const getFunctionRepeater = (fn) => {
    const sleepSeconds = 10;
    let isRepeating = true;

    return {
        async start() {
            if (!fn) {
                return;
            }

            while (isRepeating) {
                fn();
                await sleep.sleepSeconds(sleepSeconds); // eslint-disable-line no-await-in-loop
            }
        },

        stop() {
            isRepeating = false;
        },
    };
};

export default {
    getFunctionRepeater,
};
