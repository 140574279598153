import * as types from "juice-base/store-common/types.js";

import * as app from "juice-base/store-common/app/actions.js";
import * as device from "juice-base/store-common/device/actions.js";
import * as monitors from "juice-base/store-common/monitors/actions.js";
import * as pages from "juice-base/store-common/pages/actions.js";
import * as vocabulary from "juice-base/store-common/vocabulary/actions.js";
import * as t2s from "juice-base/store-common/text2speech/actions.js";
import * as player from "juice-base/store-common/player/actions.js";
import * as search from "juice-base/store-common/search/actions.js";

import * as navigation from "./navigation/actions.js";

import * as info from "./info/actions.js";
import * as user from "./user/actions.js";
import * as lms from "./lms/actions.js";

import * as notifications from "./notifications/actions.js";
import * as tutorials from "./tutorials/actions.js";
import * as templates from "./templates/actions.js";
import * as popups from "./popups/actions.js";

import * as juices from "./juices/actions.js";
import * as juiceStories from "./juice-stories/actions.js";
import * as juicesVideos from "./juices-videos/actions.js";
import * as extraJuices from "./extra-juices/actions.js";
import * as polls from "./polls/actions.js";

import * as teacher from "./teacher/actions.js";
import * as teacherStudents from "./teacher-students/actions.js";
import * as guardian from "./guardian/actions.js";
import * as student from "./student/actions.js";
import * as studentTodo from "./student-todo/actions.js";
import * as studentAssignments from "./student-assignments/actions.js";

import * as standards from "./standards/actions.js";

import * as assignments from "./assignments/actions.js";

import * as geo from "./geo/actions.js";

import * as subscription from "./subscription/actions.js";


const clearAfterSignOut = () => ({
    type: types.CLEAR_AFTER_SIGNOUT,
});

export default {
    clearAfterSignOut,

    app,
    device,
    monitors,
    navigation,

    info,
    user,
    lms,

    t2s,
    player,

    notifications,
    tutorials,
    templates,
    popups,
    pages,
    vocabulary,

    juices,
    juiceStories,
    juicesVideos,
    extraJuices,
    polls,

    teacher,
    teacherStudents,
    guardian,
    student,
    studentTodo,
    studentAssignments,

    search,

    standards,

    assignments,

    geo,

    subscription,
};
