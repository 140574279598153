import { useState } from "react";


const getState = () => ({
    classId: null,
    statusById: {},
    teacherIds: [],
    visibleIds: [],
});

const usePopupConfirmDeleteTeacher = () => {
    const [state, setState] = useState(() => getState());

    const open = (teacherIds, classId) => {
        const firstId = teacherIds?.[0] || null;

        setState((prev) => ({
            ...prev,
            classId,
            teacherIds,
            visibleIds: [firstId],
        }));
    };

    const closeId = (params = {}, callbacks = {}) => {
        const { teacherId } = params;

        setState((prev) => {
            const teacherIds = [...prev.teacherIds].filter((tId) => tId !== teacherId);

            const visibleIds = [];

            if (teacherIds?.[0]) {
                visibleIds.push(teacherIds[0]);
            }

            const isLastClosed = visibleIds.length === 0;

            if (isLastClosed && callbacks?.onLastClosed) {
                callbacks.onLastClosed();
            }

            return {
                ...prev,
                statusById: {
                    ...prev.statusById,
                    [teacherId]: {},
                },
                teacherIds,
                visibleIds,
            };
        });
    };

    const setLoadingById = (id) => {
        setState((prev) => {
            const statusById = { ...prev.statusById };

            statusById[id] = {
                isLoading: true,
            };

            return {
                ...prev,
                statusById,
            };
        });
    };

    const setErrorById = (id, error) => {
        setState((prev) => {
            const statusById = { ...prev.statusById };

            statusById[id] = {
                error,
            };

            return {
                ...prev,
                statusById,
            };
        });
    };

    const setSuccessDeletedById = (id) => {
        setState((prev) => {
            const statusById = { ...prev.statusById };

            statusById[id] = {
                isSuccessfullyDeleted: true,
            };

            return {
                ...prev,
                statusById,
            };
        });
    };

    const clear = () => {
        setState(getState());
    };

    return {
        state,
        open,
        closeId,
        setLoadingById,
        setErrorById,
        setSuccessDeletedById,
        clear,
    };
};

export default usePopupConfirmDeleteTeacher;
