import * as globalTypes from "juice-base/store-common/types.js";
import * as types from "./types.js";


const initialState = {
    isUserLoading: false,
    isUserLoaded: false,
    session: "",
    user: {},

    isOptionsLoaded: false,
    options: {},

    isPaymentDetailsLoaded: false,
    paymentDetails: {},

    isTeacherWelcomePopupOpen: false,
};

export default (state, action) => {
    switch (action.type) {
        case types.SET_USER_LOADING: {
            return {
                ...state,
                isUserLoading: action.payload.isUserLoading,
            };
        }

        case types.SET_USER_SESSION: {
            return {
                ...state,
                session: action.payload.session,
            };
        }

        case types.SET_USER: {
            return {
                ...state,
                isUserLoading: false,
                isUserLoaded: true,
                user: action.payload.user ?? {},
            };
        }

        case types.CLEAR_USER: {
            return {
                ...state,
                isUserLoading: false,
                isUserLoaded: false,
                user: {},
            };
        }

        case types.SET_USER_OPTIONS: {
            return {
                ...state,
                isOptionsLoaded: true,
                options: action.payload.options,
            };
        }

        case types.SET_USER_OPTION: {
            const { options } = state;
            const { key, value } = action.payload;

            return {
                ...state,
                options: {
                    ...options,
                    [key]: value,
                },
            };
        }

        case types.SET_USER_EMAIL_NOTIFICATION: {
            let userSettings = {};

            if (state?.user?.settings) {
                userSettings = {
                    ...state.user.settings,
                };
            }

            return {
                ...state,
                user: {
                    ...state.user,
                    settings: {
                        ...userSettings,
                        isEmailNotificationEnabled: action.payload.isEnabled,
                    },
                },
            };
        }

        case types.SET_USER_AUTO_RENEW_SUBSCRIPTION: {
            let userSubscription = {};

            if (state?.user?.subscription) {
                userSubscription = {
                    ...state.user.subscription,
                };
            }

            return {
                ...state,
                user: {
                    ...state.user,
                    subscription: {
                        ...userSubscription,
                        isAutoRenewEnabled: action.payload.isEnabled || false,
                    },
                },
            };
        }

        case types.SET_USER_AVATAR: {
            return {
                ...state,
                user: {
                    ...state.user,
                    avatarUrl: action.payload.avatarUrl,
                },
            };
        }

        case types.SET_USER_PAYMENT_DETAILS_LOADED: {
            return {
                ...state,
                isPaymentDetailsLoaded: action.payload.isLoaded,
            };
        }

        case types.SET_USER_PAYMENT_DETAILS: {
            return {
                ...state,
                isPaymentDetailsLoaded: true,
                paymentDetails: action.payload.card,
            };
        }

        /* --- */

        case types.SET_TEACHER_WELCOME_POPUP_OPENED: {
            return {
                ...state,
                isTeacherWelcomePopupOpen: true,
            };
        }

        case types.SET_TEACHER_WELCOME_POPUP_CLOSED: {
            return {
                ...state,
                isTeacherWelcomePopupOpen: false,
            };
        }

        /* --- */

        case globalTypes.CLEAR_AFTER_SIGNOUT: {
            return { ...initialState };
        }

        /* --- */

        default: {
            return state || { ...initialState };
        }
    }
};
