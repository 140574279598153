import React, { useContext } from "react";
import { VisibilityContext } from "react-horizontal-scrolling-menu";

import classNames from "juice-base/lib/class-names.js";
import symbols from "juice-base/lib/symbols.js";

import styles from "./styles.module.css";


const Tab = (props) => {
    const visibility = useContext(VisibilityContext);

    const onClick = (evt) => {
        props.onClick(evt, visibility);
    };

    const optionClassName = classNames({
        [styles.option]: true,
        [styles.optionSelected]: props.selected,
    });

    return (
        <div
            key={props.value}
            className={optionClassName}
            onClick={onClick}
            onKeyPress={onClick}
            role="button"
            tabIndex="-1"
        >
            {(props.label || "").trim() || symbols.nbsp}
        </div>
    );
};

Tab.defaultProps = {
    value: "",
    selected: "",
    label: "",
    onClick: () => {},
};

export default Tab;
