import React from "react";

import { Formik } from "formik";

import { hasEmptyValue } from "juice-base/lib/object.js";
import v from "juice-base/lib/form-validators.js";
import classNames from "juice-base/lib/class-names.js";

import ButtonBig from "juice-base/components/button-big/index.js";
import MessageDanger from "juice-base/components/message-danger/index.js";

import Input from "juice-base/components/forms/input/index.js";
import Select from "juice-base/components/forms/select/index.js";

import styles from "./styles.module.css";


const ClassAddForm = (props) => {
    const isDisabled = (formProps) => {
        const hasErrors = Object.keys(formProps.errors).length > 0;

        return hasEmptyValue(formProps.values)
            || hasErrors
            || formProps.isSubmitting;
    };

    const validateForm = (values) => {
        const errorsClassName = v.validate(values.className, [
            v.required("Please enter class name"),
        ]);

        const errorsGrades = v.validate(values.grades, [
            v.required("Please select a default reading level"),
        ]);

        const errors = {};

        if (errorsClassName) {
            errors.className = errorsClassName;
        }

        if (errorsGrades) {
            errors.grades = errorsGrades;
        }

        return errors;
    };

    const renderFormError = (error) => {
        if (error) {
            return (
                <MessageDanger>
                    {error}
                </MessageDanger>
            );
        }

        return null;
    };

    const renderForm = (formProps) => {
        const {
            values,
            errors,
            handleSubmit,
            setFieldValue,
            setTouched,
            handleChange,
            handleBlur,
            isSubmitting,
        } = formProps;

        const formClassName = classNames({
            [styles.form]: true,
            [styles.formWithError]: errors.formError,
        });

        return (
            <form onSubmit={handleSubmit} className={formClassName}>
                <div className={styles.fields}>
                    <Input
                        name="className"
                        label="Class Name *"
                        showMaxLengthMessage
                        maxLength={25}
                        value={values.className}
                        error={v.getError(formProps, "className")}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />

                    <Select
                        name="grades"
                        label="Default Reading Level *"
                        selected={values.grades}
                        options={props.grades}
                        error={v.getError(formProps, "grades")}
                        isOptionsFour
                        onSelect={(val) => {
                            setFieldValue("grades", val);
                        }}
                        onBlur={() => {
                            setTouched({ grades: true });
                        }}
                    />
                </div>

                {renderFormError(errors.formError)}

                <div className={styles.submitButton}>
                    <ButtonBig
                        type="submit"
                        disabled={isDisabled(formProps)}
                    >
                        {isSubmitting ? "Creating..." : "Create class"}
                    </ButtonBig>
                </div>
            </form>
        );
    };

    return (
        <Formik
            initialValues={props.initialValues}
            validate={validateForm}
            onSubmit={props.onSubmit}
        >
            {renderForm}
        </Formik>
    );
};

ClassAddForm.defaultProps = {
    initialValues: {
        className: "",
        grades: "",
    },
    grades: [],

    onSubmit: () => {},
};

export default ClassAddForm;
