export default (apiURL, methods) => {
    return {
        all(params = {}) {
            // TODO: rename api
            // NOTE: action=search
            let url = `${apiURL}/juice-stories/search`;

            if (params?.apiVersion === "2") {
                // NOTE: action=search2
                url = `${apiURL}/juice-stories/search2`;
            }

            return methods.get3(url, {
                session_id: params.session,
                s: params.search,
                page: params.page,
                grades: params.grades,
                order_by: params.orderBy,
                categories: params.categories,
                standards: params.standards,
                type: params.type,
            });
        },

        categories(params = {}) {
            const url = `${apiURL}/juice-stories/search2`;

            return methods.get3(url, {
                session_id: params.session || "",
                action: "getCategories",
            });
        },

        getStandards(params = {}) {
            const url = `${apiURL}/juice-stories/search/standards`;

            return methods.get3(url, {
                session_id: params.session || "",
                state: params.state,
            });
        },
    };
};
