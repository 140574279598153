import React from "react";

import IconDanger from "juice-base/icons/danger/index.js";

import styles from "./styles.module.css";


const MessageDanger = (props) => {
    const imgTitle = "Pay attention!";

    return (
        <div className={styles.message}>
            <IconDanger
                title={imgTitle}
                isOrange
            />
            <div className={styles.text}>
                {props.children}
            </div>
        </div>
    );
};

MessageDanger.defaultProps = {
    children: null,
};

export default MessageDanger;
