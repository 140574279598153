import React from "react";

import date from "juice-base/lib/date.js";
import classNames from "juice-base/lib/class-names.js";

import IconArrowRight from "juice-base/icons/arrow-right/index.js";

import Image from "juice-base/components/image/index.js";

import AssignmentStatus from "juice-base/business/assignment-status/index.js";

import styles from "./styles.module.css";


const AssignmentCard = (props) => {
    const renderDate = () => {
        if (!props.date || !props.withDate) {
            return null;
        }

        return (
            <div className={styles.assignmentDate}>
                {date.tryFormatDate(props.date, date.formatMonthDayYearDate)}
            </div>
        );
    };

    const renderTitle = () => {
        return (
            <div>
                {props.title}
            </div>
        );
    };

    const renderCompletedOn = () => {
        if (!props.completedDate) {
            return null;
        }

        const formattedDate = date.tryFormatDate(
            props.completedDate,
            date.formatMonthDayYearDate,
        );

        return (
            <div className={styles.completedDate}>
                {`Completed on ${formattedDate}`}
            </div>
        );
    };

    const renderStatusIcon = () => {
        if (!props.status) {
            return null;
        }

        return (
            <AssignmentStatus
                className={styles.statusIcon}
                status={props.status}
            />
        );
    };

    const statusIcon = renderStatusIcon();

    const assignmentClassName = classNames({
        [styles.assignment]: true,
        [styles.assignmentWithStatusIcon]: statusIcon,
    });

    return (
        <div
            className={assignmentClassName}
            onClick={props.onClick}
            onKeyPress={props.onClick}
            tabIndex="-1"
            role="button"
        >
            <Image
                className={styles.featuredImage}
                src={props.imageSrc}
                title="Featured Image"
            />

            <div className={styles.assignmentData}>
                {renderDate()}
                {renderTitle()}
                {renderCompletedOn()}
            </div>

            {statusIcon}

            <IconArrowRight
                className={styles.arrow}
                title="Open assignment"
            />
        </div>
    );
};

AssignmentCard.defaultProps = {
    title: "",
    date: "",
    imageSrc: "",
    completedDate: "",
    status: "",
    onClick: () => { },
    withDate: true,
};

export default AssignmentCard;
