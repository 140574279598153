import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import text from "juice-base/text/index.js";

import classNames from "juice-base/lib/class-names.js";

import Theme from "juice-base/project/theme.js";
import actions from "juice-base/store/actions.js";

import RequestLoader from "juice-base/components/request-loader/index.js";
import PopupWindow from "juice-base/components/popup-window/index.js";
import ButtonBig from "juice-base/components/button-big/index.js";
import Tabs from "juice-base/components/tabs/index.js";
import TabsScrolling from "juice-base/components/tabs-scrolling/index.js";

import UploaderImage from "juice-base/components/forms/uploader-image/index.js";
import TextEditor, { TEXT_EDITOR_THEMES } from "juice-base/components/forms/text-editor/index.js";

import api from "juice-app/api.js";
import events from "juice-app/events.js";

import useSponsorsPopupHook from "./use-sponsors-popup.js";
import styles from "./styles.module.css";


const storeSelector = (state) => ({
    theme: state.app.theme.theme,
    session: state.user.session,
    teacher: state.teacher,
});

const TeacherPopupSponsors = (props) => {
    const [isImageChanged, setImageChanged] = useState(false);

    const [selectedClass, setSelectedClass] = useState(() => {
        if (props.defaultClass) {
            return props.defaultClass;
        }

        return null;
    });

    /* ------ */

    const dispatch = useDispatch();
    const store = useSelector(storeSelector);

    /* ------ */

    const onLoadSponsorsByClassId = async (classId) => {
        dispatch(actions.teacher.setTeacherSponsorsByIdLoading({
            classId,
        }));

        if (classId === "all") {
            const resAll = await api.classes.getAllSponsorsByTeacher({
                session: store.session,
            });

            let contents = [];
            let images = [];

            let error = "";

            if (resAll.ok) {
                contents = resAll.data.map((sponsor) => sponsor.sponsorContent);
                images = resAll.data.map((sponsor) => ({
                    id: sponsor.sponsorImageId,
                    url: sponsor.sponsorImageUrl,
                }));
            } else {
                error = resAll.error || text.error;
            }

            dispatch(actions.teacher.setTeacherSponsorsById({
                classId,
                data: {
                    contents,
                    images,
                },
                error,
            }));
            return;
        }

        const res = await api.classes.getClassSponsors({
            classId,
        });

        let data = null;
        let error = "";

        if (res.ok) {
            data = res.data || null;
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.teacher.setTeacherSponsorsById({
            classId,
            data,
            error,
        }));
    };

    /* ------ */

    const isAllContentsSame = (sponsor) => {
        const firstContent = sponsor.contents[0] || "";

        for (let i = 0; i < sponsor.contents.length; i += 1) {
            const sContent = sponsor.contents[i] || "";

            if (sContent !== firstContent) {
                return false;
            }
        }

        return true;
    };

    const getAllClassesSponsorText = (sponsor) => {
        if (isAllContentsSame(sponsor)) {
            return sponsor.contents[0] || "";
        }

        return "";
    };

    const getText = (sponsor) => {
        if (selectedClass === "all") {
            return getAllClassesSponsorText(sponsor);
        }

        return sponsor?.sponsorContent || "";
    };

    const isAllImagesSame = (sponsor) => {
        const firstImageUrl = sponsor?.images?.[0]?.url || "";

        for (let i = 0; i < sponsor.images.length; i += 1) {
            const sImgUrl = sponsor.images[i]?.url;

            if (sImgUrl !== firstImageUrl) {
                return false;
            }
        }

        return true;
    };

    const getImage = (sponsor) => {
        const img = {
            isLoading: sponsor?.isImageLoading || false,
            imageId: "",
            imageUrl: "",
        };

        if (sponsor.sponsorImageUrl && sponsor.sponsorImageId) {
            img.imageId = sponsor.sponsorImageId;
            img.imageUrl = sponsor.sponsorImageUrl;
        } else if (selectedClass === "all" && isAllImagesSame(sponsor)) {
            const sImg = sponsor?.images?.[0] || {};

            img.imageId = sImg.id || "";
            img.imageUrl = sImg.url || "";
        }

        return img;
    };

    const getTabs = () => {
        const tabs = (store.teacher.classes || []).map((cl) => ({
            value: cl.id,
            label: cl.title,
        }));

        if (tabs.length >= 2) {
            tabs.push({
                value: "all",
                label: "all",
            });
        }

        return tabs;
    };

    const getSponsor = (classId) => {
        const data = store.teacher.classesSponsorsById[classId];

        return {
            isLoading: data?.isLoading || false,
            isSaving: data?.isSaving || false,
            data: data?.data || null,
            error: data?.error || "",
        };
    };

    const onSave = async (sponsor) => {
        dispatch(actions.teacher.setTeacherSponsorsByIdSaving({
            classId: selectedClass,
            isSaving: true,
            error: "",
        }));

        const updateApi = selectedClass === "all"
            ? api.classes.updateAllTeacherClassesSponsor
            : api.classes.updateClassSponsors;

        const image = getImage(sponsor);

        const res = await updateApi({
            session: store.session,
            classId: selectedClass,
            sponsorContent: sponsor.sponsorContent,
            sponsorImageId: image.imageId,
        });

        let error = "";

        if (res.ok) {
            setImageChanged(false);

            onLoadSponsorsByClassId(selectedClass);
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.teacher.setTeacherSponsorsByIdSaving({
            classId: selectedClass,
            isSaving: false,
            error,
        }));
    };

    const onUploadSponsorImage = async (evt) => {
        const file = evt?.target?.files?.[0] || null;

        if (!file) {
            dispatch(actions.teacher.setTeacherSponsorsImageByIdClear({
                classId: selectedClass,
            }));

            setImageChanged(true);
            return;
        }

        events.teacher.homeUploadAnImage({
            session: store.session,
            classId: selectedClass,
        });

        dispatch(actions.teacher.setTeacherSponsorsImageByIdLoading({
            classId: selectedClass,
            isLoading: true,
        }));

        const res = await api.classes.uploadSponsorMedia({
            session: store.session,
            file,
        });

        let data = null;
        let error = "";

        if (res.ok) {
            setImageChanged(true);

            data = {
                sponsorImageUrl: res?.data?.url || "",
                sponsorImageId: res?.data?.id || "",
            };
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.teacher.setTeacherSponsorsImageById({
            classId: selectedClass,
            data,
            error,
        }));
    };

    const onSelectedClassChange = (classId) => {
        onLoadSponsorsByClassId(classId);

        setSelectedClass(classId);
        setImageChanged(false);
    };

    const onSponsorChange = (contentText, size) => {
        dispatch(actions.teacher.setTeacherSponsorsContentById({
            classId: selectedClass,
            text: contentText,
            size,
        }));
    };

    /* ------ */

    useEffect(() => {
        if (props.defaultClass) {
            onSelectedClassChange(props.defaultClass);
            return;
        }

        const tabs = getTabs();
        const classId = tabs?.[0]?.value || null;

        onSelectedClassChange(classId);
    }, []);

    /* ------ */

    const renderFileUploader = (sponsor) => {
        const image = getImage(sponsor);

        let fileUploaderWarning = null;

        if (selectedClass === "all" && isImageChanged) {
            fileUploaderWarning = (
                <div className={styles.warningText}>
                    Some announcements already have an image.
                    Saving this tab will overwrite all images.
                </div>
            );
        }

        return (
            <>
                <div className={styles.blockHeader}>
                    Customize each edition of The Juice with an image of yourself,
                    your organization, or a local sponsor. Make it your own!
                </div>
                <UploaderImage
                    isLoading={image.isLoading}
                    imageUrl={image.imageUrl}
                    onFileChange={onUploadSponsorImage}
                    imageRequiredHeight={props.imageRequiredHeight}
                    imageRequiredWidth={props.imageRequiredWidth}
                />
                {fileUploaderWarning}
            </>
        );
    };

    const renderTextEditor = (sponsor) => {
        const defaultText = getText(sponsor);

        let rewriteMessage = null;

        if (selectedClass === "all" && !isAllContentsSame(sponsor)) {
            rewriteMessage = (
                <p className={styles.rewriteMessage}>
                    Some announcements have already been written.
                    Saving this tab will overwrite all announcements.
                </p>
            );
        }

        const editorTheme = store.theme === Theme.getThemes().DARK
            ? TEXT_EDITOR_THEMES.DARK
            : TEXT_EDITOR_THEMES.LIGHT;

        return (
            <div className={styles.textEditor}>
                <div className={styles.blockHeader}>
                    Add some text
                </div>
                <div className={styles.blockDescription}>
                    <p>
                        Say hello to your students,
                        state your organization&apos;s mission or values,
                        or thank a local sponsor. Keep it short and simple!
                    </p>
                </div>
                <TextEditor
                    label=""
                    name="sponsorText"
                    defaultValue={defaultText}
                    theme={editorTheme}
                    onChange={onSponsorChange}
                />
                {rewriteMessage}
            </div>
        );
    };

    /* ------ */

    const renderTabs = () => {
        const tabs = getTabs();

        if (props.isMobile || tabs.length > 4) {
            return (
                <div className={styles.tabsScrolling}>
                    <TabsScrolling
                        selected={selectedClass}
                        tabs={tabs}
                        onChange={onSelectedClassChange}
                    />
                </div>
            );
        }

        return (
            <Tabs
                onlyTabs
                tabs={tabs}
                selectedTab={selectedClass}
                onChange={onSelectedClassChange}
            />
        );
    };

    const renderMaxContentMessage = (params) => {
        const { isMaxLengthReached, textSize } = params;

        const maxLengthClassName = classNames({
            [styles.maxLengthMessage]: isMaxLengthReached,
        });

        return (
            <div className={maxLengthClassName}>
                {`${textSize}/${props.maxTextCharacters} characters`}
            </div>
        );
    };

    const renderError = (params) => {
        if (params.error) {
            return (
                <div className={styles.errorMessage}>
                    {params.error}
                </div>
            );
        }

        return <div />;
    };

    const renderSaveButton = (params) => {
        const {
            isMaxLengthReached,
            isSaving,
            sponsorData,
            error,
        } = params;

        return (
            <ButtonBig
                onClick={() => {
                    onSave(sponsorData);
                }}
                disabled={isMaxLengthReached || isSaving || error}
            >
                {isSaving ? "Saving..." : "Save"}
            </ButtonBig>
        );
    };

    const renderControl = (sponsor) => {
        const textSize = sponsor?.data?.sponsorContentSize || 0;
        const error = sponsor?.error || "";
        const sponsorData = sponsor?.data || null;

        const isSaving = sponsor?.isSaving || false;
        const isMaxLengthReached = textSize > props.maxTextCharacters;

        return (
            <div className={styles.controls}>
                {renderMaxContentMessage({
                    textSize,
                    isMaxLengthReached,
                })}
                {renderError({
                    error,
                })}
                {renderSaveButton({
                    isSaving,
                    isMaxLengthReached,
                    sponsorData,
                    error,
                })}
            </div>
        );
    };

    /* ------ */

    const renderContent = () => {
        const sponsor = getSponsor(selectedClass);

        let content = null;

        if (sponsor.isLoading || !sponsor.data) {
            content = (
                <RequestLoader />
            );
        } else {
            content = (
                <div className={styles.contentFields}>
                    {renderFileUploader(sponsor.data)}
                    {renderTextEditor(sponsor.data)}
                    {renderControl(sponsor)}
                </div>
            );
        }

        return (
            <div className={styles.content}>
                {renderTabs()}
                {content}
            </div>
        );
    };

    // TODO: showConfirmOnClose
    return (
        <PopupWindow
            onClose={props.onClose}
            title="Upload an image"
        >
            {renderContent()}
        </PopupWindow>
    );
};

TeacherPopupSponsors.defaultProps = {
    isMobile: false,

    defaultClass: null,

    maxTextCharacters: 125,
    imageRequiredWidth: 320,
    imageRequiredHeight: 80,

    onClose: () => { },
};

export const useSponsorsPopup = useSponsorsPopupHook;
export default TeacherPopupSponsors;
