import React from "react";

import classNames from "juice-base/lib/class-names.js";

import DailyJuice from "juice-base/project/daily-juice.js";

import styles from "./styles.module.css";


const JuiceStatusV2 = (props) => {
    const { status } = props;

    if (!status) {
        return null;
    }

    const s = DailyJuice.getStatusByName(status);

    if (!s) {
        return null;
    }

    const renderElement = () => {
        const style = {
            backgroundColor: s.color,
        };

        let isRounded = false;

        if (DailyJuice.isCompleteStatus(s.name) || DailyJuice.isInProgressStatus(s.name)) {
            isRounded = true;
        }

        const statusClassName = classNames({
            [styles.statusElement]: true,
            [styles.statusElementRounded]: isRounded,
        });

        return (
            <div
                className={statusClassName}
                style={style}
            />
        );
    };

    const renderLabel = () => {
        if (!props.withLabel) {
            return null;
        }

        return (
            <div>
                {s.label}
            </div>
        );
    };

    const statusClassName = classNames({
        [styles.status]: true,
        [styles.statusWithLabel]: props.withLabel,
    });

    return (
        <div className={statusClassName}>
            {renderElement()}
            {renderLabel()}
        </div>
    );
};

JuiceStatusV2.defaultProps = {
    status: "",
    withLabel: false,
};

export default JuiceStatusV2;
