// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".uV92DyneXbILP2EK8ZQw {\n    padding: 2rem 0 2rem 0;\n}\n\n.fzk0MmYT3vUsMgJzXKTb {\n    height: 100%;\n    display: grid;\n    grid-template-rows: auto min-content;\n}\n\n.sewHZ26UTf91LcQVa88w {\n    padding: 1rem;\n    font-size: 1.8rem;\n    font-weight: 600;\n    text-align: center;\n}\n\n.yZpXb7KEECfvUqZEMM9D {\n    padding: 1rem;\n    display: grid;\n}", "",{"version":3,"sources":["webpack://./app/containers/teacher-popup-lms/styles.module.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;AAC1B;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,oCAAoC;AACxC;;AAEA;IACI,aAAa;IACb,iBAAiB;IACjB,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,aAAa;AACjB","sourcesContent":[".content {\n    padding: 2rem 0 2rem 0;\n}\n\n.lmsMessage {\n    height: 100%;\n    display: grid;\n    grid-template-rows: auto min-content;\n}\n\n.lmsError {\n    padding: 1rem;\n    font-size: 1.8rem;\n    font-weight: 600;\n    text-align: center;\n}\n\n.lmsControl {\n    padding: 1rem;\n    display: grid;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "uV92DyneXbILP2EK8ZQw",
	"lmsMessage": "fzk0MmYT3vUsMgJzXKTb",
	"lmsError": "sewHZ26UTf91LcQVa88w",
	"lmsControl": "yZpXb7KEECfvUqZEMM9D"
};
export default ___CSS_LOADER_EXPORT___;
