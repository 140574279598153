import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import RequestLoader from "juice-base/components/request-loader/index.js";
import LayoutContent from "juice-base/components/layout-content/index.js";

import actions from "juice-base/store/actions.js";

import api from "juice-app/api.js";

import styles from "./styles.module.css";


const GuardianDailyJuices = () => {
    const [juicesState, setJuices] = useState({
        isLoaded: false,
        error: null,
    });

    const [guardianState, setGuardianState] = useState({
        isLoaded: false,
        students: [],
    });

    const {
        session,
        guardian,
    } = useSelector((state) => ({
        session: state.user.session,
        guardian: state.guardian,
    }));

    const navigate = useNavigate();

    const dispatch = useDispatch();

    useEffect(() => {
        if (!guardianState.isLoaded) {
            api.students.getStudentsByGuardian({
                session,
            }).then((res) => {
                const students = res.students || [];

                let selectedStudentId = guardian.selectedStudent;

                if (!selectedStudentId) {
                    selectedStudentId = students[0] ? students[0].id || null : null;
                }

                dispatch(actions.guardian.setSelectedStudent({
                    selectedStudent: selectedStudentId,
                }));

                setGuardianState({
                    isLoaded: true,
                    students,
                });
            });
        } else {
            api.dailyJuices.getJuicesByPage({
                session,
                studentId: guardian.selectedStudent,
            }).then((res) => {
                if (res.ok && res.juices[0]) {
                    navigate(`/daily-juices/${res.juices[0].id}`);
                } else {
                    setJuices({
                        isLoaded: true,
                        error: res.error,
                    });
                }
            });
        }
    }, [guardianState]);

    if (!juicesState.isLoaded) {
        return (
            <RequestLoader />
        );
    }

    let juiceError = juicesState.error;

    if (!juicesState.error) {
        juiceError = "Juices not found";
    }

    return (
        <LayoutContent>
            <div className={styles.index}>
                <div className={styles.errorMessage}>
                    {juiceError}
                </div>
            </div>
        </LayoutContent>
    );
};

export default GuardianDailyJuices;
