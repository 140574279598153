import React from "react";

import IconWrapper from "juice-base/icons/wrapper/index.js";


const IconNotepadX = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={iconProps.className}
                >
                    {iconProps.title}
                    <path
                        fill="currentColor"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M20 4C20 2.89543 19.1046 2 18 2H6C4.89543 2 4 2.89543 4 4V21C4 22.1046 4.89543 23 6 23H18C19.1046 23 20 22.1046 20 21V4ZM18 4L18 21H6L6 4L18 4ZM12 3.7515C12.415 3.7515 12.7515 3.41504 12.7515 3C12.7515 2.58496 12.415 2.2485 12 2.2485C11.585 2.2485 11.2485 2.58496 11.2485 3C11.2485 3.41504 11.585 3.7515 12 3.7515Z"
                    />
                    <path
                        fill="currentColor"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M16 2V6H8V2H10.2676C10.6134 1.4022 11.2597 1 12 1C12.7403 1 13.3866 1.4022 13.7324 2H16ZM12 3.7515C12.415 3.7515 12.7515 3.41504 12.7515 3C12.7515 2.58496 12.415 2.2485 12 2.2485C11.585 2.2485 11.2485 2.58496 11.2485 3C11.2485 3.41504 11.585 3.7515 12 3.7515Z"
                    />
                    <path
                        stroke="currentColor"
                        strokeWidth="2"
                        d="M8 9L16 17M8 17L16 9"
                    />
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconNotepadX;
