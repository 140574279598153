// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BQSAD6p3h5ZRSj85PFL3 {\n    display: grid;\n    justify-content: center;\n}\n\n.f_EPrjvEUyisgVmZyVKw {\n    max-width: 90rem;\n\n    word-break: break-word;\n}\n", "",{"version":3,"sources":["webpack://./app/views/page/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,gBAAgB;;IAEhB,sBAAsB;AAC1B","sourcesContent":[".index {\n    display: grid;\n    justify-content: center;\n}\n\n.content {\n    max-width: 90rem;\n\n    word-break: break-word;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"index": "BQSAD6p3h5ZRSj85PFL3",
	"content": "f_EPrjvEUyisgVmZyVKw"
};
export default ___CSS_LOADER_EXPORT___;
