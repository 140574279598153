import React, { useEffect, useState } from "react";

import Students from "juice-base/project/students.js";

import date from "juice-base/lib/date.js";
import classNames from "juice-base/lib/class-names.js";

import IconArrowBold from "juice-base/icons/arrow-bold/index.js";
import IconArrowsSort from "juice-base/icons/arrows-sort/index.js";
import IconCaret from "juice-base/icons/caret/index.js";
import IconDownload from "juice-base/icons/download/index.js";
import IconListLayout from "juice-base/icons/list-layout/index.js";
import IconTrashWithLinesFull from "juice-base/icons/trash-with-lines-full/index.js";
import IconUserAdd from "juice-base/icons/user-add/index.js";
import IconUserInRing from "juice-base/icons/user-in-ring/index.js";

import IconClickable from "juice-base/components/icon-clickable/index.js";
import RequestLoader from "juice-base/components/request-loader/index.js";
import Collapse from "juice-base/components/collapse-section/index.js";
import MenuHorizontalScrolling from "juice-base/components/menu-horizontal-scrolling/index.js";
import ButtonToggler from "juice-base/components/button-toggler/index.js";
import ButtonCircle from "juice-base/components/button-circle/index.js";
import PopupDateRangeCustom from "juice-base/components/popup-date-range-custom/index.js";
import Checkbox from "juice-base/components/forms/checkbox/index.js";

import JuiceStatus from "juice-base/business/juice-status/index.js";

import TableMenuButton from "./table-menu-button.js";
import TableRowMenuButton from "./table-row-menu-button.js";
import styles from "./styles.module.css";


const getRowStyles = (isOverview) => {
    const rowStyle = {
        gridTemplateColumns: "5% 18% 20% 16% 13% 13% 10% 5%",
    };

    if (isOverview) {
        rowStyle.gridTemplateColumns = "5% 25% 25% 20% 20% 5%";
    }

    return rowStyle;
};

const TableStudents = (props) => {
    const [tableSelectedUsers, setTableSelectedUsers] = useState([]);

    const [cardsExpended, setCardsExpended] = useState(false);

    const [isVisibleCustomRangePopup, setVisibleCustomRangePopup] = useState(false);

    const [isAllRowsCheckboxSelected, setIsAllRowsCheckboxSelected] = useState(false);

    const isSortedByName = props.sortValue.indexOf("name") !== -1;
    const isSortedByScore = props.sortValue.indexOf("score") !== -1;

    const rowStyles = getRowStyles(props.isOverview);

    const { StudentsSortByValues } = Students;

    /* --- */

    useEffect(() => {
        setIsAllRowsCheckboxSelected(false);
        setTableSelectedUsers([]);
    }, [props.data]);

    /* --- */

    const onChangeSortValueByNameColumn = () => {
        let value = StudentsSortByValues.nameATOZ;

        if (props.sortValue === StudentsSortByValues.nameATOZ) {
            value = StudentsSortByValues.nameZTOA;
        }

        props.onChangeSortValue(value);
    };

    const onChangeSortValueByQuizScoreColumn = () => {
        let value = StudentsSortByValues.scoreHighToLow;

        if (props.sortValue === StudentsSortByValues.scoreHighToLow) {
            value = StudentsSortByValues.scoreLowToHigh;
        }

        props.onChangeSortValue(value);
    };

    const onExtendOrCollapseCards = () => {
        setCardsExpended((prev) => !prev);
    };

    const onChangeAllRowsCheckox = () => {
        if (isAllRowsCheckboxSelected) {
            setTableSelectedUsers([]);
        } else {
            setTableSelectedUsers(props.data);
        }

        setIsAllRowsCheckboxSelected((prev) => !prev);
    };

    const getColumnsTitles = () => {
        const columnNameArrowClassName = classNames({
            [styles.columnNameArrow]: true,
            [styles.columnNameArrowUp]: props.sortValue === StudentsSortByValues.nameZTOA,
        });

        const columnQuizScoreArrowClassName = classNames({
            [styles.columnQuizScoreArrow]: true,
            [styles.columnQuizScoreArrowUp]:
                props.sortValue === StudentsSortByValues.scoreHighToLow,
        });

        let name = "Name";
        let quizScore = props.isOverview ? "Average quiz Score" : "Quiz Score";

        if (!props.isCards) {
            name = (
                <div
                    className={styles.columnName}
                    data-comment="table-students-sort-by-name"
                    onClick={onChangeSortValueByNameColumn}
                    onKeyPress={onChangeSortValueByNameColumn}
                    role="button"
                    tabIndex="-1"
                >
                    <div>
                        Name
                    </div>
                    <div className={columnNameArrowClassName}>
                        <IconArrowBold
                            title="Sort by name"
                            isBlack
                        />
                    </div>
                </div>
            );

            quizScore = (
                <div
                    className={styles.columnQuizScore}
                    data-comment="table-students-sort-by-score"
                    onClick={onChangeSortValueByQuizScoreColumn}
                    onKeyPress={onChangeSortValueByQuizScoreColumn}
                    role="button"
                    tabIndex="-1"
                >
                    <div>
                        {props.isOverview ? "Average quiz Score" : "Quiz Score"}
                    </div>
                    <div className={columnQuizScoreArrowClassName}>
                        <IconArrowBold
                            title="Sort by quiz status"
                            isBlack
                        />
                    </div>
                </div>
            );
        }

        const allRowsCheckbox = (
            <Checkbox
                key="allRowsCheckbox"
                name="allRowsCheckbox"
                checked={isAllRowsCheckboxSelected}
                dataComment="table-students-select-all"
                onChange={onChangeAllRowsCheckox}
            />
        );

        let titles = [
            allRowsCheckbox,
            name,
            "Username",
            "Quiz Status",
            quizScore,
            "Extra Juice",
            "Video",
            "",
        ];

        if (props.isOverview) {
            titles = [
                allRowsCheckbox,
                name,
                "Username",
                "Completion status",
                quizScore,
                "",
            ];
        }

        return titles;
    };

    /* --- */

    const isSelectedUserInTable = (studentId) => {
        for (let i = 0; i < tableSelectedUsers.length; i += 1) {
            if (tableSelectedUsers[i].id === studentId) {
                return true;
            }
        }

        return false;
    };

    const onAddStudent = () => {
        if (tableSelectedUsers.length > 0) {
            return;
        }

        props.onAddStudent();
    };

    const onRemoveSelectedUsers = () => {
        if (tableSelectedUsers.length === 0) {
            return;
        }

        props.onRemoveStudents(tableSelectedUsers);
    };

    const onSelectStudentInTable = (student) => {
        const isSelectedUser = isSelectedUserInTable(student.id);

        let newStudents = [];

        if (isSelectedUser) {
            newStudents = tableSelectedUsers.filter((s) => s.id !== student.id);
        } else {
            newStudents = tableSelectedUsers.concat(student);
        }

        setIsAllRowsCheckboxSelected(newStudents.length === props.data.length);
        setTableSelectedUsers(newStudents);
    };

    const onSelectCustomRange = (values) => {
        setVisibleCustomRangePopup(false);

        props.onSelectCustomRange(values);
    };

    const isTableReady = () => {
        if (props.isLoading || props.error) {
            return false;
        }

        if (props.data.length === 0) {
            return false;
        }

        return true;
    };

    /* --- */

    const renderTableSortToggler = () => {
        const sortByValues = [
            { value: "name", label: "Name" },
            { value: "score", label: "Score" },
        ];

        const selectedValue = isSortedByName ? "name" : "score";

        const sortTogglerClassName = classNames({
            [styles.sortToggler]: true,
            [styles.sortTogglerMobile]: props.isCards,
        });

        return (
            <div
                className={sortTogglerClassName}
                data-comment="table-students-sort-toggler"
            >
                <ButtonToggler
                    small
                    buttons={sortByValues}
                    selected={selectedValue}
                    onSelect={(value) => {
                        if (value === "name") {
                            props.onChangeSortValue(StudentsSortByValues.nameATOZ);
                        } else {
                            props.onChangeSortValue(StudentsSortByValues.scoreHighToLow);
                        }
                    }}
                />
            </div>
        );
    };

    const renderTableControlButton = (params) => {
        const buttonClassName = classNames({
            [styles.tableControlButton]: true,
            [params.buttonClassName]: params.buttonClassName,
        });

        let tooltip = null;

        if (params?.tooltip?.message) {
            tooltip = params.tooltip;
        }

        return (
            <div
                className={buttonClassName}
                data-comment={params.dataComment}
            >
                <ButtonCircle
                    icon={params.icon}
                    tooltip={tooltip}
                    onClick={params.onClick}
                    disabled={params.disabled}
                    isMobile={props.isCards}
                />
            </div>
        );
    };

    const renderMobileButtonMenu = () => {
        return (
            <div className={styles.menuButtonsMobile}>
                <TableMenuButton
                    isDisabledDownload={props.data.length === 0}
                    isDisabledRemove={tableSelectedUsers.length === 0}
                    onDownloadClassStudents={props.onDownloadClassStudents}
                    onAddStudent={onAddStudent}
                    onRemoveSelectedUsers={onRemoveSelectedUsers}
                />
            </div>
        );
    };

    /* ----- */

    const renderTableControlsButtonsMobile = () => {
        const sortButton = renderTableControlButton({
            dataComment: "table-students-sort-by-name",
            buttonClassName: styles.sortByNameButtonMobile,
            icon: <IconArrowsSort isBlack />,
            onClick: () => {
                if (isSortedByName) {
                    onChangeSortValueByNameColumn();
                } else if (isSortedByScore) {
                    onChangeSortValueByQuizScoreColumn();
                }
            },
        });

        const expandButton = renderTableControlButton({
            buttonClassName: styles.expandOrCollapseButton,
            icon: <IconListLayout isGrey />,
            onClick: onExtendOrCollapseCards,
        });

        const menuButton = renderMobileButtonMenu();

        return (
            <>
                {sortButton}
                {expandButton}
                {menuButton}
            </>
        );
    };

    const renderTableControlsButtonsDesktop = () => {
        const downloadButton = renderTableControlButton({
            disabled: props.data.length === 0,
            buttonClassName: styles.downloadButton,
            icon: <IconDownload isSky />,
            tooltip: {
                message: "Download Report",
            },
            onClick: props.onDownloadClassStudents,
        });

        let addButton = null;

        if (!props.hideAddStudentButton) {
            addButton = renderTableControlButton({
                dataComment: "table-students-add-student",
                buttonClassName: styles.addStudentButton,
                icon: <IconUserAdd isSky />,
                tooltip: {
                    message: "Add Student",
                },
                onClick: onAddStudent,
            });
        }

        const controls = renderTableControlButton({
            disabled: tableSelectedUsers.length === 0,
            dataComment: "table-students-remove-students",
            buttonClassName: styles.deleteStudentsButton,
            icon: <IconTrashWithLinesFull isSky />,
            tooltip: {
                message: "Delete Students",
                align: "to-left",
            },
            onClick: onRemoveSelectedUsers,
        });

        return (
            <>
                {downloadButton}
                {addButton}
                {controls}
            </>
        );
    };

    const renderTableControlsButtons = () => {
        let controls = null;

        if (props.isCards) {
            controls = renderTableControlsButtonsMobile();
        } else {
            controls = renderTableControlsButtonsDesktop();
        }

        const controlsClassName = classNames({
            [styles.tableControlsButtons]: true,
            [styles.tableControlsButtonsMobile]: props.isCards,
        });

        return (
            <div className={controlsClassName}>
                {controls}
            </div>
        );
    };

    /* ----- */

    const renderTableRanges = () => {
        const dates = [
            date.DATES.today,
            date.DATES.yesterday,
            date.DATES.thisWeek,
            date.DATES.lastWeek,
            date.DATES.thisMonth,
            date.DATES.past3Months,
            {
                value: "custom",
                label: "Custom",
            },
        ];

        return (
            <div
                data-comment="table-students-date-range"
                className={styles.dateRange}
            >
                <MenuHorizontalScrolling
                    items={dates}
                    selected={props.dateRange.selectedRange}
                    onSelect={(value) => {
                        if (value === "custom") {
                            setVisibleCustomRangePopup(true);
                        } else {
                            props.onChangeDateRange(value);
                        }
                    }}
                />
            </div>
        );
    };

    const renderTableControls = () => {
        if (props.isLoading) {
            return null;
        }

        if (props.isCards) {
            return (
                <div className={styles.mobileControls}>
                    <div className={styles.mobileControlMenuRanges}>
                        {renderTableRanges()}
                    </div>
                    <div className={styles.mobileControlsTogglerWithButtons}>
                        {renderTableSortToggler()}
                        {renderTableControlsButtons()}
                    </div>
                </div>
            );
        }

        return (
            <div className={styles.desktopControls}>
                {renderTableRanges()}
                {renderTableSortToggler()}
                {renderTableControlsButtons()}
            </div>
        );
    };

    /* --- */

    const renderColumnTitle = () => {
        const columnsTitles = getColumnsTitles();

        return columnsTitles.map((title) => (
            <div>{title}</div>
        ));
    };

    /* --- */

    const renderTableRowMenuButton = (student) => {
        return (
            <TableRowMenuButton
                onResetStudentPassword={() => {
                    props.onResetStudentPassword({
                        id: student.id,
                        hasEmail: student.email,
                    });
                }}
                onRemoveStudent={() => {
                    props.onRemoveStudent(student.id);
                }}
            />
        );
    };

    const renderSelectStudentControls = (student) => {
        const isSelectedUser = isSelectedUserInTable(student.id);

        const checkbox = (
            <Checkbox
                key={`studentCheckbox-${student.id}`}
                name={`studentCheckbox-${student.id}`}
                checked={isSelectedUser}
                onChange={() => {
                    onSelectStudentInTable(student);
                }}
            />
        );

        if (props.isCards) {
            return (
                <div className={styles.studentCardMobileControls}>
                    {checkbox}
                    <IconClickable
                        className={styles.profileIcon}
                        onClick={() => {
                            props.onOpenStudentPopup(student.id);
                        }}
                    >
                        <IconUserInRing
                            title="Open profile"
                            isBlack
                        />
                    </IconClickable>
                </div>
            );
        }

        return checkbox;
    };

    const renderStudentName = (student) => {
        return (
            <div
                onClick={() => {
                    props.onOpenStudentPopup(student.id);
                }}
                onKeyPress={() => {
                    props.onOpenStudentPopup(student.id);
                }}
                className={styles.studentClickableData}
                role="button"
                tabIndex="-1"
                data-comment="student-table-student-name"
            >
                {student.fullName}
            </div>
        );
    };

    const renderStudentUsername = (student) => {
        return (
            <div>{student.userName}</div>
        );
    };

    const renderTableRows = () => {
        return props.data.map((student) => {
            const menuButton = renderTableRowMenuButton(student);
            const selectStudentControls = renderSelectStudentControls(student);
            const selectedStudentName = renderStudentName(student);
            const selectedStudentUsername = renderStudentUsername(student);

            if (!props.isOverview) {
                const quizStatus = (
                    <JuiceStatus
                        statusId={student.juiceStatusId}
                    />
                );

                const extraJuiceIcons = (student.extraJuices || []).map((ej) => (
                    <JuiceStatus
                        statusId={ej.statusId}
                    />
                ));

                const lifeHackIcons = (student.lifeHacks || []).map((hack) => (
                    <JuiceStatus
                        statusId={hack.statusId}
                    />
                ));

                let quizScore = "-";

                if (student.juiceStatusId !== -1 && student.quizScore !== -1) {
                    quizScore = `${student.quizScore}%`;
                }

                return [
                    selectStudentControls,
                    selectedStudentName,
                    selectedStudentUsername,
                    quizStatus,
                    quizScore,
                    extraJuiceIcons,
                    lifeHackIcons,
                    menuButton,
                ];
            }

            const completionStatus = (
                <JuiceStatus
                    statusId={student.completionStatusId}
                />
            );

            return [
                selectStudentControls,
                selectedStudentName,
                selectedStudentUsername,
                completionStatus,
                `${student.averageQuizScore}%`,
                menuButton,
            ];
        });
    };

    const renderEmptyTable = () => {
        return (
            <div className={styles.emptyTableContainer}>
                <div className={styles.emptyTableLabel}>
                    Hmm. Looks like there&apos;s no information to display, yet.
                    Add some students to get started.
                </div>
                <div
                    className={styles.emptyTableButton}
                    onClick={onAddStudent}
                    onKeyPress={onAddStudent}
                    tabIndex="-1"
                    role="button"
                >
                    Add students
                </div>
            </div>
        );
    };

    const renderTableStatus = () => {
        if (props.isLoading) {
            return (
                <div className={styles.errorRow}>
                    <RequestLoader />
                </div>
            );
        }

        if (props.error) {
            return (
                <div className={styles.errorRow}>
                    {props.error}
                </div>
            );
        }

        if (props.data.length === 0) {
            return renderEmptyTable();
        }

        return null;
    };

    const renderTableContent = () => {
        if (!isTableReady()) {
            return renderTableStatus();
        }

        const tableRows = renderTableRows();

        return tableRows.map((row, index) => {
            const rowClassName = classNames({
                [styles.oneRow]: true,
                [styles.row]: true,
                [styles.disabledRow]: props.disabledRowsIds.indexOf(index) !== -1,
            });

            return (
                <div
                    style={rowStyles}
                    className={rowClassName}
                >
                    {row.map((rowItem) => (
                        <div className={styles.rowItem}>
                            {rowItem}
                        </div>
                    ))}
                </div>
            );
        });
    };

    const renderCards = () => {
        if (!isTableReady()) {
            return renderTableStatus();
        }

        const tableRows = renderTableRows();

        const collapseExtendButton = (
            <IconCaret
                className={styles.caretDown}
                title="Extend info"
            />
        );

        const collapseCollapseButton = (
            <IconCaret
                className={styles.caretDown}
                title="Collapse info"
            />
        );

        const columnsTitles = getColumnsTitles();

        return tableRows.map((row) => {
            const cardRows = [];

            let userFullName = "";
            let userControls = null;

            columnsTitles.forEach((title, index) => {
                const rowClassName = classNames({
                    [styles.rowIcons]: title === "Extra Juice" || title === "Video",
                });

                if (index === 1) {
                    userFullName = row[index];
                    userControls = { ...row[0] };
                }

                if (title && index !== 0) {
                    cardRows.push(
                        <div className={styles.cardRow}>
                            <div className={styles.cardTitle}>
                                {title}
                            </div>
                            <div className={rowClassName}>
                                {row[index]}
                            </div>
                        </div>,
                    );
                }
            });

            const cardTitle = (
                <div className={styles.mobileCardCollapsed}>
                    <div className={styles.studentFullName}>
                        {userFullName}
                    </div>
                    <div>{userControls}</div>
                </div>
            );

            return (
                <Collapse
                    isExtended={cardsExpended}
                    title={cardTitle}
                    collapseClassName={styles.cardCollapse}
                    extendedContentClassName={styles.cardExtended}
                    extendWithButton
                    extendButton={collapseExtendButton}
                    collapseButton={collapseCollapseButton}
                >
                    <div className={styles.card}>
                        {cardRows}
                    </div>
                </Collapse>
            );
        });
    };

    const renderRowsOrCards = () => {
        if (props.isCards) {
            return renderCards();
        }

        const columnClassName = classNames({
            [styles.oneRow]: true,
            [styles.tableColumns]: true,
        });

        return (
            <div className={styles.tableRows}>
                <div
                    style={rowStyles}
                    className={columnClassName}
                >
                    {renderColumnTitle()}
                </div>
                {renderTableContent()}
            </div>
        );
    };

    const renderCustomRangePopup = () => {
        if (!isVisibleCustomRangePopup) {
            return null;
        }

        return (
            <PopupDateRangeCustom
                calendarDates={props.calendarDates}
                dateRange={props.dateRange}
                onApply={onSelectCustomRange}
                onClose={() => {
                    setVisibleCustomRangePopup(false);
                }}
            />
        );
    };

    const settingsClassName = classNames({
        [styles.settings]: true,
        [styles.cardsSettings]: props.isCards,
    });

    const controlsClassName = classNames({
        [styles.controls]: true,
        [styles.controlsCards]: props.isCards,
    });

    return (
        <>
            {renderCustomRangePopup()}

            <div className={styles.table}>
                <div className={settingsClassName}>
                    <div className={styles.title}>
                        {props.title}
                    </div>
                    <div className={controlsClassName}>
                        {renderTableControls()}
                    </div>
                </div>
                {renderRowsOrCards()}
            </div>
        </>
    );
};

TableStudents.defaultProps = {
    title: null,

    sortValue: "",

    dateRange: {},
    calendarDates: {},

    data: [],
    disabledRowsIds: [],

    error: null,

    hideAddStudentButton: false,
    isCards: false,
    isLoading: false,
    isOverview: false,

    onChangeDateRange: () => { },
    onSelectCustomRange: () => { },

    onChangeSortValue: () => { },

    onOpenStudentPopup: () => { },

    onDownloadClassStudents: () => { },

    onAddStudent: () => { },
    onRemoveStudent: () => { },
    onRemoveStudents: () => { },
    onResetStudentPassword: () => { },
};

export default TableStudents;
