import React from "react";
import { Route, Navigate } from "react-router-dom";

import settings from "juice-app/settings.js";

import Index from "juice-app/views/index/index.js";
import NotFound from "juice-app/views/not-found/index.js";

import UserSignIn from "juice-app/views/user/sign-in/index.js";
import UserSignInLMS from "juice-app/views/user/sign-in-lms/index.js";

import UserSignUp from "juice-app/views/user/sign-up/index.js";
import UserSignUpTrialRoles from "juice-app/views/user/sign-up-trial-roles/index.js";
import UserSignUpTrial from "juice-app/views/user/sign-up-trial/index.js";
import UserPasswordForgot from "juice-app/views/user/password-forgot/index.js";
import UserPasswordChange from "juice-app/views/user/password-change/index.js";
import UserProfile from "juice-app/views/user/profile/index.js";

import Purchase from "juice-app/views/purchase/index.js";

import DailyJuiceStory from "juice-app/views/daily-juice-story/index.js";

import DailyJuice from "juice-app/views/daily-juice/index.js";
import DailyJuices from "juice-app/views/daily-juices/index.js";

import DailyJuicesTeacher from "juice-app/views/daily-juices-teacher/index.js";

import AssignmentStory from "juice-app/views/assignment-story/index.js";
import Assignment from "juice-app/views/assignment/index.js";
import Assignments from "juice-app/views/assignments/index.js";

import Class from "juice-app/views/class/index.js";
import Vocabulary from "juice-app/views/vocabulary/index.js";
import VocabularyWord from "juice-app/views/vocabulary-word/index.js";

import Dashboard from "juice-app/views/dashboard/index.js";

import Student from "juice-app/views/student/index.js";

import Search from "juice-app/views/search/index.js";

import Story from "juice-app/views/story/index.js";
import ExtraJuice from "juice-app/views/extra-juice/index.js";

import Subscribe from "juice-app/views/subscribe/index.js";
import SubscribeByPlan from "juice-app/views/subscribe-by-plan/index.js";

import Page from "juice-app/views/page/index.js";

import Debug from "juice-app/views/debug/index.js";

import Unsubscribe from "juice-app/views/unsubscribe/index.js";

import studentRoutes from "./student.js";


const classViewPath = settings.features.ASSIGNMENTS
    ? "class/:dateFrom/:dateToOrLoadType/:view"
    : "class/:dateFrom/:dateTo";

export default [
    <Route index element={<Index />} />,

    // NOTE: purchase and sign up
    <Route path="purchase" element={<Purchase />} />,
    <Route path="purchase/:referralCode" element={<Purchase />} />,
    <Route path="purchase/:referralCode/:role" element={<Purchase />} />,

    // NOTE: roles sign up via referral code
    <Route path="sign-up/trial/roles/:referralCode" element={<UserSignUpTrialRoles />} />,
    <Route path="sign-up/trial/roles" element={<UserSignUpTrialRoles />} />,
    <Route path="sign-up/trial/:role/:referralCode" element={<UserSignUpTrial />} />,
    <Route path="sign-up/trial" element={<UserSignUpTrial />} />,

    // NOTE: student sign up by class code
    <Route path="user/sign-up/:code" element={<UserSignUp />} />,
    <Route path="user/sign-up" element={<UserSignUp />} />,

    <Route path="user/login" element={<Navigate to="/user/sign-in" replace />} />,
    <Route path="user/sign-in" element={<Navigate to="/sign-in" />} />,
    <Route path="user/forgot-password" element={<Navigate to="/forgot-password" />} />,

    <Route path="sign-in" element={<UserSignIn />} />,
    <Route path="sign-in/lms" element={<UserSignInLMS />} />,
    <Route path="forgot-password" element={<UserPasswordForgot />} />,

    <Route path="user/set-password/:secretKey" element={<UserSignIn />} />,
    <Route path="user/reset-password/:secretKey" element={<UserSignIn />} />,

    // NOTE: teacher subscribes to plan
    <Route path="subscribe/:planSlug" element={<SubscribeByPlan />} />,
    <Route path="subscribe" element={<Subscribe />} />,

    // TODO: change aside nav to open new profile
    <Route path="user/profile" element={<UserProfile />} />,
    <Route path="user/change-password" element={<UserPasswordChange />} />,

    <Route path="assignments/:groupCode/:storyId" element={<AssignmentStory />} />,
    <Route path="assignments/:groupCode" element={<Assignment />} />,
    <Route path="assignments" element={<Assignments />} />,

    <Route path="daily-juices/:juiceId/:storyId" element={<DailyJuiceStory />} />,
    <Route path="daily-juices/:juiceId" element={<DailyJuice />} />,
    <Route path="daily-juices" element={<DailyJuices />} />,

    <Route path="teacher-daily-juices/:juiceId" element={<DailyJuicesTeacher />} />,
    <Route path="teacher-daily-juices" element={<DailyJuicesTeacher />} />,

    <Route path={`daily-juice-preview-${settings.previewCode}/:juiceId/:storyId`} element={<DailyJuiceStory />} />,
    <Route path={`daily-juice-preview-${settings.previewCode}/:juiceId`} element={<DailyJuice />} />,

    <Route path="dashboard/:tab/:dateFrom/:dateTo/:categories/:standardType" element={<Dashboard />} />,
    <Route path="dashboard/:tab" element={<Dashboard />} />,
    <Route path="dashboard" element={<Dashboard />} />,

    <Route path="story/:storyId" element={<Story />} />,
    <Route path="extra-juice/:extraJuiceId" element={<ExtraJuice />} />,

    <Route path={`${classViewPath}/*`} element={<Class />}>
        {studentRoutes}
    </Route>,
    <Route path={classViewPath} element={<Class />}>
        {studentRoutes}
    </Route>,
    <Route path="class" element={<Class />} />,

    <Route path="search" element={<Search />} />,

    <Route path="vocabulary/:wordId" element={<VocabularyWord />} />,
    <Route path="vocabulary" element={<Vocabulary />} />,

    <Route path="student" element={<Student />} />,

    <Route path="page/:slug" element={<Page />} />,

    <Route path="telemetry" element={<Debug />} />,

    <Route path="unsubscribe" element={<Unsubscribe />} />,

    <Route path="*" element={<NotFound />} />,
];
