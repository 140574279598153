import React, { useEffect, useState } from "react";

import urls from "juice-base/lib/urls.js";

import TeacherAddStudentsRows from "juice-base/business/teacher-add-students-rows/index.js";
import PopupConfirmClassStudentsLimit from "juice-base/business/popup-confirm-class-students-limit/index.js";


const TeacherAddStudentsManually = (props) => {
    const [
        isVisibleClassStudentsLimitPopup,
        setIsVisibleClassStudentsLimitPopup,
    ] = useState(false);

    useEffect(() => {
        setIsVisibleClassStudentsLimitPopup(props.rowsLimit === 0 && !props.noRowsLimit);
    }, [props.rowsLimit]);

    const onCloseClassStudentsLimitPopup = () => {
        setIsVisibleClassStudentsLimitPopup(false);
    };

    const onContactSupport = () => {
        urls.openUrl(props.supportLink);
    };

    const renderClassStudentsLimitPopup = () => {
        if (!isVisibleClassStudentsLimitPopup) {
            return null;
        }

        return (
            <PopupConfirmClassStudentsLimit
                onContactSupport={onContactSupport}
                onClose={onCloseClassStudentsLimitPopup}
            />
        );
    };

    return (
        <>
            {renderClassStudentsLimitPopup()}
            <TeacherAddStudentsRows
                editable
                grades={props.grades}
                rowsLimit={props.rowsLimit}
                noRowsLimit={props.noRowsLimit}
                supportLink={props.supportLink}
                defaultGrade={props.defaultGrade}
                onRemoveStudent={props.onRemoveStudent}
                onAddStudentsToClass={props.onAddStudentsToClass}
            />
        </>
    );
};

TeacherAddStudentsManually.defaultProps = {
    importStudentsTemplateUrl: "",
    supportLink: "",

    rowsLimit: 3,
    noRowsLimit: false,
    defaultGrade: "G7-G8",
    grades: [],

    onAddStudentsToClass: () => {},
    onRemoveStudent: () => {},
};

export default TeacherAddStudentsManually;
