// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".iyHpH1ivn8fORf0bbJ4c {\n    display: grid;\n    grid-auto-flow: column;\n\n    width: max-content;\n}\n\n.iyHpH1ivn8fORf0bbJ4c div:first-child {\n    border-radius: 0.5rem 0 0 0.5rem;\n}\n\n.iyHpH1ivn8fORf0bbJ4c div:last-child {\n    border-radius: 0 0.5rem 0.5rem 0;\n    border-left: 0;\n}\n\n/* ----- */\n\n.FsdwJwHtiGXH_YUI5Xt_ {\n    height: 4rem;\n    width: 5rem;\n\n    display: grid;\n    justify-content: center;\n    align-items: center;\n\n    border: 0.2rem solid var(--button-toggler-icon-border-color);\n\n    cursor: pointer;\n    outline: none;\n\n    user-select: none;\n}\n\n.FsdwJwHtiGXH_YUI5Xt_ svg {\n    width: 2rem;\n    height: 2rem;\n}\n\n.gyN8nOAnOLhRuAOIU6nN {\n    background-color: var(--button-toggler-icon-bg-color-selected);\n}\n", "",{"version":3,"sources":["webpack://./base/components/button-toggler-icon/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;;IAEtB,kBAAkB;AACtB;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI,gCAAgC;IAChC,cAAc;AAClB;;AAEA,UAAU;;AAEV;IACI,YAAY;IACZ,WAAW;;IAEX,aAAa;IACb,uBAAuB;IACvB,mBAAmB;;IAEnB,4DAA4D;;IAE5D,eAAe;IACf,aAAa;;IAEb,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,8DAA8D;AAClE","sourcesContent":[".togglers {\n    display: grid;\n    grid-auto-flow: column;\n\n    width: max-content;\n}\n\n.togglers div:first-child {\n    border-radius: 0.5rem 0 0 0.5rem;\n}\n\n.togglers div:last-child {\n    border-radius: 0 0.5rem 0.5rem 0;\n    border-left: 0;\n}\n\n/* ----- */\n\n.toggler {\n    height: 4rem;\n    width: 5rem;\n\n    display: grid;\n    justify-content: center;\n    align-items: center;\n\n    border: 0.2rem solid var(--button-toggler-icon-border-color);\n\n    cursor: pointer;\n    outline: none;\n\n    user-select: none;\n}\n\n.toggler svg {\n    width: 2rem;\n    height: 2rem;\n}\n\n.togglerSelected {\n    background-color: var(--button-toggler-icon-bg-color-selected);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"togglers": "iyHpH1ivn8fORf0bbJ4c",
	"toggler": "FsdwJwHtiGXH_YUI5Xt_",
	"togglerSelected": "gyN8nOAnOLhRuAOIU6nN"
};
export default ___CSS_LOADER_EXPORT___;
