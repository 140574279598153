import React from "react";

import styles from "./styles.module.css";


const HeaderBlock = (props) => {
    let totalQuizzes = null;

    if (props.withTotalQuizzes) {
        totalQuizzes = (
            <div className={styles.dateBlockQuizzes}>
                {`out of ${props.quizzes}`}
            </div>
        );
    }

    return (
        <div
            key={props.value}
            className={styles.dateBlock}
        >
            <div className={styles.dateBlockDate}>
                {props.date}
            </div>
            {totalQuizzes}
        </div>
    );
};

HeaderBlock.defaultProps = {
    value: "",
    date: "",
    quizzes: "",
    withTotalQuizzes: false,
};

export default HeaderBlock;
