import React from "react";

import classNames from "juice-base/lib/class-names.js";

import styles from "./styles.module.css";


const Position = (props) => {
    const containerClassName = classNames({
        [styles.fabContainer]: true,
        [styles.fabContainerUpper]: props.placeUpper,
        [styles.fabContainerAlignByVw]: props.alignRightByVW,
    });

    return (
        <div className={containerClassName}>
            {props.children}
        </div>
    );
};

Position.defaultProps = {
    placeUpper: false,
    alignRightByVW: false,
    children: null,
};

export default Position;
