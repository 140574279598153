import React from "react";

import IconWrapper from "juice-base/icons/wrapper/index.js";


const IconText = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    width="19"
                    height="18"
                    viewBox="0 0 19 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={iconProps.className}
                >
                    {iconProps.title}
                    <path
                        fill="currentColor"
                        d="M16.2852 0H2.28516C1.18516 0 0.285156 0.9 0.285156 2V16C0.285156 17.1 1.18516 18 2.28516 18H16.2852C17.3852 18 18.2852 17.1 18.2852 16V2C18.2852 0.9 17.3852 0 16.2852 0ZM11.2852 14H4.28516V12H11.2852V14ZM14.2852 10H4.28516V8H14.2852V10ZM14.2852 6H4.28516V4H14.2852V6Z"
                    />
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconText;
