import React from "react";

import IconWrapper from "juice-base/icons/wrapper/index.js";


const IconCard = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    className={iconProps.className}
                    width="20"
                    height="16"
                    viewBox="0 0 20 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill="currentColor"
                        d="M18 0.000976562H2C0.89 0.000976562 0.00999999 0.890977 0.00999999 2.00098L0 14.001C0 15.111 0.89 16.001 2 16.001H18C19.11 16.001 20 15.111 20 14.001V2.00098C20 0.890977 19.11 0.000976562 18 0.000976562ZM18 14.001H2V8.00098H18V14.001ZM18 4.00098H2V2.00098H18V4.00098Z"
                    />
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconCard;
