import React, { useEffect, useState, useRef } from "react";

import classNames from "juice-base/lib/class-names.js";

import IconPencil from "juice-base/icons/pencil/index.js";
import IconCaret from "juice-base/icons/caret/index.js";
import IconCheck from "juice-base/icons/check/index.js";
import IconInformation from "juice-base/icons/information/index.js";
import IconMinusInRing from "juice-base/icons/minus-in-ring/index.js";

import IconClickable from "juice-base/components/icon-clickable/index.js";
import TextLine from "juice-base/components/text-line/index.js";

import styles from "./styles.module.css";


const MenuSticky = (props) => {
    const menuRef = useRef(null);
    const [isOpened, setIsOpened] = useState(false);

    /* --- */

    const onToggle = () => {
        setIsOpened((val) => !val);
    };

    const onSelect = (evt, opt) => {
        setIsOpened(false);
        props.onSelect(opt.value);
    };

    const onEditOption = (evt, id) => {
        evt.stopPropagation();
        props.onEdit(id);
    };

    const onRemoveOption = (evt, id) => {
        evt.stopPropagation();
        props.onRemove(id);
    };

    /* --- */

    useEffect(() => {
        const globalClose = (evt) => {
            if (menuRef?.current?.contains
                && menuRef.current.contains(evt.target)) {
                return;
            }

            setIsOpened(() => false);
        };

        if (document) {
            document.addEventListener("click", globalClose, false);
        }

        return () => {
            if (document) {
                document.removeEventListener("click", globalClose, false);
            }
        };
    }, []);

    /* --- */

    const renderControlOptions = () => {
        if (props.controlOptions.length === 0) {
            return null;
        }

        return props.controlOptions.map((opt) => {
            return (
                <div
                    className={styles.controlOption}
                    onClick={() => {
                        setIsOpened(false);
                        opt.onClick();
                    }}
                    onKeyPress={() => {
                        setIsOpened(false);
                        opt.onClick();
                    }}
                    tabIndex="-1"
                    role="button"
                >
                    {opt.icon}
                    {opt.name}
                </div>
            );
        });
    };

    const renderOptionSelectedCaret = () => {
        const imgClassName = classNames({
            [styles.optionSelectedCaretImgUp]: isOpened,
        });

        return (
            <div className={styles.optionSelectedCaret}>
                <IconCaret
                    className={imgClassName}
                    isBlack
                />
            </div>
        );
    };

    const renderOptionSelectedValue = () => {
        let selectedOpt = null;

        for (let i = 0; i < props.options.length; i += 1) {
            const opt = props.options[i];

            if (opt.value === props.selected) {
                selectedOpt = opt;
                break;
            }
        }

        let info = null;

        if (selectedOpt?.info) {
            info = (
                <IconInformation
                    className={styles.iconInfo}
                    isRed
                />
            );
        }

        const label1 = (
            <div className={styles.optionSelectedValueLabel}>
                {selectedOpt ? selectedOpt.label : "Select ..."}
            </div>
        );

        let label2 = null;

        if (selectedOpt?.label2) {
            label2 = (
                <div className={styles.optionSelectedValueLabel2}>
                    {selectedOpt.label2}
                </div>
            );
        }

        return (
            <div className={styles.optionSelectedValueContainer}>
                <div className={styles.optionSelectedValue}>
                    {label1}
                    {label2}
                </div>
                {info}
            </div>
        );
    };

    const renderOptionSelectedIcon = (isSelected) => {
        if (!isSelected) {
            return <div />;
        }

        return (
            <IconCheck
                className={styles.iconCheckSelected}
                isSky
            />
        );
    };

    const renderOptionInfoIcon = (info) => {
        if (!info) {
            return null;
        }

        return (
            <IconInformation
                className={styles.iconInfo}
                isRed
            />
        );
    };

    const renderOptionEditButton = (id) => {
        return (
            <IconClickable
                className={styles.optionClickableControl}
                onClick={(evt) => {
                    onEditOption(evt, id);
                }}
            >
                <IconPencil
                    className={styles.optionClickableControlIcon}
                    isBlack
                />
            </IconClickable>
        );
    };

    const renderOptionRemoveButton = (id, withRemoveButton) => {
        if (!withRemoveButton) {
            return null;
        }

        return (
            <IconClickable
                className={styles.optionClickableControl}
                onClick={(evt) => {
                    onRemoveOption(evt, id);
                }}
            >
                <IconMinusInRing
                    className={styles.optionClickableControlIcon}
                    isRed
                />
            </IconClickable>
        );
    };

    const renderOptionControls = (opt) => {
        const controls = [
            renderOptionInfoIcon(opt.info),
            renderOptionEditButton(opt.value),
            renderOptionRemoveButton(opt.value, opt.withRemoveButton),
        ];

        return (
            <div className={styles.optionControls}>
                {controls}
            </div>
        );
    };

    const renderOptions = () => {
        if (!isOpened) {
            return null;
        }

        const options = props.options.map((opt) => {
            const isSelected = props.selected === opt.value;

            const optClassName = classNames({
                [styles.option]: true,
                [styles.optionAlreadySelected]: isSelected,
            });

            return (
                <div
                    className={optClassName}
                    onClick={(evt) => { onSelect(evt, opt); }}
                    onKeyPress={(evt) => { onSelect(evt, opt); }}
                    tabIndex="-1"
                    role="button"
                >
                    {renderOptionSelectedIcon(isSelected)}
                    <TextLine>
                        {opt.label}
                    </TextLine>
                    {renderOptionControls(opt)}
                </div>
            );
        });

        return (
            <div className={styles.options}>
                {options}
                {renderControlOptions()}
            </div>
        );
    };

    const optionSelectedClassName = classNames({
        [styles.optionSelected]: true,
        [styles.optionSelectedWithBorderRadius]: props.withBorderRadius,
    });

    return (
        <div
            ref={menuRef}
            className={styles.menu}
            data-comment={props.dataComment}
        >
            <div
                className={optionSelectedClassName}
                onClick={onToggle}
                onKeyPress={onToggle}
                tabIndex="-1"
                role="button"
            >
                {renderOptionSelectedValue()}
                {renderOptionSelectedCaret()}
            </div>

            {renderOptions()}
        </div>
    );
};

MenuSticky.defaultProps = {
    dataComment: "",
    selected: null,
    options: [],
    controlOptions: [],
    onSelect: () => { },
    onEdit: () => { },
    onRemove: () => { },
    withBorderRadius: false,
};

export default MenuSticky;
