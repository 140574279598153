import { useState } from "react";


const getState = () => ({
    classId: null,
    isVisible: false,
});

const usePopupAddTeacher = () => {
    const [state, setState] = useState(() => getState());

    const open = (classId) => {
        setState((prev) => ({
            ...prev,
            classId,
            isVisible: true,
        }));
    };

    const close = () => {
        setState(getState());
    };

    return {
        state,
        open,
        close,
    };
};


export default usePopupAddTeacher;
