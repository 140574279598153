import React, { useState } from "react";

import IconStandards from "juice-base/icons/standards/index.js";
import IconPlusInRing from "juice-base/icons/plus-in-ring/index.js";
import IconMinusInRing from "juice-base/icons/minus-in-ring/index.js";
import IconCheck from "juice-base/icons/check/index.js";
import IconClose from "juice-base/icons/close/index.js";

import PopupWindow from "juice-base/components/popup-window/index.js";
import Accordion from "juice-base/components/accordion/index.js";

import styles from "./styles.module.css";


const PopupQuiz = (props) => {
    const [expandedStandards, setExpandedStandards] = useState([]);

    /* ----- */

    const onExpandStandard = (type) => {
        setExpandedStandards((prev) => {
            if (prev.indexOf(type) === -1) {
                return [...prev].concat(type);
            }

            return [...prev].filter((t) => t !== type);
        });
    };

    /* ----- */

    const renderTitle = () => {
        return (
            <div className={styles.header}>
                <div>Question</div>
                <div>{props.question}</div>
            </div>
        );
    };

    const renderAnswers = () => {
        const answers = props.answers.map((answer) => {
            let icon = <div />;

            if (answer.isUserChoice || answer.isCorrect) {
                if (answer.isCorrect) {
                    icon = (
                        <IconCheck
                            className={styles.answerIcon}
                            isGreen
                        />
                    );
                } else {
                    icon = (
                        <IconClose
                            className={styles.answerIcon}
                            isRed
                        />
                    );
                }
            }

            return (
                <div className={styles.answer}>
                    {icon}
                    <div>{answer.answer}</div>
                </div>
            );
        });

        return answers;
    };

    const renderStandards = () => {
        const standards = props.standards.map((s) => {
            const isExpanded = expandedStandards.indexOf(s.type) !== -1;

            const label = (
                <div className={styles.standardLabel}>
                    <IconStandards
                        className={styles.standardLabelIcon}
                        isRed
                    />
                    <div>{s.name}</div>
                </div>
            );

            let icon = null;

            if (isExpanded) {
                icon = (
                    <IconMinusInRing
                        className={styles.standardIcon}
                        isRed
                    />
                );
            } else {
                icon = (
                    <IconPlusInRing
                        className={styles.standardIcon}
                        isRed
                    />
                );
            }

            return (
                <div className={styles.standard}>
                    <Accordion
                        label={label}
                        icon={icon}
                        iconClassName={styles.standardIcon}
                        onClick={() => {
                            onExpandStandard(s.type);
                        }}
                        withContentPadding={false}
                        expanded={isExpanded}
                    >
                        <div className={styles.standardContent}>
                            {s.details}
                        </div>
                    </Accordion>
                </div>
            );
        });

        return (
            <div>
                {standards}
            </div>
        );
    };

    /* ------ */

    return (
        <PopupWindow
            title={renderTitle()}
            barClassName={styles.popupBar}
            isCentered={!props.isMobile}
            titleToStart
            withTitleBarShadow={false}
            hideScrollbar={props.hideScrollbar}
            onClose={props.onClose}
        >
            <div>
                {renderAnswers()}
                {renderStandards()}
            </div>
        </PopupWindow>
    );
};

PopupQuiz.defaultProps = {
    question: "",

    answers: [],
    standards: [],

    onClose: () => { },

    isMobile: false,
    hideScrollbar: false,
};

export default PopupQuiz;
