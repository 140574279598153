import React from "react";

import text from "juice-base/text/index.js";

import ButtonFlat from "juice-base/components/button-flat/index.js";
import PopupConfirm, {
    PopupConfirmContent,
    PopupConfirmTitle,
    PopupConfirmButtons,
} from "juice-base/components/popup-confirm/index.js";

import usePopupConfirmLMSClassRemovalHook from "./use-popup-confirm-lms-class-removal.js";


const PopupConfirmLMSClassRemoval = (props) => {
    return (
        <PopupConfirm>
            <PopupConfirmTitle>
                {text.confirmLMSClassRemovalTitle}
            </PopupConfirmTitle>

            <PopupConfirmContent>
                {text.confirmLMSClassRemoval}
            </PopupConfirmContent>

            <PopupConfirmButtons>
                <ButtonFlat onClick={props.onClose}>
                    Cancel
                </ButtonFlat>
                <ButtonFlat onClick={props.onProceed}>
                    OK
                </ButtonFlat>
            </PopupConfirmButtons>
        </PopupConfirm>
    );
};

PopupConfirmLMSClassRemoval.defaultProps = {
    onClose: () => { },
    onProceed: () => { },
};

export const usePopupConfirmLMSClassRemoval = usePopupConfirmLMSClassRemovalHook;
export default PopupConfirmLMSClassRemoval;
