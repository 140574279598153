import React, { useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";

import text from "juice-base/text/index.js";

import StripeContext from "juice-base/context/stripe/index.js";

import RequestLoader from "juice-base/components/request-loader/index.js";
import MessageDanger from "juice-base/components/message-danger/index.js";


import styles from "./styles.module.css";

const storeSelector = (state) => ({
    libs: state.app.libs,
});

const StripeContainer = (props) => {
    const stripeCtx = useContext(StripeContext);
    const store = useSelector(storeSelector);

    /* --- */

    useEffect(() => {
        if (stripeCtx?.loadStripeLib) {
            stripeCtx?.loadStripeLib();
        }
    }, []);

    useEffect(() => {
        if (!stripeCtx.isInitialized()) {
            stripeCtx.initStripe();
        }
    }, [stripeCtx.loadStripe]);

    /* --- */

    if (store.libs?.stripe?.error) {
        const stripeError = store.libs?.stripe?.error
            || text.errorTryAgain;

        return (
            <div className={styles.error}>
                <MessageDanger>
                    {stripeError}
                </MessageDanger>
            </div>
        );
    }

    if (!store.libs?.stripe?.isLoaded
        || !stripeCtx.isInitialized()) {
        return (
            <RequestLoader />
        );
    }

    return (
        <Elements stripe={stripeCtx.stripe}>
            {props.children}
        </Elements>
    );
};

StripeContainer.defaultProps = {
    children: null,
};

export default StripeContainer;
