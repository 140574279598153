import React from "react";

import { getStoryLegendIconByName } from "juice-base/icons/index.js";

import DailyJuice from "juice-base/project/daily-juice.js";

import PopupCommon from "juice-base/components/popup-common/index.js";

import styles from "./styles.module.css";


const PopupDailyJuiceStatesLegend = (props) => {
    const renderLegend = () => {
        const icons = [];

        Object.keys(DailyJuice.StoryStatesLegend).forEach((key) => {
            const iconTitle = DailyJuice.StoryStatesLegend[key];

            const icon = getStoryLegendIconByName(key, {
                className: styles.icon,
                title: iconTitle,
            });

            icons.push(
                <div className={styles.row}>
                    {icon}
                    {iconTitle}
                </div>,
            );
        });

        return (
            <div className={styles.legend}>
                {icons}
            </div>
        );
    };

    return (
        <PopupCommon
            onClose={props.onClose}
            showClose={false}
        >
            <div className={styles.popup}>
                {renderLegend()}
            </div>
        </PopupCommon>
    );
};

PopupDailyJuiceStatesLegend.defaultProps = {
    onClose: () => { },
};

export default PopupDailyJuiceStatesLegend;
