import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";

import text from "juice-base/text/index.js";
import actionsUser from "juice-base/actions/user.js";
import actionsGeo from "juice-base/actions/geo.js";
import actions from "juice-base/store/actions.js";

import Geo from "juice-base/project/geo.js";
import Subscription from "juice-base/project/subscription.js";

import useCoupon from "juice-base/hooks/use-coupon/index.js";
import usePaymentDetails from "juice-base/hooks/use-payment-details/index.js";
import usePaymentSubscription from "juice-base/hooks/use-payment-subscription/index.js";

import MessageDanger from "juice-base/components/message-danger/index.js";
import UserCheckoutForm from "juice-base/forms/user-checkout/index.js";

import Stripe from "juice-app/containers/stripe/index.js";

import api from "juice-app/api.js";

import styles from "./styles.module.css";


const storeSelector = (state) => ({
    theme: state.app.theme.theme,
    session: state.user.session,
    subdivisionsByAlpha2: state.geo.subdivisionsByAlpha2,
});

// NOTE: payment
const UserCheckoutPayment = (props) => {
    const dispatch = useDispatch();
    const store = useSelector(storeSelector);

    const elements = useElements();
    const stripe = useStripe();

    const [sessionPlan, setSessionPlan] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");

    const paymentDetails = usePaymentDetails();
    const paymentSubscription = usePaymentSubscription();
    const coupon = useCoupon();

    /* --- */

    const getSubdivisions = () => {
        const subdivs = store.subdivisionsByAlpha2[Geo.DEFAULT_ALPHA_2]?.subdivisions || [];

        return subdivs.map((div) => ({
            name: div.name,
            value: div.code,
        }));
    };

    /* --- */

    const loadSessionPlan = async () => {
        const res = await api.subscription.getPlanBySession({
            session: store.session,
        });

        if (!res.ok || !res.plan) {
            setErrorMessage(res.error || text.error);
            return;
        }

        setSessionPlan(res.plan);
    };

    const loadSubdivisions = () => {
        if (!store.subdivisionsByAlpha2[Geo.DEFAULT_ALPHA_2]) {
            dispatch(actionsGeo.loadSubdivisionsByAlpha2({ api, actions }, {
                session: store.session,
                alpha2: Geo.DEFAULT_ALPHA_2,
            }));
        }
    };

    /* --- */

    const onApplyCoupon = async (values) => {
        if (!values.couponCode) {
            return;
        }

        coupon.setSubmitted(values.couponCode);

        const res = await api.subscription.subscriptionApplyCoupon({
            session: store.session,
            coupon: values.couponCode,
            plan: sessionPlan.id,
        });

        if (!res.ok) {
            coupon.setLoaded({
                error: res.error || text.error,
            });
            return;
        }

        let discountMessage = "";

        if (res.data.discountUSD > 0) {
            discountMessage = `$${res.data.discountUSD} discount added`;
        } else {
            discountMessage = `${res.data.discountPercent}% discount added`;
        }

        coupon.setLoaded({
            discountMessage,
            error: "",
        });
    };

    /* --- */

    const onPaymentTrackActivity = (intentId, stripeRes) => {
        api.subscription.paymentLogActivity({
            session: store.session,
            intentId,
            body: stripeRes,
        });
    };

    const completePayment = async (intentId) => {
        const res = await api.subscription.paymentCompleted({
            session: store.session,
            intentId,
        });

        if (!res.ok) {
            return [false, ""];
        }

        return [true, res.data.thankYouMessage || ""];
    };

    const confirmPayment = async (details) => {
        const res = await stripe.confirmCardPayment(details.clientSecret, {
            payment_method: details.paymentMethodId,
            setup_future_usage: "off_session",
        });

        onPaymentTrackActivity(details.intentId, res);

        return res?.paymentIntent?.status === "succeeded";
    };

    const loadPaymentPriceDetails = async (intentId) => {
        const res = await api.subscription.getPaymentDetails({
            session: store.session,
            intentId,
        });

        let price = null;
        let error = null;

        if (res.ok) {
            price = res.data.amount;
        } else {
            error = res.error || text.error;
        }

        paymentSubscription.setData({
            isPriceLoaded: true,
            price,
            error,
        });
    };

    const createPaymentMethod = async (intentId, subscriptionDates, billingValues) => {
        const billingDetails = {
            name: billingValues.name,
            address: {
                line1: billingValues.line1,
                line2: billingValues.line2,
                city: billingValues.city,
                state: billingValues.state.value,
                postal_code: billingValues.zip,
            },
        };

        const cardElement = elements.getElement(CardElement);

        const res = await stripe.createPaymentMethod({
            type: "card",
            card: cardElement,
            billing_details: billingDetails,
        });

        onPaymentTrackActivity(intentId, res);

        if (!res?.paymentMethod) {
            paymentDetails.setError(res.error?.message || text.error);
            return "";
        }

        paymentSubscription.setData({
            isCardLoaded: true,
            card: {
                name: res.paymentMethod.billing_details.name,
                expYear: res.paymentMethod.card.exp_year,
                expMonth: res.paymentMethod.card.exp_month,
                last4: res.paymentMethod.card.last4,
            },
            subscriptionDates,
            isAutoRenew: billingValues.isAutoRenew,
        });

        paymentDetails.setData({
            paymentMethodId: res.paymentMethod.id,
        });

        return res.paymentMethod.id;
    };

    const createPaymentIntent = async (values) => {
        paymentDetails.setError("");

        const res = await api.subscription.createPaymentIntentByPlan({
            session: store.session,
            plan: sessionPlan.id,
            coupon: coupon.state.couponCode,
            isAutoRenew: values.isAutoRenew,
        });

        if (!res.ok) {
            paymentDetails.setData({
                clientSecret: null,
                intentId: null,
                paymentMethodId: null,
                error: res.error || text.error,
            });
            return null;
        }

        const {
            clientSecret,
            intentExternalId: intentId,
            subscriptionEncId,
            is100PercentDiscount,
        } = res.data;

        paymentDetails.setData({
            clientSecret,
            intentId,
            error: "",
        });

        return {
            intentId,
            clientSecret,
            is100PercentDiscount,
            subscriptionEncId,
            dates: {
                start: res.data.subscriptionStartDateFormatted,
                end: res.data.subscriptionEndDateFormatted,
            },
        };
    };

    const onPaymentSubmit = async (values, formActions) => {
        const paymentIntent = await createPaymentIntent(values);

        if (!paymentIntent) {
            formActions.setSubmitting(false);
            return;
        }

        const paymentMethod = await createPaymentMethod(
            paymentIntent.intentId,
            paymentIntent.dates,
            values,
            formActions,
        );

        if (!paymentMethod) {
            formActions.setSubmitting(false);
            return;
        }

        if (paymentIntent.is100PercentDiscount) {
            await api.subscription.paymentAttachPaymentMethod({
                session: store.session,
                paymentMethodId: paymentMethod,
            });

            const completeRes = await api.subscription.paymentCompleteWithDiscount100({
                session: store.session,
                subscriptionId: paymentIntent.subscriptionEncId,
            });

            if (!completeRes.ok) {
                paymentDetails.setError(completeRes.error || text.error);
                return;
            }

            props.onPayment();
            return;
        }

        await loadPaymentPriceDetails(paymentIntent.intentId);

        const isSubscribed = await confirmPayment({
            intentId: paymentIntent.intentId,
            clientSecret: paymentIntent.clientSecret,
            paymentMethodId: paymentMethod,
        });

        if (!isSubscribed) {
            paymentDetails.setError(text.errorTryAgain);
            formActions.setSubmitting(false);
            return;
        }

        const [isCompleted, msg] = await completePayment(paymentIntent.intentId);

        if (!isCompleted) {
            paymentDetails.setError(msg || text.errorTryAgain);
            formActions.setSubmitting(false);
            return;
        }

        dispatch(actionsUser.loadSession({ api, actions }, {
            session: store.session,
        }));

        props.onPayment();
    };

    /* --- */

    useEffect(() => {
        loadSessionPlan();
        loadSubdivisions();
    }, []);

    /* --- */

    if (!store.session) {
        return null;
    }

    if (errorMessage) {
        return (
            <div className={styles.message}>
                <MessageDanger>
                    {errorMessage}
                </MessageDanger>
            </div>
        );
    }

    const couponState = {
        ...coupon.state,
        onApply(values) {
            onApplyCoupon(values);
        },
        onChange() {
            coupon.reset();
        },
    };

    const isPlanAnnually = Subscription.isPlanAnnually(sessionPlan);

    return (
        <UserCheckoutForm
            theme={store.theme}
            states={getSubdivisions()}
            error={paymentDetails.state.error}
            onSubmit={onPaymentSubmit}
            couponState={couponState}
            isDisabledTabIndex={props.isDisabledTabIndex}
            withAutoRenew={isPlanAnnually}
            withCoupon
        />
    );
};

UserCheckoutPayment.defaultProps = {
    isDisabledTabIndex: false,
    onPayment: () => { },
};

/* --- */

const UserCheckoutPaymentContainer = (props) => {
    return (
        <Stripe>
            <UserCheckoutPayment
                isDisabledTabIndex={props.isDisabledTabIndex}
                onPayment={props.onPayment}
            />
        </Stripe>
    );
};

UserCheckoutPaymentContainer.defaultProps = {
    isDisabledTabIndex: false,
    onPayment: () => { },
};

export default UserCheckoutPaymentContainer;
