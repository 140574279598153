import React from "react";

import classNames from "juice-base/lib/class-names.js";

import styles from "./styles.module.css";


const Section = (props) => {
    let header = null;

    if (props.name) {
        header = (
            <div className={styles.header}>
                {props.name}
            </div>
        );
    }

    const sectionClassName = classNames({
        [styles.section]: true,
        [styles.sectionWithOverflowHidden]: props.overflowHidden,
    });

    return (
        <section className={sectionClassName}>
            {header}
            {props.children}
        </section>
    );
};

Section.defaultProps = {
    name: "",
    overflowHidden: false,
    children: null,
};

export default Section;
