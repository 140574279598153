import React from "react";
import { useSelector } from "react-redux";

import device from "juice-base/lib/device.js";
import useDimensions from "juice-base/hooks/use-dimensions/index.js";

import LayoutContent from "juice-base/components/layout-content/index.js";

import juiceScrollbarStyles from "juice-base/components/juice-scrollbar/styles.module.css";
import styles from "./styles.module.css";


const Debug = () => {
    const version = useSelector((state) => state.app.version);

    const dimensions = useDimensions();

    const isYesNo = (val) => {
        return val ? "Yes" : "No";
    };

    const deviceInfo = [
        { name: "Build", value: version },

        { name: "Resolution (browser)", value: `${dimensions.width}x${dimensions.height}` },
        { name: "Resolution (available)", value: device.resolutionAvailable },

        { name: "Device", value: device.deviceInfo },
        { name: "Engine", value: device.engineInfo },

        { name: "Dark Theme", value: isYesNo(device.isDarkTheme) },
        { name: "Mobile", value: isYesNo(device.isMobile) },
        { name: "PWA", value: isYesNo(device.isPWA) },

        { name: "Browser Chrome", value: isYesNo(device.isChrome) },
        { name: "Browser Firefox", value: isYesNo(device.isFirefox) },
        { name: "Browser Safari", value: isYesNo(device.isSafari) },
        { name: "Browser Opera", value: isYesNo(device.isOpera) },
        { name: "Browser IE", value: isYesNo(device.isIE) },

        { name: "Windows", value: isYesNo(device.isWindows) },
        { name: "Mac", value: isYesNo(device.isMac) },

        { name: "IOS 15", value: isYesNo(device.isIOS15) },
        { name: "Mobile IOS", value: isYesNo(device.isMobileIOS) },
        { name: "Iphone", value: isYesNo(device.isIphone) },
        { name: "Ipad", value: isYesNo(device.isIpad) },
        { name: "Ipod", value: isYesNo(device.isIpod) },

        { name: "Mobile Android", value: isYesNo(device.isMobileAndroid) },
        { name: "Mobile Opera", value: isYesNo(device.isMobileOpera) },
        { name: "Mobile Windows", value: isYesNo(device.isMobileWindows) },
        { name: "Mobile BlackBerry", value: isYesNo(device.isMobileBlackBerry) },

        { name: "Navigator UserAgent", value: device.navigatorUserAgent },
        { name: "Navigator AppVersion", value: device.navigatorAppVersion },
        { name: "Navigator MaxTouchPoints", value: device.navigatorMaxTouchPoints },
    ];

    const renderRows = (rows) => {
        return rows.map((row) => (
            <tr>
                <td>{row.name}</td>
                <td>{row.value}</td>
            </tr>
        ));
    };

    const debugClassName = [
        styles.debug,
        juiceScrollbarStyles.scrollbar,
    ].join(" ");

    return (
        <LayoutContent>
            <div className={debugClassName}>
                <table className={styles.table}>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderRows(deviceInfo)}
                    </tbody>
                </table>
            </div>
        </LayoutContent>
    );
};

export default Debug;
