import React from "react";

import classNames from "juice-base/lib/class-names.js";

import SelectCustom from "juice-base/components/select-custom/index.js";

import styles from "./styles.module.css";


const SelectCustomWithControls = (props) => {
    const renderControls = () => {
        if (props.controls.length === 0) {
            return <div />;
        }

        const controls = props.controls.map((control) => {
            return (
                <div
                    className={styles.control}
                    onClick={control.onClick}
                    onKeyPress={control.onClick}
                    tabIndex="-1"
                    role="button"
                >
                    <control.icon />
                </div>
            );
        });

        return (
            <div className={styles.controls}>
                {controls}
            </div>
        );
    };

    const selectClassName = classNames({
        [styles.select]: true,
        [styles.selectDisabled]: props.disabled,
    });

    return (
        <div className={selectClassName}>
            <SelectCustom
                selected={props.selected}
                options={props.options}
                icon={props.icon}
                disabled={props.disabled}
                onSelect={props.onSelect}
                withLabel2={props.withLabel2}
                withScroll={props.withScroll}
                withBorder={false}
            />
            {renderControls()}
        </div>
    );
};

SelectCustomWithControls.defaultProps = {
    selected: null,
    options: [],
    controls: [],
    icon: null,
    disabled: false,
    withLabel2: false,
    withScroll: true,
    onSelect: () => { },
    onIconClick: () => { },
};

export default SelectCustomWithControls;
