import React from "react";

import classNames from "juice-base/lib/class-names.js";

import IconCaret from "juice-base/icons/caret/index.js";

import styles from "./styles.module.css";


const ColumnNameWithSort = (props) => {
    const columnClassName = classNames({
        [styles.columnName]: true,
        [styles.columnNameWithSort]: true,
    });

    const caretClassName = classNames({
        [styles.caret]: true,
        [styles.caretSorted]: props.inSorted,
    });

    return (
        <div
            className={columnClassName}
            onClick={props.onClick}
            onKeyPress={props.onClick}
            tabIndex="-1"
            role="button"
        >
            <div>{props.name}</div>

            <IconCaret
                className={caretClassName}
            />
        </div>
    );
};

ColumnNameWithSort.defaultProps = {
    name: "Name",
    inSorted: false,
    onClick: () => { },
};

export default ColumnNameWithSort;
