// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".yL0Ys9IZvkJHBo0MXiGz {\n    display: grid;\n    grid-auto-flow: column;\n    grid-gap: 2rem;\n\n    align-items: center;\n    justify-content: start;\n\n    font-weight: 600;\n\n    height: 6rem;\n    padding: 0 2rem;\n\n    border-bottom: 0.1rem solid var(--grey-light);\n\n    cursor: pointer;\n    outline: none;\n}\n\n.XX5hpc63XlDhgVo5lTgC {\n    width: 2.5rem;\n    height: 2.5rem;\n}\n\n.Chnqbgtpb0EcE764jXk2 {\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n}\n\n.iTHU6FEvsplBF1IblKO9 {\n    margin: 1.5rem 2rem;\n}\n", "",{"version":3,"sources":["webpack://./base/business/daily-juice-explore/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,cAAc;;IAEd,mBAAmB;IACnB,sBAAsB;;IAEtB,gBAAgB;;IAEhB,YAAY;IACZ,eAAe;;IAEf,6CAA6C;;IAE7C,eAAe;IACf,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,cAAc;AAClB;;AAEA;IACI,gBAAgB;IAChB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".row {\n    display: grid;\n    grid-auto-flow: column;\n    grid-gap: 2rem;\n\n    align-items: center;\n    justify-content: start;\n\n    font-weight: 600;\n\n    height: 6rem;\n    padding: 0 2rem;\n\n    border-bottom: 0.1rem solid var(--grey-light);\n\n    cursor: pointer;\n    outline: none;\n}\n\n.rowIcon {\n    width: 2.5rem;\n    height: 2.5rem;\n}\n\n.rowTitle {\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n}\n\n.viewMoreButton {\n    margin: 1.5rem 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": "yL0Ys9IZvkJHBo0MXiGz",
	"rowIcon": "XX5hpc63XlDhgVo5lTgC",
	"rowTitle": "Chnqbgtpb0EcE764jXk2",
	"viewMoreButton": "iTHU6FEvsplBF1IblKO9"
};
export default ___CSS_LOADER_EXPORT___;
