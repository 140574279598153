import React, { useState } from "react";

import text from "juice-base/text/index.js";

import PopupWindow from "juice-base/components/popup-window/index.js";
import ButtonBig from "juice-base/components/button-big/index.js";
import Chip from "juice-base/components/chip/index.js";

import styles from "./styles.module.css";


const PopupFilterCategories = (props) => {
    const [selectedCategoriesIds, setSelectedCategoriesIds] = useState(props.selectedCategories);
    const [clickedOnReset, setClickedOnReset] = useState(false);

    /* ----- */

    const isSelectedCategoriesSameAsDefault = () => {
        if (selectedCategoriesIds.length !== props.selectedCategories.length) {
            return false;
        }

        for (let i = 0; i < selectedCategoriesIds.length; i += 1) {
            if (props.selectedCategories.indexOf(selectedCategoriesIds[i]) === -1) {
                return false;
            }
        }

        return true;
    };

    /* ----- */


    const onClose = () => {
        if (clickedOnReset && !isSelectedCategoriesSameAsDefault()) {
            props.onApply(selectedCategoriesIds);
        } else {
            props.onClose();
        }
    };

    const onCategoryClick = (categoryId, isSelected) => {
        let newSelectedCategories = [...selectedCategoriesIds];

        if (!isSelected) {
            newSelectedCategories.push(categoryId);
        } else if (isSelected) {
            newSelectedCategories = newSelectedCategories.filter((catId) => catId !== categoryId);
        }

        setSelectedCategoriesIds(newSelectedCategories);
    };

    const onApplyCategories = () => {
        if (!isSelectedCategoriesSameAsDefault()) {
            props.onApply(selectedCategoriesIds);
        }
    };

    const onResetCategories = () => {
        if (selectedCategoriesIds.length > 0) {
            setSelectedCategoriesIds([]);
            setClickedOnReset(true);
        }
    };

    /* ----- */

    const renderTitle = () => {
        return (
            <div className={styles.header}>
                <div>{text.categoryFilter}</div>
                <div>{text.selectCategories}</div>
            </div>
        );
    };

    const renderCategories = () => {
        const categories = props.categories.map((cat) => {
            const isSelected = selectedCategoriesIds.indexOf(cat.value) !== -1;

            return (
                <Chip
                    label={cat.label}
                    onClick={() => {
                        onCategoryClick(cat.value, isSelected);
                    }}
                    selected={isSelected}
                    isPumpkinTheme
                />
            );
        });

        return (
            <div>
                {categories}
            </div>
        );
    };

    const renderControls = () => {
        return (
            <div className={styles.controls}>
                <ButtonBig
                    onClick={onApplyCategories}
                    disabled={isSelectedCategoriesSameAsDefault()}
                    isOrangeTheme
                >
                    Apply
                </ButtonBig>
                <ButtonBig
                    onClick={onResetCategories}
                    disabled={selectedCategoriesIds.length === 0}
                    isOrangeTheme
                    outlined
                >
                    Reset All Filters
                </ButtonBig>
            </div>
        );
    };

    /* ----- */

    return (
        <PopupWindow
            title={renderTitle()}
            barClassName={styles.popupBar}
            popupClassName={styles.popup}
            onClose={onClose}
            withTitleBarShadow={false}
            showClose={false}
            isCentered
            isSmall
        >
            <div className={styles.content}>
                {renderCategories()}
                {renderControls()}
            </div>
        </PopupWindow>
    );
};

PopupFilterCategories.defaultProps = {
    categories: [],
    selectedCategories: [],

    onApply: () => { },
    onResetCategories: () => { },
    onClose: () => { },
};

export default PopupFilterCategories;
