import React from "react";
import { Link } from "react-router-dom";

import { Formik } from "formik";

import ButtonBig from "juice-base/components/button-big/index.js";

import Input from "juice-base/components/forms/input/index.js";
import Password from "juice-base/components/forms/password/index.js";
import Checkbox from "juice-base/components/forms/checkbox/index.js";

import v from "juice-base/lib/form-validators.js";

import styles from "./styles.module.css";


const UserSignInForm = (props) => {
    const validateForm = (values) => {
        const errorsUsername = v.validate(values.username, [
            v.required("Please enter username"),
        ]);

        const errorsPassword = v.validate(values.password, [
            v.required("Please enter password"),
            v.min(`Minimum password length is ${props.passwordMinLen}`, props.passwordMinLen),
        ]);

        const errors = {};

        if (errorsUsername) {
            errors.username = errorsUsername;
        }

        if (errorsPassword) {
            errors.password = errorsPassword;
        }

        return errors;
    };

    const renderForm = (formProps) => {
        const {
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
        } = formProps;

        return (
            <form onSubmit={handleSubmit} className={styles.form}>
                <Input
                    name="username"
                    label="Email Address or Username *"
                    placeholder="Email Address or Username *"
                    autoComplete="username"
                    value={values.username}
                    error={v.getError(formProps, "username")}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />

                <Password
                    name="password"
                    label="Password *"
                    placeholder="Password *"
                    autoComplete="current-password"
                    value={values.password}
                    error={v.getError(formProps, "password")}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />

                <div className={styles.formLinksSection}>
                    <Checkbox
                        name="isRememberMe"
                        label="Remember me"
                        checked={values.isRememberMe}
                        onChange={handleChange}
                    />

                    <Link
                        to={{
                            pathname: "/forgot-password",
                            state: {
                                username: values.username,
                            },
                        }}
                        className={styles.accountLinkPassword}
                    >
                        Forgot password?
                    </Link>
                </div>

                <div className={styles.submitButtonBlock}>
                    <ButtonBig
                        type="submit"
                        disabled={isSubmitting}
                    >
                        Sign In
                    </ButtonBig>
                </div>
            </form>
        );
    };

    return (
        <Formik
            initialValues={props.initialValues}
            validate={validateForm}
            onSubmit={props.onSubmit}
        >
            {renderForm}
        </Formik>
    );
};

UserSignInForm.defaultProps = {
    initialValues: {
        username: "",
        password: "",
        isRememberMe: true,
    },
    passwordMinLen: 8,
    onSubmit: () => { },
};

export default UserSignInForm;
