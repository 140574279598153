import { useState } from "react";


const getState = () => ({
    isVisible: false,
    story: null,
});

const usePopupAssign = () => {
    const [state, setState] = useState(() => getState());

    const open = (story) => {
        setState((prev) => ({
            ...prev,
            isVisible: true,
            story,
        }));
    };

    const close = () => {
        setState(getState());
    };

    return {
        state,
        open,
        close,
    };
};

export default usePopupAssign;
