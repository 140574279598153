import React, { useEffect, useState } from "react";
import { Navigate, useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import text from "juice-base/text/index.js";
import userActions from "juice-base/actions/user.js";
import actions from "juice-base/store/actions.js";
import storage from "juice-base/lib/storage/index.js";

import useTitle from "juice-base/hooks/use-title/index.js";
import useReferralCode from "juice-base/hooks/use-referral-code/index.js";

import LayoutContent from "juice-base/components/layout-content/index.js";
import LogoRound from "juice-base/components/logo-round/index.js";
import MessageDanger from "juice-base/components/message-danger/index.js";
import RequestLoader from "juice-base/components/request-loader/index.js";
import ButtonBig from "juice-base/components/button-big/index.js";

import MessageReferralCode from "juice-base/business/message-referral-code/index.js";
import UserSignUpTrialCreateAccount from "juice-base/forms/user-sign-up-trial-create-account/index.js";

import settings from "juice-app/settings.js";
import api from "juice-app/api.js";

import styles from "./styles.module.css";


const storeSelector = (state) => ({
    userId: state.user.user.userId,
});

const PurchaseView = () => {
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const store = useSelector(storeSelector);

    const referralCode = useReferralCode();

    const [isVisibleSignUp, setIsVisibleSignUp] = useState(false);

    const checkReferralCode = async () => {
        if (!params.referralCode) {
            referralCode.setCodeMissing();
            return;
        }

        const res = await api.signup.validateReferralCode({
            code: params.referralCode,
        });

        let isActive = false;
        let error = null;

        const isTypePayment = res?.data?.type === "payment";

        if (res.ok && isTypePayment) {
            isActive = res.data.isActive;
        } else {
            error = res.error || text.error;
        }

        referralCode.setLoaded(isActive, error);
    };

    const onCreateAccount = (values, { setSubmitting, setErrors }) => {
        dispatch(userActions.signUpByReferralCode({
            api,
            actions,
            storage,
            onError(err) {
                setErrors({
                    formError: err,
                });
                setSubmitting(false);
            },
            onReady() {
                navigate("/");
            },
        }, {
            values,
            referralCode: params.referralCode,
            role: params.role || "",
        }));
    };

    /* --- */

    useTitle(() => "Create an account", []);

    useEffect(() => {
        checkReferralCode();
    }, []);

    /* --- */

    const renderSignUp = () => {
        return (
            <UserSignUpTrialCreateAccount
                passwordMinLength={settings.password.minLength}
                onSubmit={onCreateAccount}
            />
        );
    };

    const renderContent = () => {
        const supportLink = [
            settings.landingSite.domain,
            settings.landingSite.routeSupport,
        ].join("");

        if (!params.referralCode) {
            return (
                <div className={styles.message}>
                    <MessageDanger>
                        Please enter correct referral code
                    </MessageDanger>
                </div>
            );
        }

        if (!referralCode.state.isLoaded) {
            return (
                <RequestLoader />
            );
        }

        if (referralCode.state.error) {
            return (
                <MessageReferralCode
                    supportLink={supportLink}
                >
                    {referralCode.state.error}
                </MessageReferralCode>
            );
        }

        if (!referralCode.state.isActive) {
            return (
                <MessageReferralCode
                    supportLink={supportLink}
                >
                    {`Referral code ${params.referralCode} is not active!`}
                </MessageReferralCode>
            );
        }

        if (isVisibleSignUp) {
            return (
                <div>
                    <div className={styles.contentHeader}>
                        <LogoRound />

                        <div className={styles.formName}>
                            Create an account
                        </div>
                    </div>
                    {renderSignUp()}
                </div>
            );
        }

        return (
            <div className={styles.messageConfirmation}>
                <div className={styles.messageConfirmationHeader}>
                    {text.welcomeToJuice}
                </div>
                <div className={styles.messageConfirmationText}>
                    {text.purchaseSubscription1}
                </div>
                <div className={styles.messageConfirmationText}>
                    {text.purchaseSubscription2}
                </div>
                <ButtonBig
                    onClick={() => {
                        setIsVisibleSignUp(true);
                    }}
                >
                    Next
                </ButtonBig>
            </div>
        );
    };

    if (store.userId) {
        return (
            <Navigate to="/" />
        );
    }

    return (
        <LayoutContent>
            <div className={styles.content}>
                {renderContent()}
            </div>
        </LayoutContent>
    );
};

export default PurchaseView;
