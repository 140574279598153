// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TRSVzO9QI09W3zqtyDSQ {\n    display: grid;\n    grid-auto-flow: column;\n    grid-gap: 2rem;\n\n    justify-content: start;\n    align-items: center;\n\n    padding: 0rem 2rem;\n}\n\n.aJGyXfq2BBO6Pkw_U3D3 {\n    display: grid;\n    grid-auto-flow: column;\n    grid-gap: 1rem;\n\n    align-items: center;\n}\n\n.EXZqnJunwtc1wsULeuzB {\n    min-width: 1rem;\n}\n", "",{"version":3,"sources":["webpack://./base/business/grades-icons/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,cAAc;;IAEd,sBAAsB;IACtB,mBAAmB;;IAEnB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,cAAc;;IAEd,mBAAmB;AACvB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".container {\n    display: grid;\n    grid-auto-flow: column;\n    grid-gap: 2rem;\n\n    justify-content: start;\n    align-items: center;\n\n    padding: 0rem 2rem;\n}\n\n.icons {\n    display: grid;\n    grid-auto-flow: column;\n    grid-gap: 1rem;\n\n    align-items: center;\n}\n\n.icon {\n    min-width: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "TRSVzO9QI09W3zqtyDSQ",
	"icons": "aJGyXfq2BBO6Pkw_U3D3",
	"icon": "EXZqnJunwtc1wsULeuzB"
};
export default ___CSS_LOADER_EXPORT___;
