import React from "react";

import IconWrapper from "juice-base/icons/wrapper/index.js";


const IconTrashWithLinesFull = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    width="20"
                    height="16"
                    viewBox="0 0 20 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={iconProps.className}
                >
                    {iconProps.title}
                    <path
                        fill="currentColor"
                        d="M13 12H17V14H13V12ZM13 4H20V6H13V4ZM13 8H19V10H13V8ZM1 14C1 15.1 1.9 16 3 16H9C10.1 16 11 15.1 11 14V4H1V14ZM12 1H9L8 0H4L3 1H0V3H12V1Z"
                    />
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconTrashWithLinesFull;
