// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TZ2sbD3nlrHU2rNDSIWd {\n    position: fixed;\n    bottom: -8rem;\n    left: 0;\n    right: 0;\n\n    z-index: 100;\n\n    transition: bottom 300ms ease 0s,\n                width 300ms ease 0s;\n}\n\n.RR37ndFdLxSbIAiVEEhH {\n    bottom: 0rem;\n}\n\n.GB2dIstLcnlG1JxEN9mY {\n    bottom: 7rem;\n}\n\n@media only screen and (min-width: 501px) {\n    .TZ2sbD3nlrHU2rNDSIWd {\n        display: grid;\n        grid-template-columns: 50rem;\n        justify-content: center;\n    }\n}\n\n@media only screen and (min-width: 901px) {\n    .RR37ndFdLxSbIAiVEEhH {\n        bottom: 0;\n    }\n\n    .GB2dIstLcnlG1JxEN9mY {\n        bottom: 0;\n    }\n}\n", "",{"version":3,"sources":["webpack://./base/components/popup-player-audio/styles.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,aAAa;IACb,OAAO;IACP,QAAQ;;IAER,YAAY;;IAEZ;mCAC+B;AACnC;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI;QACI,aAAa;QACb,4BAA4B;QAC5B,uBAAuB;IAC3B;AACJ;;AAEA;IACI;QACI,SAAS;IACb;;IAEA;QACI,SAAS;IACb;AACJ","sourcesContent":[".popup {\n    position: fixed;\n    bottom: -8rem;\n    left: 0;\n    right: 0;\n\n    z-index: 100;\n\n    transition: bottom 300ms ease 0s,\n                width 300ms ease 0s;\n}\n\n.popupVisible {\n    bottom: 0rem;\n}\n\n.popupVisibleWithBottomIndent {\n    bottom: 7rem;\n}\n\n@media only screen and (min-width: 501px) {\n    .popup {\n        display: grid;\n        grid-template-columns: 50rem;\n        justify-content: center;\n    }\n}\n\n@media only screen and (min-width: 901px) {\n    .popupVisible {\n        bottom: 0;\n    }\n\n    .popupVisibleWithBottomIndent {\n        bottom: 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popup": "TZ2sbD3nlrHU2rNDSIWd",
	"popupVisible": "RR37ndFdLxSbIAiVEEhH",
	"popupVisibleWithBottomIndent": "GB2dIstLcnlG1JxEN9mY"
};
export default ___CSS_LOADER_EXPORT___;
