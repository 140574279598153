import React from "react";
import { Formik } from "formik";

import Signup from "juice-base/project/signup.js";

import { hasEmptyValue } from "juice-base/lib/object.js";

import Select from "juice-base/components/forms/select/index.js";
import ButtonBig from "juice-base/components/button-big/index.js";

import styles from "./styles.module.css";


const UserSignUpTrialRoles = (props) => {
    const roles = Signup.getSignupOptions();

    const isDisabled = (formProps) => {
        return hasEmptyValue(formProps.values);
    };

    const renderForm = (formProps) => {
        const {
            values,
            handleSubmit,
            setFieldValue,
        } = formProps;

        return (
            <form onSubmit={handleSubmit} className={styles.form}>
                <Select
                    name="role"
                    label="Educators, choose your role *"
                    selected={values.role}
                    options={roles}
                    isOptionsFour
                    onSelect={(value) => {
                        props.onRoleChange(value?.value || "");

                        setFieldValue("role", value);
                    }}
                />

                <div className={styles.submitButtonBlock}>
                    <ButtonBig
                        type="submit"
                        disabled={isDisabled(formProps)}
                    >
                        Next
                    </ButtonBig>
                </div>
            </form>
        );
    };

    return (
        <Formik
            initialValues={props.initialValues}
            onSubmit={props.onSubmit}
        >
            {renderForm}
        </Formik>
    );
};

UserSignUpTrialRoles.defaultProps = {
    initialValues: {
        role: "",
    },
    onSubmit: () => { },
    onRoleChange: () => { },
};

export default UserSignUpTrialRoles;
