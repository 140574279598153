import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import device from "juice-base/lib/device.js";
import actions from "juice-base/store/actions.js";
import vocabularyActions from "juice-base/actions/vocabulary.js";

import AudioManagerContext from "juice-base/context/audio-manager/index.js";

import useTitle from "juice-base/hooks/use-title/index.js";

import { withAuth } from "juice-base/components/auth/index.js";
import LayoutContent from "juice-base/components/layout-content/index.js";
import RequestLoader from "juice-base/components/request-loader/index.js";

import PopupImage from "juice-base/components/popup-image/index.js";
import PopupPlayerAudio from "juice-base/components/popup-player-audio/index.js";
import PopupExtraJuice from "juice-base/components/popup-extra-juice/index.js";

import PopupWord from "juice-base/business/popup-word/index.js";
import DailyJuiceStory from "juice-base/business/daily-juice-story/index.js";

import api from "juice-app/api.js";

import styles from "./styles.module.css";


const storeSelector = (state) => ({
    dimensions: state.device.dimensions,
    session: state.user.session,
    storiesById: state.juiceStories.storiesById,
    wordPopup: state.vocabulary.popup,
    wordsByName: state.vocabulary.wordsByName,
    playerState: state.player.playerState,
});

const StoryView = () => {
    const audioManager = useContext(AudioManagerContext);

    const params = useParams();
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const store = useSelector(storeSelector);

    const [imagePopup, setImagePopup] = useState(null);
    const [audioPlayerPopup, setAudioPlayerPopup] = useState(-1);

    const [extraJuicePopup, setExtraJuicePopup] = useState({
        ejId: -1,
        isVisiblePlayer: false,
    });

    const goBack = () => {
        navigate("/");
    };

    /* --- */

    const onOpenImagePopup = (image) => {
        setImagePopup(image);
    };

    const onCloseImagePopup = () => {
        setImagePopup(null);
    };

    /* --- */

    const onWordClick = (word) => {
        dispatch(vocabularyActions.openPopup({
            api,
            actions,
        }, {
            session: store.session,
            word,
        }));
    };

    const onCloseWordPopup = () => {
        dispatch(vocabularyActions.closePopup({ actions }));
    };

    /* --- */

    const onOpenExtraJuicePopup = (sId, ejId) => {
        if (sId && ejId) {
            setExtraJuicePopup((prev) => ({
                ...prev,
                ejId,
            }));
        }
    };

    const onCloseExtraJuicePopup = () => {
        setExtraJuicePopup((prev) => ({
            ...prev,
            ejId: -1,
        }));
    };

    /* --- */

    const onPlayJuiceStory = (storyId) => {
        setAudioPlayerPopup(storyId);
        audioManager.loadStory(storyId);
    };

    const onCloseAudioPlayerPopup = () => {
        setAudioPlayerPopup(-1);
    };

    /* --- */

    const onShowExtraJuiceStoryPlayer = (eJuiceId) => {
        setExtraJuicePopup((prev) => ({
            ...prev,
            isVisiblePlayer: true,
        }));

        audioManager.loadExtraJuice(eJuiceId);
    };

    const onHideExtraJuiceStoryPlayer = () => {
        setExtraJuicePopup((prev) => ({
            ...prev,
            isVisiblePlayer: false,
        }));
    };

    /* --- */

    const loadStory = async () => {
        if (store.storiesById?.[params.storyId]) {
            return;
        }

        const res = await api.juiceStories.getStoryByStudent({
            session: store.session,
            storyId: params.storyId,
        });

        if (res.ok) {
            const { story } = res;

            dispatch(actions.juiceStories.setStory({
                story,
            }));

            if (story.id !== parseInt(params.storyId, 10)) {
                navigate(`/story/${story.id}`);
            }
        } else {
            goBack();
        }
    };

    useEffect(() => {
        return () => {
            dispatch(vocabularyActions.closePopup({ actions }));
        };
    }, []);

    useEffect(() => {
        loadStory();
    }, [params.storyId]);

    useTitle(() => {
        let title = "Story";

        const story = store.storiesById[params.storyId];

        if (story) {
            title = story.title;
        }

        return title;
    }, [store.storiesById]);

    /* --- */

    const renderImagePopup = () => {
        return (
            <PopupImage
                image={imagePopup}
                onClose={onCloseImagePopup}
            />
        );
    };

    const renderExtraJuicePopup = (story) => {
        if (extraJuicePopup.ejId === -1) {
            return null;
        }

        const eJuice = story.extraJuices[0];

        if (!eJuice) {
            return null;
        }

        const trackGroupName = "extraJuices";
        const trackId = eJuice.id;
        let audioData = null;

        if (extraJuicePopup.isVisiblePlayer) {
            audioData = store.playerState?.[trackGroupName]?.[trackId] || null;
        }

        return (
            <PopupExtraJuice
                key={`popup-extra-juice-${extraJuicePopup.ejId}`}
                storyId={story.id}
                extraJuice={eJuice}
                audio={audioData}
                onWordClick={onWordClick}
                onAudioPlay={() => {
                    onShowExtraJuiceStoryPlayer(eJuice.id);
                }}
                onPlay={() => {
                    audioManager.play(trackGroupName, trackId);
                }}
                onPause={() => {
                    audioManager.pause(trackGroupName, trackId);
                }}
                onRewind={() => {
                    audioManager.rewind(trackGroupName, trackId);
                }}
                onForward={() => {
                    audioManager.forward(trackGroupName, trackId);
                }}
                onChangeRate={(rate) => {
                    audioManager.changeRate(trackGroupName, trackId, rate);
                }}
                onPlayerClose={() => {
                    audioManager.stop(trackGroupName, trackId);
                    onHideExtraJuiceStoryPlayer();
                }}
                onClose={onCloseExtraJuicePopup}
            />
        );
    };

    const renderWordPopup = () => {
        const trackGroupName = "words";
        const audioData = store.playerState?.[trackGroupName] || {};

        return (
            <PopupWord
                wordsByName={store.wordsByName}
                wordPopup={store.wordPopup}
                audio={audioData}
                onAudioLoad={(txt) => {
                    audioManager.loadWord(txt);
                }}
                onAudioPlay={(txt) => {
                    audioManager.play(trackGroupName, txt);
                }}
                onAudioStop={(txt) => {
                    audioManager.stop(trackGroupName, txt);
                }}
                onAudioStopAll={(words) => {
                    audioManager.stopAllTracks(trackGroupName, words);
                }}
                onClose={onCloseWordPopup}
            />
        );
    };

    const renderPlayerAudioPopup = (story) => {
        let title = "";
        let categoryName = "";
        let img = null;
        let trackGroupName = "";
        let trackId = "";
        let audioData = {};

        if (audioPlayerPopup !== -1) {
            title = story.title;
            categoryName = story.categoryName;

            if (story?.featuredImage?.url) {
                img = story.featuredImage.url;
            }

            trackGroupName = "juiceStories";
            trackId = story.id;
        }

        if (!trackGroupName || !trackId) {
            return null;
        }

        audioData = store.playerState?.[trackGroupName]?.[trackId] || {};

        return (
            <PopupPlayerAudio
                key={`player-audio-story-${story.id}`}
                image={img}
                title={title}
                category={categoryName}
                audio={audioData}
                onPlay={() => {
                    audioManager.play(trackGroupName, trackId);
                }}
                onPause={() => {
                    audioManager.pause(trackGroupName, trackId);
                }}
                onRewind={() => {
                    audioManager.rewind(trackGroupName, trackId);
                }}
                onForward={() => {
                    audioManager.forward(trackGroupName, trackId);
                }}
                onChangeRate={(rate) => {
                    audioManager.changeRate(trackGroupName, trackId, rate);
                }}
                onClose={() => {
                    audioManager.stop(trackGroupName, trackId);
                    onCloseAudioPlayerPopup();
                }}
            />
        );
    };

    const renderPopups = (story) => {
        const ps = [];

        if (imagePopup) {
            ps.push(renderImagePopup());
        }

        if (extraJuicePopup.ejId !== -1) {
            const ejPopup = renderExtraJuicePopup(story);

            if (ejPopup) {
                ps.push(ejPopup);
            }
        }

        if (store.wordPopup.isVisible) {
            ps.push(renderWordPopup());
        }

        if (audioPlayerPopup !== -1) {
            ps.push(renderPlayerAudioPopup(story));
        }

        return ps;
    };

    const renderStory = (story) => {
        let videoCaptionSrc = null;

        if (story.featuredVideo?.id) {
            videoCaptionSrc = api.videos.getVideoCaptionURL({
                id: story.featuredVideo.id,
                session: store.session,
            });
        }

        return (
            <DailyJuiceStory
                storyIndex={null}
                story={story}
                dimensions={store.dimensions}
                isDefaultVideo={!device.isChrome}
                videoCaptionSrc={videoCaptionSrc}
                onImageClick={onOpenImagePopup}
                onWordClick={onWordClick}
                onAudioPlay={() => {
                    onPlayJuiceStory(story.id);
                }}
                onExtraJuiceWordClick={onOpenExtraJuicePopup}
            />
        );
    };

    if (!store.storiesById[params.storyId]) {
        return (
            <RequestLoader />
        );
    }

    const story = store.storiesById[params.storyId];

    return (
        <>
            {renderPopups(story)}

            <LayoutContent>
                <div className={styles.story}>
                    {renderStory(story)}
                </div>
            </LayoutContent>
        </>
    );
};

export default withAuth([
    "teacher",
    "student",
    "guardian",
])(StoryView);
