const setTitle = (title) => {
    if (window.document) {
        window.document.title = title;
    }
};

const setTheme = (theme) => {
    const html = document.querySelector("html");

    if (html) {
        html.dataset.theme = theme;
    }
};

export default {
    setTitle,
    setTheme,
};
