import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import Grades from "juice-base/project/grades.js";

import dailyJuiceActions from "juice-base/actions/daily-juice.js";
import actions from "juice-base/store/actions.js";
import storage from "juice-base/lib/storage/index.js";

import RequestLoader from "juice-base/components/request-loader/index.js";

import api from "juice-app/api.js";

import styles from "./styles.module.css";


const getDefaultFilterGrades = (grades) => {
    let defaultValue = "";
    const defaultGrades = storage.local.loadDailyJuicesDefaultGrades();

    if (grades && grades.length > 0) {
        if (defaultGrades) {
            for (let i = 0; i < grades.length; i += 1) {
                const grade = grades[i];
                if (grade.value === defaultGrades) {
                    defaultValue = { ...grade };
                    break;
                }
            }
        } else {
            defaultValue = grades[grades.length - 1];
        }
    }

    return defaultValue;
};

const StudentDailyJuicesTrial = () => {
    const getFilterByGrade = () => {
        const gs = getDefaultFilterGrades(Grades.getGradesOptions());
        return gs ? gs.value : "";
    };

    const [juiceManager, setJuiceManager] = useState({
        error: null,
    });

    const navigate = useNavigate();

    const dispatch = useDispatch();
    const store = useSelector((state) => ({
        session: state.user.session,
        lastVisitedJuice: state.juices.lastVisitedJuice,
        juices: state.juices.juices,
        isJuicesLoaded: state.juices.isJuicesLoaded,
    }));

    /* ----------- */

    const getLastJuice = () => {
        if (store?.juices?.[0]) {
            return store.juices[0];
        }

        return null;
    };

    /* ----------- */

    useEffect(() => {
        if (store.isJuicesLoaded) {
            return;
        }

        dispatch(actions.juices.clearJuices());

        dispatch(dailyJuiceActions.loadDailyJuicesByPage(
            {
                api,
                actions,
            },
            {
                session: store.session,
                page: 0,
                grades: getFilterByGrade().split("-").join(",").toLowerCase(),
            },
        ));
    }, [store.isJuicesLoaded]);

    useEffect(() => {
        if (!store.isJuicesLoaded) {
            return;
        }

        if (store.lastVisitedJuice) {
            navigate(`/daily-juices/${store.lastVisitedJuice}`);
        } else {
            const lastJuice = getLastJuice();

            if (!lastJuice?.id) {
                setJuiceManager({
                    error: "Failed to load juice.",
                });
            } else {
                navigate(`/daily-juices/${lastJuice.id}`);
            }
        }
    }, [store.lastVisitedJuice, store.isJuicesLoaded]);

    /* ----------- */

    if (!store.lastVisitedJuice || !store.isJuicesLoaded) {
        return (
            <RequestLoader />
        );
    }

    if (juiceManager.error) {
        return (
            <div className={styles.error}>
                {juiceManager.error}
            </div>
        );
    }

    return null;
};

export default StudentDailyJuicesTrial;
