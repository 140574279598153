import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import User from "juice-base/project/user.js";

import { withAuth } from "juice-base/components/auth/index.js";

import Search from "juice-app/containers/search/index.js";


const storeSelector = (state) => ({
    user: state.user.user,
});

const ShowSearch = () => {
    const navigate = useNavigate();

    const store = useSelector(storeSelector);

    if (User.isTypeTrial(store.user)) {
        navigate("/daily-juices");
        return null;
    }

    return (
        <Search />
    );
};

export default withAuth([
    "teacher",
    "student",
    "guardian",
])(ShowSearch);
