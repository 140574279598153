import React from "react";

import IconWrapper from "juice-base/icons/wrapper/index.js";


const IconFullscreenInCircle = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    width="34"
                    height="34"
                    viewBox="0 0 34 34"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={iconProps.className}
                >
                    <path
                        fill="currentColor"
                        d="M0,17A17,17,0,1,1,17,34,17,17,0,0,1,0,17Z"
                    />
                    <path
                        fill="#ffffff"
                        d="M22.25,17h-1.5v2.25H18.5v1.5h3.75Zm-9-2.25H15.5v-1.5H11.75V17h1.5Zm10.5-4.5H10.25a1.5,1.5,0,0,0-1.5,1.5v10.5a1.5,1.5,0,0,0,1.5,1.5h13.5a1.5,1.5,0,0,0,1.5-1.5V11.75A1.5,1.5,0,0,0,23.75,10.25Zm0,12H10.25V11.74h13.5Z"
                    />
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconFullscreenInCircle;
