export const getStoryPoll = (story) => {
    if (!story?.polls || story?.polls?.length === 0) {
        return null;
    }

    const poll = story.polls[0];

    let isLoading = false;
    let isDisabled = false;
    let error = "";

    if (poll?.isLoading) {
        isLoading = true;
    }

    if (isLoading || poll?.userAnswerId) {
        isDisabled = true;
    }

    if (poll?.error) {
        error = poll.error;
    }

    return {
        isDisabled,
        isLoading,
        userAnswerId: poll.userAnswerId,
        error,
        data: poll,
    };
};


export default {
    getStoryPoll,
};
