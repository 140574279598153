import React from "react";

import useTitle from "juice-base/hooks/use-title/index.js";

import { withAuth } from "juice-base/components/auth/index.js";

import TeacherDashboardV2 from "juice-app/containers/teacher-dashboard-v2/index.js";


const Dashboard = () => {
    useTitle(() => "Dashboard", []);

    return (
        <TeacherDashboardV2 />
    );
};

export default withAuth([
    "teacher",
])(Dashboard);
