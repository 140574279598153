import React from "react";

import classNames from "juice-base/lib/class-names.js";

import RequestLoader from "juice-base/components/request-loader/index.js";
import PopupWindow from "juice-base/components/popup-window/index.js";

import styles from "./styles.module.css";


const PopupQuizReview = (props) => {
    const renderContent = () => {
        if (!props.isLoaded) {
            return <RequestLoader />;
        }

        const questions = props.questions.map((quiz, index) => {
            const answers = [];
            const studentAnswers = [];

            let studentAnswersCorrect = true;

            for (let i = 0; i < quiz.answers.length; i += 1) {
                const answerClassNames = classNames({
                    [styles.answer]: true,
                    [styles.correctAnswer]: quiz.answers[i].is_correct_answer,
                });

                if (quiz.answers[i].is_user_choice) {
                    studentAnswers.push(i + 1);
                }

                if (quiz.answers[i].is_user_choice && !quiz.answers[i].is_correct_answer) {
                    studentAnswersCorrect = false;
                }

                answers.push(
                    <div className={answerClassNames}>
                        {`${i + 1}.  ${quiz.answers[i].answer}`}
                    </div>,
                );
            }

            let studentAnswerd = (
                <div className={[styles.studentAnswer, styles.incorrect].join(" ")}>
                    incorrect
                </div>
            );

            if (studentAnswersCorrect) {
                studentAnswerd = (
                    <div className={[styles.studentAnswer, styles.correct].join(" ")}>
                        correct
                    </div>
                );
            }

            return (
                <div className={styles.quiz}>
                    <div className={styles.question}>
                        <div className={styles.qustionSmall}>
                            {`question ${index + 1}`}
                        </div>
                        <div className={styles.questionTitle}>
                            {quiz.question}
                        </div>
                    </div>
                    <div className={styles.studentAnswersBlock}>
                        <div className={styles.studentAnswers}>
                            {`Your Answer: ${studentAnswers.join(", ")}`}
                        </div>
                        {studentAnswerd}
                    </div>
                    <div>
                        {answers}
                    </div>
                </div>
            );
        });

        return questions;
    };

    return (
        <PopupWindow
            title="Quiz review"
            onClose={props.onClose}
        >
            {renderContent()}
        </PopupWindow>
    );
};

PopupQuizReview.defaultProps = {
    questions: [],
    onClose: () => { },
    isLoaded: false,
};

export default PopupQuizReview;
