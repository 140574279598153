import React from "react";

import RequestLoader from "juice-base/components/request-loader/index.js";
import ButtonFlat from "juice-base/components/button-flat/index.js";
import PopupConfirm, {
    PopupConfirmContent,
    PopupConfirmButtons,
} from "juice-base/components/popup-confirm/index.js";


const PopupConfirmDeleteClass = (props) => {
    let content = `
        Are you sure you want to delete ${props.className}?
        You will lose access to all student activity data,
        and students in this class will no longer be able to access articles in The Juice.
    `;

    if (props.isLoading) {
        content = (
            <RequestLoader />
        );
    }

    if (props.error) {
        content = props.error;
    }

    return (
        <PopupConfirm>
            <PopupConfirmContent>
                {content}
            </PopupConfirmContent>

            <PopupConfirmButtons>
                <ButtonFlat
                    disabled={props.isLoading}
                    onClick={props.onClose}
                >
                    Cancel
                </ButtonFlat>
                <ButtonFlat
                    disabled={props.isLoading || props.error}
                    onClick={props.onDelete}
                >
                    Delete
                </ButtonFlat>
            </PopupConfirmButtons>
        </PopupConfirm>
    );
};

PopupConfirmDeleteClass.defaultProps = {
    className: "",

    isLoading: false,
    error: null,

    onDelete: () => {},
    onClose: () => {},
};

export default PopupConfirmDeleteClass;
