import * as types from "./types.js";


export const setUserLoading = (isUserLoading) => ({
    type: types.SET_USER_LOADING,
    payload: {
        isUserLoading,
    },
});

export const setUserSession = (session) => ({
    type: types.SET_USER_SESSION,
    payload: {
        session,
    },
});

export const setUser = (user) => ({
    type: types.SET_USER,
    payload: {
        user,
    },
});

export const clearUser = () => ({
    type: types.CLEAR_USER,
});

export const setUserOptions = (payload) => ({
    type: types.SET_USER_OPTIONS,
    payload,
});

export const setUserOption = (payload) => ({
    type: types.SET_USER_OPTION,
    payload,
});

export const setUserEmailNotification = (payload) => ({
    type: types.SET_USER_EMAIL_NOTIFICATION,
    payload,
});

export const setUserAutoRenewSubscription = (payload) => ({
    type: types.SET_USER_AUTO_RENEW_SUBSCRIPTION,
    payload,
});

export const setUserAvatar = (payload) => ({
    type: types.SET_USER_AVATAR,
    payload,
});

export const setUserPaymentDetails = (payload) => ({
    type: types.SET_USER_PAYMENT_DETAILS,
    payload,
});

export const setUserPaymentDetailsLoaded = (payload) => ({
    type: types.SET_USER_PAYMENT_DETAILS_LOADED,
    payload,
});

export const setTeacherWelcomePopupOpened = (payload) => ({
    type: types.SET_TEACHER_WELCOME_POPUP_OPENED,
    payload,
});

export const setTeacherWelcomePopupClosed = (payload) => ({
    type: types.SET_TEACHER_WELCOME_POPUP_CLOSED,
    payload,
});
