// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IYpsTJkF4qWERuU1da0L {\n    display: grid;\n    grid-gap: 1rem;\n\n    max-width: 40rem;\n\n    margin: auto;\n}", "",{"version":3,"sources":["webpack://./app/containers/teacher-daily-juices-student-edition/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,cAAc;;IAEd,gBAAgB;;IAEhB,YAAY;AAChB","sourcesContent":[".error {\n    display: grid;\n    grid-gap: 1rem;\n\n    max-width: 40rem;\n\n    margin: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": "IYpsTJkF4qWERuU1da0L"
};
export default ___CSS_LOADER_EXPORT___;
