import React from "react";

import classNames from "juice-base/lib/class-names.js";

import SIZES from "./sizes.js";
import styles from "./styles.module.css";


const BlockProgressLegend = (props) => {
    const renderBar = (i) => {
        if (props.isAverageScore) {
            return (
                <div
                    className={styles.barLegendPoint}
                    style={{
                        height: `${SIZES.barHeightRem * 0.75}rem`,
                        width: `${SIZES.barHeightRem * 0.75}rem`,
                    }}
                />
            );
        }

        const barClassNames = classNames({
            [styles.bar]: true,
            [styles.barLegend]: true,
            [styles.bar1]: i % props.names.length === 0,
            [styles.bar2]: i % props.names.length === 1,
            [styles.bar3]: i % props.names.length === 2,
        });

        return (
            <div
                className={barClassNames}
                style={{
                    height: `${SIZES.barHeightRem}rem`,
                }}
            />
        );
    };

    const legend = [];

    for (let i = 0; i < props.names.length; i += 1) {
        legend.push(
            <div className={styles.legendRow}>
                {renderBar(i)}
                {props.names[i]}
            </div>,
        );
    }

    const legendClassName = classNames({
        [styles.legend]: true,
        [props.className]: props.className,
    });

    return (
        <div className={legendClassName}>
            {legend}
        </div>
    );
};

BlockProgressLegend.defaultProps = {
    className: "",
    names: [],
    isAverageScore: false,
};

export default BlockProgressLegend;
