import React, { useState } from "react";

import IconPlayerHeadphone from "juice-base/icons/player-headphone/index.js";
import IconOrange from "juice-base/icons/orange/index.js";

import JuiceContent from "juice-base/components/juice-content/index.js";
import ButtonCircle from "juice-base/components/button-circle/index.js";
import PlayerAudio from "juice-base/components/player-audio/index.js";
import PopupWindow from "juice-base/components/popup-window/index.js";

import styles from "./styles.module.css";


const PopupExtraJuice = (props) => {
    const { storyId, extraJuice } = props;

    const [isPlayerExpanded, setIsPlayerExpanded] = useState(false);

    const renderTitle = () => {
        return (
            <div className={styles.headerTitle}>
                <IconOrange
                    className={styles.headerTitleIcon}
                    title="Extra Juice"
                />
                <div className={styles.headerTitleText}>
                    {extraJuice.title}
                </div>
            </div>
        );
    };

    const renderPlayer = () => {
        if (!props.audio) {
            return null;
        }

        return (
            <PlayerAudio
                image={extraJuice.image || ""}
                title={extraJuice.title}
                category="Extra Juice"
                audio={props.audio}
                error={props.audio.error || ""}
                isExpanded={isPlayerExpanded}
                onFirstPlay={props.onFirstPlay}
                onPlay={props.onPlay}
                onPause={props.onPause}
                onRewind={props.onRewind}
                onForward={props.onForward}
                onChangeRate={props.onChangeRate}
                onExpand={() => {
                    setIsPlayerExpanded((prev) => !prev);
                }}
                onClose={props.onPlayerClose}
            />
        );
    };

    if (storyId === -1 || !extraJuice) {
        return null;
    }

    const iconHeadPhone = (
        <IconPlayerHeadphone
            isOrange
        />
    );

    const audioPlayer = (
        <ButtonCircle
            icon={iconHeadPhone}
            onClick={() => {
                if (!props.audio) {
                    props.onAudioPlay();
                }
            }}
        />
    );

    const titleBoxStyles = {};

    if (extraJuice.image) {
        titleBoxStyles.backgroundImage = `url(${extraJuice.image})`;
    }

    return (
        <PopupWindow
            title={renderTitle()}
            rightControl={audioPlayer}
            player={renderPlayer()}
            playerExpanded={isPlayerExpanded}
            hideScrollbar={props.hideScrollbar}
            onClose={props.onClose}
        >
            <div className={styles.popupContent}>
                <div
                    className={styles.titleBox}
                    style={titleBoxStyles}
                >
                    {extraJuice.title}
                </div>

                <JuiceContent
                    storyId={props.storyId}
                    content={extraJuice.content}
                    onWordClick={(word) => {
                        props.onWordClick(word);
                    }}
                    className={styles.content}
                />
            </div>
        </PopupWindow>
    );
};

PopupExtraJuice.defaultProps = {
    storyId: -1,
    extraJuice: null,
    hideScrollbar: true,
    onWordClick: () => { },

    onFirstPlay: () => { },
    onPlay: () => { },
    onPause: () => { },
    onRewind: () => { },
    onForward: () => { },
    onChangeRate: () => { },
    onPlayerClose: () => { },

    onClose: () => { },
};

export default PopupExtraJuice;
