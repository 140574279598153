import React from "react";

import IconInformation from "juice-base/icons/information/index.js";
import symbols from "juice-base/lib/symbols.js";
import TextLine from "juice-base/components/text-line/index.js";

import styles from "./styles.module.css";


const MenuContentRow = (props) => {
    let labelValue = null;

    let tooltip = null;

    if (props.tooltip) {
        tooltip = (
            <div className={styles.tooltip}>
                <IconInformation
                    isRed
                />
                <div className={styles.tooltipMessage}>
                    {props.tooltip}
                </div>
            </div>
        );
    }

    if (props.labelOnly) {
        labelValue = (
            <div className={styles.rowLabelOnly}>
                {props.label}
            </div>
        );
    } else {
        labelValue = (
            <>
                <div className={styles.rowLabel}>
                    {props.label}
                </div>
                <div className={styles.rowContentValue}>
                    <TextLine className={styles.rowValue}>
                        {props.value || symbols.nbsp}
                    </TextLine>
                    {tooltip}
                </div>
            </>
        );
    }

    return (
        <div className={styles.row}>
            <div className={styles.rowContent}>
                {labelValue}
            </div>
            <div className={styles.rowIcon}>
                {props.icon}
            </div>
        </div>
    );
};

MenuContentRow.defaultProps = {
    label: "",
    value: "",
    icon: null,
    tooltip: "",
    labelOnly: false,
};

export default MenuContentRow;
