import React, { useState } from "react";

import classNames from "juice-base/lib/class-names.js";
import {
    tryFormatDate,
    formatMonthDayYearDate,
} from "juice-base/lib/date.js";

import IconPlayerHeadphone from "juice-base/icons/player-headphone/index.js";
import IconOrange from "juice-base/icons/orange/index.js";
import IconCopyWithArrow from "juice-base/icons/copy-with-arrow/index.js";

import Image from "juice-base/components/image/index.js";
import JuiceContentFirstLines from "juice-base/components/juice-content-first-lines/index.js";
import PlayerAudio from "juice-base/components/player-audio/index.js";
import ButtonShare from "juice-base/components/button-share/index.js";
import ButtonCircle from "juice-base/components/button-circle/index.js";

import styles from "./styles.module.css";


const SearchCard = (props) => {
    const [isPlayerVisible, setPlayerVisible] = useState(false);

    const onClosePlayer = () => {
        props.onClose();
        setPlayerVisible(false);
    };

    const onOpenPlayer = () => {
        setPlayerVisible(true);
    };

    const onShareButtonClick = (evt) => {
        evt.stopPropagation();
        props.share.onClick();
    };

    const onAssignmentsClick = (evt) => {
        evt.stopPropagation();
        props.onAssignmentsClick();
    };

    const renderShareButton = () => {
        if (!props.withShareButton) {
            return null;
        }

        return (
            <ButtonShare
                googleClassroom={props.share.googleClassroom}
                onCloseMenu={props.share.onCloseMenu}
                onCopyLink={props.share.onCopyLink}
                onClick={onShareButtonClick}
                isGoogleClassroomLoading={props.share.isGoogleClassroomLoading}
                isCopyLinkLoading={props.share.isCopyLinkLoading}
                closeMenuOnClassroomClick={props.share.closeMenuOnClassroomClick}
                closeMenuOnCopyLinkClick={props.share.closeMenuOnCopyLinkClick}
                isVisibleMenu={props.share.isVisibleMenu}
                isMenuOpensDown={!props.isCardsView}
                isAuto={false}
            />
        );
    };

    const renderAssignButton = () => {
        if (!props.withAssignButton) {
            return null;
        }

        const assignIcon = (
            <IconCopyWithArrow
                isOrange
            />
        );

        return (
            <ButtonCircle
                icon={assignIcon}
                onClick={onAssignmentsClick}
            />
        );
    };

    const renderFeaturedImage = () => {
        if (!props?.featuredImage?.smallSrc || !props?.featuredImage?.largeSrc) {
            return null;
        }

        const isInfographicImage = props?.featuredImage?.infographic || false;

        const imgClassName = classNames({
            [styles.featuredImageList]: !props.isCardsView,

            [styles.infographicImageCard]: props.isCardsView && isInfographicImage,
            [styles.featuredImageCard]: props.isCardsView && !isInfographicImage,
        });

        let imgSrc = "";

        if (props.isCardsView) {
            imgSrc = props.featuredImage.largeSrc;
        } else {
            imgSrc = props.featuredImage.smallSrc;
        }

        return (
            <div
                className={imgClassName}
                onClick={props.onCardClick}
                onKeyPress={props.onCardClick}
                tabIndex="-1"
                role="button"
            >
                <Image
                    src={imgSrc}
                    title="Featured"
                />
            </div>
        );
    };

    const renderListenButton = () => {
        if (props.type === "video") {
            return null;
        }

        const icon = (
            <IconPlayerHeadphone
                title="Headphone"
                isOrange
            />
        );

        if (!props.isCardsView) {
            return (
                <ButtonCircle
                    icon={icon}
                    onClick={props.onCardClickAndPlay}
                />
            );
        }

        return (
            <ButtonCircle
                icon={icon}
                onClick={onOpenPlayer}
            />
        );
    };

    const renderCategory = () => {
        let categoryTitle = null;

        if (props.category) {
            categoryTitle = props.category;
        }

        if (props.type === "extra juice" && !props.isCardsView) {
            categoryTitle = "Extra juice";
        }

        if (!categoryTitle) {
            return null;
        }

        return (
            <div className={styles.categoryTitle}>
                {categoryTitle}
            </div>
        );
    };

    const renderDatePublished = () => {
        const dateClassName = classNames({
            [styles.datePublished]: true,
            [styles.datePublishedList]: !props.isCardsView,
        });

        const date = tryFormatDate(props.datePublished, formatMonthDayYearDate);

        return (
            <div className={dateClassName}>
                {date}
            </div>
        );
    };

    const renderTitle = (withEJCategory = false) => {
        let title = (
            <div className={styles.cardTitle}>
                {props.title}
            </div>
        );

        if (props.type === "extra juice") {
            let category = null;

            if (withEJCategory) {
                category = (renderCategory());
            }

            const extraJuiceTitleClassName = classNames({
                [styles.extraJuiceTitle]: true,
                [styles.listExtraJuiceTitle]: !props.isCardsView,
            });

            let datePublished = null;

            if (!props.isCardsView) {
                datePublished = renderDatePublished();
            }

            title = (
                <div className={extraJuiceTitleClassName}>
                    <IconOrange
                        className={styles.extraJuiceTitleIcon}
                        title="Extra Juice"
                    />
                    <div className={styles.storyTitle}>
                        {category}
                        {props.title}
                        {datePublished}
                    </div>
                </div>
            );
        }

        return title;
    };

    const renderAudioPlayer = () => {
        if (!isPlayerVisible) {
            return null;
        }

        let imageSrc = "";

        if (props?.featuredImage?.smallSrc) {
            imageSrc = props.featuredImage.smallSrc;
        }

        return (
            <PlayerAudio
                key={`player-audio-story-${props.cardId}`}
                image={imageSrc}
                title={props.title}
                category={props.category}
                audio={props.audioData}
                onFirstPlay={props.onFirstPlay}
                onPlay={props.onPlay}
                onPause={props.onPause}
                onRewind={props.onRewind}
                onForward={props.onForward}
                onChangeRate={props.onChangeRate}
                onClose={onClosePlayer}
            />
        );
    };

    const listenButton = renderListenButton();
    const shareButton = renderShareButton();
    const assignButton = renderAssignButton();

    if (!props.isCardsView) {
        const storyClassName = classNames({
            [styles.storyList]: true,
            [styles.storyListWithPadding]: props.withPadding,
            [styles.storyListWithImage]: props?.featuredImage?.smallSrc,
        });

        let category = null;

        let datePublished = null;

        if (props.type !== "extra juice") {
            category = renderCategory();
            datePublished = renderDatePublished();
        }

        return (
            <div className={styles.storyListWrapper}>
                <div
                    className={storyClassName}
                    onClick={props.onCardClick}
                    onKeyPress={props.onCardClick}
                    role="button"
                    tabIndex="-1"
                >
                    {renderFeaturedImage()}
                    <div className={styles.storyListContent}>
                        <div className={styles.listContentBody}>
                            {category}
                            {renderTitle(true)}
                            {datePublished}
                        </div>
                        <div className={styles.storyListControls}>
                            {listenButton}
                            {shareButton}
                            {assignButton}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={styles.storyCard}>
            {renderFeaturedImage()}

            <div className={styles.storyCardContent}>
                <div
                    className={styles.cardContentBody}
                    onClick={props.onCardClick}
                    onKeyPress={props.onCardClick}
                    tabIndex="-1"
                    role="button"
                >
                    {renderCategory()}

                    {renderTitle()}

                    <div className={styles.cardStoryContent}>
                        <JuiceContentFirstLines
                            content={props.content}
                        />
                    </div>
                </div>

                <div className={styles.cardListenButton}>
                    {renderDatePublished()}

                    <div className={styles.cardControls}>
                        {listenButton}
                        {shareButton}
                        {assignButton}
                    </div>
                </div>

                <div className={styles.audioPlayer}>
                    {renderAudioPlayer()}
                </div>
            </div>
        </div>
    );
};

SearchCard.defaultProps = {
    cardId: null,
    datePublished: null,
    type: "article",
    featuredImage: {},
    category: null,
    title: "",
    content: "",
    status: "",
    share: {},

    onCardClick: () => { },
    onCardClickAndPlay: () => { },
    onAssignmentsClick: () => { },

    isCardsView: false,
    withPadding: false,
    withShareButton: false,
    withAssignButton: false,
};

export default SearchCard;
