import React from "react";
import { Formik } from "formik";

import IconMagnifier from "juice-base/icons/magnifier/index.js";

import InputSearch from "juice-base/components/forms/input-search/index.js";

import styles from "./styles.module.css";


const SearchForm = (props) => {
    const renderTextSearchIcon = () => {
        return (
            <div className={styles.textSearchButton}>
                <IconMagnifier
                    title="Search"
                    className={styles.textSearchButtonIcon}
                    isOrange
                />
            </div>
        );
    };

    const renderForm = (formProps) => {
        const {
            values,
            handleChange,
            handleSubmit,
        } = formProps;

        return (
            <form onSubmit={handleSubmit} className={styles.form}>
                <div className={styles.textSearcher}>
                    {renderTextSearchIcon()}
                    <InputSearch
                        value={values.search}
                        onChange={(evt) => {
                            handleChange(evt);
                            const value = evt.target.value || "";
                            props.onChange(value.trim());
                        }}
                    />
                </div>
            </form>
        );
    };

    return (
        <Formik
            onSubmit={props.onSubmit}
            initialValues={props.initialValues}
        >
            {renderForm}
        </Formik>
    );
};

SearchForm.defaultProps = {
    initialValues: {},
    onChange: () => { },
    onSubmit: () => { },
};

export default SearchForm;
