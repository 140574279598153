import React from "react";

import classNames from "juice-base/lib/class-names.js";

import IconCaret from "juice-base/icons/caret/index.js";

import styles from "./styles.module.css";


const SelectWithPopup = (props) => {
    const onOpenPopup = () => {
        if (props.disabled) {
            return;
        }

        props.onOpenPopup();
    };

    /* ---- */

    const renderIcon = () => {
        if (props.icon) {
            return (
                <div className={styles.selectCustomIcon}>
                    {props.icon}
                </div>
            );
        }

        return null;
    };

    const renderCount = () => {
        if (props.count === 0) {
            return null;
        }

        const countClassName = classNames({
            [styles.countCircle]: true,
            [styles.countCircleRose]: props.roseTheme,
        });

        return (
            <div className={styles.count}>
                <div className={countClassName}>
                    {props.count}
                </div>
            </div>
        );
    };

    const selectCustomClassName = classNames({
        [styles.selectCustom]: true,
        [styles.selectCustomDisabled]: props.disabled,
    });

    return (
        <div className={styles.selectContainer}>
            <div
                className={selectCustomClassName}
                onKeyPress={onOpenPopup}
                onClick={onOpenPopup}
                role="button"
                tabIndex="-1"
            >
                <div className={styles.selectCustomIconContainer}>
                    {renderIcon()}
                    {renderCount()}
                </div>
                <div className={styles.selectCustomTitle}>
                    {props.name}
                </div>
                <IconCaret
                    className={styles.iconCaret}
                />
            </div>
        </div>
    );
};

SelectWithPopup.defaultProps = {
    name: "",
    icon: null,
    count: 0,

    onOpenPopup: () => { },

    disabled: false,
    roseTheme: false,
};

export default SelectWithPopup;
