import React, { useEffect, useState } from "react";

import classNames from "juice-base/lib/class-names.js";

import Grades from "juice-base/project/grades.js";

import IconPlusInRing from "juice-base/icons/plus-in-ring/index.js";
import IconTrashWithLinesFull from "juice-base/icons/trash-with-lines-full/index.js";

import ButtonBig from "juice-base/components/button-big/index.js";

import PopupConfirmClassStudentsLimit from "juice-base/business/popup-confirm-class-students-limit/index.js";

import StudentAddForm from "juice-base/forms/student-add/index.js";

import styles from "./styles.module.css";


const TeacherAddStudentsRows = (props) => {
    const rowsValues = {
        nameValue: "",
        nameError: null,

        lastNameValue: "",
        lastNameError: null,

        emailValue: "",
        emailError: null,

        gradeValue: props.defaultGrade,
        gradeError: null,
    };

    const [isVisibleClassStudentsLimitPopup, setIsVisibleClassStudentsLimitPopup] = useState(false);

    const [isRowsEdited, setIsRowsEdited] = useState(false);

    const [rows, setRows] = useState([
        { ...rowsValues },
    ]);

    useEffect(() => {
        if (!isRowsEdited && props.rows.length > 0) {
            setRows(props.rows);
        }
    }, [props.rows]);

    const isRowsLimitReached = () => {
        if (props.noRowsLimit) {
            return false;
        }

        if (rows.length >= props.rowsLimit) {
            return true;
        }

        return false;
    };

    const isRowsMoreThanLimit = () => {
        if (props.noRowsLimit) {
            return false;
        }

        if (rows.length > props.rowsLimit) {
            return true;
        }

        return false;
    };

    const isRowsHasErrors = () => {
        if (isRowsMoreThanLimit()) {
            return true;
        }

        for (let i = 0; i < rows.length; i += 1) {
            if ((rows[i].nameError || !rows[i].nameValue)
                || (rows[i].lastNameError || !rows[i].lastNameValue)
                || rows[i].emailError
                || !rows[i].gradeValue || rows[i].gradeError) {
                return true;
            }
        }

        return false;
    };

    const onAddStudentsToClass = () => {
        if (isRowsHasErrors()) {
            return;
        }

        props.onAddStudentsToClass(rows);

        setRows([
            { ...rowsValues },
        ]);
    };

    const onProccedToConfirmation = () => {
        if (isRowsHasErrors()) {
            return;
        }

        props.onProccedToConfirmation(rows);
    };

    const onImportAddStudents = () => {
        if (isRowsHasErrors()) {
            return;
        }

        props.onImportAddStudents(rows);
    };

    const onSetIsEdited = () => {
        if (!isRowsEdited) {
            setIsRowsEdited(true);
        }
    };

    const onAddRow = () => {
        if (isRowsLimitReached()) {
            setIsVisibleClassStudentsLimitPopup(true);
            return;
        }

        setRows([
            ...rows,
            { ...rowsValues },
        ]);

        onSetIsEdited();
    };

    const onSetRows = (newRows) => {
        onSetIsEdited();

        setRows(newRows);
    };

    const onRemoveRow = (idx) => {
        props.onRemoveStudent({
            onUndo: () => {
                onSetRows(rows);
            },
            row: rows[idx],
        });

        const newRows = rows.filter((row, index) => index !== idx);

        onSetRows(newRows);
    };

    const onUploadDifferentFile = () => {
        setIsRowsEdited(false);

        setRows([
            { ...rowsValues },
        ]);

        props.onUploadDifferentFile();
    };

    const onChangeRow = (params = {}) => {
        const { index, value, error } = params;

        let keyName = "name";
        let keyError = "error";

        if (params.isName) {
            keyName = "nameValue";
            keyError = "nameError";
        } else if (params.isLastname) {
            keyName = "lastNameValue";
            keyError = "lastNameError";
        } else if (params.isEmail) {
            keyName = "emailValue";
            keyError = "emailError";
        } else if (params.isGrade) {
            keyName = "gradeValue";
            keyError = "gradeError";
        }

        const newRows = [...rows];

        newRows[index] = {
            ...newRows[index],
            [keyName]: value || "",
            [keyError]: error || null,
        };

        setRows(newRows);
        onSetIsEdited();
    };

    const renderControlsImported = () => {
        if (props.preview) {
            return (
                <div className={styles.sectionButtonsInColumn}>
                    <ButtonBig
                        onClick={props.onImportBackToEdit}
                        outlined
                    >
                        Back to edit
                    </ButtonBig>
                    <ButtonBig
                        onClick={onImportAddStudents}
                        disabled={isRowsHasErrors()}
                    >
                        Add students
                    </ButtonBig>
                </div>
            );
        }

        let controls = null;

        if (rows.length !== 0) {
            controls = (
                <div className={styles.sectionButtonsInColumn}>
                    <ButtonBig
                        onClick={onUploadDifferentFile}
                        outlined
                    >
                        Upload a different file
                    </ButtonBig>
                    <ButtonBig
                        onClick={onProccedToConfirmation}
                        disabled={isRowsHasErrors()}
                    >
                        Proceed to confirmation
                    </ButtonBig>
                </div>
            );
        }

        return controls;
    };

    const renderControls = () => {
        if (props.imported) {
            return renderControlsImported();
        }

        const buttonDisabled = isRowsLimitReached();

        const addAnotherStudentButtonClassName = classNames({
            [styles.addAnotherStudentButton]: true,
            [styles.addAnotherStudentButtonDisabled]: buttonDisabled,
        });

        return (
            <div className={styles.sectionButtonsInRow}>
                <div
                    className={addAnotherStudentButtonClassName}
                    onClick={onAddRow}
                    onKeyPress={onAddRow}
                    role="button"
                    tabIndex="-1"
                >
                    <IconPlusInRing
                        isSky={!buttonDisabled}
                    />
                    <div>
                        Add another student
                    </div>
                </div>
                <ButtonBig
                    disabled={isRowsHasErrors()}
                    onClick={onAddStudentsToClass}
                    uppercase
                >
                    Add students to class
                </ButtonBig>
            </div>
        );
    };

    const onCloseClassStudentsLimitPopup = () => {
        setIsVisibleClassStudentsLimitPopup(false);
    };

    const onContactSupport = () => {
        window.open(props.supportLink, "_blank");
    };

    const renderClassStudentsLimitPopup = () => {
        if (!isVisibleClassStudentsLimitPopup) {
            return null;
        }

        return (
            <PopupConfirmClassStudentsLimit
                onContactSupport={onContactSupport}
                onClose={onCloseClassStudentsLimitPopup}
            />
        );
    };

    const renderRemoveButton = (onRemoveFn) => {
        return (
            <div
                className={styles.removeRowButton}
                onClick={onRemoveFn}
                onKeyPress={onRemoveFn}
                tabIndex="-1"
                role="button"
            >
                <IconTrashWithLinesFull
                    title="Remove row"
                />
            </div>
        );
    };

    const renderAddStudentForm = (params = {}) => {
        const { row } = params;

        let selectedGradeValue = row.gradeValue;

        if (!isRowsEdited && !selectedGradeValue) {
            selectedGradeValue = props.defaultGrade;
        }

        const initValues = {
            firstname: row.nameValue,
            lastname: row.lastNameValue,
            email: row.emailValue,
            grade: {
                value: selectedGradeValue,
                isCreated: false,
            },
        };

        const rowClassName = classNames({
            [styles.contentRowFields]: true,
            [styles.rowWithTrash]: params.removeButton,
            [styles.rowWithRoseBackground]: !props.noRowsLimit
                && params.index > props.rowsLimit - 1,
        });

        return (
            <StudentAddForm
                id={params.index}
                className={rowClassName}
                initialValues={initValues}
                grades={props.grades}
                removeButton={params.removeButton}
                validateOnMount={props.imported}
                onChangeFirstname={(values) => {
                    onChangeRow({
                        isName: true,
                        index: params.index,
                        ...values,
                    });
                }}
                onChangeLastname={(values) => {
                    onChangeRow({
                        isLastname: true,
                        index: params.index,
                        ...values,
                    });
                }}
                onChangeEmail={(values) => {
                    onChangeRow({
                        isEmail: true,
                        index: params.index,
                        ...values,
                    });
                }}
                onChangeGrade={(values) => {
                    onChangeRow({
                        isGrade: true,
                        index: params.index,
                        ...values,
                    });
                }}
            />
        );
    };

    const renderPreviewField = (label, value) => {
        return (
            <div className={styles.previewField}>
                <div className={styles.previewFieldLabel}>
                    {label}
                </div>
                <div>{value}</div>
            </div>
        );
    };

    const renderAddStudentFormPreview = (params) => {
        const { row } = params;

        const gradeName = Grades.getGradeNameByValue(row.gradeValue);

        return (
            <div className={styles.previewRowFields}>
                {renderPreviewField("First Name *", row.nameValue)}
                {renderPreviewField("Last Name *", row.lastNameValue)}
                {renderPreviewField("Email", row.emailValue)}
                {renderPreviewField("Reading level", gradeName || row.gradeValue)}
            </div>
        );
    };

    const renderRows = () => {
        const isMoreThanOneRow = rows.length > 1;

        return rows.map((row, idx) => {
            let removeRowButton = null;

            if (isMoreThanOneRow && props.editable) {
                removeRowButton = renderRemoveButton(() => { onRemoveRow(idx); });
            }

            let form = null;

            if (props.editable) {
                form = renderAddStudentForm({
                    index: idx,
                    row,
                    removeButton: removeRowButton,
                });
            } else {
                form = renderAddStudentFormPreview({
                    idx,
                    row,
                });
            }

            return form;
        });
    };

    return (
        <>
            {renderClassStudentsLimitPopup()}
            <div className={styles.content}>
                {renderRows()}
                {renderControls()}
            </div>
        </>
    );
};

TeacherAddStudentsRows.defaultProps = {
    editable: false,
    imported: false,
    preview: false,

    rowsLimit: 5,
    noRowsLimit: false,

    defaultGrade: "G7-G8",
    grades: [],

    rows: [],

    supportLink: "",

    onAddStudentsToClass: () => {},

    onRemoveStudent: () => {},

    onUploadDifferentFile: () => {},
    onProccedToConfirmation: () => {},

    onImportBackToEdit: () => {},
    onImportAddStudents: () => {},
};

export default TeacherAddStudentsRows;
