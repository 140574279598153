import React from "react";

import classNames from "juice-base/lib/class-names.js";
import date from "juice-base/lib/date.js";

import IconArrowRight from "juice-base/icons/arrow-right/index.js";
import IconArrowBold from "juice-base/icons/arrow-bold/index.js";
import IconSlash from "juice-base/icons/slash/index.js";

import styles from "./styles.module.css";


const DailyJuiceNav = (props) => {
    const juiceDate = date.newDate(props.date);

    let juiceDateText = "";

    if (props.dateTitle) {
        juiceDateText = props.dateTitle;
    } else if (juiceDate === null) {
        juiceDateText = "Daily Juice";
    } else if (date.isToday(juiceDate)) {
        juiceDateText = "Today's Issue";
    } else {
        juiceDateText = date.formatDayDate(juiceDate);
    }

    const onGoToPrevStory = () => {
        if (!props.isFirstStoryOpened) {
            props.onGoToPrevStory();
        }
    };

    const onGoToNextStory = () => {
        if (!props.isLastStoryOpened) {
            props.onGoToNextStory();
        }
    };

    /* --- */

    const renderBackArrow = () => {
        return (
            <div
                className={styles.arrowBack}
                onClick={props.onBack}
                onKeyPress={props.onBack}
                role="button"
                tabIndex="-1"
            >
                <IconArrowBold
                    title="Go Back"
                    className={styles.arrowBackIcon}
                    isBlack
                />
            </div>
        );
    };

    const renderStoryControl = () => {
        const leftArrowClassName = classNames({
            [styles.goToPrevStoryButton]: true,
            [styles.arrowDisabled]: props.isFirstStoryOpened,
        });

        const rightArrowClassName = classNames({
            [styles.goToNextStoryButton]: true,
            [styles.arrowDisabled]: props.isLastStoryOpened,
        });

        return (
            <div className={styles.navControls}>
                <div
                    className={leftArrowClassName}
                    onClick={onGoToPrevStory}
                    onKeyPress={onGoToPrevStory}
                    role="button"
                    tabIndex="-1"
                >
                    <IconArrowRight
                        title="Previous story"
                        isBlack
                    />
                </div>
                <div
                    className={rightArrowClassName}
                    onClick={onGoToNextStory}
                    onKeyPress={onGoToNextStory}
                    role="button"
                    tabIndex="-1"
                >
                    <IconArrowRight
                        title="Next story"
                        isBlack
                    />
                </div>
            </div>
        );
    };

    return (
        <div
            className={styles.nav}
            data-comment="daily-juice-nav"
        >
            <div className={styles.navMobile}>
                {renderBackArrow()}
                {renderStoryControl()}
            </div>

            <div className={styles.navDesktop}>
                <div
                    className={styles.issue}
                    onClick={props.onBack}
                    onKeyPress={props.onBack}
                    tabIndex="-1"
                    role="button"
                >
                    {juiceDateText}
                </div>
                <div className={styles.separatorSign}>
                    <IconSlash
                        className={styles.separatorSignIcon}
                        title="Slash"
                        isBlack
                    />
                </div>
                <div className={styles.title}>
                    {props.title}
                </div>
                {renderStoryControl()}
            </div>
        </div>
    );
};

DailyJuiceNav.defaultProps = {
    date: "",
    dateTitle: "",
    title: "",
    isFirstStoryOpened: false,
    isLastStoryOpened: false,
    onGoToNextStory: () => {},
    onGoToPrevStory: () => {},
    onBack: () => {},
};

export default DailyJuiceNav;
