import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";

import AudioProvider from "juice-base/context/audio-provider/index.js";

import RootLayout from "juice-app/views/root-layout/index.js";

import rootRoutes from "./root.js";


const configureRoutes = (params) => {
    return (
        <AudioProvider
            store={params.store}
            actions={params.actions}
            api={params.api}
        >
            <Provider store={params.store}>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<RootLayout />}>
                            {rootRoutes}
                        </Route>
                    </Routes>
                </BrowserRouter>
            </Provider>
        </AudioProvider>
    );
};

export default configureRoutes;
