// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".XqxMK_b_C1pq7hBbw7vt {\n    position: relative;\n\n    border-radius: 0.5rem;\n\n    color: var(--text-primary-color);\n    background-color: var(--background-secondary-color);\n}\n\n.T1RuyDYJk81WOMp4iC2D {\n    display: grid;\n\n    border-radius: 0.4rem;\n\n    overflow: hidden;\n}\n\n.L3Lx0POobMxw9kXLklYQ {\n    display: grid;\n    grid-auto-flow: column;\n    grid-gap: 1rem;\n\n    justify-content: start;\n\n    padding: 1.5rem 2rem;\n    border-bottom: 0.1rem solid var(--border-default-color);\n}\n\n.TtgG6gLRBwpug3oGIWRL {\n    width: 2rem;\n    height: 2rem;\n}\n", "",{"version":3,"sources":["webpack://./base/business/popup-daily-juice-states-legend/styles.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;;IAElB,qBAAqB;;IAErB,gCAAgC;IAChC,mDAAmD;AACvD;;AAEA;IACI,aAAa;;IAEb,qBAAqB;;IAErB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,cAAc;;IAEd,sBAAsB;;IAEtB,oBAAoB;IACpB,uDAAuD;AAC3D;;AAEA;IACI,WAAW;IACX,YAAY;AAChB","sourcesContent":[".popup {\n    position: relative;\n\n    border-radius: 0.5rem;\n\n    color: var(--text-primary-color);\n    background-color: var(--background-secondary-color);\n}\n\n.legend {\n    display: grid;\n\n    border-radius: 0.4rem;\n\n    overflow: hidden;\n}\n\n.row {\n    display: grid;\n    grid-auto-flow: column;\n    grid-gap: 1rem;\n\n    justify-content: start;\n\n    padding: 1.5rem 2rem;\n    border-bottom: 0.1rem solid var(--border-default-color);\n}\n\n.icon {\n    width: 2rem;\n    height: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popup": "XqxMK_b_C1pq7hBbw7vt",
	"legend": "T1RuyDYJk81WOMp4iC2D",
	"row": "L3Lx0POobMxw9kXLklYQ",
	"icon": "TtgG6gLRBwpug3oGIWRL"
};
export default ___CSS_LOADER_EXPORT___;
