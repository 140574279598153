import React from "react";

import IconFeedback from "juice-base/icons/feedback/index.js";
import IconEmojiHappy from "juice-base/icons/emoji-happy/index.js";
import IconEmojiSad from "juice-base/icons/emoji-sad/index.js";

import PopupWindow from "juice-base/components/popup-window/index.js";
import CarouselQuizFeedback from "juice-base/business/carousel-quiz-feedback/index.js";

import styles from "./styles.module.css";


const PopupEmojiFeedback = (props) => {
    const renderLeftControl = () => {
        const icon = props.isCorrect
            ? <IconFeedback title="Quiz Feedback" isGreen />
            : <IconFeedback title="Quiz Feedback" isRed />;

        return (
            <div className={styles.feedbackIcon}>
                {icon}
            </div>
        );
    };

    const renderContent = () => {
        let emoji = null;

        if (props.isCorrect) {
            emoji = (
                <IconEmojiHappy
                    className={styles.emojiImage}
                    title="Emoji Happy"
                    isBlack
                />
            );
        } else {
            emoji = (
                <IconEmojiSad
                    className={styles.emojiImage}
                    title="Emoji Sad"
                    isBlack
                />
            );
        }

        return (
            <div className={styles.content}>
                {emoji}
                <CarouselQuizFeedback
                    messages={props.feedback}
                />
            </div>
        );
    };

    return (
        <PopupWindow
            title="Quiz Feedback"
            isSmall
            isCentered
            titleToStart
            withTitleBarShadow={false}
            leftControl={renderLeftControl()}
            onClose={props.onClose}
        >
            {renderContent()}
        </PopupWindow>
    );
};

PopupEmojiFeedback.defaultProps = {
    isCorrect: true,
    feedback: [],
    onClose: () => { },
};

export default PopupEmojiFeedback;
