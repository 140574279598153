const getThemes = () => {
    return {
        LIGHT: "light",
        DARK: "dark",
        HIGH_CONTRAST: "highContrast",
    };
};

const getDefaultTheme = () => {
    const themes = getThemes();
    return themes.LIGHT;
};

const getNextTheme = (theme) => {
    const themes = getThemes();

    if (theme === themes.LIGHT) {
        return themes.DARK;
    }

    if (theme === themes.DARK) {
        return themes.HIGH_CONTRAST;
    }

    return themes.LIGHT;
};

export default {
    getThemes,
    getDefaultTheme,
    getNextTheme,
};
