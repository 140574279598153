import React from "react";

import IconWrapper from "juice-base/icons/wrapper/index.js";


const IconDownload = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    className={iconProps.className}
                    width="14"
                    height="17"
                    viewBox="0 0 14 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill="currentColor"
                        d="M0 17H14V15H0V17ZM14 6H10V0H4V6H0L7 13L14 6Z"
                    />
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconDownload;
