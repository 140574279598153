import React, { useState, useEffect } from "react";

import text from "juice-base/text/index.js";

import date from "juice-base/lib/date.js";
import classNames from "juice-base/lib/class-names.js";
import array from "juice-base/lib/array.js";

import Standards from "juice-base/project/standards.js";
import Grades from "juice-base/project/grades.js";

import IconDownload from "juice-base/icons/download/index.js";
import IconStandards from "juice-base/icons/standards/index.js";
import IconStandardTypes from "juice-base/icons/standard-types/index.js";

import RequestLoader from "juice-base/components/request-loader/index.js";
import MenuHorizontal from "juice-base/components/menu-horizontal/index.js";
import MenuHorizontalScrolling from "juice-base/components/menu-horizontal-scrolling/index.js";
import ProgressValue from "juice-base/components/progress-value/index.js";
import SelectCustom from "juice-base/components/select-custom/index.js";
import Message from "juice-base/components/message/index.js";

import ButtonToggler from "juice-base/components/button-toggler/index.js";
import ButtonBig from "juice-base/components/button-big/index.js";

import StandardType from "juice-base/business/standard-type/index.js";
import TeacherQuizQuestion from "juice-base/business/teacher-quiz-question/index.js";

import styles from "./styles.module.css";


const getStandardState = () => ({
    id: "",
    name: "",
});

const StudentQuizPerformance = (props) => {
    const sortByQuiz = "by-quiz";
    const sortByStandard = "by-standard";

    const sortByValues = [
        { value: sortByQuiz, label: props.isMobile ? "Quiz" : "View by quiz" },
        { value: sortByStandard, label: props.isMobile ? "Standard" : "View by standard" },
    ];

    const standardTypes = props.showStateStandard
        ? Standards.getAllStandardsTypes()
        : Standards.getStandardsTypes();

    const standardDates = [
        date.DATES.today,
        date.DATES.twoWeeks,
        date.DATES.thisMonth,
        date.DATES.past3Months,
        { value: "custom", label: "Custom" },
    ];

    if ((props.customRange.dateFrom && props.customRange.dateTo) || props.customDate) {
        const { dateFrom, dateTo } = props.customRange;

        let newLabel = "Custom";

        if (props.customDate) {
            const selectedDate = date.tryFormatDate(props.customDate, date.formatFullMonthDayDate);

            newLabel = `Custom | ${selectedDate}`;
        } else {
            const d1 = date.tryFormatDate(dateFrom, date.formatFullMonthDayDate);
            const d2 = date.tryFormatDate(dateTo, date.formatFullMonthDayDate);

            newLabel = `Custom | ${d1} - ${d2}`;
        }

        standardDates[standardDates.length - 1].label = newLabel;
    }

    /* --- */

    const [sortByType, setSortByType] = useState(sortByValues[0].value);

    const [quizDate, setQuizDate] = useState(() => {
        const dates = props.dailyJuiceDates?.dates || [];

        let qDate = null;

        if (props.defaultDate) {
            qDate = array.findByFieldName(dates, "date", props.defaultDate);
        }

        if (!qDate) {
            qDate = array.last(dates);
        }

        return qDate?.id || null;
    });

    const [quizStandardDate, setQuizStandardDate] = useState(() => ({
        prevDate: standardDates[0].value,
        date: standardDates[0].value,
    }));

    const [standardType, setStandardType] = useState(() => {
        const stdType = array.head(standardTypes);
        return stdType ? stdType.value : "";
    });

    const [selectedStandard, setSelectedStandard] = useState(() => getStandardState());

    /* --- */

    const onQuizDateChange = (value) => {
        const val = parseInt(value, 10);
        const qDate = array.findOneById(props.dailyJuiceDates?.dates, val);

        if (qDate) {
            props.onDateChange({
                date: qDate.date,
            });
        }
    };

    const onLoadQuizzes = (standardId) => {
        if (sortByType === sortByQuiz) {
            onQuizDateChange(quizDate);
        } else if (sortByType === sortByStandard) {
            props.onDateRangeChange({
                standardId,
                range: quizStandardDate.date,
            });
        }
    };

    /* --- */

    const selectFirstStandardType = (values = {}) => {
        const data = props.performance.data || {};
        const stds = Standards.getAllStandardsByType(data.standards, values.standard);

        if (stds && stds.length > 0) {
            setSelectedStandard({
                id: stds[0].id,
                name: stds[0].standard,
            });

            if (values.onFinish) {
                values.onFinish(stds[0].id);
            }
        } else {
            setSelectedStandard(getStandardState());
        }
    };

    /* --- */

    const onStandardDateChange = (value) => {
        setQuizStandardDate((prev) => ({
            prevDate: prev.date === "custom" ? prev.prevDate : prev.date,
            date: value,
        }));

        props.onDateRangeChange({
            openDatepicker: value === "custom",
            range: value,
            standardId: selectedStandard.id,
        });
    };

    /* --- */

    const onSortByQuiz = (isInit) => {
        let qDate = null;
        const dates = props.dailyJuiceDates?.dates || [];

        if (isInit && props.defaultDate) {
            qDate = array.findByFieldName(dates, "date", props.defaultDate);
        }

        if (!qDate) {
            qDate = array.findOneById(dates, parseInt(quizDate, 10));
        }

        if (qDate) {
            setQuizDate(qDate.id);
            props.onDateChange({
                date: qDate.date,
            });
        }
    };

    const onSortByStandard = () => {
        const stdDate = array.head(standardDates);

        if (stdDate) {
            onStandardDateChange(stdDate.value);
        }
    };

    const onSortByValueChange = (val) => {
        setSortByType(val);
        setSelectedStandard(getStandardState());

        if (val === sortByQuiz) {
            onSortByQuiz(false);
        } else if (val === sortByStandard) {
            onSortByStandard();
        }
    };

    /* --- */

    const onDateChange = (value) => {
        setQuizDate(value);

        if (sortByType === sortByQuiz) {
            onQuizDateChange(value);
        } else if (sortByType === sortByStandard) {
            props.onDateRangeChange({
                range: value,
                standardId: selectedStandard.id,
            });
        }
    };

    const onStandardTypeChange = (value) => {
        setStandardType(value);

        selectFirstStandardType({
            standard: value,
            onFinish: (id) => {
                if (sortByType === sortByStandard) {
                    onLoadQuizzes(id);
                }
            },
        });
    };

    const onStandardChange = (options, value) => {
        const standard = array.findOneByValue(options, value);
        const standardId = standard?.id || null;

        onLoadQuizzes(standardId);

        setSelectedStandard({
            id: standardId,
            name: value,
        });
    };

    const onResetQuizStandardDate = () => {
        const d = quizStandardDate.prevDate || standardDates[0].value;

        onStandardDateChange(d);
    };

    /* --- */

    const getDateByDateId = (id) => {
        for (let i = 0; i < props.dailyJuiceDates.dates.length; i += 1) {
            if (props.dailyJuiceDates.dates[i].id === parseInt(id, 10)) {
                return props.dailyJuiceDates.dates[i].date;
            }
        }

        return null;
    };

    const onExportStudentPerformance = () => {
        const reqParams = {
            filterBy: sortByType,
            standardType,
            standardId: selectedStandard.id,
        };

        if (sortByType === sortByQuiz) {
            const rangeDate = getDateByDateId(quizDate);

            if (rangeDate) {
                reqParams.dateFrom = date.getDateFromDate(rangeDate);
                reqParams.dateTo = date.getDateFromDate(rangeDate);
            }
        } else if (sortByType === sortByStandard) {
            reqParams.range = quizStandardDate.date;
        }

        props.onExportStudentPerformance(reqParams);
    };

    /* --- */

    const getScoreByStandard = () => {
        const { data } = props.performance;

        const scoresByStandards = data?.studentScoresByStandards || [];

        for (let i = 0; i < scoresByStandards.length; i += 1) {
            if (scoresByStandards[i].standardId === selectedStandard.id) {
                return scoresByStandards[i].score || 0;
            }
        }

        return data.studentQuizScore;
    };

    const getClassAvgScoreByStandard = () => {
        const { data } = props.performance;

        const scoresByStandards = data?.classScoresByStandards || [];

        for (let i = 0; i < scoresByStandards.length; i += 1) {
            if (scoresByStandards[i].standardId === selectedStandard.id) {
                return scoresByStandards[i].score || 0;
            }
        }

        return data.classQuizScore;
    };

    const getSelectedStandardType = () => {
        const { data } = props.performance;
        const standards = data.standards || [];

        return Standards.findStandardType(standards, selectedStandard.name);
    };

    const isSelectedStandardType = (quiz, standards) => {
        if (!selectedStandard.name) {
            return false;
        }

        const quizStandards = quiz.standards || [];

        for (let i = 0; i < quizStandards.length; i += 1) {
            const sId = quizStandards[i];

            const stdType = Standards
                .findStandardTypeByStandard(standards, sId, selectedStandard.name);

            if (stdType) {
                return true;
            }
        }

        return false;
    };

    /* --- */

    useEffect(() => {
        onSortByQuiz(true);

        return () => {
            props.onClear();
        };
    }, []);

    useEffect(() => {
        if (!props.performance.isLoading
            && sortByType === sortByStandard
            && !selectedStandard.name) {
            selectFirstStandardType({
                standard: standardType,
            });
        }
    }, [props.performance.isLoading]);

    useEffect(() => {
        const { customRange, isDatepickerVisible } = props;

        if (quizStandardDate.date === "custom"
            && !isDatepickerVisible
            && !customRange.dateFrom
            && !customRange.dateTo
            && !props.customDate) {
            onResetQuizStandardDate();
        }
    }, [props.isDatepickerVisible]);

    /* --- */

    const renderSortByTypes = () => {
        return (
            <div className={styles.sortByType}>
                <ButtonToggler
                    small={props.isMobile}
                    buttons={sortByValues}
                    selected={sortByType}
                    onSelect={onSortByValueChange}
                />
                <ButtonBig
                    icon={IconDownload}
                    onClick={onExportStudentPerformance}
                >
                    {props.isMobile ? "" : "Download"}
                </ButtonBig>
            </div>
        );
    };

    const renderDates = () => {
        const datesClassName = classNames({
            [styles.dates]: true,
            [styles.datesDesktop]: !props.isMobile,
        });

        if (sortByType === sortByQuiz) {
            const dates = props.dailyJuiceDates?.dates || [];

            const items = dates.map((val) => ({
                value: val.id,
                label: date.tryFormatDate(val.date, date.formatDayShortMonthDate),
            }));

            return (
                <div className={datesClassName}>
                    <MenuHorizontalScrolling
                        hideArrows={props.isMobile}
                        key="quiz-dates"
                        items={items}
                        selected={quizDate}
                        onSelect={onDateChange}
                    />
                </div>
            );
        }

        if (sortByType === sortByStandard) {
            return (
                <div className={datesClassName}>
                    <MenuHorizontalScrolling
                        key="standard-dates"
                        items={standardDates}
                        selected={quizStandardDate.date}
                        onSelect={onStandardDateChange}
                    />
                </div>
            );
        }

        return null;
    };

    const renderStandards = () => {
        if (props.isMobile) {
            return (
                <div className={styles.standardsTypesDesktop}>
                    <SelectCustom
                        selected={standardType}
                        options={standardTypes}
                        icon={<IconStandards isRed />}
                        onSelect={onStandardTypeChange}
                    />
                </div>
            );
        }

        return (
            <div className={styles.standardsTypes}>
                <MenuHorizontal
                    items={standardTypes}
                    selected={[standardType]}
                    onSelect={onStandardTypeChange}
                    isRoseTheme
                />
            </div>
        );
    };

    const renderProgressValue = (params = {}) => {
        let value = params.score;

        if (value === -1) {
            value = 0;
        }

        return (
            <ProgressValue
                title={params.title}
                subtitle={params.subtitle}
                value={value || 0}
            />
        );
    };

    const renderQuizScores = () => {
        const { data } = props.performance;

        if (Object.keys(data).length === 0) {
            return null;
        }

        const studentGrade = Grades.getGradeGroup([data.studentGradeLevel]);
        const classGrade = Grades.getGradeGroup([data.classGradeLevel]);

        const scoresClassName = classNames({
            [styles.scores]: true,
            [styles.scoresDesktop]: !props.isMobile,
        });

        return (
            <div className={scoresClassName}>
                {renderProgressValue({
                    title: "Student's Quiz Score",
                    subtitle: `Level ${studentGrade}`,
                    score: data.studentQuizScore,
                })}
                {renderProgressValue({
                    title: "Class Average",
                    subtitle: `Level ${classGrade}`,
                    score: data.classQuizScore,
                })}
            </div>
        );
    };

    const renderByStandardScores = () => {
        const { data } = props.performance;

        if (Object.keys(data).length === 0) {
            return null;
        }

        const stds = Standards.getAllStandardsByType(data.standards, standardType);


        const options = stds.map((std) => {
            let iconName = "";

            if (props.isMobile && std.standard === selectedStandard.name) {
                iconName = "info";
            }

            return {
                id: std.id,
                value: std.standard,
                label: std.standard,
                icon: iconName,
            };
        });

        let standardTypeSelector = null;

        if (options.length === 0) {
            standardTypeSelector = (
                <SelectCustom
                    icon={<IconStandardTypes isRed />}
                    noOptionsText="No standards"
                />
            );
        } else {
            standardTypeSelector = (
                <SelectCustom
                    selected={selectedStandard.name}
                    options={options}
                    icon={<IconStandardTypes isRed />}
                    onSelect={(value) => {
                        onStandardChange(options, value);
                    }}
                    onIconClick={() => {
                        props.onStandardTypeClick(selectedStandard.name);
                    }}
                />
            );
        }

        const studentGrade = Grades.getGradeGroup([data.studentGradeLevel]);
        const classGrade = Grades.getGradeGroup([data.classGradeLevel]);

        const selectedStandardType = getSelectedStandardType();

        const scoresClassName = classNames({
            [styles.scoresStandards]: true,
            [styles.scoresStandardsDesktop]: !props.isMobile,
        });

        const typeSelectorClassName = classNames({
            [styles.scoreStandardTypeSelector]: true,
            [styles.scoreStandardTypeSelectorDesktop]: !props.isMobile,
        });

        const scoreStandardDetailsClassName = classNames({
            [styles.scoreStandardDetails]: true,
            [styles.scoreStandardDetailsDesktop]: !props.isMobile,
        });

        return (
            <div className={scoresClassName}>
                <div className={typeSelectorClassName}>
                    {standardTypeSelector}
                </div>
                <div className={scoreStandardDetailsClassName}>
                    {selectedStandardType?.details || ""}
                </div>
                <div className={styles.scoreStandardsValues}>
                    {renderProgressValue({
                        title: "Student's Quiz Score",
                        subtitle: `Level ${studentGrade}`,
                        score: getScoreByStandard(),
                    })}
                    {renderProgressValue({
                        title: "Class Average",
                        subtitle: `Level ${classGrade}`,
                        score: getClassAvgScoreByStandard(),
                    })}
                </div>
            </div>
        );
    };

    const renderScores = () => {
        if (props.performance.isLoading) {
            return null;
        }

        if (sortByType === sortByQuiz) {
            return renderQuizScores();
        }

        if (sortByType === sortByStandard) {
            return renderByStandardScores();
        }

        return null;
    };

    const renderQuizStandard = (quiz, standards) => {
        const quizStandards = quiz.standards || [];
        const quizTypes = [];

        for (let i = 0; i < quizStandards.length; i += 1) {
            const sId = quizStandards[i];

            const stdType = Standards.findStandardTypeByType(standards, sId, standardType);

            if (stdType) {
                const qStd = (
                    <div className={styles.quizStandardType}>
                        <StandardType type={stdType} />
                    </div>
                );

                quizTypes.push(qStd);
            }
        }

        return quizTypes;
    };

    const renderQuiz = (quiz) => {
        return (
            <div className={styles.quiz}>
                <TeacherQuizQuestion
                    quiz={quiz}
                    onOpenStory={props.onOpenStory}
                />
            </div>
        );
    };

    const renderNoQuizzesMessage = () => {
        let message = "Please select standard";

        if (selectedStandard.name) {
            message = `
                This student has not answered any questions related to ${selectedStandard.name} yet.
                Select a different standard from the dropdown to view student performance data.
            `;
        }

        return (
            <div className={styles.message}>
                {message}
            </div>
        );
    };

    const renderQuizzes = () => {
        if (props.performance.isLoading) {
            return null;
        }

        const { data } = props.performance;
        const standards = data.standards || [];

        if (sortByType === sortByQuiz) {
            const qs = [];
            const quizzes = data.quizzes || [];

            for (let i = 0; i < quizzes.length; i += 1) {
                const q = quizzes[i];

                qs.push(renderQuiz(q));
                qs.push(renderQuizStandard(q, standards));
            }

            return (
                <div className={styles.quizzes}>
                    {qs}
                </div>
            );
        }

        if (sortByType === sortByStandard) {
            const qs = [];
            const quizzes = data.quizzes || [];

            for (let i = 0; i < quizzes.length; i += 1) {
                const q = quizzes[i];

                if (isSelectedStandardType(q, standards)) {
                    qs.push(renderQuiz(q));
                }
            }

            if (qs.length === 0) {
                return renderNoQuizzesMessage();
            }

            return (
                <div className={styles.quizzes}>
                    {qs}
                </div>
            );
        }

        return null;
    };

    const renderContent = () => {
        if (props.performance.isLoading) {
            return (
                <RequestLoader />
            );
        }

        if (Object.keys(props.performance.data).length === 0) {
            return (
                <Message>
                    {text.noStudentAnswers}
                </Message>
            );
        }

        return (
            <>
                {renderScores()}
                {renderQuizzes()}
            </>
        );
    };

    if (!props.dailyJuiceDates?.isLoaded) {
        return (
            <RequestLoader />
        );
    }

    return (
        <div className={styles.studentQuizPerformance}>
            {renderSortByTypes()}
            {renderDates()}
            {renderStandards()}

            {renderContent()}
        </div>
    );
};

StudentQuizPerformance.defaultProps = {
    defaultDate: null,
    dailyJuiceDates: [],
    performance: {},
    customRange: {},
    customDate: null,

    onExportStudentPerformance: () => { },
    onDateChange: () => { },
    onDateRangeChange: () => { },
    onStandardTypeClick: () => { },
    onOpenStory: () => { },
    onClear: () => { },

    isDatepickerVisible: false,
    showStateStandard: false,
    isMobile: false,
};

export default StudentQuizPerformance;
