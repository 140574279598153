import React from "react";

import ButtonDefault from "juice-base/components/button-default/index.js";

import PopupConfirm, {
    PopupConfirmTitle,
    PopupConfirmContent,
    PopupConfirmButtons,
} from "juice-base/components/popup-confirm/index.js";


const PopupConfirmUnsavedProgressV2 = (props) => {
    return (
        <PopupConfirm
            hideScrollbar={props.hideScrollbar}
        >
            <PopupConfirmTitle>
                Leave this page?
            </PopupConfirmTitle>

            <PopupConfirmContent isGray>
                Changes you made may not be saved.
            </PopupConfirmContent>

            <PopupConfirmButtons>
                <ButtonDefault
                    isPrimary
                    uppercase
                    onClick={props.onLeave}
                >
                    Leave
                </ButtonDefault>
                <ButtonDefault
                    uppercase
                    onClick={props.onClose}
                >
                    Cancel
                </ButtonDefault>
            </PopupConfirmButtons>
        </PopupConfirm>
    );
};

PopupConfirmUnsavedProgressV2.defaultProps = {
    hideScrollbar: false,

    onLeave: () => { },
    onClose: () => { },
};

export default PopupConfirmUnsavedProgressV2;
