import React, { useState } from "react";

import useSnackbar from "juice-base/hooks/use-snackbar/index.js";

import MenuWithContent, { MenuContent } from "juice-base/components/menu-with-content/index.js";
import PopupFullScreen from "juice-base/components/popup-full-screen-v2/index.js";
import Snackbar from "juice-base/components/snackbar/index.js";

import TeacherShareClassCode from "juice-base/business/teacher-share-class-code/index.js";
import TeacherImportStudents from "juice-base/business/teacher-import-students/index.js";
import TeacherAddStudentsManually from "juice-base/business/teacher-add-students-manually/index.js";

import styles from "./styles.module.css";


const PopupFullScreenAddStudent = (props) => {
    const [isMenuVisible, setIsMenuVisible] = useState(true);

    const snackbar = useSnackbar();

    const studentsRowsLimit = props.classLimit - props.classStudentsCount;

    const onMenuClick = () => {
        setIsMenuVisible(false);
    };

    const onCloseSnackbar = (params = {}) => {
        snackbar.close(params.index);

        if (params.withUndo) {
            params.onCloseWithUndo();
        }
    };

    const onRemoveStudentRow = (params) => {
        const { row } = params;

        snackbar.add({
            isVisibleUndoButton: true,
            message: `${row.nameValue} ${row.lastNameValue} removed.`,
            autoCloseInSeconds: 5,
            onCloseWithUndo: () => {
                params.onUndo();
            },
        });
    };

    const renderSnackbars = () => {
        return snackbar.state.map((bar, index) => {
            return (
                <Snackbar
                    isVisibleUndoButton={bar.isVisibleUndoButton}
                    autoCloseInSeconds={bar.autoCloseInSeconds}
                    message={bar.message}
                    onClose={(params) => {
                        onCloseSnackbar({
                            ...params,
                            index,
                            onCloseWithUndo: () => {
                                bar.onCloseWithUndo();
                            },
                        });
                    }}
                />
            );
        });
    };

    const renderContent = () => {
        const menu = [
            <MenuContent name="Add Manually">
                <div className={styles.pageContent}>
                    <TeacherAddStudentsManually
                        grades={props.grades}
                        rowsLimit={studentsRowsLimit}
                        noRowsLimit={props.noClassLimit}
                        supportLink={props.supportLink}
                        defaultGrade={props.defaultGrade}
                        importStudentsTemplateUrl={props.importStudentsTemplateUrl}
                        onRemoveStudent={onRemoveStudentRow}
                        onAddStudentsToClass={props.onAddStudentsToClass}
                    />
                </div>
            </MenuContent>,
        ];

        if (props.showShareClassCodeMenu) {
            menu.push(
                <MenuContent name="Share Class Code">
                    <div className={styles.pageContent}>
                        <TeacherShareClassCode
                            onGenerateClassCode={props.onGenerateClassCode}
                            onDirectLinkClick={props.onDirectLinkClick}
                        />
                    </div>
                </MenuContent>,
            );
        }

        menu.push(
            <MenuContent name="Import Data">
                <div className={styles.pageContent}>
                    <TeacherImportStudents
                        grades={props.grades}
                        rowsLimit={studentsRowsLimit}
                        noRowsLimit={props.noClassLimit}
                        supportLink={props.supportLink}
                        defaultGrade={props.defaultGrade}
                        importStudentsTemplateUrl={props.importStudentsTemplateUrl}
                        onRemoveStudent={onRemoveStudentRow}
                        onAddStudentsToClass={props.onAddStudentsToClass}
                    />
                </div>
            </MenuContent>,
        );

        return (
            <MenuWithContent
                isMobile={props.isMobile}
                isMobileMenuVisible={isMenuVisible}
                defaultSelected={props.defaultSelectedMenu}
                onMenuClick={onMenuClick}
            >
                {menu}
            </MenuWithContent>
        );
    };

    return (
        <>
            {renderSnackbars()}
            <PopupFullScreen
                title="Add Student"
                isMobile={props.isMobile}
                isArrowBackVisible={!isMenuVisible}
                onArrowBack={(value) => {
                    setIsMenuVisible(value);
                }}
                onClose={props.onClose}
            >
                {renderContent()}
            </PopupFullScreen>
        </>
    );
};

PopupFullScreenAddStudent.defaultProps = {
    isMobile: false,
    importStudentsTemplateUrl: null,
    showShareClassCodeMenu: true,
    defaultGrade: "G7-G8",
    defaultSelectedMenu: 0,
    classStudentsCount: 0,
    classLimit: 10,
    noClassLimit: false,
    supportLink: "",
    grades: [],
    onDirectLinkClick: () => {},
    onGenerateClassCode: () => {},
    onAddStudentsToClass: () => {},
    onClose: () => {},
};

export default PopupFullScreenAddStudent;
