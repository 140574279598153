import { useState } from "react";


const getState = () => ({
    isVisiblePopup: false,
    defaultClass: null,
});

const useAnnouncementPopup = () => {
    const [state, setState] = useState(() => getState());

    const open = (params) => {
        const { defaultClass } = params;

        setState((prev) => ({
            ...prev,
            isVisiblePopup: true,
            defaultClass,
        }));
    };

    const close = () => {
        setState(getState());
    };

    return {
        state,
        open,
        close,
    };
};

export default useAnnouncementPopup;
