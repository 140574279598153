// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".yS23pxsHW6YLebO75ibf {}\n\n.CSu8BWFGK1MN1KNDtM18 {\n    margin-top: 1rem;\n    display: grid;\n}\n", "",{"version":3,"sources":["webpack://./base/forms/user-password-forgot/styles.module.css"],"names":[],"mappings":"AAAA,uBAAO;;AAEP;IACI,gBAAgB;IAChB,aAAa;AACjB","sourcesContent":[".form {}\n\n.submitButtonBlock {\n    margin-top: 1rem;\n    display: grid;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "yS23pxsHW6YLebO75ibf",
	"submitButtonBlock": "CSu8BWFGK1MN1KNDtM18"
};
export default ___CSS_LOADER_EXPORT___;
