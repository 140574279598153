import React from "react";

import classNames from "juice-base/lib/class-names.js";

import styles from "./styles.module.css";


const SectionTeacherHeader = (props) => {
    const renderRightControls = () => {
        if (!props.rightControls) {
            return (
                <div />
            );
        }

        const headerRightControlsClassName = classNames({
            [styles.headerRightControls]: true,
            [props.rightControlClassName]: props.rightControlClassName,
        });

        return (
            <div className={headerRightControlsClassName}>
                {props.rightControls}
            </div>
        );
    };

    const renderBottomControls = () => {
        if (props.bottomControls) {
            const bottomControlClassName = classNames({
                [styles.headerBottom]: true,
                [props.bottomControlClassName]: props.bottomControlClassName,
            });

            return (
                <div className={bottomControlClassName}>
                    {props.bottomControls}
                </div>
            );
        }

        return null;
    };

    const headerTopClassName = classNames({
        [styles.headerTop]: true,
        [styles.headerTopRightControlWithMaxContent]: props.isMaxContentOnRightControl,
        [props.headerClassName]: props.headerClassName,
    });

    const labelClassName = classNames({
        [styles.headerLabel]: true,
        [props.labelControlClassName]: props.labelControlClassName,
    });

    return (
        <div className={styles.header}>
            <div className={headerTopClassName}>
                <div className={labelClassName}>
                    {props.label}
                </div>
                {renderRightControls()}
            </div>
            {renderBottomControls()}
        </div>
    );
};

SectionTeacherHeader.defaultProps = {
    label: "",
    rightControls: null,
    bottomControls: null,

    isMaxContentOnRightControl: false,

    rightControlClassName: "",
    labelControlClassName: "",
    bottomControlClassName: "",
};

export default SectionTeacherHeader;
