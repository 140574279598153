// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".a7hws0LvSSwrifZ4I1uu {\n    padding: 0 1.5rem;\n    justify-content: start;\n}\n\n.q2q5BsDW87ipl50aUz5N {\n    width: 100%;\n}\n", "",{"version":3,"sources":["webpack://./base/components/popup-date-range-custom-v2/styles.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,sBAAsB;AAC1B;;AAEA;IACI,WAAW;AACf","sourcesContent":[".popupBar {\n    padding: 0 1.5rem;\n    justify-content: start;\n}\n\n.datepicker {\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popupBar": "a7hws0LvSSwrifZ4I1uu",
	"datepicker": "q2q5BsDW87ipl50aUz5N"
};
export default ___CSS_LOADER_EXPORT___;
