import User from "juice-base/project/user.js";

import visibility from "juice-base/lib/visibility.js";
import random from "juice-base/lib/random.js";

import MonitorRestarter from "juice-base/monitors/restarter.js";
import EventSourceWrapper from "juice-base/monitors/events.js";

import actions from "juice-base/store/actions.js";
import actionsTeacher from "juice-base/actions/teacher.js";
import actionsTeacherClassReloader from "juice-base/actions/teacher-class-reloader.js";
import actionsTeacherClassDailyJuices from "juice-base/actions/teacher-class-daily-juices.js";
import actionsTeacherClassAssignments from "juice-base/actions/teacher-class-assignments.js";

import api from "juice-app/api.js";


const MONITOR_COMMANDS = {
    DJ_IN_PROGRESS: "daily_juice_in_progress",
    DJ_COMPLETED: "daily_juice_completed",
    CLASS_STUDENTS_UPDATED: "class_students_updated",
    QUIZ_ANSWERED: "quiz_answered",
    ASSIGNMENT_ANSWERED: "assignment_answered",
};

const getCallbacks = (store) => {
    return {
        reloadDashboard() {
            store.dispatch(actionsTeacher.reloadDashboard({
                api,
                actions,
            }));
        },

        reloadClass() {
            store.dispatch(actionsTeacherClassReloader.reloadClass({
                api,
                actions,
            }));
        },

        reloadClassDailyJuicesScores() {
            store.dispatch(actionsTeacherClassDailyJuices.loadOrReloadDailyJuicesScores({
                api,
                actions,
            }));
        },

        reloadClassAssignmentsScores() {
            store.dispatch(actionsTeacherClassAssignments.loadOrReloadAssignmentsScores({
                api,
                actions,
            }));
        },

        /* --- */

        onStart() {
            store.dispatch(actions.monitors.setSocketMonitorOn());
        },

        onStop() {
            store.dispatch(actions.monitors.setSocketMonitorOff());
        },

        onUpdate(data) {
            if (data.cmd === MONITOR_COMMANDS.DJ_IN_PROGRESS
                || data.cmd === MONITOR_COMMANDS.DJ_COMPLETED) {
                this.reloadDashboard();
            } else if (data.cmd === MONITOR_COMMANDS.CLASS_STUDENTS_UPDATED) {
                this.reloadClass();
            } else if (data.cmd === MONITOR_COMMANDS.QUIZ_ANSWERED) {
                this.reloadClassDailyJuicesScores();
            } else if (data.cmd === MONITOR_COMMANDS.ASSIGNMENT_ANSWERED) {
                this.reloadClassAssignmentsScores();
            } else {
                console.log(`[SSE]: Unknown command ${data}`); // eslint-disable-line no-console
            }
        },

        onUpdateAll() {
            this.reloadDashboard();
            this.reloadClass();
        },
    };
};

class EventsMonitor {
    constructor(eventsURL, store) {
        this.id = random.randomHEX();

        this.eventsURL = eventsURL;
        this.store = store;
        this.session = "";

        this.state = {
            isAuthorized: false,
            isVisible: true,
        };

        this.isRunning = false;

        this.callbacks = getCallbacks(store);

        this.eventSource = new EventSourceWrapper(this.callbacks);
        this.monitor = new MonitorRestarter(this.eventSource);

        this.startStoreMonitor();
        this.startVisibilityMonitor();
    }

    /* --- */

    start() {
        if (this.isRunning) {
            return;
        }

        console.log("[EventsMonitor]: start monitor"); // eslint-disable-line no-console

        this.isRunning = true;

        const session = this.store.getState().user.session || "";
        const url = `${this.eventsURL}?app_id=${this.id}&session_id=${session}`;

        this.eventSource.setURL(url);
        this.monitor.start();
    }

    stop() {
        if (!this.isRunning) {
            return;
        }

        console.log("[EventsMonitor]: stop monitor"); // eslint-disable-line no-console

        this.isRunning = false;

        this.monitor.stop();
    }

    changeState() {
        if (!this.state.isVisible) {
            this.stop();
            return;
        }

        if (this.state.isAuthorized) {
            this.start();
        } else {
            this.stop();
        }
    }

    /* --- */

    setAuthorized(isAuthorized) {
        this.state.isAuthorized = isAuthorized;

        this.changeState();
    }

    setVisibility(isVisible) {
        this.state.isVisible = isVisible;

        this.changeState();
    }

    /* --- */

    startStoreMonitor() {
        this.store.subscribe(() => {
            const state = this.store.getState();
            this.storeChanged(state);
        });
    }

    startVisibilityMonitor() {
        const listener = visibility.getVisibilityEventHandler((isVisible) => {
            this.setVisibility(isVisible);
        });

        if (document) {
            document.addEventListener("visibilitychange", listener);
        }
    }

    storeChanged(state) {
        const {
            session,
            isUserLoaded,
            user,
        } = state.user;

        const isAuthorized = session
            && isUserLoaded
            && User.hasRoleTeacher(user);

        this.setAuthorized(!!isAuthorized);
    }
}

export default EventsMonitor;
