import React from "react";

import useTitle from "juice-base/hooks/use-title/index.js";

import { withAuth } from "juice-base/components/auth/index.js";

import Assignment from "juice-app/containers/assignment/index.js";


const ShowAssignment = () => {
    useTitle(() => "Assignment", []);

    return (
        <Assignment />
    );
};

export default withAuth(["student", "teacher"])(ShowAssignment);
