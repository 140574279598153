import React from "react";
import { Outlet } from "react-router-dom";

import useTitle from "juice-base/hooks/use-title/index.js";

import { withAuth } from "juice-base/components/auth/index.js";

import ClassV2 from "juice-app/containers/class-v2/index.js";
import ClassV3 from "juice-app/containers/class-v3/index.js";

import settings from "juice-app/settings.js";


const ShowClass = () => {
    useTitle(() => "Class", []);

    if (settings.features.ASSIGNMENTS) {
        return (
            <>
                <ClassV3 />

                <Outlet />
            </>
        );
    }

    return (
        <>
            <ClassV2 />

            <Outlet />
        </>
    );
};

export default withAuth(["teacher"])(ShowClass);
