import array from "juice-base/lib/array.js";

const SCHOOL_TYPES = [
    { value: "public", label: "Public School" },
    { value: "private", label: "Private/Other School" },
];

/* ------ */

const getSchoolTypes = () => {
    return SCHOOL_TYPES;
};

const getPublicSchoolTypeValue = () => {
    return SCHOOL_TYPES[0].value;
};

const getPrivateSchoolTypeValue = () => {
    return SCHOOL_TYPES[1].value;
};

const getSchoolIdByName = (schools, name) => {
    const school = array.findByFieldName(schools, "name", name);

    if (school?.id) {
        return school.id;
    }

    return null;
};

/* ------ */

const isPublicSchoolType = (type) => {
    return type === SCHOOL_TYPES[0].value;
};

const isPrivateSchoolType = (type) => {
    return type === SCHOOL_TYPES[1].value;
};

/* ------ */

export default {
    getSchoolTypes,
    getPublicSchoolTypeValue,
    getPrivateSchoolTypeValue,
    getSchoolIdByName,

    isPublicSchoolType,
    isPrivateSchoolType,
};
