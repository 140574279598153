import React, { useState } from "react";

import Subscription from "juice-base/project/subscription.js";

import date from "juice-base/lib/date.js";

import text from "juice-base/text/index.js";

import Accordion from "juice-base/components/accordion/index.js";
import Switch from "juice-base/components/switch/index.js";
import ButtonBig from "juice-base/components/button-big/index.js";
import Message from "juice-base/components/message/index.js";
import RequestLoader from "juice-base/components/request-loader/index.js";
import LoaderSmall from "juice-base/components/loader-small/index.js";
import WithScroll from "juice-base/components/with-scroll/index.js";
import ProfileRow from "juice-base/components/profile-row/index.js";

import settings from "juice-app/settings.js";

import styles from "./styles.module.css";


const PlanDetails = (props) => {
    const [isOpenAccordion, seIsOpenAccordion] = useState(false);

    const userPlan = Subscription.getPlanByPlanId(props.plans.plans, props.user.planId);
    const userActivePlan = Subscription.getPlanByPlanId(props.plans.activePlans, props.user.planId);

    const isEmptyPlan = Object.keys(userPlan).length === 0;
    const isEmptyActivePlan = Object.keys(userActivePlan).length === 0;

    /* --- */

    const getPlanNameAndPrice = () => {
        let planName = "";
        let planPrice = "";

        if (props.user.isFree) {
            const { daysLeftToExpire, expiration } = props.user;

            planPrice = "Free / No expiration";
            planName = "Free Trial";

            if (expiration && daysLeftToExpire > 0) {
                planPrice = `Free / ${date.tryFormatDateUTC(expiration, date.formatShortMonthDayYearDate)}`;
            }

            return {
                planName,
                planPrice,
            };
        }

        if (!isEmptyPlan) {
            planName = userPlan?.name || "";

            if (Subscription.isPlanAnnually(userPlan)) {
                planPrice = `$${userPlan?.price} / year`;
            } else if (Subscription.isPlanMonthly(userPlan)) {
                planPrice = `$${userPlan?.price} / month`;
            }

            return {
                planName,
                planPrice,
            };
        }

        if (!isEmptyActivePlan) {
            planName = userActivePlan?.name || "";

            if (Subscription.isPlanAnnually(userActivePlan)) {
                planPrice = `$${userActivePlan?.price} / year`;
            } else if (Subscription.isPlanMonthly(userActivePlan)) {
                planPrice = `$${userActivePlan?.price} / month`;
            }

            return {
                planName,
                planPrice,
            };
        }

        return {
            planName: "",
            planPrice: "",
        };
    };

    const getPlanFeatures = () => {
        if (props.user.isFree) {
            return props.plans.freeTrialPlanFeatures || [];
        }

        if (!isEmptyPlan) {
            return userPlan?.features || [];
        }

        if (!isEmptyActivePlan) {
            return userActivePlan?.features || [];
        }

        return [];
    };

    /* --- */

    const onAccordionClick = () => {
        seIsOpenAccordion((prev) => !prev);
    };

    /* ------ */

    const renderLabel = () => {
        const { planName, planPrice } = getPlanNameAndPrice();

        return (
            <div className={styles.planDetailsAccordionLabel}>
                <div>{`The Juice ${planName} Plan`}</div>
                <div>{planPrice}</div>
            </div>
        );
    };

    const renderAutoRenewRow = () => {
        if (!props.user.hasPlan) {
            return null;
        }

        if (!Subscription.isPlanAnnually(userPlan)) {
            return null;
        }

        const loader = props.subscription.isLoadingAutoRenewSubscription
            ? <LoaderSmall />
            : null;

        const isDisabled = props.subscription.isLoadingAutoRenewSubscription
            || !props.plans.isPlansLoaded;

        const rightControl = (
            <div className={styles.switchWithLoader}>
                {loader}

                <Switch
                    name="isAutoRenewEnabled"
                    disabled={isDisabled}
                    checked={props.subscription.isAutoRenewEnabled}
                    onChange={props.subscription.onChangeAutoRenew}
                />
            </div>
        );

        return (
            <ProfileRow
                customRightControl={rightControl}
                value={`Expires on ${props.subscription.expireDate}`}
                isAutoRenewPlan
                boldName
            />
        );
    };

    const renderControls = () => {
        let upgradeButton = null;

        if (props.user.hasPlan && !isEmptyPlan) {
            upgradeButton = (
                <ButtonBig
                    disabled={!props.plans.isPlansLoaded}
                    onClick={props.subscription.onUpgrade}
                >
                    Upgrade plan
                </ButtonBig>
            );
        }

        return (
            <div className={styles.personalInfoControls}>
                {renderAutoRenewRow()}

                {upgradeButton}

                <ButtonBig
                    outlined
                    onClick={props.onContactSupport}
                >
                    Contact Support
                </ButtonBig>
            </div>
        );
    };

    const renderContent = () => {
        if (!props.plans.isPlansLoaded
            || !props.plans.isActivePlansLoaded) {
            return (
                <RequestLoader />
            );
        }

        if (isEmptyPlan && isEmptyActivePlan && !props.user.isFree) {
            const supportEmail = (
                <a
                    href={`mailto:${settings.supportEmail}`}
                >
                    {settings.supportEmail}
                </a>
            );

            return (
                <Message>
                    {text.userHasNoPaymentPlans}
                    {supportEmail}
                </Message>
            );
        }

        const features = getPlanFeatures();

        return (
            <Accordion
                label={renderLabel()}
                expanded={isOpenAccordion}
                onClick={onAccordionClick}
            >
                <ul className={styles.planDetailsFeatures}>
                    {features.map((feat) => (
                        <li>{feat}</li>
                    ))}
                </ul>
            </Accordion>
        );
    };

    /* --- */

    return (
        <div className={styles.planDetailsContainer}>
            <WithScroll>
                <div className={styles.planDetails}>
                    {renderContent()}
                </div>
            </WithScroll>
            {renderControls()}
        </div>
    );
};

PlanDetails.defaultProps = {
    user: {
        planId: 0,
        daysLeftToExpire: 0,
        expiration: "",
        isFree: true,
        hasPlan: false,
    },
    plans: {
        isPlansLoaded: false,
        plans: [],
        freeTrialPlanFeatures: [],
        isActivePlansLoaded: false,
        activePlans: [],
    },
    subscription: {
        isLoadingAutoRenewSubscription: false,
        isAutoRenewEnabled: false,
        expireDate: "",
        onUpgrade: () => { },
        onChangeAutoRenew: () => { },
        onCancel: () => { },
    },
    onContactSupport: () => { },
};

export default PlanDetails;
