import { useState } from "react";


const initialState = {
    isOpen: false,
    image: {
        url: "",
        title: "",
    },
};

const useInfographicImage = () => {
    const [state, setState] = useState({
        ...initialState,
    });

    const open = (image) => {
        setState((prev) => ({
            ...prev,
            isOpen: true,
            image: {
                url: image.url,
                title: image.title,
            },
        }));
    };

    const close = () => {
        setState({
            ...initialState,
        });
    };

    return {
        state,
        open,
        close,
    };
};

export default useInfographicImage;
