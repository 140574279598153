import React from "react";

import classNames from "juice-base/lib/class-names.js";

import IconPlayerHeadphone from "juice-base/icons/player-headphone/index.js";
import IconCopyWithArrow from "juice-base/icons/copy-with-arrow/index.js";

import Video from "juice-base/components/video/index.js";
import FeaturedImage from "juice-base/components/featured-image/index.js";
import JuiceContent from "juice-base/components/juice-content/index.js";
import ButtonWithIcon from "juice-base/components/button-with-icon/index.js";
import ButtonShare from "juice-base/components/button-share/index.js";
import EventsWrapper from "juice-base/components/events-wrapper/index.js";
import ButtonCircle from "juice-base/components/button-circle/index.js";

import dailyJuiceCommonStyles from "juice-base/business/daily-juice-common/styles.module.css";
import styles from "./styles.module.css";


const DailyJuiceStory = (props) => {
    const { story } = props;

    if (!story) {
        return null;
    }

    const onListenStory = () => {
        props.onAudioPlay();
    };

    const onShareButtonClick = (evt) => {
        evt.stopPropagation();
        props.share.onClick();
    };

    const renderFeaturedVideo = () => {
        const { featuredVideo } = story;

        let posterUrl = "";

        if (featuredVideo.featuredImage && featuredVideo.featuredImage.url) {
            posterUrl = featuredVideo.featuredImage.url;
        }

        return (
            <EventsWrapper className={styles.featuredVideoContainer}>
                <Video
                    videoId={featuredVideo.id}
                    src={featuredVideo.url}
                    type={featuredVideo.mimeType}
                    captionSrc={props.videoCaptionSrc}
                    poster={posterUrl}
                    onPlayStart={props.onVideoPlayStart}
                    onPlayEnd={props.onVideoPlayEnd}
                    onPause={props.onVideoPlayPause}
                    canPlay={props.isVisible}
                    isDefault={props.isDefaultVideo}
                />
            </EventsWrapper>
        );
    };

    const renderFeaturedContent = () => {
        if (story?.featuredImage?.url) {
            return (
                <FeaturedImage
                    featuredImage={story.featuredImage}
                    dimensions={props.dimensions}
                    onFullscreenImage={props.onImageClick}
                />
            );
        }

        if (story?.featuredVideo?.url) {
            return renderFeaturedVideo();
        }

        return null;
    };

    const renderCategory = () => {
        let category = story.categoryName;

        if (props.showStoryIndex && props.storyIndex !== null) {
            category = `${props.storyIndex + 1} | ${story.categoryName}`;
        }

        const categoryClassName = classNames({
            [dailyJuiceCommonStyles.header]: true,
            [styles.category]: true,
        });

        return (
            <div className={categoryClassName}>
                {category}
            </div>
        );
    };

    const renderTitle = () => {
        const headlineClassName = classNames({
            [dailyJuiceCommonStyles.headline]: true,
            [styles.headline]: true,
        });

        return (
            <div className={headlineClassName}>
                {story.title}
            </div>
        );
    };

    const renderShareButton = () => {
        const storyId = props?.story?.ID || "";

        if (!props.withShareButton || !storyId) {
            return null;
        }

        const dataComment = `classroom-share-button-daily-juice-${props.storyIndex}`;

        return (
            <ButtonShare
                googleClassroom={props.share.googleClassroom}
                onCloseMenu={props.share.onCloseMenu}
                onCopyLink={props.share.onCopyLink}
                onClick={onShareButtonClick}
                isGoogleClassroomLoading={props.share.isGoogleClassroomLoading}
                isCopyLinkLoading={props.share.isCopyLinkLoading}
                dataComment={dataComment}
                closeMenuOnClassroomClick={props.share.closeMenuOnClassroomClick}
                closeMenuOnCopyLinkClick={props.share.closeMenuOnCopyLinkClick}
                isVisibleMenu={props.share.isVisibleMenu}
                isAuto={false}
            />
        );
    };

    const renderAssignButton = () => {
        if (!props.withAssignButton) {
            return null;
        }

        const assignIcon = (
            <IconCopyWithArrow
                isOrange
            />
        );

        return (
            <ButtonCircle
                icon={assignIcon}
                onClick={props.onAssignmentsClick}
            />
        );
    };

    const renderControls = () => {
        if (story?.featuredVideo?.url) {
            return null;
        }

        if (!props.enableListen) {
            return null;
        }

        return (
            <div className={styles.playerAudioToggleButton}>
                <ButtonWithIcon
                    icon={<IconPlayerHeadphone title="Headphone" isWhite />}
                    onClick={onListenStory}
                >
                    Listen
                </ButtonWithIcon>
                {renderShareButton()}
                {renderAssignButton()}
            </div>
        );
    };

    return (
        <div className={styles.juiceStory}>
            {renderFeaturedContent()}

            {renderCategory()}
            {renderTitle()}
            {renderControls()}

            <JuiceContent
                className={styles.content}
                content={story.content}
                extraJuices={story.extraJuices || []}
                onWordClick={props.onWordClick}
                onExtraJuiceWordClick={(openedBy) => {
                    const sId = story.ID || story.id;
                    const ejs = story.extraJuices || [];
                    const ejId = ejs.length > 0 ? ejs[0].id : null;

                    props.onExtraJuiceWordClick(sId, ejId, openedBy);
                }}
            />
        </div>
    );
};

DailyJuiceStory.defaultProps = {
    juiceId: "",
    story: null,
    storyIndex: 0,
    dimensions: {},
    videoCaptionSrc: null,
    share: {},

    onCopyToClipboard: () => { },
    onImageClick: () => { },
    onWordClick: () => { },
    onAudioPlay: () => { },
    onVideoPlayStart: () => { },
    onVideoPlayPause: () => { },
    onVideoPlayEnd: () => { },
    onExtraJuiceWordClick: () => { },
    onAssignmentsClick: () => { },

    showStoryIndex: true,
    enableListen: true,
    isDefaultVideo: true,
    withShareButton: false,
    withAssignButton: false,
};

export default DailyJuiceStory;
