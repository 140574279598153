const loadSubdivisionsByAlpha2 = (services, params) => {
    const {
        actions,
        api,
    } = services;

    return async (dispatch) => {
        dispatch(actions.geo.setSubdivisionsByAlpha2Loading({
            alpha2: params.alpha2,
        }));

        const res = await api.geo.getSubdivisions({
            session: params.session,
            alpha2: params.alpha2,
        });

        if (!res.ok) {
            return;
        }

        dispatch(actions.geo.setSubdivisionsByAlpha2({
            alpha2: params.alpha2,
            subdivisionName: res.subdivisionName,
            subdivisions: res.subdivisions,
        }));
    };
};

const loadSchoolDistricts = (services, params) => {
    const {
        actions,
        api,
    } = services;

    const {
        session,
        subdivision,
    } = params;

    return async (dispatch) => {
        const res = await api.geo.getDistricts({
            session,
            search: "",
            subdivision,
        });

        if (!res.ok) {
            return;
        }

        dispatch(actions.geo.setDistrictsBySubdivision({
            subdivision,
            districts: res?.districts || [],
        }));
    };
};

const loadSchoolCities = (services, params) => {
    const {
        actions,
        api,
    } = services;

    const {
        session,
        subdivision,
    } = params;

    return async (dispatch) => {
        const res = await api.geo.getPrivateSchoolsCities({
            session,
            subdivision,
        });

        if (!res.ok) {
            return;
        }

        dispatch(actions.geo.setCitiesBySubdivision({
            subdivision,
            cities: res?.cities || [],
        }));
    };
};

const loadSchoolsByCity = (services, params) => {
    const {
        actions,
        api,
    } = services;

    const {
        session,
        city,
    } = params;

    return async (dispatch) => {
        const res = await api.geo.getPrivateSchools({
            session,
            city,
        });

        if (!res.ok) {
            return;
        }

        dispatch(actions.geo.setSchoolsByCity({
            city,
            schools: res?.schools || [],
        }));
    };
};

const loadSchoolsByDistrictId = (services, params) => {
    const {
        actions,
        api,
    } = services;

    const {
        session,
        districtId,
    } = params;

    return async (dispatch) => {
        const res = await api.geo.getSchools({
            session,
            search: "",
            districtId,
        });

        if (!res.ok) {
            return;
        }

        dispatch(actions.geo.setSchoolsByDistrictId({
            districtId,
            schools: res.schools || [],
        }));
    };
};

export default {
    loadSubdivisionsByAlpha2,
    loadSchoolDistricts,
    loadSchoolCities,
    loadSchoolsByCity,
    loadSchoolsByDistrictId,
};
