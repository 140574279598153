import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import date from "juice-base/lib/date.js";
import storage from "juice-base/lib/storage/index.js";
import urls from "juice-base/lib/urls.js";

import Grades from "juice-base/project/grades.js";
import Classes from "juice-base/project/classes.js";
import Standards from "juice-base/project/standards.js";
import Pages from "juice-base/project/pages.js";
import Urls from "juice-base/project/urls.js";

import actions from "juice-base/store/actions.js";
import actionsTeacher from "juice-base/actions/teacher.js";
import actionsSite from "juice-base/actions/site.js";

import useAccordion from "juice-base/hooks/use-accordion/index.js";

import IconCategoriesList from "juice-base/icons/categories-list/index.js";
import IconCalendar from "juice-base/icons/calendar/index.js";
import IconAnnouncement from "juice-base/icons/announcement/index.js";
import IconClassPerformance from "juice-base/icons/class-performance/index.js";
import IconDownload from "juice-base/icons/download/index.js";

import RequestLoader from "juice-base/components/request-loader/index.js";
import Tabs from "juice-base/components/tabs/index.js";
import SelectCustomWithControls from "juice-base/components/select-custom-with-controls/index.js";
import SelectCustom from "juice-base/components/select-custom/index.js";
import AccordionWithControls from "juice-base/components/accordion-with-controls/index.js";
import AlertBox from "juice-base/components/alert-box/index.js";
import ButtonBig from "juice-base/components/button-big/index.js";
import PopupDateRangeCustomV2 from "juice-base/components/popup-date-range-custom-v2/index.js";
import IconClickable from "juice-base/components/icon-clickable/index.js";

import TeacherCompletionRate from "juice-base/business/teacher-completion-rate/index.js";
import TeacherAverageScores from "juice-base/business/teacher-average-scores/index.js";
import TeacherQuickLinks from "juice-base/business/teacher-quick-links/index.js";
import TeacherClassAnnouncement from "juice-base/business/teacher-class-announcement/index.js";
import TeacherLearningStandards from "juice-base/business/teacher-learning-standards/index.js";
import GradesIcons from "juice-base/business/grades-icons/index.js";
import GradesIndicator from "juice-base/business/grades-indicator/index.js";

import PopupPageContainer from "juice-app/containers/popup-page/index.js";
import PopupFullScreenAddStudentV2 from "juice-app/containers/popup-full-screen-add-student-v2/index.js";
import TeacherPopupAnnouncement, { useAnnouncementPopup } from "juice-app/containers/teacher-popup-announcement/index.js";
import TeacherPopupSponsors, { useSponsorsPopup } from "juice-app/containers/teacher-popup-sponsors/index.js";
import UserFooter from "juice-app/containers/user-footer/index.js";

import api from "juice-app/api.js";
import settings from "juice-app/settings.js";

import styles from "./styles.module.css";


const TABS = [
    {
        value: "analytics",
        label: "Analytics",
        icon: IconClassPerformance,
    },
    {
        value: "announcements",
        label: "Announcements",
        icon: IconAnnouncement,
    },
];

const PAGES_SLUGS = Pages.getPagesSlugs();

const storeSelector = (state) => ({
    dimensions: state.device.dimensions,
    siteDate: state.info.siteDate,
    urlParams: state.navigation.params,
    navigation: state.navigation,

    session: state.user.session,
    user: state.user.user,

    teacher: state.teacher,
    classesStatsByLearningStandards: state.teacher.classesStatsByLearningStandards,
    classesPerformanceByRange: state.teacher.classesPerformanceByRange,
    classesStoriesCategoriesByRange: state.teacher.classesStoriesCategoriesByRange,
});

const TeacherDashboardV2 = () => {
    const [isVisibleAddStudentsPopup, setIsVisibleAddStudentsPopup] = useState(false);

    const [selectedDate, setSelectedDate] = useState(null);

    const [customDatesPopup, setCustomDatesPopup] = useState({
        startDate: null,
        endDate: null,
        isVisible: false,
    });

    const [isVisibleChartRadarPage, setIsVisibleChartRadarPage] = useState(false);

    /* ----- */

    const classesAccordion = useAccordion();
    const sponsorsPopup = useSponsorsPopup();
    const announcementPopup = useAnnouncementPopup();

    const navigate = useNavigate();

    const dispatch = useDispatch();
    const store = useSelector(storeSelector);

    const hasDateRange = store.urlParams.dateFrom && store.urlParams.dateTo;

    const selectedTab = store.urlParams.tab || TABS[0].value;
    const selectedCategory = store.urlParams?.categories || "all";
    const selectedStandard = store.urlParams?.standardType || Standards.getJuiceStandardValue();

    const isMobile900 = store.dimensions.width <= 900;
    const isMobile1570 = store.dimensions.width <= 1570;

    /* ----- */

    const getCategories = () => {
        return selectedCategory.replaceAll("-", ",");
    };

    /* ----- */

    const getSelectedDate = (dateOptions) => {
        if (selectedDate) {
            return selectedDate;
        }

        let currentRange = "custom";

        if (hasDateRange) {
            for (let i = 0; i < dateOptions.length; i += 1) {
                const opt = dateOptions[i];

                if (opt.dateFrom === store.urlParams.dateFrom
                    && opt.dateTo === store.urlParams.dateTo) {
                    currentRange = opt.value;
                    break;
                }
            }
        }

        return currentRange;
    };

    /* ----- */

    const getRanges = () => {
        const options = [];
        const ranges = date.getRanges();

        const currDate = store.siteDate || new Date();

        for (let i = 0; i < ranges.length; i += 1) {
            const r = ranges[i];

            const { dateFrom, dateTo } = date.getDatesByRange(currDate, r.value);

            const df = date.getDateFromDate(dateFrom);
            const dt = date.getDateFromDate(dateTo);

            const opt = {
                value: r.value,
                label: r.label,
                label2: "",
                dateFrom: df,
                dateTo: dt,
            };

            if (store.siteDate) {
                opt.label2 = date.getFormattedRangeByRange(store.siteDate, r.value);
            }

            options.push(opt);
        }

        const customOpt = {
            value: "custom",
            label: "Custom Date or Range",
            label2: "",
        };

        const sDate = getSelectedDate(options);

        if (sDate === "custom") {
            const d1 = date.tryFormatDateUTC(store.urlParams.dateFrom, date.formatMonthDayYearDate);
            const d2 = date.tryFormatDateUTC(store.urlParams.dateTo, date.formatMonthDayYearDate);

            customOpt.label2 = `${d1} - ${d2}`;
        }

        return [
            ...options,
            customOpt,
        ];
    };

    const getCategoriesOptions = () => {
        const range = `${store.urlParams.dateFrom}-${store.urlParams.dateTo}`;

        const categories = store.classesStoriesCategoriesByRange?.[range]?.categories || [];

        const options = [
            { value: "all", label: "All Categories" },
        ];

        for (let i = 0; i < categories.length; i += 1) {
            const c = categories[i];

            const sameOptionLabelIndex = options.map((opt) => opt.label).indexOf(c.name);

            if (sameOptionLabelIndex === -1) {
                options.push({
                    value: c.id.toString(),
                    label: c.name,
                });
            } else {
                options[sameOptionLabelIndex].value = `${options[sameOptionLabelIndex].value}-${c.id}`;
            }
        }

        return options;
    };

    /* ----- */

    const getSelectedTabIcon = (tab) => {
        for (let i = 0; i < TABS.length; i += 1) {
            if (TABS[i].value === tab) {
                return TABS[i].icon;
            }
        }

        return TABS[0]?.icon || null;
    };

    const getClassAnnouncement = (classId) => {
        const data = store.teacher.classesAnnouncementsById?.[classId] || {};

        return {
            isLoading: data?.isLoading || false,
            data: data?.data || {},
            error: data?.error || "",
        };
    };

    /* ----- */

    const loadSiteDate = () => {
        dispatch(actionsSite.loadSiteDate({
            api,
            actions,
        }));
    };

    const loadTeacherClasses = async () => {
        dispatch(actionsTeacher.loadClasses(
            { actions, api, storage },
            { session: store.session },
        ));
    };

    const loadClassesCategories = () => {
        dispatch(actionsTeacher.loadClassesCategories({
            api,
            actions,
        }));
    };

    const loadClassesPerformance = () => {
        dispatch(actionsTeacher.loadClassesPerformance({ api, actions }));
    };

    const loadClassesLearningStandards = () => {
        dispatch(actionsTeacher.loadClassesStatsByLearningStandards({
            api,
            actions,
        }));
    };

    /* ----- */

    const loadClassAnnouncement = async (params) => {
        const { classId } = params;

        dispatch(actionsTeacher.loadClassAnnouncement(
            { api, actions },
            { classId },
        ));
    };

    const loadClassSponsor = async (params) => {
        const { classId } = params;

        dispatch(actionsTeacher.loadClassSponsor(
            { api, actions },
            { classId },
        ));
    };

    const loadAllClassesSponsors = async () => {
        dispatch(actionsTeacher.loadAllClassesSponsors(
            { api, actions },
            {
                session: store.session,
                classes: store.teacher.classes,
            },
        ));
    };

    const loadAllClassesAnnouncements = async () => {
        dispatch(actionsTeacher.loadAllClassesAnnouncements(
            { api, actions },
            {
                session: store.session,
                classes: store.teacher.classes,
            },
        ));
    };

    /* ----- */

    const onTabChange = (params) => {
        const { tab } = params;

        setSelectedDate(null);

        if (tab === TABS[0].value) {
            navigate("/dashboard/analytics");
        } else if (tab === TABS[1].value) {
            navigate("/dashboard/announcements");
        }
    };

    /* ----- */

    const onClassAnnouncementsClick = (params) => {
        const { classId, isExpanded } = params;

        if (isExpanded) {
            loadClassAnnouncement({
                classId,
            });

            loadClassSponsor({
                classId,
            });
        }

        classesAccordion.onSetExpanded(classId);
    };

    /* ----- */

    const onOpenSponsorPopup = (classId) => {
        sponsorsPopup.open({
            defaultClass: classId,
        });
    };

    const onOpenAnnouncementPopup = (classId) => {
        announcementPopup.open({
            defaultClass: classId,
        });
    };

    const onOpenAddStudentPopup = (classId) => {
        dispatch(actions.teacher.setTeacherSelectedClass({
            selectedClassId: classId,
        }));

        setIsVisibleAddStudentsPopup(true);
    };

    /* ----- */

    const onOpenCustomDatesPopup = (startDate, endDate) => {
        setCustomDatesPopup({
            startDate,
            endDate,
            isVisible: true,
        });
    };

    const onCloseCustomDatesPopup = () => {
        setCustomDatesPopup({
            startDate: null,
            startTo: null,
            isVisible: false,
        });
    };

    const onApplyCustomDates = (dates) => {
        const dateFrom = date.getDateFromDate(dates.startDate, "-");
        const dateTo = date.getDateFromDate(dates.endDate, "-");

        setSelectedDate("custom");

        navigate(`/dashboard/${selectedTab}/${dateFrom}/${dateTo}/all/${selectedStandard}`);

        onCloseCustomDatesPopup();
    };

    /* ----- */

    const onCategoryChange = (value) => {
        const { dateFrom, dateTo } = store.urlParams;

        navigate(`/dashboard/${selectedTab}/${dateFrom}/${dateTo}/${value}/${selectedStandard}`);
    };

    const onRangeChange = (range) => {
        if (range === "custom") {
            const { dateFrom, dateTo } = store.urlParams;
            onOpenCustomDatesPopup(dateFrom, dateTo);
            return;
        }

        setSelectedDate(range);

        const currDate = store.siteDate || new Date();

        const { dateFrom, dateTo } = date.getDatesByRange(currDate, range);

        const dFrom = date.getDateFromDate(dateFrom);
        const dTo = date.getDateFromDate(dateTo);

        navigate(`/dashboard/${selectedTab}/${dFrom}/${dTo}/all/${selectedStandard}`);
    };

    const onStandardTypeChange = (value) => {
        const { dateFrom, dateTo } = store.urlParams;
        navigate(`/dashboard/${selectedTab}/${dateFrom}/${dateTo}/${selectedCategory}/${value}`);
    };

    /* ----- */

    const onExportClassPerformance = () => {
        const { dateFrom, dateTo } = store.urlParams;

        const exportUrl = api.export.getClassesPerformance({
            session: store.session,
            categories: getCategories(),
            dateFrom,
            dateTo,
        });

        urls.universalDownload(exportUrl);
    };

    const onExportStatsByStandards = () => {
        const { dateFrom, dateTo } = store.urlParams;

        const exportUrl = api.export.getClassesStatsByLearningStandards({
            session: store.session,
            dateFrom,
            dateTo,
            standardType: selectedStandard,
        });

        urls.universalDownload(exportUrl);
    };

    /* ----- */

    useEffect(() => {
        loadTeacherClasses();

        return () => {
            dispatch(actions.teacher.clearTeacherClassesPerformance());
            dispatch(actions.teacher.clearTeacherClassesStatsByLearningStandards());
        };
    }, []);

    useEffect(() => {
        if (!store.siteDate) {
            loadSiteDate();
            return;
        }

        const { pathname } = store.navigation.location;

        if (!Urls.isDashboard(pathname)) {
            return;
        }

        const isValidDateFrom = store.urlParams.dateFrom
            && date.isDate(store.urlParams.dateFrom);

        const isValidDateTo = store.urlParams.dateTo
            && date.isDate(store.urlParams.dateTo);

        if (isValidDateFrom && isValidDateTo) {
            if (selectedTab === TABS[0].value) {
                loadClassesCategories();
                loadClassesPerformance();
                loadClassesLearningStandards();
            }
            return;
        }

        let dateFrom = null;
        let dateTo = null;

        if (isValidDateFrom) {
            dateFrom = store.urlParams.dateFrom;
        } else {
            dateFrom = date.getDateFromDate(store.siteDate);
        }

        if (isValidDateTo) {
            dateTo = store.urlParams.dateTo;
        } else {
            dateTo = date.getDateFromDate(store.siteDate);
        }

        if (selectedTab === TABS[0].value
            && (store.urlParams.dateFrom !== dateFrom
                || store.urlParams.dateTo !== dateTo)) {
            const categories = "all";
            const standardType = Standards.getJuiceStandardValue();

            navigate(`/dashboard/${selectedTab}/${dateFrom}/${dateTo}/${categories}/${standardType}`);
        }
    }, [
        store.siteDate,
        store.navigation.location.pathname,
        store.urlParams.tab,
        store.urlParams.dateFrom,
        store.urlParams.dateTo,
    ]);

    /* ----- */

    const renderRadarPagePopup = () => {
        if (!isVisibleChartRadarPage) {
            return null;
        }

        return (
            <PopupPageContainer
                slug={PAGES_SLUGS.infoRadarChart}
                onClose={() => {
                    setIsVisibleChartRadarPage(false);
                }}
            />
        );
    };

    /* ----- */

    const renderAddStudentWindow = () => {
        if (!isVisibleAddStudentsPopup) {
            return null;
        }

        const { classes, selectedClassId } = store.teacher;

        const classLimit = Classes.getClassLimit(classes, selectedClassId);
        const classStudentsCount = Classes.getStudentsCount(classes, selectedClassId);
        const classGrade = Classes.getClassGradeById(classes, selectedClassId);

        const defaultGrade = classGrade
            ? Grades.getValidGrade(classGrade)
            : Grades.getMaxGradeValue();

        return (
            <PopupFullScreenAddStudentV2
                classId={selectedClassId}
                classLimit={classLimit}
                classStudentsCount={classStudentsCount}
                defaultGrade={defaultGrade}
                defaultSelectedMenu={1}
                onLoadClass={loadTeacherClasses}
                onClose={() => {
                    setIsVisibleAddStudentsPopup(false);
                }}
            />
        );
    };

    const renderAnnouncementPopup = () => {
        if (!announcementPopup.state.isVisiblePopup) {
            return null;
        }

        return (
            <TeacherPopupAnnouncement
                isMobile={isMobile900}
                defaultClass={announcementPopup.state.defaultClass}
                onClose={() => {
                    loadAllClassesAnnouncements();
                    announcementPopup.close();
                }}
            />
        );
    };

    const renderSponsorsPopup = () => {
        if (!sponsorsPopup.state.isVisiblePopup) {
            return null;
        }

        return (
            <TeacherPopupSponsors
                isMobile={isMobile900}
                defaultClass={sponsorsPopup.state.defaultClass}
                onClose={() => {
                    loadAllClassesSponsors();
                    sponsorsPopup.close();
                }}
            />
        );
    };

    /* ----- */

    const renderTabsSelect = () => {
        const IconComponent = getSelectedTabIcon(selectedTab);

        const options = TABS.map((tab) => ({
            ...tab,
            optionIcon: <tab.icon />,
        }));

        return (
            <SelectCustomWithControls
                options={options}
                icon={<IconComponent isOrange />}
                onSelect={(tab) => {
                    onTabChange({
                        tab,
                    });
                }}
                selected={selectedTab}
            />
        );
    };

    const renderTabs = () => {
        if (isMobile900) {
            return renderTabsSelect();
        }

        return (
            <Tabs
                tabs={TABS}
                selectedTab={selectedTab}
                onChange={(value) => {
                    onTabChange({
                        tab: value,
                    });
                }}
                onlyTabs
            />
        );
    };

    /* ----- */

    const renderClassAnnouncements = (classId) => {
        const announcement = getClassAnnouncement(classId);
        const sponsor = store.teacher.classesSponsorsById?.[classId] || {};

        return (
            <div className={styles.classAnnouncements}>
                <TeacherClassAnnouncement
                    isLoading={announcement.isLoading}
                    content={announcement.data.announcementContent}
                    onEdit={() => {
                        onOpenAnnouncementPopup(classId);
                    }}
                />
                <TeacherQuickLinks
                    isLoading={sponsor?.isLoading || false}
                    sponsorImage={sponsor?.data?.sponsorImageUrl}
                    onUploadImageClick={() => {
                        onOpenSponsorPopup(classId);
                    }}
                />
            </div>
        );
    };

    const renderAddStudentMessage = () => {
        if (!store.teacher.isClassesLoaded || store.teacher.classes.length === 0) {
            return null;
        }

        const emptyClasses = Classes.getEmptyClasses(store.teacher.classes);

        if (emptyClasses.length === 0) {
            return null;
        }

        if (store.teacher.classes.length > emptyClasses.length) {
            return null;
        }

        const controls = [];

        const selectedClass = store.teacher.selectedClassId === -1
            ? emptyClasses[0].id
            : store.teacher.selectedClassId;

        if (emptyClasses.length > 1) {
            const options = emptyClasses.map((cl) => ({
                value: cl.id,
                label: cl.title,
            }));

            controls.push(
                <SelectCustom
                    selected={selectedClass}
                    options={options}
                    onSelect={(value) => {
                        dispatch(actions.teacher.setTeacherSelectedClass({
                            selectedClassId: value,
                        }));
                    }}
                />,
            );
        }

        controls.push(
            <ButtonBig
                onClick={() => {
                    onOpenAddStudentPopup(selectedClass);
                }}
            >
                Add student
            </ButtonBig>,
        );

        return (
            <AlertBox
                theme="add-student"
                rightControls={controls}
            >
                You haven&apos;t added any students yet. Add a student to get started!
            </AlertBox>
        );
    };

    const renderClassesAnnouncements = () => {
        if (!store.teacher.isClassesLoaded) {
            return (
                <RequestLoader />
            );
        }

        const classes = store.teacher.classes.map((cl) => (
            <AccordionWithControls
                key={`announcement-${cl.id}`}
                isExpanded={classesAccordion.isExpanded(cl.id)}
                label={cl.title}
                label2={`${cl.studentsCount} students`}
                onClick={(params) => {
                    onClassAnnouncementsClick({
                        ...params,
                        classId: cl.id,
                    });
                }}
            >
                {renderClassAnnouncements(cl.id)}
            </AccordionWithControls>
        ));

        return (
            <div className={styles.classesAnnouncements}>
                {classes}
            </div>
        );
    };

    /* ----- */

    const renderClassesGradesIcons = () => {
        return (
            <GradesIcons />
        );
    };

    const renderExportButton = (params = {}) => {
        return (
            <IconClickable
                className={styles.exportButton}
                onClick={params?.onClick}
            >
                <IconDownload />
            </IconClickable>
        );
    };

    /* ----- */

    const renderRangePicker = () => {
        const dateOptions = getRanges();
        const sDate = getSelectedDate(dateOptions);

        return (
            <SelectCustom
                options={dateOptions}
                icon={<IconCalendar isBlack />}
                selected={sDate}
                onSelect={onRangeChange}
                withScroll={false}
                withBorder={false}
                withLabel2
            />
        );
    };

    const renderAnalyticsClassPerformanceControls = () => {
        const categoryOptions = getCategoriesOptions();

        return (
            <div className={styles.controlBar}>
                <div className={styles.controlBarName}>
                    Class Performance
                </div>
                <div className={styles.controlBarRange}>
                    {renderRangePicker()}
                </div>
                <div className={styles.controlBarSettings}>
                    <SelectCustom
                        options={categoryOptions}
                        selected={selectedCategory}
                        icon={<IconCategoriesList isBlack />}
                        onSelect={onCategoryChange}
                        withBorder={false}
                    />
                </div>
                <div className={styles.controlBarGradesExport}>
                    <div className={styles.controlBarGrades}>
                        {renderClassesGradesIcons()}
                    </div>
                    <div className={styles.controlBarExport}>
                        {renderExportButton({
                            onClick: onExportClassPerformance,
                        })}
                    </div>
                </div>
            </div>
        );
    };

    const renderClassNameWithGrades = (classId, data) => {
        const className = Classes.getClassNameById(store.teacher.classes, classId);
        const classGrades = Grades.filterGradesDuplicate(data?.classGrades || []);

        const classGradesIndicators = classGrades.map((grades) => (
            <GradesIndicator
                grades={grades}
            />
        ));

        return (
            <div className={styles.classNameWithGrades}>
                <div>
                    {className}
                </div>
                {classGradesIndicators}
            </div>
        );
    };

    const renderAnalyticsClassPerformance = () => {
        const { dateFrom, dateTo } = store.urlParams;
        const range = `${dateFrom}-${dateTo}`;
        const categories = getCategories();

        const classesPerformances = store.classesPerformanceByRange?.[range]?.[categories] || {};
        const dataByClasses = classesPerformances?.data?.classes || [];

        const completionRate = {
            all: {
                percents: [
                    classesPerformances?.data?.all?.completed || 0,
                    classesPerformances?.data?.all?.inProgress || 0,
                    classesPerformances?.data?.all?.unopened || 0,
                ],
            },
            classes: (dataByClasses || []).map((d) => ({
                title: renderClassNameWithGrades(d?.classId, d),
                percents: [
                    d?.completed || 0,
                    d?.inProgress || 0,
                    d?.unopened || 0,
                ],
            })),
        };

        const averageScores = {
            all: {
                average: classesPerformances?.data?.all?.averageScore || 0,
                min: classesPerformances?.data?.all?.minScore || 0,
                max: classesPerformances?.data?.all?.maxScore || 0,
            },
            classes: (dataByClasses || []).map((d) => ({
                title: renderClassNameWithGrades(d?.classId, d),
                average: d?.averageScore || 0,
                min: d?.minScore || 0,
                max: d?.maxScore || 0,
            })),
        };

        return (
            <div className={styles.classPerformance}>
                <TeacherCompletionRate
                    data={completionRate}
                    isLoading={classesPerformances.isLoading || false}
                />
                <TeacherAverageScores
                    data={averageScores}
                    isLoading={classesPerformances.isLoading || false}
                />
            </div>
        );
    };

    const renderAnalyticsLearningStandardsControls = () => {
        return (
            <div className={styles.controlBar}>
                <div className={styles.controlBarName}>
                    Learning Standards
                </div>
                <div className={styles.controlBarRange}>
                    {renderRangePicker()}
                </div>
                <div className={styles.controlBarSettings}>
                    <SelectCustom
                        options={Standards.getAllStandardsTypes()}
                        icon={<IconCategoriesList isBlack />}
                        onSelect={onStandardTypeChange}
                        selected={selectedStandard}
                        withBorder={false}
                    />
                </div>
                <div className={styles.controlBarGradesExport}>
                    <div className={styles.controlBarGrades}>
                        {renderClassesGradesIcons()}
                    </div>
                    <div className={styles.controlBarExport}>
                        {renderExportButton({
                            onClick: onExportStatsByStandards,
                        })}
                    </div>
                </div>
            </div>
        );
    };

    const renderAnalyticsLearningStandards = () => {
        const { dateFrom, dateTo } = store.urlParams;
        const range = `${dateFrom}-${dateTo}`;
        const stats = store.classesStatsByLearningStandards?.[range]?.data || [];

        const isLoading = store.classesStatsByLearningStandards?.[range]?.isLoading || false;

        const data = [];
        const labels = [];

        for (let i = 0; i < stats.length; i += 1) {
            const d = stats[i];
            const className = renderClassNameWithGrades(d?.classId, d);

            labels.push(className);
            data.push(d);
        }

        return (
            <TeacherLearningStandards
                labels={labels}
                data={data}
                selectedStandardType={selectedStandard}
                onInfoClick={() => {
                    setIsVisibleChartRadarPage(true);
                }}
                isMobile={isMobile1570}
                isLoading={isLoading}
            />
        );
    };

    const renderAnalyticsPage = () => {
        return (
            <>
                {renderAnalyticsClassPerformanceControls()}
                {renderAnalyticsClassPerformance()}
                {renderAnalyticsLearningStandardsControls()}
                {renderAnalyticsLearningStandards()}
            </>
        );
    };

    const renderContent = () => {
        if (selectedTab === TABS[0].value) {
            if (!hasDateRange) {
                return (
                    <RequestLoader />
                );
            }

            return renderAnalyticsPage();
        }

        if (selectedTab === TABS[1].value) {
            return renderClassesAnnouncements();
        }

        return null;
    };

    /* ----- */

    const renderCustomDatesPopup = () => {
        if (!customDatesPopup.isVisible) {
            return null;
        }

        const startDate = date.newDateUTC(customDatesPopup.startDate);
        const endDate = date.newDateUTC(customDatesPopup.endDate);

        const minDate = settings.calendarDates.min;
        let maxDate = null;

        if (store.siteDate) {
            maxDate = date.newDateUTC(store.siteDate);
        }

        return (
            <PopupDateRangeCustomV2
                startDate={startDate}
                endDate={endDate}
                minDate={minDate}
                maxDate={maxDate}
                onApply={onApplyCustomDates}
                onClose={onCloseCustomDatesPopup}
            />
        );
    };

    /* ----- */

    return (
        <>
            {renderRadarPagePopup()}
            {renderSponsorsPopup()}
            {renderAnnouncementPopup()}
            {renderAddStudentWindow()}
            {renderCustomDatesPopup()}

            <div className={styles.page}>
                <div className={styles.content}>
                    {renderTabs()}
                    {renderAddStudentMessage()}
                    {renderContent()}
                </div>
            </div>

            <UserFooter />
        </>
    );
};

export default TeacherDashboardV2;
