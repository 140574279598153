export default (apiURL, methods) => {
    return {
        getAllClassesAnnouncementsByTeacher(params = {}) {
            const url = `${apiURL}/classes/announcements/by-teacher`;

            return methods.get(url, {
                session_id: params.session,
            });
        },

        updateAllTeacherClassesAnnouncements(params = {}) {
            const url = `${apiURL}/classes/announcements/by-teacher`;

            return methods.post(url, {
                session_id: params.session,
                class_id: params.classId,
                announcement_content: params.content,
                announcement_title: params.title,
            });
        },

        /* --- */

        getAllSponsorsByTeacher(params = {}) {
            const url = `${apiURL}/classes/sponsors`;

            return methods.get(url, {
                session_id: params.session,
            });
        },

        updateAllTeacherClassesSponsor(params = {}) {
            const url = `${apiURL}/classes/sponsors/by-teacher`;

            return methods.post(url, {
                session_id: params.session,
                sponsor_image: params.sponsorImageId,
                sponsor_content: params.sponsorContent,
            });
        },

        uploadSponsorMedia(params = {}) {
            const url = `${apiURL}/classes/sponsors/media`;

            return methods.postForm(url, {
                session_id: params.session,
                file: params.file,
            });
        },

        /* --- */

        getTeacherClasses(params = {}) {
            const url = `${apiURL}/classes/by-teacher`;

            return methods.get(url, {
                session_id: params.session,
            });
        },

        getTeacherClassesStudents(params = {}) {
            // NOTE: action=get_teacher_classes_students
            const url = `${apiURL}/classes/students`;

            return methods.get3(url, {
                session_id: params.session,
            });
        },

        /* --- */

        getClassAnnouncement(params = {}) {
            const url = `${apiURL}/classes/${params.classId}/announcement`;

            return methods.get(url);
        },

        updateClassAnnouncement(params = {}) {
            const url = `${apiURL}/classes/${params.id}/announcement`;

            return methods.post(url, {
                session_id: params.session,
                class_id: params.classId,
                announcement_content: params.content,
                announcement_title: params.title,
            });
        },

        /* --- */

        getClassSponsors(params = {}) {
            const url = `${apiURL}/classes/${params.classId}/sponsors`;

            return methods.get(url);
        },

        updateClassSponsors(params = {}) {
            const url = `${apiURL}/classes/${params.classId}/sponsors`;

            return methods.post(url, {
                session_id: params.session,
                class_id: params.classId,
                sponsor_image: params.sponsorImageId,
                sponsor_content: params.sponsorContent,
            });
        },

        // TODO: delete request
        addStudent(params = {}) {
            const url = `${apiURL}/classes/${params.classId}/students/add`;

            return methods.get(url, {
                action: "add_student",
                session_id: params.session,
                full_name: params.fullname,
                email: params.email,
            });
        },

        addStudentV2(params = {}) {
            // NOTE: action=add_student_v2
            const url = `${apiURL}/classes/${params.classId}/students/add`;

            return methods.post(url, {
                session_id: params.session,
                class_id: params.classId,

                firstname: params.firstname,
                lastname: params.lastname,
                email: params.email,
                grade: params.grade,
                add_new_on_duplicate: params.addNewOnDuplicate,
            });
        },

        resetStudentPasswordById(params = {}) {
            // NOTE: action=reset_student_password
            const url = `${apiURL}/classes/students/reset-password`;

            return methods.get(url, {
                session_id: params.session,
                student_id: params.studentId,
            });
        },

        acceptStudentToClass(params = {}) {
            // NOTE: action=class_approve_student_by_teacher
            const url = `${apiURL}/students/${params.studentId}/approve`;

            return methods.get(url, {
                session_id: params.session,
            });
        },

        rejectStudentToClass(params = {}) {
            // NOTE: action=class_reject_student_by_teacher
            const url = `${apiURL}/students/${params.studentId}/reject`;

            return methods.get(url, {
                session_id: params.session,
                student_id: params.studentId,
                class_id: params.classId,
            });
        },

        dismissStudentWithForgottenPassword(params = {}) {
            // NOTE: action=class_dismiss_student_by_teacher_with_forgotten_password
            const url = `${apiURL}/students/${params.studentId}/dismiss`;

            return methods.get(url, {
                session_id: params.session,
            });
        },

        removeStudentById(params = {}) {
            const url = `${apiURL}/classes/students/remove`;

            return methods.get(url, {
                session_id: params.session,
                student_id: params.studentId,
            });
        },

        getClassStudentsById(params = {}) {
            // NOTE: action=get_students_by_class_id
            const url = `${apiURL}/classes/${params.classId}/students`;

            return methods.get(url, {
                session_id: params.session,
                date_from: params.dateFrom,
                date_to: params.dateTo,
            });
        },

        getClassStudentsScoresByRange(params = {}) {
            // NOTE: action=get_teacher_class_students_scores_by_range
            const url = `${apiURL}/classes/${params.classId}/students/scores`;

            return methods.get(url, {
                session_id: params.session,
                date_from: params.dateFrom,
                date_to: params.dateTo,
            });
        },

        getClassShortInfoStudentsById(params = {}) {
            // NOTE: action=get_students_by_class_id_mini
            const url = `${apiURL}/classes/${params.classId}/students/mini`;

            return methods.get(url, {
                session_id: params.session,
            });
        },

        getJuiceStats(params = {}) {
            const url = `${apiURL}/classes/${params.classId}/stats/juice`;

            return methods.get(url, {
                session_id: params.session,
                date_from: params.dateFrom,
                date_to: params.dateTo,
            });
        },

        getStudentsActivity(params = {}) {
            // NOTE: action=get_student_activity
            const url = `${apiURL}/classes/${params.classId}/activity`;

            return methods.get(url, {
                session_id: params.session,
                class_id: params.classId,
                date_from: params.dateFrom,
                date_to: params.dateTo,
            });
        },

        getStudentsWithForgottenPasswords(params = {}) {
            // NOTE: action=get_students_with_forgotten_passwords
            const url = `${apiURL}/students/with-forgotten-passwords`;

            return methods.get(url, {
                session_id: params.session,
                class_id: params.classId,
            });
        },

        getStudentsWithPendingStatus(params = {}) {
            // NOTE: action=get_class_students_with_pending_status
            const url = `${apiURL}/classes/${params.classId}/students/pending`;

            return methods.get(url, {
                session_id: params.session,
                page: params.page,
            });
        },

        getLearningStandardsByClassId(params = {}) {
            // NOTE: action=get_learning_standards_by_class_id
            const url = `${apiURL}/classes/${params.classId}/standards`;

            return methods.get(url, {
                session_id: params.session,
                class_id: params.classId,
                date_from: params.dateFrom,
                date_to: params.dateTo,
            });
        },

        setUpTeacherClass(params = {}) {
            // NOTE: action=teacher_class_set_up
            const url = `${apiURL}/classes/setup`;

            return methods.postForm(url, {
                session_id: params.session,
                country: params.country,
                subdivision: params.subdivision,
                district: params.district,
                city: params.city,
                school_type: params.schoolType,
                school: params.schoolName,
                school_id: params.schoolId,
                department: params.department,
                class: params.class,
                grades: params.grades,
            });
        },

        updateClass(params = {}) {
            // NOTE: action=class_update_by_teacher
            const url = `${apiURL}/classes/by-teacher/update`;

            return methods.postForm(url, {
                session_id: params.session,
                class_id: params.classId,
                class_name: params.className,
                grade_level: params.grade,
            });
        },

        addClass(params = {}) {
            // NOTE: action=class_add_by_teacher
            const url = `${apiURL}/classes/by-teacher/create`;

            return methods.postForm(url, {
                session_id: params.session,
                class_name: params.className,
                grade_level: params.grade,
            });
        },

        deleteClass(params = {}) {
            // NOTE: action=class_delete_by_teacher
            const url = `${apiURL}/classes/by-teacher/delete`;

            return methods.post(url, {
                session_id: params.session,
                class_id: params.classId,
            });
        },

        getStoryCategoriesByClassId(params = {}) {
            // NOTE: action=get_teacher_classes_stories_categories_by_range
            const url = `${apiURL}/classes/juice-stories/categories`;

            return methods.get3(url, {
                session_id: params.session,
                range: params.range,
                date_from: params.dateFrom,
                date_to: params.dateTo,
            });
        },

        getClassJuicesStats(params = {}) {
            // NOTE: action=get_juice_stats_by_class_id_v2
            const url = `${apiURL}/classes/${params.classId}/stats/juice/v2`;

            return methods.get3(url, {
                session_id: params.session,
                range: params.range,
                date_from: params.dateFrom,
                date_to: params.dateTo,
            });
        },

        getClassCompletionRates(params = {}) {
            // NOTE: action=get_teacher_class_completion_rates_by_range
            const url = `${apiURL}/classes/${params.classId}/stats/completion-rates`;

            return methods.get3(url, {
                session_id: params.session,
                range: params.range,
                date_from: params.dateFrom,
                date_to: params.dateTo,
            });
        },

        // TODO: delete request
        getClassAverageScores(params = {}) {
            // NOTE: action=get_teacher_class_average_scores_by_range
            const url = `${apiURL}/classes/${params.classId}/average-scores`;

            return methods.get3(url, {
                session_id: params.session,
                category_id: params.category,
                range: params.range,
                date_from: params.dateFrom,
                date_to: params.dateTo,
            });
        },

        getClassAverageScoresByStandards(params = {}) {
            // NOTE: action=get_teacher_classes_average_scores_by_standards
            const url = `${apiURL}/classes/average-scores/by-standards`;

            return methods.get3(url, {
                session_id: params.session,
                range: params.range,
                date_from: params.dateFrom,
                date_to: params.dateTo,
            });
        },

        getClassPollResults(params = {}) {
            // NOTE: action=get_poll_results_by_class_id
            const url = `${apiURL}/classes/${params.classId}/polls/${params.pollId}`;

            return methods.get3(url, {
                session_id: params.session,
            });
        },

        getClassNotifications(params = {}) {
            // NOTE: action=class_get_students_with_bounce_notifications
            const url = `${apiURL}/classes/${params.classId}/notifications`;

            return methods.get3(url, {
                session_id: params.session,
            });
        },

        getClassesPerformance(params = {}) {
            // NOTE: action=get_teacher_classes_performance
            const url = `${apiURL}/classes/performance`;

            return methods.get3(url, {
                session_id: params.session,
                categories: params.categories,
                date_from: params.dateFrom,
                date_to: params.dateTo,
            });
        },

        getClassesStatsByLearningStandards(params = {}) {
            // NOTE: action=get_teacher_classes_stats_by_learning_standards
            const url = `${apiURL}/classes/stats/by-standards`;

            return methods.get3(url, {
                session_id: params.session,
                date_from: params.dateFrom,
                date_to: params.dateTo,
            });
        },

        getClassDetails(params = {}) {
            // NOTE: action=class_get_details
            const url = `${apiURL}/classes/${params.classId}`;

            return methods.get3(url, {
                session_id: params.session,
            });
        },

        checkTeacherForAdditionToClass(params = {}) {
            // NOTE: action=class_check_teacher_for_addition
            const url = `${apiURL}/classes/${params.classId}/teachers/addition-check`;

            return methods.post(url, {
                session_id: params.session,
                class_id: params.classId,
                email: params.email,
            });
        },

        addExistingTeacherToClass(params = {}) {
            // NOTE: action=class_add_existing_teacher_to_class
            const url = `${apiURL}/classes/${params.classId}/teachers/add`;

            return methods.post(url, {
                session_id: params.session,
                class_id: params.classId,
                email: params.email,
            });
        },

        registerNewTeacherToClass(params = {}) {
            // NOTE: action=class_register_new_teacher_to_class
            const url = `${apiURL}/classes/${params.classId}/teachers/register`;

            return methods.post(url, {
                session_id: params.session,
                class_id: params.classId,
                email: params.email,
                first_name: params.firstName,
                last_name: params.lastName,
            });
        },

        deleteTeacherFromClass(params = {}) {
            // NOTE: action=class_remove_additional_teacher
            const url = `${apiURL}/classes/${params.classId}/teachers/delete`;

            return methods.post(url, {
                session_id: params.session,
                class_id: params.classId,
                teacher_id: params.teacherId,
            });
        },

        getClassTeachers(params = {}) {
            // NOTE: action=class_get_teachers
            const url = `${apiURL}/classes/${params.classId}/teachers`;

            return methods.get3(url, {
                session_id: params.session,
                class_id: params.classId,
            });
        },
    };
};
