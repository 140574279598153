import React from "react";

import IconPencilFilled from "juice-base/icons/pencil-filled/index.js";
import IconReset from "juice-base/icons/reset/index.js";
import IconTrashFull from "juice-base/icons/trash-full/index.js";

import IconClickable from "juice-base/components/icon-clickable/index.js";
import MenuContentRow from "juice-base/components/menu-content-row/index.js";


const StudentAccountInfo = (props) => {
    if (!props.account) {
        return null;
    }

    const renderAccount = () => {
        let icon = null;

        if (!props.isLMS) {
            icon = (
                <IconClickable onClick={props.onDeleteAccount}>
                    <IconTrashFull
                        title="Delete Account"
                        isBlack
                    />
                </IconClickable>
            );
        }

        return (
            <MenuContentRow
                label="Account"
                icon={icon}
                labelOnly
            />
        );
    };

    const renderName = () => {
        let icon = null;

        if (!props.isLMS) {
            icon = (
                <IconClickable onClick={props.onEditName}>
                    <IconPencilFilled
                        title="Edit name"
                        isBlack
                    />
                </IconClickable>
            );
        }

        return (
            <MenuContentRow
                label="Name"
                value={props.account?.fullName || ""}
                icon={icon}
            />
        );
    };

    const renderEmail = () => {
        let icon = null;

        if (!props.isLMS) {
            icon = (
                <IconClickable onClick={props.onEditEmail}>
                    <IconPencilFilled
                        title="Edit email"
                        isBlack
                    />
                </IconClickable>
            );
        }

        return (
            <MenuContentRow
                label="Email"
                value={props.account?.email || ""}
                icon={icon}
                tooltip={props.emailNotification}
            />
        );
    };

    const renderUsername = () => {
        return (
            <MenuContentRow
                label="Username"
                value={props.account?.userName || ""}
            />
        );
    };

    const renderPassword = () => {
        if (props.isLMS) {
            return null;
        }

        const icon = (
            <IconClickable onClick={props.onEditPassword}>
                <IconReset
                    title="Edit password"
                    isBlack
                />
            </IconClickable>
        );

        return (
            <MenuContentRow
                label="Password"
                value="***********"
                icon={icon}
            />
        );
    };

    return (
        <div>
            {renderAccount()}
            {renderName()}
            {renderEmail()}
            {renderUsername()}
            {renderPassword()}
        </div>
    );
};

StudentAccountInfo.defaultProps = {
    account: null,
    emailNotification: false,
    isLMS: false,
    onDeleteAccount: () => { },
    onEditName: () => { },
    onEditEmail: () => { },
    onEditPassword: () => { },
};

export default StudentAccountInfo;
