const STORIES_TYPES = {
    article: "article",
    infographic: "infographic",
    extraJuice: "extra juice",
    video: "video",
};

const isStoryTypeExtraJuice = (type) => {
    return type === STORIES_TYPES.extraJuice;
};

const isStoryTypeVideo = (type) => {
    return type === STORIES_TYPES.video;
};

/* ------ */

const getViewTypes = () => {
    return {
        list: "list",
        cards: "cards",
    };
};

const getContentTypes = () => {
    return [
        { value: "all", label: "All" },
        { value: "article", label: "Article" },
        { value: "infographic", label: "Infographic" },
        { value: "extra juice", label: "Extra juice" },
        { value: "video", label: "Video" },
    ];
};

const getSortOrders = () => {
    return [
        { value: "relevance", label: "Relevance" },
        { value: "desc", label: "Newest" },
        { value: "asc", label: "Oldest" },
    ];
};

const getSearchResult = (storeEmpty, storeByKeyword, searchValue) => {
    if (!searchValue) {
        return storeEmpty || {};
    }

    return storeByKeyword[searchValue] || {};
};

const getCategories = (categories, categoriesIds) => {
    const cats = categories || [];
    const catsIds = categoriesIds || [];

    const res = [];

    catsIds.forEach((catId) => {
        for (let i = 0; i < cats.length; i += 1) {
            const cat = cats[i];
            if (cat.id === catId) {
                res.push(cat);
                break;
            }
        }
    });

    return res;
};

export default {
    isStoryTypeExtraJuice,
    isStoryTypeVideo,

    getViewTypes,
    getContentTypes,
    getSortOrders,
    getSearchResult,
    getCategories,
};
