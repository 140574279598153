// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.cr2WNrpcHAOfOu0YoiAl {\n    display: grid;\n    align-items: center;\n\n    padding: 0 2rem;\n    height: 6rem;\n\n    font-weight: 600;\n    color: var(--text-secondary-color);\n\n    border-bottom: 0.1rem solid var(--border-default-color);\n}\n\n.cPqgKjdI_hBdpC0Mg2uo {\n    border: 0.1rem solid var(--border-default-color);\n\n    border-left: 0;\n    border-right: 0;\n}\n\n.zR9bZIWZD4bnwtBGtH9D {\n    padding: 0 2rem;\n    font-weight: 600;\n    color: var(--text-secondary-color);\n}\n\n.OqwqlJnQDHuPawk4jg5C {\n    margin: 2rem;\n}\n", "",{"version":3,"sources":["webpack://./base/business/teacher-completion-rate/styles.module.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,mBAAmB;;IAEnB,eAAe;IACf,YAAY;;IAEZ,gBAAgB;IAChB,kCAAkC;;IAElC,uDAAuD;AAC3D;;AAEA;IACI,gDAAgD;;IAEhD,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,kCAAkC;AACtC;;AAEA;IACI,YAAY;AAChB","sourcesContent":["\n.completionRateAll {\n    display: grid;\n    align-items: center;\n\n    padding: 0 2rem;\n    height: 6rem;\n\n    font-weight: 600;\n    color: var(--text-secondary-color);\n\n    border-bottom: 0.1rem solid var(--border-default-color);\n}\n\n.completionRateBySchool {\n    border: 0.1rem solid var(--border-default-color);\n\n    border-left: 0;\n    border-right: 0;\n}\n\n.completionRateBySchoolLabel {\n    padding: 0 2rem;\n    font-weight: 600;\n    color: var(--text-secondary-color);\n}\n\n.legend {\n    margin: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"completionRateAll": "cr2WNrpcHAOfOu0YoiAl",
	"completionRateBySchool": "cPqgKjdI_hBdpC0Mg2uo",
	"completionRateBySchoolLabel": "zR9bZIWZD4bnwtBGtH9D",
	"legend": "OqwqlJnQDHuPawk4jg5C"
};
export default ___CSS_LOADER_EXPORT___;
