import { debugEvent } from "juice-base/lib/debug.js";


export default (methods, ids) => {
    return {
        homeChangeClass(params) {
            const event = {
                session: params.session,
                locationId: ids.location.home,
                eventId: ids.event.teacherHomeChangeClass,
                objectId: params.classId,
            };

            debugEvent("Home teacher change class", event);
            methods.sendEvent(event);
        },

        homeEditClassAnnouncement(params) {
            const event = {
                session: params.session,
                locationId: ids.location.home,
                eventId: ids.event.teacherHomeEditClassAnnouncement,
                objectId: params.classId,
            };

            debugEvent("Home teacher edit class announcement", event);
            methods.sendEvent(event);
        },

        homeUploadAnImage(params) {
            const event = {
                session: params.session,
                locationId: ids.location.home,
                eventId: ids.event.teacherHomeUploadAnImage,
                objectId: params.classId,
            };

            debugEvent("Home teacher upload an image", event);
            methods.sendEvent(event);
        },

        homeViewAllScores(params) {
            const event = {
                session: params.session,
                locationId: ids.location.home,
                eventId: ids.event.teacherHomeViewAllScores,
            };

            debugEvent("Home teacher view all scores", event);
            methods.sendEvent(event);
        },

        exportClassReport(params) {
            const event = {
                session: params.session,
                locationId: ids.location.class,
                eventId: ids.event.teacherExportClassReport,
                objectId: params.classId,
            };

            debugEvent("Teacher export class report", event);
            methods.sendEvent(event);
        },

        exportStudentActivityReport(params) {
            const event = {
                session: params.session,
                locationId: ids.location.class,
                eventId: ids.event.teacherExportStudentActivityReport,
                objectId: params.studentId,
            };

            debugEvent("Teacher export student activity report", event);
            methods.sendEvent(event);
        },

        exportStudentPerformanceReport(params) {
            const event = {
                session: params.session,
                locationId: ids.location.class,
                eventId: ids.event.teacherExportStudentPerformanceReport,
                objectId: params.studentId,
            };

            debugEvent("Teacher export student performance report", event);
            methods.sendEvent(event);
        },

        teacherExportClassScores(params) {
            const event = {
                session: params.session,
                locationId: ids.location.class,
                eventId: ids.event.teacherExportClassScores,
                objectId: params.classId,
            };

            debugEvent("Teacher export class scores report", event);
            methods.sendEvent(event);
        },

        teacherExportClassWeekScores(params) {
            const event = {
                session: params.session,
                locationId: ids.location.class,
                eventId: ids.event.teacherExportClassWeekScores,
                objectId: params.classId,
            };

            debugEvent("Teacher export class week scores report", event);
            methods.sendEvent(event);
        },

        openSearch(params) {
            const event = {
                session: params.session,
                locationId: ids.location.search,
                eventId: ids.event.teacherOpenSearch,
            };

            debugEvent("Teacher open search", event);
            methods.sendEvent(event);
        },

        onSearch(params) {
            const event = {
                session: params.session,
                locationId: ids.location.search,
                eventId: ids.event.teacherOnSearch,
            };

            debugEvent("Teacher search", event);
            methods.sendEvent(event);
        },

        searchSortChange(params) {
            const event = {
                session: params.session,
                locationId: ids.location.search,
                eventId: ids.event.teacherSearchSortChange,
            };

            debugEvent("Teacher search sort change", event);
            methods.sendEvent(event);
        },

        searchGradeChange(params) {
            const event = {
                session: params.session,
                locationId: ids.location.search,
                eventId: ids.event.teacherSearchGradeChange,
            };

            debugEvent("Teacher search grade change", event);
            methods.sendEvent(event);
        },

        searchCategoriesChange(params) {
            const event = {
                session: params.session,
                locationId: ids.location.search,
                eventId: ids.event.teacherSearchCategoriesChange,
            };

            debugEvent("Teacher search categories change", event);
            methods.sendEvent(event);
        },

        searchOpenStory(params) {
            const event = {
                session: params.session,
                locationId: ids.location.search,
                eventId: ids.event.teacherSearchOpenStory,
                objectId: params.storyId,
            };

            debugEvent("Teacher search open story", event);
            methods.sendEvent(event);
        },

        exportAssignments(params) {
            const event = {
                session: params.session,
                locationId: ids.location.class,
                eventId: ids.event.teacherExportAssignments,
                objectId: params.studentId,
            };

            debugEvent("Teacher export assignments", event);
            methods.sendEvent(event);
        },

        openAssignmentStory(params) {
            const event = {
                session: params.session,
                locationId: ids.location.class,
                eventId: ids.event.teacherOpenAssignmentStory,
                objectId: params.storyId,
            };

            debugEvent("Teacher open assignment story", event);
            methods.sendEvent(event);
        },

        openAssignmentQuiz(params) {
            const event = {
                session: params.session,
                locationId: ids.location.class,
                eventId: ids.event.teacherOpenAssignmentQuiz,
                objectId: params.studentId,
                subObjectId: params.quizId,
            };

            debugEvent("Teacher open assignment quiz", event);
            methods.sendEvent(event);
        },

        openAssignmentStandards(params) {
            const event = {
                session: params.session,
                locationId: ids.location.class,
                eventId: ids.event.teacherOpenAssignmentStandards,
                objectId: params.studentId,
            };

            debugEvent("Teacher open assignment standards", event);
            methods.sendEvent(event);
        },

        sortAssignmentsByDate(params) {
            const event = {
                session: params.session,
                locationId: ids.location.class,
                eventId: ids.event.teacherSortAssignmentsByDate,
                objectId: params.studentId,
            };

            debugEvent("Teacher sort assignment by date", event);
            methods.sendEvent(event);
        },

        sortAssignmentsByScore(params) {
            const event = {
                session: params.session,
                locationId: ids.location.class,
                eventId: ids.event.teacherSortAssignmentsByScore,
                objectId: params.studentId,
            };

            debugEvent("Teacher sort assignment by score", event);
            methods.sendEvent(event);
        },

        sortAssignmentsByGrade(params) {
            const event = {
                session: params.session,
                locationId: ids.location.class,
                eventId: ids.event.teacherSortAssignmentsByGrade,
                objectId: params.studentId,
            };

            debugEvent("Teacher sort assignment by grade", event);
            methods.sendEvent(event);
        },

        sortAssignmentsByStandards(params) {
            const event = {
                session: params.session,
                locationId: ids.location.class,
                eventId: ids.event.teacherSortAssignmentsByStandard,
                objectId: params.studentId,
            };

            debugEvent("Teacher sort assignment by standards", event);
            methods.sendEvent(event);
        },

        createAssignmentsOnSearch(params) {
            const event = {
                session: params.session,
                locationId: ids.location.search,
                eventId: ids.event.teacherCreateAssignment,
                objectId: params.storyId,
                subObjectId: params.quizId || null,
            };

            debugEvent("Teacher create assignments on search", event);
            methods.sendEvent(event);
        },

        createAssignmentsOnDailyJuice(params) {
            const event = {
                session: params.session,
                locationId: ids.location.dailyJuice,
                eventId: ids.event.teacherCreateAssignment,
                objectId: params.storyId,
                subObjectId: params.quizId || null,
            };

            debugEvent("Teacher create assignments on Daily Juice", event);
            methods.sendEvent(event);
        },

        takeTheTour(params) {
            const event = {
                session: params.session,
                locationId: ids.location.home,
                eventId: ids.event.teacherSignUpTakeTheTour,
                objectId: params.isMobile ? 0 : 1,
            };

            debugEvent("Teacher take the tour on sign up", event);
            methods.sendEvent(event);
        },

        exploreOnMyOwn(params) {
            const event = {
                session: params.session,
                locationId: ids.location.home,
                eventId: ids.event.teacherSignUpExploreOnMyOwn,
                objectId: params.isMobile ? 0 : 1,
            };

            debugEvent("Teacher explore on my own on sign up", event);
            methods.sendEvent(event);
        },

        tourVideoPlay(params) {
            const event = {
                session: params.session,
                locationId: ids.location.home,
                eventId: ids.event.teacherSignUpTourVideoPlay,
                objectId: params.videoId,
                subObjectId: params.isMobile ? 0 : 1,
            };

            debugEvent("Teacher tour video play on sign up", event);
            methods.sendEvent(event);
        },

        signUpTourVideoPause(params) {
            const event = {
                session: params.session,
                locationId: ids.location.home,
                eventId: ids.event.teacherSignUpTourVideoPause,
                objectId: params.videoId,
                subObjectId: params.isMobile ? 0 : 1,
                timeTook: params.timeTook,
            };

            debugEvent("Teacher tour video pause on sign up", event);
            methods.sendEvent(event);
        },

        signUpTourVideoEnd(params) {
            const event = {
                session: params.session,
                locationId: ids.location.home,
                eventId: ids.event.teacherSignUpTourVideoEnd,
                objectId: params.videoId,
                subObjectId: params.isMobile ? 0 : 1,
            };

            debugEvent("Teacher tour video end on sign up", event);
            methods.sendEvent(event);
        },

        dailyJuiceOpen(params) {
            const event = {
                session: params.session,
                locationId: ids.location.dailyJuice,
                eventId: ids.event.teacherDailyJuiceOpen,
                objectId: params.dailyJuiceId,
            };

            debugEvent("Teacher open daily juice", event);
            methods.sendEvent(event);
        },

        dailyJuiceClose(params) {
            const event = {
                session: params.session,
                locationId: ids.location.dailyJuice,
                eventId: ids.event.teacherDailyJuiceClose,
                objectId: params.dailyJuiceId,
                timeTook: params.timeTook,
            };

            debugEvent("Teacher close daily juice", event);
            methods.sendEvent(event);
        },
    };
};
