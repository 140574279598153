import * as globalTypes from "juice-base/store-common/types.js";
import * as types from "./types.js";


const initialState = {
    isProvidersLoaded: false,
    providers: [],
    providersError: "",

    syncingError: "",

    isSyncingClasses: false,
};

export default (state, action) => {
    switch (action.type) {
        case types.SET_LMS_PROVIDERS: {
            return {
                ...state,
                isProvidersLoaded: true,
                providers: action.payload.providers || [],
            };
        }

        case types.SET_LMS_PROVIDERS_ERROR: {
            return {
                ...state,
                providersError: action.payload.error || "",
            };
        }

        case types.SET_LMS_SYNCING_ERROR: {
            return {
                ...state,
                syncingError: action.payload.error || "",
            };
        }

        case types.SET_LMS_SYNCING_CLASSES: {
            return {
                ...state,
                isSyncingClasses: action.payload.isSyncing,
            };
        }

        case types.CLEAR_LMS_SYNC: {
            return {
                ...state,
                syncingError: "",
                isSyncingClasses: false,
            };
        }

        /* --- */

        case globalTypes.CLEAR_AFTER_SIGNOUT: {
            return { ...initialState };
        }

        /* --- */

        default: {
            return state || { ...initialState };
        }
    }
};
