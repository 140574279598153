import React from "react";

import useTitle from "juice-base/hooks/use-title/index.js";

import { withAuth } from "juice-base/components/auth/index.js";

import StudentDailyJuiceV2 from "juice-app/containers/student-daily-juice-v2/index.js";


const DailyJuice = () => {
    useTitle(() => "Daily Juices", []);

    return (
        <StudentDailyJuiceV2 />
    );
};

export default withAuth([
    "teacher",
    "guardian",
    "student",
])(DailyJuice);
