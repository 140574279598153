import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import text from "juice-base/text/index.js";

import useTitle from "juice-base/hooks/use-title/index.js";

import LayoutContent from "juice-base/components/layout-content/index.js";
import LogoRound from "juice-base/components/logo-round/index.js";
import ButtonBig from "juice-base/components/button-big/index.js";
import MessageWithEmoji from "juice-base/components/message-with-emoji/index.js";
import PasswordForgotForm from "juice-base/forms/user-password-forgot/index.js";

import api from "juice-app/api.js";

import styles from "./styles.module.css";


const RESTORE_BY_EMAIL = "restoreByEmail";
const RESTORE_BY_LSM = "restoreByLMS";

const PasswordForgot = () => {
    const navigate = useNavigate();

    const [isMessageVisible, setIsMessageVisible] = useState(false);
    const [isResetBy, setIsResetBy] = useState("");

    const store = useSelector((state) => ({
        location: state.navigation.location,
    }));

    useTitle(() => "Reset password", []);

    const onBackToSignIn = () => {
        navigate("/sign-in");
    };

    const onSubmit = async (values, { setSubmitting, setErrors }) => {
        const res = await api.user.resetPassword({
            username: values.username,
        });

        if (res.ok) {
            setIsMessageVisible(true);
            setIsResetBy(res.resetMethod);
        } else if (res.isLmsUser) {
            setIsMessageVisible(true);
            setIsResetBy(RESTORE_BY_LSM);
        } else {
            setErrors({
                username: res.error || text.error,
            });
        }

        setSubmitting(false);
    };

    const renderForm = () => {
        if (isMessageVisible) {
            return null;
        }

        const initialValues = {
            username: store?.location?.state?.username || "",
        };

        return (
            <PasswordForgotForm
                initialValues={initialValues}
                onSubmit={onSubmit}
            />
        );
    };

    if (isMessageVisible) {
        let header = "We're on the case!";
        let msg = text.restorePasswordStudent;
        let emojiType = "happy";

        if (isResetBy === RESTORE_BY_EMAIL) {
            msg = text.restorePasswordViaEmail;
        } else if (isResetBy === RESTORE_BY_LSM) {
            header = "Hmmm...";
            msg = text.restorePasswordLMSUser;
            emojiType = "sad";
        }

        return (
            <div className={styles.passwordForgot}>
                <div className={styles.content}>
                    <div className={styles.message}>
                        <MessageWithEmoji
                            header={header}
                            message={msg}
                            type={emojiType}
                        />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <LayoutContent>
            <div className={styles.content}>
                <div className={styles.contentHeader}>
                    <LogoRound />

                    <div className={styles.formName}>
                        Forgot your password?
                    </div>
                </div>

                {renderForm()}

                <div className={styles.backButton}>
                    <ButtonBig onClick={onBackToSignIn}>
                        Back to sign in
                    </ButtonBig>
                </div>
            </div>
        </LayoutContent>
    );
};

export default PasswordForgot;
