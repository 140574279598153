import React, { useRef } from "react";

import Close from "juice-base/components/close/index.js";
import PopupCommon from "juice-base/components/popup-common/index.js";
import WordCard from "juice-base/components/word-card/index.js";

import juiceScrollbarStyles from "juice-base/components/juice-scrollbar/styles.module.css";
import styles from "./styles.module.css";


const PopupWordCard = (props) => {
    const { word } = props;

    const popupLayout = useRef(null);

    const onClose = (evt) => {
        if (popupLayout.current
            && popupLayout.current === evt.target) {
            props.onClose();
        }
    };

    if (!word) {
        return null;
    }

    return (
        <PopupCommon
            onClose={props.onClose}
            showClose={false}
            popupClasses={[
                styles.overlappedPopup,
                props.popupClasses,
            ].join(" ")}
            hideBodyScrollOnOpen={props.hideScrollbar}
        >
            <div
                className={styles.wordCardPopup}
                ref={popupLayout}
                onClick={onClose}
                onKeyPress={onClose}
                role="button"
                tabIndex="-1"
            >
                <div className={styles.word}>
                    <Close
                        onClose={props.onClose}
                        className={styles.close}
                    />

                    <WordCard
                        word={word.word}
                        definitions={word.definitions}
                        audio={props.audio}
                        onLoad={props.onLoad}
                        onPlay={props.onPlay}
                        onStop={props.onStop}
                        onStopAll={props.onStopAll}
                        className={[
                            juiceScrollbarStyles.scrollbar,
                            styles.wordCardScroller,
                        ].join(" ")}
                    />
                </div>
            </div>
        </PopupCommon>
    );
};

PopupWordCard.defaultProps = {
    word: null,
    audio: {},
    popupClasses: "",
    onLoad: () => { },
    onPlay: () => { },
    onStop: () => { },
    onStopAll: () => { },
    onClose: () => { },
    hideScrollbar: true,
};

export default PopupWordCard;
