import React, { useEffect, useRef, useState } from "react";

import classNames from "juice-base/lib/class-names.js";
import dimensions from "juice-base/lib/dimensions.js";

import styles from "./styles.module.css";


const ButtonWithMessage = (props) => {
    const buttonRef = useRef(null);

    const [isVisibleMessage, setIsVisibleMessage] = useState(false);

    const dims = dimensions.getDimensions();
    const isMobile = dims?.width < 500;

    /* --- */

    const onClick = () => {
        if (!isMobile) {
            return;
        }

        setIsVisibleMessage((prev) => !prev);
    };

    /* --- */

    useEffect(() => {
        if (isMobile) {
            return;
        }

        setIsVisibleMessage(false);
    }, [isMobile]);

    useEffect(() => {
        const globalClose = (evt) => {
            if (buttonRef?.current?.contains
                && buttonRef.current.contains(evt.target)) {
                return;
            }

            setIsVisibleMessage(() => false);
        };

        if (document) {
            document.addEventListener("click", globalClose, false);
            document.addEventListener("scroll", globalClose, false);
        }

        return () => {
            if (document) {
                document.removeEventListener("click", globalClose, false);
                document.removeEventListener("scroll", globalClose, false);
            }
        };
    }, []);

    /* --- */

    const messageClassName = classNames({
        [styles.messageBlock]: true,
        [styles.messageBlockVisible]: isVisibleMessage,
        [props.messageClassName]: true,
        [styles.popover]: true,
    });

    return (
        <div
            ref={buttonRef}
            className={styles.buttonWithMessage}
            onClick={onClick}
            onKeyPress={onClick}
            tabIndex="-1"
            role="button"
        >
            <div className={styles.control}>
                {props.children}
            </div>
            <div className={messageClassName}>
                <div className={styles.popoverContent}>
                    {props.message}
                </div>
                <div
                    className={[
                        props.triangleClassName,
                        styles.triangle,
                    ].join(" ")}
                />
            </div>
        </div>
    );
};

ButtonWithMessage.defaultMessage = {
    message: "",
    messageClassName: "",
    triangleClassName: "",
    pageWidth: 0,
};

export default ButtonWithMessage;
