import {
    applyMiddleware,
    combineReducers,
    createStore,
} from "redux";

import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

import app from "juice-base/store-common/app/reducer.js";
import device from "juice-base/store-common/device/reducer.js";
import monitors from "juice-base/store-common/monitors/reducer.js";
import pages from "juice-base/store-common/pages/reducer.js";
import vocabulary from "juice-base/store-common/vocabulary/reducer.js";
import text2speech from "juice-base/store-common/text2speech/reducer.js";
import player from "juice-base/store-common/player/reducer.js";
import search from "juice-base/store-common/search/reducer.js";

import navigation from "./navigation/reducer.js";

import info from "./info/reducer.js";
import user from "./user/reducer.js";
import lms from "./lms/reducer.js";

import notifications from "./notifications/reducer.js";
import tutorials from "./tutorials/reducer.js";
import templates from "./templates/reducer.js";
import popups from "./popups/reducer.js";

import juices from "./juices/reducer.js";
import juiceStories from "./juice-stories/reducer.js";
import juicesVideos from "./juices-videos/reducer.js";
import extraJuices from "./extra-juices/reducer.js";
import polls from "./polls/reducer.js";

import teacher from "./teacher/reducer.js";
import teacherStudents from "./teacher-students/reducer.js";
import guardian from "./guardian/reducer.js";
import student from "./student/reducer.js";
import studentTodo from "./student-todo/reducer.js";
import studentAssignments from "./student-assignments/reducer.js";

import standards from "./standards/reducer.js";

import assignments from "./assignments/reducer.js";

import geo from "./geo/reducer.js";

import subscription from "./subscription/reducer.js";


const configureStore = () => {
    const rootReducer = combineReducers({
        app,
        device,
        monitors,
        navigation,

        info,
        user,
        lms,

        text2speech,
        player,

        notifications,
        tutorials,
        templates,
        popups,
        pages,
        vocabulary,

        juices,
        juiceStories,
        juicesVideos,
        extraJuices,
        polls,

        teacher,
        teacherStudents,
        guardian,
        student,
        studentTodo,
        studentAssignments,

        search,

        standards,

        assignments,

        geo,

        subscription,
    });

    const middlewares = [thunk];
    const middlewareEnhancer = applyMiddleware(...middlewares);

    const enhancers = [middlewareEnhancer];
    const composedEnhancers = composeWithDevTools(...enhancers);

    const initialState = {};
    return createStore(rootReducer, initialState, composedEnhancers);
};

export default configureStore;
