import React from "react";

import IconWrapper from "juice-base/icons/wrapper/index.js";


const IconCheckInCircle = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    width="27"
                    height="27"
                    viewBox="0 0 27 27"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={iconProps.className}
                >
                    <path
                        fill="currentColor"
                        opacity="0.12"
                        d="M13.3327 2.66699C7.45268 2.66699 2.66602 7.45366 2.66602 13.3337C2.66602 19.2137 7.45268 24.0003 13.3327 24.0003C19.2127 24.0003 23.9993 19.2137 23.9993 13.3337C23.9993 7.45366 19.2127 2.66699 13.3327 2.66699ZM10.666 20.0003L5.33268 14.667L7.21268 12.787L10.666 16.227L19.4527 7.44032L21.3327 9.33366L10.666 20.0003Z"
                    />
                    <path
                        fill="currentColor"
                        d="M13.3333 0C5.97333 0 0 5.97333 0 13.3333C0 20.6933 5.97333 26.6667 13.3333 26.6667C20.6933 26.6667 26.6667 20.6933 26.6667 13.3333C26.6667 5.97333 20.6933 0 13.3333 0ZM13.3333 24C7.45334 24 2.66667 19.2133 2.66667 13.3333C2.66667 7.45333 7.45334 2.66667 13.3333 2.66667C19.2133 2.66667 24 7.45333 24 13.3333C24 19.2133 19.2133 24 13.3333 24ZM19.4533 7.44L10.6667 16.2267L7.21333 12.7867L5.33333 14.6667L10.6667 20L21.3333 9.33333L19.4533 7.44Z"
                    />
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconCheckInCircle;
