import React from "react";
import HorizontalScrollingMenu from "juice-base/components/horizontal-scrolling-menu/index.js";

import date from "juice-base/lib/date.js";

import HeaderBlock from "./header-block.js";
import styles from "./styles.module.css";


const RowHeader = (props) => {
    const renderBlocks = () => {
        return props.dates.map((d, i) => {
            let itemId = `row-${i}`;

            if (d.ids && d.ids.length > 0) {
                itemId = `row-${d.ids.join(",")}`;
            }

            return (
                <HeaderBlock
                    key={itemId}
                    itemId={itemId}
                    date={date.tryFormatDateUTC(d.date, date.formatFullDayShortMonthDate)}
                    quizzes={d.totalQuizzes}
                    withTotalQuizzes={props.withTotalQuizzes}
                />
            );
        });
    };

    /* ------- */

    if (props.dates.length === 0) {
        return null;
    }

    return (
        <div className={styles.studentRowResultsHeaderBlocks}>
            <HorizontalScrollingMenu
                isButtonInCircle
                autoHideArrows={false}
                hideArrows={props.hideArrows}
                onSelect={props.onChange}
                onPositionChange={props.onPositionChange}
                onFirstLastItemsVisibleChange={props.onFirstLastItemsVisibleChange}
            >
                {renderBlocks}
            </HorizontalScrollingMenu>
        </div>
    );
};

RowHeader.defaultProps = {
    dates: [],
    onFirstLastItemsVisibleChange: () => {},
    onPositionChange: () => {},
    onChange: () => {},
    hideArrows: false,
    withTotalQuizzes: false,
};

export default RowHeader;
