import React from "react";

import staticFiles from "juice-base/static-files.js";

import styles from "./styles.module.css";


const UserMainFooter = (props) => {
    const renderLogo = () => {
        return (
            <img
                className={styles.logo}
                src={staticFiles.juiceLogoRegular}
                alt="The Juice"
            />
        );
    };

    const renderCopyright = () => {
        return (
            <div className={styles.copyright}>
                <div>Copyright</div>
                <div>
                    &copy;
                    {`${props.copyright}.`}
                </div>
                <div>All rights reserved.</div>
            </div>
        );
    };

    const renderLinks = () => {
        return (
            <div className={styles.links}>
                <a
                    href={props.privacyPolicyLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    data-comment="privacy-policy"
                >
                    Privacy Policy
                </a>
                <a
                    href={props.supportLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    data-comment="support"
                >
                    Support
                </a>
            </div>
        );
    };

    return (
        <div className={styles.footer}>
            {renderLogo()}
            {renderCopyright()}
            {renderLinks()}
        </div>
    );
};

UserMainFooter.defaultProps = {
    privacyPolicyLink: "",
    copyright: "",
    supportLink: "",
};

export default UserMainFooter;
