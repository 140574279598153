import React from "react";


const Money = (props) => {
    if (!props.value) {
        return null;
    }

    const moneyClassName = [];

    if (props.className) {
        moneyClassName.push(props.className);
    }

    let value = `$${props.value}`;

    if (Number.isNaN(parseFloat(props.value))) {
        value = props.value;
    }

    return (
        <div className={moneyClassName.join(" ")}>
            {value}
        </div>
    );
};

Money.defaultProps = {
    value: "",
    className: "",
};

export default Money;
