import React from "react";

import { Formik } from "formik";

import v from "juice-base/lib/form-validators.js";

import Input from "juice-base/components/forms/input/index.js";
import Select from "juice-base/components/forms/select/index.js";


const StudentAddForm = (props) => {
    const getFirstnameError = (value) => {
        return v.validate(value, [
            v.required("Please enter a name"),
        ]);
    };

    const getLastnameError = (value) => {
        return v.validate(value, [
            v.required("Please enter a lastname"),
        ]);
    };

    const getEmailError = (value) => {
        let emailError = null;

        if (value) {
            emailError = v.validate(value, [
                v.email("Please enter a valid email"),
            ]);
        }

        return emailError;
    };

    const getGradeError = (value) => {
        return v.validate(value, [
            v.required("Please select a reading level"),
        ]);
    };

    const validateForm = (values) => {
        const firstnameError = getFirstnameError(values.firstname);
        const lastnameError = getLastnameError(values.lastname);
        const emailError = getEmailError(values.email);
        const gradeError = getGradeError(values?.grade?.value || "");

        const errors = {};

        if (firstnameError) {
            errors.firstname = firstnameError;
        }

        if (lastnameError) {
            errors.lastname = lastnameError;
        }

        if (emailError) {
            errors.email = emailError;
        }

        if (gradeError) {
            errors.grade = gradeError;
        }

        return errors;
    };

    const renderForm = (formProps) => {
        const {
            values,
            errors,
            setFieldValue,
            handleChange,
            handleBlur,
            setTouched,
        } = formProps;

        return (
            <form className={props.className}>
                <Input
                    id={`firstname-${props.id}`}
                    name="firstname"
                    label="First Name *"
                    value={values.firstname}
                    error={errors.firstname}
                    onChange={(e) => {
                        handleChange(e);
                        props.onChangeFirstname({
                            value: e.target.value,
                            error: getFirstnameError(e.target.value),
                        });
                    }}
                    onBlur={handleBlur}
                />

                <Input
                    id={`lastname-${props.id}`}
                    name="lastname"
                    label="Last Name *"
                    value={values.lastname}
                    error={errors.lastname}
                    onChange={(e) => {
                        handleChange(e);
                        props.onChangeLastname({
                            value: e.target.value,
                            error: getLastnameError(e.target.value),
                        });
                    }}
                    onBlur={handleBlur}
                />

                <Input
                    id={`email-${props.id}`}
                    name="email"
                    label="Email"
                    value={values.email}
                    error={errors.email}
                    onChange={(e) => {
                        handleChange(e);
                        props.onChangeEmail({
                            value: e.target.value,
                            error: getEmailError(e.target.value),
                        });
                    }}
                    onBlur={handleBlur}
                />

                <Select
                    name="grade"
                    label="Reading Level *"
                    selected={values.grade}
                    options={props.grades}
                    error={errors.grade}
                    isEmptyAllowed={false}
                    isOptionsFour
                    onSelect={(val) => {
                        setFieldValue("grade", val);

                        const gradeValue = val?.value || "";

                        props.onChangeGrade({
                            value: gradeValue,
                            error: getGradeError(gradeValue),
                        });
                    }}
                    onBlur={() => {
                        setTouched({ grade: true });
                    }}
                />

                {props.removeButton}
            </form>
        );
    };

    return (
        <Formik
            enableReinitialize
            validateOnMount={props.validateOnMount}
            initialValues={props.initialValues}
            validate={validateForm}
        >
            {renderForm}
        </Formik>
    );
};

StudentAddForm.defaultProps = {
    id: 0,
    className: "",

    validateOnMount: false,

    initialValues: {},
    grades: [],

    removeButton: null,

    onChangeFirstname: () => {},
    onChangeLastname: () => {},
    onChangeEmail: () => {},
    onChangeGrade: () => {},
};

export default StudentAddForm;
