const loadTemplate = (services, params) => {
    const { api, actions } = services;

    return async (dispatch) => {
        dispatch(actions.templates.setTemplateLoadingBySlug({
            slug: params.slug,
        }));

        const res = await api.templates.getTemplate({
            slug: params.slug,
        });

        let template = null;

        if (res.ok) {
            template = res.template;
        }

        dispatch(actions.templates.setTemplateBySlug({
            slug: params.slug,
            template,
        }));
    };
};

export default {
    loadTemplate,
};
