import React from "react";

import classNames from "juice-base/lib/class-names.js";

import Header from "./header.js";
import HeaderWithPages from "./header-with-pages.js";
import Explanation from "./explanation.js";
import Message from "./message.js";
import ContentWithPages from "./content-with-pages.js";

import styles from "./styles.module.css";


const SectionTeacher = (props) => {
    const sectionClassName = classNames({
        [styles.section]: true,
        [props.className]: props.className,
    });

    return (
        <section
            data-comment={props.dataComment}
            className={sectionClassName}
        >
            {props.children}
        </section>
    );
};

SectionTeacher.defaultProps = {
    className: "",
    dataComment: "section-teacher",
    children: null,
};

export const SectionTeacherHeader = Header;
export const SectionTeacherHeaderWithPages = HeaderWithPages;
export const SectionTeacherContentWithPages = ContentWithPages;
export const SectionTeacherExplanation = Explanation;
export const SectionTeacherMessage = Message;

export default SectionTeacher;
