import React, { useState } from "react";

import IconDotsThree from "juice-base/icons/dots-three/index.js";
import IconDownload from "juice-base/icons/download/index.js";
import IconTrashWithLinesFull from "juice-base/icons/trash-with-lines-full/index.js";
import IconUserAdd from "juice-base/icons/user-add/index.js";

import MenuWithButton, {
    MenuOption,
} from "juice-base/components/menu-with-button/index.js";


const TableMenuButton = (props) => {
    const [isVisibleMenu, setIsVisibleMenu] = useState(false);

    const onClick = () => {
        setIsVisibleMenu((prev) => !prev);
    };

    const onCloseMenu = () => {
        setIsVisibleMenu(false);
    };

    /* ---- */

    const onAddStudent = () => {
        props.onAddStudent();
        onCloseMenu();
    };

    const onRemoveSelectedUsers = () => {
        props.onRemoveSelectedUsers();
        onCloseMenu();
    };

    const onDownloadClassStudents = () => {
        props.onDownloadClassStudents();
        onCloseMenu();
    };

    /* ---- */

    return (
        <MenuWithButton
            isVisibleMenu={isVisibleMenu}
            icon={<IconDotsThree />}
            onClick={onClick}
            onCloseMenu={onCloseMenu}
        >
            <MenuOption
                name="Download Report"
                icon={<IconDownload title="Download" isSky />}
                disabled={props.isDisabledDownload}
                onClick={onDownloadClassStudents}
            />
            <MenuOption
                name="Add Student"
                icon={<IconUserAdd title="Add student" isSky />}
                onClick={onAddStudent}
            />
            <MenuOption
                name="Delete students"
                icon={<IconTrashWithLinesFull title="Delete selected Students" isSky />}
                disabled={props.isDisabledRemove}
                onClick={onRemoveSelectedUsers}
            />
        </MenuWithButton>
    );
};

TableMenuButton.defaultProps = {
    isDisabledDownload: false,
    isDisabledRemove: false,
    onDownloadClassStudents: () => { },
    onAddStudent: () => { },
    onRemoveSelectedUsers: () => { },
};

export default TableMenuButton;
