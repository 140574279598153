import React from "react";

import styles from "./styles.module.css";


const ContentWithPages = (props) => {
    const renderPages = () => {
        const pages = props.pages.map((page) => {
            return (
                <div className={styles.contentWithPagePage}>
                    {page}
                </div>
            );
        });

        return pages;
    };

    return (
        <div className={styles.contentWithPageContainer}>
            <div
                className={styles.contentWithPage}
                style={{
                    transform: `translateX(${(props.currentPage * 100) * -1}%)`,
                }}
            >
                {renderPages()}
            </div>
        </div>
    );
};

ContentWithPages.defaultProps = {
    currentPage: 0,
    pages: [],
};

export default ContentWithPages;
