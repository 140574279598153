import React from "react";

import useTitle from "juice-base/hooks/use-title/index.js";

import { withAuth } from "juice-base/components/auth/index.js";

import AssignmentStory from "juice-app/containers/assignment-story/index.js";


const ShowAssignmentStory = () => {
    useTitle(() => "Assignment story", []);

    return (
        <AssignmentStory />
    );
};

export default withAuth(["student", "teacher"])(ShowAssignmentStory);
