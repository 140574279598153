import React from "react";

import PopupWindow from "juice-base/components/popup-window/index.js";
import DevPlaceholder from "juice-base/components/dev-placeholder/index.js";


const PopupSubscriptionCancel = (props) => {
    const renderContent = () => {
        return (
            <DevPlaceholder />
        );
    };

    return (
        <PopupWindow
            title="Cancel Subscription"
            onClose={props.onClose}
        >
            {renderContent()}
        </PopupWindow>
    );
};

PopupSubscriptionCancel.defaultProps = {
    messages: [],
    onClose: () => {},
};

export default PopupSubscriptionCancel;
