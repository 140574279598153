import { useState } from "react";


const getState = () => ({
    isVisible: false,
    classes: [],
    onCancel: () => { },
});

const usePopupConfirmLMSClassRemoval = () => {
    const [state, setState] = useState(() => getState());

    const open = (params = {}) => {
        setState({
            isVisible: true,
            classes: params.classes,
            onCancel: params.onCancel,
        });
    };

    const close = () => {
        setState(getState());
    };

    const cancel = () => {
        if (state.onCancel) {
            state.onCancel();
        }
    };

    return {
        state,
        open,
        close,
        cancel,
    };
};

export default usePopupConfirmLMSClassRemoval;
