import React from "react";

import urls from "juice-base/lib/urls.js";

import MessageDanger from "juice-base/components/message-danger/index.js";
import ButtonBig from "juice-base/components/button-big/index.js";

import styles from "./styles.module.css";


const MessageReferralCode = (props) => {
    const onContactSupport = () => {
        urls.openUrl(props.supportLink);
    };

    return (
        <div className={styles.section}>
            <div className={styles.messageDanger}>
                <MessageDanger>
                    {props.children}
                </MessageDanger>
            </div>
            <div className={styles.message}>
                We&apos;re sorry, but there is something wrong with the referral code.
                Please contact support.
            </div>
            <div className={styles.controls}>
                <ButtonBig
                    type="button"
                    onClick={onContactSupport}
                >
                    Contact support
                </ButtonBig>
            </div>
        </div>
    );
};

MessageReferralCode.defaultProps = {
    children: null,
    supportLink: "",
};

export default MessageReferralCode;
