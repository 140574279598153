// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kK4RlDx4lQxQLwv0no7b {\n    display: grid;\n}\n\n.g6Z_hZOZf2N76FrD7uvC {\n    margin: 2rem;\n}\n\n.XTsVPK9FURNiLUpiM5zU {\n    margin: 0 auto 4rem auto;\n    text-align: center;\n}\n\n.Qc7kprmpPwlSKTdH5Ip3 {\n    margin: 1rem 0 0 0;\n\n    color: var(--color-1);\n    font-weight: 600;\n    font-size: 2.3rem;\n    letter-spacing: 0.05rem;\n}\n\n.kYnJpneay9LicC6zbDf7 {\n    margin: 5rem 0 0 0;\n    display: grid;\n}\n\n@media only screen and (min-width: 521px) {\n    .kK4RlDx4lQxQLwv0no7b {\n        grid-template-columns: 50rem;\n        justify-content: center;\n    }\n}\n", "",{"version":3,"sources":["webpack://./app/views/user/password-forgot/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,wBAAwB;IACxB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;;IAElB,qBAAqB;IACrB,gBAAgB;IAChB,iBAAiB;IACjB,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI;QACI,4BAA4B;QAC5B,uBAAuB;IAC3B;AACJ","sourcesContent":[".content {\n    display: grid;\n}\n\n.message {\n    margin: 2rem;\n}\n\n.contentHeader {\n    margin: 0 auto 4rem auto;\n    text-align: center;\n}\n\n.formName {\n    margin: 1rem 0 0 0;\n\n    color: var(--color-1);\n    font-weight: 600;\n    font-size: 2.3rem;\n    letter-spacing: 0.05rem;\n}\n\n.backButton {\n    margin: 5rem 0 0 0;\n    display: grid;\n}\n\n@media only screen and (min-width: 521px) {\n    .content {\n        grid-template-columns: 50rem;\n        justify-content: center;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "kK4RlDx4lQxQLwv0no7b",
	"message": "g6Z_hZOZf2N76FrD7uvC",
	"contentHeader": "XTsVPK9FURNiLUpiM5zU",
	"formName": "Qc7kprmpPwlSKTdH5Ip3",
	"backButton": "kYnJpneay9LicC6zbDf7"
};
export default ___CSS_LOADER_EXPORT___;
