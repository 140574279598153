import React, { useState } from "react";

import IconDotsThree from "juice-base/icons/dots-three/index.js";

import MenuWithButton, {
    MenuOption,
} from "juice-base/components/menu-with-button/index.js";


const TableRowMenuButton = (props) => {
    const [isVisibleMenu, setIsVisibleMenu] = useState(false);

    const onClick = () => {
        setIsVisibleMenu((prev) => !prev);
    };

    const onCloseMenu = () => {
        setIsVisibleMenu(false);
    };

    return (
        <MenuWithButton
            icon={<IconDotsThree />}
            onClick={onClick}
            onCloseMenu={onCloseMenu}
            isVisibleMenu={isVisibleMenu}
        >
            <MenuOption
                name="Reset Password"
                onClick={props.onResetStudentPassword}
            />
            <MenuOption
                name="Remove Student..."
                onClick={props.onRemoveStudent}
            />
        </MenuWithButton>
    );
};

TableRowMenuButton.defaultProps = {
    onResetStudentPassword: () => { },
    onRemoveStudent: () => { },
};

export default TableRowMenuButton;
