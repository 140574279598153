import { validate, required, email } from "juice-base/lib/form-validators.js";

const StudentsSortByValues = {
    nameATOZ: "name: a to z",
    nameZTOA: "name: z to a",
    quizStatus: "quiz status",
    scoreHighToLow: "score: high to low",
    scoreLowToHigh: "score: low to high",
};

const sortStudentsByNameATOZ = (data) => {
    return [...data].sort((a, b) => a.lastName.localeCompare(b.lastName));
};

const sortStudentsByNameZTOA = (data) => {
    return [...data].sort((a, b) => b.lastName.localeCompare(a.lastName));
};

const sortStudents = (sortBy, data) => {
    if (sortBy === StudentsSortByValues.nameATOZ) {
        return sortStudentsByNameATOZ(data);
    }

    if (sortBy === StudentsSortByValues.nameZTOA) {
        return sortStudentsByNameZTOA(data);
    }

    return data;
};

const getSortValueBySort = (sortValue) => {
    if (!sortValue) {
        return StudentsSortByValues.nameATOZ;
    }

    if (sortValue === StudentsSortByValues.nameATOZ) {
        return StudentsSortByValues.nameZTOA;
    }

    if (sortValue === StudentsSortByValues.nameZTOA) {
        return StudentsSortByValues.nameATOZ;
    }

    return StudentsSortByValues.nameATOZ;
};

export const getDefaultSort = () => {
    return StudentsSortByValues.nameATOZ;
};

export const getSortValues = () => {
    return [
        { value: StudentsSortByValues.nameATOZ, label: "Name: A to Z" },
        { value: StudentsSortByValues.nameZTOA, label: "Name: Z to A" },
        { value: StudentsSortByValues.quizStatus, label: "Quiz Status" },
        { value: StudentsSortByValues.scoreHighToLow, label: "Score: High to Low" },
        { value: StudentsSortByValues.scoreLowToHigh, label: "Score: Low to High" },
    ];
};

export const findSortKeyByValue = (value) => {
    if (!value) {
        return "";
    }

    let sortKey = "";

    Object.keys(StudentsSortByValues).forEach((key) => {
        if (StudentsSortByValues[key] === value) {
            sortKey = key;
        }
    });

    return sortKey;
};

const sortByQuizScore = (a, b, isSortByLowToHigh) => {
    if (isSortByLowToHigh) {
        if (a.juiceStatusId === -1) {
            return -1;
        }

        if (b.juiceStatusId === -1) {
            return 1;
        }

        if (a.quizScore < b.quizScore) {
            return -1;
        }

        if (a.quizScore > b.quizScore) {
            return 1;
        }
    } else {
        if (a.juiceStatusId === -1) {
            return 1;
        }

        if (b.juiceStatusId === -1) {
            return -1;
        }

        if (a.quizScore < b.quizScore) {
            return 1;
        }

        if (a.quizScore > b.quizScore) {
            return -1;
        }
    }

    return 0;
};

export const sortBy = (sortByValue, students, isAverageScore) => {
    const sortedStudents = [
        ...students,
    ];

    if (sortByValue === StudentsSortByValues.nameATOZ) {
        sortedStudents.sort((a, b) => a.lastName.localeCompare(b.lastName));
        return sortedStudents;
    }

    if (sortByValue === StudentsSortByValues.nameZTOA) {
        sortedStudents.sort((a, b) => b.lastName.localeCompare(a.lastName));
        return sortedStudents;
    }

    if (sortByValue === StudentsSortByValues.quizStatus) {
        const unopenedUsers = sortedStudents
            .filter((user) => user.juiceStatusId === 0);

        const inProgressUsers = sortedStudents
            .filter((user) => user.juiceStatusId === 1);

        const completedUsers = sortedStudents
            .filter((user) => user.juiceStatusId === 2);

        const missingUsers = sortedStudents
            .filter((user) => user.completionStatusId === 6);

        const allCompletedUsers = sortedStudents
            .filter((user) => user.completionStatusId === 7);

        const newStudents = [
            ...completedUsers,
            ...allCompletedUsers,
            ...inProgressUsers,
            ...unopenedUsers,
            ...missingUsers,
        ];

        if (newStudents.length !== 0) {
            return newStudents;
        }

        return sortedStudents;
    }

    if (sortByValue === StudentsSortByValues.scoreHighToLow) {
        if (isAverageScore) {
            sortedStudents.sort((a, b) => b.averageQuizScore - a.averageQuizScore);
        } else {
            sortedStudents.sort((a, b) => {
                return sortByQuizScore(a, b, false);
            });
        }

        return sortedStudents;
    }

    if (sortByValue === StudentsSortByValues.scoreLowToHigh) {
        if (isAverageScore) {
            sortedStudents.sort((a, b) => a.averageQuizScore - b.averageQuizScore);
        } else {
            sortedStudents.sort((a, b) => {
                return sortByQuizScore(a, b, true);
            });
        }

        return sortedStudents;
    }

    return sortedStudents;
};

/* --- */

export const isStatusActive = (student) => {
    return student && student.userStatusId === 1;
};

/* --- */

export const validateName = (value) => {
    return validate(value, [
        required("Please enter a name"),
    ]);
};

export const validateLastName = (value) => {
    return validate(value, [
        required("Please enter a last name"),
    ]);
};

export const validateEmail = (value) => {
    return validate(value, [
        email("Sorry, that email address isn't valid. Please enter a valid one and try again."),
    ]);
};


export default {
    StudentsSortByValues,
    sortStudents,
    getSortValueBySort,
    getDefaultSort,

    getSortValues,
    sortByQuizScore,
    sortBy,
    isStatusActive,
    findSortKeyByValue,
    validateName,
    validateLastName,
    validateEmail,
};
