// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".lVnQKpvEb7AFcImeLkbJ {\n    width: 5rem;\n    height: 5rem;\n\n    padding: 1rem;\n\n    border-radius: 100%;\n\n    background-color: var(--yellow);\n\n    box-shadow: 0rem 0.1rem 0.3rem 0rem var(--grey);\n}\n", "",{"version":3,"sources":["webpack://./base/components/logo-round/styles.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;;IAEZ,aAAa;;IAEb,mBAAmB;;IAEnB,+BAA+B;;IAE/B,+CAA+C;AACnD","sourcesContent":[".logoImg {\n    width: 5rem;\n    height: 5rem;\n\n    padding: 1rem;\n\n    border-radius: 100%;\n\n    background-color: var(--yellow);\n\n    box-shadow: 0rem 0.1rem 0.3rem 0rem var(--grey);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logoImg": "lVnQKpvEb7AFcImeLkbJ"
};
export default ___CSS_LOADER_EXPORT___;
