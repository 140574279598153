// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CcAL4GB7U2xuu6vRcI85 {\n    background-color: var(--background-color);\n}\n\n/* --- */\n\n.l_Dde0lhB8GSjQIqz76W {\n    padding: 2rem 2rem 1rem 2rem;\n}\n\n.P9unACCCaSQMv9DxClpg {\n    padding: 0 2rem 2rem 2rem;\n}\n\n.If5RYaK7H8qG0sj4EuCA {\n    padding: 0 2rem 2rem 2rem;\n}\n\n.rgo2f0rdc36FC0FGczdO {\n    padding: 0 2rem 2rem 2rem;\n}\n\n@media only screen and (min-width: 620px) {\n    .l_Dde0lhB8GSjQIqz76W,\n    .P9unACCCaSQMv9DxClpg,\n    .If5RYaK7H8qG0sj4EuCA,\n    .rgo2f0rdc36FC0FGczdO {\n        padding-left: 0;\n        padding-right: 0;\n    }\n}\n", "",{"version":3,"sources":["webpack://./base/business/extra-juice/styles.module.css"],"names":[],"mappings":"AAAA;IACI,yCAAyC;AAC7C;;AAEA,QAAQ;;AAER;IACI,4BAA4B;AAChC;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI;;;;QAII,eAAe;QACf,gBAAgB;IACpB;AACJ","sourcesContent":[".extraJuice {\n    background-color: var(--background-color);\n}\n\n/* --- */\n\n.category {\n    padding: 2rem 2rem 1rem 2rem;\n}\n\n.headline {\n    padding: 0 2rem 2rem 2rem;\n}\n\n.playerAudioToggleButton {\n    padding: 0 2rem 2rem 2rem;\n}\n\n.content {\n    padding: 0 2rem 2rem 2rem;\n}\n\n@media only screen and (min-width: 620px) {\n    .category,\n    .headline,\n    .playerAudioToggleButton,\n    .content {\n        padding-left: 0;\n        padding-right: 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"extraJuice": "CcAL4GB7U2xuu6vRcI85",
	"category": "l_Dde0lhB8GSjQIqz76W",
	"headline": "P9unACCCaSQMv9DxClpg",
	"playerAudioToggleButton": "If5RYaK7H8qG0sj4EuCA",
	"content": "rgo2f0rdc36FC0FGczdO"
};
export default ___CSS_LOADER_EXPORT___;
