import React from "react";

import HorizontalScrollingMenu, { Chip } from "juice-base/components/horizontal-scrolling-menu/index.js";


const MenuHorizontalScrolling = (props) => {
    const renderChips = ({ dragging, handleItemClick }) => {
        return props.items.map((menuItem) => {
            // NOTE: itemId is required for react-horizontal-scrolling-menu
            //       to track items(string format)
            const itemId = `chip-item-${menuItem.value}`;

            const isSelected = props.selected === menuItem.value;

            return (
                <Chip
                    isRoseTheme={props.isRoseTheme}
                    selected={isSelected}
                    key={itemId}
                    itemId={itemId}
                    label={menuItem.label}
                    withCursorPointer={!dragging}
                    onClick={handleItemClick({
                        value: menuItem.value,
                        itemId,
                    })}
                />
            );
        });
    };

    // NOTE: The "selected" prop must be the same as
    //       the Tab "key" prop(to scroll to selected tab onInit)
    return (
        <HorizontalScrollingMenu
            dataComment={props.dataComment}
            selected={`chip-item-${props.selected}`}
            onSelect={props.onSelect}
            hideArrows={props.hideArrows}
        >
            {renderChips}
        </HorizontalScrollingMenu>
    );
};

MenuHorizontalScrolling.defaultProps = {
    dataComment: "menu-horizontal-scrolling",
    selected: null,
    items: [],
    hideArrows: true,
    isRoseTheme: false,
    onSelect: () => { },
};

export default MenuHorizontalScrolling;
