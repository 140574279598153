import React from "react";

import classNames from "juice-base/lib/class-names.js";

import IconCheck from "juice-base/icons/check/index.js";

import styles from "./styles.module.css";


const Option = (props) => {
    if (!props.option) {
        return null;
    }

    const onSelect = (evt) => {
        props.onSelect(evt, props.option);
    };

    const renderIcon = () => {
        if (!props.isSelected) {
            return null;
        }

        if (props.orangeTheme) {
            return (
                <IconCheck
                    isOrange
                />
            );
        }

        return (
            <IconCheck
                isRed
            />
        );
    };

    const renderOptionIcon = () => {
        if (!props.option.optionIcon) {
            return null;
        }

        return props.option.optionIcon;
    };

    const optClassName = classNames({
        [styles.option]: true,
        [styles.optionSmall]: props.small,
        [styles.optionWithOptionIcon]: props.option.optionIcon,
        [styles.optionWithIcon]: props.withIcon && !props.option.optionIcon,
        [styles.optionOrange]: props.orangeTheme,
        [styles.optionAlreadySelected]: props.isSelected,
        [styles.optionAlreadySelectedOrange]: props.isSelected && props.orangeTheme,
    });

    return (
        <div
            className={optClassName}
            onClick={onSelect}
            onKeyPress={onSelect}
            tabIndex="-1"
            role="button"
        >
            {renderOptionIcon()}
            {props.option.label}
            {renderIcon()}
        </div>
    );
};

Option.defaultProps = {
    option: null,
    isSelected: false,
    withIcon: false,
    orangeTheme: false,
    small: false,
    onSelect: () => { },
};

export default Option;
