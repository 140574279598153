import React from "react";

import IconDashboard from "juice-base/icons/dashboard/index.js";
import IconGraph from "juice-base/icons/graph/index.js";
import IconHome from "juice-base/icons/home/index.js";
import IconJuiceCup from "juice-base/icons/juice-cup/index.js";
import IconClipboard from "juice-base/icons/clipboard/index.js";
import IconSearch from "juice-base/icons/search/index.js";
import IconUsers from "juice-base/icons/users/index.js";

import IconNotepadCheck from "juice-base/icons/notepad-check/index.js";
import IconNotepadExclamation from "juice-base/icons/notepad-exclamation/index.js";
import IconNotepadStop from "juice-base/icons/notepad-stop/index.js";
import IconNotepadX from "juice-base/icons/notepad-x/index.js";

import IconPlayInCircle from "juice-base/icons/play-in-circle/index.js";
import IconPlayInDottedCircle from "juice-base/icons/play-in-dotted-circle/index.js";

import IconPollAnswered from "juice-base/icons/poll-answered/index.js";
import IconPollUnanswered from "juice-base/icons/poll-unanswered/index.js";


const ICONS_BY_NAME = {
    dashboard: IconDashboard,
    graph: IconGraph,
    home: IconHome,
    juiceCup: IconJuiceCup,
    clipboard: IconClipboard,
    users: IconUsers,
    search: IconSearch,
};

const STORY_LEGEND_ICONS = {
    notepadCheck: {
        icon: IconNotepadCheck,
        props: {
            isGreen: true,
        },
    },

    notepadExclamation: {
        icon: IconNotepadExclamation,
        props: {
            isSky: true,
        },
    },

    notepadStop: {
        icon: IconNotepadStop,
        props: {
            isRed: true,
        },
    },

    notepadX: {
        icon: IconNotepadX,
        props: {
            isGreen: true,
        },
    },

    playInCircle: {
        icon: IconPlayInCircle,
        props: {
            isGreen: true,
        },
    },

    playInDottedCircle: {
        icon: IconPlayInDottedCircle,
        props: {
            isSky: true,
        },
    },

    pollAnswered: {
        icon: IconPollAnswered,
        props: {
            isSky: true,
        },
    },

    pollUnanswered: {
        icon: IconPollUnanswered,
        props: {
            isSky: true,
        },
    },
};

const getIconByName = (name) => {
    return ICONS_BY_NAME[name] || null;
};

const getStoryLegendIconByName = (name, additionalProps = {}) => {
    const iconData = STORY_LEGEND_ICONS[name] || null;

    if (!iconData) {
        return null;
    }

    const props = iconData.props || {};

    const componentProps = {
        ...props,
        ...additionalProps,
    };

    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <iconData.icon {...componentProps} />
    );
};

export {
    getIconByName,
    getStoryLegendIconByName,
};

export default {};
