import React from "react";

import { Formik } from "formik";

import Schools from "juice-base/project/schools.js";

import Select from "juice-base/components/forms/select/index.js";
import ButtonBig from "juice-base/components/button-big/index.js";
import ButtonToggler from "juice-base/components/button-toggler/index.js";

import styles from "./styles.module.css";


const TeacherSchoolDetailsForm = (props) => {
    const onChangeSchoolType = (type) => {
        props.onChangeSchoolType(type);
    };

    /* ------ */

    const isDisabledNextButton = (values) => {
        const {
            country,
            subdivision,
            schoolDistrict,
            schoolCity,
            schoolName,
        } = values;

        return !country
            || !subdivision
            || (!schoolDistrict && !schoolCity)
            || !schoolName;
    };

    /* ------ */

    const renderSchoolDistrictOrCityField = (values, setFieldValue) => {
        const isDisabled = !values.subdivision || props.isHomeSchool || props.isFamily;

        if (Schools.isPublicSchoolType(props.selectedSchoolType)) {
            return (
                <Select
                    name="schoolDistrict"
                    label="School District *"
                    selected={values.schoolDistrict}
                    options={props.districtsOptions}
                    onSelect={(val) => {
                        setFieldValue("schoolDistrict", val);
                        setFieldValue("schoolName", "");
                        props.onSelectDistrict(val);
                    }}
                    isDisabled={isDisabled}
                    isDisabledTabIndex={props.isDisabledTabIndex}
                    isSearchable
                    isCreatable
                />
            );
        }

        return (
            <Select
                name="schoolCity"
                label="School City *"
                selected={values.schoolCity}
                options={props.citiesOptions}
                onSelect={(val) => {
                    setFieldValue("schoolCity", val);
                    setFieldValue("schoolName", "");
                    props.onSelectCity(val);
                }}
                isDisabledTabIndex={props.isDisabledTabIndex}
                isDisabled={isDisabled}
                isSearchable
                isCreatable
            />
        );
    };

    const renderSchoolDetailsFields = (values, setFieldValue) => {
        const { schoolDistrict, schoolCity, subdivision } = values;

        const buttons = Schools.getSchoolTypes();

        const isDisabledSchoolName = (!schoolDistrict && !schoolCity)
            || props.isHomeSchool
            || props.isFamily;

        return (
            <div>
                <div className={styles.schoolTypesToggler}>
                    <ButtonToggler
                        buttons={buttons}
                        selected={props.selectedSchoolType}
                        onSelect={(val) => {
                            onChangeSchoolType(val);
                            setFieldValue("schoolName", "");
                            setFieldValue("schoolDistrict", "");
                            setFieldValue("schoolCity", "");
                        }}
                        disabled={!subdivision || props.isHomeSchool || props.isFamily}
                        uppercase={false}
                    />
                </div>

                {renderSchoolDistrictOrCityField(values, setFieldValue)}

                <Select
                    name="schoolName"
                    label="School Name *"
                    selected={values.schoolName}
                    options={props.schoolNamesOptions}
                    onSelect={(val) => {
                        setFieldValue("schoolName", val);
                        props.onSelectSchoolName(val);
                    }}
                    isDisabled={isDisabledSchoolName}
                    isDisabledTabIndex={props.isDisabledTabIndex}
                    isSearchable
                    isCreatable
                />
            </div>
        );
    };

    const renderForm = (formProps) => {
        const {
            values,
            handleSubmit,
            setFieldValue,
        } = formProps;

        const isSubdivCreatable = values?.country?.value === "Other";

        return (
            <form onSubmit={handleSubmit} className={styles.form}>
                <div className={styles.fields}>
                    <Select
                        name="country"
                        label="Country *"
                        selected={values.country}
                        options={props.countries}
                        isDisabledTabIndex={props.isDisabledTabIndex}
                        onSelect={(val) => {
                            setFieldValue("country", val);
                            setFieldValue("subdivision", "");

                            if (!props.isHomeSchool && !props.isFamily) {
                                setFieldValue("schoolDistrict", "");
                                setFieldValue("schoolCity", "");
                                setFieldValue("schoolName", "");
                            }

                            props.onCountryChange(val?.value || "");
                        }}
                    />
                    <Select
                        name="subdivision"
                        label={`${props.subdivisionName} *`}
                        selected={values.subdivision}
                        options={props.subdivisions}
                        isDisabled={!values.country || props.isDisabledSubdivision}
                        isDisabledTabIndex={props.isDisabledTabIndex}
                        isSearchable
                        isCreatable={isSubdivCreatable}
                        onSelect={(val) => {
                            setFieldValue("subdivision", val);

                            if (!props.isHomeSchool && !props.isFamily) {
                                setFieldValue("schoolDistrict", "");
                                setFieldValue("schoolCity", "");
                                setFieldValue("schoolName", "");
                            }

                            props.onSubdivisionsChange(val?.value || "");
                        }}
                    />
                    {renderSchoolDetailsFields(values, setFieldValue)}
                </div>

                <div className={styles.submitButton}>
                    <ButtonBig
                        disabled={isDisabledNextButton(values)}
                        isDisabledTabIndex={props.isDisabledTabIndex}
                        type="submit"
                    >
                        Next
                    </ButtonBig>
                </div>
            </form>
        );
    };

    return (
        <Formik
            initialValues={props.initialValues}
            onSubmit={props.onSubmit}
        >
            {renderForm}
        </Formik>
    );
};

TeacherSchoolDetailsForm.defaultProps = {
    initialValues: {},

    subdivisionName: "State",
    selectedSchoolType: "",

    districtsOptions: [],
    schoolNamesOptions: [],
    countries: [],
    subdivisions: [],
    citiesOptions: [],

    onCountryChange: () => { },
    onSubdivisionsChange: () => { },
    onSelectDistrict: () => { },
    onSelectCity: () => { },
    onSelectSchoolName: () => { },
    onChangeSchoolType: () => { },
    onSubmit: () => { },

    isHomeSchool: false,
    isFamily: false,
    isDisabledSubdivision: false,
    isDisabledTabIndex: false,
};

export default TeacherSchoolDetailsForm;
