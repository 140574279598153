import React, { useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import text from "juice-base/text/index.js";

import storage from "juice-base/lib/storage/index.js";
import actions from "juice-base/store/actions.js";
import userActions from "juice-base/actions/user.js";

import useTitle from "juice-base/hooks/use-title/index.js";
import useReferralCode from "juice-base/hooks/use-referral-code/index.js";

import LayoutContent from "juice-base/components/layout-content/index.js";
import LogoRound from "juice-base/components/logo-round/index.js";
import Footer from "juice-base/components/footer/index.js";
import RequestLoader from "juice-base/components/request-loader/index.js";

import UserSignUpTrialCreateAccount from "juice-base/forms/user-sign-up-trial-create-account/index.js";
import MessageReferralCode from "juice-base/business/message-referral-code/index.js";
import MessageAlreadyAuthorized from "juice-base/business/message-already-authorized/index.js";

import settings from "juice-app/settings.js";
import api from "juice-app/api.js";

import styles from "./styles.module.css";


const storeSelector = (state) => ({
    isUserLoading: state.user.isUserLoading,
    userId: state.user.user.userId,
});

const UserSignUpTrial = () => {
    const navigate = useNavigate();
    const params = useParams();
    const referralCode = useReferralCode();

    const dispatch = useDispatch();
    const store = useSelector(storeSelector);

    /* --- */

    useEffect(() => {
        if (!params?.referralCode) {
            referralCode.setCodeMissing();
            return;
        }

        api.signup.validateReferralCode({
            code: params.referralCode,
        }).then((res) => {
            let isActive = false;
            let error = null;

            if (res.ok) {
                isActive = res.data.isActive;
            } else {
                error = res.error || text.error;
            }

            referralCode.setLoaded(isActive, error);
        });
    }, []);

    useTitle(() => "Create an account", []);

    /* --- */

    const onCreateAccount = async (values, { setSubmitting, setErrors }) => {
        const res = await api.signup.signUpByReferralCode({
            ...values,
            code: params.referralCode,
            role: params.role || "teacher",
        });

        if (!res.ok) {
            setErrors({
                formError: res.error || text.error,
            });
            setSubmitting(false);
            return;
        }

        if (values.isRememberMe) {
            storage.local.saveSession(res.sessionId ?? "");
            storage.session.saveSession("");
        } else {
            storage.local.saveSession("");
            storage.session.saveSession(res.sessionId ?? "");
        }

        dispatch(actions.user.setUserSession(res.sessionId || ""));
        dispatch(actions.user.setUser(res.user));

        const userId = res?.user?.userId || "";

        if (userId) {
            dispatch(userActions.saveUserId({ storage }, {
                userId,
            }));
        }

        navigate("/");
    };

    const renderTextBeforeForm = () => {
        let textByRole = "";

        if (params.role === "teacher") {
            textByRole = text.trialCreateAccount1;
        }

        if (params.role === "administrator") {
            textByRole = text.trialCreateAccount2;
        }

        if (params.role === "homeschooler") {
            textByRole = text.trialCreateAccount3;
        }

        if (textByRole) {
            return (
                <div className={styles.textBeforeForm}>
                    {textByRole}
                </div>
            );
        }

        return null;
    };

    const renderTextAfterForm = () => {
        let textByRole = "";

        if (params.role === "administrator") {
            textByRole = text.trialCreateAccount4;
        }

        if (params.role === "homeschooler") {
            textByRole = text.trialCreateAccount5;
        }

        if (textByRole) {
            return (
                <div className={styles.textAfterForm}>
                    {textByRole}
                </div>
            );
        }

        return null;
    };

    const renderForm = () => {
        return (
            <UserSignUpTrialCreateAccount
                passwordMinLength={settings.password.minLength}
                onSubmit={onCreateAccount}
            />
        );
    };

    const renderContent = () => {
        if (store.isUserLoading) {
            return null;
        }

        if (store.userId) {
            return (
                <MessageAlreadyAuthorized
                    onClick={() => {
                        navigate("/");
                    }}
                />
            );
        }

        const supportLink = [
            settings.landingSite.domain,
            settings.landingSite.routeSupport,
        ].join("");

        if (!params?.referralCode) {
            return (
                <MessageReferralCode
                    supportLink={supportLink}
                >
                    Referral code is missing.
                </MessageReferralCode>
            );
        }

        if (!referralCode.state.isLoaded) {
            return (
                <div className={styles.content}>
                    <RequestLoader />
                </div>
            );
        }

        if (referralCode.state.error) {
            return (
                <MessageReferralCode
                    supportLink={supportLink}
                >
                    {referralCode.state.error}
                </MessageReferralCode>
            );
        }

        if (!referralCode.state.isActive) {
            return (
                <MessageReferralCode
                    supportLink={supportLink}
                >
                    {`Referral code ${params.referralCode} is not active!`}
                </MessageReferralCode>
            );
        }

        return (
            <div className={styles.content}>
                <div className={styles.contentHeader}>
                    <LogoRound />

                    <div className={styles.formName}>
                        Create an account
                    </div>
                </div>

                {renderTextBeforeForm()}

                {renderForm()}

                {renderTextAfterForm()}

                <div className={styles.links}>
                    <Link to="/" className={styles.link}>
                        Already have an account? Sign in
                    </Link>
                </div>

                <div className={styles.footer}>
                    <Footer
                        copyright={settings.copyright}
                    />
                </div>
            </div>
        );
    };

    return (
        <LayoutContent>
            {renderContent()}
        </LayoutContent>
    );
};

export default UserSignUpTrial;
