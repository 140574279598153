import React from "react";

import IconAnnouncement from "juice-base/icons/announcement/index.js";

import Text from "juice-base/components/text/index.js";
import SectionStudentV2 from "juice-base/business/section-student-v2/index.js";

import classAnnouncementStyles from "juice-base/components/class-announcement/styles.module.css";
import styles from "./styles.module.css";


const SectionAnnouncement = (props) => {
    return (
        <SectionStudentV2
            title={props.title}
            icon={<IconAnnouncement isOrange />}
        >
            <div className={styles.content}>
                <Text className={classAnnouncementStyles.content}>
                    {props.content}
                </Text>
            </div>
        </SectionStudentV2>
    );
};

SectionAnnouncement.defaultProps = {
    title: "Class announcement",
    content: null,
};

export default SectionAnnouncement;
