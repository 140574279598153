import * as types from "./types.js";


const initialState = {
    isStandardsLoaded: false,
    standards: {},

    isCategoriesLoaded: false,
    categories: {},

    searchEmpty: {
        isLoading: false,
        data: {},
    },
    searchByKeyword: {},
};

export default (state, action) => {
    switch (action.type) {
        case types.SET_SEARCH_STANDARDS: {
            return {
                ...state,
                isStandardsLoaded: true,
                standards: action.payload.standards || [],
            };
        }

        /* --- */

        case types.SET_SEARCH_CATEGORIES: {
            return {
                ...state,
                isCategoriesLoaded: true,
                categories: action.payload.categories || {},
            };
        }

        /* --- */

        case types.SET_SEARCH_EMPTY_LOADING: {
            return {
                ...state,
                searchEmpty: {
                    ...state.searchEmpty.data,
                    isLoading: true,
                    data: {},
                },
            };
        }

        case types.SET_SEARCH_EMPTY_DATA: {
            const { data } = action.payload;

            return {
                ...state,
                searchEmpty: {
                    ...state.searchEmpty.data,
                    isLoading: false,
                    data,
                },
            };
        }

        /* --- */

        case types.SET_SEARCH_BY_KEYWORD_LOADING: {
            const { keyword } = action.payload;

            const { searchByKeyword } = state;

            return {
                ...state,
                searchByKeyword: {
                    ...searchByKeyword,
                    [keyword]: {
                        isLoading: true,
                        data: {},
                    },
                },
            };
        }

        case types.SET_SEARCH_BY_KEYWORD: {
            const { keyword, data } = action.payload;

            const { searchByKeyword } = state;

            return {
                ...state,
                searchByKeyword: {
                    ...searchByKeyword,
                    [keyword]: {
                        ...searchByKeyword[keyword],
                        isLoading: false,
                        data,
                    },
                },
            };
        }

        default: {
            return state || { ...initialState };
        }
    }
};
