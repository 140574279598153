// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".n8Jy6b4nQ50gbBhUNeXB {\n    display: grid;\n}\n\n.qJq4zfz9LIzbvxi8s8_w {\n    margin: 0 auto;\n    width: 10rem;\n    height: auto;\n}\n\n.gbPr5xX9A_yzgsyBosHZ {\n    margin: 2rem 0;\n\n    color: var(--color-1);\n    font-family: \"Soleil-Semibold\";\n    font-size: 2rem;\n    text-align: center;\n}\n\n.tvAir3rTMgqTMp2yQMyd {\n    color: var(--color-5);\n    text-align: center;\n}\n", "",{"version":3,"sources":["webpack://./base/components/message-with-emoji/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,cAAc;IACd,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,cAAc;;IAEd,qBAAqB;IACrB,8BAA8B;IAC9B,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,qBAAqB;IACrB,kBAAkB;AACtB","sourcesContent":[".messageWithEmoji {\n    display: grid;\n}\n\n.emojiImg {\n    margin: 0 auto;\n    width: 10rem;\n    height: auto;\n}\n\n.header {\n    margin: 2rem 0;\n\n    color: var(--color-1);\n    font-family: \"Soleil-Semibold\";\n    font-size: 2rem;\n    text-align: center;\n}\n\n.message {\n    color: var(--color-5);\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"messageWithEmoji": "n8Jy6b4nQ50gbBhUNeXB",
	"emojiImg": "qJq4zfz9LIzbvxi8s8_w",
	"header": "gbPr5xX9A_yzgsyBosHZ",
	"message": "tvAir3rTMgqTMp2yQMyd"
};
export default ___CSS_LOADER_EXPORT___;
