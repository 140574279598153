const keys = {
    session: "session",

    themeAutomatic: "themeAutomatic",
    theme: "theme",

    userIds: "userIds",

    popups: "popups",

    dailyJuicesDefaultGrades: "dailyJuicesDefaultGrades",
    dailyJuices: "dailyJuices",
    dailyJuiceQuizzes: "dailyJuiceQuizzes",
    dailyJuiceConfetti: "dailyJuiceConfetti",
    teacherSelectedClass: "teacherSelectedClass",
};

/* --- */

export const saveSession = (session) => {
    if (window.localStorage) {
        localStorage.setItem(keys.session, session);
    }
};

export const loadSession = () => {
    if (window.localStorage) {
        return localStorage.getItem(keys.session) || "";
    }

    return "";
};

export const clearSession = () => {
    if (window.localStorage) {
        localStorage.removeItem(keys.session);
    }
};

/* --- */

export const saveAutomaticTheme = (isAutomatic) => {
    if (window.localStorage) {
        localStorage.setItem(keys.themeAutomatic, isAutomatic);
    }
};

export const loadAutomaticTheme = () => {
    if (window.localStorage) {
        const isAutomatic = localStorage.getItem(keys.themeAutomatic);

        if (isAutomatic === "true") {
            return true;
        }

        if (isAutomatic === "false") {
            return false;
        }
    }

    return true;
};

export const loadTheme = () => {
    if (window.localStorage) {
        return localStorage.getItem(keys.theme) || "";
    }

    return "";
};

export const saveTheme = (theme) => {
    if (window.localStorage) {
        localStorage.setItem(keys.theme, theme || "");
    }
};

/* --- */

export const loadUserIds = () => {
    if (!window.localStorage) {
        return [];
    }

    const userIds = localStorage.getItem(keys.userIds) || "[]";

    try {
        return JSON.parse(userIds);
    } catch {
        return [];
    }
};

export const saveUserIds = (userIds) => {
    if (!window.localStorage) {
        return;
    }

    localStorage.setItem(keys.userIds, JSON.stringify(userIds));
};

/* --- */

export const loadPopups = () => {
    if (window.localStorage) {
        const popups = localStorage.getItem(keys.popups) || "[]";

        try {
            return JSON.parse(popups);
        } catch {
            return [];
        }
    }

    return [];
};

export const savePopups = (popups) => {
    if (window.localStorage) {
        const item = JSON.stringify(popups || "[]");
        localStorage.setItem(keys.popups, item);
    }
};

/* --- */

export const loadDailyJuicesDefaultGrades = () => {
    if (window.localStorage) {
        return localStorage.getItem(keys.dailyJuicesDefaultGrades) || "";
    }

    return "";
};

export const saveDailyJuicesDefaultGrades = (grades) => {
    if (window.localStorage) {
        localStorage.setItem(keys.dailyJuicesDefaultGrades, grades || "");
    }
};

/* --- */

export const loadDailyJuices = () => {
    if (window.localStorage) {
        const juices = localStorage.getItem(keys.dailyJuices) || "[]";

        try {
            return JSON.parse(juices);
        } catch {
            return [];
        }
    }

    return [];
};

export const saveDailyJuices = (juices) => {
    if (window.localStorage) {
        const item = JSON.stringify(juices || "[]");
        localStorage.setItem(keys.dailyJuices, item);
    }
};

/* --- */

export const saveTeacherSelectedClass = (id) => {
    if (window.localStorage) {
        localStorage.setItem(keys.teacherSelectedClass, id);
    }
};


export const loadTeacherSelectedClass = () => {
    if (window.localStorage) {
        let classId = localStorage.getItem(keys.teacherSelectedClass) || "";
        classId = parseInt(classId, 10);
        return Number.isNaN(classId) ? "" : classId;
    }

    return "";
};

export const clearTeacherSelectedClass = () => {
    if (window.localStorage) {
        localStorage.removeItem(keys.teacherSelectedClass);
    }
};
