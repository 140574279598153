import React, { useContext, useEffect } from "react";
import { VisibilityContext } from "react-horizontal-scrolling-menu";

import classNames from "juice-base/lib/class-names.js";

import IconArrowRight from "juice-base/icons/arrow-right";

import ButtonCircleArrow from "juice-base/components/button-circle-arrow/index.js";

import styles from "./arrows.module.css";


export const Arrow = (props) => {
    const arrowClassName = classNames({
        [styles.arrow]: true,
        [styles.arrowDisabled]: props.disabled,
    });

    return (
        <button
            className={arrowClassName}
            onClick={props.onClick}
            disabled={props.disabled}
            type="button"
        >
            {props.children}
        </button>
    );
};

export const LeftArrow = (props) => {
    const {
        isFirstItemVisible,
        isLastItemVisible,
        items,
        scrollPrev,
    } = useContext(VisibilityContext);

    useEffect(() => {
        if (props.onFirstItemVisibleChange) {
            if (items?.size === 0) {
                return;
            }

            props.onFirstItemVisibleChange(isFirstItemVisible);
        }
    }, [isFirstItemVisible, items?.size]);

    if (props.isHidden) {
        return null;
    }

    if (props.autoHide && isFirstItemVisible && isLastItemVisible) {
        return null;
    }

    if (props.isButtonInCircle) {
        return (
            <Arrow disabled={isFirstItemVisible} onClick={() => scrollPrev()}>
                <ButtonCircleArrow
                    left
                    caretBold
                />
            </Arrow>
        );
    }

    return (
        <Arrow disabled={isFirstItemVisible} onClick={() => scrollPrev()}>
            <IconArrowRight
                className={styles.arrowLeft}
                isBlack
            />
        </Arrow>
    );
};

export const RightArrow = (props) => {
    const {
        isFirstItemVisible,
        isLastItemVisible,
        items,
        scrollNext,
    } = useContext(VisibilityContext);

    useEffect(() => {
        if (props.onLastItemVisibleChange) {
            if (items?.size === 0) {
                return;
            }

            props.onLastItemVisibleChange(isLastItemVisible);
        }
    }, [isLastItemVisible, items?.size]);

    if (props.isHidden) {
        return null;
    }

    if (props.autoHide && isFirstItemVisible && isLastItemVisible) {
        return null;
    }

    if (props.isButtonInCircle) {
        return (
            <Arrow disabled={isLastItemVisible} onClick={() => scrollNext()}>
                <ButtonCircleArrow
                    right
                    caretBold
                />
            </Arrow>
        );
    }

    return (
        <Arrow disabled={isLastItemVisible} onClick={() => scrollNext()}>
            <IconArrowRight
                className={styles.arrowRight}
                isBlack
            />
        </Arrow>
    );
};
