// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".eElcz_dW95mTP6PIU0TN {\n    max-width: 130rem;\n    width: 100%;\n\n    margin: 0 auto;\n}\n\n.IJnC8Gmpk88S9SoSss9Q {\n    color: var(--error-color);\n\n    text-align: center;\n}\n", "",{"version":3,"sources":["webpack://./app/containers/guardian-daily-juices/styles.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,WAAW;;IAEX,cAAc;AAClB;;AAEA;IACI,yBAAyB;;IAEzB,kBAAkB;AACtB","sourcesContent":[".index {\n    max-width: 130rem;\n    width: 100%;\n\n    margin: 0 auto;\n}\n\n.errorMessage {\n    color: var(--error-color);\n\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"index": "eElcz_dW95mTP6PIU0TN",
	"errorMessage": "IJnC8Gmpk88S9SoSss9Q"
};
export default ___CSS_LOADER_EXPORT___;
