import React from "react";

import PopupWindow from "juice-base/components/popup-window/index.js";
import MessageDanger from "juice-base/components/message-danger/index.js";
import UploaderImage from "juice-base/components/forms/uploader-image/index.js";

import styles from "./styles.module.css";


const PopupProfileAvatar = (props) => {
    const onUploadProfileImage = (evt) => {
        props.onUploadProfileImage(evt);
    };

    const renderContent = () => {
        let error = null;

        if (props.error) {
            error = (
                <div className={styles.error}>
                    <MessageDanger>
                        {props.error}
                    </MessageDanger>
                </div>
            );
        }

        return (
            <div className={styles.content}>
                {error}
                <UploaderImage
                    imageUrl={props.avatarUrl}
                    isLoading={props.isSaving}
                    onFileChange={onUploadProfileImage}
                />
            </div>
        );
    };

    return (
        <PopupWindow
            onClose={props.onClose}
            title="Upload profile image"
        >
            {renderContent()}
        </PopupWindow>
    );
};

PopupProfileAvatar.defaultProps = {
    avatarUrl: "",
    error: "",
    isSaving: false,
    onUploadProfileImage: () => {},
    onClose: () => {},
};

export default PopupProfileAvatar;
