import React from "react";

import PopupCommon from "juice-base/components/popup-common/index.js";
import DailyJuiceStats from "juice-base/business/daily-juice-stats/index.js";


const PopupDailyJuiceStats = (props) => {
    if (!props.stats) {
        return null;
    }

    return (
        <PopupCommon
            onClose={props.onClose}
            showClose={false}
        >
            <DailyJuiceStats
                isWindows={props.isWindows}
                stats={props.stats}
                sponsors={props.sponsors}
                onSponsorClick={props.onSponsorClick}
                onScrollTo={props.onScrollTo}
            />
        </PopupCommon>
    );
};

PopupDailyJuiceStats.defaultProps = {
    stats: null,
    sponsors: {},
    isWindows: false,
    onSponsorClick: () => { },
    onScrollTo: () => { },
    onClose: () => { },
};

export default PopupDailyJuiceStats;
