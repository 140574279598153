import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import Grades from "juice-base/project/grades.js";
import Classes from "juice-base/project/classes.js";

import urls from "juice-base/lib/urls.js";
import copyToClipboard from "juice-base/lib/clipboard.js";

import actions from "juice-base/store/actions.js";

import useSnackbar from "juice-base/hooks/use-snackbar/index.js";
import useClassCodePopup from "juice-base/hooks/use-class-code-popup/index.js";

import MenuWithContent, { MenuContent } from "juice-base/components/menu-with-content/index.js";
import PopupFullScreen from "juice-base/components/popup-full-screen-v2/index.js";
import Snackbar from "juice-base/components/snackbar/index.js";

import TeacherShareClassCode from "juice-base/business/teacher-share-class-code/index.js";
import TeacherImportStudents from "juice-base/business/teacher-import-students/index.js";
import TeacherAddStudentsManually from "juice-base/business/teacher-add-students-manually/index.js";
import PopupConfirmGenerateClassCode from "juice-base/business/popup-confirm-generate-class-code/index.js";

import TeacherPopupStudentsAdd from "juice-app/containers/teacher-popup-students-add/index.js";

import api from "juice-app/api.js";

import settings from "juice-app/settings.js";

import styles from "./styles.module.css";


const storeSelector = (state) => ({
    session: state.user.session,
    user: state.user.user,
    dimensions: state.device.dimensions,
    teacher: state.teacher,
});

const PopupFullScreenAddStudentV2 = (props) => {
    const [isMenuVisible, setIsMenuVisible] = useState(true);
    const [templateLink, setTemplateLink] = useState("");

    const [addStudentsState, setAddStudentsState] = useState({
        isVisiblePopup: false,
        students: [],
    });

    /* ------ */

    const dispatch = useDispatch();
    const store = useSelector(storeSelector);

    const snackbar = useSnackbar();
    const classCodePopup = useClassCodePopup();
    const classCodeLinkPopup = useClassCodePopup();

    const studentsRowsLimit = props.classLimit - props.classStudentsCount;
    const signUpUrl = `${urls.getSiteUrl()}${settings.signUpByCodePath}`;

    const isMobile800 = store.dimensions.width < 800;

    /* ------ */

    const onLoadImportStudentsTemplate = async () => {
        const res = await api.students.getImportStudentsTemplate({
            session: store.session,
        });

        if (res.ok) {
            setTemplateLink(res.templateLink);
        }
    };

    const onDirectLinkClick = async () => {
        const className = Classes.getClassNameById(store.teacher.classes, props.classId);
        const classCode = Classes.getClassCodeById(store.teacher.classes, props.classId);

        classCodeLinkPopup.open(className);

        if (classCode) {
            classCodeLinkPopup.setCode(classCode);
        } else {
            const res = await api.signup.generateClassCode({
                session: store.session,
                classId: props.classId,
            });

            if (!res.ok) {
                classCodeLinkPopup.setError(res.error);
                return;
            }

            classCodeLinkPopup.setCode(res.code);

            dispatch(actions.teacher.setClassCode({
                classId: props.classId,
                classCode: res.code,
            }));
        }
    };

    const onGenerateClassCode = async () => {
        const classCode = Classes.getClassCodeById(store.teacher.classes, props.classId);
        const className = Classes.getClassNameById(store.teacher.classes, props.classId);

        classCodePopup.open(className);

        if (classCode) {
            classCodePopup.setCode(classCode);
        } else {
            const res = await api.signup.generateClassCode({
                session: store.session,
                classId: props.classId,
            });

            if (!res.ok) {
                classCodePopup.setError(res.error);
                return;
            }

            classCodePopup.setCode(res.code);

            dispatch(actions.teacher.setClassCode({
                classId: props.classId,
                classCode: res.code,
            }));
        }
    };

    /* ------ */

    const onMenuClick = () => {
        setIsMenuVisible(false);
    };

    const onCloseSnackbar = (params = {}) => {
        snackbar.close(params.index);

        if (params.withUndo && params.onCloseWithUndo) {
            params.onCloseWithUndo();
        }
    };

    const onRemoveStudentRow = (params) => {
        const { row } = params;

        let message = "Row removed.";

        if (row.nameValue && row.lastNameValue) {
            message = `${row.nameValue} ${row.lastNameValue} removed.`;
        }

        snackbar.add({
            message,
            autoCloseInSeconds: 5,
            onCloseWithUndo: params.onUndo,
            isVisibleUndoButton: true,
        });
    };

    const onCopyToClipboard = (toCopy, snackbarMessage) => {
        copyToClipboard(toCopy);

        snackbar.add({
            autoCloseInSeconds: 3,
            message: snackbarMessage,
        });
    };

    const onAddStudentsToClass = (students) => {
        setAddStudentsState({
            isVisiblePopup: true,
            students,
        });
    };

    const onCloseAddStudentsPopup = () => {
        setAddStudentsState({
            isVisiblePopup: false,
            students: [],
        });
    };

    /* ------ */

    useEffect(() => {
        onLoadImportStudentsTemplate();
    }, []);

    /* ------ */

    const renderClassCodePopup = () => {
        if (!classCodePopup.state.isOpen) {
            return null;
        }

        return (
            <PopupConfirmGenerateClassCode
                code={classCodePopup.state.code}
                signUpUrl={signUpUrl}
                className={classCodePopup.state.className}
                error={classCodePopup.state.error}
                onSubmit={(copyValue) => {
                    onCopyToClipboard(copyValue, "Class code copied to clipboard.");
                }}
                onClose={classCodePopup.close}
                isLoading={classCodePopup.state.isLoading}
                isDisabled={classCodePopup.state.isLoading || classCodePopup.state.error}
            />
        );
    };

    const renderClassCodeDirectLinkPopup = () => {
        if (!classCodeLinkPopup.state.isOpen) {
            return null;
        }

        return (
            <PopupConfirmGenerateClassCode
                code={classCodeLinkPopup.state.code}
                signUpUrl={signUpUrl}
                error={classCodeLinkPopup.state.error}
                className={classCodeLinkPopup.state.className}
                onSubmit={(copyValue) => {
                    onCopyToClipboard(copyValue, "Direct link copied to clipboard.");
                }}
                onClose={classCodeLinkPopup.close}
                isDisabled={classCodeLinkPopup.state.isLoading}
                isLoading={classCodeLinkPopup.state.isLoading || classCodeLinkPopup.state.error}
                isLink
            />
        );
    };

    const renderStudentsAddPopup = () => {
        if (!addStudentsState.isVisiblePopup) {
            return null;
        }

        return (
            <TeacherPopupStudentsAdd
                classId={props.classId}
                students={addStudentsState.students}
                onLoadClass={props.onLoadClass}
                onClose={onCloseAddStudentsPopup}
            />
        );
    };

    const renderSnackbars = () => {
        return snackbar.state.map((bar, index) => {
            return (
                <Snackbar
                    isVisibleUndoButton={bar.isVisibleUndoButton}
                    autoCloseInSeconds={bar.autoCloseInSeconds}
                    message={bar.message}
                    onClose={(params) => {
                        onCloseSnackbar({
                            ...params,
                            index,
                            onCloseWithUndo: () => {
                                bar.onCloseWithUndo();
                            },
                        });
                    }}
                />
            );
        });
    };

    const renderContent = () => {
        const supportLink = [
            settings.landingSite.domain,
            settings.landingSite.routeSupport,
        ].join("");

        const menu = [
            <MenuContent name="Add Manually">
                <div className={styles.pageContent}>
                    <TeacherAddStudentsManually
                        grades={Grades.getGradesOptions()}
                        rowsLimit={studentsRowsLimit}
                        noRowsLimit={props.noClassLimit}
                        supportLink={supportLink}
                        defaultGrade={props.defaultGrade}
                        importStudentsTemplateUrl={templateLink}
                        onRemoveStudent={onRemoveStudentRow}
                        onAddStudentsToClass={onAddStudentsToClass}
                    />
                </div>
            </MenuContent>,
        ];

        if (props.showShareClassCodeMenu) {
            menu.push(
                <MenuContent name="Share Class Code">
                    <div className={styles.pageContent}>
                        <TeacherShareClassCode
                            onGenerateClassCode={onGenerateClassCode}
                            onDirectLinkClick={onDirectLinkClick}
                        />
                    </div>
                </MenuContent>,
            );
        }

        menu.push(
            <MenuContent name="Import Data">
                <div className={styles.pageContent}>
                    <TeacherImportStudents
                        grades={Grades.getGradesOptions()}
                        rowsLimit={studentsRowsLimit}
                        noRowsLimit={props.noClassLimit}
                        supportLink={supportLink}
                        defaultGrade={props.defaultGrade}
                        importStudentsTemplateUrl={templateLink}
                        onRemoveStudent={onRemoveStudentRow}
                        onAddStudentsToClass={onAddStudentsToClass}
                    />
                </div>
            </MenuContent>,
        );

        return (
            <MenuWithContent
                isMobile={isMobile800}
                isMobileMenuVisible={isMenuVisible}
                defaultSelected={props.defaultSelectedMenu}
                onMenuClick={onMenuClick}
            >
                {menu}
            </MenuWithContent>
        );
    };

    return (
        <>
            {renderSnackbars()}
            {renderClassCodeDirectLinkPopup()}
            {renderClassCodePopup()}
            {renderStudentsAddPopup()}

            <PopupFullScreen
                title="Add Student"
                isMobile={isMobile800}
                isArrowBackVisible={!isMenuVisible}
                onArrowBack={setIsMenuVisible}
                onClose={props.onClose}
            >
                {renderContent()}
            </PopupFullScreen>
        </>
    );
};

PopupFullScreenAddStudentV2.defaultProps = {
    classId: null,
    showShareClassCodeMenu: true,
    defaultGrade: "G7-G8",
    defaultSelectedMenu: 0,
    classStudentsCount: 0,
    classLimit: 10,
    noClassLimit: false,
    onLoadClass: () => { },
    onClose: () => { },
};

export default PopupFullScreenAddStudentV2;
