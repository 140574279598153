import React from "react";

import IconWrapper from "juice-base/icons/wrapper/index.js";


const IconPlusInRing = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={iconProps.className}
                >
                    <circle
                        cx="9"
                        cy="9"
                        r="6.75"
                        stroke="currentColor"
                        strokeWidth="1.5"
                    />
                    <path
                        d="M9 6V12M6 9H12"
                        stroke="currentColor"
                        strokeWidth="1.5"
                    />
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconPlusInRing;
