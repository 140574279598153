const PAGES_SLUGS = {
    infoRadarChart: "info-radar-chart",
};

const getPagesSlugs = () => {
    return {
        ...PAGES_SLUGS,
    };
};

export default {
    getPagesSlugs,
};
