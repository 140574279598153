import React from "react";

import classNames from "juice-base/lib/class-names.js";

import styles from "./styles.module.css";


const Switch = (props) => {
    const onChange = (evt) => {
        if (props.disabled) {
            return;
        }

        props.onChange(evt.target.checked);
    };

    const swClassName = classNames({
        [styles.switch]: true,
        [styles.switchChecked]: props.checked,
        [styles.switchDisabled]: props.disabled,
    });

    return (
        <label htmlFor={props.name} className={swClassName}>
            <input
                id={props.name}
                name={props.name}
                className={styles.input}
                type="checkbox"
                checked={props.checked}
                disabled={props.disabled}
                onChange={onChange}
            />
            <span className={styles.sliderDotContainer}>
                <span className={styles.sliderDot} />
            </span>
            <span className={styles.slider} />
        </label>
    );
};

Switch.defaultProps = {
    name: "",
    checked: false,
    disabled: false,
    onChange: () => {},
};

export default Switch;
