import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import text from "juice-base/text/index.js";

import useTitle from "juice-base/hooks/use-title/index.js";
import useReferralCode from "juice-base/hooks/use-referral-code/index.js";

import LayoutContent from "juice-base/components/layout-content/index.js";
import LogoRound from "juice-base/components/logo-round/index.js";
import Footer from "juice-base/components/footer/index.js";
import RequestLoader from "juice-base/components/request-loader/index.js";

import UserSignUpTrialRoles from "juice-base/forms/user-sign-up-trial-roles/index.js";

import MessageReferralCode from "juice-base/business/message-referral-code/index.js";
import MessageAlreadyAuthorized from "juice-base/business/message-already-authorized/index.js";

import settings from "juice-app/settings.js";
import api from "juice-app/api.js";

import styles from "./styles.module.css";


const storeSelector = (state) => ({
    isUserLoading: state.user.isUserLoading,
    userId: state.user.user.userId,
});

const SignUpTrialRoles = () => {
    const [selectedRole, setSelectedRole] = useState("");

    const navigate = useNavigate();
    const params = useParams();
    const referralCode = useReferralCode();

    const store = useSelector(storeSelector);

    const supportLink = [
        settings.landingSite.domain,
        settings.landingSite.routeSupport,
    ].join("");

    /* --- */

    useEffect(() => {
        if (!params?.referralCode) {
            referralCode.setCodeMissing();
            return;
        }

        api.signup.validateReferralCode({
            code: params.referralCode,
        }).then((res) => {
            let isActive = false;
            let error = null;

            if (res.ok) {
                isActive = res.data.isActive;
            } else {
                error = res.error || "Error!";
            }

            referralCode.setLoaded(isActive, error);
        });
    }, []);

    useTitle(() => "Select Role", []);

    /* --- */

    const onSubmit = (values) => {
        navigate(`/sign-up/trial/${values.role.value}/${params.referralCode}`);
    };

    const renderForm = () => {
        return (
            <UserSignUpTrialRoles
                onSubmit={onSubmit}
                onRoleChange={setSelectedRole}
            />
        );
    };

    const renderTextBelowForm = () => {
        if (["teacher", "administrator"].indexOf(selectedRole) !== -1) {
            return (
                <div className={styles.textBelowForm}>
                    {text.trialRegistration5}
                </div>
            );
        }

        return null;
    };

    const renderContent = () => {
        if (store.isUserLoading) {
            return null;
        }

        if (store.userId) {
            return (
                <MessageAlreadyAuthorized
                    onClick={() => {
                        navigate("/");
                    }}
                />
            );
        }

        if (!params?.referralCode) {
            return (
                <MessageReferralCode
                    supportLink={supportLink}
                >
                    Referral code is missing.
                </MessageReferralCode>
            );
        }

        if (!referralCode.state.isLoaded) {
            return (
                <div className={styles.content}>
                    <RequestLoader />
                </div>
            );
        }

        if (referralCode.state.error) {
            return (
                <MessageReferralCode
                    supportLink={supportLink}
                >
                    {referralCode.state.error}
                </MessageReferralCode>
            );
        }

        if (!referralCode.state.isActive) {
            return (
                <MessageReferralCode
                    supportLink={supportLink}
                >
                    {`Referral code ${params.referralCode} is not active!`}
                </MessageReferralCode>
            );
        }

        const description1 = selectedRole
            ? text.trialRegistration4
            : text.trialRegistration1;

        return (
            <div className={styles.content}>
                <div className={styles.contentHeader}>
                    <LogoRound />

                    <div className={styles.formName}>
                        {description1}
                    </div>

                    <div className={styles.formName}>
                        {text.trialRegistration2}
                    </div>

                    <div className={styles.formWarning}>
                        {text.trialRegistration3}
                    </div>
                </div>

                {renderForm()}

                {renderTextBelowForm()}

                <div className={styles.links}>
                    <Link to="/" className={styles.link}>
                        Already have an account? Sign in
                    </Link>
                </div>

                <div className={styles.footer}>
                    <Footer
                        copyright={settings.copyright}
                    />
                </div>
            </div>
        );
    };

    return (
        <LayoutContent>
            <div className={styles.signUp}>
                {renderContent()}
            </div>
        </LayoutContent>
    );
};

export default SignUpTrialRoles;
