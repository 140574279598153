import React, { useState } from "react";

import staticFiles from "juice-base/static-files.js";

import useTitle from "juice-base/hooks/use-title/index.js";

import UserEmailUnsubscribe from "juice-base/forms/user-email-unsubscribe";

import settings from "juice-app/settings.js";
import api from "juice-app/api.js";

import styles from "./styles.module.css";


const Unsubscribe = () => {
    const [isUnsubscribed, setIsUnsubscribed] = useState(false);

    useTitle(() => "Unsubscribe", []);

    const onSubmit = async (values) => {
        await api.user.newsletterUnsubscribe({
            email: values.email,
        });

        setIsUnsubscribed(true);
    };

    let content = (
        <>
            <div className={styles.formHeader}>
                Enter your email below to unsubscribe from The Daily Juice newsletter
            </div>

            <UserEmailUnsubscribe
                onSubmit={onSubmit}
            />
        </>
    );

    if (isUnsubscribed) {
        const supportMessage = "If you have any questions, send us a message at ";

        const supportEmail = (
            <a
                href={`mailto:${settings.supportEmail}`}
                className={styles.supportLink}
            >
                {settings.supportEmail}
            </a>
        );

        content = (
            <div className={styles.unsubscribeMessages}>
                <img
                    src={staticFiles.iconEmailStop}
                    className={styles.icon}
                    alt="Email Stop Icon"
                />

                <div className={styles.contentHeader}>
                    You will be missed!
                </div>

                <div className={styles.message}>
                    Your account has been unsubscribed from The Daily Juice notification
                    email, but you can still login and enjoy all the benefits of The Juice.
                </div>

                <div className={styles.message}>
                    {supportMessage}
                    {supportEmail}
                </div>
            </div>
        );
    }

    return (
        <div className={styles.content}>
            <div className={styles.block}>
                {content}
            </div>
        </div>
    );
};

export default Unsubscribe;
