import React from "react";

import ButtonBig from "juice-base/components/button-big/index.js";
import WithScroll from "juice-base/components/with-scroll/index.js";
import ProfileRow from "juice-base/components/profile-row/index.js";

import UserPaymentDetailsChange from "juice-app/containers/user-payment-details-change/index.js";
import Stripe from "juice-app/containers/stripe/index.js";

import styles from "./styles.module.css";


const Payment = (props) => {
    const onBackToManagement = () => {
        props.onLoadUserPaymentDetails();
        props.onSetIsVisiblePaymentChange(false);
    };

    const onChangePaymentDetails = () => {
        props.onSetIsVisiblePaymentChange(true);
    };

    /* ---- */

    const renderCardDetails = () => {
        if (props.isVisiblePaymentChange || !props.hasCardDetails) {
            return (
                <Stripe>
                    <UserPaymentDetailsChange
                        isAddPaymentDetails={!props.hasCardDetails}
                        onContactSupport={props.onContactSupport}
                        onBackToManagement={onBackToManagement}
                    />
                </Stripe>
            );
        }

        return (
            <div>
                <ProfileRow
                    isCardName
                    value={props.card.name}
                />
                <ProfileRow
                    isCardNumber
                    value={`**** **** **** ${props.card.last4}`}
                />
                <ProfileRow
                    isExpiration
                    value={`${props.card.expMonth}/${props.card.expYear}`}
                />
            </div>
        );
    };

    const renderControls = () => {
        if (!props.hasCardDetails || props.isVisiblePaymentChange) {
            return null;
        }

        return (
            <div className={styles.personalInfoControls}>
                <ButtonBig
                    onClick={onChangePaymentDetails}
                >
                    Change payment details
                </ButtonBig>
                <ButtonBig
                    outlined
                    onClick={props.onContactSupport}
                >
                    Contact Support
                </ButtonBig>
            </div>
        );
    };

    return (
        <div className={styles.payment}>
            <WithScroll>
                {renderCardDetails()}
            </WithScroll>
            {renderControls()}
        </div>
    );
};

Payment.defaultProps = {
    hasCardDetails: false,
    hasSubscription: false,
    card: {
        name: "",
        last4: "",
        expMonth: "",
        expYear: "",
    },
    expireDate: "",
    isVisiblePaymentChange: false,
    onSetIsVisiblePaymentChange: () => { },
    onLoadUserPaymentDetails: () => { },
    onCancelSubscription: () => { },
    onContactSupport: () => { },
};

export default Payment;
