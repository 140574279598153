import React, { useState } from "react";

import classNames from "juice-base/lib/class-names.js";

import styles from "./styles.module.css";


const AvatarIcon = (props) => {
    const [isLoadingError, setIsLoadingError] = useState(false);

    if (props.avatarUrl && !isLoadingError) {
        const avatarClassName = classNames({
            [styles.avatarImage]: true,
            [styles.avatarDisabled]: props.disabled,
        });

        return (
            <div
                className={avatarClassName}
                onClick={props.onClick}
                onKeyPress={props.onClick}
                role="button"
                tabIndex="-1"
            >
                <img
                    src={props.avatarUrl}
                    alt="Avatar"
                    title="Avatar"
                    onError={() => { setIsLoadingError(true); }}
                />
            </div>
        );
    }

    const avatarClassName = classNames({
        [styles.avatar]: true,
        [styles.avatarDisabled]: props.disabled,
    });

    return (
        <div
            className={avatarClassName}
            onClick={props.onClick}
            onKeyPress={props.onClick}
            role="button"
            tabIndex="-1"
        >
            {props.name || "A"}
        </div>
    );
};

AvatarIcon.defaultProps = {
    name: "A",
    avatarUrl: "",
    onClick: () => {},
    disabled: false,
};

export default AvatarIcon;
