import React from "react";

import IconWrapper from "juice-base/icons/wrapper/index.js";


const IconEmojiHappy = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    width="230"
                    height="184"
                    viewBox="0 0 230 184"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={iconProps.className}
                >
                    {iconProps.title}
                    <path
                        fill="#FDFF00"
                        d="M207.207 19.9961H1.25391V165.277H207.207V19.9961Z"
                    />
                    <path
                        fill="#000000"
                        d="M113.171 34.7959H134.827V57.0928H113.171V34.7959ZM58.3828 34.7959H80.1995V57.0928H58.3828V34.7959Z"
                    />
                    <path
                        fill="#000000"
                        d="M58.385 69.54H165.708V111.08C165.756 117.935 164.503 124.737 162.014 131.124C159.719 137.067 156.166 142.443 151.598 146.883C146.85 151.383 141.237 154.872 135.1 157.139C128.065 159.713 120.614 160.965 113.124 160.833C105.553 160.938 98.0141 159.836 90.7899 157.57C84.4787 155.582 78.6214 152.369 73.5532 148.115C68.8111 144.083 65.0046 139.067 62.3987 133.414C59.6909 127.472 58.3207 121.008 58.385 114.478V69.54ZM76.6435 109.948C76.6435 119.748 79.6887 126.86 85.779 131.284C91.8693 135.708 101.001 137.904 113.173 137.871C118.39 137.958 123.598 137.416 128.686 136.258C132.587 135.383 136.219 133.58 139.274 131.001C142.072 128.5 144.14 125.288 145.258 121.706C146.59 117.251 147.218 112.614 147.117 107.965V91.3567H76.6435V109.948Z"
                    />
                    <path
                        fill="#000000"
                        d="M0.914062 183.104V0.961914H49.8046V20.0577H25.5379V164.439H49.8046V183.104H0.914062Z"
                    />
                    <path
                        fill="#000000"
                        d="M183.34 183.104V164.439H207.582V20.0577H183.34V0.961914H232.255V183.104H183.34Z"
                    />
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconEmojiHappy;
