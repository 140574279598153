import * as types from "./types.js";


export const setDimensions = (dimensions) => ({
    type: types.SET_DIMENSIONS,
    payload: {
        dimensions,
    },
});

export const setIsPWA = (isPWA) => ({
    type: types.SET_IS_PWA,
    payload: {
        isPWA,
    },
});
