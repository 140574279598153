// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".XlcJVc_VvJF7x6oGn7P1 {}\n\n.nHX1gRfXqgp3aIEEGZ1B {\n    display: grid;\n    grid-gap: 2.5rem;\n}\n\n.Uu3yZhgYQve1VGMDikLH {\n    color: var(--link-color);\n    font-size: 1.4rem;\n    font-weight: 600;\n    text-decoration: none;\n}\n\n.HyMgvMHjuB3rTik0MnBT {\n    margin-top: 2.5rem;\n    display: grid;\n}\n\n@media only screen and (min-width: 500px) {\n    .nHX1gRfXqgp3aIEEGZ1B {\n        grid-auto-flow: column;\n        justify-content: space-between;\n        align-items: center;\n    }\n}", "",{"version":3,"sources":["webpack://./base/forms/user-sign-in/styles.module.css"],"names":[],"mappings":"AAAA,uBAAO;;AAEP;IACI,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,wBAAwB;IACxB,iBAAiB;IACjB,gBAAgB;IAChB,qBAAqB;AACzB;;AAEA;IACI,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI;QACI,sBAAsB;QACtB,8BAA8B;QAC9B,mBAAmB;IACvB;AACJ","sourcesContent":[".form {}\n\n.formLinksSection {\n    display: grid;\n    grid-gap: 2.5rem;\n}\n\n.accountLinkPassword {\n    color: var(--link-color);\n    font-size: 1.4rem;\n    font-weight: 600;\n    text-decoration: none;\n}\n\n.submitButtonBlock {\n    margin-top: 2.5rem;\n    display: grid;\n}\n\n@media only screen and (min-width: 500px) {\n    .formLinksSection {\n        grid-auto-flow: column;\n        justify-content: space-between;\n        align-items: center;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "XlcJVc_VvJF7x6oGn7P1",
	"formLinksSection": "nHX1gRfXqgp3aIEEGZ1B",
	"accountLinkPassword": "Uu3yZhgYQve1VGMDikLH",
	"submitButtonBlock": "HyMgvMHjuB3rTik0MnBT"
};
export default ___CSS_LOADER_EXPORT___;
