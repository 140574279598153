import React from "react";

import classNames from "juice-base/lib/class-names.js";

import styles from "./styles.module.css";


const SectionStudentV2 = (props) => {
    const renderIcon = () => {
        if (!props.icon) {
            return null;
        }

        return (
            <div className={styles.icon}>
                {props.icon}
            </div>
        );
    };

    const renderTitleBlock = () => {
        if (!props.title) {
            return null;
        }

        return (
            <div className={styles.title}>
                {props.title}
            </div>
        );
    };

    const renderHeader = () => {
        if (!props.title) {
            return null;
        }

        const headerClassName = classNames({
            [styles.header]: true,
            [styles.headerWithBottomBorder]: props.titleBottomBorder,
            [props.headerClassName]: props.headerClassName,
        });

        return (
            <div className={headerClassName}>
                {renderIcon()}
                {renderTitleBlock()}
            </div>
        );
    };

    const sectionClassName = classNames({
        [styles.section]: true,
        [styles.sectionBorderDefault]: props.isDefaultBorder,
    });

    return (
        <div
            className={sectionClassName}
            data-comment={props.dataComment}
        >
            {renderHeader()}
            <div className={styles.content}>
                {props.children}
            </div>
        </div>
    );
};

SectionStudentV2.defaultProps = {
    dataComment: "",
    title: "",
    headerClassName: "",

    icon: null,
    children: null,

    titleBottomBorder: true,
    isDefaultBorder: false,
};

export default SectionStudentV2;
