import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import User from "juice-base/project/user.js";
import Grades from "juice-base/project/grades.js";
import Students from "juice-base/project/students.js";

import { getDateFromDate, getDatesByRange } from "juice-base/lib/date.js";
import dateMoment from "juice-base/lib/date-moment.js";
import actions from "juice-base/store/actions.js";
import text from "juice-base/text/index.js";

import useTitle from "juice-base/hooks/use-title/index.js";
import useStudentInfoPopup from "juice-base/hooks/use-student-info-popup/index.js";
import useProgressPopupAddStudents from "juice-base/hooks/use-progress-popup-add-students/index.js";
import useAddStudentPopup from "juice-base/hooks/use-add-student-popup/index.js";

import { withAuth } from "juice-base/components/auth/index.js";
import LayoutContent from "juice-base/components/layout-content/index.js";
import TableStudents from "juice-base/components/table-students/index.js";

import PopupAddStudentsProgress from "juice-base/business/popup-add-students-progress/index.js";
import PopupConfirmDeleteStudent from "juice-base/business/popup-confirm-delete-student/index.js";
import PopupFullScreenAddStudent from "juice-base/business/popup-full-screen-add-student/index.js";
import PopupNewPassword from "juice-base/business/popup-new-password/index.js";
import PopupResetPassword from "juice-base/business/popup-reset-password/index.js";

import TeacherPopupStudentInfo from "juice-app/containers/teacher-popup-student-info/index.js";
import Tutorial from "juice-app/containers/tutorial/index.js";
import UserFooter from "juice-app/containers/user-footer/index.js";

import settings from "juice-app/settings.js";
import api from "juice-app/api.js";
import events from "juice-app/events.js";

import styles from "./styles.module.css";


const Student = () => {
    const [selectedSortByValue, setSelectedSortByValue] = useState(() => {
        const sValues = Students.getSortValues();

        if (sValues && sValues.length > 0) {
            return sValues[0].value;
        }

        return "";
    });

    const defaultDateRange = "today";

    const [dateRange, setDateRange] = useState({
        selectedRange: defaultDateRange,
        startDate: null,
        endDate: null,
    });

    const [tableState, setTableState] = useState({
        isLoaded: false,
        isOverview: false,
        message: null,
        studentsClassId: null,
        students: [],
    });

    const [deleteStudentPopup, setDeleteStudentPopup] = useState({
        isOpen: false,
        isLoading: false,
        error: null,
        id: -1,
    });

    const [resetStudentPasswordPopup, setResetStudentPasswordPopup] = useState({
        isOpen: false,
        isLoading: false,
        message: null,
    });

    const [deleteSelectedUsersPopup, setDeleteSelectedUsersPopup] = useState({
        isOpen: false,
        isLoading: false,
        error: null,
        students: [],
    });

    const [newPasswordPopupState, setNewPasswordPopupState] = useState({
        isOpen: false,
        isSubmitted: false,
        isSaving: false,
        message: null,
        id: null,
    });

    const studentInfoPopup = useStudentInfoPopup();
    const addStudentPopup = useAddStudentPopup();
    const progressPopupAddStudents = useProgressPopupAddStudents();

    const dispatch = useDispatch();

    const store = useSelector((state) => ({
        user: state.user.user,
        session: state.user.session,
        guardian: state.guardian,
        dimensions: state.device.dimensions,
        juices: state.juices,
        teacher: state.teacher,
    }));

    let sortedStudents = tableState.students.filter((student) => {
        return Students.isStatusActive(student);
    });

    sortedStudents = Students.sortBy(selectedSortByValue, sortedStudents, tableState.isOverview);

    /* ------ */

    const loadImportStudentsTemplate = () => {
        api.students.getImportStudentsTemplate({
            session: store.session,
        }).then((res) => {
            if (res.ok) {
                addStudentPopup.setTemplate(res.templateLink);
            }
        });
    };

    const loadGuardianStudents = (selectedRange, customDates = null) => {
        setDateRange((prev) => ({
            ...prev,
            selectedRange,
            startDate: customDates ? customDates.dateFrom : null,
            endDate: customDates ? customDates.dateTo : null,
        }));

        setTableState((prev) => ({
            ...prev,
            isLoaded: false,
            isOverview: false,
            message: null,
            students: [],
        }));

        if (customDates?.dateFrom && customDates?.dateTo) {
            const dateFrom = customDates.dateFrom.format("YYYY-MM-DD");
            const dateTo = customDates.dateTo.format("YYYY-MM-DD");

            api.students.getStudentsStatsByGuardian({
                session: store.session,
                dateFrom,
                dateTo,
            }).then((res) => {
                const newStudents = res.ok ? res.data.students : [];
                const isOverview = res.ok ? res.data.isOverview : false;
                const message = res.ok ? null : res.error;
                const studentsClassId = res.ok ? res.data.classId : null;

                setTableState((prev) => ({
                    ...prev,
                    isLoaded: true,
                    isOverview,
                    message,
                    studentsClassId: studentsClassId || null,
                    students: newStudents,
                }));
            });
        } else {
            api.site.getSiteDate().then((res) => {
                const siteDate = res.ok ? res.date : null;

                const dates = getDatesByRange(siteDate, selectedRange);

                let { dateFrom, dateTo } = dates;

                if (dateFrom !== "all") {
                    dateFrom = getDateFromDate(dateFrom);
                }

                if (dateTo !== "") {
                    dateTo = getDateFromDate(dateTo);
                }

                if (dateFrom !== "all" && dateTo !== "") {
                    setDateRange((prev) => ({
                        ...prev,
                        startDate: dateMoment.dateFromString(dateFrom),
                        endDate: dateMoment.dateFromString(dateTo),
                    }));
                }

                api.students.getStudentsStatsByGuardian({
                    session: store.session,
                    dateFrom,
                    dateTo,
                }).then((sRes) => {
                    const newStudents = sRes.ok ? sRes.data.students : [];
                    const isOverview = sRes.ok ? sRes.data.isOverview : false;
                    const studentsClassId = sRes.ok ? sRes.data.classId : null;

                    setTableState({
                        isLoaded: true,
                        isOverview,
                        message: sRes.ok ? null : sRes.error,
                        studentsClassId: studentsClassId || null,
                        students: newStudents,
                    });
                });
            });
        }
    };

    const loadStudentInfo = (studentId) => {
        studentInfoPopup.open(studentId);

        api.students.getStudentInfo({
            session: store.session,
            studentId,
        }).then((res) => {
            studentInfoPopup.setStudent(res.student);
        });
    };

    /* --- */

    useEffect(() => {
        if (store.session) {
            const range = {
                dateFrom: dateRange.startDate,
                dateTo: dateRange.endDate,
            };

            loadGuardianStudents(dateRange.selectedRange, range);
        }
    }, [store.session]);

    useTitle(() => "Student", []);

    /* --- */

    const onChangeDateRange = (range) => {
        loadGuardianStudents(range);
    };

    const onSelectCustomRange = (values) => {
        const range = {
            dateFrom: values.startDate,
            dateTo: values.endDate,
        };

        loadGuardianStudents("custom", range);
    };

    const loadStudentDailyJuices = (studentId, clearJuices = true) => {
        if (clearJuices) {
            dispatch(actions.juices.clearStudentJuicesById({
                studentId,
            }));
        }

        dispatch(actions.juices.setStudentJuicesByIdLoading({
            studentId,
        }));

        let page = 0;

        if (!clearJuices && store.juices?.studentJuicesById[studentId]?.page) {
            page = store.juices.studentJuicesById[studentId].page;
        }

        api.students.getStudentDailyJuicesResultsByPage({
            session: store.session,
            studentId,
            page,
        }).then((res) => {
            if (res.ok) {
                dispatch(actions.juices.setStudentJuicesById({
                    studentId,
                    juices: res.juices,
                    hasMore: res.hasMore,
                    page: page + 1,
                }));
            }
        });
    };

    const onAddStudentsToClass = (idx, students, addNewOnDuplicate = false) => {
        if (!students[idx]) {
            progressPopupAddStudents.setInProgress(false);
            return;
        }

        const newStudents = [...students];

        newStudents[idx] = {
            ...newStudents[idx],
            status: "loading",
        };

        progressPopupAddStudents.setStudents({
            index: idx,
            students: newStudents,
        });

        const student = newStudents[idx];

        const selectedGrade = student.gradeValue.split("-")[1];

        api.classes.addStudentV2({
            session: store.session,
            classId: tableState.studentsClassId,
            firstname: student.nameValue,
            lastname: student.lastNameValue,
            email: student.emailValue,
            grade: selectedGrade,
            addNewOnDuplicate,
        }).then((res) => {
            if (res.ok) {
                newStudents[idx] = {
                    ...newStudents[idx],
                    status: "success",
                };

                progressPopupAddStudents.setStudentsLoadedSuccessfully({
                    students: newStudents,
                });

                onAddStudentsToClass(idx + 1, newStudents);
            } else {
                let errorCode = -1;

                if (res.error.indexOf("Fullname duplicate") !== -1) {
                    errorCode = 0;
                }

                if (res.error.indexOf("Email duplicate") !== -1) {
                    errorCode = 1;
                }

                if (errorCode === -1) {
                    newStudents[idx] = {
                        ...newStudents[idx],
                        status: "failed",
                    };
                }

                progressPopupAddStudents.setStudentsFailedLoaded({
                    students: newStudents,
                    errorCode,
                });

                if (errorCode === -1) {
                    onAddStudentsToClass(idx + 1, newStudents);
                }
            }
        });
    };

    const onCloseNewPasswordPopup = () => {
        setNewPasswordPopupState({
            isOpen: false,
            isSubmitted: false,
            isLoading: false,
            message: null,
            id: null,
        });
    };

    const onAfterChangeStudentPassword = (studentId) => {
        dispatch(actions.teacher.deleteTeacherStudentWithForgottenPassword({
            studentId,
        }));
    };

    const onChangeStudentPassword = async (newPassword) => {
        setNewPasswordPopupState((prev) => ({
            ...prev,
            isSubmitted: true,
            isSaving: true,
        }));

        const res = await api.students.setPassword({
            session: store.session,
            studentId: newPasswordPopupState.id,
            password: newPassword,
        });

        if (res.ok) {
            onAfterChangeStudentPassword(newPasswordPopupState.id);
        }

        let message = "";

        if (res.ok) {
            if (res.isEmailSentToStudent) {
                message = text.passwordUpdated;
            } else {
                message = text.studentResetPassword;
            }
        } else {
            message = res.error || text.error;
        }

        setNewPasswordPopupState((prev) => ({
            ...prev,
            isSaving: false,
            message,
        }));
    };

    const onCloseAddStudentPopup = () => {
        addStudentPopup.close();
        progressPopupAddStudents.close();
    };

    const onCloseResetStudentPopup = () => {
        setResetStudentPasswordPopup({
            isOpen: false,
            isLoading: true,
            message: null,
        });
    };

    const onCloseDeleteSelectedUsersPopup = () => {
        setDeleteSelectedUsersPopup((prev) => ({
            ...prev,
            isOpen: false,
            isLoading: false,
            error: null,
            students: [],
        }));
    };

    const removeSelectedUsers = () => {
        const { students } = deleteSelectedUsersPopup;

        setDeleteSelectedUsersPopup((prev) => ({
            ...prev,
            isLoading: true,
        }));

        for (let i = 0; i < students.length; i += 1) {
            api.classes.removeStudentById({
                session: store.session,
                studentId: students[i].id,
            }).then((res) => {
                if (i === students.length - 1) {
                    if (res.ok) {
                        loadGuardianStudents(defaultDateRange);

                        onCloseDeleteSelectedUsersPopup();
                    } else {
                        setDeleteSelectedUsersPopup((prev) => ({
                            ...prev,
                            isOpen: true,
                            isLoading: false,
                            error: res.error,
                        }));
                    }
                }
            });
        }
    };

    const onOpenStudentPopup = (userId) => {
        events.guardian.selectStudent({
            session: store.session,
            studentId: userId,
        });

        loadStudentInfo(userId);

        loadStudentDailyJuices(userId);
    };

    const onCloseAddStudentProgressPopup = () => {
        const range = {
            dateFrom: dateRange.startDate,
            dateTo: dateRange.endDate,
        };

        loadGuardianStudents(dateRange.selectedRange, range);

        progressPopupAddStudents.close();
    };

    const onAddDuplicate = () => {
        onAddStudentsToClass(
            progressPopupAddStudents.state.currentLoading.index,
            progressPopupAddStudents.state.students,
            true,
        );
    };

    const onSkipDuplicate = () => {
        const newStudents = [...progressPopupAddStudents.state.students];

        newStudents[progressPopupAddStudents.state.currentLoading.index] = {
            ...newStudents[progressPopupAddStudents.state.currentLoading.index],
            status: "skipped",
        };

        progressPopupAddStudents.setStudentsSkippedStatus({
            students: newStudents,
        });

        onAddStudentsToClass(
            progressPopupAddStudents.state.currentLoading.index + 1,
            newStudents,
        );
    };

    const onAddStudentsToClassButtonClick = (students) => {
        progressPopupAddStudents.open();

        onAddStudentsToClass(0, students);
    };

    const onShowAddStudentPopup = () => {
        addStudentPopup.open();

        loadImportStudentsTemplate();
    };

    const onCloseStudentPopup = () => {
        studentInfoPopup.close();
    };

    const onCloseDeletingPopup = () => {
        setDeleteStudentPopup({
            isOpen: false,
            isLoading: false,
            error: null,
            id: -1,
        });
    };

    const onRemoveStudent = (studentId) => {
        setDeleteStudentPopup({
            isOpen: true,
            isLoading: false,
            error: null,
            id: studentId,
        });
    };

    const removeStudent = () => {
        setDeleteStudentPopup((prev) => ({
            ...prev,
            isLoading: true,
        }));

        api.classes.removeStudentById({
            session: store.session,
            studentId: deleteStudentPopup.id,
        }).then((res) => {
            if (res.ok) {
                onCloseDeletingPopup();
                onCloseStudentPopup();

                loadGuardianStudents(defaultDateRange);
            } else {
                setDeleteStudentPopup((prev) => ({
                    ...prev,
                    isLoading: false,
                    error: res.error,
                }));
            }
        });
    };

    const onOpenNewPasswordPopup = (studentId) => {
        setNewPasswordPopupState({
            isSaving: false,
            isSubmitted: false,
            message: null,
            isOpen: true,
            id: studentId,
        });
    };

    const onResetStudentPassword = (student) => {
        if (student.hasEmail) {
            setResetStudentPasswordPopup({
                isLoading: true,
                isOpen: true,
                message: null,
            });

            api.classes.resetStudentPasswordById({
                session: store.session,
                studentId: student.id,
            }).then((res) => {
                setResetStudentPasswordPopup((prev) => ({
                    ...prev,
                    isLoading: false,
                    message: res.ok ? "Email successfully sent!" : res.error,
                }));
            });
        } else {
            onOpenNewPasswordPopup(student.id);
        }
    };

    const onSelectStudent = (id) => {
        events.guardian.selectStudent({
            session: store.session,
            studentId: id,
        });

        loadStudentInfo(id);

        loadStudentDailyJuices(id);
    };

    const isContentLoaded = () => {
        if (!tableState.isLoaded) {
            return false;
        }

        return true;
    };

    const renderTutorial = () => {
        if (!isContentLoaded()) {
            return null;
        }

        return <Tutorial name="guardian-student" />;
    };

    const renderAddStudentWindow = () => {
        if (!addStudentPopup.state.isOpen) {
            return null;
        }

        const supportLink = [
            settings.landingSite.domain,
            settings.landingSite.routeSupport,
        ].join("");

        return (
            <PopupFullScreenAddStudent
                grades={Grades.getGradesOptions()}
                isMobile={store.dimensions.width < 800}
                noClassLimit
                showShareClassCodeMenu={false}
                supportLink={supportLink}
                importStudentsTemplateUrl={addStudentPopup.state.templateLink}
                onAddStudentsToClass={onAddStudentsToClassButtonClick}
                onClose={onCloseAddStudentPopup}
            />
        );
    };

    const renderDeletePopup = () => {
        if (!deleteStudentPopup.isOpen) {
            return null;
        }

        return (
            <PopupConfirmDeleteStudent
                isLoading={deleteStudentPopup.isLoading}
                error={deleteStudentPopup.error}
                onDelete={removeStudent}
                onClose={onCloseDeletingPopup}
            />
        );
    };

    const renderNewPasswordPopup = () => {
        if (!newPasswordPopupState.isOpen) {
            return null;
        }

        return (
            <PopupNewPassword
                passwordMinLength={settings.password.minLength}
                message={newPasswordPopupState.message}
                isSubmitted={newPasswordPopupState.isSubmitted}
                isSaving={newPasswordPopupState.isSaving}
                onClose={onCloseNewPasswordPopup}
                onSave={onChangeStudentPassword}
            />
        );
    };

    const renderDeleteSelectedUsersPopup = () => {
        if (!deleteSelectedUsersPopup.isOpen) {
            return null;
        }

        return (
            <PopupConfirmDeleteStudent
                students={deleteSelectedUsersPopup.students}
                isLoading={deleteSelectedUsersPopup.isLoading}
                error={deleteSelectedUsersPopup.error}
                onDelete={removeSelectedUsers}
                onClose={onCloseDeleteSelectedUsersPopup}
            />
        );
    };

    const renderAddStudentsProgressPopup = () => {
        if (!progressPopupAddStudents.state.isOpen) {
            return null;
        }

        return (
            <PopupAddStudentsProgress
                data={progressPopupAddStudents.state.students}
                inProgress={progressPopupAddStudents.state.inProgress}
                totalLoaded={progressPopupAddStudents.state.totalLoaded}
                currentStudentErrorCode={progressPopupAddStudents.state.currentLoading.errorCode}
                currentLoadingStudentIndex={progressPopupAddStudents.state.currentLoading.index}
                onAddDuplicate={onAddDuplicate}
                onSkipDuplicate={onSkipDuplicate}
                onClose={onCloseAddStudentProgressPopup}
            />
        );
    };

    const renderResetStudentPopup = () => {
        if (!resetStudentPasswordPopup.isOpen) {
            return null;
        }

        return (
            <PopupResetPassword
                onClose={onCloseResetStudentPopup}
                isLoading={resetStudentPasswordPopup.isLoading}
                message={resetStudentPasswordPopup.message}
            />
        );
    };

    const renderTable = () => {
        return (
            <TableStudents
                dateRange={dateRange}
                calendarDates={settings.calendarDates}
                onChangeDateRange={onChangeDateRange}
                onSelectCustomRange={onSelectCustomRange}
                sortValue={selectedSortByValue}
                data={sortedStudents}
                error={tableState.message}
                onOpenStudentPopup={onOpenStudentPopup}
                onAddStudent={onShowAddStudentPopup}
                onRemoveStudent={onRemoveStudent}
                onResetStudentPassword={onResetStudentPassword}
                onChangeSortValue={(value) => {
                    setSelectedSortByValue(value);
                }}
                onRemoveStudents={(s) => {
                    setDeleteSelectedUsersPopup((prev) => ({
                        ...prev,
                        isOpen: true,
                        students: s,
                    }));
                }}
                hideAddStudentButton={User.isTypeRegular(store.user)}
                isCards={store.dimensions.width < 1200}
                isOverview={tableState.isOverview}
                isLoading={!tableState.isLoaded}
            />
        );
    };

    const renderStudentInfoPopup = () => {
        if (!studentInfoPopup.state.isOpen) {
            return null;
        }

        let isStudentForgotPassword = false;
        let studentData = {};

        const accountId = studentInfoPopup.state?.student?.account?.ID;

        if (accountId) {
            const { studentsWithForgottenPasswords } = store.teacher;

            for (let i = 0; i < studentsWithForgottenPasswords.length; i += 1) {
                if (studentsWithForgottenPasswords[i].id === accountId) {
                    isStudentForgotPassword = true;
                    break;
                }
            }

            studentData = {
                ...studentInfoPopup.state.student,
                isForgotPassword: isStudentForgotPassword,
            };
        }

        return (
            <TeacherPopupStudentInfo
                session={store.session}
                classId={tableState.studentsClassId}
                studentId={studentInfoPopup.state.studentId}
                student={studentData}
                isStudentLoaded={studentInfoPopup.state.isLoaded}
                students={sortedStudents}
                isMobile={store.dimensions.width < 900}
                isCards={store.dimensions.width < 900}
                hideArrows={store.dimensions.width < 500}
                onSelectStudent={onSelectStudent}
                onChangePassword={onAfterChangeStudentPassword}
                onRemoveStudent={() => {
                    onRemoveStudent(studentInfoPopup.state.studentId);
                }}
                onUpdate={() => {
                    loadStudentInfo(studentInfoPopup.state.studentId);

                    const range = {
                        dateFrom: dateRange.startDate,
                        dateTo: dateRange.endDate,
                    };

                    loadGuardianStudents(dateRange.selectedRange, range);
                }}
                onClose={onCloseStudentPopup}
            />
        );
    };

    // TODO: missing class id case
    return (
        <>
            {renderTutorial()}

            {renderStudentInfoPopup()}
            {renderAddStudentWindow()}
            {renderAddStudentsProgressPopup()}

            {renderResetStudentPopup()}
            {renderNewPasswordPopup()}

            {renderDeleteSelectedUsersPopup()}
            {renderDeletePopup()}

            <LayoutContent>
                <div className={styles.index}>
                    {renderTable()}
                </div>
            </LayoutContent>

            <UserFooter />
        </>
    );
};

export default withAuth(["guardian"])(Student);
