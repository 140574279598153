import React, { useState, useEffect, useRef } from "react";

import classNames from "juice-base/lib/class-names.js";

import IconChevron from "juice-base/icons/chevron/index.js";

import styles from "./styles.module.css";


const Accordion = (props) => {
    const contentRef = useRef(null);

    const contentPaddingPx = 15;

    const [contentHeight, setContentHeight] = useState(0);

    /* ------ */

    useEffect(() => {
        let newHeight = "100";

        if (contentRef?.current?.children?.[0]?.scrollHeight) {
            const childrenHeight = contentRef.current.children[0].scrollHeight;

            if (props.withContentPadding) {
                newHeight = `${childrenHeight + (contentPaddingPx * 2)}px`;
            } else {
                newHeight = childrenHeight;
            }
        }

        if (contentHeight !== newHeight) {
            setContentHeight(newHeight);
        }
    }, [
        contentRef?.current,
        props.expanded,
        props.children,
        props.withContentPadding,
    ]);

    /* ------ */

    const renderLabels = () => {
        if (props.label2) {
            return (
                <>
                    <div>{props.label}</div>
                    <div>{props.label2}</div>
                </>
            );
        }

        return <div>{props.label}</div>;
    };

    const renderIcon = () => {
        const caretClassName = classNames({
            [styles.caret]: true,
            [styles.caretDown]: props.expanded && !props.icon,
            [props.iconClassName]: props.iconClassName,
        });

        if (props.icon) {
            return (
                <div className={caretClassName}>
                    {props.icon}
                </div>
            );
        }

        return (
            <IconChevron
                className={caretClassName}
                isBlack
            />
        );
    };

    /* ------ */

    const contentClassName = classNames({
        [styles.content]: true,
        [styles.contentExpanded]: props.expanded,
    });

    const labelClassName = classNames({
        [styles.accordionLabel]: true,
        [styles.accordionLabel2]: props.label2,
        [props.className]: props.className,
    });

    const contentStyle = {};

    if (props.expanded) {
        contentStyle.height = contentHeight;

        if (props.withContentPadding) {
            contentStyle.padding = `${contentPaddingPx}px`;
        }
    }

    return (
        <div className={styles.accordion}>
            <div
                className={labelClassName}
                onClick={props.onClick}
                onKeyPress={props.onClick}
                tabIndex="-1"
                role="button"
            >
                {renderLabels()}
                {renderIcon()}
            </div>
            <div
                ref={contentRef}
                className={contentClassName}
                style={contentStyle}
            >
                <div>{props.children}</div>
            </div>
        </div>
    );
};

Accordion.defaultProps = {
    label: "",
    label2: "",

    className: "",
    iconClassName: "",

    children: null,
    icon: null,

    onClick: () => { },

    withContentPadding: true,
    expanded: false,
};

export default Accordion;
