import Jobs from "juice-base/project/jobs.js";
import text from "juice-base/text/index.js";


const loadProviders = (services, params) => {
    return async (dispatch) => {
        const res = await services.api.lms.providersSummary({
            session: params.session,
        });

        if (res.ok) {
            dispatch(services.actions.lms.setProviders({
                providers: res.data,
            }));
        } else {
            dispatch(services.actions.lms.setProvidersError({
                error: res.error || text.error,
            }));
        }
    };
};

const syncClasses = (services, params, callbacks) => {
    return async (dispatch) => {
        const res = await services.api.lms.syncClasses({
            session: params.session,
            classes: params.classes,
        });

        if (!res.ok || !res.jobId) {
            dispatch(services.actions.lms.setSyncingError({
                error: res.error || text.error,
            }));
            return;
        }

        callbacks.onSuccess(res.jobId);
    };
};

const startSync = (services) => {
    return async (dispatch) => {
        dispatch(services.actions.lms.setSyncingClasses({
            isSyncing: true,
        }));
    };
};

const stopSync = (services) => {
    return async (dispatch) => {
        dispatch(services.actions.lms.setSyncingClasses({
            isSyncing: false,
        }));
    };
};

const checkSyncJob = (services, params, callbacks) => {
    const { api, actions } = services;

    const statuses = Jobs.getJobStatuses();

    return async (dispatch) => {
        const res = await api.jobs.getJobById({
            session: params.session,
            jobId: params.jobId,
        });

        if (!res.ok) {
            dispatch(actions.lms.setSyncingError({
                error: res.error || text.error,
            }));

            callbacks.onStop();
            return;
        }

        if (res.status === statuses.failed) {
            dispatch(actions.lms.setSyncingError({
                error: text.error,
            }));

            callbacks.onStop();
            return;
        }

        if (res.status === statuses.succeeded) {
            callbacks.onSuccess();
        }
    };
};


export default {
    loadProviders,

    syncClasses,
    startSync,
    stopSync,
    checkSyncJob,
};
