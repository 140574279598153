import React from "react";

import IconArrowBold from "juice-base/icons/arrow-bold/index.js";

import styles from "./styles.module.css";


const SectionStudent = (props) => {
    const renderSeeMore = () => {
        if (!props.showSeeMore) {
            return null;
        }

        return (
            <div
                className={styles.seeMore}
                onClick={props.onSeeMoreClick}
                onKeyPress={props.onSeeMoreClick}
                role="button"
                tabIndex="-1"
            >
                <div>
                    See more
                </div>
                <IconArrowBold
                    title="See more"
                    isBlack
                />
            </div>
        );
    };

    const renderTitleBlock = () => {
        if (!props.title) {
            return null;
        }

        return (
            <div className={styles.sectionTitle}>
                {props.title}
            </div>
        );
    };

    const sectionClasses = [
        styles.studentSection,
    ];

    const contentClasses = [
        styles.content,
        props.contentClassName,
    ];

    if (props.sectionClassName) {
        sectionClasses.push(props.sectionClassName);
    }

    if (!props.title) {
        contentClasses.push(styles.contentWithoutTitle);
    }

    return (
        <div
            className={sectionClasses.join(" ")}
            data-comment={props.dataComment}
        >
            <div className={styles.headerSection}>
                {renderTitleBlock()}
                {renderSeeMore()}
            </div>

            <div className={contentClasses.join(" ")}>
                {props.children}
            </div>
        </div>
    );
};

SectionStudent.defaultProps = {
    title: "",
    sectionClassName: "",
    contentClassName: "",
    dataComment: "section-student",
    showSeeMore: true,
    onSeeMoreClick: () => { },
    children: null,
};

export default SectionStudent;
