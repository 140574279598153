import React, { useEffect, useState } from "react";

import classNames from "juice-base/lib/class-names.js";
import PlayerAudio from "juice-base/components/player-audio/index.js";

import styles from "./styles.module.css";


const PopupPlayerAudio = (props) => {
    const [isVisible, setIsVisible] = useState(false);
    const [isPlayerExpanded, setIsPlayerExpanded] = useState(false);

    useEffect(() => {
        window.setTimeout(() => {
            setIsVisible(true);
        }, 100);
    }, []);

    const onExpand = () => {
        setIsPlayerExpanded((prev) => !prev);
    };

    const onClose = () => {
        setIsVisible(false);
        props.onPause();

        window.setTimeout(() => {
            props.onClose();
        }, 100);
    };

    const popupClassName = classNames({
        [styles.popup]: true,
        [styles.popupVisible]: isVisible && !props.withBottomIndent,
        [styles.popupVisibleWithBottomIndent]: isVisible && props.withBottomIndent,
    });

    return (
        <div className={popupClassName}>
            <PlayerAudio
                image={props.image}
                title={props.title}
                category={props.category}
                audio={props.audio}
                isExpanded={isPlayerExpanded}
                onFirstPlay={props.onFirstPlay}
                onPlay={props.onPlay}
                onPause={props.onPause}
                onRewind={props.onRewind}
                onForward={props.onForward}
                onChangeRate={props.onChangeRate}
                onExpand={onExpand}
                onClose={onClose}
            />
        </div>
    );
};

PopupPlayerAudio.defaultProps = {
    image: "",
    title: "",
    category: "",
    audio: {},
    withBottomIndent: true,
    onFirstPlay: () => {},
    onPlay: () => {},
    onPause: () => {},
    onRewind: () => {},
    onForward: () => {},
    onChangeRate: () => {},
    onClose: () => {},
};

export default PopupPlayerAudio;
