// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Q5TDa1rFXrouPEhxHieZ {\n    position: relative;\n}\n\n.ZA1mSt3bHQfRsUwgDlsE {\n    position: absolute;\n    z-index: 1;\n}\n\n.tFZMdccdouwWimHTamK5 {\n    top: 3.5rem;\n    transform: translate(-50%);\n}\n\n.jOCiHjnoo5MvJXu3ui9a {\n    top: 3.5rem;\n    transform: translate(-100%);\n}\n\n.qlZbZirQuiwEAgyta2mj {\n    top: -1.5rem;\n    left: 3rem;\n}\n", "",{"version":3,"sources":["webpack://./base/components/info-tooltip/styles.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,UAAU;AACd;;AAEA;IACI,WAAW;IACX,0BAA0B;AAC9B;;AAEA;IACI,WAAW;IACX,2BAA2B;AAC/B;;AAEA;IACI,YAAY;IACZ,UAAU;AACd","sourcesContent":[".infoTooltip {\n    position: relative;\n}\n\n.tooltip {\n    position: absolute;\n    z-index: 1;\n}\n\n.tooltipBottomCenter {\n    top: 3.5rem;\n    transform: translate(-50%);\n}\n\n.tooltipBottomLeft {\n    top: 3.5rem;\n    transform: translate(-100%);\n}\n\n.tooltipRightCenter {\n    top: -1.5rem;\n    left: 3rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"infoTooltip": "Q5TDa1rFXrouPEhxHieZ",
	"tooltip": "ZA1mSt3bHQfRsUwgDlsE",
	"tooltipBottomCenter": "tFZMdccdouwWimHTamK5",
	"tooltipBottomLeft": "jOCiHjnoo5MvJXu3ui9a",
	"tooltipRightCenter": "qlZbZirQuiwEAgyta2mj"
};
export default ___CSS_LOADER_EXPORT___;
