import { useState, useCallback } from "react";


const useDrag = () => {
    const [clicked, setClicked] = useState(false);
    const [dragging, setDragging] = useState(false);
    const [position, setPosition] = useState(0);
    const [diff, setDiff] = useState(0);

    const dragStart = useCallback((evt) => {
        setPosition(evt.clientX);
        setDiff(0);
        setClicked(true);
    }, []);

    const dragStop = useCallback(() => {
        window.requestAnimationFrame(() => {
            setDragging(false);
            setClicked(false);
        });
    }, []);

    const dragMove = useCallback((evt, cb) => {
        const newDiff = position - evt.clientX;

        const movedEnough = Math.abs(newDiff) > 5;

        if (clicked && movedEnough) {
            setDragging(true);
        }

        if (dragging && movedEnough) {
            setPosition(evt.clientX);
            setDiff(newDiff);
            cb(newDiff);
        }
    }, [clicked, dragging, position]);

    return {
        dragStart,
        dragStop,
        dragMove,
        diff,
        dragging,
        position,
        setDragging,
        setDiff,
        setPosition,
    };
};

export default useDrag;
