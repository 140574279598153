import React from "react";

import Assignments from "juice-base/project/assignments.js";

import IconNotepadCheck from "juice-base/icons/notepad-check/index.js";
import IconNotepadX from "juice-base/icons/notepad-x/index.js";
import IconNotepadExclamation from "juice-base/icons/notepad-exclamation/index.js";


const AssignmentStatus = (props) => {
    const renderIcon = () => {
        const { status } = props;

        if (Assignments.isCorrectAssignmentStatus(status)) {
            return (
                <IconNotepadCheck
                    isGreen
                    title={props.correctTitle || "Correct"}
                />
            );
        }

        if (Assignments.isIncorrectAssignmentStatus(status)) {
            return (
                <IconNotepadX
                    isRed
                    title={props.incorrectTitle || "Incorrect"}
                />
            );
        }

        if (Assignments.isPendingAssignmentStatus(status)) {
            return (
                <IconNotepadExclamation
                    isSky
                    title={props.pendingTitle || "Pending"}
                />
            );
        }

        return null;
    };

    return (
        <div className={props.className}>
            {renderIcon()}
        </div>
    );
};

AssignmentStatus.defaultProps = {
    className: "",
    status: "",
    correctTitle: "",
    incorrectTitle: "",
    pendingTitle: "",
};

export default AssignmentStatus;
