import React from "react";

import UserCouponCodeForm from "juice-base/forms/user-coupon-code/index.js";

import RequestLoader from "juice-base/components/request-loader/index.js";

import CouponAttached from "juice-base/business/coupon-attached/index.js";


const Coupon = (props) => {
    if (props.isSubmitted && !props.error) {
        if (props.isLoading) {
            return (
                <RequestLoader />
            );
        }

        return (
            <CouponAttached
                couponCode={props.couponCode}
                discountMessage={props.discountMessage}
            />
        );
    }

    return (
        <UserCouponCodeForm
            isLoading={props.isLoading}
            isSubmitted={props.isSubmitted}
            couponCode={props.couponCode}
            discountMessage={props.discountMessage}
            error={props.error}
            initialValues={{
                couponCode: props.couponCode,
            }}
            onChange={props.onChange}
            onApply={props.onApply}
            onSubmit={props.onSubmit}
        />
    );
};

Coupon.defaultProps = {
    isLoading: false,
    isSubmitted: false,
    couponCode: "",
    discountMessage: "",
    error: "",
    onChange: () => {},
    onApply: () => {},
    onSubmit: () => {},
};

export default Coupon;
