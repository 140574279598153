import { debugEvent } from "juice-base/lib/debug.js";


export default (methods, ids) => {
    return {
        onSearch(params) {
            const event = {
                session: params.session,
                locationId: ids.location.search,
                eventId: ids.event.onSearch,
            };

            debugEvent("Search", event);
            methods.sendEvent(event);
        },

        storyClick(params) {
            const event = {
                session: params.session,
                locationId: ids.location.search,
                eventId: ids.event.searchStoryClick,
                objectId: params.storyId,
            };

            debugEvent("Click on found story", event);
            methods.sendEvent(event);
        },

        filterClick(params) {
            const event = {
                session: params.session,
                locationId: ids.location.search,
                eventId: ids.event.searchFilterClick,
            };

            debugEvent("Click on search filter", event);
            methods.sendEvent(event);
        },
    };
};
