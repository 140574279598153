import React, { useState } from "react";

import Standards from "juice-base/project/standards.js";

import classNames from "juice-base/lib/class-names.js";

import text from "juice-base/text/index.js";

import IconQuestion from "juice-base/icons/question/index.js";

import SectionTeacher, {
    SectionTeacherHeaderWithPages,
    SectionTeacherContentWithPages,
} from "juice-base/components/section-teacher/index.js";
import RequestLoader from "juice-base/components/request-loader/index.js";
import ChartRadar from "juice-base/components/chart-radar/index.js";
import Message from "juice-base/components/message/index.js";
import IconClickable from "juice-base/components/icon-clickable/index.js";
import Switch from "juice-base/components/switch/index.js";

import StandardsShape from "juice-base/business/standards-shape/index.js";

import styles from "./styles.module.css";


const TeacherLearningStandards = (props) => {
    const [currentPage, setCurrentPage] = useState(0);

    const [chartRadarStateByClassId, setChartRadarStateByClassId] = useState({});

    /* ----- */

    const onGoToPrevPage = () => {
        setCurrentPage((prev) => prev - 1);
    };

    const onGoToNextPage = () => {
        setCurrentPage((prev) => prev + 1);
    };

    const onToggleChartRadarTotalQuestions = (id) => {
        setChartRadarStateByClassId((prev) => {
            const prevStateById = prev?.[id] || {};
            const isVisible = prevStateById?.isTotalQuestionsVisible || false;

            return {
                ...prev,
                [id]: {
                    ...prevStateById,
                    isTotalQuestionsVisible: !isVisible,
                },
            };
        });
    };

    /* ----- */

    const getStandardName = (standards) => {
        const standard = Standards.getStandardByType(standards, props.selectedStandardType);

        if (standard?.standard) {
            return standard.standard;
        }

        return "";
    };

    /* ----- */

    const renderNoData = () => {
        return (
            <Message>
                {text.noData}
            </Message>
        );
    };

    const renderChartRadarLabel = (groupName, standardName, currentLabelsCount, totalLabels) => {
        const standardsShape = StandardsShape({
            standardGroup: groupName,
            isStringFormat: true,
        });

        const labelClassName = classNames({
            [styles.chartRadarLabelReversed]: currentLabelsCount > (totalLabels / 2),
        });

        const fullStandardName = standardName || "";

        const sName = fullStandardName.length > 11
            ? `${fullStandardName.slice(0, 11)}...`
            : fullStandardName;

        const label = `
            <div class="${styles.chartRadarLabel}">
                ${standardsShape}
                <div class="${labelClassName}">
                    ${sName}
                </div>
            </div>
        `;

        return {
            label,
            tooltip: fullStandardName,
        };
    };

    const renderChartLegendElements = (elemOnly) => {
        return ["Total", "Answered", "Correct"].map((name, i) => {
            const legendChartClassName = classNames({
                [styles.legendChart]: true,
                [styles.legendChart1]: i === 0,
                [styles.legendChart2]: i === 1,
                [styles.legendChart3]: i === 2,
            });

            const elem = (
                <div className={legendChartClassName}>
                    <div />
                    <div />
                </div>
            );

            if (elemOnly) {
                return elem;
            }

            return (
                <div className={styles.legendRow}>
                    {elem}
                    <div>
                        {name}
                    </div>
                </div>
            );
        });
    };

    const renderChartRadarControls = (data) => {
        const chartId = data?.classId;

        if (!chartId || data?.standard?.length === 0) {
            return <div />;
        }

        const isSwitchChecked = chartRadarStateByClassId?.[chartId]?.isTotalQuestionsVisible
            || false;

        const totalControl = (
            <div className={styles.control}>
                <div className={styles.controlLegendElement}>
                    {renderChartLegendElements(true)[0]}
                </div>
                <div>
                    Show Total Questions
                </div>
                <div className={styles.controlSwitch}>
                    <Switch
                        name={`toggle-chart-radar-total-questions-${chartId}`}
                        onChange={() => {
                            onToggleChartRadarTotalQuestions(chartId);
                        }}
                        checked={isSwitchChecked}
                    />
                </div>
            </div>
        );

        return (
            <div className={styles.controls}>
                {totalControl}
            </div>
        );
    };

    const renderChartRadar = (d) => {
        const isTotalVisible = chartRadarStateByClassId?.[d.classId]?.isTotalQuestionsVisible
            || false;

        const maxQuizzes = isTotalVisible
            ? d.maxTotalQuizzesByStandard
            : d.maxAnsweredQuizzesForAllStudents;

        const data = {
            labels: [],
            shapes: [[], [], []],
        };

        let totalLabels = 0;

        for (let i = 0; i < d.standards.length; i += 1) {
            totalLabels += d.standards[i].standards.length;
        }

        for (let i = 0; i < d.standards.length; i += 1) {
            const standard = d.standards[i];

            for (let j = 0; j < standard.standards.length; j += 1) {
                const group = standard.standards[j];

                const currentLabelsCount = data.labels.length;
                const standardName = getStandardName(group.standards);

                const chartLabel = renderChartRadarLabel(
                    standard.anchorStandardGroup,
                    standardName,
                    currentLabelsCount,
                    totalLabels,
                );

                data.labels.push({
                    label: chartLabel.label,
                    tooltip: chartLabel.tooltip,
                });

                if (isTotalVisible) {
                    data.shapes[0].push({
                        value: ((group.totalQuizzesByStandard * 100) / maxQuizzes) || 0,
                    });
                }

                data.shapes[1].push({
                    value: ((group.totalAnswered * 100) / maxQuizzes) || 0,
                    tooltip: d.totalAnsweredQuizzes === 1 && group.totalAnswered === 1
                        ? "Only one question answered"
                        : null,
                });

                data.shapes[2].push({
                    value: ((group.totalCorrect * 100) / maxQuizzes) || 0,
                });
            }
        }

        const numberOfSides = data.labels.length;
        const numberOfLevel = maxQuizzes < 9
            ? maxQuizzes
            : 9;

        const ticks = [];

        for (let i = 0; i < numberOfLevel + 1; i += 1) {
            const tick = Math.floor((maxQuizzes / numberOfLevel) * i);

            ticks.push({
                tick: `${tick}`,
                tooltip: i === 0 && d.totalAnsweredQuizzes === 0
                    ? "No questions answered"
                    : null,
                isVisible: i > 0,
            });
        }

        return (
            <ChartRadar
                data={data}
                ticks={ticks}
                numberOfSides={numberOfSides}
                numberOfLevel={numberOfLevel || 2}
                isMobile={props.isMobile}
            />
        );
    };

    const renderBlock = (d) => {
        if (d?.standards?.length === 0) {
            return renderNoData();
        }

        return (
            <div className={styles.blockContent}>
                {renderChartRadarControls(d)}

                <div className={styles.blockContentChart}>
                    {renderChartRadar(d)}
                </div>
            </div>
        );
    };

    const renderPages = () => {
        const pages = props.data.map((d) => {
            return renderBlock(d);
        });

        return pages;
    };

    const renderLegend = () => {
        const groups = Standards.getAnchorStandardsGroups().map((group) => (
            <div className={styles.legendRow}>
                <StandardsShape
                    standardGroup={group}
                />
                <div>
                    {group}
                </div>
            </div>
        ));

        const legendClassName = classNames({
            [styles.legend]: true,
            [styles.legendDesktop]: !props.isMobile,
        });

        const legendChartClassName = classNames({
            [styles.legend]: true,
            [styles.legendDesktop]: !props.isMobile,
            [styles.chartLegendDesktop]: !props.isMobile,
        });

        return (
            <div className={styles.legends}>
                <div className={legendClassName}>
                    {groups}
                </div>
                <div className={legendChartClassName}>
                    {renderChartLegendElements(false)}
                </div>
            </div>
        );
    };

    const renderMobileContent = () => {
        const label = props.labels?.[currentPage] || "";

        if (props.data.length === 0) {
            return renderNoData();
        }

        return (
            <>
                <SectionTeacherHeaderWithPages
                    label={label}
                    currentPage={currentPage}
                    totalPages={props.data.length}
                    onLeftClick={onGoToPrevPage}
                    onRightClick={onGoToNextPage}
                    onInfoClick={props.onInfoClick}
                />
                <SectionTeacherContentWithPages
                    currentPage={currentPage}
                    pages={renderPages()}
                />
            </>
        );
    };

    const renderDesktopContent = () => {
        const rowColumns = 3;
        const lastRowColumnsCount = (props?.data?.length || 0) % rowColumns;

        if (props.data.length === 0) {
            return renderNoData();
        }

        const blocksByClasses = props.data.map((d, i) => {
            const blockClassName = classNames({
                [styles.blockByClass]: true,
                [styles.blockByClassBorderRight]: (i + 1) % rowColumns !== 0,
                [styles.blockByClassNoBorderBottom]:
                    i > (props.data.length - lastRowColumnsCount - 1),
            });

            const blockLabelClassName = classNames({
                [styles.blockByClassLabel]: true,
            });

            return (
                <div className={blockClassName}>
                    <div className={blockLabelClassName}>
                        {props.labels?.[i] || ""}

                        <IconClickable onClick={props.onInfoClick}>
                            <IconQuestion
                                isBlack
                            />
                        </IconClickable>
                    </div>
                    {renderBlock(d)}
                </div>
            );
        });

        return (
            <div className={styles.contentDesktop}>
                {blocksByClasses}
            </div>
        );
    };

    const renderContent = () => {
        if (props.isLoading) {
            return (
                <RequestLoader />
            );
        }

        if (props.isMobile) {
            return (
                <>
                    {renderMobileContent()}
                    {renderLegend()}
                </>
            );
        }

        return (
            <>
                {renderDesktopContent()}
                {renderLegend()}
            </>
        );
    };

    const sectionClassName = classNames({
        [styles.sectionDesktop]: !props.isMobile,
    });

    return (
        <SectionTeacher className={sectionClassName}>
            {renderContent()}
        </SectionTeacher>
    );
};

TeacherLearningStandards.defaultProps = {
    labels: [],
    data: [],
    selectedStandardType: "",
    onInfoClick: () => { },
    isLoading: false,
    isMobile: false,
};

export default TeacherLearningStandards;
