import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import studentAssignmentsActions from "juice-base/actions/student-assignments.js";
import actions from "juice-base/store/actions.js";

import LayoutContent from "juice-base/components/layout-content/index.js";

import AssignmentsTodoList from "juice-base/business/assignments-todo-list/index.js";
import AssignmentsCompleted from "juice-base/business/assignments-completed/index.js";

import api from "juice-app/api.js";

import styles from "./styles.module.css";


const storeSelector = (state) => ({
    dimensions: state.device.dimensions,
    session: state.user.session,
    user: state.user.user,
    playerState: state.player.playerState,

    assignmentsByGroupCode: state.assignments.assignmentsByGroupCode,

    completedAssignmentsByPage: state.studentAssignments.completedAssignmentsByPage,
    incompleteAssignmentsByPage: state.studentAssignments.incompleteAssignmentsByPage,
});

const Assignment = () => {
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const store = useSelector(storeSelector);

    /* ------- */

    const onGoToAssignment = (assignment, storyId) => {
        const { groupCode } = assignment;

        if (!groupCode || !storyId) {
            return;
        }

        navigate(`/assignments/${groupCode}/${storyId}`);
    };

    /* ------- */

    const loadCompletedAssignments = async () => {
        dispatch(studentAssignmentsActions.loadCompletedAssignments(
            { api, actions },
            { page: store.completedAssignmentsByPage?.page || 0 },
        ));
    };

    const loadIncompleteAssignments = async () => {
        dispatch(studentAssignmentsActions.loadIncompleteAssignments(
            { api, actions },
            { page: store.incompleteAssignmentsByPage?.page || 0 },
        ));
    };

    /* ------- */

    useEffect(() => {
        loadCompletedAssignments();
        loadIncompleteAssignments();

        return () => {
            dispatch(actions.studentAssignments.clearCompletedAssignments());
            dispatch(actions.studentAssignments.clearIncompleteAssignments());
        };
    }, []);

    /* ------- */

    const renderTodoList = () => {
        return (
            <AssignmentsTodoList
                data={store.incompleteAssignmentsByPage?.data || []}
                error={store.incompleteAssignmentsByPage?.error || ""}
                onAssignmentClick={onGoToAssignment}
                onLoadMore={loadIncompleteAssignments}
                hasMore={store.incompleteAssignmentsByPage?.hasMore || false}
                isLoading={store.incompleteAssignmentsByPage?.isLoading || false}
            />
        );
    };

    const renderCompletedAssignments = () => {
        return (
            <AssignmentsCompleted
                data={store.completedAssignmentsByPage?.data || []}
                error={store.completedAssignmentsByPage?.error || ""}
                onAssignmentClick={onGoToAssignment}
                onLoadMore={loadCompletedAssignments}
                hasMore={store.completedAssignmentsByPage?.hasMore || false}
                isLoading={store.completedAssignmentsByPage?.isLoading || false}
            />
        );
    };

    return (
        <LayoutContent>
            <div className={styles.content}>
                {renderTodoList()}
                {renderCompletedAssignments()}
            </div>
        </LayoutContent>
    );
};

export default Assignment;
