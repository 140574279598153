import React from "react";

import IconEmojiHappy from "juice-base/icons/emoji-happy/index.js";

import LinkBlockWithImage from "juice-base/components/link-block-with-image/index.js";
import RequestLoader from "juice-base/components/request-loader/index.js";
import ButtonFlat from "juice-base/components/button-flat/index.js";

import styles from "./styles.module.css";


const DailyJuiceTodoList = (props) => {
    const renderEmoji = () => {
        return (
            <div className={styles.noTodo}>
                <div className={styles.emojisHappy}>
                    <IconEmojiHappy
                        className={styles.emojisHappyIcon}
                        title="Emoji Happy"
                        isBlack
                    />
                </div>
                <div className={styles.noTodoText}>
                    <div>
                        You&apos;ve finished
                    </div>
                    <div>
                        All your daily juice!
                    </div>
                </div>
            </div>
        );
    };

    const renderTodoList = () => {
        const {
            data,
            showLoadMore,
            isLoading,
        } = props;

        if (data.length === 0 && !showLoadMore && !isLoading) {
            return renderEmoji();
        }

        const list = data.map((todo) => (
            <LinkBlockWithImage
                imgUrl={todo.imgUrl}
                date={todo.date}
                title={todo.title}
                url={todo.juiceUrl}
            />
        ));

        if (isLoading) {
            list.push(
                <RequestLoader />,
            );
        }

        if (showLoadMore && !isLoading) {
            list.push(
                <ButtonFlat
                    onClick={props.onLoadMore}
                    uppercase={false}
                >
                    Load More
                </ButtonFlat>,
            );
        }

        return list;
    };

    return (
        <div
            className={styles.content}
            data-comment="section-daily-juices-todo-list"
        >
            <div className={styles.header}>
                To-Do List
            </div>
            {renderTodoList()}
        </div>
    );
};

DailyJuiceTodoList.defaultProps = {
    data: [],

    onLoadMore: () => { },

    isLoading: false,
    showLoadMore: false,
};

export default DailyJuiceTodoList;
