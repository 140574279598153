import React, { useRef, useEffect, useState } from "react";

import DailyJuice from "juice-base/project/daily-juice.js";
import Standards from "juice-base/project/standards.js";

import timer from "juice-base/lib/timer.js";

import IconLamp from "juice-base/icons/lamp/index.js";
import IconStandards from "juice-base/icons/standards/index.js";

import Text from "juice-base/components/text/index.js";
import QuizQuestionForm from "juice-base/forms/quiz-question/index.js";
import QuizQuestionAnswers from "juice-base/business/quiz-question-answers/index.js";

import PopupLearningStandards from "juice-base/business/popup-learning-standards/index.js";

import styles from "./styles.module.css";


const QuizQuestion = (props) => {
    const [isVisibleStandardsPopup, setIsVisibleStandardsPopup] = useState(false);

    const { question } = props;

    const quizTimer = useRef(null);

    useEffect(() => {
        if (props.isVisible) {
            quizTimer.current = timer.startTimer();
        } else {
            quizTimer.current = null;
        }
    }, [props.isVisible]);

    const getStandard = () => {
        const type = props.defaultStandardType
            ? props.defaultStandardType
            : "juice-standard";

        const juiceStandards = Standards.getStandardsByType(props.standards, type);

        if (juiceStandards.length === 0) {
            return null;
        }

        return juiceStandards[0]?.standard;
    };

    const onStandardsClick = () => {
        setIsVisibleStandardsPopup(true);
    };

    const onSubmit = ({ answer }, formMethods) => {
        const diffSeconds = quizTimer?.current?.getDiffSeconds() || 0;

        const isCorrect = DailyJuice.isValidQuiz(question);

        props.onSubmit({
            quizId: question.quizId,
            questionId: question.id,
            answerId: parseInt(answer, 10),
            timeTook: diffSeconds,
            isCorrect,
        }, formMethods);
    };

    const renderStandardsPopup = () => {
        if (!isVisibleStandardsPopup) {
            return null;
        }

        return (
            <PopupLearningStandards
                hideScrollbar={props.hideScrollbarOnStandardsPopup}
                showStateStandard={props.showStateStandard}
                isMobile={props.isMobile}
                defaultStandardType={props.defaultStandardType}
                standards={props.standards}
                onClose={() => {
                    setIsVisibleStandardsPopup(false);
                }}
            />
        );
    };

    const renderContent = () => {
        let content = null;

        const isAnswered = DailyJuice.isAnsweredQuiz(question);

        if (isAnswered || props.showAnswers) {
            const answers = (question.answers || []).map((ans) => {
                let isUserChoice = ans.is_user_choice;

                if (props.showAnswers) {
                    isUserChoice = true;
                }

                return {
                    ...ans,
                    isUserChoice,
                    isCorrect: ans.is_correct_answer,
                };
            });

            content = (
                <QuizQuestionAnswers
                    quizId={question.quizId}
                    answers={answers}
                />
            );
        } else {
            const initialValues = {
                answer: null,
            };

            if (props.allowAnswering) {
                content = (
                    <QuizQuestionForm
                        questionId={question.id}
                        answers={question.answers || []}
                        initialValues={initialValues}
                        allowAnswering={props.allowAnswering}
                        onSelectAnswer={(answerId) => {
                            props.onSelectAnswer({
                                quizId: question.quizId,
                                answerId,
                            });
                        }}
                        onSubmit={onSubmit}
                    />
                );
            } else {
                content = (
                    <QuizQuestionAnswers
                        quizId={question.quizId}
                        answers={question.answers || []}
                    />
                );
            }
        }

        return content;
    };

    const renderQuestionWithStandards = () => {
        let standardsButtonMobile = null;
        let standardsButton = null;

        if (props.standards.length > 0) {
            if (props.isMobile) {
                standardsButtonMobile = (
                    <div
                        className={styles.questionButton}
                        onClick={onStandardsClick}
                        onKeyPress={onStandardsClick}
                        role="button"
                        tabIndex="-1"
                    >
                        <IconStandards
                            title="Show Standards"
                            isRed
                        />
                    </div>
                );
            } else {
                const standard = getStandard();

                if (standard) {
                    standardsButton = (
                        <div
                            className={styles.standard}
                            onClick={onStandardsClick}
                            onKeyPress={onStandardsClick}
                            role="button"
                            tabIndex="-1"
                        >
                            {standard}
                        </div>
                    );
                }
            }
        }

        return (
            <div className={styles.questionSectionWithStandard}>
                <div className={styles.questionSectionQuestion}>
                    <Text className={styles.question}>
                        {question.question}
                    </Text>
                    {standardsButton}
                </div>
                {standardsButtonMobile}
            </div>
        );
    };

    const renderQuestionWithFeedback = () => {
        const tipsButton = (
            <div
                className={styles.questionButton}
                onClick={props.onTipsClick}
                onKeyPress={props.onTipsClick}
                role="button"
                tabIndex="-1"
            >
                <IconLamp
                    title="Show Tips and Tricks"
                    isRed
                />
            </div>
        );

        return (
            <div className={styles.questionSectionWithTips}>
                <div className={styles.questionSectionQuestionWithTips}>
                    <Text className={styles.question}>
                        {question.question}
                    </Text>
                    {tipsButton}
                </div>
            </div>
        );
    };

    const renderQuestion = () => {
        if (props.showStandards) {
            return renderQuestionWithStandards();
        }

        if (props.showFeedback) {
            return renderQuestionWithFeedback();
        }

        return (
            <div className={styles.questionSection}>
                <div className={styles.questionSectionQuestion}>
                    <Text className={styles.question}>
                        {question.question}
                    </Text>
                </div>
            </div>
        );
    };

    if (!question) {
        return null;
    }

    return (
        <>
            {renderStandardsPopup()}

            <div className={styles.quizQuestion} data-comment={props.dataComment}>
                <div className={styles.header}>
                    Question
                </div>

                {renderQuestion()}

                {renderContent()}
            </div>
        </>
    );
};

QuizQuestion.defaultProps = {
    question: null,
    standards: [],
    defaultStandardType: null,
    dataComment: "",

    showStateStandard: false,
    showStandards: false,
    showFeedback: false,
    hideScrollbarOnStandardsPopup: true,

    allowAnswering: true,
    showAnswers: false,
    isMobile: false,

    onTipsClick: () => { },
    onSelectAnswer: () => { },
    onSubmit: () => { },
};

export default QuizQuestion;
