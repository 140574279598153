import React from "react";

import IconWrapper from "juice-base/icons/wrapper/index.js";


const IconLamp = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    className={iconProps.className}
                    width="23"
                    height="22"
                    viewBox="0 0 23 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    {iconProps.title}
                    <path
                        d="M6 20H10C10 21.1 9.1 22 8 22C6.9 22 6 21.1 6 20ZM4 19H12V17H4V19ZM15.5 9.5C15.5 13.32 12.84 15.36 11.73 16H4.27C3.16 15.36 0.5 13.32 0.5 9.5C0.5 5.36 3.86 2 8 2C12.14 2 15.5 5.36 15.5 9.5ZM20.37 7.37L19 8L20.37 8.63L21 10L21.63 8.63L23 8L21.63 7.37L21 6L20.37 7.37ZM18 6L18.94 3.94L21 3L18.94 2.06L18 0L17.06 2.06L15 3L17.06 3.94L18 6Z"
                        fill="currentColor"
                    />
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconLamp;
