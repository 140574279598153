// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".efXLaBn91qoSVl4O1JH_ {}\n\n/* --- */\n\n.mGzRcDXKsl1qmGilWObl {\n    padding: 2rem 2rem 1rem 2rem;\n}\n\n.pki117cFTGxOXIs0FrVS {\n    padding: 1rem;\n}\n\n@media only screen and (min-width: 620px) {\n    .mGzRcDXKsl1qmGilWObl,\n    .pki117cFTGxOXIs0FrVS {\n        padding-left: 0;\n        padding-right: 0;\n    }\n}\n", "",{"version":3,"sources":["webpack://./base/business/daily-juice-story-poll/styles.module.css"],"names":[],"mappings":"AAAA,uBAAa;;AAEb,QAAQ;;AAER;IACI,4BAA4B;AAChC;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI;;QAEI,eAAe;QACf,gBAAgB;IACpB;AACJ","sourcesContent":[".juiceStory {}\n\n/* --- */\n\n.category {\n    padding: 2rem 2rem 1rem 2rem;\n}\n\n.content {\n    padding: 1rem;\n}\n\n@media only screen and (min-width: 620px) {\n    .category,\n    .content {\n        padding-left: 0;\n        padding-right: 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"juiceStory": "efXLaBn91qoSVl4O1JH_",
	"category": "mGzRcDXKsl1qmGilWObl",
	"content": "pki117cFTGxOXIs0FrVS"
};
export default ___CSS_LOADER_EXPORT___;
