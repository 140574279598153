import React from "react";

import useTitle from "juice-base/hooks/use-title/index.js";

import { withAuth } from "juice-base/components/auth/index.js";

import Assignments from "juice-app/containers/assignments/index.js";


const ShowAssignment = () => {
    useTitle(() => "Assignments", []);

    return (
        <Assignments />
    );
};

export default withAuth(["student"])(ShowAssignment);
