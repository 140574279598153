import React from "react";

import { Link } from "react-router-dom";

import SectionStudent from "juice-base/components/section-student/index.js";

import styles from "./styles.module.css";


const AccountSettings = (props) => {
    const renderSettings = () => {
        const ret = [
            <div className={styles.title}>
                Settings
            </div>,
        ];

        props.settings.forEach((setting) => {
            ret.push(
                <div className={styles.oneSetting}>
                    <div className={styles.settingTitle}>
                        {setting.title}
                    </div>
                    <div className={styles.settingValue}>
                        {setting.value}
                    </div>
                </div>,
            );
        });

        return (ret);
    };

    const renderChangePassword = () => {
        if (props.isLMS) {
            return null;
        }

        return (
            <div className={styles.changePassword}>
                <Link
                    to="/user/change-password"
                >
                    Change password
                </Link>
            </div>
        );
    };

    return (
        <SectionStudent
            title=" account"
            showSeeMore={false}
            contentClassName={styles.sectionContent}
        >
            {renderSettings()}
            {renderChangePassword()}
        </SectionStudent>
    );
};


AccountSettings.defaultProps = {
    settings: [],
    isLMS: false,
};

export default AccountSettings;
