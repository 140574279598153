// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ehN5EWC0KtRSh8irk62c {\n    width: 100%;\n    max-width: 135rem;\n\n    justify-self: center;\n}\n\n.Hz1h8nOyy9Ecn3Sk9Nmv {\n    display: grid;\n    justify-content: start;\n}\n\n.QheQLBFXxey9BF5gHs_L {\n    width: 100%;\n}\n\n.kefl91zVGI9BpBECevjl {\n    color: var(--error-color);\n    text-align: center;\n}\n", "",{"version":3,"sources":["webpack://./app/views/student/styles.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,iBAAiB;;IAEjB,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;AACtB","sourcesContent":[".index {\n    width: 100%;\n    max-width: 135rem;\n\n    justify-self: center;\n}\n\n.studentsList {\n    display: grid;\n    justify-content: start;\n}\n\n.table {\n    width: 100%;\n}\n\n.errorMessage {\n    color: var(--error-color);\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"index": "ehN5EWC0KtRSh8irk62c",
	"studentsList": "Hz1h8nOyy9Ecn3Sk9Nmv",
	"table": "QheQLBFXxey9BF5gHs_L",
	"errorMessage": "kefl91zVGI9BpBECevjl"
};
export default ___CSS_LOADER_EXPORT___;
