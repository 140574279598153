import React from "react";

import IconCheck from "juice-base/icons/check/index.js";
import IconPencil from "juice-base/icons/pencil/index.js";

import IconClickable from "juice-base/components/icon-clickable/index.js";
import Text from "juice-base/components/text/index.js";
import RequestLoader from "juice-base/components/request-loader/index.js";

import SectionTeacher, {
    SectionTeacherHeader,
    SectionTeacherExplanation,
    SectionTeacherMessage,
} from "juice-base/components/section-teacher/index.js";

import classAnnouncementStyles from "juice-base/components/class-announcement/styles.module.css";
import styles from "./styles.module.css";


const TeacherClassAnnouncement = (props) => {
    const renderRightControls = () => {
        const headerEditButton = (
            <IconClickable
                className={styles.editButton}
                onClick={props.onEdit}
            >
                <IconPencil isOrange />
            </IconClickable>
        );

        const explanationMark = (
            <SectionTeacherExplanation alignLeft>
                Write a custom message your students will see every day in the Daily Juice
            </SectionTeacherExplanation>
        );

        return [headerEditButton, explanationMark];
    };

    const renderNote = () => {
        if (!props.content) {
            return null;
        }

        return (
            <div className={styles.note}>
                <IconCheck isGreen />
                <div>
                    Message will display to students every day this week
                </div>
            </div>
        );
    };

    const renderAnnouncement = () => {
        if (!props.content) {
            return (
                <SectionTeacherMessage>
                    No content for this class.
                </SectionTeacherMessage>
            );
        }

        return (
            <Text className={classAnnouncementStyles.content}>
                {props.content}
            </Text>
        );
    };

    const renderContent = () => {
        if (props.isLoading) {
            return (
                <RequestLoader />
            );
        }

        return (
            <div className={styles.content}>
                {renderAnnouncement()}
                {renderNote()}
            </div>
        );
    };

    return (
        <SectionTeacher dataComment="daily-juice-class-announcement">
            <SectionTeacherHeader
                label="Class Announcement"
                rightControls={renderRightControls()}
            />
            {renderContent()}
        </SectionTeacher>
    );
};

TeacherClassAnnouncement.defaultProps = {
    content: null,
    isLoading: false,
    onEdit: () => { },
};

export default TeacherClassAnnouncement;
