import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import date from "juice-base/lib/date.js";
import text from "juice-base/text/index.js";

import IconScoresHorizontal from "juice-base/icons/scores-horizontal/index.js";

import pollsActions from "juice-base/actions/polls.js";
import actions from "juice-base/store/actions.js";

import PopupWindow from "juice-base/components/popup-window/index.js";
import PollSection from "juice-base/components/poll-section/index.js";
import Tiles from "juice-base/components/tiles/index.js";
import ButtonRounded from "juice-base/components/button-rounded/index.js";
import RequestLoader from "juice-base/components/request-loader/index.js";
import Message from "juice-base/components/message/index.js";

import api from "juice-app/api.js";

import styles from "./styles.module.css";


const storeSelector = (state) => ({
    session: state.user.session,
    polls: state.polls,
});

const UserPopupPolls = (props) => {
    const dispatch = useDispatch();
    const store = useSelector(storeSelector);

    /* ------ */

    const onLoadClosedPolls = (params = {}) => {
        dispatch(pollsActions.loadClosedPolls({
            api,
            actions,
        }, {
            session: store.session,
            page: params.page,
        }));
    };

    const onLoadMore = () => {
        onLoadClosedPolls({
            page: store.polls.page,
        });
    };

    /* ------ */

    useEffect(() => {
        if (store.polls.isClosedPollsLoaded) {
            return;
        }

        onLoadClosedPolls({
            page: 0,
        });
    }, []);

    /* ------ */

    const renderLoadMoreButton = () => {
        const { hasMorePages, isClosedPollsLoading } = store.polls;

        if (isClosedPollsLoading) {
            return (
                <RequestLoader />
            );
        }

        if (!hasMorePages) {
            return null;
        }

        return (
            <div className={styles.loadMoreButton}>
                <ButtonRounded
                    uppercase
                    onClick={onLoadMore}
                >
                    Load More
                </ButtonRounded>
            </div>
        );
    };

    const renderPoll = (poll) => {
        return (
            <div className={styles.poll}>
                <div className={styles.pollDate}>
                    {date.tryFormatDateUTC(poll.pollStart, date.formatMonthDayYearDate)}
                </div>
                <PollSection
                    isDisabled
                    isVisibleMessage={false}
                    isOutdated={poll.isOutdated}
                    selectedAnswer={poll.userAnswerId}
                    question={poll.question}
                    answers={poll.answers}
                />
            </div>
        );
    };

    const renderContent = () => {
        const {
            isClosedPollsLoaded,
            isClosedPollsLoading,
            closedPolls,
        } = store.polls;

        if (!isClosedPollsLoaded && isClosedPollsLoading) {
            return (
                <RequestLoader />
            );
        }

        if (closedPolls.length === 0) {
            return (
                <Message>
                    {text.noClosedPolls}
                </Message>
            );
        }

        const pollsByMonths = [];

        for (let i = 0; i < closedPolls.length; i += 1) {
            const poll = closedPolls[i];
            const pollDate = date.getYearMonthFromDate(date.newDateUTC(poll.pollStart));

            const index = pollsByMonths.map((c) => c.date).indexOf(pollDate);

            if (index !== -1) {
                pollsByMonths[index].polls.push(poll);
            } else {
                pollsByMonths.push({
                    date: pollDate,
                    polls: [poll],
                });
            }
        }

        const sections = pollsByMonths.map((data) => {
            const polls = data.polls.map((poll) => renderPoll(poll));
            const pollDate = date.tryFormatDateUTC(data.date, date.formatMonthYearDate);

            return (
                <div className={styles.section}>
                    <div className={styles.date}>
                        {pollDate}
                    </div>
                    <div className={styles.polls}>
                        <Tiles>
                            {polls}
                        </Tiles>
                    </div>
                </div>
            );
        });

        return (
            <div>
                {sections}
                {renderLoadMoreButton()}
            </div>
        );
    };

    const renderLeftControl = () => {
        return (
            <div className={styles.icon}>
                <IconScoresHorizontal
                    className={styles.iconSvg}
                    title="Polls"
                    isOrange
                />
            </div>
        );
    };

    return (
        <PopupWindow
            title="Polls"
            withTitleBarShadow={false}
            titleToStart
            leftControl={renderLeftControl()}
            onClose={props.onClose}
        >
            {renderContent()}
        </PopupWindow>
    );
};

UserPopupPolls.defaultProps = {
    onClose: () => { },
};

export default UserPopupPolls;
