import * as globalTypes from "juice-base/store-common/types.js";
import * as types from "./types.js";


const initialState = {
    isSponsorLoaded: false,
    sponsor: {},

    isAnnouncementLoaded: false,
    announcement: {},

    isQuizResultsLoaded: false,
    quizResults: [],
};

export default (state, action) => {
    switch (action.type) {
        case types.SET_STUDENT_SPONSOR: {
            return {
                ...state,
                isSponsorLoaded: true,
                sponsor: action.payload.sponsor || {},
            };
        }

        case types.SET_STUDENT_ANNOUNCEMENT: {
            return {
                ...state,
                isAnnouncementLoaded: true,
                announcement: action.payload.announcement || {},
            };
        }

        case types.SET_STUDENT_QUIZ_RESULTS: {
            return {
                ...state,
                isQuizResultsLoaded: true,
                quizResults: action.payload.quizResults || [],
            };
        }

        /* --- */

        case globalTypes.CLEAR_AFTER_SIGNOUT: {
            return { ...initialState };
        }

        /* --- */

        default: {
            return state || { ...initialState };
        }
    }
};
