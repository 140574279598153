import React from "react";

import IconWrapper from "juice-base/icons/wrapper/index.js";


const IconHome = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={iconProps.className}
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        fill="currentColor"
                        d="M3.47974 16.7215L16.255 5.22379L29.0303 16.7215H25.1977V26.9417H17.5325V19.2766H14.9775V26.9417H7.31231V16.7215H3.47974ZM22.643 14.4094L16.2554 8.66054L9.86773 14.4094V24.3869H12.4228V16.7217H20.0879V24.3869H22.643V14.4094Z"
                    />
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconHome;
