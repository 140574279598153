// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SjlqlEN9l1UEhQM5zxpJ {\n    padding: 2rem;\n\n    background-color: var(--background-secondary-color);\n\n    border: 0.1rem solid var(--border-default-color);\n    border-radius: 1rem;\n}\n\n.rOEy4W8QONDEujowXSRT {\n    margin-bottom: 2rem;\n\n    color: var(--color-1);\n    font-size: 2rem;\n    font-weight: 600;\n}\n\n.B5K_qzP4yuL6vxKVNvob {\n    margin-top: 2.5rem;\n    display: grid;\n}\n", "",{"version":3,"sources":["webpack://./base/forms/user-password-change/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;;IAEb,mDAAmD;;IAEnD,gDAAgD;IAChD,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;;IAEnB,qBAAqB;IACrB,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,aAAa;AACjB","sourcesContent":[".form {\n    padding: 2rem;\n\n    background-color: var(--background-secondary-color);\n\n    border: 0.1rem solid var(--border-default-color);\n    border-radius: 1rem;\n}\n\n.header {\n    margin-bottom: 2rem;\n\n    color: var(--color-1);\n    font-size: 2rem;\n    font-weight: 600;\n}\n\n.submitButtonBlock {\n    margin-top: 2.5rem;\n    display: grid;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "SjlqlEN9l1UEhQM5zxpJ",
	"header": "rOEy4W8QONDEujowXSRT",
	"submitButtonBlock": "B5K_qzP4yuL6vxKVNvob"
};
export default ___CSS_LOADER_EXPORT___;
