import Standards from "juice-base/project/standards.js";

import date from "juice-base/lib/date.js";


const ASSIGNMENTS_SORT_VALUES = {
    dateNewestToOldest: "date: newest to oldest",
    dateOldestToNewest: "date: oldest to newest",

    scoreHighToLow: "score: high to low",
    scoreLowToHigh: "score: low to high",

    gradeHighToLow: "grade: high to low",
    gradeLowToHigh: "grade: low to high",

    standardHighToLow: "standard: high to low",
    standardLowToHigh: "standard: low to high",
};

const ASSIGNMENTS_STATUSES = {
    correct: "correct",
    incorrect: "incorrect",
    pending: "pending",
};

/* ------ */

const isCorrectAssignmentStatus = (status) => {
    return status === ASSIGNMENTS_STATUSES.correct;
};

const isIncorrectAssignmentStatus = (status) => {
    return status === ASSIGNMENTS_STATUSES.incorrect;
};

const isPendingAssignmentStatus = (status) => {
    return status === ASSIGNMENTS_STATUSES.pending;
};

/* ------ */

const getCorrectAssignmentStatus = () => {
    return ASSIGNMENTS_STATUSES.correct;
};

const getIncorrectAssignmentStatus = () => {
    return ASSIGNMENTS_STATUSES.incorrect;
};

/* ------ */

const getSortValues = () => {
    return ASSIGNMENTS_SORT_VALUES;
};

/* ------ */

const onSortByDate = (data, value) => {
    const sorted = [...data].sort((a, b) => {
        const d1 = date.newDate(a.date);
        const d2 = date.newDate(b.date);

        if (value === ASSIGNMENTS_SORT_VALUES.dateNewestToOldest) {
            return d1.getTime() - d2.getTime();
        }

        return d2.getTime() - d1.getTime();
    });

    return sorted;
};

const onSortByScore = (data, value) => {
    const sorted = [...data].sort((a, b) => {
        const s1 = a.score;
        const s2 = b.score;

        if (value === ASSIGNMENTS_SORT_VALUES.scoreHighToLow) {
            return s1 - s2;
        }

        return s2 - s1;
    });

    return sorted;
};

const onSortByGrades = (data, value) => {
    let gradesOrder = [["G5", "G6"], ["G7", "G8"], ["G9", "G10"], ["G11", "G12"]];

    if (value === ASSIGNMENTS_SORT_VALUES.gradeLowToHigh) {
        gradesOrder = [...gradesOrder].reverse();
    }

    const sorted = [];

    for (let i = 0; i < gradesOrder.length; i += 1) {
        const grades = gradesOrder[i];

        for (let j = 0; j < data.length; j += 1) {
            const grade = data[j].grades;

            for (let k = 0; k < grade.length; k += 1) {
                const g = grade[k];

                if (grades.indexOf(g) !== -1) {
                    sorted.push(data[j]);
                    break;
                }
            }
        }
    }

    return sorted;
};

const onSortByStandards = (data, value) => {
    const sorted = [...data].sort((a, b) => {
        const aJuiceStandard = Standards.getJuiceStandard(a.standards)?.[0]?.standard || null;
        const bJuiceStandard = Standards.getJuiceStandard(b.standards)?.[0]?.standard || null;

        let s1 = 0;
        let s2 = 0;

        if (aJuiceStandard) {
            s1 = parseInt(aJuiceStandard.split(".")[2], 10) || 0;
        }

        if (bJuiceStandard) {
            s2 = parseInt(bJuiceStandard.split(".")[2], 10) || 0;
        }

        if (value === ASSIGNMENTS_SORT_VALUES.standardHighToLow) {
            return s1 - s2;
        }

        return s2 - s1;
    });

    return sorted;
};

const sort = (data, value) => {
    const {
        dateNewestToOldest,
        dateOldestToNewest,

        scoreHighToLow,
        scoreLowToHigh,

        gradeHighToLow,
        gradeLowToHigh,

        standardHighToLow,
        standardLowToHigh,
    } = ASSIGNMENTS_SORT_VALUES;

    if (value === dateNewestToOldest || value === dateOldestToNewest) {
        return onSortByDate(data, value);
    }

    if (value === scoreHighToLow || value === scoreLowToHigh) {
        return onSortByScore(data, value);
    }

    if (value === gradeHighToLow || value === gradeLowToHigh) {
        return onSortByGrades(data, value);
    }

    if (value === standardHighToLow || value === standardLowToHigh) {
        return onSortByStandards(data, value);
    }

    return data;
};

/* ------ */

const getStoryById = (stories, id) => {
    for (let i = 0; i < stories.length; i += 1) {
        const story = stories[i];

        if (story.ID === id) {
            return story;
        }
    }

    return null;
};

/* ------ */

export default {
    isCorrectAssignmentStatus,
    isIncorrectAssignmentStatus,
    isPendingAssignmentStatus,

    getCorrectAssignmentStatus,
    getIncorrectAssignmentStatus,

    getSortValues,

    sort,
    onSortByDate,
    onSortByScore,
    onSortByGrades,
    onSortByStandards,

    getStoryById,
};
