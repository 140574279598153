import text from "juice-base/text/index.js";


const loadCompletedAssignments = (services, params) => {
    const { api, actions } = services;

    return async (dispatch, getState) => {
        const state = getState();

        dispatch(actions.studentAssignments.setCompletedAssignmentsByPageLoading());

        const res = await api.students.getStudentCompletedAssignments({
            session: state.user.session,
            page: params.page,
        });

        let data = [];
        let error = "";
        let hasMore = false;

        if (res.ok) {
            data = res.assignments || [];
            hasMore = res.hasMore || false;
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.studentAssignments.setCompletedAssignmentsByPage({
            data,
            error,
            hasMore,
        }));
    };
};

const loadIncompleteAssignments = (services, params) => {
    const { api, actions } = services;

    return async (dispatch, getState) => {
        const state = getState();

        dispatch(actions.studentAssignments.setIncompleteAssignmentsByPageLoading());

        const res = await api.students.getStudentIncompleteAssignments({
            session: state.user.session,
            page: params.page,
        });

        let data = [];
        let error = "";
        let hasMore = false;

        if (res.ok) {
            data = res.assignments || [];
            hasMore = res.hasMore || false;
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.studentAssignments.setIncompleteAssignmentsByPage({
            data,
            error,
            hasMore,
        }));
    };
};

const loadAssignments = (services, params) => {
    const { api, actions } = services;

    return async (dispatch, getState) => {
        const state = getState();

        dispatch(actions.studentAssignments.setAssignmentsByPageLoading());

        const res = await api.students.getStudentAssignmentsByPage({
            session: state.user.session,
            page: params.page,
        });

        let data = [];
        let error = "";
        let hasMore = false;

        if (res.ok) {
            data = res.assignments || [];
            hasMore = res.hasMore || false;
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.studentAssignments.setAssignmentsByPage({
            data,
            error,
            hasMore,
        }));
    };
};

export default {
    loadCompletedAssignments,
    loadIncompleteAssignments,
    loadAssignments,
};
