import React, { useState } from "react";

import text from "juice-base/text/index.js";

import BlockProgress, {
    BlockProgressLayout,
    BlockProgressLegend,
} from "juice-base/components/block-progress/index.js";
import SectionTeacher, {
    SectionTeacherHeader,
} from "juice-base/components/section-teacher/index.js";
import Accordion from "juice-base/components/accordion/index.js";
import RequestLoader from "juice-base/components/request-loader/index.js";
import Message from "juice-base/components/message/index.js";

import styles from "./styles.module.css";


const TeacherAverageScores = (props) => {
    const [isAverageScoresExpanded, setIsAverageScoresExpanded] = useState(() => {
        if (props.isBySchoolExpandedByDefault) {
            return true;
        }

        return false;
    });

    const renderAllClassesScores = () => {
        let totalPercents = 0;

        const allData = props?.data?.all || {};
        const dataKeys = Object.keys(allData);

        dataKeys.forEach((key) => {
            totalPercents += props.data.all[key];
        });

        if (totalPercents === 0) {
            return (
                <Message>
                    {text.noData}
                </Message>
            );
        }

        return (
            <BlockProgressLayout>
                <BlockProgress
                    data={[props.data.all]}
                    isAverageScore
                />
            </BlockProgressLayout>
        );
    };

    const renderDataByClasses = () => {
        if (props.data.classes.length === 0) {
            return (
                <Message>
                    {text.noDataBySchools}
                </Message>
            );
        }

        return (
            <BlockProgressLayout>
                <BlockProgress
                    data={props.data.classes}
                    isAverageScore
                />
            </BlockProgressLayout>
        );
    };

    const renderContent = () => {
        if (props.isLoading) {
            return (
                <RequestLoader />
            );
        }

        return (
            <div>
                <div className={styles.averageScoresAll}>
                    All
                </div>

                {renderAllClassesScores()}

                <div className={styles.averageScoresBySchool}>
                    <Accordion
                        className={styles.averageScoresBySchoolLabel}
                        label="By School"
                        onClick={() => {
                            setIsAverageScoresExpanded((prev) => !prev);
                        }}
                        expanded={isAverageScoresExpanded}
                        withContentPadding={false}
                    >
                        {renderDataByClasses()}
                    </Accordion>
                </div>

                <div className={styles.legend}>
                    <BlockProgressLegend
                        names={["Average"]}
                        isAverageScore
                    />
                </div>
            </div>
        );
    };

    return (
        <SectionTeacher>
            <SectionTeacherHeader label="Average Scores" />
            {renderContent()}
        </SectionTeacher>
    );
};

TeacherAverageScores.defaultProps = {
    data: null,
    isBySchoolExpandedByDefault: true,
    isLoading: false,
};

export default TeacherAverageScores;
