// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QsZAcd_kgJAjGMEfUpFq {\n    color: var(--link-color);\n    text-decoration: none;\n    font-weight: 600;\n\n    border-bottom: 0.2rem solid var(--link-color);\n\n    cursor: pointer;\n    outline: none;\n}\n\n.QsZAcd_kgJAjGMEfUpFq:visited {\n    color: var(--link-color-visited);\n    border-color: var(--link-color-visited);\n}\n", "",{"version":3,"sources":["webpack://./base/components/link/styles.module.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,qBAAqB;IACrB,gBAAgB;;IAEhB,6CAA6C;;IAE7C,eAAe;IACf,aAAa;AACjB;;AAEA;IACI,gCAAgC;IAChC,uCAAuC;AAC3C","sourcesContent":[".link {\n    color: var(--link-color);\n    text-decoration: none;\n    font-weight: 600;\n\n    border-bottom: 0.2rem solid var(--link-color);\n\n    cursor: pointer;\n    outline: none;\n}\n\n.link:visited {\n    color: var(--link-color-visited);\n    border-color: var(--link-color-visited);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": "QsZAcd_kgJAjGMEfUpFq"
};
export default ___CSS_LOADER_EXPORT___;
