import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import actions from "juice-base/store/actions.js";
import storage from "juice-base/lib/storage/index.js";
import userActions from "juice-base/actions/user.js";

import text from "juice-base/text/index.js";

import User from "juice-base/project/user.js";

import useTitle from "juice-base/hooks/use-title/index.js";

import LayoutContent from "juice-base/components/layout-content/index.js";
import RequestLoader from "juice-base/components/request-loader/index.js";
import LogoRound from "juice-base/components/logo-round/index.js";
import WizardMultiStep from "juice-base/components/wizard-multi-step/index.js";
import Footer from "juice-base/components/footer/index.js";

import UserSignUpClassCodeForm from "juice-base/forms/user-sign-up-class-code/index.js";
import UserSignUpPersonalInfoForm from "juice-base/forms/user-sign-up-personal-info/index.js";
import UserSignUpPasswordForm from "juice-base/forms/user-sign-up-password/index.js";
import UserSignInForm from "juice-base/forms/user-sign-in/index.js";

import PopupConfirmClassCode from "juice-base/business/popup-confirm-class-code/index.js";
import PopupConfirmSignUp from "juice-base/business/popup-confirm-sign-up/index.js";

import settings from "juice-app/settings.js";
import api from "juice-app/api.js";
import events from "juice-app/events.js";

import styles from "./styles.module.css";


const SignUp = () => {
    const params = useParams();
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const [isCodeLoaded, setIsCodeLoaded] = useState(false);
    const [codeInitialError, setCodeInitialError] = useState("");

    const [currentStep, setCurrentStep] = useState(0);

    const [classCode, setClassCode] = useState(params.code || "");
    const [classTeacher, setClassTeacher] = useState({});
    const [personalInfo, setPersonalInfo] = useState({
        username: "",
        password: "",
        firstName: "",
        lastName: "",
    });

    const [isVisibleClassCodePopup, setIsVisibleClassCodePopup] = useState(false);
    const [isVisibleSignUpPopup, setIsVisibleSignUpPopup] = useState(false);

    useTitle(() => "Sign Up", []);

    /* --- */

    const isTrialCode = () => {
        return classTeacher.signUpType === "trial";
    };

    /* --- */

    const onGoToStep = (newStepIndex) => {
        setCurrentStep(newStepIndex);
    };

    const onGoToNextStep = () => {
        setCurrentStep((prev) => prev + 1);
    };

    /* --- */

    const onOpenClassCodePopup = () => {
        setIsVisibleClassCodePopup(true);
    };

    const onCloseClassCodePopup = () => {
        setIsVisibleClassCodePopup(false);
    };

    /* --- */

    const onOpenSignUpPopup = () => {
        setIsVisibleSignUpPopup(true);
    };

    const onCloseSignUpPopup = () => {
        setIsVisibleSignUpPopup(false);
    };

    /* --- */

    const onCodeSubmit = (values, { setSubmitting, setErrors }) => {
        api.signup.validateClassCode({
            code: values.code,
        }).then((res) => {
            if (res.ok) {
                setClassCode(values.code);
                setClassTeacher(res.data || {});

                onOpenClassCodePopup();
            } else {
                setErrors({
                    code: res.error || "Invalid code",
                });
            }

            setSubmitting(false);
        });
    };

    const onPersonalInfoSubmit = (values, { setSubmitting, setErrors }) => {
        api.signup.postPersonalInfo({
            ...values,
            code: classCode,
        }).then((res) => {
            if (res.ok && res.data.username) {
                setPersonalInfo((prev) => ({
                    ...prev,
                    username: res.data.username,
                    firstName: values.firstName,
                    lastName: values.lastName,
                }));
                onGoToNextStep();
            } else {
                setErrors({
                    formError: res.error || "Error!",
                });
            }

            setSubmitting(false);
        });
    };

    const onPasswordSubmit = (values, { setSubmitting, setErrors }) => {
        api.signup.postPassword({
            username: personalInfo.username,
            password: values.newPassword,
        }).then((res) => {
            if (res.ok) {
                if (!isTrialCode()) {
                    onOpenSignUpPopup();
                }

                setPersonalInfo((prev) => ({
                    ...prev,
                    password: values.newPassword,
                }));

                onGoToNextStep();
            } else {
                setErrors({
                    password: res.error || "Error!",
                });
            }

            setSubmitting(false);
        });
    };

    const onSignInSubmit = async (values, { setSubmitting, setErrors }) => {
        const res = await api.user.signIn(values);

        if (!res.ok) {
            setErrors({
                username: res.error || text.error,
            });
            setSubmitting(false);
            return;
        }

        events.user.signIn({
            session: res.sessionId,
        });

        if (values.isRememberMe) {
            storage.local.saveSession(res.sessionId ?? "");
            storage.session.saveSession("");
        } else {
            storage.local.saveSession("");
            storage.session.saveSession(res.sessionId ?? "");
        }

        dispatch(actions.user.setUserSession(res.sessionId || ""));
        dispatch(actions.user.setUser(res.user));

        const userId = res?.user?.userId || "";

        if (userId) {
            dispatch(userActions.saveUserIdAndReport({ storage, api }, {
                userId,
            }));
        }

        navigate("/");
    };

    /* --- */

    useEffect(() => {
        setClassCode(params.code || "");

        if (!params.code) {
            setIsCodeLoaded(true);
            return;
        }

        api.signup.validateClassCode({
            code: params.code,
        }).then((res) => {
            if (res.ok) {
                setClassTeacher(res.data || {});
            } else {
                setCodeInitialError(res.error || "Error!");
            }

            setIsCodeLoaded(true);
        });
    }, [params.code]);

    /* --- */

    const renderClassCodePopup = () => {
        const teacherFullName = User.getFullName({
            firstName: classTeacher.createdByFirstName,
            lastName: classTeacher.reatedByLastName,
        });

        return (
            <PopupConfirmClassCode
                teacherFullName={teacherFullName}
                message={classTeacher.customSignUpMessage}
                onContinue={() => {
                    onCloseClassCodePopup();
                    onGoToNextStep();
                }}
                onClose={onCloseClassCodePopup}
            />
        );
    };

    const renderSignUpPopup = () => {
        return (
            <PopupConfirmSignUp
                onClose={onCloseSignUpPopup}
            />
        );
    };

    const renderPopups = () => {
        const ps = [];

        if (isVisibleClassCodePopup) {
            ps.push(renderClassCodePopup());
        }

        if (isVisibleSignUpPopup) {
            ps.push(renderSignUpPopup());
        }

        return ps;
    };

    /* --- */

    const renderEnterClassCode = () => {
        const initialValues = {
            code: classCode,
        };

        const initialErrors = {
            code: codeInitialError,
        };

        return (
            <>
                <div className={styles.contentHeaderName}>
                    Enter Class Code
                </div>

                <div className={styles.contentWelcome}>
                    Welcome to The Juice!
                    Enter your class code to get started.
                </div>

                <UserSignUpClassCodeForm
                    initialValues={initialValues}
                    initialErrors={initialErrors}
                    onSubmit={onCodeSubmit}
                />

                <div className={styles.links}>
                    <Link to="/" className={styles.link}>
                        Already have an account? Sign in
                    </Link>
                </div>
            </>
        );
    };

    const renderPersonalInfo = () => {
        const initialValues = {
            firstName: "",
            lastName: "",
            email: "",
        };

        let headerName = "";

        if (classTeacher.customSignUpMessage) {
            headerName = classTeacher.customSignUpMessage;
        } else {
            const teacherFullName = User.getFullName({
                firstName: classTeacher.createdByFirstName,
                lastName: classTeacher.reatedByLastName,
            });

            headerName = `
                Welcome to ${teacherFullName}'s class on The Juice!
            `;
        }

        return (
            <>
                <div className={styles.contentHeaderName}>
                    <p className={styles.contentHeaderNameLine}>
                        {headerName}
                    </p>
                    <p className={styles.contentHeaderNameLine}>
                        Enter your personal information to get started.
                    </p>
                </div>

                <UserSignUpPersonalInfoForm
                    initialValues={initialValues}
                    isEmailRequired={isTrialCode()}
                    onSubmit={onPersonalInfoSubmit}
                />
            </>
        );
    };

    const renderUsernameInfo = () => {
        const initialValues = {
            newPassword: "",
        };

        const { firstName, lastName } = personalInfo;
        const fullName = firstName || lastName;
        const headerName = `Thanks for signing up, ${fullName}!`;

        return (
            <>
                <div className={styles.contentHeaderName}>
                    <p className={styles.contentHeaderNameLine}>
                        {headerName}
                    </p>
                    <p className={styles.contentHeaderNameLine}>
                        Your Username is:
                    </p>
                </div>

                <div className={styles.contentUsername}>
                    {personalInfo.username}
                </div>

                <div className={styles.contentHeaderName}>
                    Set your password:
                </div>

                <div className={styles.form}>
                    <UserSignUpPasswordForm
                        initialValues={initialValues}
                        passwordMinLength={settings.password.minLength}
                        onSubmit={onPasswordSubmit}
                    />
                </div>
            </>
        );
    };

    const renderSignIn = () => {
        if (!personalInfo.username || !personalInfo.password) {
            return null;
        }

        const initialValues = {
            username: personalInfo.username,
            password: personalInfo.password,
            isRememberMe: true,
        };

        return (
            <>
                <div className={styles.contentHeaderName}>
                    Sign In
                </div>

                <UserSignInForm
                    initialValues={initialValues}
                    onPasswordForgot={() => { }}
                    onSubmit={onSignInSubmit}
                />
            </>
        );
    };

    if (!isCodeLoaded) {
        return (
            <RequestLoader />
        );
    }

    const hasValidCode = params.code && !codeInitialError;

    const wizardSteps = [];

    if (!hasValidCode) {
        wizardSteps.push({ name: "Enter Class code", content: renderEnterClassCode() });
    }

    wizardSteps.push({ name: "Enter Info", content: renderPersonalInfo() });
    wizardSteps.push({ name: "Get Username", content: renderUsernameInfo() });
    wizardSteps.push({ name: "Sign In", content: renderSignIn() });

    return (
        <>
            {renderPopups()}

            <LayoutContent>
                <div className={styles.content}>
                    <div className={styles.contentHeader}>
                        <LogoRound />
                    </div>

                    <WizardMultiStep
                        step={currentStep}
                        steps={wizardSteps}
                        onStepChange={onGoToStep}
                        disablePrevSteps
                    />

                    <Footer
                        copyright={settings.copyright}
                    />
                </div>
            </LayoutContent>
        </>
    );
};

export default SignUp;
