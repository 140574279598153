import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import device from "juice-base/lib/device.js";
import scroll from "juice-base/lib/scroll.js";

import assignmentsActions from "juice-base/actions/assignments.js";
import actions from "juice-base/store/actions.js";

import DailyJuice from "juice-base/project/daily-juice.js";
import Polls from "juice-base/project/polls.js";

import AudioManagerContext from "juice-base/context/audio-manager/index.js";

import LayoutContent from "juice-base/components/layout-content/index.js";
import RequestLoader from "juice-base/components/request-loader/index.js";
import Tiles from "juice-base/components/tiles/index.js";
import Message from "juice-base/components/message/index.js";
import PopupPlayerAudio from "juice-base/components/popup-player-audio/index.js";

import PopupDailyJuiceStatesLegend from "juice-base/business/popup-daily-juice-states-legend/index.js";
import DailyJuiceStoryCard from "juice-base/business/daily-juice-story-card/index.js";
import DailyJuiceStoryPollCard from "juice-base/business/daily-juice-story-poll-card/index.js";

import api from "juice-app/api.js";

import UserPopupPolls from "juice-app/containers/user-popup-polls/index.js";

import styles from "./styles.module.css";


const storeSelector = (state) => ({
    dimensions: state.device.dimensions,
    session: state.user.session,
    user: state.user.user,
    playerState: state.player.playerState,

    assignmentsByGroupCode: state.assignments.assignmentsByGroupCode,
});

const Assignment = () => {
    const [isVisibleUserPollsPopup, setVisibleUserPollsPopup] = useState(false);
    const [isVisibleIconsLegendPopup, setIsVisibleIconsLegendPopup] = useState(false);

    const [audioPlayerState, setAudioPlayerState] = useState({
        storyId: null,
    });

    const audioManager = useContext(AudioManagerContext);

    const urlParams = useParams();
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const store = useSelector(storeSelector);

    const isMobile = store.dimensions.width <= 1024;

    /* ------- */

    const getData = () => {
        let stories = [];
        let error = "";

        const data = store.assignmentsByGroupCode[urlParams.groupCode] || {};

        if (data?.data?.stories) {
            stories = data.data.stories;
        }

        if (data?.error) {
            error = data.error;
        }

        return {
            stories,
            error,
        };
    };

    /* ------- */

    const onGoToStory = (storyId) => {
        scroll.scrollToTop();
        navigate(`/assignments/${urlParams.groupCode}/${storyId}`);
    };

    /* ------- */

    const onStoryReadMore = (storyId) => {
        onGoToStory(storyId);
    };

    /* ------- */

    const onOpenUserPollsPopup = () => {
        setVisibleUserPollsPopup(true);
    };

    const onCloseUserPollsPopup = () => {
        setVisibleUserPollsPopup(false);
    };

    /* ------- */

    const onOpenAudioPlayer = (storyId) => {
        if (!audioPlayerState.storyId) {
            setAudioPlayerState((prev) => ({
                ...prev,
                storyId,
            }));

            audioManager.loadStory(storyId);
        } else {
            setAudioPlayerState((prev) => ({
                ...prev,
                storyId: null,
            }));

            window.setTimeout(() => {
                setAudioPlayerState((prev) => ({
                    ...prev,
                    storyId,
                }));

                audioManager.loadStory(storyId);
            }, 300);
        }
    };

    const onCloseAudioPlayer = () => {
        setAudioPlayerState((prev) => ({
            ...prev,
            storyId: null,
        }));
    };

    /* ------- */

    const onLoadAssignmentStories = async () => {
        dispatch(assignmentsActions.getAssignmentStories(
            { api, actions },
            {
                session: store.session,
                groupCode: urlParams.groupCode,
            },
        ));
    };

    /* ------- */

    useEffect(() => {
        onLoadAssignmentStories();
    }, []);

    /* ------- */

    const renderStory = (story, index) => {
        const storyId = story.ID;

        const poll = Polls.getStoryPoll(story);

        if (poll) {
            return (
                <DailyJuiceStoryPollCard
                    story={story}
                    storyIndex={index}
                    poll={poll}
                    onShowAllPolls={onOpenUserPollsPopup}
                    onReadMore={() => {
                        onStoryReadMore(storyId);
                    }}
                    // TODO: with assignments
                    // onSubmitPoll={(values) => {
                    //     onSubmitPollAnswer({
                    //         ...values,
                    //         storyId,
                    //     });
                    // }}
                />
            );
        }

        let videoCaptionUrl = "";

        if (story.featuredVideo?.url) {
            videoCaptionUrl = api.videos.getVideoCaptionURL({
                session: store.session,
                id: story.featuredVideo.id,
            });
        }

        return (
            <DailyJuiceStoryCard
                story={story}
                storyIndex={index}
                isDefaultVideo={!device.isChrome}
                videoCaptionUrl={videoCaptionUrl}
                onAudioPlay={() => {
                    onOpenAudioPlayer(storyId);
                }}
                onReadMore={() => {
                    onStoryReadMore(storyId);
                }}
                withQuizStatus={false}
            />
        );
    };

    const renderStories = (stories) => {
        return stories.map((s, i) => {
            return renderStory(s, i);
        });
    };

    const renderUserPollsPopup = () => {
        return (
            <UserPopupPolls
                onClose={onCloseUserPollsPopup}
            />
        );
    };

    const renderLegendPopup = () => {
        return (
            <PopupDailyJuiceStatesLegend
                onClose={() => {
                    setIsVisibleIconsLegendPopup(false);
                }}
            />
        );
    };

    const renderPlayerAudioPopup = (stories) => {
        const story = DailyJuice.getStoryById(stories, audioPlayerState.storyId);

        if (!story) {
            return null;
        }

        const storyId = story.ID;

        let img = null;

        if (story.featuredImage && story.featuredImage.url) {
            img = story.featuredImage.url;
        }

        const trackGroupName = "juiceStories";
        const trackId = storyId;
        const audioData = store.playerState?.[trackGroupName]?.[trackId];

        return (
            <PopupPlayerAudio
                key={`player-audio-story-${story.ID}`}
                image={img}
                title={story.title}
                category={story.categoryName}
                audio={audioData}
                onPlay={() => {
                    audioManager.play(trackGroupName, trackId);
                }}
                onPause={() => {
                    audioManager.pause(trackGroupName, trackId);
                }}
                onRewind={() => {
                    audioManager.rewind(trackGroupName, trackId);
                }}
                onForward={() => {
                    audioManager.forward(trackGroupName, trackId);
                }}
                onChangeRate={(rate) => {
                    audioManager.changeRate(trackGroupName, trackId, rate);
                }}
                onClose={() => {
                    audioManager.stop(trackGroupName, trackId);
                    onCloseAudioPlayer();
                }}
            />
        );
    };

    const renderPopups = (stories) => {
        const ps = [];

        if (audioPlayerState.storyId) {
            ps.push(renderPlayerAudioPopup(stories));
        }

        if (isVisibleIconsLegendPopup) {
            ps.push(renderLegendPopup());
        }

        if (isVisibleUserPollsPopup) {
            ps.push(renderUserPollsPopup());
        }

        return ps;
    };

    const { stories, error } = getData();

    if (error) {
        return (
            <Message>
                {error}
            </Message>
        );
    }

    if (stories.length === 0) {
        return (
            <RequestLoader />
        );
    }

    return (
        <>
            {renderPopups(stories)}

            <LayoutContent>
                <div className={styles.assignment}>
                    <Tiles isMobile={isMobile}>
                        {renderStories(stories)}
                    </Tiles>
                </div>
            </LayoutContent>
        </>
    );
};

export default Assignment;
