
const SORT = {
    nameATOZ: "name: a to z",
    nameZTOA: "name: z to a",
};

const getSortValues = () => {
    return [
        { value: SORT.nameATOZ, label: "Name: A to Z" },
        { value: SORT.nameZTOA, label: "Name: Z to A" },
    ];
};

const getSortValueBySort = (sort) => {
    if (!sort) {
        return SORT.nameATOZ;
    }

    if (sort === SORT.nameATOZ) {
        return SORT.nameZTOA;
    }

    if (sort === SORT.nameZTOA) {
        return SORT.nameATOZ;
    }

    return SORT.nameATOZ;
};

/* ----- */

const isSortByNameZTOA = (sortBy) => {
    if (sortBy === SORT.nameZTOA) {
        return true;
    }

    return false;
};

/* ----- */

const sortTeachersByNameATOZ = (data) => {
    return [...data].sort((a, b) => a.lastName.localeCompare(b.lastName));
};

const sortTeachersByNameZTOA = (data) => {
    return [...data].sort((a, b) => b.lastName.localeCompare(a.lastName));
};

const sortTeachers = (sortBy, data) => {
    if (sortBy === SORT.nameATOZ) {
        return sortTeachersByNameATOZ(data);
    }

    if (sortBy === SORT.nameZTOA) {
        return sortTeachersByNameZTOA(data);
    }

    return data;
};

/* ----- */

export default {
    getSortValues,
    getSortValueBySort,

    isSortByNameZTOA,

    sortTeachers,
    sortTeachersByNameATOZ,
    sortTeachersByNameZTOA,
};
