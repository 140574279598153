// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".H5LDPX5xuY0EXg6yweVN {\n    display: grid;\n}\n\n.m4uCvRGYVRpymgYxJuU5 {\n    padding: 1.5rem 2rem;\n}\n\n.s6TmlQeqkhi85JIxq6Ad {\n    padding: 1.5rem 2rem;\n    border-top: solid 0.1rem var(--grey-light);\n}\n", "",{"version":3,"sources":["webpack://./base/business/daily-juice-polls/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,oBAAoB;IACpB,0CAA0C;AAC9C","sourcesContent":[".sectionContainer {\n    display: grid;\n}\n\n.section {\n    padding: 1.5rem 2rem;\n}\n\n.viewMoreButton {\n    padding: 1.5rem 2rem;\n    border-top: solid 0.1rem var(--grey-light);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sectionContainer": "H5LDPX5xuY0EXg6yweVN",
	"section": "m4uCvRGYVRpymgYxJuU5",
	"viewMoreButton": "s6TmlQeqkhi85JIxq6Ad"
};
export default ___CSS_LOADER_EXPORT___;
