import React from "react";

import IconCheck from "juice-base/icons/check/index.js";

import styles from "./styles.module.css";


const CouponAttached = (props) => {
    return (
        <div className={styles.container}>
            <div className={styles.couponCode}>
                <div>
                    {props.couponCode}
                </div>
                <IconCheck
                    title="Check"
                    isGreen
                />
            </div>
            <div className={styles.helperText}>
                {props.discountMessage}
            </div>
        </div>
    );
};

CouponAttached.defaultProps = {
    couponCode: "",
    discountMessage: "",
};

export default CouponAttached;
