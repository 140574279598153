import React, { useState } from "react";

import { Formik } from "formik";

import Radio from "juice-base/components/forms/radio/index.js";
import Error from "juice-base/components/forms/error/index.js";
import Button from "juice-base/components/button/index.js";

import * as v from "juice-base/lib/form-validators.js";

import styles from "./styles.module.css";


const QuizQuestionForm = (props) => {
    const [isTouched, setIsTouched] = useState(false);

    const answersIds = [];
    const answersValues = {};

    props.answers.forEach((a, i) => {
        const answerId = i + 1;
        answersIds.push(answerId);
        answersValues[answerId] = a.answer;
    });

    const validateForm = (values) => {
        const errorsAnswers = v.validate(values.answer, [
            v.required("Please select answer"),
        ]);

        const errors = {};

        if (errorsAnswers) {
            errors.answer = errorsAnswers;
        }

        return errors;
    };

    const renderAnswerError = (err) => {
        if (!err) {
            return null;
        }

        return (
            <div className={styles.answerError}>
                <Error>{err}</Error>
            </div>
        );
    };

    const renderForm = (formProps) => {
        const {
            values,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
        } = formProps;

        const formClassName = [
            styles.form,
            props.className,
        ].join(" ");

        return (
            <form
                onSubmit={handleSubmit}
                className={formClassName}
            >
                {answersIds.map((aId) => {
                    const radio = (
                        <Radio
                            key={aId}
                            id={`question-${props.questionId}`}
                            name="answer"
                            label={answersValues[aId]}
                            value={aId}
                            checked={aId === parseInt(values.answer, 10)}
                            disabled={isSubmitting}
                            onChange={(evt) => {
                                setIsTouched(true);
                                props.onSelectAnswer(aId);
                                handleChange(evt);
                            }}
                            onBlur={handleBlur}
                            isDarkPink
                        />
                    );

                    return (
                        <div className={styles.field}>
                            {radio}
                        </div>
                    );
                })}

                {renderAnswerError(errors.answer)}

                <div className={styles.buttons}>
                    <Button
                        type="submit"
                        disabled={!isTouched || isSubmitting}
                        theme="purple"
                    >
                        Submit
                    </Button>
                </div>
            </form>
        );
    };

    return (
        <Formik
            id={`quiz-form-question-${props.questionId}`}
            initialValues={props.initialValues}
            validate={validateForm}
            onSubmit={props.onSubmit}
        >
            {renderForm}
        </Formik>
    );
};

QuizQuestionForm.defaultProps = {
    questionId: 0,
    answers: [],
    initialValues: {},
    className: "",
    onSubmit: () => {},
    onSelectAnswer: () => {},
};

export default QuizQuestionForm;
