import React from "react";

import ButtonFlat from "juice-base/components/button-flat/index.js";
import PopupConfirm, {
    PopupConfirmContent,
    PopupConfirmButtons,
} from "juice-base/components/popup-confirm/index.js";


const PopupConfirmSignUp = (props) => {
    return (
        <PopupConfirm onClose={props.onClose}>
            <PopupConfirmContent>
                You&apos;re all set!
                Make sure to keep track of your username and password.
                Go ahead and sign in to get started!
            </PopupConfirmContent>

            <PopupConfirmButtons>
                <ButtonFlat onClick={props.onClose}>
                    Ok
                </ButtonFlat>
            </PopupConfirmButtons>
        </PopupConfirm>
    );
};

PopupConfirmSignUp.defaultProps = {
    onClose: () => {},
};

export default PopupConfirmSignUp;
