import React from "react";

import styles from "./styles.module.css";


const InputSearch = (props) => {
    return (
        <div className={styles.fieldInput}>
            <input
                id="search"
                name="search"
                className={styles.input}
                value={props.value}
                type="text"
                autoComplete="off"
                maxLength={255}
                placeholder={props.placeholder}
                onKeyPress={props.onKeyPress}
                onChange={props.onChange}
                onBlur={props.onBlur}
            />
        </div>
    );
};

InputSearch.defaultProps = {
    value: "",
    placeholder: "Search for articles, infographics, videos, and extra juices!",
    onKeyPress: () => { },
    onChange: () => { },
    onBlur: () => { },
};

export default InputSearch;
