import React, { useState } from "react";

import RequestLoader from "juice-base/components/request-loader/index.js";

import Input from "juice-base/components/forms/input/index.js";
import ButtonFlat from "juice-base/components/button-flat/index.js";
import PopupConfirm, {
    PopupConfirmContent,
    PopupConfirmButtons,
} from "juice-base/components/popup-confirm/index.js";

import * as v from "juice-base/lib/form-validators.js";

import styles from "./styles.module.css";


const PopupNameChange = (props) => {
    const [inputValue, setInputValue] = useState(props.defaultValue);

    const nameError = v.validate(inputValue, [
        v.required("Please enter fullname"),
    ]);

    const renderContent = () => {
        let content = (
            <Input
                value={inputValue}
                label="Full name"
                error={nameError}
                onChange={(e) => {
                    setInputValue(e.target.value);
                }}
            />
        );

        if (props.isSaving) {
            content = (
                <RequestLoader />
            );
        }

        if (props.error) {
            content = props.error;
        }

        return (
            <div className={styles.content}>
                <div className={styles.header}>
                    Edit Name
                </div>
                {content}
            </div>
        );
    };

    return (
        <PopupConfirm hideScrollbar={false}>
            <PopupConfirmContent>
                {renderContent()}
            </PopupConfirmContent>

            <PopupConfirmButtons>
                <ButtonFlat
                    disabled={nameError || props.isSaving || props.error}
                    onClick={() => {
                        props.onSave(inputValue);
                    }}
                >
                    Save
                </ButtonFlat>
                <ButtonFlat
                    disabled={props.isSaving}
                    onClick={props.onClose}
                >
                    Cancel
                </ButtonFlat>
            </PopupConfirmButtons>
        </PopupConfirm>
    );
};

PopupNameChange.defaultProps = {
    defaultValue: "",
    isSaving: false,
    error: null,
    onSave: () => {},
    onClose: () => {},
};

export default PopupNameChange;
