import React from "react";

import IconWrapper from "juice-base/icons/wrapper/index.js";


const IconPlus = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={iconProps.className}
                >
                    {iconProps.title}
                    <path
                        fill="currentColor"
                        d="M14 8.00098H8V14.001H6V8.00098H0V6.00098H6V0.000976562H8V6.00098H14V8.00098Z"
                    />
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconPlus;
