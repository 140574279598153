import React from "react";

import classNames from "juice-base/lib/class-names.js";

import IconNotepadCheck from "juice-base/icons/notepad-check/index.js";
import IconNotepadExclamation from "juice-base/icons/notepad-exclamation/index.js";
import IconNotepadStop from "juice-base/icons/notepad-stop/index.js";
import IconNotepadX from "juice-base/icons/notepad-x/index.js";
import IconPlayerHeadphone from "juice-base/icons/player-headphone/index.js";
import IconPlayInCircle from "juice-base/icons/play-in-circle/index.js";
import IconPlayInDottedCircle from "juice-base/icons/play-in-dotted-circle/index.js";

import DailyJuice from "juice-base/project/daily-juice.js";
import Image from "juice-base/components/image/index.js";
import Video from "juice-base/components/video/index.js";
import JuiceContentFirstLines from "juice-base/components/juice-content-first-lines/index.js";

import dailyJuiceCommonStyles from "juice-base/business/daily-juice-common/styles.module.css";
import styles from "./styles.module.css";


const DailyJuiceStoryCard = (props) => {
    const { story } = props;

    if (!story) {
        return null;
    }

    let hasFeaturedImage = false;
    let hasVocabFeaturedImage = false;

    if (story.featuredImage?.url) {
        hasFeaturedImage = true;
    }

    if (story.featuredImage?.vocabUrl) {
        hasVocabFeaturedImage = true;
    }

    const storyVideo = {
        hasVideo: false,
        isUserViewedVideo: false,
    };

    if (story.featuredVideo?.url) {
        storyVideo.hasVideo = true;
        storyVideo.isUserViewedVideo = story.featuredVideo.isUserViewed;
    }

    const onListenStory = () => {
        props.onAudioPlay();
    };

    const renderFeaturedVideo = () => {
        const { featuredVideo } = story;

        let posterUrl = "";

        if (featuredVideo?.featuredImage?.url) {
            posterUrl = featuredVideo.featuredImage.url;
        }

        return (
            <div className={styles.featuredVideoContainer}>
                <Video
                    src={featuredVideo.url}
                    captionSrc={props.videoCaptionUrl}
                    videoId={featuredVideo.id}
                    poster={posterUrl}
                    type={featuredVideo.mimeType}
                    onPlayStart={props.onVideoPlayStart}
                    onPlayEnd={props.onVideoPlayEnd}
                    onPause={props.onVideoPlayPause}
                    isDefault={props.isDefaultVideo}
                />
            </div>
        );
    };

    const renderFeaturedContent = () => {
        if (hasFeaturedImage || hasVocabFeaturedImage) {
            let image = { ...story.featuredImage };

            if (hasVocabFeaturedImage) {
                image = {
                    url: story.featuredImage.vocabUrl,
                    title: "Vocabulary",
                    infographic: false,
                };
            }

            const imageClassName = classNames({
                [styles.featuredImage]: true,
                [styles.featuredInfographicImage]: image.infographic,
            });

            return (
                <div
                    className={styles.featuredImgContainer}
                    onClick={props.onReadMore}
                    onKeyPress={props.onReadMore}
                    role="button"
                    tabIndex="-1"
                >
                    <Image
                        src={image.url}
                        title={image.title}
                        className={imageClassName}
                    />
                </div>
            );
        }

        if (storyVideo.hasVideo) {
            return renderFeaturedVideo();
        }

        return null;
    };

    const renderHeadline = () => {
        const headlineClassName = classNames({
            [dailyJuiceCommonStyles.headline]: true,
            [styles.headline]: true,
        });

        return (
            <div
                className={headlineClassName}
                onClick={props.onReadMore}
                onKeyPress={props.onReadMore}
                role="button"
                tabIndex="-1"
            >
                {story.title}
            </div>
        );
    };

    const renderQuizVideoStatus = () => {
        const quizClassName = classNames({
            [styles.quizStatus]: true,
            [styles.quizStatusVideo]: storyVideo.hasVideo,
            [styles.quizStatusVideoCorrect]: storyVideo.hasVideo && storyVideo.isUserViewedVideo,
        });

        let statusIcon = (
            <>
                <IconPlayInDottedCircle
                    className={styles.quizStatusIcon}
                    title={DailyJuice.StoryStates.videoNew}
                    isSky
                />
                {DailyJuice.StoryStates.videoNew}
            </>
        );

        if (storyVideo.isUserViewedVideo) {
            statusIcon = (
                <>
                    <IconPlayInCircle
                        className={styles.quizStatusIcon}
                        title={DailyJuice.StoryStates.videoWatched}
                        isGreen
                    />
                    {DailyJuice.StoryStates.videoWatched}
                </>
            );
        }

        return (
            <div className={quizClassName}>
                {statusIcon}
            </div>
        );
    };

    const renderQuizStatusLabel = () => {
        if (storyVideo.hasVideo) {
            return renderQuizVideoStatus();
        }

        if (!story.isUserOpenedStory) {
            const newStoryClassName = classNames({
                [styles.quizStatus]: true,
                [styles.quizStatusCorrect]: true,
            });

            return (
                <div className={newStoryClassName}>
                    <IconNotepadExclamation
                        title={DailyJuice.StoryStates.storyNew}
                        className={styles.quizStatusIcon}
                        isGreen
                    />
                    {DailyJuice.StoryStates.storyNew}
                </div>
            );
        }

        const quizzes = props.story.quizzes || [];

        if (quizzes.length === 0) {
            return (
                <div className={styles.quizStatus}>
                    <IconNotepadCheck
                        title={DailyJuice.StoryStates.quizEmpty}
                        className={styles.quizStatusIcon}
                        isSky
                    />
                    {DailyJuice.StoryStates.quizEmpty}
                </div>
            );
        }

        const quiz = quizzes[0];
        const isAnswered = DailyJuice.isAnsweredQuiz(quiz);
        const isValid = isAnswered ? DailyJuice.isValidQuiz(quiz) : false;

        const quizClassName = classNames({
            [styles.quizStatus]: true,
            [styles.quizStatusIncorrect]: !isAnswered,
        });

        if (isAnswered) {
            if (isValid) {
                return (
                    <div className={quizClassName}>
                        <IconNotepadCheck
                            title={DailyJuice.StoryStates.quizCorrect}
                            className={styles.quizStatusIcon}
                            isSky
                        />
                        {DailyJuice.StoryStates.quizCorrect}
                    </div>
                );
            }

            return (
                <div className={quizClassName}>
                    <IconNotepadX
                        title={DailyJuice.StoryStates.quizIncorrect}
                        className={styles.quizStatusIcon}
                        isGreen
                    />
                    {DailyJuice.StoryStates.quizIncorrect}
                </div>
            );
        }

        return (
            <div className={quizClassName}>
                <IconNotepadStop
                    title={DailyJuice.StoryStates.quizSkipped}
                    className={styles.quizStatusIcon}
                    isRed
                />
                {DailyJuice.StoryStates.quizSkipped}
            </div>
        );
    };

    const renderListenButton = () => {
        if (storyVideo.hasVideo) {
            return null;
        }

        return (
            <div
                className={styles.listenButton}
                role="button"
                tabIndex="-1"
                onKeyPress={onListenStory}
                onClick={onListenStory}
            >
                <IconPlayerHeadphone
                    title="Headphone"
                    isOrange
                />
                <div>Listen</div>
            </div>
        );
    };

    const renderQuizStatus = () => {
        if (!props.withQuizStatus) {
            return null;
        }

        return (
            <div
                className={styles.quizStatusSection}
                onKeyPress={props.onReadMore}
                onClick={props.onReadMore}
                tabIndex="-1"
                role="button"
            >
                {renderQuizStatusLabel()}
            </div>
        );
    };

    const renderStoryControls = () => {
        return (
            <div className={styles.controls}>
                {renderQuizStatus()}
                {renderListenButton()}
            </div>
        );
    };

    const cardClassName = classNames({
        [dailyJuiceCommonStyles.block]: true,
        [styles.card]: true,
    });

    const categoryClassName = classNames({
        [dailyJuiceCommonStyles.header]: true,
        [styles.category]: true,
    });

    return (
        <div
            className={cardClassName}
            data-comment="daily-juice-story-card"
        >
            {renderFeaturedContent()}

            <div>
                <div className={categoryClassName}>
                    {`${props.storyIndex + 1} | ${story.categoryName}`}
                </div>

                {renderHeadline()}

                <JuiceContentFirstLines
                    content={story.content}
                    className={styles.content}
                    onClick={props.onReadMore}
                />

                {renderStoryControls()}
            </div>
        </div>
    );
};

DailyJuiceStoryCard.defaultProps = {
    storyIndex: 0,
    story: null,
    videoCaptionUrl: "",
    onAudioPlay: () => { },
    onVideoPlayEnd: () => { },
    onVideoPlayPause: () => { },
    onVideoPlayStart: () => { },
    onReadMore: () => { },
    isDefaultVideo: true,
    withQuizStatus: true,
};

export default DailyJuiceStoryCard;
