import React, { useEffect, useState, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";

import text from "juice-base/text/index.js";
import array from "juice-base/lib/array.js";
import copyToClipboard from "juice-base/lib/clipboard.js";
import device from "juice-base/lib/device.js";
import urls from "juice-base/lib/urls.js";

import User from "juice-base/project/user.js";
import DailyJuiceStory from "juice-base/project/daily-juice-story.js";
import Grades from "juice-base/project/grades.js";
import Search from "juice-base/project/search.js";
import Country from "juice-base/project/country.js";
import Standards from "juice-base/project/standards.js";
import Google from "juice-base/project/google.js";

import actions from "juice-base/store/actions.js";
import vocabularyActions from "juice-base/actions/vocabulary.js";
import standardsActions from "juice-base/actions/standards.js";

import AudioManagerContext from "juice-base/context/audio-manager/index.js";

import useTitle from "juice-base/hooks/use-title/index.js";
import useSnackbar from "juice-base/hooks/use-snackbar/index.js";

import LayoutContent from "juice-base/components/layout-content/index.js";
import RequestLoader from "juice-base/components/request-loader/index.js";
import Tiles from "juice-base/components/tiles/index.js";
import Pagination from "juice-base/components/pagination/index.js";
import Snackbar from "juice-base/components/snackbar/index.js";
import { useButtonShare } from "juice-base/components/button-share/index.js";

import PopupJuiceStorySearch, {
    useInfographicImage,
    useJuiceStorySearch,
} from "juice-base/components/popup-juice-story-search/index.js";

import PopupWord from "juice-base/business/popup-word/index.js";
import SearchControl from "juice-base/business/search-control/index.js";
import SearchCard from "juice-base/business/search-card/index.js";

import PopupAssign, { usePopupAssign } from "juice-app/containers/popup-assign/index.js";

import api from "juice-app/api.js";
import events from "juice-app/events.js";
import settings from "juice-app/settings.js";

import styles from "./styles.module.css";


const SearchViewTypes = Search.getViewTypes();

const storeSelector = (state) => ({
    dimensions: state.device.dimensions,
    session: state.user.session,
    user: state.user.user,
    isSearchCategoriesLoaded: state.search.isCategoriesLoaded,
    searchCategories: state.search.categories,

    stateStandardsByState: state.standards.stateStandardsByState,

    isStandardsLoaded: state.search.isStandardsLoaded,
    searchStandards: state.search.standards,

    searchEmpty: state.search.searchEmpty,
    searchByKeyword: state.search.searchByKeyword,
    wordPopup: state.vocabulary.popup,
    wordsByName: state.vocabulary.wordsByName,
    playerState: state.player.playerState,
});

const SearchContainer = () => {
    const dispatch = useDispatch();
    const store = useSelector(storeSelector);

    const isMobile = store.dimensions.width < 750;

    const isUserTeacher = User.hasRoleTeacher(store.user);
    const isUserStudent = User.hasRoleStudent(store.user);

    const defaultViewType = SearchViewTypes.list;
    const [viewType, setViewType] = useState(defaultViewType);

    const [searchState, setSearchState] = useState({});

    const searchResults = Search.getSearchResult(
        store.searchEmpty,
        store.searchByKeyword,
        searchState.search,
    );

    const storyPopup = useJuiceStorySearch();
    const infographicImage = useInfographicImage();
    const buttonShare0 = useButtonShare();
    const buttonShare1 = useButtonShare();
    const popupAssign = usePopupAssign();

    const audioManager = useContext(AudioManagerContext);
    const snackbar = useSnackbar();

    const [shareMenuOpenId, setShareMenuOpedId] = useState(null);

    /* --- */

    const onCopyToClipboard = (params = {}) => {
        copyToClipboard(params.toCopy);

        snackbar.add({
            message: params.message,
        });
    };

    /* --- */

    const getStoryPathByType = (story) => {
        if (story.type === "extra juice") {
            return `/extra-juice/${story.storyOrExtraJuiceId}`;
        }

        if (story.storyOrExtraJuiceId) {
            return `/story/${story.storyOrExtraJuiceId}`;
        }

        return "";
    };

    const getStoryVideo = (story) => {
        if (story.type === "video" && story?.featuredVideo?.featuredImage?.sizes?.large) {
            const captionSrc = api.videos.getVideoCaptionURL({
                id: story.featuredVideo.id,
                session: store.session,
            });

            return {
                id: story.featuredVideo.id,
                url: story.featuredVideo.url,
                poster: story.featuredVideo.featuredImage.sizes.large.src,
                captionSrc,
                mimeType: story.featuredVideo.mimeType,
            };
        }

        return null;
    };

    const getStories = () => {
        if (searchState.isTyping
            || searchResults?.isLoading
            || !searchResults?.data?.results) {
            return [];
        }

        return searchResults.data.results.map((story, id) => {
            let sType = story.type;

            const storyId = story.ID;
            const category = story.categoryName || null;
            const featuredImage = DailyJuiceStory.getStoryImages(story);

            const video = getStoryVideo(story);

            if (sType === "extra_juice") {
                sType = "extra juice";
            }

            return {
                id,
                storyOrExtraJuiceId: storyId,
                type: sType,
                grades: story.grades,
                title: story.title,
                featuredImage,
                content: story.content,
                quiz: story.quiz || null,
                category,
                statusId: 1,
                video,
                extraJuices: story.extraJuices || [],
                datePublished: story.date,
            };
        });
    };

    const getStoryById = (id) => {
        const stories = getStories();
        return array.findOneById(stories, id);
    };

    const getCategories = () => {
        if (!store.isSearchCategoriesLoaded) {
            return [];
        }

        return store.searchCategories || {};
    };

    const getStandards = () => {
        if (!store.isStandardsLoaded) {
            return [];
        }

        return store.searchStandards || {};
    };

    /* --- */

    const onCloseShareMenu = () => {
        setShareMenuOpedId(null);
    };

    /* --- */

    const onCreateAssignment = async (story) => {
        const storyId = story.storyOrExtraJuiceId;
        const quiz = story?.quiz || null;

        const reqParams = {
            session: store.session,
            storyId,
        };

        if (quiz) {
            reqParams.quizId = quiz.quizId;
            reqParams.questionId = quiz.questionId;
        }

        events.teacher.createAssignmentsOnSearch(reqParams);

        const res = await api.assignments.createAssignment(reqParams);

        let link = "";
        let path = "";

        if (res.ok) {
            const siteUrl = urls.getSiteUrl();

            path = `/assignments/${res.groupCode}/${storyId}`;
            link = `${siteUrl}${path}`;
        }

        return {
            link,
            path,
        };
    };

    /* --- */

    const onCopyLinkWithoutQuiz = (story) => {
        const path = getStoryPathByType(story);

        const siteUrl = urls.getSiteUrl();

        if (!path) {
            return;
        }

        onCopyToClipboard({
            message: `The link to "${story.title}" has been copied to your clipboard.`,
            toCopy: `${siteUrl}${path}`,
        });
    };

    const onCardCopyLink = async (story) => {
        if (!story?.quiz?.quizId || settings.features.ASSIGNMENTS) {
            onCopyLinkWithoutQuiz(story);
            onCloseShareMenu();
            return;
        }

        // TODO: remove later with assignments feature
        buttonShare0.setCopyLinkLoading(true);

        const { link } = await onCreateAssignment(story);

        buttonShare0.setCopyLinkLoading(false);
        onCloseShareMenu();

        if (!link) {
            snackbar.add({
                message: text.error,
            });
            return;
        }

        onCopyToClipboard({
            message: `The link to "${story.title}" has been copied to your clipboard.`,
            toCopy: link,
        });
    };

    /* --- */

    const onCopyGoogleClassroomLinkWithoutQuiz = (story) => {
        const path = getStoryPathByType(story);

        if (!path) {
            return;
        }

        const googleLink = Google.getGoogleClassroomShareLink(
            settings.google.classroomShareUrl,
            path,
            story.title,
        );

        urls.openUrl(googleLink);

        onCloseShareMenu();
    };

    const onCardGoogleClassroomClick = async (story) => {
        if (!story?.quiz?.quizId || settings.features.ASSIGNMENTS) {
            onCopyGoogleClassroomLinkWithoutQuiz(story);
            return;
        }

        buttonShare0.setGoogleClassroomLoading(true);

        const { path } = await onCreateAssignment(story);

        buttonShare0.setGoogleClassroomLoading(false);
        onCloseShareMenu();

        if (!path) {
            snackbar.add({
                message: text.error,
            });
            return;
        }

        const googleLink = Google.getGoogleClassroomShareLink(
            settings.google.classroomShareUrl,
            path,
            story.title,
        );

        urls.openUrl(googleLink);
    };

    /* --- */

    const onStoryPopupCopyLink = async (story) => {
        if (!story?.quiz?.quizId || settings.features.ASSIGNMENTS) {
            onCopyLinkWithoutQuiz(story);
            buttonShare1.toggleMenu();
            return;
        }

        // TODO: remove later with assignments feature
        buttonShare1.setCopyLinkLoading(true);

        const { link } = await onCreateAssignment(story);

        buttonShare1.setCopyLinkLoading(false);
        buttonShare1.toggleMenu();

        if (!link) {
            snackbar.add({
                message: text.error,
            });
            return;
        }

        onCopyToClipboard({
            message: `The link to "${story.title}" has been copied to your clipboard.`,
            toCopy: link,
        });
    };

    const onStoryPopupGoogleClassroomClick = async (story) => {
        if (!story?.quiz?.quizId || settings.features.ASSIGNMENTS) {
            onCopyGoogleClassroomLinkWithoutQuiz(story);
            buttonShare1.toggleMenu();
            return;
        }

        buttonShare1.setGoogleClassroomLoading(true);

        const { path } = await onCreateAssignment(story);

        buttonShare1.setGoogleClassroomLoading(false);
        buttonShare1.toggleMenu();

        if (!path) {
            snackbar.add({
                message: text.error,
            });
            return;
        }

        const googleLink = Google.getGoogleClassroomShareLink(
            settings.google.classroomShareUrl,
            path,
            story.title,
        );

        urls.openUrl(googleLink);
    };

    /* --- */

    const onOpenCategories = () => {
        events.search.filterClick({
            session: store.session,
            userId: store.user.userId,
            grades: searchState.grades,
        });
    };

    const onChangeOrderBy = () => {
        if (!isUserTeacher) {
            return;
        }

        events.teacher.searchSortChange({
            session: store.session,
        });
    };

    const onChangeGrade = () => {
        if (!isUserTeacher) {
            return;
        }

        events.teacher.searchGradeChange({
            session: store.session,
        });
    };

    const onChangeCategories = () => {
        if (!isUserTeacher) {
            return;
        }

        events.teacher.searchCategoriesChange({
            session: store.session,
        });
    };

    const onCardClick = (id, storyId, autoPlay) => {
        if (isUserTeacher) {
            events.teacher.searchOpenStory({
                session: store.session,
                storyId: storyId || 0,
            });
        } else {
            events.search.storyClick({
                session: store.session,
                userId: store.user.userId,
                grades: searchState.grades,
                storyId: storyId || 0,
            });
        }

        if (autoPlay) {
            storyPopup.openWithAutoPlay(id);
        } else {
            storyPopup.open(id);
        }
    };

    const onExtraJuiceWordClick = (hasExtraJuices) => {
        if (!hasExtraJuices) {
            return;
        }

        storyPopup.openExtraJuice();
    };

    const onCloseExtraJuice = () => {
        storyPopup.closeExtraJuice();
    };

    /* --- */

    const onClickShareButton = (cardId) => {
        if (cardId === shareMenuOpenId) {
            onCloseShareMenu();
            return;
        }

        setShareMenuOpedId(cardId);
    };

    /* --- */

    const onSearch = async (params) => {
        setSearchState(params);

        setShareMenuOpedId(null);

        if (!params.search
            && params.categories.length === 0
            && params.standards.length === 0) {
            return;
        }

        storyPopup.close();

        if (isUserTeacher) {
            events.teacher.onSearch({
                session: store.session,
            });
        } else {
            events.search.onSearch({
                session: store.session,
                userId: store.user.userId,
                grades: params.grades,
                word: params.search,
            });
        }

        if (params.search === "") {
            dispatch(actions.search.setSearchEmptyLoading());
        } else {
            dispatch(actions.search.setSearchByKeywordLoading({
                keyword: params.search,
            }));
        }

        let sCategories = params.categories.join(",");

        if (!sCategories) {
            sCategories = "all";
        }

        const grades = Grades.convertGradesToQueryString(params.grades);

        const searchRes = await api.search.all({
            apiVersion: "2",
            session: store.session,
            search: params.search,
            page: params.page,
            orderBy: params.orderBy,
            categories: sCategories,
            standards: params.standards.join(",") || "all",
            type: params.type,
            grades,
        });

        let resultsPerPage = 0;
        let totalResults = 0;
        let results = [];
        let categories = [];
        let error = "";

        if (searchRes.ok) {
            resultsPerPage = searchRes.perPage;
            totalResults = searchRes.totalResults;
            results = searchRes.results;
            categories = searchRes.categories;
        } else {
            error = searchRes.error || text.error;
        }

        const data = {
            resultsPerPage,
            totalResults,
            results,
            error,
            selectedPage: params.page,
            categories,
        };

        if (params.search === "") {
            dispatch(actions.search.setSearchEmptyData({
                data,
            }));
        } else {
            dispatch(actions.search.setSearchByKeyword({
                keyword: params.search,
                data,
            }));
        }
    };

    /* --- */

    const onWordClick = (word) => {
        dispatch(vocabularyActions.openPopup({
            api,
            actions,
        }, {
            session: store.session,
            word,
        }));
    };

    const onCloseWordPopup = () => {
        dispatch(vocabularyActions.closePopup({ actions }));
    };

    /* --- */

    const onOpenImagePopup = (story) => {
        infographicImage.open({
            url: story.featuredImage.largeSrc,
            title: "Featured",
        });
    };

    const onCloseImagePopup = () => {
        infographicImage.close();
    };

    /* --- */

    const onStoryPlay = (trackGroupName, trackId) => {
        audioManager.pauseAll();

        if (trackGroupName === "juiceStories") {
            audioManager.loadStory(trackId);
        } else if (trackGroupName === "extraJuices") {
            audioManager.loadExtraJuice(trackId);
        }
    };

    const onStoryListenClick = (story) => {
        let trackGroupName = "juiceStories";

        if (story.type === "extra juice" || storyPopup.state.isExtraJuiceView) {
            trackGroupName = "extraJuices";
        }

        let trackId = story.storyOrExtraJuiceId;

        if (storyPopup.state.isExtraJuiceView) {
            trackId = null;

            if (story.extraJuices && story.extraJuices[0]) {
                trackId = story.extraJuices[0].id;
            }
        }

        if (storyPopup.state.playerState.trackGroupName === trackGroupName
            && storyPopup.state.playerState.trackId === trackId) {
            return;
        }

        storyPopup.openPlayer(trackGroupName, trackId);

        onStoryPlay(trackGroupName, trackId);
    };

    const onCloseSingleStoryView = () => {
        onCloseImagePopup();
        storyPopup.close();
    };

    /* --- */

    const onOpenAssignPopup = (story) => {
        let stateStandard = null;

        if (isUserTeacher
            && Country.isCountryUS(store.user.country)
            && store.user.subdivision
            && store.stateStandardsByState?.[store.user.subdivision]
            && story?.quiz?.standardId) {
            stateStandard = Standards.getStandardById(
                store.stateStandardsByState[store.user.subdivision],
                story.quiz.standardId,
            );
        }

        let defaultStandardType = Standards.getDefaultStandardValue(stateStandard);

        if (searchState.standardType) {
            defaultStandardType = searchState.standardType;
        }

        const standards = story?.quiz?.standards || [];

        if (stateStandard) {
            standards.push(stateStandard);
        }

        popupAssign.open({
            storyId: story?.storyOrExtraJuiceId || null,
            category: story?.category || "",
            title: story?.title || "",
            date: story?.datePublished,
            standards,
            standardId: story?.quiz?.standardId || null,
            quizId: story?.quiz?.quizId || null,
            questionId: story?.quiz?.questionId || null,
            defaultStandardType,
            featuredImageSrc: story?.featuredImage?.smallSrc || "",
        });
    };

    /* --- */

    const loadSearchStandards = async () => {
        const res = await api.search.getStandards({
            session: store.session,
            state: store.user.subdivision,
        });

        if (!res.ok) {
            return;
        }

        dispatch(actions.search.setSearchStandards({
            standards: res.data,
        }));
    };

    const loadCategories = async () => {
        const categoriesRes = await api.search.categories({
            session: store.session,
        });

        if (!categoriesRes.ok) {
            return;
        }

        dispatch(actions.search.setSearchCategories({
            categories: categoriesRes.categories,
        }));
    };

    const loadStateStandards = async () => {
        if (!isUserTeacher || !Country.isCountryUS(store.user.country) || !store.user.subdivision) {
            return;
        }

        dispatch(standardsActions.loadStateStandards(
            { api, actions },
            { subdivision: store.user.subdivision },
        ));
    };

    /* --- */

    useTitle(() => {
        let title = "Search";

        if (searchState.search) {
            title += ` - ${searchState.search}`;
        }

        return title;
    }, [searchState.search]);

    useEffect(() => {
        if (isUserTeacher) {
            events.teacher.openSearch({
                session: store.session,
            });
        }

        loadSearchStandards();
        loadCategories();

        loadStateStandards();

        return () => {
            dispatch(vocabularyActions.closePopup({ actions }));
        };
    }, []);

    /* --- */

    const renderStoryPopup = () => {
        if (!storyPopup.state.isOpen) {
            return null;
        }

        const story = getStoryById(storyPopup.state.id);

        if (!story) {
            return null;
        }

        let extraJuice = null;

        if (storyPopup.state.isExtraJuiceView) {
            if (story.extraJuices && story.extraJuices[0]) {
                extraJuice = { ...story.extraJuices[0] };
            }
        }

        const {
            trackGroupName,
            trackId,
        } = storyPopup.state.playerState;

        let audioData = null;

        if (storyPopup.state.playerState.isOpen) {
            audioData = store.playerState?.[trackGroupName]?.[trackId] || null;
        }

        let stateStandard = null;

        if (isUserTeacher
            && Country.isCountryUS(store.user.country)
            && store.user.subdivision
            && store.stateStandardsByState?.[store.user.subdivision]
            && story?.quiz?.standardId) {
            stateStandard = Standards.getStandardById(
                store.stateStandardsByState[store.user.subdivision],
                story.quiz.standardId,
            );
        }

        let defaultStandardType = Standards.getDefaultStandardValue(stateStandard);

        if (searchState.standardType) {
            defaultStandardType = searchState.standardType;
        }

        const googleClassroom = {
            shareUrl: settings.google.classroomShareUrl,
            title: story.title,
            onClick: () => {
                onStoryPopupGoogleClassroomClick(story);
            },
        };

        const share = {
            googleClassroom,
            onClick: buttonShare1.toggleMenu,
            onCloseMenu: () => {
                buttonShare1.setVisible(false);
            },
            onCopyLink: () => {
                onStoryPopupCopyLink(story);
            },
            closeMenuOnClassroomClick: false,
            closeMenuOnCopyLinkClick: false,
            isVisibleMenu: buttonShare1.state.isVisibleMenu,
            isGoogleClassroomLoading: buttonShare1.state.isGoogleClassroomLoading,
            isCopyLinkLoading: buttonShare1.state.isCopyLinkLoading,
        };

        return (
            <PopupJuiceStorySearch
                story={story}
                share={share}
                infographic={infographicImage.state.image}
                extraJuice={extraJuice}
                audio={audioData}
                trackId={storyPopup.state.playerState.trackId}
                stateStandard={stateStandard}
                defaultStandardType={defaultStandardType}
                onCloseExtraJuice={onCloseExtraJuice}
                onCloseInfographic={onCloseImagePopup}
                withAutoPlay={storyPopup.state.isAutoPlay}
                onAudioPlay={onStoryListenClick}
                onFullscreenImageClick={() => {
                    onOpenImagePopup(story);
                }}
                onPlay={() => {
                    audioManager.play(trackGroupName, trackId);
                }}
                onPause={() => {
                    audioManager.pause(trackGroupName, trackId);
                }}
                onRewind={() => {
                    audioManager.rewind(trackGroupName, trackId);
                }}
                onForward={() => {
                    audioManager.forward(trackGroupName, trackId);
                }}
                onChangeRate={(rate) => {
                    audioManager.changeRate(trackGroupName, trackId, rate);
                }}
                onClosePlayer={() => {
                    audioManager.stop(trackGroupName, trackId);

                    storyPopup.closePlayer();
                }}
                onClose={onCloseSingleStoryView}
                onWordClick={onWordClick}
                onExtraJuiceWordClick={() => {
                    let hasExtraJuices = false;

                    if (story.extraJuices && story.extraJuices[0]) {
                        hasExtraJuices = true;
                    }

                    onExtraJuiceWordClick(hasExtraJuices);
                }}
                isDefaultVideo={!device.isChrome}
                isExtraJuiceView={storyPopup.state.isExtraJuiceView}
                isInfographicView={infographicImage.state.isOpen}
                isMobile={isMobile}
                withQuiz={isUserTeacher}
                withShareButton={isUserTeacher}
            />
        );
    };

    const renderStory = (story) => {
        const trackGroupName = story.type === "extra juice"
            ? "extraJuices"
            : "juiceStories";

        const trackId = story.storyOrExtraJuiceId;
        const audioData = store.playerState?.[trackGroupName]?.[trackId];

        const googleClassroom = {
            shareUrl: settings.google.classroomShareUrl,
            title: story.title,
            onClick: () => {
                onCardGoogleClassroomClick(story);
            },
        };

        const share = {
            googleClassroom,
            onCloseMenu: onCloseShareMenu,
            onCopyLink: () => {
                onCardCopyLink(story);
            },
            onClick: () => {
                onClickShareButton(story.id);
            },
            closeMenuOnClassroomClick: false,
            closeMenuOnCopyLinkClick: false,
            isVisibleMenu: shareMenuOpenId === story.id,
            isGoogleClassroomLoading: buttonShare0.state.isGoogleClassroomLoading,
            isCopyLinkLoading: buttonShare0.state.isCopyLinkLoading,
        };

        let withAssignButton = false;

        if (settings.features.ASSIGNMENTS
            && !Search.isStoryTypeVideo(story.type)
            && !Search.isStoryTypeExtraJuice(story.type)
            && isUserTeacher) {
            withAssignButton = true;
        }

        return (
            <SearchCard
                key={story.storyOrExtraJuiceId}
                cardId={story.id}
                storyId={story.storyOrExtraJuiceId}
                type={story.type}
                datePublished={story.datePublished}
                featuredImage={story.featuredImage}
                category={story.category}
                title={story.title}
                content={story.content}
                statusId={story.statusId}
                audioData={audioData}
                share={share}
                onAssignmentsClick={() => {
                    onOpenAssignPopup(story);
                }}
                onCardClick={() => {
                    onCardClick(story.id, story.storyOrExtraJuiceId, false);
                }}
                onCardClickAndPlay={() => {
                    onCardClick(story.id, story.storyOrExtraJuiceId, true);
                }}
                onFirstPlay={() => {
                    if (story.type === "article" || story.type === "infographic") {
                        onStoryPlay("juiceStories", trackId);
                    } else if (story.type === "extra juice") {
                        onStoryPlay("extraJuices", trackId);
                    }
                }}
                onPlay={() => {
                    audioManager.play(trackGroupName, trackId);
                }}
                onPause={() => {
                    audioManager.pause(trackGroupName, trackId);
                }}
                onRewind={() => {
                    audioManager.rewind(trackGroupName, trackId);
                }}
                onForward={() => {
                    audioManager.forward(trackGroupName, trackId);
                }}
                onChangeRate={(rate) => {
                    audioManager.changeRate(trackGroupName, trackId, rate);
                }}
                onWordClick={onWordClick}
                onClosePlayer={() => {
                    audioManager.stop(trackGroupName, trackId);
                }}
                onClose={() => {
                    audioManager.stop(trackGroupName, trackId);
                }}
                isCardsView={viewType === SearchViewTypes.cards}
                withShareButton={isUserTeacher}
                withAssignButton={withAssignButton}
            />
        );
    };

    const renderStories = () => {
        if (!searchState.search
            && searchState?.categories?.length === 0
            && searchState?.standards?.length === 0) {
            return null;
        }

        if (searchState.isTyping || searchResults?.isLoading) {
            return (
                <RequestLoader />
            );
        }

        if (searchResults?.data?.error) {
            return (
                <div className={styles.contentMessage}>
                    {searchResults.data.error}
                </div>
            );
        }

        const stories = getStories();

        if (stories.length === 0) {
            if (searchState.search === "") {
                const cats = Search.getCategories(
                    store.searchCategories[searchState.type],
                    searchState.categories,
                ).map((cat) => {
                    return cat.name;
                }).join(", ");

                if (cats.length === 0 && searchState.standards.length > 0) {
                    return (
                        <div className={styles.contentMessage}>
                            No stories found by selected standards
                        </div>
                    );
                }

                return (
                    <div className={styles.contentMessage}>
                        {`No stories found by categories: ${cats}`}
                    </div>
                );
            }

            return (
                <div className={styles.contentMessage}>
                    {`No stories found by keyword: "${searchState.search}"`}
                </div>
            );
        }

        const allStories = stories.map((card) => renderStory(card));

        if (viewType === SearchViewTypes.list) {
            return (
                <div className={styles.storiesList}>
                    {allStories}
                </div>
            );
        }

        return (
            <Tiles isMobile={isMobile}>
                {allStories}
            </Tiles>
        );
    };

    const renderWordPopup = () => {
        if (!store.wordPopup.isVisible) {
            return null;
        }

        const trackGroupName = "words";
        const audioData = store.playerState?.[trackGroupName] || {};

        return (
            <PopupWord
                wordsByName={store.wordsByName}
                wordPopup={store.wordPopup}
                hideScrollbar={false}
                audio={audioData}
                onAudioLoad={(txt) => {
                    audioManager.loadWord(txt);
                }}
                onAudioPlay={(txt) => {
                    audioManager.play(trackGroupName, txt);
                }}
                onAudioStop={(txt) => {
                    audioManager.stop(trackGroupName, txt);
                }}
                onAudioStopAll={(words) => {
                    audioManager.stopAllTracks(trackGroupName, words);
                }}
                onClose={onCloseWordPopup}
            />
        );
    };

    const renderAssignPopup = () => {
        if (!popupAssign.state.isVisible) {
            return null;
        }

        return (
            <PopupAssign
                story={popupAssign.state.story}
                onClose={popupAssign.close}
            />
        );
    };

    const renderPagination = () => {
        if (!searchState.search && searchState?.categories?.length === 0) {
            return null;
        }

        if (searchState.isTyping
            || searchResults?.isLoading
            || !searchResults?.data) {
            return null;
        }

        const totalResults = searchResults.data?.totalResults;
        const resultsPerPage = searchResults.data?.resultsPerPage;

        let count = 0;

        if (totalResults && resultsPerPage) {
            count = Math.ceil(totalResults / resultsPerPage);
        }

        if (Number.isNaN(count) || count === 0) {
            return null;
        }

        return (
            <Pagination
                count={count}
                defaultPage={searchResults?.data?.selectedPage}
                onChange={(page) => {
                    onSearch({
                        ...searchState,
                        page,
                    });
                }}
            />
        );
    };

    const renderSnackbars = () => {
        return snackbar.state.map((bar, index) => {
            if (!bar.isVisibleSnackbar) {
                return null;
            }

            return (
                <Snackbar
                    message={bar.message}
                    onClose={() => {
                        snackbar.close(index);
                    }}
                />
            );
        });
    };

    const defaultGrades = store.user.grades?.length > 0
        ? Grades.getValidGrade(store.user.grades[0]).split("-")
        : Grades.getMaxGradeValue();

    const withStandardsFilter = isUserTeacher;

    return (
        <>
            {renderStoryPopup()}
            {renderWordPopup()}
            {renderAssignPopup()}

            {renderSnackbars()}

            <LayoutContent>
                <div className={styles.index}>
                    <SearchControl
                        defaultGrades={defaultGrades}
                        disabledGrades={isUserStudent}
                        standards={getStandards()}
                        categories={getCategories()}
                        defaultViewType={defaultViewType}
                        withStandardsFilter={withStandardsFilter}
                        withStateStandard={Country.isCountryUS(store.user.country)}
                        onViewTypeChange={setViewType}
                        onChangeOrderBy={onChangeOrderBy}
                        onChangeGrade={onChangeGrade}
                        onChangeCategories={onChangeCategories}
                        onOpenCategories={onOpenCategories}
                        onSearch={onSearch}
                    />

                    <div className={styles.stories}>
                        {renderStories()}
                        {renderPagination()}
                    </div>
                </div>
            </LayoutContent>
        </>
    );
};

export default SearchContainer;
