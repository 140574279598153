// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".nhVpH00RR21W1kN7JGLw {\n    display: grid;\n}\n\n.Ix7LnSTK3HzhZwGTktyU {\n    grid-template-columns: 3rem auto;\n\n    align-items: center;\n\n    font-weight: 600;\n    font-size: 1.1rem;\n}\n\n.BHIIyA2BYCSHIk51j7Rg {\n    width: 1.8rem;\n    height: 0.5rem;\n\n    border-radius: 0.3rem;\n}\n\n.uhCtpF_cECeXA3fJ2meA {\n    width: 0.8rem;\n    height: 0.8rem;\n\n    border-radius: 1rem;\n}\n\n@media only screen and (min-width: 500px) {\n    .nhVpH00RR21W1kN7JGLw {\n        font-size: 1.6rem;\n    }\n\n    .uhCtpF_cECeXA3fJ2meA {\n        width: 1rem;\n        height: 1rem;\n    }\n}\n", "",{"version":3,"sources":["webpack://./base/business/juice-status-v2/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,gCAAgC;;IAEhC,mBAAmB;;IAEnB,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,cAAc;;IAEd,qBAAqB;AACzB;;AAEA;IACI,aAAa;IACb,cAAc;;IAEd,mBAAmB;AACvB;;AAEA;IACI;QACI,iBAAiB;IACrB;;IAEA;QACI,WAAW;QACX,YAAY;IAChB;AACJ","sourcesContent":[".status {\n    display: grid;\n}\n\n.statusWithLabel {\n    grid-template-columns: 3rem auto;\n\n    align-items: center;\n\n    font-weight: 600;\n    font-size: 1.1rem;\n}\n\n.statusElement {\n    width: 1.8rem;\n    height: 0.5rem;\n\n    border-radius: 0.3rem;\n}\n\n.statusElementRounded {\n    width: 0.8rem;\n    height: 0.8rem;\n\n    border-radius: 1rem;\n}\n\n@media only screen and (min-width: 500px) {\n    .status {\n        font-size: 1.6rem;\n    }\n\n    .statusElementRounded {\n        width: 1rem;\n        height: 1rem;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"status": "nhVpH00RR21W1kN7JGLw",
	"statusWithLabel": "Ix7LnSTK3HzhZwGTktyU",
	"statusElement": "BHIIyA2BYCSHIk51j7Rg",
	"statusElementRounded": "uhCtpF_cECeXA3fJ2meA"
};
export default ___CSS_LOADER_EXPORT___;
