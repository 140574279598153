// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Eo9tYeyqePE9Ycg0tV2p {\n    display: grid;\n    grid-gap: 2rem;\n\n    padding: 2rem;\n}\n\n.SeMwdAH7Y5LpGr8z8ONO {\n    max-height: 20rem;\n    overflow: auto;\n\n    padding: 1rem 0;\n}\n\n.pF8nbEg5BVpgOt_34riU {\n    display: grid;\n    grid-auto-flow: column;\n    grid-gap: 1rem;\n\n    justify-content: space-between;\n    align-items: center;\n\n    padding: 0.5rem;\n}", "",{"version":3,"sources":["webpack://./app/containers/teacher-popup-students-add/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,cAAc;;IAEd,aAAa;AACjB;;AAEA;IACI,iBAAiB;IACjB,cAAc;;IAEd,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,cAAc;;IAEd,8BAA8B;IAC9B,mBAAmB;;IAEnB,eAAe;AACnB","sourcesContent":[".content {\n    display: grid;\n    grid-gap: 2rem;\n\n    padding: 2rem;\n}\n\n.studentsList {\n    max-height: 20rem;\n    overflow: auto;\n\n    padding: 1rem 0;\n}\n\n.studentRow {\n    display: grid;\n    grid-auto-flow: column;\n    grid-gap: 1rem;\n\n    justify-content: space-between;\n    align-items: center;\n\n    padding: 0.5rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "Eo9tYeyqePE9Ycg0tV2p",
	"studentsList": "SeMwdAH7Y5LpGr8z8ONO",
	"studentRow": "pF8nbEg5BVpgOt_34riU"
};
export default ___CSS_LOADER_EXPORT___;
