import React from "react";

import IconWrapper from "juice-base/icons/wrapper/index.js";


const IconGradesG11G12 = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={iconProps.className}
                >
                    {iconProps.title}
                    <rect
                        width="40"
                        height="40"
                        rx="20"
                        fill="#F97316"
                    />
                    <rect
                        x="9"
                        y="7"
                        width="2.5"
                        height="12.3"
                        fill="#ffffff"
                    />
                    <rect
                        x="14"
                        y="7"
                        width="2.5"
                        height="12.3"
                        fill="#ffffff"
                    />
                    <path
                        d="M33 25.4H28.6V21H26.4V25.4H22V27.6H26.4V32H28.6V27.6H33V25.4Z"
                        fill="#ffffff"
                    />
                    <mask
                        id="gradesG11G12IconMask"
                        style={{ maskType: "alpha" }}
                        width="40"
                        height="40"
                    >
                        <circle
                            cx="20"
                            cy="20"
                            r="20"
                            fill="#F97316"
                        />
                    </mask>
                    <g mask="url(#gradesG11G12IconMask)">
                        <path
                            d="M30.0862 2.46683L31.9624 3.28911L9.87622 38.3742L7.99999 37.552L30.0862 2.46683Z"
                            fill="#ffffff"
                        />
                    </g>
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconGradesG11G12;
