import React, { useState, useEffect, useRef } from "react";

import AvatarIcon from "juice-base/components/avatar-icon/index.js";
import AvatarMenu from "juice-base/components/avatar-menu/index.js";

import styles from "./styles.module.css";


const Avatar = (props) => {
    const menuRef = useRef(null);

    const [isVisibleMenu, setIsVisibleMenu] = useState(false);

    /* --- */

    useEffect(() => {
        const globalClose = (evt) => {
            if (props.disabledMenuGlobalClose) {
                return;
            }

            if (menuRef?.current?.contains
                && menuRef.current.contains(evt.target)) {
                return;
            }

            setIsVisibleMenu(false);
        };

        if (document) {
            document.addEventListener("click", globalClose, false);
        }

        return () => {
            if (document) {
                document.removeEventListener("click", globalClose, false);
            }
        };
    }, [props.disabledMenuGlobalClose]);

    /* --- */

    const onAvatarClick = () => {
        setIsVisibleMenu((prev) => !prev);
    };

    const renderMenu = () => {
        if (!isVisibleMenu) {
            return null;
        }

        return (
            <AvatarMenu
                data={props.data}
                onSettings={props.onSettings}
                onLogout={props.onLogout}
                onEditPassword={props.onEditPassword}
                onEditEmail={props.onEditEmail}
                onManageAccount={props.onManageAccount}
                onContactSupport={props.onContactSupport}
                isVisibleFreeTrialRow={props.isVisibleFreeTrialRow}
                isVisibleManageButton={props.isVisibleManageButton}
            />
        );
    };

    return (
        <div
            ref={menuRef}
            className={styles.avatar}
        >
            <AvatarIcon
                name={props.data.name}
                avatarUrl={props.data.avatarUrl}
                onClick={onAvatarClick}
            />
            {renderMenu()}
        </div>
    );
};

Avatar.defaultProps = {
    data: {
        name: "A",
        fullname: "",
        username: "",
        email: "",
        avatarUrl: "",
        daysLeftToExpire: 0,
        isLmsUser: false,
    },
    disabledMenuGlobalClose: false,
    isVisibleFreeTrialRow: false,
    isVisibleManageButton: false,
    onSettings: () => { },
    onLogout: () => { },
    onEditPassword: () => { },
    onEditEmail: () => { },
    onManageAccount: () => { },
    onContactSupport: () => { },
};

export default Avatar;
