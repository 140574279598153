import React, { useState } from "react";

import RequestLoader from "juice-base/components/request-loader/index.js";
import Input from "juice-base/components/forms/input/index.js";
import ButtonFlat from "juice-base/components/button-flat/index.js";
import PopupConfirm, {
    PopupConfirmContent,
    PopupConfirmButtons,
} from "juice-base/components/popup-confirm/index.js";

import * as v from "juice-base/lib/form-validators.js";

import styles from "./styles.module.css";


const PopupEmailChange = (props) => {
    const [inputValue, setInputValue] = useState(props.defaultValue);

    const emailError = v.validate(inputValue, [
        v.required("Please enter an email address"),
        v.email("Sorry, that email address isn't valid. Please enter a valid one and try again."),
    ]);

    const renderContent = () => {
        let content = (
            <Input
                label="Email address"
                value={inputValue}
                error={emailError}
                onChange={(e) => {
                    setInputValue(e.target.value);
                }}
            />
        );

        if (props.isSaving) {
            content = (
                <RequestLoader />
            );
        }

        if (props.error) {
            content = props.error;
        }

        return (
            <div className={styles.content}>
                <div className={styles.header}>
                    Edit Email
                </div>
                {content}
            </div>
        );
    };

    return (
        <PopupConfirm hideScrollbar={false}>
            <PopupConfirmContent>
                {renderContent()}
            </PopupConfirmContent>
            <PopupConfirmButtons>
                <ButtonFlat
                    onClick={() => {
                        props.onSave(inputValue);
                    }}
                    disabled={emailError || props.isSaving || props.error}
                >
                    Save
                </ButtonFlat>
                <ButtonFlat
                    onClick={props.onClose}
                    disabled={props.isSaving}
                >
                    Cancel
                </ButtonFlat>
            </PopupConfirmButtons>
        </PopupConfirm>
    );
};

PopupEmailChange.defaultProps = {
    defaultValue: "",
    isSaving: false,
    error: null,
    onSave: () => {},
    onClose: () => {},
};

export default PopupEmailChange;
