import createEvents from "juice-base/api-common/events-custom/index.js";

import api from "juice-app/api.js";


const getProjectEvents = () => {
    return createEvents({
        sendEvent: api.events.sendEvent,
    });
};

export default getProjectEvents();
