const TEMPLATES = {
    administratorSchoolDetails: "welcome-teacher-administrator-school-details",
    administratorClassSetUp: "welcome-teacher-administrator-class-set-up",

    homeschoolerSchoolDetails: "welcome-teacher-homeschooler-school-details",
    homeschoolerClassSetUp: "welcome-teacher-homeschooler-class-set-up",

    organizationSchoolDetails: "welcome-teacher-organization-school-details",
    organizationClassSetUp: "welcome-teacher-organization-class-set-up",
};


export const getAdministratorTemplates = () => {
    return [
        TEMPLATES.administratorSchoolDetails,
        TEMPLATES.administratorClassSetUp,
    ];
};

export const getHomeschoolerTemplates = () => {
    return [
        TEMPLATES.homeschoolerSchoolDetails,
        TEMPLATES.homeschoolerClassSetUp,
    ];
};

export const getOrganizationTemplates = () => {
    return [
        TEMPLATES.organizationSchoolDetails,
        TEMPLATES.organizationClassSetUp,
    ];
};


export default {
    getAdministratorTemplates,
    getHomeschoolerTemplates,
    getOrganizationTemplates,
};
