import { useState } from "react";

const getState = () => ({
    isOpen: false,
    classId: null,

    isDeleting: false,
    deletingError: null,
});

const useAddClassPopup = () => {
    const [state, setState] = useState(() => getState());

    const open = (classId) => {
        setState((prev) => ({
            ...prev,
            isOpen: true,
            classId,
        }));
    };

    const close = () => {
        setState(getState());
    };

    const setIsDeleting = (isDel) => {
        setState((prev) => ({
            ...prev,
            isOpen: false,
            isDeleting: isDel,
        }));
    };

    const setDeletingError = (deletingError) => {
        setState((prev) => ({
            ...prev,
            isDeleting: false,
            deletingError,
        }));
    };

    return {
        state,
        open,
        close,

        setIsDeleting,
        setDeletingError,
    };
};

export default useAddClassPopup;
