import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import pagesActions from "juice-base/actions/pages.js";
import actions from "juice-base/store/actions.js";

import RequestLoader from "juice-base/components/request-loader/index.js";
import PopupWindow from "juice-base/components/popup-window/index.js";
import Message from "juice-base/components/message/index.js";
import Text from "juice-base/components/text/index.js";

import api from "juice-app/api.js";

import styles from "./styles.module.css";


const storeSelector = (state) => ({
    pagesBySlug: state.pages.pagesBySlug,
});

const PopupPageContainer = (props) => {
    const dispatch = useDispatch();
    const store = useSelector(storeSelector);
    const page = store.pagesBySlug[props.slug];

    useEffect(() => {
        if (!props.slug) {
            return;
        }

        dispatch(pagesActions.loadPageBySlug({ actions, api }, {
            slug: props.slug,
        }));
    }, []);

    const renderContent = () => {
        if (!page) {
            return null;
        }

        if (page.isLoading) {
            return (
                <RequestLoader />
            );
        }

        if (page.error) {
            return (
                <Message>
                    {page.error}
                </Message>
            );
        }

        return (
            <Text className={styles.content}>
                {page.page?.content}
            </Text>
        );
    };

    return (
        <PopupWindow
            title={page?.page.title || ""}
            onClose={props.onClose}
        >
            {renderContent()}
        </PopupWindow>
    );
};

PopupPageContainer.defaultProps = {
    slug: "",
    onClose: () => { },
};

export default PopupPageContainer;
