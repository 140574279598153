import styles from "./styles.module.css";


const ChartTooltip = (props) => {
    return `
        <div
            class="${styles.tooltip}"
            style="width: ${props.width}px"
        >
            <div class="${styles.tooltipValue}">
                0%
            </div>
            <div class="${styles.tooltipArrow}" />
        </div>
    `;
};

export default ChartTooltip;
