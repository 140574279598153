import React from "react";

import IconWrapper from "juice-base/icons/wrapper/index.js";


const IconArrowBold = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={iconProps.className}
                >
                    {iconProps.title}
                    <path
                        fill="currentColor"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M13.7071 5.79291L12.2929 7.20712L16.0858 11H4V13H16.0858L12.2929 16.7929L13.7071 18.2071L19.9142 12L13.7071 5.79291Z"
                    />
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconArrowBold;
