import React from "react";

import IconWrapper from "juice-base/icons/wrapper/index.js";


const IconClipboardChecked = (props) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <IconWrapper {...props}>
            {(iconProps) => (
                <svg
                    width="54"
                    height="56"
                    viewBox="0 0 54 56"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={iconProps.className}
                >
                    {iconProps.title}
                    <path
                        d="M49.75 29.125L53.875 33.25L34.6525 52.5L22.25 40.125L26.375 36L34.6525 44.25L49.75 29.125Z"
                        fill="currentColor"
                    />
                    <path
                        d="M38.75 11.25V19.5H11.25V11.25H5.75V49.75H24.12L14.4675 40.125L26.375 28.2175L34.6525 36.4675L44.25 26.8425V11.25H38.75Z"
                        opacity="0.12"
                        fill="currentColor"
                    />
                    <path
                        d="M5.75 49.75V11.25H11.25V19.5H38.75V11.25H44.25V26.8425L49.75 21.3425V11.25C49.75 8.225 47.275 5.75 44.25 5.75H32.755C31.6 2.56 28.575 0.25 25 0.25C21.425 0.25 18.4 2.56 17.245 5.75H5.75C2.725 5.75 0.25 8.225 0.25 11.25V49.75C0.25 52.775 2.725 55.25 5.75 55.25H29.62L24.12 49.75H5.75ZM25 5.75C26.5125 5.75 27.75 6.9875 27.75 8.5C27.75 10.0125 26.5125 11.25 25 11.25C23.4875 11.25 22.25 10.0125 22.25 8.5C22.25 6.9875 23.4875 5.75 25 5.75Z"
                        fill="currentColor"
                    />
                </svg>
            )}
        </IconWrapper>
    );
};

export default IconClipboardChecked;
