export default (apiURL) => {
    return {
        getStudentDailyJuicesResultsExportUrl(params = {}) {
            // NOTE: action=report_daily_juice_activity_by_student_id
            const reqParams = [
                `session_id=${params.session}`,
                `student_id=${params.studentId}`,
                `date_from=${params.dateFrom}`,
                `date_to=${params.dateTo}`,
            ].join("&");

            return `${apiURL}/export/student-daily-juices-results?${reqParams}`;
        },

        // TODO: remove from rest api
        getClassStudentsExportUrl(params = {}) {
            // NOTE: action=report_class_students_by_teacher
            const reqParams = [
                `session_id=${params.session}`,
                `class_id=${params.classId}`,
                `sort_by=${params.sortBy}`,
                `date_from=${params.dateFrom}`,
                `date_to=${params.dateTo}`,
            ].join("&");

            return `${apiURL}/export/class-students?${reqParams}`;
        },

        getStudentQuizPerformanceExportUrl(params = {}) {
            // NOTE: action=report_student_quiz_performance
            const reqParams = [
                `session_id=${params.session}`,
                `student_id=${params.studentId}`,
                `filter_by=${params.filterBy}`,
                `standard_type=${params.standardType}`,
                `standard_id=${params.standardId}`,
                `range=${params.range}`,
                `date_from=${params.dateFrom}`,
                `date_to=${params.dateTo}`,
            ].join("&");

            return `${apiURL}/export/student-quiz-performance?${reqParams}`;
        },

        // NOTE: remove later
        getStatsAverageScoresByRange(params = {}) {
            // NOTE: action=teacher_report_stats_average_scores_by_range
            const reqParams = [
                `session_id=${params.session}`,
                `class_id=${params.classId}`,
                `category_id=${params.categoryId}`,
                `range=${params.range || ""}`,
                `grades=${params.grades || ""}`,
                `date_from=${params.dateFrom || ""}`,
                `date_to=${params.dateTo || ""}`,
            ].join("&");

            return `${apiURL}/export/class-stats-by-range?${reqParams}`;
        },

        // NOTE: remove later
        getStatsAverageScoresByStandardsByRange(params = {}) {
            // NOTE: action=teacher_report_standards_stats_by_range
            const reqParams = [
                `session_id=${params.session}`,
                `classes=${params.classes}`,
                `grades=${params.grades}`,
                `standard=${params.standard}`,
                `standard_group=${params.standardGroup}`,
                `range=${params.range || ""}`,
                `date_from=${params.dateFrom || ""}`,
                `date_to=${params.dateTo || ""}`,
            ].join("&");

            return `${apiURL}/export/classes-stats-by-standard-by-range?${reqParams}`;
        },

        getClassScoresByRange(params = {}) {
            // NOTE: action=teacher_report_class_scores_by_range
            const reqParams = [
                `session_id=${params.session}`,
                `class_id=${params.classId}`,
                `sort_by=${params.sortBy || ""}`,
                `date_from=${params.dateFrom || ""}`,
                `date_to=${params.dateTo || ""}`,
            ].join("&");

            return `${apiURL}/export/class-scores-by-range?${reqParams}`;
        },

        getClassAssignmentsScoresByRange(params = {}) {
            // NOTE: action=teacher_report_class_assignments_scores_by_range
            const reqParams = [
                `session_id=${params.session}`,
                `class_id=${params.classId}`,
                `sort_by=${params.sortBy || ""}`,
                `date_from=${params.dateFrom || ""}`,
                `date_to=${params.dateTo || ""}`,
            ].join("&");

            return `${apiURL}/export/class-assignments-scores/by-range?${reqParams}`;
        },

        getAssignmentsByStudentId(params = {}) {
            // NOTE: action=teacher_report_assignments_by_student_id
            const reqParams = [
                `session_id=${params.session}`,
                `student_id=${params.studentId}`,
                `page=${params.page}`,
            ].join("&");

            return `${apiURL}/export/assignments-by-student?${reqParams}`;
        },

        getClassesPerformance(params = {}) {
            // NOTE: action=report_teacher_classes_performance
            const reqParams = [
                `session_id=${params.session}`,
                `categories=${params.categories}`,
                `date_from=${params.dateFrom}`,
                `date_to=${params.dateTo}`,
            ].join("&");

            return `${apiURL}/export/classes-performance?${reqParams}`;
        },

        getClassesStatsByLearningStandards(params = {}) {
            // NOTE: action=report_teacher_classes_stats_by_learning_standards
            const reqParams = [
                `session_id=${params.session}`,
                `date_from=${params.dateFrom}`,
                `date_to=${params.dateTo}`,
                `standard_type=${params.standardType}`,
            ].join("&");

            return `${apiURL}/export/classes-stats/by-standards?${reqParams}`;
        },
    };
};
