import React from "react";

import DatepickerCustom from "juice-base/components/datepicker-custom/index.js";
import PopupWindow from "juice-base/components/popup-window/index.js";

import styles from "./styles.module.css";


const PopupDateRangeCustomV2 = (props) => {
    const onSaveDate = (date) => {
        props.onApply({
            startDate: date,
            endDate: date,
        });
    };

    const defaultRange = {
        start: props.startDate,
        end: props.endDate,
    };

    return (
        <PopupWindow
            title="Custom Selection"
            barClassName={styles.popupBar}
            onClose={props.onClose}
            withTitleBarShadow={false}
            isSmall
        >
            <DatepickerCustom
                datepickerClassName={styles.datepicker}
                defaultRange={defaultRange}
                minDate={props.minDate}
                maxDate={props.maxDate}
                onSaveRange={props.onApply}
                onSaveDate={onSaveDate}
                isRangeTabByDefault
                withClose={false}
            />
        </PopupWindow>
    );
};

PopupDateRangeCustomV2.defaultProps = {
    startDate: null,
    endDate: null,

    minDate: null,
    maxDate: null,

    onApply: () => { },
    onClose: () => { },
};

export default PopupDateRangeCustomV2;
