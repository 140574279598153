import React from "react";

import ButtonFlat from "juice-base/components/button-flat/index.js";
import PopupConfirm, {
    PopupConfirmContent,
    PopupConfirmButtons,
    PopupConfirmTitle,
} from "juice-base/components/popup-confirm/index.js";


const PopupConfirmAddExistingTeacher = (props) => {
    const content = `
        The teacher with the email ${props.email} is in our system
        and will be added as a teacher to your class.
        A confirmation email notifying them will be sent.
    `;

    return (
        <PopupConfirm
            closeOnLayoutClick
            hideScrollbar={props.hideScrollbar}
            onClose={props.onClose}
        >
            <PopupConfirmTitle>
                Add Existing Teacher
            </PopupConfirmTitle>

            <PopupConfirmContent isGray>
                {content}
            </PopupConfirmContent>

            <PopupConfirmButtons>
                <ButtonFlat
                    onClick={props.onClose}
                    uppercase={false}
                    danger
                >
                    Cancel
                </ButtonFlat>
                <ButtonFlat
                    onClick={props.onAdd}
                    uppercase={false}
                >
                    Add Teacher
                </ButtonFlat>
            </PopupConfirmButtons>
        </PopupConfirm>
    );
};

PopupConfirmAddExistingTeacher.defaultProps = {
    email: "",
    hideScrollbar: false,
    onAdd: () => {},
    onClose: () => {},
};

export default PopupConfirmAddExistingTeacher;
